// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IdenticonDisplay from './IdenticonDisplay';
import longArrowIcon from '../../../assets/images/arrow-long.svg';
import { TYPE_AUTH } from '../../../constants/account-contants';
import * as accountActions from '../../../actions/account-polkadot-actions';
import cutWalletAddress from '../../../util/cutWalletAddress';
import loadingIcon from '../../../assets/images/loading.png';
import styles from './login-modal.module.scss';
import { CHAIN_NAMES } from '../../../constants/blockchain-constants';

const Accounts = ({ dark, allDotAccounts, dotChainMode }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const connect = async (typeAuth, account) => {
    const chain = dotChainMode === CHAIN_NAMES.POLKADOT ? CHAIN_NAMES.POLKADOT : CHAIN_NAMES.KUSAMA;
    await dispatch(accountActions.connect(typeAuth, account, chain));
  };

  if (!allDotAccounts || !allDotAccounts[0]) {
    // eslint-disable-next-line max-len
    return (
      <a
        className={styles.description}
        target='_blank'
        rel='noreferrer nofollow noopener'
        href='https://polkadot.js.org/extension/'
      >
        <p style={{ textAlign: 'center' }}>Download PolkadotJS</p>
      </a>
    );
  }

  if (!loading) {
    // eslint-disable-next-line react/prop-types
    return allDotAccounts.map((account) => (
      <button
        key={account.address}
        className={styles.itemInner}
        onClick={(e) => {
          e.preventDefault();
          connect(TYPE_AUTH.POLKADOT_JS, account);
          setLoading(true);
        }}
        style={{ backgroundColor: dark ? 'black' : 'white' }}
      >
        <div className={styles.logo}> {IdenticonDisplay(account.address)} </div>
        <span className={styles.item} style={{ color: dark ? '#ffffff' : '#201f1e' }}>
          {cutWalletAddress(account.encodedAddress)}
        </span>
        <img className={styles.arrow} src={longArrowIcon} alt='' />
      </button>
    ));
  }

  return (
    <div className={styles.loading}>
      <img src={loadingIcon} className='rotate' alt='' />
    </div>
  );
};

Accounts.propTypes = {
  dark: PropTypes.bool.isRequired,
  allDotAccounts: PropTypes.object.isRequired,
  dotChainMode: PropTypes.string.isRequired,
};

const Main = ({ global, allDotAccounts, dotChainMode }) => (
  <>
    <h3
      className={styles.title}
      style={{ color: global.dark ? '#ffffff' : '#201f1e' }}
      id='alert-dialog-title'
    >
      {dotChainMode === 'POLKADOT' ? 'Polkadot' : 'Kusama'} wallet
    </h3>
    <p className={styles.description} style={{ color: global.dark ? '#ffffff' : '#606371' }}>
      Safely connect to your existing {dotChainMode === 'POLKADOT' ? 'Polkadot' : 'Kusama'} address
    </p>

    <ul className={styles.list}>
      <li className={styles.item}>
        <Accounts
          dark={global.dark}
          allDotAccounts={allDotAccounts}
          dotChainMode={dotChainMode}
          className={styles.logo}
        />
      </li>
    </ul>
  </>
);

Main.propTypes = {
  global: PropTypes.object.isRequired,
  allDotAccounts: PropTypes.array.isRequired,
  dotChainMode: PropTypes.string.isRequired,
};

export default Main;
