import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color, ...props }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 31 44'
      className={className}
      {...props}
    >
      <path
        fill='#C6CBD2'
        d='M7.165 19.358c.09 5.272 5.105 9.154 10.043 8.157a.353.353 0 00.282-.342c.014-1.235-.048-2.355-.338-3.57a.332.332 0 00-.42-.237c-.699.208-1.609.198-2.348-.034a4.106 4.106 0 01-2.23-1.736c-.711-1.082-.583-2.878-.62-4.384l-.006.003-.057-5.566a3.995 3.995 0 013.99-4.033 4.044 4.044 0 014.075 3.99l.047 3.385a.414.414 0 00.234.366c1.498.73 3.164 2.205 4.093 3.649.026.04.089.021.088-.027l-.074-7.174c-.047-4.584-3.836-8.333-8.42-8.332-4.677 0-8.465 3.828-8.416 8.505l.076 7.351v.03z'
      ></path>
      <path
        fill='#C6CBD2'
        d='M23.414 22.329a.94.94 0 00.004-.009c-.6-2.05-1.978-3.712-3.8-4.853v.003h-.002c-1.642-.996-3.837-1.42-5.845-1.026a.466.466 0 00-.348.383c-.117 1.1-.081 2.327.331 3.526a.378.378 0 00.456.243 4.225 4.225 0 012.311.023v-.001c.579.149 1.481.627 2.085 1.505.855 1.112.765 3.006.788 4.608h.001c.014 1.215.044 4.299.057 5.535a4.044 4.044 0 01-3.99 4.074 4.044 4.044 0 01-4.074-3.99l-.048-3.394a.413.413 0 00-.227-.363c-1.512-.768-3.149-2.201-4.1-3.62-.026-.04-.088-.02-.088.027L7 32.066c.048 4.63 3.875 8.418 8.506 8.418 4.63-.048 8.379-3.876 8.33-8.506-.094-1.686.158-8.249-.421-9.649z'
      ></path>
    </svg>
  );
}
