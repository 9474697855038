import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      version='1.1'
      viewBox='0 0 59 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        fill='#5a5beb'
        d='M50.6,0,29,1.84a3.17,3.17,0,0,0-2.34,5L39,24.62a3.17,3.17,0,0,0,5.48-.45L53.74,4.56A3.18,3.18,0,0,0,50.6,0Z'
      />
      <path
        fill='#5a5beb'
        d='M0,3.44,1.83,25.05a3.17,3.17,0,0,0,5,2.34L24.62,15a3.17,3.17,0,0,0-.46-5.48L4.55.31A3.17,3.17,0,0,0,0,3.44Z'
      />
      <path
        fill='#5a5beb'
        d='M3.44,54l21.61-1.79a3.17,3.17,0,0,0,2.34-5L15,29.42a3.17,3.17,0,0,0-5.48.46L.31,49.49A3.17,3.17,0,0,0,3.44,54Z'
      />
      <path
        fill='#5a5beb'
        d='M54,50.58,52.23,29a3.17,3.17,0,0,0-5-2.35L29.44,39a3.17,3.17,0,0,0,.46,5.48l19.61,9.25A3.17,3.17,0,0,0,54,50.58Z'
      />
    </svg>
  );
}
