import { IconProps } from '../Icon';

export default function Icon({ className, color, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      className={className}
      {...otherProps}
      viewBox='0 0 24 24'
    >
      <path
        fill='url(#paint0_linear_1151_13371)'
        d='M14.36 2.366L12.008 0 9.639 2.38l2.357 2.366 2.368-2.38h-.003zm8.758 2.586l-2.34-2.405-6.603 6.48a3.12 3.12 0 01-4.35 0L3.218 2.543.878 4.952V24h3.335V8.222L7.48 11.43A6.425 6.425 0 0012 13.263c1.636 0 3.272-.61 4.519-1.834l3.205-3.146v15.714h3.398V4.951h-.004z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_1151_13371'
          x1='18.041'
          x2='-0.567'
          y1='1.845'
          y2='6.712'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.372' stopColor='#FF97D6'></stop>
          <stop offset='0.802' stopColor='#F8B994'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
