import networkConstants from '../constants/network.constant';
import { NetworkId } from '../types/network.types';

export default {
  findNetworkType(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return networkConstants.networkType.binance;
      case networkConstants.networkId.polyMainnet:
      case networkConstants.networkId.polyTestnet:
        return networkConstants.networkType.polygon;
      default:
        return networkConstants.networkType.ethereum;
    }
  },

  findNetworkName(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return 'Binance Smart Chain';
      case networkConstants.networkId.polyMainnet:
      case networkConstants.networkId.polyTestnet:
        return 'Polygon';
      default:
        return 'Ethereum';
    }
  },

  findTokenType(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return 'BEP-20';
      default:
        return 'ERC-20';
    }
  },

  findBlockExplorerUrl(networkId?: NetworkId) {
    switch (networkId) {
      case networkConstants.networkId.bscMainnet:
        return 'https://bscscan.com';
      case networkConstants.networkId.bscTestnet:
        return 'https://testnet.bscscan.com';
      case networkConstants.networkId.ethKovan:
        return 'https://kovan.etherscan.io';
      case networkConstants.networkId.polyMainnet:
        return 'https://polygonscan.com';
      default:
        return 'https://etherscan.io';
    }
  },
};
