import React from 'react';
import PropTypes from 'prop-types';

import TokenColumn from '../../../../screens/lending/blocks-for-lending-modals/token-column';
import ModalButton from '../../../../screens/lending/blocks-for-lending-modals/modal-button';
import OmIcon from '../../../../assets/images/tokens/token-type2/om.svg';
import { BORROW_LIMIT_TOOLTIP_TEXT } from '../../../../constants/modal-constants';
import styles from './borrow.module.scss';

const Borrow = ({
  disabled,
  onBorrow,
  tokenIcon,
  borrowAPY,
  distributionAPY,
  borrowBalanceUsd,
  newBorrowBalanceUsd,
  borrowLimitPercent,
  newBorrowLimitPercent,
  isPaused,
}) => {
  const tokenColumn = [
    {
      tokenImage: tokenIcon,
      apyName: 'Borrow APY',
      apyValue: isPaused ? '-' : borrowAPY,
    },
    {
      tokenImage: OmIcon,
      apyName: 'Distribution APY',
      apyValue: isPaused ? '-' : distributionAPY,
    },
  ];

  const borrowColumn = [
    {
      apyName: 'Borrow Balance',
      usdValue: borrowBalanceUsd,
      newUsdValue: newBorrowBalanceUsd,
    },
    {
      apyName: 'Borrow Limit Used',
      percentValue: borrowLimitPercent,
      newPercentValue: newBorrowLimitPercent,
    },
  ];

  return (
    <>
      <div className={styles.row}>
        <TokenColumn title='Borrow Rates' list={tokenColumn} />
        <TokenColumn
          title='Borrow Limit'
          list={borrowColumn}
          tooltipText={BORROW_LIMIT_TOOLTIP_TEXT}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <ModalButton text='Borrow' onClick={onBorrow} disabled={disabled} />
      </div>
    </>
  );
};

Borrow.propTypes = {
  disabled: PropTypes.bool,
  tokenIcon: PropTypes.string,
  borrowAPY: PropTypes.string,
  distributionAPY: PropTypes.string,
  borrowBalanceUsd: PropTypes.string,
  borrowLimitPercent: PropTypes.string,
  newBorrowBalanceUsd: PropTypes.string,
  newBorrowLimitPercent: PropTypes.string,
  onBorrow: PropTypes.func,
  isPaused: PropTypes.bool,
};

Borrow.defaultProps = {
  disabled: false,
  tokenIcon: null,
  borrowAPY: '0',
  distributionAPY: '0',
  borrowBalanceUsd: '0',
  borrowLimitPercent: '0',
  newBorrowBalanceUsd: null,
  newBorrowLimitPercent: null,
  onBorrow: () => {},
  isPaused: false,
};

export default React.memo(Borrow);
