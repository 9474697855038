import React from 'react';
import classNames from 'classnames';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import ModalButton from '../../../screens/lending/blocks-for-lending-modals/modal-button';

import styles from './pool-claim-modal.module.scss';
import closeIcon from '../../../assets/images/close-icon.svg';
import labsClaim from '../../../assets/images/labs-claim.svg';

import { POOL_CLAIM_MODAL } from '../../../constants/modal-constants';
import * as modalActions from '../../../actions/modal-actions';

const PoolClaimModal = ({ show, logo, name, quantity }) => {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);

  return (
    <Dialog open={show} className={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <img className={styles.logo} src={logo} alt='' />
            <div>{name}</div>
          </div>
          <button
            className={styles.close}
            onClick={() => dispatch(modalActions.hideModal(POOL_CLAIM_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={classNames({ [styles.light]: !dark })}>
          <div className={styles.body}>
            <img src={labsClaim} alt='' />
            <div className={styles.description}>
              <div>You are claiming</div>
              <div className={styles.price}>{quantity}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <ModalButton
              text={'Done'}
              onClick={() => dispatch(modalActions.hideModal(POOL_CLAIM_MODAL))}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

PoolClaimModal.propTypes = {};

export default connectModal({ name: POOL_CLAIM_MODAL })(PoolClaimModal);
