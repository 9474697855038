import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './progress-bar.module.scss';

const ProgressBar = ({ className, borrowText, limit, value, styleType }) => {
  const dark = useSelector((state) => state.basic.dark);

  const limitNumber = Number(limit.replace('<', ''));
  return (
    <div
      className={cn(className, styles.wrapper, {
        [styles.dark]: dark,
        [styles.small]: styleType === 'small',
      })}
    >
      {borrowText && (
        <p className={styles.borrowText}>
          {borrowText} <span>{limit}%</span>
        </p>
      )}
      <div className={styles.progressBar}>
        <div className={styles.progressLine} style={{ width: `${limitNumber}%` }} />
        {styleType !== 'small' && (
          <>
            {limitNumber >= 25 && <div className={cn(styles.circle, styles.circleFirst)}>25%</div>}
            {limitNumber >= 50 && <div className={cn(styles.circle, styles.circleSecond)}>50%</div>}
            {limitNumber >= 75 && <div className={cn(styles.circle, styles.circleThird)}>75%</div>}
            {limitNumber >= 100 && (
              <div className={cn(styles.circle, styles.circleFourth)}>100%</div>
            )}
          </>
        )}
      </div>
      {value !== null && <p className={styles.value}>${value}</p>}
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  borrowText: PropTypes.string,
  limit: PropTypes.string.isRequired,
  value: PropTypes.string,
  styleType: PropTypes.oneOf(['small', '']),
};

ProgressBar.defaultProps = {
  className: '',
  borrowText: '',
  value: null,
  styleType: '',
};

export default React.memo(ProgressBar);
