import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import App from './app';
import Loader from './components/homeLoader/index';
import GovernancePageImage from './assets/images/SoftGovernance.svg';
import LoansPageImage from './assets/coming/loans-page.png';

import {
  INDEX_PATH,
  STAKING_PATH,
  LOANS_PATH,
  LENDING_PATH,
  GOVERNANCE_PATH,
  MANTRA_POOL_PATH,
  SUPPORT_PATH,
  OM_TOKEN_SWAP_PATH,
  LAUNCHPAD_PATH,
  LAUNCHPAD_PATH_BY_ID,
  DELEGATOR_PATH,
} from './constants/router-constants';
import { Delegator } from './screens/delegator/Delegator';

const AsyncComingSoon = React.lazy(() => import('./components/comingSoon/ComingSoon'));
const AsyncGovernance = React.lazy(() => import('./components/governance/Governance'));
const AsyncDashboard = React.lazy(() => import('./screens/dashboard/Dashboard'));
const AsyncMantraPool = React.lazy(() => import('./screens/mantra-pool'));
const AsyncLending = React.lazy(() => import('./screens/lending'));
const AsyncStaking = React.lazy(() => import('./screens/staking/Staking'));
const AsyncPageNotFound = React.lazy(() => import('./screens/page-not-found/PageNotFound'));
const AsyncOmTokenMigration = React.lazy(() => import('./screens/om-token-migration'));
const AsyncZendit = React.lazy(() => import('./screens/zendit/Zendit'));
const AsyncZenditPoolId = React.lazy(() => import('./screens/zendit/components/PoolIndex'));

function SupportPage() {
  const location = useLocation();
  window.open('https://docs.mantradao.com', '_blank');
  return <Redirect to={location.state.prevPath} />;
}

const Routes = (props) => (
  <App>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={INDEX_PATH} component={AsyncDashboard} />
        <Route path={STAKING_PATH} component={AsyncStaking} />
        <Route
          exact
          path={OM_TOKEN_SWAP_PATH}
          render={() => <AsyncOmTokenMigration global={props} />}
        />
        <Route
          exact
          path={LOANS_PATH}
          render={() => <AsyncComingSoon title='Loans' image={LoansPageImage} global={props} />}
        />
        <Route path={LENDING_PATH} render={() => <AsyncLending global={props} />} />
        <Route
          exact
          path={GOVERNANCE_PATH}
          render={() => (
            <AsyncGovernance title='Soft Governance' image={GovernancePageImage} global={props} />
          )}
        />
        {/*<Route exact path={MANTRA_POOL_PATH} render={() => <AsyncMantraPool global={props} />} />*/}
        <Route path={SUPPORT_PATH} render={() => <SupportPage />} />
        <Route path={DELEGATOR_PATH} component={Delegator} />
        <Route exact path={LAUNCHPAD_PATH} component={AsyncZendit} />
        <Route exact path={LAUNCHPAD_PATH_BY_ID} component={AsyncZenditPoolId} />
        <Route render={() => <AsyncPageNotFound dark={props.dark} />} />
      </Switch>
    </Suspense>
  </App>
);

const mapStateToProps = (state) => ({
  dark: state.basic.dark,
  light: state.basic.light,
});

export default connect(mapStateToProps, {})(Routes);
