import BN from 'bignumber.js';
import commas from '../util/formatter';

/**
 * Converts value to a string with the desired precision
 * @param {number|string} value
 * @param {number} precision
 * @returns {string}
 */
export const formatPrecision = (value, precision, round) => {
  if (!value) {
    return '0';
  }
  precision = precision === undefined ? 1 : precision;
  const valBN = BN.isBigNumber(value) ? value : new BN(value);
  return commas(valBN.dp(precision, round || BN.ROUND_DOWN).toFixed());
};

export const shortenLongNumber = (num, digits = 2) => {
  const postfix = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = postfix.length - 1; i > 0; i -= 1) {
    if (num >= postfix[i].value) {
      break;
    }
  }
  return (
    Number((num / postfix[i].value).toFixed(digits))
      .toString()
      .replace(rx, '$1') + postfix[i].symbol
  );
};

export const cacheResultFunction =
  (key, fn) =>
  async (...args) => {
    const result = await fn(args);
    const strResult = JSON.stringify(result);
    if (localStorage.getItem(key) !== strResult) {
      localStorage.setItem(key, JSON.stringify(result));
    }
    return result;
  };

export const getDeltaTime = (dateFrom, dateTo = new Date()) => {
  const delta = Math.abs(new Date(dateFrom) - new Date(dateTo));
  const days = Math.floor(delta / 60 / 60 / 24 / 1000);
  const hours = Math.floor(delta / 60 / 60 / 1000 - days * 24);
  const minutes = Math.floor(delta / 60 / 1000 - hours * 60 - days * 60 * 24);

  return {
    days,
    hours,
    minutes,
  };
};

export const getDeltaTimeLabel = (shortLabels, dateFrom, dateTo) => {
  const getPluralPostfix = (val) => (val > 1 ? 's' : '');
  const { days, hours, minutes } = getDeltaTime(dateFrom, dateTo);

  const dayLabel = shortLabels ? 'd' : ` day${getPluralPostfix(days)}`;
  const hourLabel = shortLabels ? 'h' : ` hour${getPluralPostfix(hours)}`;
  const minLabel = shortLabels ? 'm' : ` minute${getPluralPostfix(minutes)}`;

  const daysTitle = days > 0 ? `${days}${dayLabel}` : '';
  const hrTitle = hours > 0 ? `${hours}${hourLabel}` : '';
  const minTitle = minutes > 0 ? `${minutes}${minLabel}` : '';

  if (!minutes) {
    if (!hours) {
      return `${daysTitle}`;
    }
    return `${daysTitle} ${hrTitle}`;
  }

  if (!hours) {
    if (!daysTitle) {
      return `${minTitle}`;
    }
    return `${daysTitle} ${minTitle}`;
  }
  return `${daysTitle} ${hrTitle} ${minTitle}`;
};

export const getTokenByZenTokenSymbol = (tokensDataList, zenTokenSymbol) => {
  return tokensDataList.find((tokenData) => tokenData.zenTokenSymbol === zenTokenSymbol);
};
