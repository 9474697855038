import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../actions/delegator-actions';
import delegateContractAbi from '../../../blockchain/abi/delegateContractAbi';
import formatMoney from '../../../helpers/money-helper';
import {
  formatAtomToken,
  formatBandToken,
  formatDpvnToken,
  formatEmoneyToken,
  formatKavaToken,
  formatLunaToken,
  formatMaticToken,
} from '../../../helpers/token-helper';
import delegatorService from '../../../services/delegator/delegator.service';
import { DelegatorLink, UserRate } from '../../../services/delegator/delegator.types';
import metamaskService from '../../../services/metamask.service';
import validatorsService from '../../../services/validators/validators.service';

const formatDelegation = (delegation: any, chain: any) => {
  if (chain === 'atom') return formatAtomToken(delegation);
  else if (chain === 'luna') return formatLunaToken(delegation);
  else if (chain === 'band') return formatBandToken(delegation);
  else if (chain === 'dpvn') return formatDpvnToken(delegation);
  else if (chain === 'kava') return formatKavaToken(delegation);
  else if (chain === 'emoney') return formatEmoneyToken(delegation);
  else if (chain === 'matic') return formatMaticToken(delegation);
};

export function useWalletValidator(walletAddress: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const validateAddress = async (walletAddress: string) => {
    if (walletAddress) {
      setLoading(true);
      setError(false);
      try {
        // add logic here to validate walletAddress

        // Length check

        // starts with 'cosmos' if on cosmos chain
        const data = await axios.get('https://jsonplaceholder.typicode.com/todos/1');
        if (data.status !== 200) {
          setError(true);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      } finally {
        setTimeout(() => setLoading(false), 2000);
      }
    }
  };

  useEffect(() => {
    validateAddress(walletAddress);
  }, [walletAddress]);

  return { validateAddress, loading, error };
}

export function useDelegatorCreateHook(chainId: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [messageId, setMessageId] = useState('');

  const createLink = async (walletAddress: string, otherWalletAddress: string) => {
    setLoading(true);
    setError(false);
    try {
      const { messageId } = await delegatorService.createLink(
        walletAddress,
        otherWalletAddress,
        chainId,
      );

      setMessageId(messageId);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return [createLink, loading, error, messageId] as const;
}

export function useDelegatorSignCreateHook(chainId: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const DELEGATE_CONTRACT_ADDRESS = '0x9f21939EFe79Bf081B27F7A6D9bAeAE04c1D67Ca';

  const signCreateLink = async (
    walletAddress: string,
    otherWalletAddress: string,
    signature: string | null,
    encodedMsg: string | null,
  ) => {
    if (signature && encodedMsg) {
      setLoading(true);
      setError(false);

      const metamaskInstance = metamaskService.getInstance();

      const delegatorContract = new metamaskInstance.eth.Contract(
        delegateContractAbi,
        DELEGATE_CONTRACT_ADDRESS,
      );

      try {
        await delegatorContract.methods
          .linkAddresses(otherWalletAddress, signature, encodedMsg)
          .send({
            from: walletAddress,
          });

        setIsSigned(true);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return [signCreateLink, loading, error, isSigned] as const;
}

export function useDelegatorPollCheckHook(chainId: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [delegatorLinks, setDelegatorLinks] = useState<DelegatorLink[]>([]);

  const pollStatus = async (ethAddress: string, otherAddress: string) => {
    setLoading(true);
    setError(false);
    try {
      const { delegators } = await delegatorService.pollDelegatorStatus(
        ethAddress,
        otherAddress,
        chainId,
      );

      setDelegatorLinks(delegators);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return [pollStatus, delegatorLinks, loading, error] as const;
}

export function useDelegatorCheckHook(chainId: number) {
  const dispatch = useDispatch();
  const { setDelegatorLinks: setDelegatorLinksStore } = bindActionCreators(
    DelegatorActions,
    dispatch,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [delegatorLinks, setDelegatorLinks] = useState<DelegatorLink[]>([]);

  const checkAddress = async (ethAddress: string | null, otherAddress: string | null) => {
    setLoading(true);
    setError(false);
    try {
      const { delegators } = await delegatorService.listDelegators(
        ethAddress,
        otherAddress,
        chainId,
      );

      setDelegatorLinks(delegators);
      setDelegatorLinksStore({ chainId, delegatorLinks: delegators });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return [checkAddress, delegatorLinks, loading, error] as const;
}

export function useDelegatorUserRateHook(chainId: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [userRate, setUserRate] = useState<UserRate[]>([]);
  const [REWARD_POOL_SIZE, setREWARD_POOL_SIZE] = useState(500000);
  const [userRateParsed, setUserRateParsed] = useState({
    userStake: 0,
    userRatio: 0,
    linkedStake: 0,
    totalStake: 0,
  });

  const checkDelegation = async (otherAddress: string) => {
    setLoading(true);
    setError(false);
    try {
      const { userRate } = await delegatorService.userRate(otherAddress, chainId);
      setUserRateParsed(JSON.parse(JSON.stringify(userRate)));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { checkDelegation, userRate, REWARD_POOL_SIZE, userRateParsed, loading, error };
}

export function useValidatorAccountingDataHook(chainId: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { USD: currencies } = useSelector((state: any) => state.basic.currency);

  const [validators, setValidators] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const getValidatorAccountingInfo = async () => {
    setLoading(true);
    setError(false);
    try {
      const validators: any = await validatorsService.getValidatorAccounting(chainId);

      // Take raw validator data and get
      // A. Human readable node amounts in native token
      // B. Dollar value for each chain
      // C. Total node value
      const parsedValidators = validators
        .map((validator: any) => {
          const parsedDelegation = formatDelegation(validator.delegation, validator.chainName);
          if (parsedDelegation === undefined) {
            console.warn(`Unable to parse delegation amount for chain: ${validator.chainName}`);
            return null;
          }

          const usdAmount =
            Number(parsedDelegation.replaceAll(',', '')) *
            currencies[validator.chainName.toUpperCase()];

          return Object.assign(validator, {
            usdAmount,
            usdValue: usdAmount,
            nodeValue: formatMoney.formatUSD(usdAmount),
          });
        })
        .filter((v: any) => v !== null);

      setValidators(parsedValidators);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return [getValidatorAccountingInfo, validators, totalValue, loading, error] as const;
}
