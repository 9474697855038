import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactCountdown from 'react-countdown';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import IconInfo from '../../iconInfo';
import Timer from '../../../screens/mantra-pool/countdown/timer';
import stylesModal from '../modal.module.scss';
import styles from './pool-result-modal.module.scss';
import * as modalActions from '../../../actions/modal-actions';
import {
  POOL_PROVABLY_MODAL,
  POOL_ROUND_MODAL,
  CLAIM_REWARDS_MODAL,
} from '../../../constants/modal-constants';
// import { formatToken } from '../../../helpers/token-helper';
import cutWalletAddress from '../../../util/cutWalletAddress';
import { formatToken, formatUSDTToken } from '../../../helpers/token-helper';

const Details = ({ data, updateData }) => {
  const dispatch = useDispatch();
  const networkId = useSelector((state) => state.account.networkId);
  const address = useSelector((state) => state.account.address);
  const withdrawalPeriod = useSelector((state) => state.mantraPool.withdrawalPeriod);
  const dark = useSelector((state) => state.basic.dark);

  let amount =
    networkId === 1 || networkId === 0 || networkId === 137
      ? formatUSDTToken(data.totalReward)
      : formatToken(data.totalReward, 18);

  let usdTokenSymbol = '';
  if (networkId === 1) {
    usdTokenSymbol = 'USDT';
  } else if (networkId === 56) {
    usdTokenSymbol = 'BUSD';
  } else if (networkId === 137) {
    usdTokenSymbol = 'USDT';
  }

  return (
    <>
      <div className={styles.value}>
        {amount} {usdTokenSymbol}
      </div>
      <div className={styles.title}>Total Prize POOL Value</div>
      <div className={cn(styles.countdown, { [styles.dark]: dark })}>
        <ReactCountdown
          date={data.closedAt * 1000 + withdrawalPeriod * 1000}
          renderer={(props) => (
            <Timer className={styles.timer} withSeconds dark={dark} {...props} />
          )}
          onComplete={() =>
            setTimeout(() => {
              updateData();
            }, 2000)
          }
        />
        <Tooltip
          placement='top-start'
          title={
            <div className={styles.tooltip}>
              All Winners are given 6 days to claim their prize. If you&apos;re not able to, then
              your rewards will be forfeited.
              <br />
              Subscribe to our social media so you can get notified of the POOL results.
            </div>
          }
        >
          <div className={styles.tooltipIcon}>
            <IconInfo />
          </div>
        </Tooltip>
      </div>
      <div className={cn(styles.title, styles.claimTitle)}>Claim Within</div>
      {data.winners.map((item) => (
        <div key={item} className={styles.claimRow}>
          <div className={styles.claimRowLabel}>{cutWalletAddress(item)}</div>
          <div>
            <Button
              className={cn(stylesModal.button, stylesModal.autoHeight, {
                [stylesModal.claimed]: data.rewardPaid,
                [stylesModal.dark]: dark,
              })}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  modalActions.showModal(CLAIM_REWARDS_MODAL, {
                    roundIndex: data.index,
                    onSuccess: () => updateData(),
                  }),
                );
              }}
              disabled={
                address !== item.toLowerCase() ||
                data.rewardPaid ||
                new Date().getTime() > data.closedAt * 1000 + withdrawalPeriod * 1000
              }
              variant='contained'
              color='secondary'
            >
              {data.rewardPaid ? 'Claimed' : 'Claim'}
            </Button>
          </div>
        </div>
      ))}
      <div className={styles.description}>
        <b>All Winners are given 6 days to claim their prize.</b> If you&apos;re not able to, then
        your rewards will be forfeited.
        <br />
        <br />
        Subscribe to our social media so you can get notified of the POOL results.
      </div>
      <div className={styles.info}>
        <div className={styles.row}>
          <div>Date</div>
          <div>{moment(new Date(data.endsAt * 1000)).format('YYYY.MM.DD HH:mm')}</div>
        </div>
        <div className={styles.row}>
          <div>Round</div>
          <div>{+data.index + 1}</div>
        </div>
        <div className={styles.row}>
          <div>Participants</div>
          <div>
            {/* eslint-disable-next-line */}
            <a
              href=''
              onClick={(e) => {
                e.preventDefault();
                dispatch(modalActions.showModal(POOL_ROUND_MODAL, { roundIndex: data.index }));
              }}
            >
              {data.countParticipants}
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div>Total Entries</div>
          <div>
            {/* eslint-disable-next-line */}
            <a
              href=''
              onClick={(e) => {
                e.preventDefault();
                dispatch(modalActions.showModal(POOL_ROUND_MODAL, { roundIndex: data.index }));
              }}
            >
              {data.totalEntries}
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowLabel}>POOL Hash</div>
          <div>
            {/* eslint-disable-next-line */}
            <a
              href=''
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  modalActions.showModal(POOL_PROVABLY_MODAL, {
                    roundIndex: data.index,
                    seed: data.seed,
                  }),
                );
              }}
            >
              {data.seed}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

Details.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default Details;
