import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 156 130'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <g xmlns='http://www.w3.org/2000/svg' clipPath='url(#clip0)'>
        <g clipPath='url(#clip1)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5478 43.6509L72.7205 0L79.2906 53.6409C79.7827 57.6271 80.6685 65.6978 76.3624 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.785 91.0665 0.0320129 125.982 0.0320129 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.975 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5478 43.6509Z'
            fill='url(#paint0_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5478 43.6509L72.7205 0L79.2906 53.6409C79.7827 57.6271 80.6685 65.6978 76.3624 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.785 91.0665 0.0320129 125.982 0.0320129 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.975 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5478 43.6509Z'
            fill='url(#paint1_linear)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5478 43.6509L72.7205 0L79.2906 53.6409C79.7827 57.6271 80.6685 65.6978 76.3624 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.785 91.0665 0.0320129 125.982 0.0320129 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.975 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5478 43.6509Z'
            fill='url(#paint2_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5478 43.6509L72.7205 0L79.2906 53.6409C79.7827 57.6271 80.6685 65.6978 76.3624 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.785 91.0665 0.0320129 125.982 0.0320129 125.982L48.1137 63.8032C48.8273 62.8927 49.1226 61.7609 48.975 60.629L47.031 46.333C46.8834 45.3979 47.0802 44.4629 47.5478 43.6509Z'
            fill='url(#paint3_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5798 43.6509L72.7525 0L79.3226 53.6409C79.8147 57.6271 80.7006 65.6978 76.3944 72.2184C70.9563 79.8954 66.4532 81.4948 61.9009 83.0942C59.6617 83.8816 57.3979 84.6936 55.0356 86.2191C46.817 91.0665 0.0640259 125.982 0.0640259 125.982L48.1457 63.8031C48.8593 62.8927 49.1546 61.7609 49.007 60.629L47.063 46.3329C46.9154 45.3979 47.1123 44.4629 47.5798 43.6509Z'
            fill='url(#paint4_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5798 43.6509L72.7525 0L79.3226 53.6409C79.8147 57.6271 80.7006 65.6978 76.3944 72.2184C70.9563 79.8954 66.4532 81.4948 61.9009 83.0942C59.6617 83.8816 57.3979 84.6936 55.0356 86.2191C46.817 91.0665 0.0640259 125.982 0.0640259 125.982L48.1457 63.8031C48.8593 62.8927 49.1546 61.7609 49.007 60.629L47.063 46.3329C46.9154 45.3979 47.1123 44.4629 47.5798 43.6509Z'
            fill='url(#paint5_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.048 81.3166L147.264 124.942L97.525 103.812C93.8268 102.245 86.3944 98.9766 82.9005 91.987C78.9711 83.4389 79.8375 78.7395 80.7285 73.9974C81.1662 71.6645 81.5949 69.298 81.4549 66.4894C81.3663 56.9482 74.5048 -0.998974 74.5048 -0.998974L104.313 71.7306C104.744 72.8038 105.577 73.6255 106.631 74.0635L119.984 79.5281C120.867 79.8677 121.579 80.5057 122.048 81.3166Z'
            fill='url(#paint6_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.048 81.3166L147.264 124.942L97.525 103.812C93.8268 102.245 86.3944 98.9766 82.9005 91.987C78.9711 83.4389 79.8375 78.7395 80.7285 73.9974C81.1662 71.6645 81.5949 69.298 81.4549 66.4894C81.3663 56.9482 74.5048 -0.998974 74.5048 -0.998974L104.313 71.7306C104.744 72.8038 105.577 73.6255 106.631 74.0635L119.984 79.5281C120.867 79.8677 121.579 80.5057 122.048 81.3166Z'
            fill='url(#paint7_linear)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.048 81.3166L147.264 124.942L97.525 103.812C93.8268 102.245 86.3944 98.9766 82.9005 91.987C78.9711 83.4389 79.8375 78.7395 80.7285 73.9974C81.1662 71.6645 81.5949 69.298 81.4549 66.4894C81.3663 56.9482 74.5048 -0.998974 74.5048 -0.998974L104.313 71.7306C104.744 72.8038 105.577 73.6255 106.631 74.0635L119.984 79.5281C120.867 79.8677 121.579 80.5057 122.048 81.3166Z'
            fill='url(#paint8_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.048 81.3166L147.264 124.942L97.525 103.812C93.8268 102.245 86.3944 98.9766 82.9005 91.987C78.9711 83.4389 79.8375 78.7395 80.7285 73.9974C81.1662 71.6645 81.5949 69.298 81.4549 66.4894C81.3663 56.9482 74.5048 -0.998974 74.5048 -0.998974L104.313 71.7306C104.744 72.8038 105.577 73.6255 106.631 74.0635L119.984 79.5281C120.867 79.8677 121.579 80.5057 122.048 81.3166Z'
            fill='url(#paint9_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.032 81.3439L147.248 124.97L97.509 103.839C93.8108 102.272 86.3784 99.0039 82.8845 92.0144C78.9551 83.4663 79.8215 78.7668 80.7125 74.0248C81.1502 71.6919 81.5789 69.3253 81.4389 66.5168C81.3503 56.9755 74.4888 -0.971634 74.4888 -0.971634L104.297 71.7579C104.728 72.8311 105.561 73.6528 106.615 74.0909L119.968 79.5554C120.851 79.895 121.563 80.533 122.032 81.3439Z'
            fill='url(#paint10_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5477 43.6509L72.7205 0L79.2905 53.6409C79.7827 57.6271 80.6685 65.6978 76.3623 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.7849 91.0665 0.0319824 125.982 0.0319824 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.9749 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5477 43.6509Z'
            fill='url(#paint11_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5477 43.6509L72.7205 0L79.2905 53.6409C79.7827 57.6271 80.6685 65.6978 76.3623 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.7849 91.0665 0.0319824 125.982 0.0319824 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.9749 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5477 43.6509Z'
            fill='url(#paint12_linear)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5477 43.6509L72.7205 0L79.2905 53.6409C79.7827 57.6271 80.6685 65.6978 76.3623 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.7849 91.0665 0.0319824 125.982 0.0319824 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.9749 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5477 43.6509Z'
            fill='url(#paint13_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5477 43.6509L72.7205 0L79.2905 53.6409C79.7827 57.6271 80.6685 65.6978 76.3623 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.7849 91.0665 0.0319824 125.982 0.0319824 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.9749 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5477 43.6509Z'
            fill='url(#paint14_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5798 43.6509L72.7525 0L79.3225 53.6409C79.8147 57.6271 80.7005 65.6978 76.3943 72.2184C70.9562 79.8954 66.4532 81.4948 61.9009 83.0942C59.6617 83.8816 57.3979 84.6936 55.0356 86.2191C46.8169 91.0665 0.0639954 125.982 0.0639954 125.982L48.1457 63.8031C48.8593 62.8927 49.1546 61.7609 49.0069 60.629L47.063 46.3329C46.9154 45.3979 47.1122 44.4629 47.5798 43.6509Z'
            fill='url(#paint15_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.5477 43.6509L72.7205 0L79.2905 53.6409C79.7827 57.6271 80.6685 65.6978 76.3623 72.2184C70.9242 79.8954 66.4212 81.4948 61.8689 83.0942C59.6297 83.8816 57.3659 84.6936 55.0036 86.2191C46.7849 91.0665 0.0319824 125.982 0.0319824 125.982L48.1137 63.8031C48.8273 62.8927 49.1226 61.7609 48.9749 60.629L47.031 46.3329C46.8834 45.3979 47.0802 44.4629 47.5477 43.6509Z'
            fill='url(#paint16_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.016 81.3166L147.232 124.942L97.493 103.812C93.7948 102.245 86.3624 98.9766 82.8685 91.987C78.9391 83.4389 79.8055 78.7395 80.6965 73.9974C81.1342 71.6645 81.5629 69.298 81.4229 66.4894C81.3343 56.9482 74.4728 -0.998974 74.4728 -0.998974L104.281 71.7306C104.712 72.8038 105.545 73.6255 106.599 74.0635L119.952 79.5281C120.835 79.8677 121.547 80.5057 122.016 81.3166Z'
            fill='url(#paint17_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.016 81.3166L147.232 124.942L97.493 103.812C93.7948 102.245 86.3624 98.9766 82.8685 91.987C78.9391 83.4389 79.8055 78.7395 80.6965 73.9974C81.1342 71.6645 81.5629 69.298 81.4229 66.4894C81.3343 56.9482 74.4728 -0.998974 74.4728 -0.998974L104.281 71.7306C104.712 72.8038 105.545 73.6255 106.599 74.0635L119.952 79.5281C120.835 79.8677 121.547 80.5057 122.016 81.3166Z'
            fill='url(#paint18_linear)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.016 81.3166L147.232 124.942L97.493 103.812C93.7948 102.245 86.3624 98.9766 82.8685 91.987C78.9391 83.4389 79.8055 78.7395 80.6965 73.9974C81.1342 71.6645 81.5629 69.298 81.4229 66.4894C81.3343 56.9482 74.4728 -0.998974 74.4728 -0.998974L104.281 71.7306C104.712 72.8038 105.545 73.6255 106.599 74.0635L119.952 79.5281C120.835 79.8677 121.547 80.5057 122.016 81.3166Z'
            fill='url(#paint19_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.016 81.3166L147.232 124.942L97.493 103.812C93.7948 102.245 86.3624 98.9766 82.8685 91.987C78.9391 83.4389 79.8055 78.7395 80.6965 73.9974C81.1342 71.6645 81.5629 69.298 81.4229 66.4894C81.3343 56.9482 74.4728 -0.998974 74.4728 -0.998974L104.281 71.7306C104.712 72.8038 105.545 73.6255 106.599 74.0635L119.952 79.5281C120.835 79.8677 121.547 80.5057 122.016 81.3166Z'
            fill='url(#paint20_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122 81.3439L147.216 124.97L97.477 103.839C93.7788 102.272 86.3464 99.0039 82.8525 92.0144C78.9231 83.4663 79.7895 78.7668 80.6805 74.0248C81.1182 71.6919 81.5469 69.3253 81.4069 66.5168C81.3183 56.9755 74.4568 -0.971638 74.4568 -0.971638L104.265 71.7579C104.696 72.8311 105.529 73.6528 106.583 74.0909L119.936 79.5554C120.819 79.895 121.531 80.533 122 81.3439Z'
            fill='url(#paint21_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M122.016 81.3166L147.232 124.942L97.493 103.812C93.7948 102.245 86.3624 98.9766 82.8685 91.987C78.9391 83.4389 79.8055 78.7395 80.6965 73.9974C81.1342 71.6645 81.5629 69.298 81.4229 66.4894C81.3343 56.9482 74.4728 -0.998974 74.4728 -0.998974L104.281 71.7306C104.712 72.8038 105.545 73.6255 106.599 74.0635L119.952 79.5281C120.835 79.8677 121.547 80.5057 122.016 81.3166Z'
            fill='url(#paint22_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0401 127.8L1.65089 127.825L44.8203 95.315C48.0263 92.8957 54.5729 88.0932 62.3729 88.5622C71.7405 89.4332 75.3771 92.5333 79.0384 95.676C80.8399 97.2215 82.675 98.776 85.1773 100.059C93.4846 104.753 147.099 127.784 147.099 127.784L69.2094 117.234C68.0642 117.071 66.9363 117.381 66.0299 118.075L54.6212 126.907C53.8852 127.502 52.977 127.799 52.0401 127.8Z'
            fill='url(#paint23_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0401 127.8L1.65089 127.825L44.8203 95.315C48.0263 92.8957 54.5729 88.0932 62.3729 88.5622C71.7405 89.4332 75.3771 92.5333 79.0384 95.676C80.8399 97.2215 82.675 98.776 85.1773 100.059C93.4846 104.753 147.099 127.784 147.099 127.784L69.2094 117.234C68.0642 117.071 66.9363 117.381 66.0299 118.075L54.6212 126.907C53.8852 127.502 52.977 127.799 52.0401 127.8Z'
            fill='url(#paint24_linear)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0401 127.8L1.65089 127.825L44.8203 95.315C48.0263 92.8957 54.5729 88.0932 62.3729 88.5622C71.7405 89.4332 75.3771 92.5333 79.0384 95.676C80.8399 97.2215 82.675 98.776 85.1773 100.059C93.4846 104.753 147.099 127.784 147.099 127.784L69.2094 117.234C68.0642 117.071 66.9363 117.381 66.0299 118.075L54.6212 126.907C53.8852 127.502 52.977 127.799 52.0401 127.8Z'
            fill='url(#paint25_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0401 127.8L1.65089 127.825L44.8203 95.315C48.0263 92.8957 54.5729 88.0932 62.3729 88.5622C71.7405 89.4332 75.3771 92.5333 79.0384 95.676C80.8399 97.2215 82.675 98.776 85.1773 100.059C93.4846 104.753 147.099 127.784 147.099 127.784L69.2094 117.234C68.0642 117.071 66.9363 117.381 66.0299 118.075L54.6212 126.907C53.8852 127.502 52.977 127.799 52.0401 127.8Z'
            fill='url(#paint26_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0241 127.773L1.6349 127.798L44.8043 95.2877C48.0103 92.8684 54.5569 88.0659 62.3569 88.5348C71.7245 89.4059 75.3611 92.5059 79.0224 95.6486C80.8239 97.1941 82.659 98.7487 85.1613 100.032C93.4686 104.726 147.083 127.757 147.083 127.757L69.1935 117.206C68.0482 117.044 66.9203 117.354 66.0139 118.048L54.6052 126.879C53.8692 127.475 52.961 127.772 52.0241 127.773Z'
            fill='url(#paint27_radial)'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.0401 127.8L1.65089 127.825L44.8203 95.315C48.0263 92.8957 54.5729 88.0932 62.3729 88.5622C71.7405 89.4332 75.3771 92.5333 79.0384 95.676C80.8399 97.2215 82.675 98.776 85.1773 100.059C93.4846 104.753 147.099 127.784 147.099 127.784L69.2094 117.234C68.0642 117.071 66.9363 117.381 66.0299 118.075L54.6212 126.907C53.8852 127.502 52.977 127.799 52.0401 127.8Z'
            fill='url(#paint28_radial)'
          />
        </g>
      </g>
      <defs xmlns='http://www.w3.org/2000/svg'>
        <radialGradient
          id='paint0_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(78.5045 68.5837) rotate(143.806) scale(97.2038 111.381)'
        >
          <stop stopColor='#1C1159' /> <stop offset='0.4487' stopColor='#FF78D1' />
          <stop offset='0.6228' stopColor='#FFC979' />
          <stop offset='0.7685' stopColor='#FFF58C' /> <stop offset='0.8983' stopColor='white' />
        </radialGradient>
        <linearGradient
          id='paint1_linear'
          x1='79.6539'
          y1='64.3918'
          x2='37.6592'
          y2='58.9925'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0456257' stopColor='#0F0F13' stopOpacity='0.67' />
          <stop offset='0.321849' stopColor='#582C7A' stopOpacity='0' />
        </linearGradient>
        <radialGradient
          id='paint2_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-25.9327 23.197) rotate(2.95394) scale(112.535 450.185)'
        >
          <stop offset='0.735423' stopColor='#D7468C' stopOpacity='0.24' />
          <stop offset='0.908835' stopColor='#27043F' stopOpacity='0.32' />
        </radialGradient>
        <radialGradient
          id='paint3_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(2.72872 36.6503) rotate(9.27541) scale(93.9516 103.378)'
        >
          <stop offset='0.11701' stopColor='#27043F' stopOpacity='0' />
          <stop offset='0.56027' stopColor='#E33272' stopOpacity='0.4' />
          <stop offset='0.779103' stopColor='#9036EC' stopOpacity='0.17' />
        </radialGradient>
        <radialGradient
          id='paint4_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(0.0640244 125.984) rotate(-37.0178) scale(108.534 114.051)'
        >
          <stop stopColor='white' /> <stop offset='0.185217' stopColor='#FFD79A' />
          <stop offset='0.34495' stopColor='#FFBF51' />
          <stop offset='0.629193' stopColor='#FF778F' stopOpacity='0.03' />
        </radialGradient>
        <radialGradient
          id='paint5_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(72.6548) rotate(82.6988) scale(64.515 20.7402)'
        >
          <stop stopColor='#FFBF51' />
          <stop offset='0.520833' stopColor='#E33272' stopOpacity='0.28' />
          <stop offset='1' stopColor='#46008E' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint6_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(84.9772 95.6595) rotate(-96.194) scale(97.2038 111.381)'
        >
          <stop stopColor='#1C1159' /> <stop offset='0.4487' stopColor='#FF78D1' />
          <stop offset='0.6228' stopColor='#FFC979' />
          <stop offset='0.7685' stopColor='#FFF58C' /> <stop offset='0.8983' stopColor='white' />
        </radialGradient>
        <linearGradient
          id='paint7_linear'
          x1='88.0328'
          y1='98.7508'
          x2='113.706'
          y2='65.0821'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0456257' stopColor='#0F0F13' stopOpacity='0.67' />
          <stop offset='0.321849' stopColor='#582C7A' stopOpacity='0' />
        </linearGradient>
        <radialGradient
          id='paint8_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(91.8409 106.953) rotate(-30.5883) scale(67.8842 128.806)'
        >
          <stop offset='0.1475' stopColor='#27043F' stopOpacity='0.12' />
          <stop offset='0.456553' stopColor='#DB3B80' stopOpacity='0.08' />
        </radialGradient>
        <radialGradient
          id='paint9_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(150.52 46.0025) rotate(135.945) scale(87.3222 91.7611)'
        >
          <stop offset='0.11701' stopColor='#27043F' stopOpacity='0' />
          <stop offset='0.535374' stopColor='#E33272' stopOpacity='0.4' />
          <stop offset='0.700045' stopColor='#9036EC' stopOpacity='0.17' />
        </radialGradient>
        <radialGradient
          id='paint10_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(74.4873 -0.972461) rotate(82.9823) scale(108.534 114.051)'
        >
          <stop offset='0.0916302' stopColor='white' />
          <stop offset='0.225286' stopColor='#FFD79A' />
          <stop offset='0.486767' stopColor='#FF77F3' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint11_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(78.5045 68.5837) rotate(143.806) scale(97.2038 111.381)'
        >
          <stop stopColor='#1C1159' /> <stop offset='0.0281713' stopColor='#5D09E1' />
          <stop offset='0.4487' stopColor='#E478FF' />
          <stop offset='0.6228' stopColor='#8479FF' />
          <stop offset='0.7685' stopColor='#8CF1FF' />
          <stop offset='0.8983' stopColor='#37FFDB' />
        </radialGradient>
        <linearGradient
          id='paint12_linear'
          x1='79.6539'
          y1='64.3918'
          x2='37.6592'
          y2='58.9925'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0456257' stopColor='#0F0F13' stopOpacity='0.67' />
          <stop offset='0.321849' stopColor='#582C7A' stopOpacity='0' />
        </linearGradient>
        <radialGradient
          id='paint13_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(84.8532 56.9928) rotate(-150.588) scale(67.8842 128.806)'
        >
          <stop offset='0.1475' stopColor='#27043F' stopOpacity='0.12' />
          <stop offset='0.456553' stopColor='#DB3B80' stopOpacity='0.08' />
        </radialGradient>
        <radialGradient
          id='paint14_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(2.72869 36.6503) rotate(15.9454) scale(87.3222 91.7611)'
        >
          <stop offset='0.11701' stopColor='#27043F' stopOpacity='0' />
          <stop offset='0.524525' stopColor='#E33272' stopOpacity='0.65' />
          <stop offset='0.700045' stopColor='#5C0FAB' stopOpacity='0.4' />
        </radialGradient>
        <radialGradient
          id='paint15_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(0.0639939 125.984) rotate(-37.0178) scale(108.534 114.051)'
        >
          <stop offset='0.0916302' stopColor='#37FFDB' />
          <stop offset='0.225286' stopColor='#9AF9FF' />
          <stop offset='0.641005' stopColor='#C377FF' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint16_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(72.6228) rotate(82.6988) scale(64.515 20.7402)'
        >
          <stop stopColor='#51F5FF' />
          <stop offset='0.677663' stopColor='#8032E3' stopOpacity='0.28' />
          <stop offset='1' stopColor='#46008E' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint17_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(84.9452 95.6595) rotate(-96.194) scale(97.2038 111.381)'
        >
          <stop stopColor='#1C1159' /> <stop offset='0.0281713' stopColor='#5D09E1' />
          <stop offset='0.4487' stopColor='#E478FF' />
          <stop offset='0.6228' stopColor='#8479FF' />
          <stop offset='0.7685' stopColor='#8CF1FF' />
          <stop offset='0.8983' stopColor='#37FFDB' />
        </radialGradient>
        <linearGradient
          id='paint18_linear'
          x1='88.0008'
          y1='98.7508'
          x2='113.674'
          y2='65.0821'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0456257' stopColor='#0F0F13' stopOpacity='0.67' />
          <stop offset='0.321849' stopColor='#582C7A' stopOpacity='0' />
        </linearGradient>
        <radialGradient
          id='paint19_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(91.8089 106.953) rotate(-30.5883) scale(67.8842 128.806)'
        >
          <stop offset='0.1475' stopColor='#27043F' stopOpacity='0.12' />
          <stop offset='0.456553' stopColor='#DB3B80' stopOpacity='0.08' />
        </radialGradient>
        <radialGradient
          id='paint20_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(150.488 46.0025) rotate(135.945) scale(87.3222 91.7611)'
        >
          <stop offset='0.11701' stopColor='#27043F' stopOpacity='0' />
          <stop offset='0.524525' stopColor='#E33272' stopOpacity='0.65' />
          <stop offset='0.700045' stopColor='#5C0FAB' stopOpacity='0.4' />
        </radialGradient>
        <radialGradient
          id='paint21_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(74.4553 -0.972465) rotate(82.9822) scale(108.534 114.051)'
        >
          <stop offset='0.0916302' stopColor='#37FFDB' />
          <stop offset='0.225286' stopColor='#9AF9FF' />
          <stop offset='0.641005' stopColor='#C377FF' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint22_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(147.281 124.858) rotate(-157.301) scale(64.515 20.7402)'
        >
          <stop stopColor='#51F5FF' />
          <stop offset='0.677663' stopColor='#8032E3' stopOpacity='0.28' />
          <stop offset='1' stopColor='#46008E' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint23_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(58.1541 88.5244) rotate(23.806) scale(97.2038 111.381)'
        >
          <stop stopColor='#1C1159' /> <stop offset='0.0281713' stopColor='#5D09E1' />
          <stop offset='0.4487' stopColor='#E478FF' />
          <stop offset='0.6228' stopColor='#8479FF' />
          <stop offset='0.7685' stopColor='#8CF1FF' />
          <stop offset='0.8983' stopColor='#37FFDB' />
        </radialGradient>
        <linearGradient
          id='paint24_linear'
          x1='53.9492'
          y1='89.6249'
          x2='70.2705'
          y2='128.693'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0456257' stopColor='#0F0F13' stopOpacity='0.67' />
          <stop offset='0.321849' stopColor='#582C7A' stopOpacity='0' />
        </linearGradient>
        <radialGradient
          id='paint25_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(44.9417 88.8217) rotate(89.4117) scale(67.8841 128.806)'
        >
          <stop offset='0.1475' stopColor='#27043F' stopOpacity='0.12' />
          <stop offset='0.456553' stopColor='#DB3B80' stopOpacity='0.08' />
        </radialGradient>
        <radialGradient
          id='paint26_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(68.3869 170.115) rotate(-104.055) scale(87.3222 91.7611)'
        >
          <stop offset='0.11701' stopColor='#27043F' stopOpacity='0' />
          <stop offset='0.524525' stopColor='#E33272' stopOpacity='0.65' />
          <stop offset='0.700045' stopColor='#5C0FAB' stopOpacity='0.4' />
        </radialGradient>
        <radialGradient
          id='paint27_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(147.084 127.756) rotate(-157.018) scale(108.534 114.051)'
        >
          <stop offset='0.0916302' stopColor='#37FFDB' />
          <stop offset='0.225286' stopColor='#9AF9FF' />
          <stop offset='0.641005' stopColor='#C377FF' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint28_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(1.69977 127.91) rotate(-37.3012) scale(64.515 20.7402)'
        >
          <stop stopColor='#51F5FF' />
          <stop offset='0.677663' stopColor='#8032E3' stopOpacity='0.28' />
          <stop offset='1' stopColor='#46008E' stopOpacity='0' />
        </radialGradient>
        <clipPath id='clip0'>
          <rect width='147.592' height='128' fill='white' />
        </clipPath>
        <clipPath id='clip1'>
          <rect width='147.592' height='128' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
