import web3Service from '../../web3-service';
import ZEN_DAI_UNI_STAKING from '../../../blockchain/abi/ZEN_DAI_UNI_STAKING.json';
import config from '../../../config/env';

class ZenDaiUniStakingContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      ZEN_DAI_UNI_STAKING,
      config.REACT_APP_ZEN_DAI_UNI_STAKING_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  async perSecondReward() {
    return this.contract.methods.perSecondReward().call();
  }

  async totalSupply() {
    return this.contract.methods.totalSupply().call();
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }

  async claimedOf(address) {
    return this.contract.methods.claimedOf(address).call();
  }

  async rewardUnlockingTime(address) {
    return this.contract.methods.rewardUnlockingTime(address).call();
  }

  async earnedOf(address) {
    return this.contract.methods.earnedOf(address).call();
  }

  async rewardOf(address) {
    return this.contract.methods.rewardOf(address).call();
  }

  async stake(address, amount) {
    const options = { from: address };
    return this.contract.methods.stake(amount).send(options);
  }

  async unstake(address, amount) {
    const options = { from: address };
    return this.contract.methods.unstake(amount).send(options);
  }

  async claim(address, amount) {
    const options = { from: address };
    return this.contract.methods.claim(amount).send(options);
  }

  async withdraw(address, amount) {
    const options = { from: address };
    return this.contract.methods.withdraw(amount).send(options);
  }
}

export default new ZenDaiUniStakingContractService();
