import _ from 'lodash';
import {
  GET_OM_BALANCE,
  GET_OM_PRICE,
  GET_OM_V2_BALANCE,
  GET_OM_V2_PRICE,
  GET_OM_V3_BALANCE,
  GET_OM_V3_PRICE,
  GET_BNB_FINE_BALANCE,
  GET_BNB_FINE_PRICE,
  GET_BNB_MIST_BALANCE,
  GET_BNB_MIST_PRICE,
  GET_BNB_CBD_BALANCE,
  GET_BNB_CBD_PRICE,
  GET_BNB_OM_BALANCE,
  GET_BNB_OM_PRICE,
  GET_LABS_BALANCE,
  GET_LABS_PRICE,
  GET_RFUEL_BALANCE,
  GET_RFUEL_PRICE,
  GET_UNI_BALANCE,
  GET_UNI_PRICE,
  GET_DOT_BALANCE,
  GET_DOT_BALANCE_DATA,
  GET_KSM_BALANCE,
  GET_KSM_BALANCE_DATA,
  GET_UNI_V2_BALANCE,
  GET_ROYA_BALANCE,
  GET_ROYA_PRICE,
  GET_UNI_V2_PRICE,
  GET_LABS_UNI_SIMPLE_BALANCE,
  GET_LABS_UNI_SIMPLE_PRICE,
  GET_ROYA_UNI_BALANCE,
  GET_ROYA_UNI_PRICE,
  LOGOUT,
  GET_FXF_PRICE,
  GET_FXF_BALANCE,
  GET_LABS_UNI_BALANCE,
  GET_LABS_UNI_PRICE,
  GET_L3P_BALANCE,
  GET_L3P_PRICE,
  GET_PKF_BALANCE,
  GET_PKF_PRICE,
  GET_KYL_BALANCE,
  GET_KYL_PRICE,
  GET_RAZE_BALANCE,
  GET_RAZE_PRICE,
  GET_SRFUEL_BALANCE,
  GET_SRFUEL_PRICE,
  GET_BITE_BALANCE,
  GET_BITE_PRICE,
  GET_BCUBE_BALANCE,
  GET_BCUBE_PRICE,
  GET_SPWN_BALANCE,
  GET_SPWN_PRICE,
  GET_ALPHA_BALANCE,
  GET_ALPHA_PRICE,
  GET_BONDLY_ETH_BALANCE,
  GET_BONDLY_ETH_PRICE,
  GET_RAZE_ETH_UNI_BALANCE,
  GET_RAZE_ETH_UNI_PRICE,
  GET_ROSN_BALANCE,
  GET_ROSN_PRICE,
  GET_BONDLY_BSC_BALANCE,
  GET_BONDLY_BSC_PRICE,
  GET_BNB_BBANK_BALANCE,
  GET_BNB_BBANK_PRICE,
  GET_BNB_MLT_BALANCE,
  GET_BNB_MLT_PRICE,
  GET_BNB_ROSN_BALANCE,
  GET_BNB_ROSN_PRICE,
  GET_BBANK_ETH_UNI_BALANCE,
  GET_BBANK_ETH_UNI_PRICE,
  GET_BONDLY_ETH_UNI_BALANCE,
  GET_BONDLY_ETH_UNI_PRICE,
  GET_BCUBE_ETH_UNI_BALANCE,
  GET_BCUBE_ETH_UNI_PRICE,
  GET_BONDLY_WBNB_BALANCE,
  GET_BONDLY_WBNB_PRICE,
  GET_BONDLY_USDT_UNI_BALANCE,
  GET_BONDLY_USDT_UNI_PRICE,
  GET_BITE_ETH_UNI_BALANCE,
  GET_BITE_ETH_UNI_PRICE,
  GET_ZENUSDT_OM_ETH_BALANCE,
  GET_ZENUSDT_OM_ETH_PRICE,
  GET_BITE_ETH_INTERVALS_BALANCE,
  GET_BITE_ETH_INTERVALS_PRICE,
  GET_ALPHA_ETH_UNI_BALANCE,
  GET_ALPHA_ETH_UNI_PRICE,
  GET_LABS_GROUP_BALANCE,
  GET_LABS_GROUP_PRICE,
  GET_MLT_BALANCE,
  GET_MLT_PRICE,
  GET_OM_BSC_BALANCE,
  GET_OM_BSC_PRICE,
  GET_OM_POLYGON_BALANCE,
  GET_OM_POLYGON_PRICE,
  GET_ZEN_DAI_UNI_BALANCE,
  GET_ZEN_DAI_UNI_PRICE,
  GET_ZEN_UST_UNI_BALANCE,
  GET_ZEN_UST_UNI_PRICE,
  GET_ZENUSDC_UNI_ETH_BALANCE,
  GET_ZENUSDC_UNI_ETH_PRICE,
  GET_ZEN_WBTC_UNI_PRICE,
  GET_ZEN_WBTC_UNI_BALANCE,
  GET_ZEN_ETH_UNI_BALANCE,
  GET_ZEN_ETH_UNI_PRICE,
  GET_OM_ETH_POLYGON_PRICE,
  GET_OM_ETH_POLYGON_BALANCE,
  GET_CIRUS_BALANCE,
  GET_CIRUS_PRICE,
  GET_SKYRIM_BALANCE,
  GET_SKYRIM_PRICE,
  GET_GAMER_POLYGON_PRICE,
  GET_GAMER_POLYGON_BALANCE,
  GET_STFI_WBNB_UNI_BALANCE,
  GET_STFI_WBNB_UNI_PRICE,
  GET_GAMER_WETH_POLYGON_BALANCE,
  GET_GAMER_WETH_POLYGON_PRICE,
  GET_L3P_WBNB_UNI_BALANCE,
  GET_GAMER_WBNB_UNI_BALANCE,
  GET_FACTR_WBNB_UNI_BALANCE,
  GET_FACTR_BALANCE,
  GET_L3P_WBNB_UNI_PRICE,
  GET_FACTR_WBNB_UNI_PRICE,
  GET_GAMER_WBNB_UNI_PRICE,
} from '../actions/action-types';

const DEFAULT_FIELDS = {
  omBalance: null,
  omPrice: null,
  omV2Balance: null,
  omV2Price: null,
  omV3Balance: null,
  omV3Price: null,
  kylBalance: null,
  kylPrice: null,
  pkfBalance: null,
  pkfPrice: null,
  labsBalance: null,
  labsPrice: null,
  l3pBalance: null,
  l3pPrice: null,
  royaBalance: null,
  royaPrice: null,
  fxfBalance: null,
  fxfPrice: null,
  rfuelBalance: null,
  rfuelPrice: null,
  dotBalance: null,
  totalStakedValue: {},
  totalNominatedValue: {},
  uniBalance: null,
  uniPrice: null,
  uniV2Balance: null,
  uniV2Price: null,
  labsUniSimpleBalance: null,
  labsUniSimplePrice: null,
  royaUniBalance: null,
  royaUniPrice: null,
  razeEthUniBalance: null,
  razeEthUniPrice: null,
  bbankEthUniBalance: null,
  bbankEthUniPrice: null,
  bondlyEthUniBalance: null,
  bondlyEthUniPrice: null,
  biteEthUniBalance: null,
  biteEthUniPrice: null,
  zenUsdtOmEthBalance: null,
  zenUsdtOmEthPrice: null,
  zenUsdcUniEthBalance: null,
  zenUsdcUniEthPrice: null,
  bcubeEthUniBalance: null,
  bcubeEthUniPrice: null,
  alphaEthUniBalance: null,
  alphaEthUniPrice: null,
  biteEthIntervalsBalance: null,
  biteEthIntervalsPrice: null,
  bondlyUsdtUniBalance: null,
  bondlyUsdtUniPrice: null,
  labsUniBalance: null,
  labsUniPrice: null,
  bnbFineBalance: null,
  bnbFinePrice: null,
  bnbMistBalance: null,
  bnbMistPrice: null,
  bnbCbdBalance: null,
  bnbCbdPrice: null,
  bnbBbankBalance: null,
  bnbBbankPrice: null,
  bnbMltBalance: null,
  bnbMltPrice: null,
  bnbRosnBalance: null,
  bnbRosnPrice: null,
  bondlyWbnbBalance: null,
  bondlyWbnbPrice: null,
  bnbOmBalance: null,
  bnbOmPrice: null,
  razeBalance: null,
  razePrice: null,
  biteBalance: null,
  bitePrice: null,
  cirusBalance: null,
  cirusPrice: null,
  skyrimBalance: null,
  skyrimPrice: null,
  mltBalance: null,
  mltPrice: null,
  bcubeBalance: null,
  bcubePrice: null,
  spwnBalance: null,
  spwnPrice: null,
  labsGroupBalance: null,
  labsGroupPrice: null,
  alphaBalance: null,
  alphaPrice: null,
  bondlyEthBalance: null,
  bondlyEthPrice: null,
  bondlyBscBalance: null,
  bondlyBscPrice: null,
  omBscBalance: null,
  omBscPrice: null,
  omPolygonBalance: null,
  omPolygonPrice: null,
  sRfuelBalance: null,
  sRfuelPrice: null,
  zenDaiUniBalance: null,
  zenDaiUniPrice: null,
  zenWbtcUniBalance: null,
  zenWbtcUniPrice: null,
  zenEthUniBalance: null,
  zenEthUniPrice: null,
  gamerPolygonBalance: null,
  gamerPolygonPrice: null,
  gamerWethPolygonBalance: null,
  gamerWethPolygonPrice: null,
  stfiWbnbUniBalance: null,
  stfiWbnbUniPrice: null,
  l3pWbnbUniBalance: null,
  l3pWbnbUniPrice: null,
  factrWbnbUniBalance: null,
  factrWbnbUniPrice: null,
  factrBalance: null,
  gamerWbnbUniBalance: null,
  gamerWbnbUniPrice: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case GET_OM_BALANCE:
      return { ...state, omBalance: action.payload };
    case GET_OM_PRICE:
      return { ...state, omPrice: action.payload };
    case GET_OM_V2_BALANCE:
      return { ...state, omV2Balance: action.payload };
    case GET_OM_V2_PRICE:
      return { ...state, omPrice: action.payload };
    case GET_OM_V3_BALANCE:
      return { ...state, omV3Balance: action.payload };
    case GET_OM_V3_PRICE:
      return { ...state, omPrice: action.payload };
    case GET_RAZE_BALANCE:
      return { ...state, razeBalance: action.payload };
    case GET_RAZE_PRICE:
      return { ...state, razePrice: action.payload };
    case GET_BITE_BALANCE:
      return { ...state, biteBalance: action.payload };
    case GET_BITE_PRICE:
      return { ...state, bitePrice: action.payload };
    case GET_CIRUS_BALANCE:
      return { ...state, cirusBalance: action.payload };
    case GET_CIRUS_PRICE:
      return { ...state, cirusPrice: action.payload };
    case GET_SKYRIM_BALANCE:
      return { ...state, skyrimBalance: action.payload };
    case GET_SKYRIM_PRICE:
      return { ...state, skyrimPrice: action.payload };
    case GET_BCUBE_BALANCE:
      return { ...state, bcubeBalance: action.payload };
    case GET_BCUBE_PRICE:
      return { ...state, bcubePrice: action.payload };
    case GET_SPWN_BALANCE:
      return { ...state, spwnBalance: action.payload };
    case GET_SPWN_PRICE:
      return { ...state, spwnPrice: action.payload };
    case GET_LABS_GROUP_BALANCE:
      return { ...state, labsGroupBalance: action.payload };
    case GET_LABS_GROUP_PRICE:
      return { ...state, labsGroupPrice: action.payload };
    case GET_ALPHA_BALANCE:
      return { ...state, alphaBalance: action.payload };
    case GET_ALPHA_PRICE:
      return { ...state, alphaPrice: action.payload };
    case GET_BONDLY_ETH_BALANCE:
      return { ...state, bondlyEthBalance: action.payload };
    case GET_BONDLY_ETH_PRICE:
      return { ...state, bondlyEthPrice: action.payload };
    case GET_ROSN_BALANCE:
      return { ...state, rosnBalance: action.payload };
    case GET_ROSN_PRICE:
      return { ...state, rosnPrice: action.payload };
    case GET_BONDLY_BSC_BALANCE:
      return { ...state, bondlyBscBalance: action.payload };
    case GET_BONDLY_BSC_PRICE:
      return { ...state, bondlyBscPrice: action.payload };
    case GET_KYL_BALANCE:
      return { ...state, kylBalance: action.payload };
    case GET_KYL_PRICE:
      return { ...state, kylPrice: action.payload };
    case GET_PKF_BALANCE:
      return { ...state, pkfBalance: action.payload };
    case GET_PKF_PRICE:
      return { ...state, pkfPrice: action.payload };
    case GET_LABS_BALANCE:
      return { ...state, labsBalance: action.payload };
    case GET_LABS_PRICE:
      return { ...state, labsPrice: action.payload };
    case GET_L3P_BALANCE:
      return { ...state, l3pBalance: action.payload };
    case GET_L3P_PRICE:
      return { ...state, l3pPrice: action.payload };
    case GET_ROYA_BALANCE:
      return { ...state, royaBalance: action.payload };
    case GET_ROYA_PRICE:
      return { ...state, royaPrice: action.payload };
    case GET_FXF_BALANCE:
      return { ...state, fxfBalance: action.payload };
    case GET_FXF_PRICE:
      return { ...state, fxfPrice: action.payload };
    case GET_RFUEL_BALANCE:
      return { ...state, rfuelBalance: action.payload };
    case GET_RFUEL_PRICE:
      return { ...state, rfuelPrice: action.payload };
    case GET_UNI_BALANCE:
      return { ...state, uniBalance: action.payload };
    case GET_UNI_PRICE:
      return { ...state, uniPrice: action.payload };
    case GET_DOT_BALANCE:
      return { ...state, dotBalance: action.payload };
    case GET_DOT_BALANCE_DATA:
      return { ...state, dotData: action.payload };
    case GET_KSM_BALANCE:
      return { ...state, ksmBalance: action.payload };
    case GET_KSM_BALANCE_DATA:
      return { ...state, ksmData: action.payload };
    case GET_UNI_V2_BALANCE:
      return { ...state, uniV2Balance: action.payload };
    case GET_UNI_V2_PRICE:
      return { ...state, uniV2Price: action.payload };
    case GET_LABS_UNI_SIMPLE_BALANCE:
      return { ...state, labsUniSimpleBalance: action.payload };
    case GET_LABS_UNI_SIMPLE_PRICE:
      return { ...state, labsUniSimplePrice: action.payload };
    case GET_ROYA_UNI_BALANCE:
      return { ...state, royaUniBalance: action.payload };
    case GET_ROYA_UNI_PRICE:
      return { ...state, royaUniPrice: action.payload };
    case GET_RAZE_ETH_UNI_BALANCE:
      return { ...state, razeEthUniBalance: action.payload };
    case GET_RAZE_ETH_UNI_PRICE:
      return { ...state, razeEthUniPrice: action.payload };
    case GET_BBANK_ETH_UNI_BALANCE:
      return { ...state, bbankEthUniBalance: action.payload };
    case GET_BBANK_ETH_UNI_PRICE:
      return { ...state, bbankEthUniPrice: action.payload };
    case GET_BONDLY_ETH_UNI_BALANCE:
      return { ...state, bondlyEthUniBalance: action.payload };
    case GET_BONDLY_ETH_UNI_PRICE:
      return { ...state, bondlyEthUniPrice: action.payload };
    case GET_BCUBE_ETH_UNI_BALANCE:
      return { ...state, bcubeEthUniBalance: action.payload };
    case GET_BCUBE_ETH_UNI_PRICE:
      return { ...state, bcubeEthUniPrice: action.payload };
    case GET_ALPHA_ETH_UNI_BALANCE:
      return { ...state, alphaEthUniBalance: action.payload };
    case GET_ALPHA_ETH_UNI_PRICE:
      return { ...state, alphaEthUniPrice: action.payload };
    case GET_BITE_ETH_UNI_BALANCE:
      return { ...state, biteEthUniBalance: action.payload };
    case GET_BITE_ETH_UNI_PRICE:
      return { ...state, biteEthUniPrice: action.payload };
    case GET_ZENUSDT_OM_ETH_BALANCE:
      return { ...state, zenUsdtOmEthBalance: action.payload };
    case GET_ZENUSDT_OM_ETH_PRICE:
      return { ...state, zenUsdtOmEthPrice: action.payload };
    case GET_ZENUSDC_UNI_ETH_BALANCE:
      return { ...state, zenUsdcUniEthBalance: action.payload };
    case GET_ZENUSDC_UNI_ETH_PRICE:
      return { ...state, zenUsdcUniEthPrice: action.payload };
    case GET_BITE_ETH_INTERVALS_BALANCE:
      return { ...state, biteEthIntervalsBalance: action.payload };
    case GET_BITE_ETH_INTERVALS_PRICE:
      return { ...state, biteEthIntervalsPrice: action.payload };
    case GET_BONDLY_USDT_UNI_BALANCE:
      return { ...state, bondlyUsdtUniBalance: action.payload };
    case GET_BONDLY_USDT_UNI_PRICE:
      return { ...state, bondlyUsdtUniPrice: action.payload };
    case GET_LABS_UNI_BALANCE:
      return { ...state, labsUniBalance: action.payload };
    case GET_LABS_UNI_PRICE:
      return { ...state, labsUniPrice: action.payload };
    case GET_BNB_FINE_BALANCE:
      return { ...state, bnbFineBalance: action.payload };
    case GET_BNB_FINE_PRICE:
      return { ...state, bnbFinePrice: action.payload };
    case GET_BNB_MIST_BALANCE:
      return { ...state, bnbMistBalance: action.payload };
    case GET_BNB_MIST_PRICE:
      return { ...state, bnbMistPrice: action.payload };
    case GET_BNB_CBD_BALANCE:
      return { ...state, bnbCbdBalance: action.payload };
    case GET_BNB_CBD_PRICE:
      return { ...state, bnbCbdPrice: action.payload };
    case GET_BNB_BBANK_BALANCE:
      return { ...state, bnbBbankBalance: action.payload };
    case GET_BNB_BBANK_PRICE:
      return { ...state, bnbBbankPrice: action.payload };
    case GET_BNB_MLT_BALANCE:
      return { ...state, bnbMltBalance: action.payload };
    case GET_BNB_MLT_PRICE:
      return { ...state, bnbMltPrice: action.payload };
    case GET_BNB_ROSN_BALANCE:
      return { ...state, bnbRosnBalance: action.payload };
    case GET_BNB_ROSN_PRICE:
      return { ...state, bnbRosnPrice: action.payload };
    case GET_MLT_BALANCE:
      return { ...state, mltBalance: action.payload };
    case GET_MLT_PRICE:
      return { ...state, mltPrice: action.payload };
    case GET_BONDLY_WBNB_BALANCE:
      return { ...state, bondlyWbnbBalance: action.payload };
    case GET_BONDLY_WBNB_PRICE:
      return { ...state, bondlyWbnbPrice: action.payload };
    case GET_BNB_OM_BALANCE:
      return { ...state, bnbOmBalance: action.payload };
    case GET_BNB_OM_PRICE:
      return { ...state, bnbOmPrice: action.payload };
    case GET_OM_BSC_PRICE:
      return { ...state, omBscPrice: action.payload };
    case GET_OM_BSC_BALANCE:
      return { ...state, omBscBalance: action.payload };
    case GET_OM_POLYGON_PRICE:
      return { ...state, omPolygonPrice: action.payload };
    case GET_OM_POLYGON_BALANCE:
      return { ...state, omPolygonBalance: action.payload };
    case GET_OM_ETH_POLYGON_BALANCE:
      return { ...state, omEthPolygonBalance: action.payload };
    case GET_OM_ETH_POLYGON_PRICE:
      return { ...state, omEthPolygonPrice: action.payload };
    case GET_GAMER_POLYGON_BALANCE:
      return { ...state, gamerPolygonBalance: action.payload };
    case GET_GAMER_POLYGON_PRICE:
      return { ...state, gamerPolygonPrice: action.payload };
    case GET_GAMER_WETH_POLYGON_BALANCE:
      return { ...state, gamerWethPolygonBalance: action.payload };
    case GET_GAMER_WETH_POLYGON_PRICE:
      return { ...state, gamerWethPolygonPrice: action.payload };
    case GET_SRFUEL_BALANCE:
      return { ...state, sRfuelBalance: action.payload };
    case GET_SRFUEL_PRICE:
      return { ...state, sRfuelPrice: action.payload };
    case GET_ZEN_DAI_UNI_BALANCE:
      return { ...state, zenDaiUniBalance: action.payload };
    case GET_ZEN_DAI_UNI_PRICE:
      return { ...state, zenDaiUniPrice: action.payload };
    case GET_ZEN_UST_UNI_BALANCE:
      return { ...state, zenUstUniBalance: action.payload };
    case GET_ZEN_UST_UNI_PRICE:
      return { ...state, zenUstUniPrice: action.payload };
    case GET_ZEN_WBTC_UNI_BALANCE:
      return { ...state, zenWbtcUniBalance: action.payload };
    case GET_ZEN_WBTC_UNI_PRICE:
      return { ...state, zenWbtcUniPrice: action.payload };
    case GET_ZEN_ETH_UNI_BALANCE:
      return { ...state, zenEthUniBalance: action.payload };
    case GET_ZEN_ETH_UNI_PRICE:
      return { ...state, zenEthUniPrice: action.payload };
    case GET_STFI_WBNB_UNI_BALANCE:
      return { ...state, stfiWbnbUniBalance: action.payload };
    case GET_STFI_WBNB_UNI_PRICE:
      return { ...state, stfiWbnbUniPrice: action.payload };
    case GET_L3P_WBNB_UNI_BALANCE:
      return { ...state, l3pWbnbUniBalance: action.payload };
    case GET_L3P_WBNB_UNI_PRICE:
      return { ...state, l3pWbnbUniPrice: action.payload };
    case GET_FACTR_WBNB_UNI_BALANCE:
      return { ...state, factrWbnbUniBalance: action.payload };
    case GET_FACTR_WBNB_UNI_PRICE:
      return { ...state, factrWbnbUniPrice: action.payload };
    case GET_FACTR_BALANCE:
      return { ...state, factrBalance: action.payload };
    case GET_GAMER_WBNB_UNI_BALANCE:
      return { ...state, gamerWbnbUniBalance: action.payload };
    case GET_GAMER_WBNB_UNI_PRICE:
      return { ...state, gamerWbnbUniPrice: action.payload };
    case LOGOUT:
      return _.cloneDeep(DEFAULT_FIELDS);
    default:
      return state;
  }
};
