import Joi from 'joi';

import { Environment } from './env.types';

export default Joi.object<Environment>({
  NODE_ENV: Joi.string().allow('development', 'production'),
  REACT_APP_NETWORK: Joi.string().allow('mainnet', 'kovan'),
  REACT_APP_API_URL: Joi.string().required(),
  REACT_APP_LOCATION_URL: Joi.string().required(),
  REACT_APP_INFURA_PROJECT_ID: Joi.string().required(),
  REACT_APP_INFURA_RPC_URL: Joi.string().required(),
  REACT_APP_BSC_RPC_URL: Joi.string().required(),
  REACT_APP_BSC_URL: Joi.string().required(),
  REACT_APP_ZENTEREST_PRICEFEED_API_URL: Joi.string().required(),
  REACT_APP_ZENTEREST_LIQUIDATOR_API_URL: Joi.string().required(),
  REACT_APP_COINGECKO_API_URL: Joi.string().required(),
  REACT_APP_THEGRAPH_API_URL: Joi.string().required(),
  REACT_APP_THEGRAPH_API_REWARD_DELEGATOR_NAME: Joi.string().required(),
  REACT_APP_THEGRAPH_API_UNISWAP_V2_NAME: Joi.string().required(),
  REACT_APP_THEGRAPH_API_ZENTEREST_NAME: Joi.string().required(),
  REACT_APP_ETHERSCAN_URL: Joi.string().required(),
  REACT_APP_MANTRA_CDN_URL: Joi.string().required(),
  REACT_APP_KUSAMA_WSS_URL: Joi.string().required(),
  REACT_APP_POLKADOT_WSS_URL: Joi.string().required(),
  REACT_APP_OM_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_V2_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_V2_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_GRANTS_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_REWARDS_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_RESERVES_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_REFERRALS_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_TEAM_ADVISORS_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_PRIVATE_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_BUYBACK_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_V3_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_V3_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_FINE_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_FINE_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_OM_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_OM_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RAZE_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RAZE_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SRFUEL_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SRFUEL_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_CIRUS_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_CIRUS_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SKYRIM_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SKYRIM_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BCUBE_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BCUBE_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SPWN_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_SPWN_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_MLT_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_MLT_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_STAKING_ADDRESS_BSC: Joi.string().length(42).required(),
  REACT_APP_OM_ERC20_ADDRESS_BSC: Joi.string().length(42).required(),
  REACT_APP_OM_STAKING_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_OM_ERC20_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_OM_ETH_STAKING_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_OM_ETH_ERC20_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_GAMER_STAKING_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_GAMER_ERC20_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_GAMER_WETH_STAKING_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_GAMER_WETH_ERC20_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_STFI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_STFI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FACTR_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FACTR_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_ETH_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_ETH_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROSN_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROSN_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_BSC_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_BSC_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_KYL_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_KYL_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_PKF_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_PKF_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_L3P_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_L3P_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROYA_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROYA_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FXF_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FXF_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_STAKING_MIGRATION_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_OM_TOKEN_MIGRATION_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROYA_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ROYA_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RAZE_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RAZE_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BBANK_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BBANK_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BCUBE_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BCUBE_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_GROUP_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_GROUP_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ALPHA_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ALPHA_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ALPHA_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ALPHA_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZENUSDT_OM_ETH_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZENUSDT_OM_ETH_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZENUSDC_UNI_ETH_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZENUSDC_UNI_ETH_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_ETH_INTERVALS_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BITE_ETH_INTERVALS_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_USDT_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_USDT_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_MIST_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_MIST_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_CBD_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_CBD_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_BBANK_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_BBANK_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_MLT_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_MLT_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_ROSN_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BNB_ROSN_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_WBNB_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_BONDLY_WBNB_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RFUEL_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_RFUEL_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_UNI_V2_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_UNI_V2_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_UNI_SIMPLE_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_UNI_SIMPLE_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LABS_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_COMPTROLLER_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_COMPAUNDLENS_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_PRICE_ORACLE_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ETH_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_USDT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_USDC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_WBTC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_COMP_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_DAI_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_CREAM_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RFUEL_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_OM_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_DAI_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_DAI_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_UST_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_UST_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_WBTC_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_WBTC_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_ETH_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_ZEN_ETH_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_STFI_WBNB_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_STFI_WBNB_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_L3P_WBNB_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_L3P_WBNB_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FACTR_WBNB_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_FACTR_WBNB_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_GAMER_WBNB_UNI_ERC20_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_GAMER_WBNB_UNI_STAKING_ADDRESS: Joi.string().length(42).required(),
  /** @TODO Make this `required()` again  */
  REACT_APP_LENDING_ZEN_LINK_TOKEN_ADDRESS: Joi.string().allow(''),
  REACT_APP_LENDING_ZEN_AAVE_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_UNI_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_SUSHI_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_SNX_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_YFI_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_DSD_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_BONDLY_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_POLS_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_1INCH_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RSR_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ROYA_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_FTT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_SRM_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_BAL_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_CRV_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_UMA_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RUNE_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_FRAX_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_HEGIC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RHEGIC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_MPH_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ZLOT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ZHEGIC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_WHITE_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_WNXM_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RENBTC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_MKR_TOKEN_ADDRESS: Joi.string().allow(''),
  REACT_APP_LENDING_ZEN_BNT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_KNC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_CEL_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_CORN_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_API3_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_MATIC_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_BAO_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_UST_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_DVG_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_GRT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_0X_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_OMG_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_INJ_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_BADGER_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ROOK_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_UTK_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ALPHA_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_RGT_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_FXF_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_KYL_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_PAID_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_ENJ_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LENDING_ZEN_LABS_TOKEN_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LAUNCHPAD_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_LAUNCHPAD_BSC_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_MANTRA_POOL_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_MANTRA_POOL_ADDRESS_BSC: Joi.string().length(42).required(),
  REACT_APP_MANTRA_POOL_ADDRESS_POLYGON: Joi.string().length(42).required(),
  REACT_APP_POLKADOT_REWARD_CONTRACT_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_POLKADOT_LINK_CONTRACT_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_POLKADOT_VALIDATOR_ADDRESSES: Joi.string().required(),
  REACT_APP_KUSAMA_REWARD_CONTRACT_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_KUSAMA_LINK_CONTRACT_ADDRESS: Joi.string().length(42).required(),
  REACT_APP_KUSAMA_VALIDATOR_ADDRESSES: Joi.string().required(),
  REACT_APP_MATIC_MERKLE_AIRDROP_CONTRACT_ADDRESS: Joi.string().length(42).required(),
}).unknown();
