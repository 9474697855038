import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={400}
      height={228.125}
      viewBox='0 0 400 228.125'
      className={className}
      {...otherProps}
    >
      <g fillRule='evenodd'>
        <path
          d='M203.125 113.958v108.125h191.25V5.833h-191.25v108.125M67.083 69.583v2.709H98.75v88.75h5.624l.053-44.323.052-44.323 15.886-.054 15.885-.053v-5.414H67.083v2.708m266.413.052l-.058 2.761-15.886.053-15.885.054v88.747h-5.834V72.503l-15.885-.054-15.885-.053-.059-2.761-.058-2.76h69.608l-.058 2.76'
          fill='#fbfbfb'
        />
        <path
          d='M279.947 72.447l15.885.056.054 44.217.053 44.218-.001-44.271V72.396L280 72.394l-15.937-.002 15.884.055m21.608.105c-.055.143-.075 20.136-.045 44.427l.053 44.167.052-44.322.052-44.321 15.885-.055 15.886-.055-15.892-.051c-12.56-.04-15.913.004-15.991.21m-197.078 44.219c0 24.406.025 34.36.054 22.119.03-12.241.03-32.21 0-44.375-.029-12.166-.054-2.15-.054 22.256'
          fill='#4c4c4c'
        />
        <path
          d='M203.013 5.885c-.054.144-.075 48.87-.045 108.282l.053 108.021h191.458V5.729l-95.683-.052c-76.438-.042-95.704 0-95.783.208m191.362 108.073v108.125h-191.25V5.833h191.25v108.125M66.92 69.635l-.045 2.865 15.99-.051 15.989-.051-15.879-.054-15.88-.055-.064-2.759-.065-2.759-.046 2.864m197.12-.052c0 1.547.033 2.18.073 1.407.04-.774.04-2.039 0-2.813-.04-.773-.073-.141-.073 1.406m69.375 0c0 1.547.033 2.18.073 1.407.04-.774.04-2.039 0-2.813-.04-.773-.073-.141-.073 1.406m-220.967 2.865c4.383.031 11.555.031 15.937 0 4.383-.032.797-.057-7.968-.057-8.766 0-12.352.025-7.969.057m-12.405 88.748c.768.04 2.081.04 2.917.001.835-.04.207-.073-1.397-.073-1.605 0-2.288.032-1.52.072m197.197 0c.83.04 2.19.04 3.02 0 .831-.039.151-.072-1.51-.072s-2.341.033-1.51.072'
          fill='#8c8c8c'
        />
        <path
          d='M0 114.063v114.062h400V0H0v114.063m394.583-.105v108.334H202.917V5.625h191.666v108.333M136.25 69.688V72.5h-31.667v88.75H98.75V72.5H66.875v-5.625h69.375v2.813m127.917-.105v2.709h31.875v88.75h5.416v-88.75h31.875v-5.417h-69.166v2.708'
          fill='#141414'
        />
      </g>
    </svg>
  );
}
