import React from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { POOL_PROVABLY_MODAL } from '../../../constants/modal-constants';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import stylesModal from '../modal.module.scss';
import styles from './pool-provably-modal.module.scss';

const PoolProvablyModal = ({ show, roundIndex, seed }) => {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(stylesModal.dialog, stylesModal.poolResult, stylesModal.overflow, {
        [styles.dark]: dark,
      })}
    >
      <div className={cn(stylesModal.container, stylesModal.overflow)}>
        <div className={stylesModal.header}>
          <div className={stylesModal.headerTitle}>
            <span>PROVABLY FAIR LOTTERY</span>
          </div>
          <button
            className={stylesModal.close}
            onClick={() => dispatch(modalActions.hideModal(POOL_PROVABLY_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.heading}>
            <div className={styles.title}>POOL HASH FOR ROUND {+roundIndex + 1}</div>
            <div className={styles.hash}>{seed}</div>
          </div>
          <div>
            This lottery is provably fair. What that means is that there is no way the site can
            cheat you by picking a lottery winner who we favour. All winners are picked randomly and
            the method for picking the winners is described below.
            <br />
            1. At the beginning of each round, we generate a server seed for that round and show you
            the SHA256 hash of this seed.
            <br />
            2. When a lottery round has concluded, we make a list of all user id&apos;s and their
            lottery entries and sort it in ascending order of the user id&apos;s.
            <br />
            3. We then use this list to assign entry numbers for all entries. For example if user id
            1 has 10 entries and user id 2 has 20 entries, then user id 1 will have entry numbers 0
            to 9 and user id 2 will have entry numbers from 10 to 29. This is done for all users.
            <br />
            4. Then the list containing the user id&apos;s, entries held by each user and their
            entry numbers is published in a text file. This file also contains the total number of
            entries issuedTotal in the round and the server seed for that round.
            <br />
            5. A MD5 hash of this text file is calculated.
            <br />
            6. Then the current bitcoin block number is recorded and a block number that is 12
            blocks into the future is decided on. The block hash of this future block is used as a
            &quot;client seed&quot; ie. a seed that we are not aware of when the list in step 4 has
            been published.
            <br />
            7. Then a tweet is made via our twitter account (https://twitter.com/mantradao)
            containing the link to the file generated in step 4, the MD5 hash of the file and the
            future bitcoin block number as determined in step 6. Since tweets cannot be edited, only
            deleted, this will provide a timestamp for when the list in step 4 was made, which can
            be compared to check that the bitcoin block determined in step 6 is in the future ie. we
            do not know the block hash of this block when the list is generated.
            <br />
            8. The winning entry numbers will be calculated as described below:
            <br />
            o A string (STRING1) is created - &quot;[SERVER SEED]:[BLOCK HASH]:[WINNING
            POSITION]&quot;.
            <br />
            o The SHA256 hash of STRING1 is calculated and the first 8 characters of this hash are
            taken (STRING2).
            <br />
            o STRING2 is converted to a decimal which gives us a number between 0 and 4,294,967,295
            (NUM1).
            <br />
            o NUM1 is then multiplied by the (total number of entries minus 1) and divided by
            4,294,967,295 to get a number (NUM2) between 0 and the total number of entries less 1.
            <br />
            o NUM2 is then rounded off to the nearest whole number which is the winning entry
            number.
            <br />
            o The [WINNING POSITION] starts at 1 and is increased by 1 for picking the 10 winners.
            The user having the entry with the lowest [WINNING POSITION] wins the first prize and so
            on.
            <br />
            o If a user wins more than 1 prize, the number is discarded and [WINNING POSITION] is
            increased by 1 and the calculations are run again to pick a new user. This is to ensure
            that a user cannot win more than 1 prize in a round.
            <br />
            <br />
            HOW IS THE SYSTEM FAIR? The system described above that is used for picking the winners
            is provably fair because it has multiple safeguards in place to ensure that winners are
            picked randomly and fairly. 1. We provide a SHA256 hash of the server seed when a
            lottery round starts. If we change the server seed at any time, the SHA256 hash of the
            new seed will not match the hash we provided earlier. After a round has ended and we
            provide the server seed that will be used to pick winners, the hash of the seed we
            provide can be checked against the hash we provided originally and both should match. 2.
            We use a future bitcoin block hash as the &quot;client seed&quot; ie. a seed that we do
            not know of. All bitcoin block hashes are unique and nobody knows what the block hash of
            a future block will be until the block has been mined. 3. We use twitter to publish the
            future bitcoin block number, the list of users and their entry numbers and the MD5 hash
            of the user list. It provides a timestamp to check if the block hash that we use is
            indeed in the future. Since tweets cannot be edited, only deleted - if we delete the
            tweet after the future block has been mined and publish a new one, it would be easy to
            catch us cheating by comparing the timestamp of the tweet and that of the bitcoin block.
            The timestamp of the tweet should always be lesser than the time timestamp of the
            bitcoin block whose hash is used as the client seed. 4. We provide a MD5 hash of the
            text file containing the list of users and their entry numbers. If even a single
            character in the text file is changed after the MD5 hash in the tweet is published, the
            MD5 hash of the edited file will not match the MD5 hash provided in the tweet.
          </div>
        </div>
      </div>
    </Dialog>
  );
};

PoolProvablyModal.propTypes = {
  show: PropTypes.bool,
  roundIndex: PropTypes.string,
  seed: PropTypes.string,
};

PoolProvablyModal.defaultProps = {
  show: false,
  roundIndex: '',
  seed: '',
};

export default connectModal({ name: POOL_PROVABLY_MODAL })(PoolProvablyModal);
