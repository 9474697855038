import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='19'
      height='17'
      viewBox='0 0 19 17'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      className={className}
      {...otherProps}
    >
      <path
        d='M15.9041 8.81706H15.1692C15.0619 8.81706 14.9738 8.85145 14.9053 8.92053C14.8362 8.98921 14.8019 9.07724 14.8019 9.18444V12.8583C14.8019 13.3634 14.6221 13.7958 14.2624 14.1556C13.9027 14.5153 13.4702 14.695 12.965 14.695H3.41285C2.9077 14.695 2.47522 14.5153 2.11544 14.1556C1.75575 13.7958 1.57592 13.3635 1.57592 12.8583V3.30624C1.57592 2.8011 1.75571 2.36878 2.11544 2.00904C2.47522 1.64926 2.9077 1.46948 3.41285 1.46948L9.10739 1.46947C9.21471 1.46947 9.30278 1.43493 9.37158 1.36612C9.44038 1.29724 9.47477 1.2093 9.47477 1.10205V0.367177C9.47477 0.260132 9.44038 0.172027 9.37158 0.103265C9.30278 0.0343813 9.21471 0 9.10739 0L3.41285 6.09308e-06C2.50212 6.09308e-06 1.7233 0.323391 1.07644 0.970122C0.429831 1.61681 0.106445 2.39564 0.106445 3.30636V12.8584C0.106445 13.7691 0.429831 14.548 1.07648 15.1945C1.72334 15.8413 2.50216 16.1649 3.41289 16.1649H12.965C13.8757 16.1649 14.6546 15.8413 15.3014 15.1945C15.9482 14.548 16.2716 13.7691 16.2716 12.8584V9.18472C16.2716 9.0774 16.2371 8.98933 16.1681 8.92053C16.0992 8.85145 16.0111 8.81706 15.9041 8.81706Z'
        fill='#E22D73'
      ></path>
      <path
        d='M6.81687 10.5688C6.56579 10.8199 6.56579 11.227 6.81687 11.4781C7.06795 11.7291 7.47502 11.7291 7.7261 11.4781L6.81687 10.5688ZM18.2012 0.736647C18.2012 0.381569 17.9134 0.0937222 17.5583 0.0937223L11.772 0.0937224C11.4169 0.0937224 11.129 0.38157 11.129 0.736647C11.129 1.09172 11.4169 1.37957 11.772 1.37957L16.9154 1.37957L16.9154 6.52297C16.9154 6.87804 17.2032 7.16589 17.5583 7.16589C17.9134 7.16589 18.2012 6.87804 18.2012 6.52297L18.2012 0.736647ZM7.7261 11.4781L18.0129 1.19126L17.1037 0.282031L6.81687 10.5688L7.7261 11.4781Z'
        fill='#E22D73'
      ></path>
    </svg>
  );
}
