import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 51 51'
      className={className}
      {...otherProps}
    >
      <path
        fill='#000'
        d='M26.275 18.114c1.707 1.966 1.967 7.094 1.967 7.094-1.088 5.227-2.635 8.488-5.383 8.384-2.747-.103-3.984-6.158-3.26-11.436.725-5.278 4.968-6.01 6.676-4.042z'
      ></path>
      <path
        fill='#000'
        d='M25.5 0C11.416 0 0 11.416 0 25.5S11.416 51 25.5 51 51 39.583 51 25.5C51 11.418 39.583 0 25.5 0zm14.797 39.124s-3.26.569-4.865 0c-1.604-.568-3.881-2.533-3.881-2.533s-1.398 2.266-3.209 2.789c-1.81.523-7.555.575-9.574.213-2.019-.363-6.534-1.926-7.762-9.885-1.176-7.661-.385-14.06 6.006-17.086 6.054-2.867 11.747-1.237 14.593 1.61.055-.144.517-1.67.842-2.38l7.645.076-4.662 13.277s.232 6.107 1.371 7.53c1.14 1.422 3.494 1.086 3.494 1.086l.002 5.303z'
      ></path>
      <path
        fill='#fff'
        d='M28.242 25.202c-1.088 5.228-2.635 8.488-5.383 8.385-2.747-.104-3.984-6.16-3.26-11.437.725-5.278 4.966-6.004 6.676-4.037 1.71 1.967 1.967 7.09 1.967 7.09z'
      ></path>
      <path
        fill='#000'
        d='M28.242 25.202c-1.088 5.228-2.635 8.488-5.383 8.385-2.747-.104-3.984-6.16-3.26-11.437.725-5.278 4.966-6.004 6.676-4.037 1.71 1.967 1.967 7.09 1.967 7.09z'
      ></path>
    </svg>
  );
}
