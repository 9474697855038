import styled from 'styled-components';

import Text from '../Text/Text';

const StyledBannerWrapper = styled.div`
  background: #ae528b;

  padding-right: 1.5rem;
  @media screen and (min-width: 576px) {
  }
`;

export function BetaBanner() {
  return (
    <StyledBannerWrapper>
      <div className='container'>
        <div className='col-md-12'>
          <div className='flex justify-space-between'>
            <Text style={{ margin: 0, marginRight: '1rem' }} dark={true} size='sm'>
              You are using the new interface of MANTRA DAO staking. You can still visit the{' '}
              <a
                href='https://legacy.mantradao.com/staking'
                style={{ color: '#fff' }}
                rel='noopener noreferrer'
                target='_blank'
              >
                legacy version
              </a>{' '}
              of staking.
            </Text>
          </div>
        </div>
      </div>
    </StyledBannerWrapper>
  );
}
