import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        d='M22.7299 41.0164C32.829 41.0164 41.0159 32.8295 41.0159 22.7304C41.0159 12.6313 32.829 4.44434 22.7299 4.44434C12.6308 4.44434 4.44385 12.6313 4.44385 22.7304C4.44385 32.8295 12.6308 41.0164 22.7299 41.0164Z'
        fill='#0F2F31'
      />
      <g filter='url(#plk_start_filter0_f)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.4962 31.8673C27.6716 31.8673 31.8671 27.6717 31.8671 22.4963C31.8671 17.3209 27.6716 13.1253 22.4962 13.1253C17.3207 13.1253 13.1252 17.3209 13.1252 22.4963C13.1252 27.6717 17.3207 31.8673 22.4962 31.8673ZM22.4962 34.7768C29.2785 34.7768 34.7767 29.2787 34.7767 22.4963C34.7767 15.714 29.2785 10.2158 22.4962 10.2158C15.7139 10.2158 10.2157 15.714 10.2157 22.4963C10.2157 29.2787 15.7139 34.7768 22.4962 34.7768Z'
          fill='url(#polka-starter-paint0_linear)'
        />
      </g>
      <g filter='url(#plkastarter-filter1_f)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.4962 33.322C28.4751 33.322 33.3219 28.4752 33.3219 22.4963C33.3219 16.5174 28.4751 11.6706 22.4962 11.6706C16.5173 11.6706 11.6705 16.5174 11.6705 22.4963C11.6705 28.4752 16.5173 33.322 22.4962 33.322ZM22.4962 34.7768C29.2785 34.7768 34.7767 29.2787 34.7767 22.4963C34.7767 15.714 29.2785 10.2158 22.4962 10.2158C15.7139 10.2158 10.2157 15.714 10.2157 22.4963C10.2157 29.2787 15.7139 34.7768 22.4962 34.7768Z'
          fill='url(#polka-starter-paint1_linear)'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9789 34.0768C29.1184 34.0768 34.0955 29.0998 34.0955 22.9602C34.0955 16.8206 29.1184 11.8435 22.9789 11.8435C16.8393 11.8435 11.8622 16.8206 11.8622 22.9602C11.8622 29.0998 16.8393 34.0768 22.9789 34.0768ZM22.9789 35.2406C29.7612 35.2406 35.2593 29.7425 35.2593 22.9602C35.2593 16.1779 29.7612 10.6797 22.9789 10.6797C16.1965 10.6797 10.6984 16.1779 10.6984 22.9602C10.6984 29.7425 16.1965 35.2406 22.9789 35.2406Z'
        fill='url(#polka-starter-paint2_linear)'
      />
      <g filter='url(#plk_starter_filter2_f)'>
        <path
          d='M14.903 34.6227C16.7378 34.6227 18.2251 33.1353 18.2251 31.3006C18.2251 29.4659 16.7378 27.9785 14.903 27.9785C13.0683 27.9785 11.5809 29.4659 11.5809 31.3006C11.5809 33.1353 13.0683 34.6227 14.903 34.6227Z'
          fill='black'
        />
      </g>
      <path
        d='M14.9052 34.3648C16.5964 34.3648 17.9675 32.9938 17.9675 31.3025C17.9675 29.6113 16.5964 28.2402 14.9052 28.2402C13.2139 28.2402 11.8429 29.6113 11.8429 31.3025C11.8429 32.9938 13.2139 34.3648 14.9052 34.3648Z'
        fill='white'
      />
      <g filter='url(#plk_startert_filter3_f)'>
        <path
          d='M31.3401 18.1152C33.1015 18.1152 34.5295 16.6872 34.5295 14.9258C34.5295 13.1643 33.1015 11.7363 31.3401 11.7363C29.5786 11.7363 28.1506 13.1643 28.1506 14.9258C28.1506 16.6872 29.5786 18.1152 31.3401 18.1152Z'
          fill='#00EFFF'
        />
      </g>
      <path
        d='M31.3379 18.017C33.0292 18.017 34.4002 16.6302 34.4002 14.9196C34.4002 13.209 33.0292 11.8223 31.3379 11.8223C29.6467 11.8223 28.2756 13.209 28.2756 14.9196C28.2756 16.6302 29.6467 18.017 31.3379 18.017Z'
        fill='#102F31'
      />
      <defs>
        <filter
          id='plk_start_filter0_f'
          x='3.43323e-05'
          y='0.000156403'
          width='44.9923'
          height='44.9923'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='5.10783' result='effect1_foregroundBlur' />
        </filter>
        <filter
          id='plkastarter-filter1_f'
          x='5.61865'
          y='5.61877'
          width='33.7551'
          height='33.7551'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='2.29852' result='effect1_foregroundBlur' />
        </filter>
        <filter
          id='plk_starter_filter2_f'
          x='10.0486'
          y='26.4462'
          width='9.70887'
          height='9.70887'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='0.766175' result='effect1_foregroundBlur' />
        </filter>
        <filter
          id='plk_startert_filter3_f'
          x='26.1075'
          y='9.6932'
          width='10.4651'
          height='10.4651'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.02157' result='effect1_foregroundBlur' />
        </filter>
        <linearGradient
          id='polka-starter-paint0_linear'
          x1='22.4962'
          y1='10.2158'
          x2='22.4962'
          y2='34.7768'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.130208' stopColor='#73FFF2' />
          <stop offset='1' stopColor='#01A9B4' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='polka-starter-paint1_linear'
          x1='22.4962'
          y1='10.2158'
          x2='22.4962'
          y2='34.7768'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#42FFEE' />
          <stop offset='1' stopColor='#01A9B4' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='polka-starter-paint2_linear'
          x1='22.9789'
          y1='10.6797'
          x2='22.9789'
          y2='35.2406'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#04FFEA' />
          <stop offset='1' stopColor='#01A9B4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
