import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@material-ui/core';
import styles from './second-step.module.scss';
import stylesModal from '../../modal.module.scss';
import Checkbox from '../../../checkbox';

const Details = ({ amount, onSubmit }) => {
  const { currentRound } = useSelector((state) => state.mantraPool);
  const dark = useSelector((state) => state.basic.dark);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={cn(styles.body, { [styles.dark]: dark })}>
      <div className={styles.topBlock}>
        <div className={styles.title}>Round: {currentRound + 1}</div>
        <div className={styles.row}>
          <div className={styles.rowLabel}>Adding POOL Entries</div>
          <div className={styles.rowValue}>{amount}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowLabel}>Required OM Tokens</div>
          <div className={styles.rowValue}>{amount}</div>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={isChecked}
            className={styles.checkbox}
            onChange={() => setIsChecked(!isChecked)}
          >
            <div className={styles.checkboxLabel}>
              I understand that my OM tokens will be spent for entries into the pool, and
              transferred to a smart treasury wallet, and will not be returned to me
            </div>
          </Checkbox>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={cn(stylesModal.button, styles.button)}
            variant='contained'
            onClick={() => onSubmit()}
            color='secondary'
            disabled={!isChecked}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
};

export default Details;
