import * as React from 'react';

function SvgKsmIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <path
        d='M38.017 19.154c-.115.092-.143.138-.186.153-1.625.445-2.538 1.611-2.943 3.3-.07.291-.212.567-.296.86-.421 1.411-1.264 2.424-2.468 3.13-.876.506-1.78.982-2.655 1.504-1.491.86-1.683 2.18-.477 3.469.48.506 1.13.828 1.696 1.243.159.107.318.2.564.368h-2.881c.114-.169.242-.338.442-.614-.465-.399-.986-.736-1.379-1.197-.378-.445-.641-.998-1.021-1.627-.332-.03-.822-.092-1.442-.153.258-.154.416-.246.674-.415-1.183-.2-2.19.016-3.153.323-2.314.736-4.584 1.58-6.899 2.317-.776.246-1.597.322-2.402.476-.03-.077-.045-.138-.074-.215.257-.245.515-.476.801-.752-.159-.046-.303-.092-.505-.154 2.482-1.38 4.892-2.7 7.303-4.036l-.001-.107h-1.21c.013-.138.012-.2.027-.215a2990.9 2990.9 0 018.959-6.016c.258-.17.545-.323.832-.445 1.048-.43 1.937-1.075 2.737-1.903.43-.43.945-.783 1.462-1.09.874-.537 1.754-.46 2.566.169.652.475 1.247 1.043 1.929 1.627z'
        fill='#303030'
      />
    </svg>
  );
}

export default SvgKsmIcon;
