import { toast } from 'react-toastify';

/**
 * Toast about successful event
 * @param {string} text
 */
export const toastSuccess = (text) => {
  toast.success(text, {
    autoClose: 3000,
  });
};

/**
 * Toast with information message
 * @param {string} text
 */
export const toastInfo = (text) => {
  toast.info(text, {
    autoClose: 3000,
  });
};

/**
 * Toast about unsuccessful event
 * @param {string} text
 */
export const toastError = (text) => {
  toast.error(text, {
    autoClose: 3000,
  });
};
