import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { connectModal } from 'redux-modal';
import Notice from '../../../assets/images/geo-blocker-modal-logo.svg';
import { BLACKLISTED_MODAL } from '../../../constants/modal-constants';
import s from './blacklisted-modal.module.scss';

const BlacklistedModal = ({ show }) => {
  return (
    <Dialog open={show} className={s.modal}>
      <div className={s.container}>
        <img alt='' src={Notice} />
        <h1 className={s.title}>Notice</h1>
        <p className={s.text}>
          The products and services on this website are not intended for individuals in your
          country. Nothing on this website is intended to be constructed as a solicitation of any
          individual in your country.
        </p>
      </div>
    </Dialog>
  );
};

BlacklistedModal.propTypes = {
  show: PropTypes.bool,
};

BlacklistedModal.defaultProps = {
  show: false,
};

export default connectModal({ name: BLACKLISTED_MODAL })(BlacklistedModal);
