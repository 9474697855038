import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import leaveSite from '../../assets/images/leaveSite.png';
import networkHelpers from '../../helpers/network.helpers';
import networkConstants from '../../constants/network.constant';
import config from '../../config/env';

import styles from './txID.module.scss';

const TxID = ({ className, id }) => {
  const dark = useSelector((state) => state.basic.dark);
  const { networkId } = useSelector((state) => state.account);
  const networkType = networkHelpers.findNetworkType(networkId);

  const getLinkHref = () => {
    switch (networkType) {
      case networkConstants.networkType.binance:
        return config.REACT_APP_BSC_URL;
      case networkConstants.networkType.polygon:
        return config.REACT_APP_POLYGON_URL;
      default:
        return config.REACT_APP_ETHERSCAN_URL;
    }
  };

  return (
    <div className={cn(styles.container, className, { [styles.dark]: dark })}>
      <a
        className={styles.txID}
        target='_blank'
        rel='noopener noreferrer'
        href={`${getLinkHref()}/tx/${id}`}
      >
        <b>TX: </b>
        <span>
          {id.slice(0, 6)}...{id.slice(50, 55)}
        </span>
        <img className={styles.icon} src={leaveSite} alt='' />
      </a>
    </div>
  );
};

TxID.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TxID.defaultProps = {
  className: '',
};

export default TxID;
