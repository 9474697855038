import React from 'react';
import PropTypes from 'prop-types';
import { showBN } from '../../../../helpers/lending-helper';
import Supply from '../supply';
import Withdraw from '../withdraw';
import s from '../supply-modal.module.scss';

const Tabs = ({
  isTokenEnabled,
  disabledSupply,
  disabledWithdraw,
  token,
  activeIndex,
  loading,
  newBorrowLimit,
  inputValue,
  setError,
  setDisabledSupply,
  setIsTokenEnabled,
  borrowLimitUsd,
  borrowLimitPercent,
  setLoading,
  maxWithdrawalAmount,
  isMax,
}) => {
  const { supplyDistributionAPY, supplyAPY, underlyingIcon, isPaused } = token;
  const { withdraw: withdrawNewBorrowLimit, supply: supplyNewBorrowLimit } = newBorrowLimit;

  return (
    <div className={s.footer}>
      {activeIndex === 0 && (
        <Supply
          isEnabled={isTokenEnabled}
          token={token}
          disabled={disabledSupply || loading}
          setLoading={setLoading}
          inputValue={inputValue}
          setIsTokenEnabled={setIsTokenEnabled}
          setError={setError}
          setDisabledSupply={setDisabledSupply}
          tokenIcon={underlyingIcon}
          supplyAPY={showBN(supplyAPY, 2)}
          distributionAPY={showBN(supplyDistributionAPY, 2)}
          borrowLimitUsd={borrowLimitUsd}
          borrowLimitPercent={borrowLimitPercent}
          newBorrowLimitUsd={
            supplyNewBorrowLimit?.newBorrowLimitUsd &&
            showBN(supplyNewBorrowLimit.newBorrowLimitUsd, 2)
          }
          newBorrowLimitPercent={
            supplyNewBorrowLimit?.newBorrowLimitPercent &&
            showBN(supplyNewBorrowLimit.newBorrowLimitPercent, 2)
          }
          isPaused={isPaused}
        />
      )}
      {activeIndex === 1 && (
        <Withdraw
          isMax={isMax}
          token={token}
          disabled={disabledWithdraw || loading}
          tokenIcon={underlyingIcon}
          setError={setError}
          setLoading={setLoading}
          supplyAPY={showBN(supplyAPY, 2)}
          inputValue={inputValue}
          maxWithdrawalAmount={maxWithdrawalAmount}
          borrowLimitUsd={borrowLimitUsd}
          newBorrowLimitUsd={
            withdrawNewBorrowLimit?.newBorrowLimitUsd &&
            showBN(withdrawNewBorrowLimit.newBorrowLimitUsd, 2)
          }
          borrowLimitPercent={borrowLimitPercent}
          newBorrowLimitPercent={
            withdrawNewBorrowLimit?.newBorrowLimitPercent &&
            showBN(withdrawNewBorrowLimit.newBorrowLimitPercent, 2)
          }
          isPaused={isPaused}
        />
      )}
    </div>
  );
};

Tabs.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  isTokenEnabled: PropTypes.bool.isRequired,
  disabledSupply: PropTypes.bool.isRequired,
  disabledWithdraw: PropTypes.bool.isRequired,
  newBorrowLimit: PropTypes.object.isRequired,
  setIsTokenEnabled: PropTypes.func.isRequired,
  setDisabledSupply: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  isMax: PropTypes.bool,
  token: PropTypes.object,
  borrowLimitUsd: PropTypes.string,
  borrowLimitPercent: PropTypes.string,
  maxWithdrawalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tabs.defaultProps = {
  token: {},
  isMax: false,
  loading: false,
  borrowLimitUsd: '0',
  borrowLimitPercent: '0',
  inputValue: '',
  maxWithdrawalAmount: 0,
};

export default Tabs;
