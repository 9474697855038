import { show, hide } from 'redux-modal';

export const showModal =
  (name, params = {}) =>
  (dispatch) => {
    dispatch(show(name, params));
  };

export const hideModal = (name) => (dispatch) => {
  dispatch(hide(name));
};
