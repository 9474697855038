import axios from 'axios';
import qs from 'qs';
import config from '../../config/env';
import networkConstant from '../../constants/network.constant';
import { NetworkId } from '../../types/network.types';
import { DelegatorChainIds } from './delegator.data';
import { DelegatorLinks, RewardDataType } from './delegator.types';

type Link = {
  ethereumAddress: string;
};

function getDelegatorSubgraphUrl(networkId: NetworkId) {
  switch (networkId) {
    case networkConstant.networkId.polyTestnet:
      return 'https://api.thegraph.com/subgraphs/name/mantradao/delegator-rewards-polygon-mumbai';
    case networkConstant.networkId.polyMainnet:
      return 'https://api.thegraph.com/subgraphs/name/mantradao/delegator-rewards-polygon';
    default:
      return 'https://api.thegraph.com/subgraphs/name/mantradao/delegator-rewards-polygon-mumbai';
  }
}

function getRewardsFileName(chainName: DelegatorChainIds) {
  switch (chainName) {
    case DelegatorChainIds.ATOM:
      return 'rewards-stats-cosmos-2023-1.json';
    case DelegatorChainIds.MATIC:
    case DelegatorChainIds.MUMBAI:
      return 'reward-stats-polygon-2022-7.json';

    default:
      return 'rewards-stats-cosmos-2022-2.json';
  }
}

export default {
  listDelegators: async function (
    addressEth: string | null,
    addressOther: string | null,
    chainId: number,
  ): Promise<DelegatorLinks> {
    const queryParams = qs.stringify(
      {
        addressEth,
        addressOther,
        chainId,
      },
      { skipNulls: true },
    );

    const { data: axiosResult } = await axios.get(
      `${config.REACT_APP_API_URL}/v1/delegator/list?${queryParams}`,
    );
    const { data: delegators } = axiosResult;
    return {
      delegators,
    };
  },

  pollDelegatorStatus: async function (
    addressEth: string,
    addressOther: string,
    chainId: number,
  ): Promise<DelegatorLinks> {
    const queryParams = qs.stringify({
      addressEth,
      addressOther,
      chainId,
    });

    const { data: axiosResult } = await axios.get(
      `${config.REACT_APP_API_URL}/v1/delegator/pollStatus?${queryParams}`,
    );
    const { data: delegators } = axiosResult;
    return {
      delegators,
    };
  },

  createLink: async function (
    address: string,
    addressOther: string,
    chainId: number,
  ): Promise<any> {
    const { data: axiosResult } = await axios.post(
      `${config.REACT_APP_API_URL}/v1/delegator/create`,
      {
        body: {
          address_eth: address,
          address_other: addressOther,
          chain_id: chainId,
        },
      },
    );
    const { data: messageId } = axiosResult;
    return {
      messageId,
    };
  },

  userRate: async function (addressOther: string, chainId: number): Promise<any> {
    const queryParams = qs.stringify({
      chainId,
      userAddress: addressOther,
    });

    const { data: axiosResult } = await axios.get(
      `${config.REACT_APP_API_URL}/v1/delegator/userRate?${queryParams}`,
    );
    const { data: userRate } = axiosResult;
    return {
      userRate,
    };
  },

  fetchRewards: async (chainName: DelegatorChainIds): Promise<RewardDataType> => {
    const fileName = getRewardsFileName(chainName);
    const result = await axios.get(`${config.REACT_APP_MANTRA_CDN_URL}/${fileName}`);
    const data: RewardDataType = result.data;

    return data;
  },
  getLink: async (walletAddress: string, networkId: NetworkId) => {
    const query = `query {
      links( where: { ethereumAddress:"${walletAddress}" }) {
        ethereumAddress
      }
    }`;

    const DELEGATOR_SUBGRAPH_URL = getDelegatorSubgraphUrl(networkId);

    const result = await axios({
      url: DELEGATOR_SUBGRAPH_URL,
      method: 'POST',
      data: { query },
    });

    return result.data.data.links as Link[];
  },
  getLinks: async (networkId: NetworkId) => {
    const DELEGATOR_SUBGRAPH_URL = getDelegatorSubgraphUrl(networkId);

    const query = `query {
      links {
        ethereumAddress
      }
    }`;

    const result = await axios({
      url: DELEGATOR_SUBGRAPH_URL,
      method: 'POST',
      data: { query },
    });

    return result.data.data.links as Link[];
  },
  getLinkedDelegatd: async (addresses: string[]) => {
    const URL = 'https://api.thegraph.com/subgraphs/name/mantradao/polygon-delegators';

    const query = `{
      delegators(
        where: {
          address_in: ${JSON.stringify(addresses)}
          validatorId: 43 
          amount_gt: 0
        }
        first: 1000
      ) {
        amount
      }
    }`;

    const result = await axios({
      url: URL,
      method: 'POST',
      data: { query },
    });

    return result.data.data.delegators as { amount: string }[];
  },
};
