import { Form, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import Button from '../../../../external/components/Button/Button';
import Icon from '../../../../external/components/Icon/Icon';
import { TextInput } from '../../../../external/components/Input/Input';
import Text from '../../../../external/components/Text/Text';
import { Store } from '../../../../store/store.types';
import { useDelegatorCreateHook } from '../../hooks/delegator.hook';
import { EthAddressFormType } from '../../types/form.types';
import { ButtonContainer, StyledLabel, StyledText } from '../Form/_FormComponents';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  otherWalletAddress: string;
  chainId: number;
}

const LinkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const initialValues = {
  ethAddress: '',
  blockChain: [],
};

const validationSchema = yup.object().shape({
  // ethAddress: yup.string().required('Enter an address').min(10, 'Enter a valid ethereum address'),
});

export function WalletLinkBlockChain({ otherWalletAddress, setScreen, chainId }: Props) {
  const { address: walletAddress } = useSelector(({ account }: Store) => account);

  const [createLink, loading, error, messageId] = useDelegatorCreateHook(chainId);

  const ethFieldRef = useRef<HTMLInputElement>(null);

  const handleLink = async ({ values, setSubmitting }: EthAddressFormType) => {
    setSubmitting(true);

    await createLink(walletAddress, otherWalletAddress);

    //setSubmitting(false);
  };

  useEffect(() => {
    if (messageId) {
      if (messageId !== '') {
        setScreen(4);
      }
    }
  }, [messageId]);

  return (
    <>
      <StyledLabel htmlFor='walletAddress'>
        <Text dark={true} as='span'>
          Link your COSMOS delegator wallet
        </Text>
      </StyledLabel>
      <TextInput
        id='walletAddress'
        darkAlt={true}
        defaultValue={otherWalletAddress}
        disabled={true}
      ></TextInput>
      <LinkIconWrapper>
        <Icon type='linkIcon' width={50} />
      </LinkIconWrapper>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleLink({ values, setSubmitting });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          isSubmitting,
          isValidating,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form
            id='link-eth-form'
            noValidate
            onSubmit={(e) => {
              handleSubmit(e);

              if (Object.keys(errors).length < 1) return;
            }}
          >
            <StyledLabel htmlFor='walletAddress'>
              <Text dark={true} as='span'>
                Link to Ethereum Address
              </Text>
            </StyledLabel>
            <TextInput
              id='ethAddress'
              defaultValue={walletAddress}
              darkAlt={true}
              disabled={true}
            ></TextInput>
            {errors.ethAddress && !isValidating && (
              <StyledText dark={true} size='sm'>
                <Icon width={10} height={10} type='pinkCircle' />
                {errors.ethAddress}
              </StyledText>
            )}
            <br />
            <ButtonContainer>
              <Button
                size='medium'
                type='submit'
                disabled={isSubmitting}
                dark={true}
                onClick={handleSubmit}
              >
                Link Addresses
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <Text color='grey'>
        To complete the link you will need ATOM and BNB for the cross chain linking process.
      </Text>
    </>
  );
}
