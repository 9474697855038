import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connectModal } from 'redux-modal';
import BN from 'bignumber.js';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import BalanceBlock from '../../../screens/lending/blocks-for-lending-modals/balance-block';
import TokenLine from '../../../screens/lending/blocks-for-lending-modals/token-column/token-line';
import InputWithButton from '../../../screens/lending/blocks-for-lending-modals/input-with-button';
import Borrow from './borrow';
import Repay from './repay';
import Loader from '../../loader';
// import Label from '../../../screens/lending/blocks-for-lending-modals/label';
import { BORROW_MODAL } from '../../../constants/modal-constants';
import * as modalActions from '../../../actions/modal-actions';
import * as lendingActions from '../../../actions/lending-actions';
import * as lendingBorrowActions from '../../../actions/lending-borrow-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from './borrow-modal.module.scss';
import { showBN } from '../../../helpers/lending-helper';
import { toBN } from '../../../helpers/token-helper';

const tabs = ['Borrow', 'Repay'];

const BorrowModal = ({ show, token, borrowLimitPercent }) => {
  const {
    tokenBalance,
    tokenBalanceUsd,
    underlyingIcon,
    underlyingSymbol,
    isEnabled,
    underlyingDecimals,
    underlyingBorrowBalance,
    underlyingBorrowBalanceUsd,
    zenTokenAddress,
    borrowAPY,
    borrowDistributionAPY,
    isPaused,
  } = token;

  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);
  const [balanceTitle, setBalanceTitle] = useState('Wallet Balance');
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [disabledRepay, setDisabledRepay] = useState(isEnabled);
  const [disabledBorrow, setDisabledBorrow] = useState(true);
  const [isTokenEnabled, setisTokenEnabled] = useState(isEnabled);
  const [isMaxRepay, setIsMaxRepay] = useState(false);
  const [title, setTitle] = useState('Borrow');
  const [maxInputValue, setMaxInputValue] = useState(new BN(0));
  const [newBorrowLimits, setNewBorrowLimits] = useState({
    newBorrowBalanceUsd: null,
    newBorrowLimitPercent: null,
  });

  useEffect(() => {
    if (zenTokenAddress) {
      setMaxInputValue(dispatch(lendingBorrowActions.getMaxBorrowAmount(zenTokenAddress)));
    }
  }, [dispatch, zenTokenAddress]);

  const updateBorroLimits = (activeIndexTab, value) => {
    const valueNumber = Number(value);
    let newBorrowLimitsResult = { newBorrowLimitUsd: null, newBorrowLimitPercent: null };
    if (value && valueNumber !== 0) {
      if (activeIndexTab === 0) {
        newBorrowLimitsResult = dispatch(
          lendingBorrowActions.getHypotheticalBorrowLimitsAfterBorrow(zenTokenAddress, valueNumber),
        );
      } else if (activeIndexTab === 1) {
        newBorrowLimitsResult = dispatch(
          lendingBorrowActions.getHypotheticalBorrowLimitsAfterRepay(zenTokenAddress, valueNumber),
        );
      }
    } else {
      newBorrowLimitsResult = { newBorrowLimitUsd: null, newBorrowLimitPercent: null };
    }
    setNewBorrowLimits(newBorrowLimitsResult);
    return newBorrowLimitsResult;
  };

  const onChangeInputHandler = (value) => {
    const valueBN = toBN(value);

    if (
      (value && Number.isNaN(Number(value))) ||
      valueBN.isGreaterThan(maxInputValue) ||
      valueBN.isNegative()
    ) {
      return;
    }

    setIsMaxRepay(false);
    setValueInput(value);
    const newBorrowLimitsResult = updateBorroLimits(activeIndex, value);

    if (value && !valueBN.isEqualTo(0)) {
      const { newBorrowBalanceUsd } = newBorrowLimitsResult;
      const disabled = !newBorrowBalanceUsd || newBorrowBalanceUsd.isNegative();
      setDisabledRepay(disabled);
      setDisabledBorrow(disabled);
    } else {
      setDisabledRepay(true);
      setDisabledBorrow(true);
    }
  };

  const handleClickTab = (index) => {
    setActiveIndex(index);
    if (index === 0) {
      setTitle('Borrow');
      setBalanceTitle('Currently Borrowing');
      setMaxInputValue(dispatch(lendingBorrowActions.getMaxBorrowAmount(zenTokenAddress)));
    } else if (index === 1) {
      setTitle(isTokenEnabled ? 'Repay' : 'Enable');
      setBalanceTitle('Wallet Balance');
      setMaxInputValue(
        underlyingBorrowBalance && underlyingBorrowBalance.isLessThan(tokenBalance)
          ? underlyingBorrowBalance
          : tokenBalance,
      );
    }
    onChangeInputHandler(null);
  };

  const onInpupButtonClickHandler = () => {
    if (maxInputValue.isEqualTo(0)) {
      return;
    }

    if (activeIndex === 0) {
      setDisabledBorrow(false);
    } else if (activeIndex === 1) {
      setDisabledRepay(false);
      setIsMaxRepay(true);
    }
    setValueInput(showBN(maxInputValue, underlyingDecimals));
    updateBorroLimits(activeIndex, maxInputValue);
  };

  const onEnableHandler = async () => {
    try {
      setLoading(true);
      const success = await dispatch(lendingActions.enableToken(zenTokenAddress));
      if (success) {
        setisTokenEnabled(true);
      }
    } catch (error) {
      throw error;
    } finally {
      setDisabledRepay(true);
      setLoading(false);
    }
  };

  const onRepayHandler = async () => {
    try {
      setLoading(true);
      setDisabledRepay(true);
      await dispatch(lendingBorrowActions.repay(zenTokenAddress, valueInput, isMaxRepay));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setDisabledRepay(false);
      dispatch(modalActions.hideModal(BORROW_MODAL));
    }
  };

  const onBorrowHandler = async () => {
    try {
      setLoading(true);
      setDisabledBorrow(true);
      await dispatch(lendingBorrowActions.borrow(zenTokenAddress, valueInput));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setDisabledBorrow(false);
      dispatch(modalActions.hideModal(BORROW_MODAL));
    }
  };

  const { newBorrowBalanceUsd, newBorrowLimitPercent } = newBorrowLimits;

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      {loading && <Loader className={s.loading} />}
      <div className={cn(s.container, { [s.dark]: dark })}>
        <div className={s.header}>
          <div className={s.headerTitle}>{title}</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(BORROW_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.body}>
          <TokenLine className={s.token} tokenImage={underlyingIcon} tokenName={underlyingSymbol} />
          {(isTokenEnabled || activeIndex === 0) && (
            <div className={s.inputWrapper}>
              <InputWithButton
                className={s.input}
                tokenName={underlyingSymbol}
                value={valueInput}
                onChange={(e) => onChangeInputHandler(e.target.value)}
                handleClickButton={onInpupButtonClickHandler}
                placeHolder='Please enter'
              />
              {/* <div><Label className={s.label} value={change} /></div> */}
            </div>
          )}
          <BalanceBlock
            className={s.balance}
            balanceTitle={balanceTitle}
            wallet={showBN(
              activeIndex === 0 ? underlyingBorrowBalance : tokenBalance,
              underlyingDecimals,
            )}
            tokenPrefix={underlyingSymbol}
            walletUsd={showBN(activeIndex === 0 ? underlyingBorrowBalanceUsd : tokenBalanceUsd, 2)}
          />
          <div className={s.tabWrapper}>
            {tabs.map((item, index) => (
              <button
                key={item}
                className={cn(s.tab, { [s.tabActive]: index === activeIndex })}
                onClick={() => handleClickTab(index)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div className={s.footer}>
          {activeIndex === 0 && (
            <Borrow
              onBorrow={onBorrowHandler}
              disabled={disabledBorrow}
              tokenIcon={underlyingIcon}
              borrowAPY={showBN(borrowAPY, 2)}
              distributionAPY={showBN(borrowDistributionAPY, 2)}
              borrowBalanceUsd={showBN(underlyingBorrowBalanceUsd, 2)}
              borrowLimitPercent={showBN(borrowLimitPercent, 2)}
              newBorrowBalanceUsd={newBorrowBalanceUsd && showBN(newBorrowBalanceUsd, 2)}
              newBorrowLimitPercent={newBorrowLimitPercent && showBN(newBorrowLimitPercent, 2)}
              isPaused={isPaused}
            />
          )}
          {activeIndex === 1 && (
            <Repay
              isEnabled={isTokenEnabled}
              onEnable={onEnableHandler}
              onRepay={onRepayHandler}
              disabled={disabledRepay}
              tokenIcon={underlyingIcon}
              borrowAPY={showBN(borrowAPY, 2)}
              distributionAPY={showBN(borrowDistributionAPY, 2)}
              borrowBalanceUsd={showBN(underlyingBorrowBalanceUsd, 2)}
              borrowLimitPercent={showBN(borrowLimitPercent, 2)}
              newBorrowBalanceUsd={newBorrowBalanceUsd && showBN(newBorrowBalanceUsd, 2)}
              newBorrowLimitPercent={newBorrowLimitPercent && showBN(newBorrowLimitPercent, 2)}
              isPaused={isPaused}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

BorrowModal.propTypes = {
  show: PropTypes.bool,
  token: PropTypes.object,
  borrowLimitPercent: PropTypes.object,
};

BorrowModal.defaultProps = {
  show: false,
  token: {},
  borrowLimitPercent: new BN(0),
};

export default connectModal({ name: BORROW_MODAL })(BorrowModal);
