import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './pagination.module.scss';

const Pagination = ({ className, currentPage, totalDataSize, sizePerPage, onClick }) => {
  const dark = useSelector((state) => state.basic.dark);

  if (totalDataSize <= sizePerPage) {
    return null;
  }

  const countPages = Math.ceil(totalDataSize / sizePerPage);

  const leftButtons = Array(currentPage <= 3 ? currentPage - 1 : 3).fill(null);
  const rightButtons = Array(Math.min(3, countPages - currentPage)).fill(null);

  return (
    <div className={cn(styles.container, className, { [styles.dark]: dark })}>
      {currentPage !== 1 && (
        <button onClick={() => onClick(currentPage - 1)} className={cn(styles.item, styles.active)}>
          &lt;&lt;
        </button>
      )}
      {leftButtons.map((item, index) => {
        const page = currentPage - leftButtons.length + index;
        return (
          <button key={page} onClick={() => onClick(page)} className={styles.item}>
            {page}
          </button>
        );
      })}
      <button className={cn(styles.item, styles.active)}>{currentPage}</button>
      {rightButtons.map((item, index) => {
        const page = currentPage + 1 + index;
        return (
          <button key={page} onClick={() => onClick(page)} className={styles.item}>
            {page}
          </button>
        );
      })}
      {currentPage < countPages && (
        <button onClick={() => onClick(currentPage + 1)} className={cn(styles.item, styles.active)}>
          &gt;&gt;
        </button>
      )}
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  totalDataSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
