import styled, { css } from 'styled-components';
import Icon from '../Icon/Icon';

interface Props {
  loading?: boolean;
  disabled?: true;
  color?: 'success';
}

const StyledWrapper = styled.div<Pick<Props, 'disabled' | 'color'>>`
  padding: 0.7em 1em;
  border-radius: 6px;
  display: flex;
  background: #ae528b;
  color: white;

  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(139, 37, 86);
    `}

  ${({ color }) => {
    switch (color) {
      case 'success':
        return css`
          background: #23c581;
        `;
    }
  }}
  >svg {
    margin-right: 0.2em;
  }
`;

export function StatusLabel({
  loading,
  disabled,
  children,
  color,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <StyledWrapper disabled={disabled} color={color} {...props}>
      {loading && <Icon type='spinner_white' width={'1em'} />}
      {children}
    </StyledWrapper>
  );
}
