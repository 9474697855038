import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Button from '../../../../external/components/Button/Button';
import { TextInput } from '../../../../external/components/Input/Input';
import Text from '../../../../external/components/Text/Text';
import { WalletFormSubmitType } from '../../types/form.types';
import Icon from '../../../../external/components/Icon/Icon';
import { DelegatorLink } from '../../../../services/delegator/delegator.types';

import { ButtonContainer, StyledLabel, StyledText } from '../Form/_FormComponents';

interface WalletScreenProps {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  delegatorLinks: DelegatorLink[];
  setOtherWalletAddress: React.Dispatch<React.SetStateAction<string>>;
}

const validationSchema = yup.object().shape({
  walletAddress: yup
    .string()
    .required('Enter a wallet address')
    // @TODO what's the min length of an address?
    .min(35, 'Enter a valid wallet address')
    .test({
      name: 'Test valid address',
      message: 'address is not valid',
      test: async function (this, value) {
        const walletAddress = value;

        try {
          // await axios.get(
          //   `https://jsonplaceholder.typicode.com/todos/${
          //     walletAddress === 'aaadd' ? '1' : '234234234'
          //   }`,
          // );
          return true;
        } catch (error) {
          return this.createError({
            message: 'The address is not valid',
            path: 'walletAddress',
          });
        }
      },
    }),
});

const initialValues = {
  walletAddress: '',
};

export function WalletLinkScreen({
  setScreen,
  delegatorLinks,
  setOtherWalletAddress,
}: WalletScreenProps) {
  const handleCheckAddress = async ({ values }: WalletFormSubmitType) => {
    setOtherWalletAddress(values.walletAddress);

    setScreen(2);
  };

  return (
    <>
      {delegatorLinks.length ? <Text dark={true}>Your existing links:</Text> : null}
      {delegatorLinks.map((link) => (
        <>
          <StyledText size={'xs'} color='grey'>
            {link.address_eth.substring(0, 6) +
              '...' +
              link.address_eth.substring(link.address_eth.length - 6, link.address_eth.length) +
              ' <> ' +
              link.address_other}
          </StyledText>
          <StyledText color={link.type === 'LINKED' ? 'green' : 'pink'} forwardedAs='span'>
            <Icon
              width={10}
              height={10}
              type={link.type === 'LINKED' ? 'greenCircle' : 'pinkCircle'}
              style={{ marginLeft: '20px' }}
            />
            {link.type}
            {link.type === 'VERIFIED' ? ' (continue linking proccess to finalise)' : ''}
          </StyledText>
        </>
      ))}

      <div style={{ marginTop: '20px' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { validateForm }) => {
            validateForm(false);
            await handleCheckAddress({ values });
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            isSubmitting,
            isValidating,
            submitForm,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              id='wallet-address-form'
              noValidate
              onSubmit={(e) => {
                handleSubmit(e);
                if (Object.keys(errors).length < 1) return;
              }}
            >
              <StyledLabel htmlFor='walletAddress'>
                <Text dark={true} as='span'>
                  Enter the wallet address you would like to link
                </Text>
              </StyledLabel>
              <TextInput
                id='walletAddress'
                placeholder='cosmos12...'
                darkAlt={true}
                value={values.walletAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete='off'
              ></TextInput>
              {isSubmitting && (
                <StyledText dark={true} size='sm'>
                  <Icon width={30} height={30} type='spinner' />
                  Detecting address...
                </StyledText>
              )}

              {/* {errors.walletAddress && !isValidating && (
              <StyledText dark={true} size='sm'>
                <Icon width={10} height={10} type='pinkCircle' />
                {errors.walletAddress}
              </StyledText>
            )} */}

              <Text dark={true} size='sm'>
                {/* * You will need to link your KSM address to your Ethereum / Binance Smart Chain /
              Polygon address in order to receive your additional OM Delegator Rewards */}
              </Text>

              <ButtonContainer>
                <Button
                  size='medium'
                  type='submit'
                  disabled={isSubmitting}
                  dark={true}
                  // loading={isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? 'Checking...' : 'Check address'}
                </Button>
              </ButtonContainer>

              <Text dark={true}>
                {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
              </Text>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
