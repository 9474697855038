import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import BN from 'bignumber.js';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader';
import { DISABLE_COLLATERAL_MODAL } from '../../../constants/modal-constants';
import { COLLATERAL_ABOUT_LINK } from '../../../constants/lending-constants';
import ModalButton from '../../../screens/lending/blocks-for-lending-modals/modal-button';
import ProgressBar from '../../progress-bar';
import * as modalActions from '../../../actions/modal-actions';
import * as lendingActions from '../../../actions/lending-actions';
import * as lendingSupplyActions from '../../../actions/lending-supply-actions';

import closeIcon from '../../../assets/images/close-icon.svg';
import arrow from '../../../assets/images/arrow-long.svg';
import s from './disable-collateral-modal.module.scss';
import { showBN } from '../../../helpers/lending-helper';

const DisableCollateralModal = ({ show, underlyingSymbol, zenTokenAddress }) => {
  const dispatch = useDispatch();

  const [newBorrowLimits, setNewBorrowLimit] = useState({
    newBorrowLimitUsd: new BN(0),
    newBorrowLimitPercent: new BN(0),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dark = useSelector((state) => state.basic.dark);
  const { borrowLimitPercent, borrowLimitUsd } = useSelector((state) => state.lending);

  const { newBorrowLimitPercent, newBorrowLimitUsd } = newBorrowLimits;

  useEffect(() => {
    if (zenTokenAddress) {
      setNewBorrowLimit(
        dispatch(lendingSupplyActions.getHypotheticalBorrowLimitsAfterWithdrawal(zenTokenAddress)),
      );
    }
  }, [dispatch, zenTokenAddress]);

  const isEnabledExitMarket = newBorrowLimitPercent.isLessThan(100);
  const buttonText = isEnabledExitMarket ? `Disable ${underlyingSymbol}` : 'Dismiss';
  const onClickHandler = isEnabledExitMarket
    ? async () => {
        try {
          setLoading(true);
          await dispatch(lendingActions.exitMarkets(zenTokenAddress));
          setLoading(false);
        } catch (err) {
          setError(err.message);
        } finally {
          dispatch(modalActions.hideModal(DISABLE_COLLATERAL_MODAL));
        }
      }
    : () => dispatch(modalActions.hideModal(DISABLE_COLLATERAL_MODAL));

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={cn({ [s.dark]: dark })}>
        <div className={s.header}>
          <div className={s.headerTitle}>Disable As Collateral</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(DISABLE_COLLATERAL_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.container}>
          {loading && <Loader className={s.loading} />}
          <div className={s.body}>
            <p className={s.text}>
              This asset will no longer be used towards your borrowing limit, and can&apos;t be
              seized in liquidation.
              <a
                href={COLLATERAL_ABOUT_LINK}
                target='_blank'
                rel='noopener noreferrer'
                className={s.link}
              >
                {' '}
                Learn more.
              </a>
            </p>
          </div>
          {error && <div className={s.error}>{error}</div>}
          <div className={s.footer}>
            <div className={s.lineWrapper}>
              <div className={s.line}>
                <p className={s.lineText}>Borrow Limit</p>
                <div>
                  <span className={s.lineValue}>${showBN(borrowLimitUsd, 2)}</span>
                  <img className={s.arrowImg} src={arrow} alt='arrow' />
                  <span className={s.lineValue}>${showBN(newBorrowLimitUsd, 2)}</span>
                </div>
              </div>
              <div className={s.line}>
                <p className={s.lineText}>Borrow Limit Used</p>
                <div>
                  <span className={s.lineValue}>{showBN(borrowLimitPercent, 2)}%</span>
                  <img className={s.arrowImg} src={arrow} alt='arrow' />
                  <span className={s.lineValue}>{showBN(newBorrowLimitPercent, 2)}%</span>
                </div>
              </div>
            </div>
            <ProgressBar
              className={s.progressBar}
              limit={
                newBorrowLimitPercent.isGreaterThan(100) ? '100' : showBN(newBorrowLimitPercent)
              }
              styleType='small'
            />
            <ModalButton
              text={buttonText}
              onClick={onClickHandler}
              disabled={!isEnabledExitMarket}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

DisableCollateralModal.propTypes = {
  show: PropTypes.bool,
  zenTokenAddress: PropTypes.string.isRequired,
  underlyingSymbol: PropTypes.string.isRequired,
};

DisableCollateralModal.defaultProps = {
  show: false,
};

export default connectModal({ name: DISABLE_COLLATERAL_MODAL })(DisableCollateralModal);
