import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='59'
      height='70'
      viewBox='0 0 59 70'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M29.1706 0C17.9122 0 8.75391 9.15834 8.75391 20.4167C8.75391 31.675 17.9122 40.8334 29.1706 40.8334C40.4289 40.8334 49.5873 31.675 49.5873 20.4167C49.5873 9.15834 40.4289 0 29.1706 0ZM40.216 19.7079L36.2464 23.7767L37.1885 29.5459C37.3227 30.3713 36.9756 31.2025 36.2873 31.6867C35.9139 31.9492 35.4706 32.0834 35.0302 32.0834C34.6656 32.0834 34.301 31.9929 33.9714 31.8092L29.1706 29.1579L24.3697 31.8121C23.6377 32.2175 22.7335 32.1709 22.051 31.6867C21.3656 31.2025 21.0185 30.3713 21.1497 29.5459L22.0918 23.7767L18.1252 19.7079C17.5535 19.1217 17.3552 18.2613 17.6177 17.4854C17.8772 16.7067 18.5481 16.1438 19.3618 16.0183L24.7606 15.1929L27.1931 10.01C27.9106 8.47292 30.4335 8.47292 31.151 10.01L33.5835 15.1929L38.9823 16.0183C39.7931 16.1408 40.4668 16.7067 40.7264 17.4854C40.986 18.2613 40.7877 19.1217 40.216 19.7079Z' />
      <path d='M11.5516 37.8585L0.264063 58.1294C-0.115104 58.8294 -0.0859376 59.7044 0.351563 60.3752C0.789063 61.046 1.5474 61.4544 2.36406 61.3669L11.3474 60.696L15.3141 68.7752C15.6932 69.5044 16.4224 69.971 17.2099 70.0002H17.2682C18.0557 70.0002 18.8141 69.5627 19.1932 68.8919L26.5432 55.8252L19.3974 43.196C16.4516 41.9419 13.7974 40.1044 11.5516 37.8585Z' />
      <path d='M58.0447 58.1289L46.6697 37.9747C42.9656 41.6789 38.0947 44.1872 32.6697 44.9456C31.5322 45.1206 30.3656 45.2081 29.1697 45.2081C27.8864 45.2081 26.6322 45.1206 25.4072 44.9164L29.0531 51.3622L38.9406 68.8914C39.3197 69.5622 40.0489 69.9997 40.8364 69.9997H40.8947C41.7114 69.9706 42.4406 69.5039 42.8197 68.7747L46.8156 60.6956L55.9739 61.3956C56.7906 61.4539 57.5489 61.0747 57.9864 60.3747C58.4239 59.7039 58.4531 58.8289 58.0447 58.1289Z' />
    </svg>
  );
}
