/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './checkbox.module.scss';

const Checkbox = React.memo(({ className, children, noChildContainer, checked, ...props }) => (
  <label className={cn(styles['checkbox-container'], className)}>
    <input type='checkbox' checked={checked} {...props} />
    <div className={cn(styles.icon)} />
    {children && <div className={cn(styles.children)}>{children}</div>}
  </label>
));

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  noChildContainer: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  className: '',
  children: null,
  noChildContainer: false,
};

export default Checkbox;
