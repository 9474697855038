import * as React from 'react';

function SvgLunaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <path
        d='M25.5 49C38.479 49 49 38.479 49 25.5S38.479 2 25.5 2 2 12.521 2 25.5 12.521 49 25.5 49z'
        fill='#172852'
      />
      <path
        d='M20.929 12.503c2.752-1.03 5.849-1.032 8.653-.186 3.65 1.133 6.73 3.887 8.34 7.344.444.985.87 2.012.912 3.107-2.052-1.19-4.301-1.96-6.555-2.663-3.605-1.3-7.379-2.202-10.79-3.988-.795-.475-1.808-.96-2.024-1.95-.087-.856.818-1.333 1.464-1.664zM12.622 20.823c.796-2.163 2.103-4.17 3.877-5.657.451 3.71 2.03 7.297 4.639 9.995 3.128 3.25 7.583 5.245 12.117 5.17 1.8.056 3.56-.36 5.315-.696-1.727 6.242-8.568 10.385-14.906 9.388-3.826-.489-7.358-2.754-9.489-5.952-2.404-3.535-3.01-8.228-1.553-12.248z'
        fill='#FFD83D'
      />
      <path
        d='M20.93 12.501c2.752-1.03 5.849-1.032 8.652-.186 3.65 1.133 6.73 3.887 8.34 7.344.445.985.87 2.012.913 3.107-2.052-1.19-4.301-1.96-6.555-2.663-3.605-1.3-7.379-2.202-10.79-3.988-.795-.474-1.808-.96-2.024-1.95-.087-.856.818-1.333 1.464-1.664zM12.739 20.733c.796-2.162 2.103-4.17 3.877-5.657.451 3.71 2.03 7.297 4.64 9.995 3.127 3.25 7.583 5.245 12.116 5.17 1.8.056 3.56-.36 5.316-.696-1.728 6.242-8.569 10.385-14.907 9.388-3.825-.488-7.357-2.754-9.489-5.952-2.404-3.535-3.01-8.228-1.553-12.248z'
        fill='#FFD83D'
      />
      <path
        d='M32.28 20.104c2.254.702 4.503 1.473 6.554 2.662l.14.073c.19 1.431.328 2.89.074 4.324-1.031-.437-1.868-1.206-2.742-1.885-1.683-1.4-3.4-3.003-4.026-5.174z'
        fill='#FF6F03'
      />
    </svg>
  );
}

export default SvgLunaIcon;
