import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='77'
      height='59'
      viewBox='0 0 77 59'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M41.546 27.5755H28.4551C26.7982 27.5755 25.4551 28.9187 25.4551 30.5755V63.818C25.4551 65.4748 26.7982 66.818 28.4551 66.818H41.546C43.2028 66.818 44.546 65.4748 44.546 63.818V30.5755C44.546 28.9187 43.2028 27.5755 41.546 27.5755Z' />
      <path d='M66.9991 3.18158H53.9082C52.2513 3.18158 50.9082 4.52473 50.9082 6.18158V63.8179C50.9082 65.4748 52.2514 66.8179 53.9082 66.8179H66.9991C68.656 66.8179 69.9991 65.4748 69.9991 63.8179V6.18158C69.9991 4.52473 68.656 3.18158 66.9991 3.18158Z' />
      <path d='M16.0909 41.3634H3C1.34315 41.3634 0 42.7065 0 44.3634V63.8179C0 65.4748 1.34315 66.8179 3 66.8179H16.0909C17.7478 66.8179 19.0909 65.4748 19.0909 63.8179V44.3634C19.0909 42.7065 17.7478 41.3634 16.0909 41.3634Z' />
    </svg>
  );
}
