import web3Service from '../../../web3-service';
import COMPROLLER_ABI from '../../../../blockchain/abi/lending/COMPROLLER.json';
import config from '../../../../config/env';

class LendingComptrollerContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      COMPROLLER_ABI,
      config.REACT_APP_LENDING_COMPTROLLER_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  /**
   * Get all cTokens
   * @returns {string[]}
   */
  async getAllMarkets() {
    return this.contract.methods.getAllMarkets().call();
  }

  /**
   * Get the list of inMarket cTokens (cToken collateral is true)
   * @param {string} account
   * @returns {string[]}
   */
  async getAssetsIn(account) {
    return this.contract.methods.getAssetsIn(account).call();
  }

  /**
   * @param {string} cToken
   * @returns {string}
   */
  async borrowGuardianPaused(cToken) {
    return this.contract.methods.borrowGuardianPaused(cToken).call();
  }

  /**
   * @param {string} cToken
   * @returns {string}
   */
  async compSpeeds(cToken) {
    return this.contract.methods.compSpeeds(cToken).call();
  }

  // method is used by eth.BatchRequest
  compSpeedsRequest(cToken, cb) {
    return this.contract.methods.compSpeeds(cToken).call.request(cb);
  }

  /**
   * Enter to the market with cToken
   * @param {string} account
   * @param {string[]} cToken
   * @returns {string[]}
   */
  async enterMarket(account, cToken) {
    const options = {
      from: account,
    };
    return this.contract.methods.enterMarkets([cToken]).send(options);
  }

  /**
   * @param {string} account
   * @param {string[]} cToken
   * @returns {string[]}
   */
  async exitMarket(account, cToken) {
    const options = {
      from: account,
    };
    return this.contract.methods.exitMarket(cToken).send(options);
  }

  /**
   * @param {string} account
   * @param {string[]} cToken
   * @returns {string[]}
   */
  async claimOm(account, zenTokens) {
    const options = {
      from: account,
    };
    return this.contract.methods.claimComp(account, zenTokens).send(options);
  }
}

export default new LendingComptrollerContractService();
