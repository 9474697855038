import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='94pt'
      height='94pt'
      viewBox='0, 0, 94, 94'
      className={className}
      preserveAspectRatio='xMidYMid meet'
      {...otherProps}
    >
      <g
        transform='translate(0.000000,94.000000) scale(0.100000,-0.100000)'
        fill='rgb(232, 1, 99)'
        stroke='none'
      >
        <path
          d='M0 470 l0 -470 470 0 470 0 0 470 0 470 -470 0 -470 0 0 -470z m625
334 c114 -55 182 -147 206 -275 21 -116 -29 -247 -111 -289 -98 -50 -234 4
-269 109 -11 34 -16 371 -5 371 3 0 15 -5 27 -11 21 -10 21 -10 3 5 -11 9 -24
16 -28 16 -5 0 -38 -21 -73 -47 l-65 -48 0 -134 c0 -164 10 -202 70 -262 67
-67 156 -93 238 -70 46 14 40 -4 -9 -23 -155 -62 -336 -3 -432 141 -130 196
-29 468 203 544 14 5 61 7 105 5 64 -2 92 -8 140 -32z'
        />
        <path
          d='M500 695 c0 -2 18 -14 40 -25 l40 -20 0 -126 c0 -120 1 -126 25 -150
20 -20 29 -23 44 -14 17 8 20 23 23 97 6 161 9 153 -87 201 -47 23 -85 40 -85
37z'
        />
      </g>
    </svg>
  );
}
