import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import arrow from '../../../../../assets/images/arrow-long.svg';
import styles from './token-line.module.scss';

const TokenLine = ({
  className,
  tokenImage,
  apyName,
  tokenName,
  apyValue,
  usdValue,
  newUsdValue,
  percentValue,
  newPercentValue,
  ...props
}) => (
  <div className={cn(styles.wrapper, className)} {...props}>
    <div className={styles.leftBlock}>
      {tokenImage && (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={tokenImage} alt='token' />
        </div>
      )}
      {tokenName && <p>{tokenName}</p>}
      {apyName && <p>{apyName}</p>}
    </div>
    {apyValue !== null && (
      <div className={styles.arrowWrapper}>
        <p className={styles.value}>{apyValue}%</p>
      </div>
    )}
    {usdValue !== null && (
      <div
        className={cn(styles.arrowWrapper, {
          [styles.arrowWrapperPercentValue]: newUsdValue !== null,
        })}
      >
        <span className={styles.value}>${usdValue}</span>
        {newUsdValue !== null && (
          <>
            <img className={styles.arrowImg} src={arrow} alt='arrow' />
            <span className={styles.value}>${newUsdValue}</span>
          </>
        )}
      </div>
    )}
    {percentValue !== null && (
      <div
        className={cn(styles.arrowWrapper, {
          [styles.arrowWrapperPercentValue]: newPercentValue !== null,
        })}
      >
        <span className={styles.value}>{percentValue}%</span>
        {newPercentValue !== null && (
          <>
            <img className={styles.arrowImg} src={arrow} alt='arrow' />
            <span className={styles.value}>{newPercentValue}%</span>
          </>
        )}
      </div>
    )}
  </div>
);

TokenLine.propTypes = {
  className: PropTypes.string,
  tokenImage: PropTypes.string,
  tokenName: PropTypes.string,
  apyName: PropTypes.string,
  apyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usdValue: PropTypes.string,
  newUsdValue: PropTypes.string,
  percentValue: PropTypes.string,
  newPercentValue: PropTypes.string,
};

TokenLine.defaultProps = {
  className: '',
  tokenImage: '',
  tokenName: '',
  apyName: '',
  apyValue: null,
  usdValue: null,
  newUsdValue: null,
  percentValue: null,
  newPercentValue: null,
};

export default React.memo(TokenLine);
