import web3Service from '../../web3-service';
import UNISWAP_OM_ETH_ABI from '../../../blockchain/abi/UNISWAP_OM_ETH.json';
import config from '../../../config/env';

class UniswapV2OmEthContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      UNISWAP_OM_ETH_ABI,
      config.REACT_APP_UNI_V2_ERC20_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  /**
   * Get reserves
   * @returns {Promise<[number, number]>} [omAmount,ethAmount]
   */
  async getReserves() {
    const result = await this.contract.methods.getReserves().call();
    return [result[0], result[1]];
  }

  async totalSupply() {
    return this.contract.methods.totalSupply().call();
  }

  async allowance(address, contractAddress, options = {}, defaultBlock = 'latest') {
    return this.contract.methods.allowance(address, contractAddress).call(options, defaultBlock);
  }

  async approve(address, amountApprove) {
    const options = {
      from: address,
    };
    return this.contract.methods
      .approve(config.REACT_APP_UNI_V2_STAKING_ADDRESS, amountApprove)
      .send(options);
  }
}

export default new UniswapV2OmEthContractService();
