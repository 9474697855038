import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 91.52 74.34'
      className={className}
      {...otherProps}
    >
      <defs>
        <clipPath id='netvrk_clip-path'>
          <path
            fill='none'
            d='M72.92 0c-4.86.4-10.74 3.27-12.84 3.93a30.51 30.51 0 013.38 1.7c12.16-6.41 13.28-1.18 6.8 5a40.7 40.7 0 01-14.9 8.49C33.45 26.3 24.19 4.76 44.5 1a36 36 0 00-24.67 11c5.8-4.86 12.66 3.86 2.62 11.91a115 115 0 01-12.39 7.67c-1.41.79-2.59 1.5-3.37 2C-.54 38.52-2.61 46.15 4 49.37a24 24 0 008.61 2.08c-.34-.78-.65-1.56-.92-2.35a20.62 20.62 0 01-4.51-1.23c-5.57-2.43-1.14-6.5 2.55-9 .91-.61 1.81-1.14 2.45-1.52 11.12-6.42 13 1 9 7.8-2 3.37-4.34 7.11-6.26 10.75a46.61 46.61 0 00-3.07 6.56c-2.32 6.81 0 12.43 6.67 11.89 4.87-.42 10.75-3.28 12.85-3.94a39.63 39.63 0 01-3.39-1.69c-12.15 6.39-13.28 1.16-6.8-5a40.38 40.38 0 0114.91-8.49C58 48 67.28 69.52 47 73.32a36.11 36.11 0 0024.75-11c-5.82 4.89-12.66-3.83-2.64-11.88a110.48 110.48 0 0112.39-7.67c1.41-.82 2.59-1.5 3.37-2 7.23-4.9 9.3-12.54 2.73-15.76A24.43 24.43 0 0079 22.9c.34.76.65 1.56.92 2.35a20.73 20.73 0 014.52 1.24c5.59 2.42 1.14 6.49-2.55 9-.9.63-1.81 1.16-2.44 1.54-11.13 6.42-13-1-9.05-7.8 2-3.36 4.33-7.11 6.25-10.74a48.39 48.39 0 002.93-6.63C81.81 5.43 79.78 0 73.79 0h-.87'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient'
          x1='25.58'
          x2='27.39'
          y1='100'
          y2='100'
          gradientTransform='matrix(32.49 -36.55 -37.01 -32.89 2885.57 4294.74)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#cb93fe'></stop>
          <stop offset='0.36' stopColor='#d18ffe'></stop>
          <stop offset='0.83' stopColor='#5ebbfe'></stop>
          <stop offset='1' stopColor='#56befd'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-2'>
          <path fill='none' d='M28 68.75a35.58 35.58 0 01-6.8-5c-6.48 6.13-5.43 11.34 6.8 5'></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-2'
          x1='39.25'
          x2='41.06'
          y1='93.02'
          y2='93.02'
          gradientTransform='matrix(-5.17 1.88 1.63 4.48 78.97 -423.39)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#9152ff'></stop>
          <stop offset='1' stopColor='#310061'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-3'>
          <path
            fill='none'
            d='M7.16 47.87a21 21 0 004.53 1.23 36.09 36.09 0 01-2-10.22c-3.61 2.49-8.12 6.56-2.55 9'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-3'
          x1='46.42'
          x2='48.23'
          y1='94.66'
          y2='94.66'
          gradientTransform='matrix(-4 .46 .61 5.3 139.8 -479.68)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#422ef2'></stop>
          <stop offset='1' stopColor='#1f0073'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-4'>
          <path
            fill='none'
            d='M63.46 5.66a35.27 35.27 0 016.8 5c4.38-4.14 5.29-7.89 1.65-7.89a20.56 20.56 0 00-8.45 2.93'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-4'
          x1='23.31'
          x2='25.12'
          y1='111.39'
          y2='111.39'
          gradientTransform='matrix(3.03 -3.61 -3.71 -3.11 408.84 441.19)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#000ba8'></stop>
          <stop offset='1' stopColor='#11002b'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-5'>
          <path
            fill='none'
            d='M36.11 55.23a40.66 40.66 0 00-14.91 8.49 35.61 35.61 0 006.8 5 33.37 33.37 0 003.39 1.68 35.55 35.55 0 0014.34 3H47c17.4-3.24 13-19.54-2.37-19.54a27.13 27.13 0 00-8.52 1.46'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-5'
          x1='28.82'
          x2='30.63'
          y1='97.02'
          y2='97.02'
          gradientTransform='matrix(-20.53 10.75 8.86 16.91 -208.91 -1895.29)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#653bcf'></stop>
          <stop offset='0.42' stopColor='#855adb'></stop>
          <stop offset='0.81' stopColor='#b897fc'></stop>
          <stop offset='1' stopColor='#bc9cff'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-6'>
          <path
            fill='none'
            d='M58.63 24.06C42 36.45 54.74 45.77 69.49 43.36 80 41.68 90.87 34 91 28.2a7.11 7.11 0 00-3.39-3.2A24.13 24.13 0 0079 22.88c.35.78.65 1.56.92 2.36a21 21 0 014.53 1.23c5.59 2.42 1.14 6.49-2.55 9-.91.62-1.81 1.14-2.45 1.52-11.12 6.42-13-1-9-7.8 2-3.36 4.32-7.1 6.24-10.74a46.46 46.46 0 002.88-6.59c1.62-4.77 1-9-1.92-10.85 4.11 6.84-9.43 15.79-19 23'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-6'
          x1='25.72'
          x2='27.53'
          y1='100.58'
          y2='100.58'
          gradientTransform='matrix(20.72 -25.5 -25.17 -20.45 2048.75 2756.42)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#cfd5ff'></stop>
          <stop offset='0.78' stopColor='#61d2ff'></stop>
          <stop offset='1' stopColor='#57d8ff'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-7'>
          <path
            fill='none'
            d='M22 31C11.56 32.65.65 40.31.49 46.13a7.06 7.06 0 003.43 3.24 24.11 24.11 0 008.67 2.08c-.34-.78-.65-1.56-.92-2.35a20.62 20.62 0 01-4.51-1.23c-5.57-2.43-1.14-6.5 2.55-9 .91-.61 1.81-1.14 2.45-1.52 11.12-6.42 13 1 9 7.8-2 3.37-4.34 7.11-6.26 10.75a46.61 46.61 0 00-3.07 6.56c-1.63 4.8-.94 9 1.91 10.86-4.1-6.84 9.45-15.81 19.07-23 14.82-11.09 6.24-19.69-6.24-19.69A30.65 30.65 0 0022 31'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-7'
          x1='26.74'
          x2='28.55'
          y1='96.05'
          y2='96.05'
          gradientTransform='matrix(-10.06 30.96 26.23 8.53 -2217.8 -1621.66)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#dab4ff'></stop>
          <stop offset='1' stopColor='#e5cffe'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-8'>
          <path
            fill='none'
            d='M69.07 50.42c-10.09 8-3.18 16.77 2.64 11.9a36.16 36.16 0 009.75-19.57 113.49 113.49 0 00-12.39 7.67'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-8'
          x1='24.04'
          x2='25.85'
          y1='101.36'
          y2='101.36'
          gradientTransform='matrix(12.33 -10.51 -10.46 -12.28 825.21 1559.88)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#ba28eb'></stop>
          <stop offset='0.28' stopColor='#9f38ec'></stop>
          <stop offset='0.78' stopColor='#1c87f1'></stop>
          <stop offset='1' stopColor='#029df7'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-9'>
          <path
            fill='none'
            d='M44.3 53.77C60 53.53 64.53 70.05 47 73.3a36 36 0 0020.55-7.23c-4.23 2.44-5.24-1.67-8.26-5.77a15.2 15.2 0 00-13.5-6.52H44.3'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-9'
          x1='27.5'
          x2='29.31'
          y1='94.36'
          y2='94.36'
          gradientTransform='matrix(-4.29 12.52 12.81 4.39 -1031.01 -706.36)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#fcbfff'></stop>
          <stop offset='0.31' stopColor='#fbbeff'></stop>
          <stop offset='1' stopColor='#dc9cff'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-10'>
          <path
            fill='none'
            d='M24 8.3c4.23-2.42 5.25 1.69 8.27 5.79 2.53 3.46 6.48 6.93 14.94 6.48C31.48 20.8 27 4.29 44.5 1A36 36 0 0024 8.27'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-10'
          x1='27.79'
          x2='29.6'
          y1='94.04'
          y2='94.04'
          gradientTransform='matrix(-9.54 11.75 11.86 9.63 -805.66 -1232.09)'
        ></linearGradient>
        <clipPath id='netvrk_clip-path-11'>
          <path
            fill='none'
            d='M44.5 1C27 4.29 31.48 20.8 47.16 20.57a27.64 27.64 0 008.2-1.47 40.53 40.53 0 0014.9-8.48 35.27 35.27 0 00-6.8-5A32.36 32.36 0 0060.08 4a36.1 36.1 0 00-14.35-3H44.5'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-11'
          x1='27.15'
          x2='28.96'
          y1='95.62'
          y2='95.62'
          gradientTransform='matrix(-13.18 16.22 16.62 13.5 -1169.34 -1736.76)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#8fdcff'></stop>
          <stop offset='0.24' stopColor='#3bb7ff'></stop>
          <stop offset='0.77' stopColor='#2b62d0'></stop>
          <stop offset='1' stopColor='#0037ad'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-12'>
          <path
            fill='none'
            d='M70.48 29.2c-4 6.8-2.15 14.22 9 7.8.67-.38 1.53-.91 2.44-1.52a36.27 36.27 0 00-2.1-10.15c-.27-.8-.58-1.58-.92-2.35a33.19 33.19 0 00-2.28-4.44c-1.92 3.62-4.25 7.38-6.24 10.75'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-12'
          x1='25.22'
          x2='27.03'
          y1='92.24'
          y2='92.24'
          gradientTransform='matrix(-1.26 13.84 8.39 .76 -666.69 -402.81)'
        ></linearGradient>
        <clipPath id='netvrk_clip-path-13'>
          <path
            fill='none'
            d='M36.11 55.23a40.66 40.66 0 00-14.91 8.49A36.61 36.61 0 0025.34 67c14.71-12.25 36.6-5 29.73 2.86 7.07-5.86 1.81-16.09-10.44-16.09a27.13 27.13 0 00-8.52 1.46'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-13'
          x1='27.49'
          x2='29.3'
          y1='96.73'
          y2='96.73'
          gradientTransform='matrix(-16.68 23.74 23.21 16.31 -1730.34 -2189.19)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#1b003d'></stop>
          <stop offset='0.13' stopColor='#1f054f'></stop>
          <stop offset='0.42' stopColor='#3822b7'></stop>
          <stop offset='0.67' stopColor='#a281ef'></stop>
          <stop offset='1' stopColor='#b18ff7'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-14'>
          <path
            fill='none'
            d='M69.07 50.42c-5.83 4.65-6 9.53-4 11.87 1.34-6.35 9.59-11.18 15-13.75a36.18 36.18 0 001.4-5.79A113.49 113.49 0 0069 50.42'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-14'
          x1='26.86'
          x2='28.67'
          y1='94.16'
          y2='94.16'
          gradientTransform='matrix(-4.14 12.98 11.25 3.59 -872.04 -645.93)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#002ead'></stop>
          <stop offset='0.74' stopColor='#8825dd'></stop>
          <stop offset='1' stopColor='#8f24e0'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-15'>
          <path
            fill='none'
            d='M70.48 29.2c-3.62 6.2-2.44 12.92 6.24 9.19-5.55-1.2-1.41-10.46 2.12-15.88a34.79 34.79 0 00-2.12-4.06c-1.92 3.62-4.25 7.38-6.24 10.75'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-15'
          x1='25.17'
          x2='26.98'
          y1='90.5'
          y2='90.5'
          gradientTransform='matrix(-1.19 13.08 6.69 .61 -501.51 -367.3)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#30a1f2'></stop>
          <stop offset='0.56' stopColor='#2b62d0'></stop>
          <stop offset='1' stopColor='#0037ad'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-16'>
          <path
            fill='none'
            d='M19.83 12a36.18 36.18 0 00-9.77 19.58 115 115 0 0012.39-7.67c8.09-6.44 5.28-13.3.85-13.32A5.38 5.38 0 0019.83 12'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-16'
          x1='25.6'
          x2='27.41'
          y1='105.01'
          y2='105.01'
          gradientTransform='matrix(5.72 -13.59 -11.95 -5.03 1123.2 910.37)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#266bff'></stop>
          <stop offset='1' stopColor='#0022a8'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-17'>
          <path
            fill='none'
            d='M12.07 37.34c-.67.38-1.54.9-2.45 1.52a36.16 36.16 0 002 10.24c.27.79.57 1.57.92 2.35a32.52 32.52 0 002.28 4.43c1.91-3.62 4.25-7.38 6.26-10.74 3-5 2.73-10.26-1.92-10.26a15 15 0 00-7.07 2.46'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-17'
          x1='23.51'
          x2='25.32'
          y1='104.07'
          y2='104.07'
          gradientTransform='matrix(8.37 -11.16 -10.63 -7.97 918.48 1147.6)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#8f88f8'></stop>
          <stop offset='1' stopColor='#4859f2'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-18'>
          <path
            fill='none'
            d='M12.07 37.34c-.67.38-1.54.9-2.45 1.52a37 37 0 00.56 4.92c3.8-2.66 11-5.73 12.4-2 1.1-3.73.14-6.86-3.44-6.86a15 15 0 00-7.07 2.46'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-18'
          x1='33.71'
          x2='35.52'
          y1='90.48'
          y2='90.48'
          gradientTransform='matrix(-6.03 4.39 4.12 5.66 -147.48 -624.72)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#060fb8'></stop>
          <stop offset='0.14' stopColor='#0f18bd'></stop>
          <stop offset='0.75' stopColor='#6a6ef3'></stop>
          <stop offset='1' stopColor='#7074f6'></stop>
        </linearGradient>
        <clipPath id='netvrk_clip-path-19'>
          <path
            fill='none'
            d='M19.83 12c-.62.62-1.18 1.27-1.81 1.92 5.14-2.95 9 5.73 4.36 10 8.08-6.44 5.28-13.3.85-13.32a5.41 5.41 0 00-3.4 1.4'
          ></path>
        </clipPath>
        <linearGradient
          id='netvrk_linear-gradient-19'
          x1='31.8'
          x2='33.61'
          y1='91.01'
          y2='91.01'
          gradientTransform='matrix(-6.53 5.02 5.11 6.64 -227.97 -750.66)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#001a69'></stop>
          <stop offset='0.69' stopColor='#0832ba'></stop>
          <stop offset='1' stopColor='#0933bc'></stop>
        </linearGradient>
      </defs>
      <g data-name='Layer 2'>
        <g data-name='Layer 1-2'>
          <g clipPath='url(#netvrk_clip-path)'>
            <path
              fill='url(#netvrk_linear-gradient)'
              d='M-40.31 41.63L40.05 -48.79 131.77 32.73 51.41 123.14 -40.31 41.63z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-2)'>
            <path
              fill='url(#netvrk_linear-gradient-2)'
              d='M31.64 73.76L16.28 79.35 11.09 65.06 26.45 59.47 31.64 73.76z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-3)'>
            <path
              fill='url(#netvrk_linear-gradient-3)'
              d='M12.73 48.97L1.63 50.24 0.34 39 11.45 37.74 12.73 48.97z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-4)'>
            <path
              fill='url(#netvrk_linear-gradient-4)'
              d='M59.59 7.36L68.45 -3.2 79.42 6.01 70.55 16.57 59.59 7.36z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-5)'>
            <path
              fill='url(#netvrk_linear-gradient-5)'
              d='M72.44 69.13L30.5 91.09 13.15 57.98 55.1 36.02 72.44 69.13z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-6)'>
            <path
              fill='url(#netvrk_linear-gradient-6)'
              d='M20.15 27.99L61.49 -22.89 112.85 18.83 71.51 69.7 20.15 27.99z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-7)'>
            <path
              fill='url(#netvrk_linear-gradient-7)'
              d='M60.2 34.68L43.15 87.16 -12.07 69.22 4.99 16.73 60.2 34.68z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-8)'>
            <path
              fill='url(#netvrk_linear-gradient-8)'
              d='M46.89 53.02L72 31.66 93.51 56.91 68.42 78.3 46.89 53.02z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-9)'>
            <path
              fill='url(#netvrk_linear-gradient-9)'
              d='M73.57 55.63L65.07 80.43 38.23 71.22 46.75 46.42 73.57 55.63z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-10)'>
            <path
              fill='url(#netvrk_linear-gradient-10)'
              d='M56.95 8.99L37.94 32.38 14.16 13.08 33.18 -10.31 56.95 8.99z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-11)'>
            <path
              fill='url(#netvrk_linear-gradient-11)'
              d='M79.94 8.88L53.04 42.01 17.26 12.94 44.18 -20.17 79.94 8.88z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-12)'>
            <path
              fill='url(#netvrk_linear-gradient-12)'
              d='M84.1 18.67L81.72 44.83 64.11 43.22 66.48 17.06 84.1 18.67z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-13)'>
            <path
              fill='url(#netvrk_linear-gradient-13)'
              d='M69.72 59.1L48.61 89.15 13.6 64.57 34.73 34.52 69.72 59.1z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-14)'>
            <path
              fill='url(#netvrk_linear-gradient-14)'
              d='M87.13 44.56L79.74 67.62 57.42 60.5 64.76 37.43 87.13 44.56z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-15)'>
            <path
              fill='url(#netvrk_linear-gradient-15)'
              d='M80.92 18.65L78.69 43.22 64.58 41.94 66.83 17.37 80.92 18.65z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-16)'>
            <path
              fill='url(#netvrk_linear-gradient-16)'
              d='M2.5 28.44L13.1 3.29 37.97 13.79 27.39 38.91 2.5 28.44z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-17)'>
            <path
              fill='url(#netvrk_linear-gradient-17)'
              d='M-0.47 48.34L14.8 27.99 34.08 42.44 18.83 62.79 -0.47 48.34z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-18)'>
            <path
              fill='url(#netvrk_linear-gradient-18)'
              d='M27.91 40.72L14.47 50.47 5.37 37.97 18.81 28.2 27.91 40.72z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-19)'>
            <path
              fill='url(#netvrk_linear-gradient-19)'
              d='M36.9 18.98L22.65 29.94 11.6 15.56 25.85 4.61 36.9 18.98z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path)'>
            <path
              fill='url(#netvrk_linear-gradient)'
              d='M-40.31 41.63L40.05 -48.79 131.77 32.73 51.41 123.14 -40.31 41.63z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-2)'>
            <path
              fill='url(#netvrk_linear-gradient-2)'
              d='M31.64 73.76L16.28 79.35 11.09 65.06 26.45 59.47 31.64 73.76z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-3)'>
            <path
              fill='url(#netvrk_linear-gradient-3)'
              d='M12.73 48.97L1.63 50.24 0.34 39 11.45 37.74 12.73 48.97z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-4)'>
            <path
              fill='url(#netvrk_linear-gradient-4)'
              d='M59.59 7.36L68.45 -3.2 79.42 6.01 70.55 16.57 59.59 7.36z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-5)'>
            <path
              fill='url(#netvrk_linear-gradient-5)'
              d='M72.44 69.13L30.5 91.09 13.15 57.98 55.1 36.02 72.44 69.13z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-6)'>
            <path
              fill='url(#netvrk_linear-gradient-6)'
              d='M20.15 27.99L61.49 -22.89 112.85 18.83 71.51 69.7 20.15 27.99z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-7)'>
            <path
              fill='url(#netvrk_linear-gradient-7)'
              d='M60.2 34.68L43.15 87.16 -12.07 69.22 4.99 16.73 60.2 34.68z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-8)'>
            <path
              fill='url(#netvrk_linear-gradient-8)'
              d='M46.89 53.02L72 31.66 93.51 56.91 68.42 78.3 46.89 53.02z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-9)'>
            <path
              fill='url(#netvrk_linear-gradient-9)'
              d='M73.57 55.63L65.07 80.43 38.23 71.22 46.75 46.42 73.57 55.63z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-10)'>
            <path
              fill='url(#netvrk_linear-gradient-10)'
              d='M56.95 8.99L37.94 32.38 14.16 13.08 33.18 -10.31 56.95 8.99z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-11)'>
            <path
              fill='url(#netvrk_linear-gradient-11)'
              d='M79.94 8.88L53.04 42.01 17.26 12.94 44.18 -20.17 79.94 8.88z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-12)'>
            <path
              fill='url(#netvrk_linear-gradient-12)'
              d='M84.1 18.67L81.72 44.83 64.11 43.22 66.48 17.06 84.1 18.67z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-13)'>
            <path
              fill='url(#netvrk_linear-gradient-13)'
              d='M69.72 59.1L48.61 89.15 13.6 64.57 34.73 34.52 69.72 59.1z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-14)'>
            <path
              fill='url(#netvrk_linear-gradient-14)'
              d='M87.13 44.56L79.74 67.62 57.42 60.5 64.76 37.43 87.13 44.56z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-15)'>
            <path
              fill='url(#netvrk_linear-gradient-15)'
              d='M80.92 18.65L78.69 43.22 64.58 41.94 66.83 17.37 80.92 18.65z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-16)'>
            <path
              fill='url(#netvrk_linear-gradient-16)'
              d='M2.5 28.44L13.1 3.29 37.97 13.79 27.39 38.91 2.5 28.44z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-17)'>
            <path
              fill='url(#netvrk_linear-gradient-17)'
              d='M-0.47 48.34L14.8 27.99 34.08 42.44 18.83 62.79 -0.47 48.34z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-18)'>
            <path
              fill='url(#netvrk_linear-gradient-18)'
              d='M27.91 40.72L14.47 50.47 5.37 37.97 18.81 28.2 27.91 40.72z'
            ></path>
          </g>
          <g clipPath='url(#netvrk_clip-path-19)'>
            <path
              fill='url(#netvrk_linear-gradient-19)'
              d='M36.9 18.98L22.65 29.94 11.6 15.56 25.85 4.61 36.9 18.98z'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
