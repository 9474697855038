import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ErrorIcon from '../Icon/icons/ErrorIcon';

interface Props {}

const Frame = styled.div`
  max-width: 32em;
  border-radius: 6px;
  border: solid 3px #c0215e;
  border-left-width: 3rem;
  position: relative;
`;

const IconFrame = styled.div`
  position: absolute;
  left: -2.25rem;
  margin-top: -0.75rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
`;

const ErrorMessageCont = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  max-width: 30em;

  display: flex;
  align-items: center;
`;

export function ErrorAlert({ children }: PropsWithChildren<Props>) {
  return (
    <Frame>
      <IconFrame>
        <ErrorIcon />
      </IconFrame>
      <ErrorMessageCont>{children}</ErrorMessageCont>
    </Frame>
  );
}
