import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 50 50'
      className={className}
      {...otherProps}
    >
      <path
        fill='url(#paint0_linear_rune)'
        d='M0 50l40.032-14.699-12.673-11.205L0 50zm14.706-37.091l12.673 11.187L50 0 14.706 12.909z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_rune'
          x1='0.001'
          x2='49.993'
          y1='25.001'
          y2='25.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0CF'></stop>
          <stop offset='1' stopColor='#3F9'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
