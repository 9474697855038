/* eslint-disable max-len */
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto';
// import PolkadotJsService from '../services/network-providers/polkadotjs-wallet-service';
// import { TYPE_AUTH } from '../constants/account-contants';
import {
  SET_AUTH_POLKADOT,
  LOGOUT_POLKADOT,
  SET_AUTH_KUSAMA,
  LOGOUT_KUSAMA,
  GET_ALL_POLKADOT_ACCOUNTS,
  GET_DOT_BALANCE,
  GET_KSM_BALANCE,
  CLEAR_NOMINATORS,
  POLKADOT_SET_STATUS,
  CHECK_IS_MAPPED,
  SET_AMOUNT_BONDED,
} from './action-types';
import * as modalActions from './modal-actions';
import { toastError } from './toast-actions';
// import { getDotFreeBalance, getKsmFreeBalance } from './token-polkadot-actions';
import {
  checkIsNominator,
  checkAddressMapping,
  checkIsAwaitToClaim,
  sendRequestReward,
} from './delegator-reward-actions';
import { setStatus as setKusamaStatus } from './kusama-actions';
import {
  POLKADOT_ACCOUNT_MODAL,
  POLKADOT_LOGIN_MODAL,
  KUSAMA_ACCOUNT_MODAL,
  LOGIN_MODAL,
} from '../constants/modal-constants';
// import polkadotApiService from '../services/polkadot-api-service';
// import kusamaApiService from '../services/kusama-api-service';
import { CHAIN_NAMES } from '../constants/blockchain-constants';

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const setStatus = (status, withdrowedIndex) => async (dispatch) => {
  dispatch({ type: POLKADOT_SET_STATUS, payload: { status, withdrowedIndex } });
};

export const logoutDot = () => (dispatch) => {
  localStorage.removeItem('connected-polkadot');
  localStorage.removeItem('connected-polkadot-address');
  dispatch({ type: LOGOUT_POLKADOT });
  dispatch({ type: GET_DOT_BALANCE, payload: '0' });
  dispatch({ type: CLEAR_NOMINATORS, payload: { chain: CHAIN_NAMES.POLKADOT } });
  dispatch({ type: CHECK_IS_MAPPED, payload: { isMapped: false, chain: CHAIN_NAMES.POLKADOT } });

  dispatch(modalActions.hideModal(POLKADOT_ACCOUNT_MODAL));
};

export const logoutKsm = () => (dispatch) => {
  localStorage.removeItem('connected-kusama');
  localStorage.removeItem('connected-kusama-address');
  dispatch({ type: LOGOUT_KUSAMA });
  dispatch({ type: GET_KSM_BALANCE, payload: '0' });
  dispatch({ type: CLEAR_NOMINATORS, payload: { chain: CHAIN_NAMES.KUSAMA } });
  dispatch({ type: CHECK_IS_MAPPED, payload: { isMapped: false, chain: CHAIN_NAMES.KUSAMA } });

  dispatch(modalActions.hideModal(KUSAMA_ACCOUNT_MODAL));
};

export const authorization = (typeAuth, account, chain) => async (dispatch) => {
  try {
    const SET_AUTH = chain === CHAIN_NAMES.POLKADOT ? SET_AUTH_POLKADOT : SET_AUTH_KUSAMA;

    // Transform substrate address into Kusama address
    const value = decodeAddress(account.address);
    const encodedAddress = encodeAddress(value, SET_AUTH === SET_AUTH_POLKADOT ? 0 : 2);

    // eslint-disable-next-line no-unused-vars
    const set =
      SET_AUTH === SET_AUTH_POLKADOT
        ? localStorage.setItem('connected-polkadot-address', JSON.stringify(account))
        : localStorage.setItem('connected-kusama-address', JSON.stringify(account));
    dispatch({
      type: SET_AUTH,
      payload: {
        type: typeAuth,
        address: encodedAddress,
        substrateAddress: account.address,
        bonded: encodedAddress,
        stash: 0,
        controller: 0,
      },
    });
    dispatch({
      type: SET_AMOUNT_BONDED,
      payload: {
        chain: chain,
        bondedAmount: 0, // bondedAmount,
      },
    });
    const isMapped = await dispatch(checkAddressMapping(chain));
    // Check if stash address is in nominate list
    const isNominated = await dispatch(checkIsNominator(chain));
    const isReadyToClaim = await dispatch(checkIsAwaitToClaim(chain, isNominated));
    const setFrameStatus = (status) =>
      chain === CHAIN_NAMES.POLKADOT ? setStatus(status) : setKusamaStatus(status);
    if (!isMapped) {
      dispatch(setFrameStatus(0));
      return;
    }
    if (!isNominated) {
      dispatch(setFrameStatus(1));
      return;
    }
    if (!isReadyToClaim) {
      dispatch(setFrameStatus(10));
      return;
    }
    dispatch(setFrameStatus(2));
  } catch (error) {
    throw error;
  }
};

/**
 * Connect to wallet
 */
export const connect = (typeAuth, account, chain) => async (dispatch) => {
  try {
    await dispatch(authorization(typeAuth, account, chain));
    const storageKey = chain === CHAIN_NAMES.POLKADOT ? 'connected-polkadot' : 'connected-kusama';
    localStorage.setItem(storageKey, typeAuth);
    dispatch(modalActions.hideModal(POLKADOT_LOGIN_MODAL));
    dispatch(modalActions.hideModal(LOGIN_MODAL));
  } catch (e) {
    const ignoreErrorsTitle = [
      'User closed modal',
      'User denied account authorization',
      'Already processing eth_requestAccounts. Please wait.',
    ];
    if (ignoreErrorsTitle.every((title) => e.message.indexOf(title) < 0)) {
      toastError(e.message);
    }
  }
};

// eslint-disable-next-line no-unused-vars
export const getAllAddresses = (chain) => async (dispatch) => {
  try {
    await web3Enable('MANTRA DAO');
  } catch (error) {
    alert('polkadot.js extension is not installed');
  }
  try {
    // polkadot: 0,
    // kusama: 2,

    const networkId = chain === CHAIN_NAMES.POLKADOT ? 0 : 2;
    const allAccounts = await web3Accounts();

    allAccounts.forEach((element) => {
      const value = decodeAddress(element.address);
      const encoded = encodeAddress(value, networkId);
      element.encodedAddress = encoded;
    });

    // const type = chain === CHAIN_NAMES.POLKADOT ? GET_ALL_POLKADOT_ACCOUNTS : GET_ALL_KUSAMA_ACCOUNTS;
    dispatch({ type: GET_ALL_POLKADOT_ACCOUNTS, payload: { allAccounts } });
    return allAccounts;
  } catch (e) {
    return [];
  }
};

// Used to tell the connect function what chain you are connecting
export const changeChainMode = (chain) => async (dispatch) => {
  dispatch({ type: chain });
};

export const initPolkadot = () => async (dispatch) => {
  const typeAuth = localStorage.getItem('connected-polkadot');
  if (typeAuth === 'polkadot_js') {
    try {
      await Promise.all([
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          try {
            const address = localStorage.getItem('connected-polkadot-address');
            if (address) {
              await dispatch(connect(typeAuth, JSON.parse(address), CHAIN_NAMES.POLKADOT));
            }
          } catch (e) {
            // dispatch(logout());
          }
          resolve();
        }),
      ]);

      // Check availability at dot api, If method get error than api doesn't available
      // (await polkadotApiService.web3).genesisHash.toHex();
    } catch (err) {
      throw err;
    }
  }
};

export const initKusama = () => async (dispatch) => {
  const typeAuth = localStorage.getItem('connected-kusama');
  if (typeAuth === 'polkadot_js') {
    try {
      await Promise.all([
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          try {
            const address = localStorage.getItem('connected-kusama-address');
            if (address) {
              await dispatch(connect(typeAuth, JSON.parse(address), CHAIN_NAMES.KUSAMA));
            }
          } catch (e) {
            // dispatch(logout());
          }
          resolve();
        }),
      ]);
      // await (await polkadotApiService.web3).genesisHash.toHex();
    } catch (error) {
      throw error;
    }
  }
};

export const claimRewards = (unclaimedAmount, long, chain) => async (dispatch) => {
  try {
    dispatch(setStatus(long ? 12 : 11));
    await dispatch(sendRequestReward(unclaimedAmount, long, chain));
    dispatch(setStatus(8));
  } catch (error) {
    dispatch(setStatus(99));
  }
};
