import styled from 'styled-components';
import Text from '../../../../external/components/Text/Text';

export const StyledLabel = styled.label`
  margin-bottom: 0.35rem;
  display: inline-block;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledText = styled(Text)`
  display: inline-flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }
`;
