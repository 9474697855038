import * as React from 'react';

function SvgFuseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#0A2336' />
      <path
        d='M13.104 16.615c-1.3.565-1.3 2.409 0 2.973l8.488 3.686a10.129 10.129 0 008.07 0l8.488-3.686c1.3-.565 1.3-2.408 0-2.973l-10.102-4.387a6.077 6.077 0 00-4.842 0l-10.102 4.387z'
        fill='url(#FuseIcon_svg__paint0_linear)'
      />
      <path
        d='M16.212 23.108l-3.108 1.35c-1.3.564-1.3 2.408 0 2.972l8.488 3.687a10.129 10.129 0 008.07 0l8.488-3.687c1.3-.564 1.3-2.408 0-2.973l-3.108-1.35-5.38 2.337a10.13 10.13 0 01-8.07 0l-5.38-2.336z'
        fill='url(#FuseIcon_svg__paint1_linear)'
      />
      <path
        d='M15.58 30.675l-2.476 1.075c-1.3.565-1.3 2.409 0 2.974l8.488 3.686a10.129 10.129 0 008.07 0l8.488-3.686c1.3-.565 1.3-2.409 0-2.974l-2.476-1.075-6.012 2.611a10.13 10.13 0 01-8.07 0l-6.013-2.61z'
        fill='url(#FuseIcon_svg__paint2_linear)'
      />
      <defs>
        <linearGradient
          id='FuseIcon_svg__paint0_linear'
          x1={25.627}
          y1={11.725}
          x2={25.627}
          y2={39.248}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B8FDBB' />
          <stop offset={1} stopColor='#F8FD8A' />
        </linearGradient>
        <linearGradient
          id='FuseIcon_svg__paint1_linear'
          x1={25.627}
          y1={11.725}
          x2={25.627}
          y2={39.248}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B8FDBB' />
          <stop offset={1} stopColor='#F8FD8A' />
        </linearGradient>
        <linearGradient
          id='FuseIcon_svg__paint2_linear'
          x1={25.627}
          y1={11.725}
          x2={25.627}
          y2={39.248}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B8FDBB' />
          <stop offset={1} stopColor='#F8FD8A' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgFuseIcon;
