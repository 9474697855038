import styled from 'styled-components';

import Icon from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';
import Button from '../../../../external/components/Button/Button';
import { TextInput } from '../../../../external/components/Input/Input';

interface Props {
  walletAddress: string;
  otherWalletAddress: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function LinkedScreen({ walletAddress, otherWalletAddress, setShow }: Props) {
  return (
    <StyledWrapper>
      <>
        <Icon type='done' width={200} />
        <div style={{ marginTop: '20px', width: '100%' }}>
          <TextInput
            id='KSM'
            darkAlt={true}
            disabled={true}
            defaultValue={otherWalletAddress}
            width='xl'
          ></TextInput>
        </div>
        <Text dark={true}>Address sucessfully linked</Text>
        <div style={{ marginTop: '10px' }}>
          <Button dark={true} variant='secondary' onClick={() => setShow(false)}>
            Close
          </Button>
        </div>
      </>
    </StyledWrapper>
  );
}
