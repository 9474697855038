import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 46 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        d='M24.3774 0.894531L48 14.533V41.81L24.3774 55.4485L0.754883 41.81V14.533L24.3774 0.894531Z'
        fill='#16253D'
      />
      <path
        d='M24.3774 0.894531L48 14.533V41.81L24.3774 55.4485L0.754883 41.81V14.533L24.3774 0.894531Z'
        fill='#16253D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.9831 14.5244L47.9997 14.534V34.6998L41.8345 38.2641V18.0791L47.9831 14.5244Z'
        fill='#235DCF'
      />
      <path
        d='M47.9831 14.5244L47.9968 14.5008C47.9883 14.4959 47.9779 14.4959 47.9695 14.5008L47.9831 14.5244ZM47.9997 14.534H48.027C48.027 14.5243 48.0218 14.5153 48.0134 14.5104L47.9997 14.534ZM47.9997 34.6998L48.0134 34.7234C48.0218 34.7185 48.027 34.7095 48.027 34.6998H47.9997ZM41.8345 38.2641H41.8072C41.8072 38.2738 41.8124 38.2828 41.8208 38.2877C41.8293 38.2926 41.8397 38.2926 41.8481 38.2877L41.8345 38.2641ZM41.8345 18.0791L41.8208 18.0555C41.8124 18.0604 41.8072 18.0694 41.8072 18.0791H41.8345ZM47.9695 14.548L47.9861 14.5576L48.0134 14.5104L47.9968 14.5008L47.9695 14.548ZM47.9725 14.534V34.6998H48.027V14.534H47.9725ZM47.9861 34.6762L41.8208 38.2405L41.8481 38.2877L48.0134 34.7234L47.9861 34.6762ZM41.8617 38.2641V18.0791H41.8072V38.2641H41.8617ZM41.8481 18.1027L47.9968 14.548L47.9695 14.5008L41.8208 18.0555L41.8481 18.1027Z'
        fill='#235DCF'
      />
      <path
        d='M24.3772 7.98828L41.8579 18.0808V38.2657L24.3772 48.3582L6.89653 38.2657V18.0808L24.3772 7.98828Z'
        fill='#5085EC'
      />
      <path
        d='M24.3772 48.3575V28.1726L41.8345 18.0801V38.265L24.3772 48.3575Z'
        fill='#235DCF'
        stroke='#235DCF'
        strokeWidth='0.054554'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
