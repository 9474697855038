import _ from 'lodash';
import {
  SET_AUTH_POLKADOT,
  LOGOUT_POLKADOT,
  GET_ALL_POLKADOT_ACCOUNTS,
  POLKADOT_AUTH_MODE,
  KUSAMA_AUTH_MODE,
  POLKADOT_SET_STATUS,
} from '../actions/action-types';
import { TYPE_AUTH } from '../constants/account-contants';
import { CHAIN_NAMES } from '../constants/blockchain-constants';

const DEFAULT_FIELDS = {
  auth: null,
  address: '',
  allAccounts: [],
  mode: CHAIN_NAMES.POLKADOT,
  substrateAddress: '',
  stash: '',
  controller: '',
  status: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case SET_AUTH_POLKADOT:
      if (
        !action.payload.address ||
        !action.payload.type ||
        !Object.values(TYPE_AUTH).includes(action.payload.type)
      ) {
        return state;
      }
      return {
        ...state,
        auth: action.payload.type,
        address: action.payload.address,
        substrateAddress: action.payload.substrateAddress,
        stash: action.payload.stash,
        controller: action.payload.controller,
      };
    case LOGOUT_POLKADOT:
      return _.cloneDeep(DEFAULT_FIELDS);
    case GET_ALL_POLKADOT_ACCOUNTS:
      return {
        ...state,
        allAccounts: action.payload.allAccounts,
      };
    // Helper variables to allow only one polkadot modal handling both logins
    case POLKADOT_AUTH_MODE:
      return {
        ...state,
        mode: CHAIN_NAMES.POLKADOT,
      };
    case KUSAMA_AUTH_MODE:
      return {
        ...state,
        mode: CHAIN_NAMES.KUSAMA,
      };
    case POLKADOT_SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
        withdrowedIndex: action.payload.withdrowedIndex,
      };
    default:
      return state;
  }
};
