import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './input.module.scss';

const Input = ({ className, value, icon, onChange, rightLabel, ...props }) => {
  const dark = useSelector((state) => state.basic.dark);
  return (
    <div className={cn(styles.container, className, { [styles.dark]: dark })}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <input value={value} onChange={onChange} {...props} />
      {rightLabel && <div className={styles.rightLabel}>{rightLabel}</div>}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  className: '',
  value: '',
  onChange: () => {},
};

export default Input;
