import styled from 'styled-components';

const StyledHr = styled.hr`
  border: none;
  border-top: solid 1px #4f5460;
`;

export function HorizontalRule() {
  return <StyledHr />;
}
