import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Text from '../../../external/components/Text/Text';
import moneyHelper from '../../../helpers/money-helper';
import { useValidatorAccountingDataHook } from '../hooks/delegator.hook';
import { StatCallout } from './StatCallout';

const StatRow = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  overflow-x: scroll;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledStatCallout = styled(StatCallout)`
  margin-top: 1.5rem;

  @media screen and (min-width: 768px) {
    border-left: solid grey 1px;
    margin-top: 0;
    padding-left: 2rem;
    margin-left: 2rem;
  }
`;

const NodeRow = styled.div`
  background: #171616;
  padding: 1.5rem 1rem;
  border-radius: 14px;
  margin-top: 1rem;
`;

export function NodeInfo() {
  const HARDCODED_CHAIN_ID = 2;

  // Validator value data
  const [getValidatorAccountingInfo, validators, validatorsLoading, validatorsError] =
    useValidatorAccountingDataHook(HARDCODED_CHAIN_ID);

  useEffect(() => {
    getValidatorAccountingInfo();
  }, []);

  let sum = 0;

  (async (validators: any) => {
    for (let i = 0; i < validators.length; i++) {
      sum += validators[i].usdValue;
    }
  })(validators);

  return (
    <>
      <NodeRow>
        <Text size='sm' dark={true}>
          Total staked to MDAO nodes
        </Text>
        <StyledStatCallout label={`Total`} value={`${moneyHelper.formatUSD(sum)}`} dark={true} />
        <StatRow>
          {validators
            ? validators.map(function (validator: any, i) {
                return (
                  <StyledStatCallout
                    label={`${validator.chainName.toUpperCase()}`}
                    value={validator.nodeValue}
                    dark={true}
                    key={i}
                  />
                );
              })
            : null}
        </StatRow>
      </NodeRow>
    </>
  );
}
