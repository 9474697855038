import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import TokenTitle from './token-title';
import TokenLine from './token-line';
import styles from './token-column.module.scss';

const TokenColumn = ({ className, title, list, tooltipText, isPaused }) => (
  <div className={cn(className, styles.wrapper)}>
    <TokenTitle className={styles.tokenTitle} text={title} info={tooltipText} />
    {list.map((item) => (
      <TokenLine className={styles.line} key={item.apyName} isPaused={isPaused} {...item} />
    ))}
  </div>
);
TokenColumn.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  tooltipText: PropTypes.string,
};

TokenColumn.defaultProps = {
  className: '',
  tooltipText: '',
};

export default React.memo(TokenColumn);
