import networkConstants from '../../../constants/network.constant';
import { NetworkId } from '../../../types/network.types';

export function getNetworkText(networkId: NetworkId) {
  switch (networkId) {
    case networkConstants.networkId.bscTestnet:
    case networkConstants.networkId.bscMainnet:
      return 'BSC';
    case networkConstants.networkId.ethKovan:
      return 'KOVAN';
    case networkConstants.networkId.ethRinkeby:
      return 'RINKEBY';
    case networkConstants.networkId.ethMainnet:
      return 'ETH';
    case networkConstants.networkId.polyTestnet:
    case networkConstants.networkId.polyMainnet:
      return 'POLYGON';
    default:
      return 'ETH';
  }
}
