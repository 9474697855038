// import React from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connectModal } from 'redux-modal';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from './distribution-om-success.module.scss';
import { OM } from '../../../constants/blockchain-constants';
import { useGetTheme } from '../../../hooks/useGetTheme';
import { DISTRIBUTION_OM_SUCCESS_MODAL } from '../../../constants/modal-constants';
import tick from '../../../assets/images/tick.svg';
import TxID from '../../txID';

const DistributionOmSuccessModal = ({ show, transactionHash, withdrawn }) => {
  const dispatch = useDispatch();
  const colors = useGetTheme();
  const dark = useSelector((state) => state.basic.dark);

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={cn(s.container, { [s.dark]: dark })}>
        <div className={s.header}>
          <div className={s.headerTitle}>Enhanced OM Rewards</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(DISTRIBUTION_OM_SUCCESS_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.cardContent} style={{ color: colors.text }}>
          <div className={s.icon}>
            <img src={tick} alt='' />
          </div>
          <div className={s.withdrawnValue} style={{ color: theme.palette.mantra.pink }}>
            {withdrawn} {OM}
          </div>
          <div className={s.status}>Withdrawn</div>
          <TxID className={s.txID} id={transactionHash} />
          <div className={s.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              className={s.doneButton}
              onClick={() => dispatch(modalActions.hideModal(DISTRIBUTION_OM_SUCCESS_MODAL))}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

DistributionOmSuccessModal.propTypes = {
  show: PropTypes.bool,
  transactionHash: PropTypes.string,
  withdrawn: PropTypes.string,
};

DistributionOmSuccessModal.defaultProps = {
  show: false,
  transactionHash: '',
  withdrawn: '0',
};

export default connectModal({ name: DISTRIBUTION_OM_SUCCESS_MODAL })(DistributionOmSuccessModal);
