import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ACCOUNT_MODAL, LOGIN_MODAL } from '../../../constants/modal-constants';
import { TYPE_AUTH_TITLE } from '../../../constants/account-contants';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import { CopyIcon, ViewIcon } from '../../../constants/icon-constants';
import cutWalletAddress from '../../../util/cutWalletAddress';
import networkHelpers from '../../../helpers/network.helpers';
import networkConstants from '../../../constants/network.constant';
import config from '../../../config/env';

import s from './account-modal.module.scss';

const AccountModal = ({ show }) => {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);
  const { address, auth: typeAuth, networkId } = useSelector((state) => state.account);
  const networkType = networkHelpers.findNetworkType(networkId);

  const showLoginModal = () => {
    dispatch(modalActions.showModal(LOGIN_MODAL));
  };

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(s.dialog, { [s.dark]: dark })}
    >
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.headerTitle}>MetaMask</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(ACCOUNT_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.body}>
          <div className={s.block}>
            <div className={s.blockHeader}>
              <div>
                <div className={s.blockHeaderTitle}>Connected with {TYPE_AUTH_TITLE[typeAuth]}</div>
                <div className={s.address}>
                  <div className={s.addressIcon} style={{ background: '#3AAA45' }} />
                  <div className={s.addressText}>{cutWalletAddress(address, 5)}</div>
                </div>
              </div>
              <Button variant='outlined' className={s.disconnect} onClick={() => showLoginModal()}>
                Connect
                <br />
                other Wallet
              </Button>
            </div>
            <div className={s.controls}>
              <CopyToClipboard text={address}>
                <button className={s.control}>
                  {CopyIcon}
                  Copy Address
                </button>
              </CopyToClipboard>
              {networkType === networkConstants.networkType.binance && (
                <a
                  href={`${config.REACT_APP_BSC_URL}/address/${address}`}
                  target='_blank noreferrer'
                  className={s.control}
                >
                  {ViewIcon}
                  View on BscScan
                </a>
              )}
              {networkType === networkConstants.networkType.polygon && (
                <a
                  href={`${config.REACT_APP_POLYGON_URL}/address/${address}`}
                  target='_blank noreferrer'
                  className={s.control}
                >
                  {ViewIcon}
                  View on Polygonscan
                </a>
              )}
              {networkType === networkConstants.networkType.ethereum && (
                <a
                  href={`${config.REACT_APP_ETHERSCAN_URL}/address/${address}`}
                  target='_blank noreferrer'
                  className={s.control}
                >
                  {ViewIcon}
                  View on Etherscan
                </a>
              )}
            </div>
          </div>
          <div className={s.balanceTable}>
            <div className={s.balanceTableHeader}>
              <div>Asset Balance</div>
              <div>Current Staked</div>
            </div>
            {networkType === networkConstants.networkType.ethereum && (
              <>
                {/* TODO: Uncomment */}
                {/* <Spwn /> */}
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AccountModal.propTypes = {
  show: PropTypes.bool,
};

AccountModal.defaultProps = {
  show: false,
};

export default connectModal({ name: ACCOUNT_MODAL })(AccountModal);
