import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import styles from './modal-button.module.scss';

const ModalButton = ({ className, text, styleType, onClick, disabled }) => (
  <Button
    variant='contained'
    color='secondary'
    className={cn(className, styles.wrapper, styles.button, {
      [styles.gray]: disabled || styleType === 'gray',
    })}
    onClick={() => onClick()}
    disabled={disabled}
  >
    {text}
  </Button>
);
ModalButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  styleType: PropTypes.oneOf(['', 'gray']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ModalButton.defaultProps = {
  disabled: false,
  className: '',
  text: '',
  styleType: '',
  onClick: () => {},
};

export default React.memo(ModalButton);
