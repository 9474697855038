import Web3 from 'web3';

import networkConstant from '../constants/network.constant';

export default {
  getInstanceByNetworkId: function (networkId?: number) {
    switch (networkId) {
      case networkConstant.networkId.bscMainnet:
        return new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.defibit.io'));
      case networkConstant.networkId.bscTestnet:
        return new Web3(
          new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'),
        );
      case networkConstant.networkId.ethKovan:
        return new Web3(
          new Web3.providers.HttpProvider(
            'https://kovan.infura.io/v3/075d04ba1bb14e568eba58825ba1c039',
          ),
        );
      case networkConstant.networkId.ethRinkeby:
        return new Web3(
          new Web3.providers.HttpProvider(
            'https://rinkeby.infura.io/v3/075d04ba1bb14e568eba58825ba1c039',
          ),
        );
      case networkConstant.networkId.ethMainnet:
        return new Web3(
          new Web3.providers.HttpProvider(
            'https://mainnet.infura.io/v3/07ba79fed2cd462086c8089d7cd7af5e',
          ),
        );
      case networkConstant.networkId.polyMainnet:
        return new Web3(
          new Web3.providers.HttpProvider(
            'https://polygon-mainnet.infura.io/v3/07ba79fed2cd462086c8089d7cd7af5e',
          ),
        );
      case networkConstant.networkId.polyTestnet:
        return new Web3(new Web3.providers.HttpProvider('https://rpc-mumbai.matic.today'));
      default:
        return new Web3();
    }
  },
};
