export const ACCOUNT_MODAL = 'account-modal';
export const LOGIN_MODAL = 'login-modal';
export const INFO_MODAL = 'info-modal';
export const BLACKLISTED_MODAL = 'blacklisted-modal';

export const POLKADOT_ACCOUNT_MODAL = 'polkadot-account-modal';
export const KUSAMA_ACCOUNT_MODAL = 'kusama-account-modal';
export const POLKADOT_LOGIN_MODAL = 'polkadot-login-modal';
export const JOIN_POOL_MODAL = 'join-pool-modal';
export const POOL_RESULT_MODAL = 'pool-result-modal';
export const POOL_ROUND_MODAL = 'pool-round-modal';
export const POOL_PROVABLY_MODAL = 'pool-provably-modal';
export const CLAIM_REWARDS_MODAL = 'claim-rewards-modal';
export const SUPPLY_MODAL = 'supply-modal';
export const BORROW_MODAL = 'borrow-modal';
export const ENABLE_COLLATERAL_MODAL = 'enable-collateral-modal';
export const DISABLE_COLLATERAL_MODAL = 'disable-collateral-modal';
// eslint-disable-next-line max-len
export const BORROW_LIMIT_TOOLTIP_TEXT =
  "Borrow Limit represents the USD equivalent value that you can borrow based on your supplied collateral. Each asset supplied has a different Collateral Factor that determines what proportion of the asset's value can be borrowed.";
export const DISTRIBUTION_OM_REWARDS_MODAL = 'ditribution-om-rewards-modal';
export const DISTRIBUTION_OM_SUCCESS_MODAL = 'ditribution-om-success-modal';

export const POOL_CLAIM_MODAL = 'pool-claim-modal';
