import moment from 'moment';

// const ONE_HOUR_MS = 60 * 60 * 1000;

export const timeHelpers = {
  calculateUnstakingAvail: function (duration: number | string, applicableAt: number) {
    // the duration on the contract is always in seconds

    const startStakeTime = moment.unix(applicableAt);
    const endStakeTime = startStakeTime.add(duration, 'milliseconds');
    const now = moment();
    const timeUntil = now.diff(endStakeTime);

    if (now.isAfter(endStakeTime)) {
      return 'now';
    } else {
      return moment.duration(timeUntil).humanize();
    }
  },
  addTime: function (duration: number | string, startTime: number) {
    const startStakeTime = moment.unix(startTime);
    const endStakeTime = startStakeTime.add(duration, 'seconds');
    const now = moment();
    const timeUntil = now.diff(endStakeTime);

    if (now.isAfter(endStakeTime)) {
      return 'now';
    } else {
      return moment.duration(timeUntil).humanize();
    }
  },
  checkWhenAvailable: function (duration: number | string, startTime: number) {
    const startingTime = moment.unix(startTime);
    const endTime = startingTime.add(duration, 's');
    const now = moment();
    const timeUntil = now.diff(endTime);

    if (now.isAfter(endTime)) {
      return 'Available now';
    } else {
      return `Available in ${moment.duration(timeUntil).humanize()}`;
    }
  },

  isAvailable: function (duration: number | string, startTime: number) {
    const startingTime = moment.unix(startTime);
    const endTime = startingTime.add(duration, 's');
    const now = moment();

    if (now.isAfter(endTime)) {
      return true;
    } else {
      return false;
    }
  },

  humaniseTime: function (duration: number) {
    return moment.duration(duration * 1000).humanize();
  },
  isAfter: function (time1: number, time2: number) {
    return moment(time1).isAfter(time2);
  },
  shortDate: function (unixTimestamp: number) {
    const localDate = new Date(unixTimestamp * 1000);
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      day: '2-digit',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
    return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(localDate);
  },
};
