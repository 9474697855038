import React from 'react';
import PropTypes from 'prop-types';
import TokenColumn from '../../../../../screens/lending/blocks-for-lending-modals/token-column';
import ModalButton from '../../../../../screens/lending/blocks-for-lending-modals/modal-button';
import styles from './repay-row.module.scss';

const RepayRow = ({ tokenColumn, borrowColumn, disabled, onClick }) => (
  <>
    <div className={styles.row}>
      <TokenColumn title='Borrow Rates' list={tokenColumn} />
      <TokenColumn title='Borrow Limit' list={borrowColumn} />
    </div>
    <div className={styles.buttonWrapper}>
      <ModalButton text='Repay' disabled={disabled} onClick={onClick} />
    </div>
  </>
);

RepayRow.propTypes = {
  tokenColumn: PropTypes.array,
  borrowColumn: PropTypes.array,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

RepayRow.defaultProps = {
  tokenColumn: [],
  borrowColumn: [],
  disabled: false,
  onClick: () => {},
};

export default React.memo(RepayRow);
