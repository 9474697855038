import {
  DelegatorChainIds,
  DelegatorDataType,
  DelegatorRewardRecord,
  initialDelegatorState,
} from '../services/delegator/delegator.data';
import { DelegatorLink } from '../services/delegator/delegator.types';

export enum DelegatorActionEnum {
  SET_UNCLAIM_AMOUNT = 'SET_UNCLAIM_AMOUNT',
  SET_META_DATA = 'SET_META_DATA',
  SET_REWARD_CLAIM_PERIOD = 'SET_REWARD_CLAIM_PERIOD',
  SET_TRANSACTION_ID = 'SET_TRANSACTION_ID',
  SET_REWARDS_ARRAY = 'SET_REWARDS_ARRAY',
  SET_WITHDRAW_AMOUNT = 'SET_WITHDRAW_AMOUNT',
  SET_SELECTED_CHAIN = 'SET_SELECTED_CHAIN',
  SET_DELEGATOR_LINKS = 'SET_DELEGATOR_LINKS',
  SET_DELEGATED_AMOUNT = 'SET_DELEGATED_AMOUNT',
  SET_HAS_LINKED = 'SET_HAS_LINKED',
  SET_TOTAL_DELEGATED_TO_NODE = 'SET_TOTAL_DELEGATED_TO_NODE',
  SET_APY = 'SET_APY',
}

type setApy = {
  type: DelegatorActionEnum.SET_APY;
  payload: {
    chainId: DelegatorChainIds;
    apy: number;
  };
};

type setTotalDelegatedToNode = {
  type: DelegatorActionEnum.SET_TOTAL_DELEGATED_TO_NODE;
  payload: {
    chainId: DelegatorChainIds;
    totalDelegatedToNode: number;
  };
};

type setHasLinked = {
  type: DelegatorActionEnum.SET_HAS_LINKED;
  payload: {
    chainId: DelegatorChainIds;
    hasLinked: boolean;
  };
};

type setDelegatedAmount = {
  type: DelegatorActionEnum.SET_DELEGATED_AMOUNT;
  payload: {
    chainId: DelegatorChainIds;
    delegatedAmount: string;
  };
};

type setDelegatorLinks = {
  type: DelegatorActionEnum.SET_DELEGATOR_LINKS;
  payload: {
    chainId: DelegatorChainIds;
    delegatorLinks: DelegatorLink[];
  };
};

type setSelectedChain = {
  type: DelegatorActionEnum.SET_SELECTED_CHAIN;
  payload: { chainId: DelegatorChainIds };
};

type SetWithdrawAmount = {
  type: DelegatorActionEnum.SET_WITHDRAW_AMOUNT;
  payload: string;
};

type SetRewardArray = {
  type: DelegatorActionEnum.SET_REWARDS_ARRAY;
  payload: {
    chainId: DelegatorChainIds;
    rewardArray: DelegatorRewardRecord[];
  };
};

type SetMetaData = {
  type: DelegatorActionEnum.SET_META_DATA;
  payload: {
    chainId: DelegatorChainIds;
    metadata: {
      longClaimPeriod: string;
      shortClaimPeriod: string;
      shortRewardPercentage: string;
    };
  };
};

type SetTransactionId = {
  type: DelegatorActionEnum.SET_TRANSACTION_ID;
  payload: string;
};
type SetRewardClaimPeriod = {
  type: DelegatorActionEnum.SET_REWARD_CLAIM_PERIOD;
  payload: { selectedRewardType: 'LONG' | 'SHORT' };
};

type SetUnclaimAmount = {
  type: DelegatorActionEnum.SET_UNCLAIM_AMOUNT;
  payload: { chainId: DelegatorChainIds; unclaimedReward: string };
};

export type DelegatorActionType =
  | SetUnclaimAmount
  | SetMetaData
  | SetRewardClaimPeriod
  | SetTransactionId
  | SetRewardArray
  | SetWithdrawAmount
  | setSelectedChain
  | setDelegatorLinks
  | setDelegatedAmount
  | setHasLinked
  | setTotalDelegatedToNode
  | setApy;

const DelegatorReducer = (
  state: DelegatorDataType = initialDelegatorState,
  action: DelegatorActionType,
) => {
  switch (action.type) {
    case DelegatorActionEnum.SET_APY:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId ? { ...data, apy: action.payload.apy } : data,
        ),
      };
    case DelegatorActionEnum.SET_TOTAL_DELEGATED_TO_NODE:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, totalDelegatedToNode: action.payload.totalDelegatedToNode }
            : data,
        ),
      };
    case DelegatorActionEnum.SET_HAS_LINKED:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, hasLinked: action.payload.hasLinked }
            : data,
        ),
      };
    case DelegatorActionEnum.SET_DELEGATED_AMOUNT:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, totalDelegated: action.payload.delegatedAmount }
            : data,
        ),
      };

    case DelegatorActionEnum.SET_DELEGATOR_LINKS:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, delegatorLinks: action.payload.delegatorLinks }
            : data,
        ),
      };
    case DelegatorActionEnum.SET_SELECTED_CHAIN:
      return {
        ...state,
        selectedChainId: action.payload.chainId,
      };
    case DelegatorActionEnum.SET_UNCLAIM_AMOUNT:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, unclaimedRewards: action.payload.unclaimedReward }
            : data,
        ),
      };
    case DelegatorActionEnum.SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
      };
    case DelegatorActionEnum.SET_REWARD_CLAIM_PERIOD:
      return {
        ...state,
        selectedRewardType: action.payload.selectedRewardType,
      };
    case DelegatorActionEnum.SET_WITHDRAW_AMOUNT:
      return {
        ...state,
        withdrawnAmount: action.payload,
      };
    case DelegatorActionEnum.SET_META_DATA:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, metadata: { ...action.payload.metadata } }
            : data,
        ),
      };
    case DelegatorActionEnum.SET_REWARDS_ARRAY:
      return {
        ...state,
        delegatorData: state.delegatorData.map((data) =>
          data.chainId === action.payload.chainId
            ? { ...data, rewardArray: action.payload.rewardArray }
            : data,
        ),
      };
    default:
      return state;
  }
};

export default DelegatorReducer;
