/**
 * @TODO Resolve using `a` elements without a link
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ethIcon from '../../../assets/images/wallets/eth.svg';
import polyIcon from '../../../assets/images/wallets/poly.svg';
import bscIcon from '../../../assets/images/wallets/bsc.svg';
import arrowIcon from '../../../assets/images/arrow.svg';

import styles from './login-modal.module.scss';

const Item = ({ title, icon, active, onClick, disabled, supportedNetworks }) => {
  const dark = useSelector((state) => state.basic.dark);
  return (
    <li className={cn(styles.item, { [styles.itemDisabled]: disabled })}>
      <a
        className={styles.itemInner}
        href='#'
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        <div className={cn(styles.logo, { [styles.active]: active })}>
          <img alt='' src={icon} />
          <span className={cn(styles.status, { [styles.active]: active })} />
        </div>
        <span style={{ color: dark ? '#ffffff' : '#201f1e' }}>
          {title}
          {supportedNetworks && (
            <span style={{ display: 'flex', width: '100%', margin: '0.25rem 0 0 -0.25rem' }}>
              {supportedNetworks?.eth && (
                <img style={{ width: '20px', height: '20px' }} src={ethIcon} alt='Ethereum icon' />
              )}
              {supportedNetworks?.bsc && (
                <img style={{ width: '20px', height: '20px' }} src={bscIcon} alt='Binance icon' />
              )}
              {supportedNetworks?.poly && (
                <img style={{ width: '20px', height: '20px' }} src={polyIcon} alt='Polygon icon' />
              )}
            </span>
          )}
        </span>
        {disabled ? (
          <span className={styles.disabledText} style={{ color: dark ? '#ffffff' : '#606371' }}>
            Soon
          </span>
        ) : (
          <img className={styles.arrow} src={arrowIcon} alt='' />
        )}
      </a>
    </li>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  supportedNetworks: PropTypes.shape({
    eth: PropTypes.bool,
    bsc: PropTypes.bool,
  }),
};

Item.defaultProps = {
  disabled: false,
  active: false,
  onClick: () => {},
  supportedNetworks: undefined,
};

export default Item;
