import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../actions/delegator-actions';
import loading from '../../../components/modals/claim-rewards-modal/loading';
import Button from '../../../external/components/Button/Button';
import Icon from '../../../external/components/Icon/Icon';
import { Skeleton } from '../../../external/components/SkeletonLoading/Skeleton';
import Text from '../../../external/components/Text/Text';
import { State } from '../../../reducers';
import { DelegatorChainIds, DelegatorDataType } from '../../../services/delegator/delegator.data';
import { useDelegatorClaim } from '../hooks/claim.hook';
import { StyledWrapper } from './CardWrapper';
import { StyledLinkBadge } from './LinkedBadge';

interface EvmCardProps {
  className?: string;
  chainId: DelegatorChainIds;
  showEvmSidebar: boolean;
  setShowEvmSidebar: Dispatch<React.SetStateAction<boolean>>;
}

export function EvmCard({ chainId, showEvmSidebar, setShowEvmSidebar, ...props }: EvmCardProps) {
  const [loading, setLoading] = useState(false);
  const { walletNetworkId, delegatorData, currencies } = useSelector(
    ({ account, delegators, basic }: State) => ({
      walletNetworkId: account.networkId,
      currencies: basic.currency.USD,
      delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
        (del) => del.chainId === chainId,
      )[0],
    }),
  );

  const { hasLinked, name, chainSymbol, totalDelegated, totalMonthlyRewards, networkId, apy } =
    delegatorData;
  const dispatch = useDispatch();
  const { setSelectedChainId } = bindActionCreators(DelegatorActions, dispatch);
  const { getDelegationPoly, getLink, getTotalDelegatedToNode, getPolyApy } = useDelegatorClaim({
    chainId,
    walletNetworkId,
    networkId,
  });

  const init = async () => {
    try {
      setLoading(true);
      if (!apy) {
        await Promise.all([
          getDelegationPoly(),
          getLink(),
          getTotalDelegatedToNode(),
          getPolyApy(totalMonthlyRewards, chainSymbol),
        ]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [showEvmSidebar]);

  const renderButtonText = () => {
    if (!hasLinked) return 'Start linking';
    if (parseFloat(totalDelegated) > 0 && hasLinked) return 'View nomination';
    if (parseFloat(totalDelegated) > 0 && !hasLinked) return 'Start linking';
    return 'View nomination';
  };

  return (
    <StyledWrapper {...props}>
      {loading ? (
        <>
          <Skeleton variant='circle' height='50px' width='50px' dark={true} />
          <Skeleton height='18px' width='60px' dark={true} />
          <br />
          <Skeleton height='40px' width='90px' dark={true} />
          <Skeleton height='32px' width='80px' dark={true} />
        </>
      ) : (
        <>
          {hasLinked && (
            <StyledLinkBadge>
              <span>LINKED</span>
            </StyledLinkBadge>
          )}
          <Icon.Chain type={chainSymbol} width='45' />
          <Text dark={true}>{name}</Text>
          <Text weight={500} color='grey' size='sm' style={{ marginTop: '-0.5rem' }} as='span'>
            Additional APR
          </Text>
          <Text color='pink' size='xl' weight={400}>
            {'PAUSED'}
          </Text>

          <Button
            onClick={() => {
              setSelectedChainId({ chainId });
              setShowEvmSidebar(true);
            }}
            variant='secondary'
            dark
          >
            {renderButtonText()}
          </Button>
        </>
      )}
    </StyledWrapper>
  );
}
