/**
 * @TODO Resolve using `a` elements without a link
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import { LOGIN_MODAL } from '../../../constants/modal-constants';
import arrowBackIcon from '../../../assets/images/arrow-back.svg';
// import theme from '../../../theme';
import EthWallets from './eth-wallets';
import Main from './main';
import s from './login-modal.module.scss';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-white-icon.svg';
import SubstrateWallets from './substrate-wallets';

const renderStep = (step, setActiveStep, global) => {
  switch (step) {
    case 2:
      return <EthWallets global={global} />;
    case 3:
      return <SubstrateWallets global={global} />;
    default:
      return <Main global={global} setActiveStep={setActiveStep} />;
  }
};

const LoginModal = ({ show, global }) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);

  // const colors = () => {
  //   if (global.dark) {
  //     return theme.palette.dark;
  //   }
  //   return theme.palette.light;
  // };

  return (
    <Dialog open={show} className={s.modal}>
      <div className={s.container} style={{ backgroundColor: global.dark ? '#282626' : '#F1F3F9' }}>
        {activeStep !== 1 ? (
          <button className={s.back} onClick={() => setActiveStep(1)}>
            <img src={arrowBackIcon} alt='back' />
          </button>
        ) : (
          <button className={s.close} onClick={() => dispatch(modalActions.hideModal(LOGIN_MODAL))}>
            <img src={closeIcon} alt='close' />
          </button>
        )}
        {renderStep(activeStep, setActiveStep, global)}
      </div>
    </Dialog>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool,
  global: PropTypes.object.isRequired,
};

LoginModal.defaultProps = {
  show: false,
};

export default connectModal({ name: LOGIN_MODAL })(LoginModal);
