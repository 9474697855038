import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 95 95'
      className={className}
      {...otherProps}
    >
      <g filter='url(#filter0_d_bondly)'>
        <path
          fill='#1940B0'
          d='M47.28 86.56c23.903 0 43.28-19.377 43.28-43.28C90.56 19.378 71.184 0 47.28 0 23.378 0 4 19.378 4 43.28c0 23.903 19.378 43.28 43.28 43.28z'
        ></path>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M46.478 8l30.066 17.793v7.732l-14.937 8.99 14.937 8.907v8.439l-28.58 17.32-8.219-4.925v-16.59L25.437 64.27 17 59.228V25.741l8.21-4.94 14.535 8.672V12.055L46.478 8zm-6.733 30.238l-14.52-8.663-.697.42V54.96l.897.536 14.32-8.613v-8.644zm7.527 12.9l7.021-4.223 14.661 8.745-21.011 12.733-.67-.402V51.138zm7.004-12.997l-7.004-4.177V17.216L68.334 29.68l-14.058 8.461z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_bondly'
          width='94.561'
          height='94.561'
          x='0'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow'></feBlend>
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape'></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
