import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Details from './details';
import Success from './success';
import Loading from './loading';
import Error from './error';

import { getCurrentAddressAndContract } from '../../../actions/mantra-pool-actions';
import * as modalActions from '../../../actions/modal-actions';

import { CLAIM_REWARDS_MODAL } from '../../../constants/modal-constants';

import closeIcon from '../../../assets/images/close-icon.svg';
import JoinPoolIcon from '../../../assets/mantra-pool/join-pool.svg';

import stylesModal from '../modal.module.scss';
import styles from './claim-rewards-modal.module.scss';

const ClaimRewardsModal = ({ show, roundIndex, onSuccess }) => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.account.address);
  const dark = useSelector((state) => state.basic.dark);
  const [step, setStep] = useState(1);
  const [amountReward, setAmountReward] = useState('0');
  const [error, setError] = useState(null);
  const { currentContract } = dispatch(getCurrentAddressAndContract());

  useEffect(() => {
    (async () => {
      try {
        const data = await currentContract.roundParticipant(roundIndex, address);
        setAmountReward(data.reward);
        setStep(2);
      } catch (e) {
        setError(e.message);
        setStep(4);
      }
    })();
  }, [roundIndex, address]);

  const sendTransaction = async () => {
    try {
      setStep(1);
      await currentContract.withdrawRewards(address, roundIndex);
      setStep(3);
    } catch (e) {
      setError(e.message);
      setStep(4);
    }
  };

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(stylesModal.dialog, stylesModal.poolResult, { [styles.dark]: dark })}
    >
      <div className={cn(stylesModal.container)}>
        <div className={stylesModal.header}>
          <div className={stylesModal.headerTitle}>
            <img className={stylesModal.headerIcon} src={JoinPoolIcon} alt='' />
            <span>Claim POOL Prize</span>
          </div>
          <button
            className={stylesModal.close}
            onClick={() => dispatch(modalActions.hideModal(CLAIM_REWARDS_MODAL))}
          >
            <img src={closeIcon} alt='close' />
          </button>
        </div>
        {step === 1 && <Loading />}
        {step === 2 && <Details amountReward={amountReward} onClick={() => sendTransaction()} />}
        {step === 3 && (
          <Success
            amountReward={amountReward}
            onClick={() => {
              dispatch(modalActions.hideModal(CLAIM_REWARDS_MODAL));
              onSuccess();
            }}
          />
        )}
        {step === 4 && <Error text={error} />}
      </div>
    </Dialog>
  );
};

ClaimRewardsModal.propTypes = {
  show: PropTypes.bool,
  roundIndex: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

ClaimRewardsModal.defaultProps = {
  show: false,
};

export default connectModal({ name: CLAIM_REWARDS_MODAL })(ClaimRewardsModal);
