import _ from 'lodash';
import BN from 'bignumber.js';

import {
  LENDING_NET_APY,
  LENDING_SUPPLY_BALANCE,
  LENDING_BORROW_BALANCE,
  LENDING_TOKENS,
  LENDING_ACCOUNT_LIQUIDITY,
  LENDING_BORROW_LIMIT,
  LENDING_DISTRIBUTED_OM_REWARD,
  LENDING_WALLET_BALANCE,
} from '../actions/action-types';

const DEFAULT_FIELDS = {
  netApyValue: BN(0),
  borrowLimitEth: BN(0),
  borrowLimitUsd: BN(0),
  borrowLimitPercent: BN(0),
  supplyBalanceUsd: BN(0),
  borrowedBalanceUsd: BN(0),
  accountLiquidity: new BN(0),
  allocatedOmReward: new BN(0),
  omRewardsApy: new BN(0),
  supplyTokens: [],
  borrowTokens: [],
  commonSupplyTokens: [],
  commonBorrowTokens: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case LENDING_DISTRIBUTED_OM_REWARD:
      return { ...state, allocatedOmReward: action.payload };
    case LENDING_NET_APY:
      return { ...state, netApyValue: action.payload };
    case LENDING_SUPPLY_BALANCE:
      return { ...state, supplyBalanceUsd: action.payload };
    case LENDING_WALLET_BALANCE:
      return { ...state, walletBalanceUsd: action.payload };
    case LENDING_BORROW_BALANCE:
      return { ...state, borrowedBalanceUsd: action.payload };
    case LENDING_TOKENS: {
      const { supplyTokens, borrowTokens, commonSupplyTokens, commonBorrowTokens } = action.payload;
      return {
        ...state,
        supplyTokens,
        borrowTokens,
        commonSupplyTokens,
        commonBorrowTokens,
      };
    }
    case LENDING_ACCOUNT_LIQUIDITY: {
      return { ...state, accountLiquidity: action.payload };
    }
    case LENDING_BORROW_LIMIT: {
      const { valueUsd, valueEth, percent } = action.payload;
      return {
        ...state,
        borrowLimitUsd: valueUsd,
        borrowLimitEth: valueEth,
        borrowLimitPercent: percent,
      };
    }
    default:
      return state;
  }
};
