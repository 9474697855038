import BN from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@material-ui/core';
import styles from '../join-pool-modal.module.scss';
import stylesModal from '../../modal.module.scss';
import { formatToken } from '../../../../helpers/token-helper';
import { getWinChance } from '../../../../actions/mantra-pool-actions';

const showPercent = (percent) => {
  if (percent === 1) {
    return '100';
  }

  if (percent === 0) {
    return '0';
  }

  return (percent * 100).toFixed(4);
};

const Form = ({ amount: startAmount, onSubmit }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(startAmount);
  const [currentWinChance, setCurrentWinChance] = useState(0);
  const { networkId } = useSelector((state) => state.account);
  const [newWinChance, setNewWinChance] = useState(0);
  const { currentRound, userEntries } = useSelector((state) => state.mantraPool);
  const address = useSelector((state) => state.account.address);

  const omV2Balance = useSelector((state) => state.token.omV2Balance);
  const omBscBalance = useSelector((state) => state.token.omBscBalance);
  const omPolygonBalance = useSelector((state) => state.token.omPolygonBalance);

  let omBalance = 0;
  if (networkId === 1) {
    omBalance = omV2Balance;
  } else if (networkId === 56) {
    omBalance = omBscBalance;
  } else if (networkId === 137) {
    omBalance = omPolygonBalance;
  }

  useEffect(() => {
    dispatch(getWinChance(address, currentRound, amount)).then((data) => setNewWinChance(data));
    // eslint-disable-next-line
  }, []);

  const isDisableButton = () => {
    if (new BN(amount).isLessThan(1) || new BN(amount * 10 ** 18).isGreaterThan(omBalance)) {
      return true;
    }
    return false;
  };

  const handleAmountChange = (value) => {
    if (Number(value) >= 0) {
      const newAmount = Number(value).toFixed(0);
      setAmount(newAmount);
      dispatch(getWinChance(address, currentRound)).then((data) => setCurrentWinChance(data));
      dispatch(getWinChance(address, currentRound, newAmount)).then((data) =>
        setNewWinChance(data),
      );
    }
  };

  const updatedBalance = new BN(omBalance).minus(amount * 10 ** 18).toString(10);

  return (
    <div className={cn(stylesModal.body, styles.body)}>
      {networkId === 1 ? (
        <div className={styles.overhead}>Mantra pool is live on BSC and POLYGON!</div>
      ) : (
        ''
      )}
      <div className={cn(styles.row, styles.accent)}>
        <div style={{ whiteSpace: 'nowrap' }} className={styles.overhead}>
          Join the MANTRA POOL by spending OM Tokens
        </div>
      </div>
      <div className={styles.rowHeading}>
        <div className={styles.rowHeadingValue}>Current Round:</div>
        <div className={styles.rowHeadingValue}>{currentRound + 1}</div>
      </div>
      <div className={cn(styles.row, styles.accent)}>
        <div className={styles.rowValue}>Your Entries</div>
        <div className={styles.rowValue}>{userEntries}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowValue}>Win Chance</div>
        <div className={styles.rowValue}>{showPercent(currentWinChance)}%</div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>Add Entries</div>
        <input
          className={styles.input}
          value={amount}
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <div className={styles.inputDescription}>1 OM spent = 1 Entry</div>
      </div>
      <div className={cn(styles.row, styles.accent)}>
        <div className={styles.rowValue}>Required OM Tokens</div>
        <div className={styles.rowValue}>{amount}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowValue}>OM Balance</div>
        <div className={styles.rowValue}>{formatToken(omBalance)}</div>
      </div>
      <div className={cn(styles.row, styles.accent)}>
        <div className={styles.rowValue}>Your Entries</div>
        <div className={styles.rowValue}>{new BN(userEntries).plus(amount).toString(10)}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowValue}>Win Chance</div>
        <div className={styles.rowValue}>{showPercent(newWinChance)}%</div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={cn(stylesModal.button, styles.button)}
          variant='contained'
          onClick={() => onSubmit(amount)}
          disabled={isDisableButton()}
          color='secondary'
        >
          Next
        </Button>
      </div>
    </div>
  );
};

Form.propTypes = {
  amount: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  amount: '1',
};

export default Form;
