import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;

  button + button {
    margin-left: 1rem;
  }
`;
