import BN from 'bignumber.js';

import ZenTokenService from '../services/contracts/lending/zentoken-contract-service';
import zenEthService from '../services/contracts/lending/zeneth-contract-service';
import { MAX_WITHDRAWAL_PERCENT } from '../constants/lending-constants';
import { toBN } from '../helpers/token-helper';
import { isZenEth } from '../helpers/lending-helper';
import * as lendingActions from './lending-actions';

export const getMaxWithdrawalAmount = (zenToken) => (dispatch, getState) => {
  const { lending } = getState();
  const { accountLiquidity, borrowLimitPercent } = lending;
  const {
    underlyingSupplyBalance,
    underlyingPrice,
    collateral,
    collateralFactor,
    underlyingDecimals,
  } = dispatch(lendingActions.findTokenByAddress(zenToken));
  if (!collateral || borrowLimitPercent.isEqualTo(0)) {
    return underlyingSupplyBalance;
  }

  const underlyingTokenEth = underlyingSupplyBalance.times(underlyingPrice);
  const accoutLiquidityDelta = underlyingTokenEth.times(collateralFactor);
  const maxWithdrawalAccounLiquidity = accountLiquidity.times(MAX_WITHDRAWAL_PERCENT);
  return accoutLiquidityDelta.isGreaterThan(maxWithdrawalAccounLiquidity)
    ? maxWithdrawalAccounLiquidity
        .div(underlyingPrice)
        .div(collateralFactor)
        .dp(underlyingDecimals, BN.ROUND_DOWN)
    : underlyingSupplyBalance;
};

export const getHypotheticalBorrowLimitsAfterWithdrawal =
  (zenToken, amount) => (dispatch, getState) => {
    const { lending, basic } = getState();
    const { supplyTokens, borrowedBalanceUsd } = lending;

    const token = dispatch(lendingActions.findTokenByAddress(zenToken));
    const { collateral } = token;
    if (!collateral) {
      return {
        newBorrowLimitUsd: null,
        newBorrowLimitPercent: null,
      };
    }

    let zenTokenDeltaLiquidity = toBN(0);
    const newBorrowLimit = supplyTokens
      .filter((item) => item.collateral)
      .reduce((acc, i) => {
        const {
          underlyingSupplyBalance,
          underlyingPrice,
          collateralFactor,
          underlyingDecimals,
          zenTokenAddress,
        } = i;
        if (zenToken === zenTokenAddress) {
          const underlyingSupplyDelta = amount ? toBN(amount) : underlyingSupplyBalance;

          zenTokenDeltaLiquidity = underlyingSupplyDelta
            .times(underlyingPrice)
            .times(collateralFactor)
            .dp(underlyingDecimals);
          return acc
            .plus(
              underlyingSupplyBalance
                .minus(underlyingSupplyDelta)
                .times(underlyingPrice)
                .times(collateralFactor),
            )
            .dp(underlyingDecimals);
        }

        return acc.plus(underlyingSupplyBalance.times(underlyingPrice).times(collateralFactor));
      }, toBN(0));

    const { accountLiquidity } = lending;
    const { ETH: ethUsdPrice } = basic.currency.USD;

    const newAccountLiquidityScaled = accountLiquidity.minus(zenTokenDeltaLiquidity);

    const newBorrowLimitPercent = newAccountLiquidityScaled.isEqualTo(0)
      ? newBorrowLimit.isEqualTo(0)
        ? toBN(0)
        : toBN(100)
      : borrowedBalanceUsd.isEqualTo(0)
      ? toBN(0)
      : toBN(1).minus(newAccountLiquidityScaled.div(newBorrowLimit)).times(100);

    return {
      newBorrowLimitUsd: newBorrowLimit.times(ethUsdPrice),
      newBorrowLimitPercent,
    };
  };

export const getHypotheticalBorrowLimitsAfterSupply =
  (zenToken, underlyingTokenAmount) => (dispatch, getState) => {
    const { lending, basic } = getState();
    const { accountLiquidity, borrowLimitEth } = lending;
    const { ETH: ethUsdPrice } = basic.currency.USD;

    const token = dispatch(lendingActions.findTokenByAddress(zenToken));

    const { underlyingPrice, collateralFactor, collateral } = token;

    if (!collateral) {
      return {
        newBorrowLimitUsd: null,
        newBorrowLimitPercent: null,
      };
    }

    const additionalAccountLiquidity = toBN(underlyingTokenAmount)
      .times(collateralFactor)
      .times(underlyingPrice);
    const newAccountLiquidity = accountLiquidity.plus(additionalAccountLiquidity);
    const newBorrowLimit = borrowLimitEth.plus(additionalAccountLiquidity);
    const newBorrowLimitUsd = newBorrowLimit.times(ethUsdPrice);
    const newBorrowLimitPercent = toBN(1).minus(newAccountLiquidity.div(newBorrowLimit)).times(100);

    return {
      newBorrowLimitUsd,
      newBorrowLimitPercent,
    };
  };

export const supply = (zenToken, amount) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  await dispatch(lendingActions.enableToken(zenToken));
  const token = dispatch(lendingActions.findTokenByAddress(zenToken));

  const { underlyingDecimals } = token;
  const underlyingDecimalsNumber = Number(underlyingDecimals);
  const tokenAmount = toBN(amount)
    .times(10 ** underlyingDecimalsNumber)
    .toFixed(0, BN.ROUND_DOWN);
  const zenTokenService = isZenEth(zenToken) ? zenEthService : new ZenTokenService(zenToken);
  await zenTokenService.mint(accountAddress, tokenAmount);
  await dispatch(lendingActions.setLendingData());
};

export const withdraw = (zenToken, amount, isMaxValue) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;
  const token = dispatch(lendingActions.findTokenByAddress(zenToken));

  if (!token) {
    throw new Error('Unknown token');
  }
  const { underlyingDecimals } = token;

  const zenTokenService = isZenEth(zenToken) ? zenEthService : new ZenTokenService(zenToken);

  if (isMaxValue) {
    const allZenTokens = await zenTokenService.balanceOf(accountAddress);
    const txImitationResult = await zenTokenService.withdrawViaZenCall(
      accountAddress,
      allZenTokens,
    );
    if (Number(txImitationResult) === 0) {
      await zenTokenService.withdrawViaZen(accountAddress, allZenTokens);
      return dispatch(lendingActions.setLendingData());
    }
  }

  const tokenAmount = toBN(amount)
    .times(10 ** underlyingDecimals)
    .toFixed(0, BN.ROUND_DOWN);
  await zenTokenService.withdraw(accountAddress, tokenAmount);

  return dispatch(lendingActions.setLendingData());
};
