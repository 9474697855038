import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='45'
      height='45'
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <circle cx='22.5' cy='22.5' r='22.5' fill='#01F06F' />
      <path
        d='M12.0183 29.7129V17.5584H13.415L13.6356 18.9797H13.7336C13.9623 18.506 14.289 18.1057 14.7138 17.779C15.1385 17.4359 15.6613 17.2644 16.2821 17.2644C16.9356 17.2644 17.4665 17.4196 17.8749 17.73C18.2997 18.0404 18.6182 18.4569 18.8306 18.9797H18.9041C19.1329 18.506 19.4841 18.1057 19.9579 17.779C20.448 17.4359 21.0197 17.2644 21.6732 17.2644C22.6371 17.2644 23.3477 17.5911 23.8051 18.2446C24.2789 18.8817 24.5158 19.723 24.5158 20.7686V29.7129H22.8004V20.9646C22.8004 20.3112 22.6779 19.8047 22.4329 19.4453C22.1878 19.0696 21.7712 18.8817 21.1831 18.8817C20.595 18.8817 20.1049 19.1022 19.7128 19.5433C19.3207 19.9844 19.1247 20.6216 19.1247 21.4547V29.7129H17.4093V20.9646C17.4093 20.3112 17.2868 19.8047 17.0418 19.4453C16.7967 19.0696 16.3801 18.8817 15.792 18.8817C15.2039 18.8817 14.7138 19.1022 14.3217 19.5433C13.9296 19.9844 13.7336 20.6216 13.7336 21.4547V29.7129H12.0183Z'
        fill='black'
      />
      <path
        d='M31.9977 11.5503L31.4096 24.7707H29.8902L29.2899 11.5503H31.9977ZM31.9854 26.4125V29.2673H29.2899V26.4125H31.9854Z'
        fill='black'
      />
    </svg>
  );
}
