import {
  OM_POOL_STAKE_HISTORY,
  OM_POOL_UNSTAKE_HISTORY,
  OM_POOL_REWARD_HISTORY,
  OM_V2_POOL_STAKE_HISTORY,
  OM_V2_POOL_UNSTAKE_HISTORY,
  OM_V3_POOL_STAKE_HISTORY,
  OM_V3_POOL_UNSTAKE_HISTORY,
  BNB_FINE_POOL_STAKE_HISTORY,
  BNB_FINE_POOL_UNSTAKE_HISTORY,
  RAZE_POOL_STAKE_HISTORY,
  RAZE_POOL_UNSTAKE_HISTORY,
  ROSN_POOL_STAKE_HISTORY,
  ROSN_POOL_UNSTAKE_HISTORY,
  KYL_POOL_STAKE_HISTORY,
  KYL_POOL_UNSTAKE_HISTORY,
  LABS_POOL_STAKE_HISTORY,
  LABS_POOL_UNSTAKE_HISTORY,
  ROYA_POOL_STAKE_HISTORY,
  ROYA_POOL_UNSTAKE_HISTORY,
  RFUEL_POOL_STAKE_HISTORY,
  RFUEL_POOL_UNSTAKE_HISTORY,
  RFUEL_POOL_REWARD_HISTORY,
  FXF_POOL_STAKE_HISTORY,
  FXF_POOL_UNSTAKE_HISTORY,
  L3P_POOL_STAKE_HISTORY,
  L3P_POOL_UNSTAKE_HISTORY,
  PKF_POOL_STAKE_HISTORY,
  PKF_POOL_UNSTAKE_HISTORY,
} from '../actions/action-types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    omHistory: {},
    omV2History: {},
    omV3History: {},
    bnbFineHistory: {},
    kylHistory: {},
    pkfHistory: {},
    labsHistory: {},
    l3pHistory: {},
    royaHistory: {},
    fxfHistory: {},
    rfuelHistory: {},
    razeHistory: {},
    rosnHistory: {},
  },
  action,
) => {
  switch (action.type) {
    case OM_POOL_STAKE_HISTORY:
      return { ...state, omHistory: { ...state.omHistory, stake: action.payload } };
    case OM_POOL_UNSTAKE_HISTORY:
      return { ...state, omHistory: { ...state.omHistory, unstake: action.payload } };
    case OM_POOL_REWARD_HISTORY:
      return { ...state, omHistory: { ...state.omHistory, reward: action.payload } };
    case OM_V2_POOL_STAKE_HISTORY:
      return { ...state, omV2History: { ...state.omV2History, stake: action.payload } };
    case OM_V2_POOL_UNSTAKE_HISTORY:
      return { ...state, omV2History: { ...state.omV2History, unstake: action.payload } };
    case OM_V3_POOL_STAKE_HISTORY:
      return { ...state, omV3History: { ...state.omV3History, stake: action.payload } };
    case OM_V3_POOL_UNSTAKE_HISTORY:
      return { ...state, omV3History: { ...state.omV3History, unstake: action.payload } };
    case BNB_FINE_POOL_STAKE_HISTORY:
      return { ...state, bnbFineHistory: { ...state.bnbFineHistory, stake: action.payload } };
    case BNB_FINE_POOL_UNSTAKE_HISTORY:
      return { ...state, bnbFineHistory: { ...state.bnbFineHistory, unstake: action.payload } };
    case RAZE_POOL_STAKE_HISTORY:
      return { ...state, razeHistory: { ...state.razeHistory, stake: action.payload } };
    case RAZE_POOL_UNSTAKE_HISTORY:
      return { ...state, razeHistory: { ...state.razeHistory, unstake: action.payload } };
    case ROSN_POOL_STAKE_HISTORY:
      return { ...state, rosnHistory: { ...state.rosnHistory, stake: action.payload } };
    case ROSN_POOL_UNSTAKE_HISTORY:
      return { ...state, rosnHistory: { ...state.rosnHistory, unstake: action.payload } };
    case KYL_POOL_STAKE_HISTORY:
      return { ...state, kylHistory: { ...state.kylHistory, stake: action.payload } };
    case KYL_POOL_UNSTAKE_HISTORY:
      return { ...state, kylHistory: { ...state.kylHistory, unstake: action.payload } };
    case PKF_POOL_STAKE_HISTORY:
      return { ...state, pkfHistory: { ...state.pkfHistory, stake: action.payload } };
    case PKF_POOL_UNSTAKE_HISTORY:
      return { ...state, pkfHistory: { ...state.pkfHistory, unstake: action.payload } };
    case LABS_POOL_STAKE_HISTORY:
      return { ...state, labsHistory: { ...state.labsHistory, stake: action.payload } };
    case LABS_POOL_UNSTAKE_HISTORY:
      return { ...state, labsHistory: { ...state.labsHistory, unstake: action.payload } };
    case L3P_POOL_STAKE_HISTORY:
      return { ...state, l3pHistory: { ...state.l3pHistory, stake: action.payload } };
    case L3P_POOL_UNSTAKE_HISTORY:
      return { ...state, l3pHistory: { ...state.l3pHistory, unstake: action.payload } };
    case ROYA_POOL_STAKE_HISTORY:
      return { ...state, royaHistory: { ...state.royaHistory, stake: action.payload } };
    case ROYA_POOL_UNSTAKE_HISTORY:
      return { ...state, royaHistory: { ...state.royaHistory, unstake: action.payload } };
    case FXF_POOL_STAKE_HISTORY:
      return { ...state, fxfHistory: { ...state.fxfHistory, stake: action.payload } };
    case FXF_POOL_UNSTAKE_HISTORY:
      return { ...state, fxfHistory: { ...state.fxfHistory, unstake: action.payload } };
    case RFUEL_POOL_STAKE_HISTORY:
      return { ...state, rfuelHistory: { ...state.rfuelHistory, stake: action.payload } };
    case RFUEL_POOL_UNSTAKE_HISTORY:
      return { ...state, rfuelHistory: { ...state.rfuelHistory, unstake: action.payload } };
    case RFUEL_POOL_REWARD_HISTORY:
      return { ...state, rfuelHistory: { ...state.rfuelHistory, reward: action.payload } };
    default:
      return state;
  }
};
