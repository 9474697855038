import * as React from 'react';

function SvgAtomIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <path
        d='M25.5 49C38.479 49 49 38.479 49 25.5S38.479 2 25.5 2 2 12.521 2 25.5 12.521 49 25.5 49z'
        fill='#2E3148'
      />
      <path
        d='M25.5 39.135c7.53 0 13.636-6.105 13.636-13.636 0-7.53-6.105-13.636-13.636-13.636-7.53 0-13.636 6.105-13.636 13.636 0 7.531 6.105 13.636 13.636 13.636z'
        fill='#1B1E36'
      />
      <path
        d='M25.549 4.998c-2.537 0-4.594 9.2-4.594 20.55 0 11.35 2.057 20.551 4.594 20.551 2.536 0 4.593-9.2 4.593-20.55 0-11.35-2.057-20.55-4.593-20.55zm.317 39.941c-.29.387-.58.097-.58.097-1.169-1.354-1.753-3.868-1.753-3.868-2.043-6.576-1.557-20.696-1.557-20.696.96-11.21 2.707-13.857 3.302-14.445a.363.363 0 01.465-.038c.863.612 1.586 3.168 1.586 3.168 2.136 7.93 1.943 15.376 1.943 15.376.193 6.48-1.07 13.733-1.07 13.733-.974 5.512-2.336 6.673-2.336 6.673z'
        fill='#6F7390'
      />
      <path
        d='M43.373 15.322c-1.263-2.2-10.266.594-20.116 6.242S6.46 33.575 7.722 35.775c1.262 2.2 10.267-.593 20.116-6.241 9.85-5.648 16.797-12.011 15.535-14.212zM8.887 35.473c-.483-.06-.374-.458-.374-.458.593-1.687 2.48-3.445 2.48-3.445 4.69-5.045 17.18-11.65 17.18-11.65 10.2-4.745 13.368-4.545 14.172-4.323a.362.362 0 01.263.387c-.097 1.053-1.958 2.951-1.958 2.951-5.812 5.802-12.368 9.34-12.368 9.34-5.524 3.394-12.444 5.905-12.444 5.905-5.266 1.898-6.95 1.293-6.95 1.293z'
        fill='#6F7390'
      />
      <path
        d='M43.329 35.848c1.273-2.195-5.657-8.587-15.472-14.278-9.815-5.69-18.818-8.52-20.09-6.32-1.271 2.199 5.658 8.587 15.479 14.278 9.82 5.69 18.811 8.52 20.083 6.32zM8.611 16.098c-.188-.446.209-.553.209-.553 1.757-.332 4.225.424 4.225.424 6.714 1.53 18.687 9.029 18.687 9.029 9.218 6.45 10.632 9.291 10.844 10.098a.363.363 0 01-.201.422c-.961.44-3.536-.216-3.536-.216-7.935-2.127-14.28-6.026-14.28-6.026-5.703-3.075-11.34-7.807-11.34-7.807-4.285-3.607-4.607-5.366-4.607-5.366l-.001-.005z'
        fill='#6F7390'
      />
      <path
        d='M25.5 27.917a2.418 2.418 0 100-4.835 2.418 2.418 0 000 4.835zM35.412 17.667c.775 0 1.402-.65 1.402-1.45 0-.802-.627-1.451-1.402-1.451-.774 0-1.402.65-1.402 1.45 0 .801.628 1.45 1.402 1.45zM12.396 22.599c.775 0 1.403-.65 1.403-1.451s-.628-1.45-1.403-1.45c-.774 0-1.402.649-1.402 1.45 0 .801.628 1.45 1.402 1.45zM22.647 40.393c.775 0 1.403-.65 1.403-1.45 0-.801-.628-1.45-1.403-1.45-.774 0-1.402.649-1.402 1.45 0 .8.628 1.45 1.402 1.45z'
        fill='#B7B9C8'
      />
    </svg>
  );
}

export default SvgAtomIcon;
