import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='71'
      height='71'
      fill='none'
      viewBox='0 0 71 71'
      {...otherProps}
    >
      <path
        fill='#E57CB2'
        d='M70.021 18.364v33.292c0 10.143-8.222 18.364-18.364 18.364H18.364C8.221 70.02 0 61.799 0 51.656V18.364C0 8.221 8.221 0 18.364 0h33.293C61.8 0 70.021 8.221 70.021 18.364z'
      ></path>
      <path
        fill='#fff'
        d='M56.94 32.326h-6.514v-.642c0-2.11 1.33-3.718 3.257-3.718 2.064 0 3.258 1.654 3.258 3.718v.642zm-3.257-9.912c-5.35 0-9.666 3.454-9.768 9.912h-5.464l.56-7.249c.091-1.239-.644-2.064-1.882-2.064H34.65c-1.238 0-2.018.641-2.11 1.88l-1.01 13.399c-.182 2.156-1.1 3.67-2.89 3.67-1.744 0-2.707-1.653-2.707-3.717v-11.61c0-1.237-.827-2.062-2.065-2.062H21.62c-1.239 0-2.064.825-2.064 2.063v11.609c0 2.064-.964 3.717-2.708 3.717-1.79 0-2.707-1.514-2.844-3.67l-.964-13.215c-.092-1.239-.872-2.064-2.11-2.064H8.452c-1.239 0-2.019.825-1.928 2.064l1.01 13.672c.412 5.46 4.038 8.856 9.176 8.856 2.754 0 4.772-1.193 6.011-3.165 1.239 1.972 3.257 3.165 6.01 3.165 5.185 0 8.81-3.257 9.223-8.856l.142-1.836h5.814v.323c0 6.744 4.312 10.369 10.736 10.369 2.983 0 4.634-.413 5.919-.78 1.147-.367 1.743-1.376 1.423-2.707l-.322-1.33c-.276-1.148-1.193-1.654-2.386-1.331-.918.23-2.57.596-4.634.596-2.798 0-4.221-1.56-4.221-4.817v-.323h11.012c1.239 0 2.064-.825 2.064-2.063v-3.166c0-5.46-4.404-9.27-9.819-9.27'
      ></path>
    </svg>
  );
}
