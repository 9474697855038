import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 50 50'
      className={className}
      {...otherProps}
    >
      <path
        fill='#002060'
        d='M25 0l-9.625 9.624 9.624 9.624 9.625-9.624L24.999 0zM25 30.752l-9.625 9.624L24.999 50l9.625-9.624-9.625-9.624zM40.376 15.452l-9.625 9.624 9.625 9.625L50 25.076l-9.624-9.624z'
      ></path>
      <path
        fill='#4E87F7'
        d='M27.836 25.067l6.777-6.778 6.778 6.778-6.778 6.778-6.777-6.778z'
      ></path>
      <path fill='#002060' d='M9.624 15.452L0 25.076l9.624 9.625 9.625-9.625-9.625-9.624z'></path>
      <path
        fill='#4E87F7'
        d='M8.588 25.067l6.778-6.778 6.778 6.778-6.778 6.778-6.778-6.778zM18.212 15.444l6.778-6.778 6.778 6.778-6.778 6.778-6.778-6.778zM18.212 34.69l6.778-6.778 6.778 6.778-6.778 6.778-6.778-6.778z'
      ></path>
    </svg>
  );
}
