import _ from 'lodash';

import {
  LENDING_PRICES_UPDATE_LIST,
  LENDING_PRICES_SET_FILTER,
  LENDING_PRICES_SET_FILTERED_LIST,
  LENDING_PRICES_SET_ASSET_SELECTION,
  LENDING_PRICES_SET_ASSETS,
} from '../actions/action-types';

const DEFAULT_FIELDS = {
  assetsMap: {},
  list: [],
  filteredList: [],
  filter: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case LENDING_PRICES_SET_ASSETS:
      return { ...state, assetsMap: action.payload };
    case LENDING_PRICES_UPDATE_LIST:
      return { ...state, list: action.payload };
    case LENDING_PRICES_SET_FILTERED_LIST:
      return { ...state, filteredList: action.payload };
    case LENDING_PRICES_SET_FILTER:
      return { ...state, filter: action.payload };
    case LENDING_PRICES_SET_ASSET_SELECTION: {
      const { filteredList } = state;
      const { index, isSelected } = action.payload;
      const newList = filteredList.map((item, ind) =>
        ind === index ? { ...item, isSelected } : item,
      );
      return { ...state, filteredList: newList };
    }
    default:
      return state;
  }
};
