import React from 'react';
import PropTypes from 'prop-types';
import TokenColumn from '../../../../../screens/lending/blocks-for-lending-modals/token-column';
import ModalButton from '../../../../../screens/lending/blocks-for-lending-modals/modal-button';
import styles from './repay-column.module.scss';

const EnableRow = ({ tokenColumn, disabled, onClick }) => (
  <>
    <div className={styles.column}>
      <TokenColumn title='Borrow Rates' list={tokenColumn} />
    </div>
    <div className={styles.buttonWrapper}>
      <ModalButton text='Enable' disabled={disabled} onClick={onClick} />
    </div>
  </>
);

EnableRow.propTypes = {
  tokenColumn: PropTypes.array,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

EnableRow.defaultProps = {
  tokenColumn: [],
  disabled: false,
  onClick: () => {},
};

export default React.memo(EnableRow);
