import styled, { css } from 'styled-components';

interface Props {
  display?: 'inline' | 'block';
}

export const StyledDt = styled.dt`
  display: inline-flex;
`;
export const StyledDd = styled.dd`
  margin-inline-start: 0px;
  margin-top: 0.5rem;
`;

export const StyledDl = styled.dl<Props>`
  margin-top: 2rem;
  z-index: 1;
  width: 100%;
  ${({ display }) =>
    display === 'inline'
      ? css`
          margin: 0;
          @media screen and (min-width: 992px) {
            display: flex;
            justify-content: space-between;
          }
        `
      : css``}
`;

export const StyledDefWrapper = styled.div<Props>`
  display: flex;
  ${({ display }) =>
    display === 'inline'
      ? css`
          & + & {
            margin-top: 1.5rem;
            @media screen and (min-width: 992px) {
              margin-top: 0;
            }
          }
        `
      : css`
          & + & {
            margin-top: 1.5rem;
          }
        `}

  flex-direction: column;
`;
