import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './burger-button.module.scss';
import { toggleMenu } from '../../actions/basic-actions';

const BurgerButton = ({ className }) => {
  const dispatch = useDispatch();
  const darkTheme = useSelector((state) => state.basic.dark);
  const isOpenMenu = useSelector((state) => state.basic.isOpenMenu);

  return (
    <button
      className={cn(styles.container, className, {
        [styles.white]: !darkTheme,
        [styles.active]: isOpenMenu,
      })}
      onClick={() => dispatch(toggleMenu(!isOpenMenu))}
    >
      <div className={cn(styles.line, styles.top)} />
      <div className={cn(styles.line, styles.center)} />
      <div className={cn(styles.line, styles.bottom)} />
    </button>
  );
};

BurgerButton.propTypes = {
  className: PropTypes.string,
};

BurgerButton.defaultProps = {
  className: '',
};

export default BurgerButton;
