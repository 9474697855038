import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import { ENABLE_COLLATERAL_MODAL } from '../../../constants/modal-constants';
import { COLLATERAL_ABOUT_LINK } from '../../../constants/lending-constants';
import ModalButton from '../../../screens/lending/blocks-for-lending-modals/modal-button';
import * as modalActions from '../../../actions/modal-actions';
import * as lendingActions from '../../../actions/lending-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from './enable-collateral-modal.module.scss';
import Loader from '../../loader';

const EnableCollateralModal = ({ show, zenTokenAddress, underlyingSymbol }) => {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const onClickHandler = async () => {
    try {
      setLoading(true);
      await dispatch(lendingActions.enterMarket(zenTokenAddress));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      dispatch(modalActions.hideModal(ENABLE_COLLATERAL_MODAL));
    }
  };

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={cn({ [s.dark]: dark })}>
        <div className={s.header}>
          <div className={s.headerTitle}>Enable as Collateral</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(ENABLE_COLLATERAL_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.container}>
          {loading && <Loader className={s.loading} />}
          <div className={s.body}>
            <p className={s.text}>
              Each asset used as collateral increases your borrowing limit. Be careful, this can
              subject the asset to being seized in liquidation.
              <a
                href={COLLATERAL_ABOUT_LINK}
                target='_blank'
                rel='noopener noreferrer'
                className={s.link}
              >
                {' '}
                Learn more.
              </a>
            </p>
          </div>
          {error && <div className={s.error}>{error}</div>}
          <div className={s.footer}>
            <div className={s.description}>
              *Once you enable as collateral, you will still need to supply the asset in order to
              borrow against it.
            </div>
            <ModalButton
              text={`Use ${underlyingSymbol} as collateral`}
              disabled={loading}
              onClick={onClickHandler}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

EnableCollateralModal.propTypes = {
  show: PropTypes.bool,
  zenTokenAddress: PropTypes.string.isRequired,
  underlyingSymbol: PropTypes.string.isRequired,
};

EnableCollateralModal.defaultProps = {
  show: false,
};

export default connectModal({ name: ENABLE_COLLATERAL_MODAL })(EnableCollateralModal);
