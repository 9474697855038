import { WalletLinkProvider } from 'walletlink';
import Web3 from 'web3';
import { TYPE_AUTH } from '../constants/account-contants';
import WalletConnectService from './network-providers/wallet-connect-service';
import WalletConnectBscService from './network-providers/wallet-connect-service-bsc';
import WalletConnectPolygonService from './network-providers/wallet-connect-service-polygon';

export default {
  getInstance: function () {
    const typeAuth = localStorage.getItem('connected');

    if (typeAuth === TYPE_AUTH.WALLET_CONNECT_BSC) {
      const walletConnectService = new WalletConnectBscService();
      const provider = walletConnectService._provider;

      return new Web3(provider as any);
    }
    if (typeAuth === TYPE_AUTH.WALLET_CONNECT_POLYGON) {
      const walletConnectService = new WalletConnectPolygonService();
      const provider = walletConnectService._provider;

      return new Web3(provider as any);
    }

    if (typeAuth === TYPE_AUTH.WALLET_CONNECT) {
      const walletConnectService = new WalletConnectService();
      const provider = walletConnectService._provider;

      return new Web3(provider as any);
    }
    return new Web3(window.ethereum as WalletLinkProvider);
  },
};
