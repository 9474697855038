import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        d='M22.5 0C10.1025 0 0 10.17 0 22.6314C0 34.9614 10.1025 45 22.5 45C34.8975 45 45 34.9632 45 22.6314C45 10.17 34.8984 0 22.5 0ZM31.6512 33.1956C28.9985 33.1591 26.3626 32.7671 23.814 32.0301C24.684 32.5067 25.4811 33.1056 26.181 33.8085C27.7281 35.3925 28.3563 37.2888 28.2573 37.3851C28.1583 37.4814 26.2773 36.8082 24.7311 35.2251C23.9995 34.476 23.3876 33.6188 22.9167 32.6835C22.86 32.7015 22.9167 32.6907 22.86 32.7015C22.986 34.4529 23.2848 38.0115 23.5728 38.8107H21.3975C21.6828 38.0061 21.9546 34.4241 22.0842 32.6826C22.0311 32.67 22.0842 32.6826 22.0338 32.6637C21.5939 33.6109 20.9965 34.4767 20.2671 35.2242C18.7209 36.8082 16.8399 37.4814 16.7418 37.3842C16.6437 37.287 17.271 35.3916 18.8172 33.8076C19.5176 33.1048 20.315 32.506 21.1851 32.0292C18.6365 32.766 16.0006 33.158 13.3479 33.1947C8.5401 33.2325 4.6314 31.8627 4.6296 31.6413C4.6278 31.4199 8.514 29.9889 13.3218 29.9511C15.974 29.9456 18.6149 30.2952 21.1743 30.9906C19.1871 29.9799 15.3018 27.5499 11.8899 24.165C7.1568 19.4697 4.6971 14.2794 4.9095 14.0607C5.1219 13.842 10.3365 16.2648 15.0687 20.9601C18.4464 24.3108 20.8917 28.1349 21.9366 30.1302L21.9654 30.1158C21.285 27.7695 20.2554 22.7358 20.2554 17.3178C20.2554 9.8667 22.2048 3.8268 22.5126 3.8268C22.8204 3.8268 24.7671 9.8667 24.7671 17.3178C24.7671 22.7412 23.7339 27.783 23.0571 30.1257H23.0661C24.111 28.1295 26.5563 24.3063 29.9331 20.9556C34.6671 16.2648 39.8736 13.842 40.0905 14.0607C40.3074 14.2794 37.8405 19.4697 33.111 24.165C29.6991 27.5499 25.8111 29.9799 23.8248 30.9915C26.3839 30.2962 29.0245 29.9466 31.6764 29.952C36.4842 29.9898 40.3713 31.4217 40.3695 31.6422C40.3677 31.8627 36.459 33.2334 31.6512 33.1956Z'
        fill='#8FCB67'
      />
    </svg>
  );
}
