import BN from 'bignumber.js';

import {
  toPowerOf,
  toPowerOfDot,
  toPowerOfKsm,
  toPowerOfUSDT,
  toPowerOfAtom,
  toPowerOfBand,
  toPowerOfDpvn,
  toPowerOfEmoney,
  toPowerOfKava,
  toPowerOfLuna,
  toPowerOfMatic,
} from '../blockchain/config';
import web3Service from '../services/web3-service';
import { formatPrecision } from './function-helper';

export const fromWeiToEther = (wei) => {
  try {
    return web3Service.web3.utils.fromWei(wei, 'ether');
  } catch (e) {
    return '0';
  }
};

export const fromEtherToWei = (ether) => {
  try {
    return new BN(ether).times(toPowerOf).toString(10);
  } catch (e) {
    return '0';
  }
};

export const formatToken = (wei, precision) => {
  if (!wei || wei === '0') {
    return '0';
  }
  if (typeof wei === 'number') {
    wei = `${wei}`;
  }
  return formatPrecision(fromWeiToEther(wei), precision);
};

export const formatDotToken = (dot) => {
  if (!dot || dot === '0') {
    return '0';
  }
  if (typeof dot === 'number') {
    dot = `${dot}`;
  }
  return formatPrecision(dot / toPowerOfDot);
};

export const formatAtomToken = (comsos) => {
  if (!comsos || comsos === '0') {
    return '0';
  }
  if (typeof comsos === 'number') {
    comsos = `${comsos}`;
  }
  return formatPrecision(comsos / toPowerOfAtom);
};

export const formatBandToken = (band) => {
  if (!band || band === '0') {
    return '0';
  }
  if (typeof band === 'number') {
    band = `${band}`;
  }
  return formatPrecision(band / toPowerOfBand);
};

export const formatEmoneyToken = (emoney) => {
  if (!emoney || emoney === '0') {
    return '0';
  }
  if (typeof emoney === 'number') {
    emoney = `${emoney}`;
  }
  return formatPrecision(emoney / toPowerOfEmoney);
};

export const formatLunaToken = (luna) => {
  if (!luna || luna === '0') {
    return '0';
  }
  if (typeof luna === 'number') {
    luna = `${luna}`;
  }
  return formatPrecision(luna / toPowerOfLuna);
};

export const formatDpvnToken = (dpvn) => {
  if (!dpvn || dpvn === '0') {
    return '0';
  }
  if (typeof dpvn === 'number') {
    dpvn = `${dpvn}`;
  }
  return formatPrecision(dpvn / toPowerOfDpvn);
};

export const formatKavaToken = (kava) => {
  if (!kava || kava === '0') {
    return '0';
  }
  if (typeof kava === 'number') {
    kava = `${kava}`;
  }
  return formatPrecision(kava / toPowerOfKava);
};

export const formatKsmToken = (ksm) => {
  if (!ksm || ksm === '0') {
    return '0';
  }
  if (typeof wei === 'number') {
    ksm = `${ksm}`;
  }
  return formatPrecision(ksm / toPowerOfKsm);
};

export const formatMaticToken = (matic) => {
  if (!matic || matic === '0') {
    return '0';
  }
  if (typeof wei === 'number') {
    matic = `${matic}`;
  }
  return formatPrecision(matic / toPowerOfMatic);
};

export const formatDollars = (wei) => {
  if (!wei || wei === '0') {
    return '0';
  }
  if (typeof wei === 'number') {
    wei = `${wei}`;
  }
  return formatPrecision(fromWeiToEther(wei), 2);
};

export const formatUSDTToken = (usdt) => {
  if (!usdt || usdt === '0') {
    return '0';
  }

  return formatPrecision(usdt / toPowerOfUSDT);
};

export const toBN = (weiStr) => new BN(weiStr);

export const toBNScaled = (value, decimal = 0) => toBN(value).div(10 ** decimal);
