import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      id='Layer_1'
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 212.4 212.4',
      }}
      viewBox='0 0 212.4 212.4'
      className={className}
      {...otherProps}
    >
      <style>
        {
          '.st0{fill:none}.st2{fill:url(#SVGID_00000086680594779255544260000014087736755730991266_)}.st4{fill:url(#SVGID_00000151542672270601613000000009376468664670277791_)}'
        }
      </style>
      <linearGradient
        id='SVGID_1_'
        x1={58.711}
        x2={153.693}
        y1={202.796}
        y2={12.788}
        gradientTransform='matrix(1 0 0 -1 0 214)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#2d46fc',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#f62ed6',
          }}
        />
      </linearGradient>
      <path
        d='M106.2 0c58.7 0 106.2 47.6 106.2 106.2 0 58.7-47.6 106.2-106.2 106.2S0 164.9 0 106.2C0 47.6 47.6 0 106.2 0z'
        style={{
          fill: 'url(#SVGID_1_)',
        }}
      />
      <path
        d='M68.3 180.3c-1.1 0-2.2-.3-3.2-.9l-32.9-20.6c-2.9-1.9-4.7-5.1-4.7-8.5V62.1c0-3.6 1.8-6.7 4.7-8.5L65 33.1c1-.6 2.1-.9 3.3-.9 3.4 0 6.1 2.6 6.1 6v41l30.3 19c.4.3.9.4 1.5.4s1.1-.2 1.6-.4l30.3-19V38.3c0-3.3 2.7-6.1 6-6.1 1.2 0 2.3.4 3.3 1l32.9 20.3c2.9 1.9 4.7 5.1 4.7 8.5v88c0 3.6-1.8 6.7-4.7 8.5L147.4 179c-1 .6-2.1.9-3.3.9-3.4 0-6.1-2.6-6.1-6v-40.7l-30.3-19c-.4-.3-.9-.4-1.5-.4s-1.1.2-1.6.4l-30.3 19v41c.1 3.4-2.6 6.1-6 6.1zM62 163.2v-22.6l-18.2 11.3L62 163.2zm88.1-.3 17.8-11.1-17.8-11.1v22.2zM39.3 140.6 62 126.4V85.7L39.3 71.5v69.1zm133.5 0v-69l-22.7 14.2v40.7l22.7 14.1zM138 118.9V93.2l-20.6 12.9 20.6 12.8zm-63.9 0 20.6-12.8-20.6-12.9v25.7zM62 71.5V48.9L43.8 60.2 62 71.5zm88.1-.2 18-11.1-18-11.1v22.2z'
        style={{
          fill: '#fff',
        }}
      />
    </svg>
  );
}
