import * as React from 'react';

function SvgKavaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#ED6257' />
      <path
        fill='#fff'
        d='M14.747 13.928h4.862v24.808h-4.862zM38.45 13.55H32.4L22.04 26.33v.001l10.36 12.78h6.05l-10.332-12.78 10.332-12.78z'
      />
    </svg>
  );
}

export default SvgKavaIcon;
