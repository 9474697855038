import Web3 from '../web3.service';

class Web3Service {
  getInstanceByNetworkId(walletNetworkId) {
    throw new Error('Method not implemented.');
  }
  constructor() {
    this._providerInstance = null;
    this._web3 = Web3.getInstanceByNetworkId(1);
    this.events = {};
  }

  get web3() {
    return this._web3;
  }

  get eth() {
    if (!this.web3.eth) {
      throw new Error('Eth not available');
    }
    return this.web3.eth;
  }

  get providerInstance() {
    return this._providerInstance;
  }

  set providerInstance(instance) {
    this._providerInstance = instance;
  }

  set web3(instance) {
    this._web3 = instance;
  }

  changeProvider(instance) {
    this.providerInstance?.clearEvents();
    this.providerInstance = instance;
    this.web3.setProvider(this.providerInstance.provider);
  }

  disconnect() {
    if (this.providerInstance?.disconnect) {
      this.providerInstance.disconnect();
    }
  }
}

export default new Web3Service();
