import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import theme from '../../theme';

import OmIcon from '../../assets/images/tokens/om.svg';

const GlobalLoader = ({ dark }) => {
  const colors = () => {
    if (dark) {
      return theme.palette.dark;
    }
    return theme.palette.light;
  };

  return (
    <div>
      <Dialog fullScreen style={{ width: '100vw', height: '100vh', zIndex: 9999 }} open>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            zIndex: 9999,
            backgroundColor: colors().background,
          }}
        >
          <img
            height='40px'
            className='imgPulse'
            style={{
              height: '40px !important',
              position: 'relative',
              left: '46%',
              top: '46%',
            }}
            src={OmIcon}
            alt=''
          />
        </div>
      </Dialog>
    </div>
  );
};

GlobalLoader.propTypes = {
  dark: PropTypes.bool.isRequired,
};

export default GlobalLoader;
