import _ from 'lodash';
import {
  SET_AUTH_MATIC_MERKLE_AIRDROP,
  LOGOUT_MATIC_MERKLE_AIRDROP,
  MATIC_MERKLE_AIRDROP_AUTH_MODE,
  GET_ALL_MATIC_MERKLE_AIRDROP_ACCOUNTS,
  MATIC_MERKLE_AIRDROP_SET_STATUS,
} from '../actions/action-types';
import { TYPE_AUTH } from '../constants/account-contants';
import { MERKLE_AIRDROP_NAMES } from '../constants/blockchain-constants';

const DEFAULT_FIELDS = {
  auth: null,
  address: '',
  allAccounts: [],
  substrateAddress: '',
  mode: MERKLE_AIRDROP_NAMES.MATIC_MERKLE_AIRDROP,
  status: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case SET_AUTH_MATIC_MERKLE_AIRDROP:
      if (
        !action.payload.address ||
        !action.payload.type ||
        !Object.values(TYPE_AUTH).includes(action.payload.type)
      ) {
        return state;
      }
      return {
        ...state,
        auth: action.payload.type,
        address: action.payload.address,
      };
    case GET_ALL_MATIC_MERKLE_AIRDROP_ACCOUNTS:
      return {
        ...state,
        allAccounts: action.payload.allAccounts,
      };
    case MATIC_MERKLE_AIRDROP_AUTH_MODE:
      return {
        ...state,
        mode: MERKLE_AIRDROP_NAMES.MATIC_MERKLE_AIRDROP,
      };
    case MATIC_MERKLE_AIRDROP_SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
        withdrowedIndex: action.payload.withdrowedIndex,
      };
    case LOGOUT_MATIC_MERKLE_AIRDROP:
      return _.cloneDeep(DEFAULT_FIELDS);
    default:
      return state;
  }
};
