import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import history from '../../../history';
import styles from './nav-item.module.scss';
import { toggleMenu } from '../../../actions/basic-actions';

const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: '#ae528b',
        background: 'none',
        backgroundColor: 'none',
        fontSize: '16px',
      },
    },
  },
});

const getRouteBase = () => `/${window.location.pathname.split('/')[1]}`;

const isActiveRoute = (routes) => {
  if (getRouteBase() === routes[0]) {
    return true;
  }
  if (routes[1]) {
    if (getRouteBase() === routes[1]) {
      return true;
    }
    return false;
  }
  return false;
};

const NavItem = ({ text, icon, routes, activeIcon, currentHover, setCurrentHover, colors }) => {
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={tooltipTheme}>
      <Link
        key={`${text}98`}
        to={{ pathname: routes[0], state: { prevPath: window.location.pathname } }}
        className={styles.link}
      >
        <span
          className={styles.linkWrapper}
          onClick={() => {
            history.push(routes);
            dispatch(toggleMenu(false));
          }}
          onMouseOver={() => {
            setCurrentHover(routes[0]);
          }}
          onFocus={() => {}}
          onKeyDown={() => {}}
          onMouseLeave={() => {
            setCurrentHover('');
          }}
          role='button'
          tabIndex={0}
        >
          <div
            className={cn(styles.spacer, { [styles.spacerTop]: isActiveRoute(routes) })}
            style={{ backgroundColor: colors.background }}
          >
            <div className={styles.spacerInner} style={{ backgroundColor: colors.banner }} />
          </div>
          <div
            className={styles.linkBody}
            style={{
              backgroundColor: isActiveRoute(routes) ? colors.background : colors.banner,
            }}
          >
            <img src={icon} alt='' style={{ width: '30px' }} />
            <span className={styles.text}>{text}</span>
          </div>
          <div
            className={cn(styles.spacer, { [styles.spacerBottom]: isActiveRoute(routes) })}
            style={{ backgroundColor: colors.background }}
          >
            <div className={styles.spacerInner} style={{ backgroundColor: colors.banner }} />
          </div>
        </span>
      </Link>
    </ThemeProvider>
  );
};

NavItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  activeIcon: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
  currentHover: PropTypes.string.isRequired,
  setCurrentHover: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
};

export default NavItem;
