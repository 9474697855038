import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';
import { Store } from '../../../../store/store.types';
import { useDelegatorPollCheckHook, useDelegatorSignCreateHook } from '../../hooks/delegator.hook';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  otherWalletAddress: string;
  chainId: number;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function ApprovalScreen({ otherWalletAddress, setScreen, chainId }: Props) {
  enum VerificationStatus {
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
  }

  const [txVerificationStatus, setTxVerificationStatus] = useState<VerificationStatus>(
    VerificationStatus.PENDING,
  );

  const [pollStatus, delegatorLinks] = useDelegatorPollCheckHook(chainId);
  const [signCreateLink, loading, error, isSigned] = useDelegatorSignCreateHook(chainId);

  const [linkVerificationAmount, setLinkVerificationAmount] = useState(0);
  const [changeNetworkMessage, setChangeNetworkMessage] = useState(false);
  const [verified, setVerified] = useState(false);

  const { address: walletAddress, networkId } = useSelector(({ account }: Store) => account);

  useEffect(() => {
    const checkDelegatorStatus = async () => {
      await pollStatus(walletAddress, otherWalletAddress);
    };

    checkDelegatorStatus();

    let interval: NodeJS.Timeout;

    interval = setInterval(async () => {
      await checkDelegatorStatus();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (delegatorLinks.length > 0) {
      if (delegatorLinks[0]?.type === 'VERIFIED') {
        setVerified(true);
        if (networkId !== 56) {
          setChangeNetworkMessage(true);
        } else if (!loading && !isSigned) {
          setChangeNetworkMessage(false);
          signCreateLink(
            walletAddress,
            otherWalletAddress,
            delegatorLinks[0].signature,
            delegatorLinks[0].encoded_msg,
          );
        }
      } else if (delegatorLinks[0]?.type === 'LINKED') {
        setScreen(6);
      }
    }
  }, [delegatorLinks, networkId]);

  return (
    <StyledWrapper>
      {!changeNetworkMessage ? (
        <>
          <Icon type='alert' width={200} />
          <Text dark={true}>
            Finally commit your delegator rewards link to the Binance Smart Chain
          </Text>
          <Text size='sm' dark={true}>
            Detection takes 1-2 minutes
          </Text>
          <br />
        </>
      ) : (
        <>
          <Icon type='alert' width={200} />
          <Text dark={true}>
            Switch Your Network To Binance Smart Chain and continue the linking proccess
          </Text>
        </>
      )}
    </StyledWrapper>
  );
}
