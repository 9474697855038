import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color, ...props }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
      className={className}
      {...props}
    >
      <circle cx='6' cy='6' r='6' fill='#23C581'></circle>
    </svg>
  );
}
