import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Item from './item';
import ethBscIcon from '../../../assets/images/wallets/eth-bsc.svg';
import substrateIcon from '../../../assets/images/wallets/substrate.svg';
import cosmosIcon from '../../../assets/images/wallets/cosmos.svg';
import WalletIllustration from '../../../assets/images/wallets/wallet-illustration.svg';
import { ETH_TYPE_AUTH } from '../../../constants/account-contants';

import styles from './login-modal.module.scss';

const Main = ({ global, setActiveStep }) => {
  const typeAuth = useSelector((state) => state.account.auth);
  const { address: polkadotAddress } = useSelector((state) => state.polkadotAccount);
  const { address: kusamaAddress } = useSelector((state) => state.kusamaAccount);

  return (
    <>
      <img width='100%' alt='' src={WalletIllustration} />
      <h3
        className={styles.title}
        style={{ color: global.dark ? '#ffffff' : '#201f1e' }}
        id='alert-dialog-title'
      >
        Choose Wallet
      </h3>
      <p className={styles.description} style={{ color: global.dark ? '#ffffff' : '#606371' }}>
        Safely connect to your existing blockchain wallet and access your assets.
      </p>
      <ul className={styles.list}>
        <Item
          title='Ethereum Wallets'
          icon={ethBscIcon}
          onClick={() => setActiveStep(2)}
          active={ETH_TYPE_AUTH.includes(typeAuth)}
        />
        <Item
          title='Substrate Wallets'
          icon={substrateIcon}
          onClick={() => setActiveStep(3)}
          active={!!(polkadotAddress || kusamaAddress)}
        />
      </ul>
    </>
  );
};

Main.propTypes = {
  global: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default Main;
