import * as React from 'react';
import { NetworkIconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<NetworkIconProps, 'networkId'>) {
  return (
    <svg
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
      className={className}
    >
      <path
        d='M22.5 45C34.926 45 45 34.926 45 22.5S34.926 0 22.5 0 0 10.074 0 22.5 10.074 45 22.5 45z'
        fill='#8247E5'
      />
      <path
        d='M29.947 17.686c-.54-.314-1.24-.314-1.834 0l-4.21 2.407-2.86 1.57-4.21 2.407c-.54.314-1.24.314-1.834 0l-3.346-1.884c-.54-.314-.918-.89-.918-1.517v-3.716c0-.627.324-1.203.918-1.517l3.292-1.831c.54-.314 1.24-.314 1.835 0l3.291 1.831c.54.314.918.89.918 1.517v2.407l2.86-1.622v-2.407c0-.627-.324-1.203-.917-1.517l-6.098-3.454c-.54-.313-1.242-.313-1.835 0l-6.207 3.454c-.593.314-.917.89-.917 1.517v6.96c0 .628.324 1.203.917 1.517L15 27.262c.54.314 1.24.314 1.835 0l4.209-2.355 2.86-1.622 4.21-2.355c.54-.314 1.24-.314 1.834 0l3.292 1.832c.54.314.918.89.918 1.517v3.715c0 .628-.324 1.204-.918 1.518l-3.292 1.883c-.54.314-1.24.314-1.834 0l-3.292-1.831c-.54-.314-.918-.89-.918-1.517V25.64l-2.86 1.622v2.407c0 .628.324 1.203.917 1.517l6.206 3.453c.54.314 1.242.314 1.835 0l6.207-3.453c.54-.314.917-.89.917-1.517v-6.96c0-.628-.324-1.203-.917-1.517l-6.26-3.506z'
        fill='#fff'
      />
    </svg>
  );
}
