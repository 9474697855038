import Button from '../../../../external/components/Button/Button';
import { ChainType } from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';

interface Props {
  chainSymbol: ChainType;
}

export function NominatedTimerScreen({ chainSymbol }: Props) {
  return (
    <>
      <Text dark={true}>Nominated {chainSymbol} will show up in</Text>
      <Button dark={true}>Refresh page</Button>

      <Text dark={true}>
        IMPORTANT! It takes one era (6 hours) for KSM to nominate to new validators. Your nominated
        KSM will not show up in the app until after that era which can be any time up to 6 hours
        depending on the current era.
      </Text>
    </>
  );
}
