import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserNav from '../userNav';
import * as modalActions from '../../actions/modal-actions';
import { POLKADOT_ACCOUNT_MODAL } from '../../constants/modal-constants';

const Account = () => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.polkadotAccount.address);
  const typeAuth = useSelector((state) => state.polkadotAccount.auth);

  const showAccoutModal = () => {
    dispatch(modalActions.showModal(POLKADOT_ACCOUNT_MODAL));
  };

  return address ? (
    <UserNav handleTrigger={() => showAccoutModal()} account={address} typeAuth={typeAuth} />
  ) : null;
};

export default Account;
