import * as React from 'react';

function SvgNgmIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#003034' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.402 25.5v-.085c0-10.458-8.455-18.941-18.902-18.987v3.406c8.566.045 15.496 7.004 15.496 15.58v.086h3.406zM6.428 25.5v.085c0 10.458 8.455 18.941 18.902 18.987v-3.406c-8.566-.045-15.496-7.004-15.496-15.58V25.5H6.428zM35.206 23.627H32c-.631-3.445-3.293-5.45-6.53-5.45-3.196 0-6.036 2.272-6.768 5.45h-3.078v2.554h2.965c.504 3.632 3.648 6.301 7.05 6.301 2.28 0 4.63-.766 6.27-2.885l-2.46-1.829c-1.009 1.063-2.507 1.72-3.863 1.72-1.996 0-3.54-1.296-3.929-3.307h12.699l.851-2.554zm-9.791-2.725c1.778 0 3.097 1.09 3.614 2.725H21.8c.517-1.634 1.836-2.725 3.615-2.725z'
        fill='#CCF7F0'
      />
    </svg>
  );
}

export default SvgNgmIcon;
