import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/header';
import { SideBar } from '../../external/components/SideBar/SideBar';
import Text from '../../external/components/Text/Text';
import { State } from '../../reducers';
import { DelegatorChainType, DelegatorDataType } from '../../services/delegator/delegator.data';
import '../zendit/css/zendit.scss';
import { EvmCard } from './components/EvmCard';
import { EvmSidebarBody } from './components/EvmSidebar/Body';
import { NodeInfo } from './components/NodeInfo';
import { NominateBody } from './components/NominateSidebar/SideBarBody';
import { DelegatorSearch } from './components/Search';
import { TendermintCard } from './components/TendermintCard';
import { ViewNominateBody } from './components/ViewNominateSidebar/Body';
import Disclaimer from '../../components/disclaimer/Disclaimer';

const CardRow = styled.div``;

export function Delegator() {
  const [showViewNominateSidebar, setShowViewNominateSidebar] = useState(false);
  const [showNominateSidebar, setShowNominateSidebar] = useState(false);
  const [showEvmSidebar, setShowEvmSidebar] = useState(false);
  const { USD: currencies } = useSelector((state: State) => state.basic.currency);
  const [chainType, setChainType] = useState<DelegatorChainType | 'ALL'>('ALL');
  const { walletAddress, delegatorData } = useSelector(({ account, delegators }: State) => ({
    walletAddress: account.address,
    delegatorData: (delegators as DelegatorDataType).delegatorData,
  }));

  const filteredData = delegatorData.filter((data) => {
    if (chainType === 'ALL') return data;
    return data.type === chainType;
  });

  return (
    <>
      <div className='container'>
        <div className='col-md-12'>
          <Header />
          <DelegatorSearch chainType={chainType} setChainType={setChainType} />
        </div>

        <div
          className='container'
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <Text dark={true} as='span' style={{ color: 'white' }} size='sm' weight={400}>
            The delegator rewards product has been deprecated. This page is still live for users to
            remove their delegations.
          </Text>
        </div>
        <CardRow>
          <SideBar show={showNominateSidebar} setShow={setShowNominateSidebar} dark={true}>
            <>
              <NominateBody setShow={setShowNominateSidebar} />
            </>
          </SideBar>
          <SideBar show={showViewNominateSidebar} setShow={setShowViewNominateSidebar} dark={true}>
            <>
              <ViewNominateBody setShow={setShowViewNominateSidebar} />
            </>
          </SideBar>
          <SideBar show={showEvmSidebar} setShow={setShowEvmSidebar} dark={true}>
            <>
              <EvmSidebarBody setShow={setShowEvmSidebar} />
            </>
          </SideBar>
          {}
          <div>
            {filteredData.map((d, i) => (
              <div className='col-md-4' key={i}>
                {d.type === 'TENDERMINT' ? (
                  <TendermintCard
                    delegator={d}
                    showNominateSidebar={showNominateSidebar}
                    setShowViewNominateSidebar={setShowViewNominateSidebar}
                    setShowNominateSidebar={setShowNominateSidebar}
                  />
                ) : (
                  <EvmCard
                    chainId={d.chainId}
                    setShowEvmSidebar={setShowEvmSidebar}
                    showEvmSidebar={showEvmSidebar}
                  />
                )}
              </div>
            ))}
          </div>
        </CardRow>

        <div className='col-md-12'>
          <Text size={'sm'}>
            <a
              href='https://legacy.mantradao.com/staking'
              target='_blank'
              rel='noopener noreferrer'
            >
              Looking for existing DOT and KSM delegator rewards?
            </a>
          </Text>
          <Text size={'sm'}>
            <a
              href='https://docs.mantradao.com/guides/delegator'
              rel='noopener noreferrer'
              target='_blank'
            >
              New to Delegator Rewards? Check out our guide.
            </a>
          </Text>
        </div>

        <div className='col-md-12'>
          <NodeInfo />
        </div>
        <Disclaimer dark={true} />
      </div>
    </>
  );
}
