import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg width='45' height='40' viewBox='0 0 45 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.3646 36.6851C26.298 36.6851 32.7293 30.2538 32.7293 22.3205C32.7293 14.3871 26.298 7.95581 18.3646 7.95581C10.4313 7.95581 4 14.3871 4 22.3205C4 30.2538 10.4313 36.6851 18.3646 36.6851Z'
        stroke='url(#polka_paint0_linear)'
        strokeWidth='6.62983'
      />
      <path
        d='M39.8012 8.83978C42.2423 8.83978 44.2211 6.86093 44.2211 4.41989C44.2211 1.97885 42.2423 0 39.8012 0C37.3602 0 35.3813 1.97885 35.3813 4.41989C35.3813 6.86093 37.3602 8.83978 39.8012 8.83978Z'
        fill='url(#polka_paint1_linear)'
      />
      <defs>
        <linearGradient
          id='polka_paint0_linear'
          x1='18.3646'
          y1='4.64089'
          x2='18.3646'
          y2='40'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FC3868' />
          <stop offset='1' stopColor='#FC3A6B' stopOpacity='0.13' />
        </linearGradient>
        <linearGradient
          id='polka_paint1_linear'
          x1='39.8012'
          y1='0'
          x2='39.8012'
          y2='8.83978'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0420559' stopColor='#08DEFC' />
          <stop offset='1' stopColor='#08DEFC' />
        </linearGradient>
      </defs>
    </svg>
  );
}
