import React from 'react';
import PropTypes from 'prop-types';
import TokenColumn from '../../../../../screens/lending/blocks-for-lending-modals/token-column';
import ModalButton from '../../../../../screens/lending/blocks-for-lending-modals/modal-button';
import styles from './supply-row.module.scss';

const SupplyRow = ({ tokenColumn, borrowColumn, disabled, onClick }) => (
  <>
    <div className={styles.row}>
      <TokenColumn title='Supply Rates' list={tokenColumn} />
      <TokenColumn title='Borrow Limit' list={borrowColumn} />
    </div>
    <div className={styles.buttonWrapper}>
      <ModalButton text='Supply' disabled={disabled} onClick={onClick} />
    </div>
  </>
);

SupplyRow.propTypes = {
  tokenColumn: PropTypes.array,
  borrowColumn: PropTypes.array,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SupplyRow.defaultProps = {
  tokenColumn: [],
  borrowColumn: [],
  disabled: false,
  onClick: () => {},
};

export default React.memo(SupplyRow);
