import React from 'react';
import Loader from '../../loader';
import styles from './claim-rewards-modal.module.scss';

const Loading = () => (
  <div className={styles.body}>
    <Loader className={styles.loader} />
  </div>
);

export default Loading;
