import React from 'react';
import * as s from './styles.module.scss';
import OmIcon from '../../assets/images/tokens/token-type2/om.svg';
import PartyPpopperIcon from '../../assets/zenterest/party-popper.png';

export const DistributionOmIcon = () => (
  <div className={s.imageContainer}>
    <img className={s.imagePetard} src={PartyPpopperIcon} alt='imagePetard' />
    <img className={s.image} src={OmIcon} alt='token' />
  </div>
);
