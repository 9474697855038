import web3Service from '../../web3-service';
import MANTRA_POOL_ABI from '../../../blockchain/abi/MANTRA_POOL.json';
import config from '../../../config/env';

class MantraPoolContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      MANTRA_POOL_ABI,
      config.REACT_APP_MANTRA_POOL_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  /**
   * Get amount rounds
   * @returns {string}
   */
  async roundsCount() {
    return this.contract.methods.roundsCount().call();
  }

  /**
   * Get info about round by number round
   * @param {string|number} roundIndex
   */
  async round(roundIndex) {
    return this.contract.methods.round(roundIndex).call();
  }

  async roundParticipant(roundIndex, address) {
    return this.contract.methods.roundParticipant(roundIndex, address).call();
  }

  async buyEntries(address, roundIndex, amount) {
    const options = { from: address };
    return this.contract.methods.buyEntries(roundIndex, amount).send(options);
  }

  async roundCalculation(roundIndex) {
    return this.contract.methods.roundCalculation(roundIndex).call();
  }

  async withdrawRewards(address, roundIndex) {
    const options = { from: address };
    return this.contract.methods.withdrawRewards(roundIndex).send(options);
  }

  async rewardWithdrawalPeriod() {
    return this.contract.methods.rewardWithdrawalPeriod().call();
  }
}

export default new MantraPoolContractService();
