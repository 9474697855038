import { DARK_MODE, LIGHT_MODE, UPDATE_CURRENCIES, TOGGLE_MENU } from '../actions/action-types';

import { ETH, FXF, LABS, L3P, OM, RFUEL, ROYA, PKF } from '../constants/blockchain-constants';

export default (
  state = {
    dark: true,
    light: false,
    isOpenMenu: false,
    currency: {
      USD: {
        [ETH]: 0,
        [OM]: 0,
        [RFUEL]: 0,
        [ROYA]: 0,
        [FXF]: 0,
        [L3P]: 0,
        [LABS]: 0,
        [PKF]: 0,
      },
    },
  },
  action,
) => {
  switch (action.type) {
    case DARK_MODE:
      return { ...state, dark: true, light: false };
    case LIGHT_MODE:
      return { ...state, dark: false, light: true };
    case UPDATE_CURRENCIES:
      return { ...state, currency: action.payload };
    case TOGGLE_MENU:
      return { ...state, isOpenMenu: !!action.payload };
    default:
      return state;
  }
};
