import _ from 'lodash';
import {
  STAKE_SETTINGS_RAZE_ETH_UNI,
  STAKE_DETAILS_RAZE_ETH_UNI,
  STAKE_SEND_RAZE_ETH_UNI,
  ESTIMATED_DAILY_REWARDS_RAZE_ETH_UNI,
  STAKE_SUCCESS_RAZE_ETH_UNI,
  STAKE_ERROR_RAZE_ETH_UNI,
  RESET_STAKE_RAZE_ETH_UNI,
  STAKE_BALANCE_RAZE_ETH_UNI,
  UNSTAKED_BALANCE_RAZE_ETH_UNI,
  UNSTAKE_TIME_RAZE_ETH_UNI,
  REWARD_EARNED_RAZE_ETH_UNI,
  REWARD_BALANCE_RAZE_ETH_UNI,
  CLAIM_REWARDS_DETAILS_RAZE_ETH_UNI,
  CLAIM_REWARDS_SEND_RAZE_ETH_UNI,
  CLAIM_REWARDS_SUCCESS_RAZE_ETH_UNI,
  UNSTAKE_SETTINGS_RAZE_ETH_UNI,
  UNSTAKE_DETAILS_RAZE_ETH_UNI,
  UNSTAKE_SEND_RAZE_ETH_UNI,
  UNSTAKE_SUCCESS_RAZE_ETH_UNI,
  WITHDRAW_DETAILS_RAZE_ETH_UNI,
  WITHDRAW_SEND_RAZE_ETH_UNI,
  WITHDRAW_SUCCESS_RAZE_ETH_UNI,
  PERCENT_IN_OM_RAZE_ETH_UNI,
  PERCENT_FROM_RAZE_ETH_UNI_SWAP_V2,
  LOGOUT,
} from '../actions/action-types';

// Stake status

// 0 : interest calculator - default

// 1 : stake settings
// 2 : stake details
// 3 : stake sent/pending
// 4 : stake sucessful
// 5 : stake failed

// 20 : unstake settings
// 21 : unstake details
// 22 : unstake send
// 23 : unstake success

// 30 : claim reward details
// 31 : claim reward send
// 32 : claim reward success

// 40 : withdraw unstaked details
// 41 : withdraw unstaked send
// 42 : withdraw unstaked success

// 99 : Error

const DEFAULT_FIELDS = {
  stake: {
    status: 0,
    amount: '0',
    id: '',
    stakeBalance: '0',
    unstakedBalance: '0',
    unstakedTime: '0',
    rewardEarned: '0',
    rewardBalance: '0',
    percentInOM: '0.00',
    percentFromUniSwap: '0',
    estimatedDailyReward: '0',
    perBlockReward: '0',
    omClaimType: 'unstake',
  },
};

export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    // STAKE

    case STAKE_SETTINGS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 1,
          amount: '0',
        },
      };
    case STAKE_DETAILS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 2,
          amount: action.payload.amount,
        },
      };
    case STAKE_SEND_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 3,
        },
      };
    case STAKE_SUCCESS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          id: action.payload.id,
          status: 4,
        },
      };

    // UNSTAKE

    case UNSTAKE_SETTINGS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 20,
        },
      };

    case UNSTAKE_DETAILS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 21,
          amount: action.payload.amount,
        },
      };

    case UNSTAKE_SEND_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 22,
        },
      };

    case UNSTAKE_SUCCESS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          id: action.payload.id,
          status: 23,
        },
      };

    // CLAIM REWARDS / STAKE REWARDS

    case CLAIM_REWARDS_DETAILS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          omClaimType: action.payload.type,
          status: 30,
        },
      };

    case CLAIM_REWARDS_SEND_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 31,
        },
      };

    case CLAIM_REWARDS_SUCCESS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          id: action.payload.id,
          status: 32,
          amount: action.payload.amount,
        },
      };

    // WITHDRAW UNSTAKED

    case WITHDRAW_DETAILS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 40,
        },
      };

    case WITHDRAW_SEND_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 41,
        },
      };

    case WITHDRAW_SUCCESS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          id: action.payload.id,
          status: 42,
        },
      };

    case STAKE_ERROR_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 99,
        },
      };
    case RESET_STAKE_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          status: 0,
          amount: '0',
          id: '',
        },
      };
    case STAKE_BALANCE_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          stakeBalance: action.payload.stakeBalance,
        },
      };
    case UNSTAKED_BALANCE_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          unstakedBalance: action.payload.unstakedBalance,
        },
      };
    case UNSTAKE_TIME_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          unstakedTime: action.payload.unstakedTime,
        },
      };
    case REWARD_EARNED_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          rewardEarned: action.payload.rewardEarned,
        },
      };
    case REWARD_BALANCE_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          rewardBalance: action.payload.rewardBalance,
        },
      };
    case PERCENT_IN_OM_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          percentInOM: action.payload.percentInOM,
        },
      };
    case PERCENT_FROM_RAZE_ETH_UNI_SWAP_V2:
      return {
        stake: {
          ...state.stake,
          percentFromUniSwap: action.payload.percentFromUniSwap,
        },
      };
    case ESTIMATED_DAILY_REWARDS_RAZE_ETH_UNI:
      return {
        stake: {
          ...state.stake,
          estimatedDailyReward: action.payload.estimatedDailyReward,
          perBlockReward: action.payload.perBlockReward,
        },
      };
    case LOGOUT:
      return _.cloneDeep(DEFAULT_FIELDS);
    default:
      return state;
  }
};
