import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      xmlSpace='preserve'
      className={className}
      {...otherProps}
    >
      <image
        width='50'
        height='50'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
B3RJTUUH5QQHCzEgE2x2cwAADtFJREFUaN7FmnlwVVWawH93eSvJey8bLyErSYAkEFYBCaCAAuKg
uI8ojuLW47TWVI/T3aPdU9UzPVu13W07pUx3uzBquXQroAJugAQFZEd2CCQkBEgIWd7+8u525o8b
YyNbguB8Vbfuq3fPPff7ne873/m+c68khBBcZhGAYRh0pzQMw0QgUBQFt9OJ06EiSdLlfiTq5ejE
tCxOdXRyoL6JPXUN1DU209reSTgWx9ANQKCoKmleDzkZfkoLBzFiSCkjygdTkDcQl8PxnXWQvotF
2rvCrNv6FSvWfcmeunosS1CQm0N5UT5FuQPJzszA63EBEoZh0BWO0tLeTn3zSZpOtJLoTlGUF2Rm
zVXMnjKBkkG5yLL8/YGcPN3B2yvX8O6ntaQ0jcljq5lVM55RFeUMzMq46AhblkU4FudI03E+376b
VRu30t4Z4trxY3jg1jkMLx+MLPfP/foFktJ0lq5ax3Ovv4ssy9x700xunj6ZgmDOd/L7WCLJpl37
ePW9j9ldV8+t113D382/hYFZGZcfpPV0B//54hus3bKDBTfN4v5bbiCYlXnJyp9LulMaazZt5/k3
l2KaFv/82P1MGVvdt0ESfZAd++vEzIf+Qcx7/Gmx80CdsKy+3NV/MU1LaJohWk93in9d9L9i9K0L
xR///IHQdP2i914UZM2X28T4ux4VP/3N70VHKHxlCHpBTJFIpkSyWxOJ7pR4b816Mf6uR8UvXlgs
4onkpYOsqN0oqufdL369+G2R0rQrCiGEEIZhg3x9pDRdbNixR0ya/5h46rd/EInu7v6D1G7ZKarn
PSCeffXPQjeMKw5xLpCvYbbuOSgmzf9b8e+/f+28upwTZO/hBnHVHQ+Lnz/3Yp/880qC2DCGWLt5
pxh5y0Lx6nsfnfPes1afUDTGz597ieL8PP7xwfk41Muy+PdJBOcOoKZpUjN6BD/6mzt59rV32Hng
8FltzgJ5eclKGppP8ssnHsSfNuB7gwC40EJgWRZ3zJ7O5DHV/OqlN4knu88PsvfwUV56Zzk/vOdW
KstKvlcIG+T8JAJwqCqP33sbDSdOsmz15+cGMS2LRW8tIz93IHfNmfG9Q1wMBGyrlBYMYv6N1/PK
0pV0haNngxxqOMbqL7ez8NY5BNLT/l8gLgZiwwhunzkNw7D4eP3ms0GWrFpHdoafOVMm9ksB3TCI
J7sJR+N2/WGadIYjRGJxW7k+9mMJQV+SJSEEwewM5k6rYdmaL9B0HeipR2KJJGs372DejClkBnwX
7awjFEbTDWRZZsuufZzq7CIejVNalI+syFimxcH6RiaMHo7X7SbDn05eTjYet+u8abplWf2wHsya
PJ5lq9dx5NgJqspKbJCDDcc42d7JjIljzzlSlmnR1tGJJQS79h9i35FGmlvbGF5ewusrV1N37ATX
jhrO3rp6IokELo+b0rxcVq/fQsDvw5JlhhUXMKZqKBm+dLweN/K3EkHL6ns1YVkWg/MHkR8cyBfb
d38DsmXPfgJpAygrygcgmUqxr66BYHYm67fv5njLKUZWlJMbzCEUjWEZJoXBHE62dTCitIQxFUPI
ShtAOBbH4/XwxVd76ewMUVYwiLbOEIHMDBwOlVgiQWlhPi6ng1iym4nVlTgdjh636l9Z5HW7mDRq
OJt37+fhO+baIF8dOsKE6koy/ekYhsmSlZ+w5MPV+Hw+TrS1UzWsnKKCPOLJJMUFeWQG/Oyrq2dQ
bg7F+UES8QR1DU2UFheQmRFg4shKwtEYumFiWAJT17E0jWg0zur1mznWcoraHXt48JYbmDV5AoMG
5uBPT+8XiACGlw/m/doNtHeFUCOxOIcbj3PzjCkAtHeFWPz+J3y2aSuKZwBpbhcn206xfc9+DNMk
NysDU9foiCVBkuiKxtENA7M7gaLIuLxppDlVXMKgJJhFYWERFcOrKSvO58DhowR8PjpDEZ645zbq
jx7jvU9qyQvmMGvqJHKzM/tc6gphl9Vad4pT7V2okViCrkiU4kFBwI5CqkMFWcUyTcIpnXBLAppb
weWBw02gp0CWQXHYZwBdg+44mAZYJqQSrI9HqCgt4onCIl55cyOHjjZSkhdk8rgxOFUFp8uFx+tl
1Zfb0DSdsuJCZky6qk+FlBCC7IAfl9NJffMJ1Gg8jqbpZPntaOX1eBhZVsynm78CU0dSVFAddqgw
NHC4QPHav00NhAqKgpBlUFUkU0cYKfs6gubmZjrbTyPLCrv2H2LD5q2s/HAlVaXF1EyZiuZ2caDp
OP70NA43NhOJRplRM4GAL/3CK70QeD1usrMCRGIJ5LbOEJpu4HW7AHC7nMydNoWSYBZCcYDebXuk
wwlItjUAVJd9mDokokiJCKQSNoRpgLAAQTweJ9TRxpzrp3P91BpweQkZsHHXfl5YtIhVy5dQlhOg
ruk4gYCPWDLF6o1b6ApHLmoZRVFI83pJGTpyLNmNbhq94dDldFA5bCiP3TEXl9OBkFXQUt/ASDJo
SbAM261UB0JWEAgk00QyDRvONG0Yy2LL1u3s2HeIGTUTmTB8qD0gikpSN9mwfj11m2qpzgvQrRkc
PtlKY0sby1bVEopEUWQZWZLOCSVhZ8wdoQiyosiAhNkTx1VFIX2Alzv/6gYemDMdVVFBViCVtH3f
4bQtoafsOaGlwDKRhABJ6jlk+2z7AOleN+3hCIrTzc3XT8frcdtaAAiLwwf28v5brxFqbUaVJHTT
JBSNs37rTg42NHL8VButpzvQdOMMICEEpmnhcqio2T4fiiwTSyZ7G3hcToLZWTz50H2A4NWPaunW
JCS9x21Upz1XdA2hdyMZKTB0+5qhgaEjWSYIEJJEKBxi5uBCmto6UHSDosIiDh7qBjmFrKj4s7LJ
y8tlUEYa100ez+Zd+/C6XBxqaGLLV3sZMriIaCLJiIohSIrM1dVVOB0OUrpOLJ4g2+9HzQr4cDgU
TnV0nWE2j8dFfl6Qpx57iMLcIC+8s4KWrjCSZdiuJYR9yDIoKvQsapKsIMm2S9nXFPbv3UdTQwPC
k05+MJvi3BzioS6uHjGFmqvGMnbMaMpKy/D7fZiWYMTQco6dbOWX/7OY6vLBVA4t5eU/vY+iKOgS
GCmNQHoawZxsTnd2keFPR00b4CXd6+VYa9tZ/ud1u8gbmMMP7r2LscMreGXpctZs20NXLNEzD7Re
C2DqYBgI07RdsGd+IAShzg6OHtxD2Zga9JTG7KmTeeZnP6G4qBC3yw0ILMtCCIGqgKrIZGcEKMwd
SO22XYyqHMqIYeVkpKcxpLSEUDhMhs9HKBLFNC3KivJRA740ivNzOXikEUuIs3Igp0Ml0+9jWs1E
RlVVsGPPPlZt3MyGnXtoPN5COBrBsHqUliyQJCRJxuF04Qv4KcwLMmZkNTfOmcOIMeNoPtnKyGFD
CPjSMS0L0zTOWrG/XhQ7whEKgznsratn1pSJVA8txxIWsizjdKisqN2Iy+0iJzOA6lBVxlUO5Y2V
q2nr6CI3++zdQ1mW8LicuAdmM3vaVK6dNIH2jk6amk9wtLmZltZThCNRLNPA5VDJCvgpGJTH4JJi
igoLycrMACSSKY2C4EBkWca8QLZrWhbdmsbxU6eJJpJUVZTj96WjKjKWkL6OIew+VE/l4CKyA347
15o4sorn31rGgfrGc4L0upsk4VAVHKqX9AFeBhcVcC0TEZawwy8Skizx7UBpWQJdN1D6mH7IkoTX
7ebh2+eydNU6SvPzyMvOwupZICVJIhKLs2HnHv56zgwkSbILq1EV5eRlZ/LRX1RcfRWpx2KKLCOf
B0LTjV4l+tSnJOEb4OXa8WMZXlqCMEzcLucZHnKgoYn2rjBTx420/wPIzvAz4+pxLF+7gWMtp/oN
cz4xLQtNN/qdogMoiozb5eT22dOJRKLoxjdzybIEH6xdT3lxPoPz874BAbhz1jRC0ThLPl13WSAM
00S/RAiwreJxuyjIC1Jz1ShM055TsixT33yCT9Zv5vaZ1+BwqGeCjK4cwnUTx/Lq+x/T/K1Q3B8R
wp4Pum72qQa/kCiyjNftYkhJES6no7f/1z/4hAxfOrMmT+ht2wvidKg8seB22jtDvPTO8ksaSavH
lQyz7/X3xUSSpF5dFEVm+/5DLF21jkfvvIkM3zfF2BlhZEJ1JQ/fMZeX3l3B2i07+2cFw7QndT9q
7/4ChaNxnnn5TUZXlHPL9deccV3+duPH5t9CRWkxT//ujzSdvPjEN80eKxjf3ZUuJJZl8fybSzjQ
0MQ/PbKAAR73+UEAglmZ/MePHqUjFOGpZ/9AVyR6zo5Ny0LTjCtqhV4lZZl3PlnLK0s/5KlHFjC2
aujZbc5148SRVfz2p4/z+bZd/Ox3LxKJxc8YGU030DTjgqvzZYOQJFbUbuAXixbz6J03ce9NM8/d
7nwdzL12Er/5yQ/5YO0GnvzVC5zuDGGYFinN6A2FV1IkSQIJ3vm0liefeYG7Zk/nyYV3n/c1xwXf
6gohWL52A08+s4hhJUX8298/QlVZcU+meuUgFFkmlkjy4rvLWfT2ezx42438eOHddkF2PnDRhzi7
efd+fvzMIjrCUZ56ZAE3TavB43ZddsvIPRXmgYZG/uvFN9i29yBP/2AB9908+6IvnPr8nr3ldAe/
Xvw2f/roM6aMG8kT99zG6MohOFRHby1xyQCyjCTZz3j7wzUsXvYhxYNy+ZfHFzJxZFWftof69eWD
bhis3riN/35jCXWNzVxz1WjuvvE6xlUNxZc2AAmpF+pCnUqS1LuhoOk6R0+0sGLdRpasWkcqpXPf
zbN56PYbyfRffEP9kkC+llgiyWebdvDGilXsOnSEvJwspo4bRc3oEZQX55Pl9+FyOVFkGUmSe3c7
LEugGwaReJzjrafZeaCOz7ftYv+RRrIz/My7bgrzZkylMLf/n4R8p6+DNF3nYMMxVm/axrqtu2g6
0YokS2QH/ASzM8gK+PG4XUhI6IZBOBrjdFeI9q4w3SmNgVkZTBhRwYyrxzGmcgjpA7yXqsp3A/lL
6U5pNLe2cfDoMfYfaaSlvYNEIkk0FrdrEUki4EunKC9IRWkRFYOLKMnPZYDHczkez/8BT/okF+gR
22YAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDYtMDlUMDg6MjI6NTErMDM6MDChqxdQAAAAJXRF
WHRkYXRlOm1vZGlmeQAyMDIxLTA2LTA5VDA4OjIyOjUxKzAzOjAw0Pav7AAAAABJRU5ErkJggg=='
      />
    </svg>
  );
}
