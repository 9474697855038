import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='77'
      height='59'
      viewBox='0 0 77 59'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M38.2177 7.12598C27.095 7.12598 19.0449 11.6661 19.0449 15.7847C19.0449 19.865 27.0258 24.4434 38.2177 24.4434C49.434 24.4434 57.3906 19.8547 57.3906 15.7847C57.3906 11.6597 49.322 7.12598 38.2177 7.12598Z' />
      <path d='M19.0449 24.5667C19.3914 24.8133 19.4337 24.8415 19.0449 24.5649V24.5667Z' />
      <path d='M57.3924 24.5665V24.5647C57.0453 24.8083 57.0736 24.7903 57.3924 24.5665Z' />
      <path d='M43.9297 2.61003C52.6672 3.76616 62.2751 8.1812 62.2751 15.7841V17.0249C70.6881 15.9294 76.4344 12.1566 76.4344 8.68342C76.4344 1.89641 56.9669 -3.37259 43.9297 2.61003Z' />
      <path d='M57.393 24.5647C52.0764 28.2967 44.6971 29.7043 38.2202 29.7043C31.7433 29.7043 24.364 28.297 19.0474 24.5647C19.0474 33.1859 19.015 32.8951 19.0965 33.369C19.9427 38.2789 29.0584 41.21 38.22 41.336C47.3817 41.2098 56.4974 38.2789 57.3436 33.369C57.4251 32.8952 57.393 33.1859 57.393 24.5647Z' />
      <path d='M57.3925 41.5874C52.2576 45.2376 44.7799 46.727 38.2197 46.727C31.6595 46.727 24.182 45.2378 19.0469 41.5874C19.0469 50.2716 19.016 49.9199 19.096 50.3935C20.1225 55.9376 31.4681 58.4895 38.2195 58.4895C44.971 58.4895 56.3165 55.9378 57.3431 50.3935C57.4234 49.92 57.3925 50.2716 57.3925 41.5874Z' />
      <path d='M76.4366 34.4868C72.51 37.2738 66.8944 38.8853 62.2773 39.3439C62.2773 50.8237 62.3105 50.2543 62.1797 51.0852C69.4662 50.1733 75.7604 46.9325 76.3878 43.2924C76.4555 42.8972 76.4366 43.4126 76.4366 34.4868Z' />
      <path d='M76.4366 17.4631C72.5044 20.2712 67.1195 21.771 62.2773 22.3219V34.0485C71.3061 32.693 76.2442 29.1134 76.4284 25.9318C76.4408 25.7486 76.4366 26.0039 76.4366 17.4631Z' />
      <path d='M0.00390625 8.68342C0.00390625 12.1566 5.7502 15.9295 14.1632 17.0249V15.7841C14.1632 8.1812 23.7711 3.76601 32.5086 2.61003C19.4716 -3.37259 0.00390625 1.89641 0.00390625 8.68342Z' />
      <path d='M14.1617 39.3437C9.54465 38.8852 3.929 37.2737 0.00244031 34.4866C0.00244031 43.4124 -0.0165199 42.897 0.0512591 43.2922C0.678589 46.9322 6.97278 50.1731 14.2593 51.085C14.1285 50.254 14.1617 50.8234 14.1617 39.3437Z' />
      <path d='M0.00258862 17.4631C0.00258862 26.0039 -0.00144229 25.7486 0.0107997 25.9316C0.195177 29.1132 5.13305 32.693 14.1618 34.0484V22.3217C9.31982 21.771 3.93482 20.2712 0.00258862 17.4631Z' />
    </svg>
  );
}
