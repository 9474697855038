import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 32 32'
      className={className}
      {...otherProps}
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='-1357.02'
          y1='1453.07'
          x2='-1358.02'
          y2='1453.07'
          gradientTransform='matrix(20, 0, 0, -23, 27208, 33443)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#01cbe0' />
          <stop offset='0.45' stopColor='#48a3fd' />
          <stop offset='1' stopColor='#4477cf' />
        </linearGradient>
        <linearGradient
          x1='-1364.24'
          y1='1452.57'
          x2='-1364.24'
          y2='1453.92'
          gradientTransform='matrix(22, 0, 0, -23, 30072, 33432)'
          xlinkHref='#linear-gradient'
        />
      </defs>
      <path
        d='M48.07,16.12C51,24.24,58.83,34,58.83,34a32.46,32.46,0,0,0,2.76-3.38c4.84-6.61,5.77-9.82,6-10.82-.72.05-6.64,1.82-7.38,1.82-9.18,0-11.6-5.46-11.6-10.48V11A4.93,4.93,0,0,0,48.07,16.12Z'
        transform='translate(-47.64)'
      />
      <path
        d='M58.08,9.66h7.66c0-.23-.07-.45-.12-.68V9a6.93,6.93,0,0,0-2-3.74A6,6,0,0,0,60,3.61a8.46,8.46,0,0,0-.87,0,8.1,8.1,0,0,0-3.64.76,6.37,6.37,0,0,0-1.39.9,7,7,0,0,0-2.11,3.2,8.45,8.45,0,0,0-.55,3c0,4.39,3.27,8,7.29,8h.1c.3,0,.62,0,.94,0A8.24,8.24,0,0,0,62.37,19a8,8,0,0,0,2.27-1.12A2.2,2.2,0,0,1,66,17.4a1.88,1.88,0,0,1,1.27.42,1.79,1.79,0,0,1,.76,1.41,1.49,1.49,0,0,1-.67,1.24,11.76,11.76,0,0,1-3.58,1.82,13.2,13.2,0,0,1-10.33-.76,10.85,10.85,0,0,1-4.31-4.08,11.42,11.42,0,0,1-1.54-5.93A12,12,0,0,1,49.1,5.57a10.42,10.42,0,0,1,4.07-4.1A11.83,11.83,0,0,1,59.08,0a10.83,10.83,0,0,1,5.64,1.41,9.34,9.34,0,0,1,3.64,3.94,12.7,12.7,0,0,1,1.28,5.8,1.74,1.74,0,0,1-.55,1.3,2,2,0,0,1-1.4.52H58.08a1.66,1.66,0,1,1,0-3.31Z'
        transform='translate(-47.64)'
      />
    </svg>
  );
}
