import BigNumber from 'bignumber.js';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../../actions/delegator-actions';
import time from '../../helpers/delegator.helpers';
import Loader from '../../../../components/loader';
import Button from '../../../../external/components/Button/Button';
import {
  StyledDd,
  StyledDefWrapper,
  StyledDl,
  StyledDt,
} from '../../../../external/components/DefLists';
import { Highlight } from '../../../../external/components/Highlight/Highlight';
import Text from '../../../../external/components/Text/Text';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { ProviderRpcError } from '../../../../types/metamask.types';
import { ButtonContainer } from '../../../staking/components/ButtonContainer';
import { timeHelpers } from '../../../staking/helpers/timeHelpers';
import { useDelegatorClaim } from '../../hooks/claim.hook';
import { EvmDelegatorScreensEnum } from './Body';
import { ErrorAlert } from '../../../../external/components/ErrorAlert/ErrorAlert';
import { getNetworkText } from '../../../staking/helpers/getNetworkText';

interface Props {
  selectedChainId: DelegatorChainIds;
  setScreen: React.Dispatch<SetStateAction<EvmDelegatorScreensEnum>>;
}
export function EvmDelegationScreen({ selectedChainId, setScreen }: Props) {
  const dispatch = useDispatch();
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawIndex, setWithdrawIndex] = useState('');

  const { setRewardType, setWithdrawAmount } = bindActionCreators(DelegatorActions, dispatch);
  const { walletNetworkId, walletAddress, delegatorData } = useSelector(
    ({ account, delegators }: State) => ({
      walletNetworkId: account.networkId,
      walletAddress: account.address,
      delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
        (del) => del.chainId === selectedChainId,
      )[0],
    }),
  );

  const {
    totalDelegated,
    chainSymbol,
    chainId,
    unclaimedRewards: rewardBalance,
    rewardArray,
    metadata,
    delegationUrl,
    networkId,
    totalMonthlyRewards,
  } = delegatorData;

  const { longClaimPeriod, shortClaimPeriod, shortRewardPercentage } = metadata;

  const [loading, setLoading] = useState(false);

  const { fetchRewards, getMetadata, getRequestedRewardArray, sendReceiveReward } =
    useDelegatorClaim({
      chainId,
      walletNetworkId,
      networkId,
    });

  const init = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchRewards(), getMetadata(), getRequestedRewardArray()]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setScreen(EvmDelegatorScreensEnum.ERROR);
    }
  };
  const invalidNetwork = walletNetworkId !== networkId;

  useEffect(() => {
    if (!invalidNetwork) {
      init();
    }
  }, []);

  const handleWithdraw = async (i: string, isFullWithdrawal: boolean, amount: string) => {
    setWithdrawIndex(i);
    try {
      const shiftedAmount = new BigNumber(amount)
        .multipliedBy(isFullWithdrawal ? 1 : parseFloat(shortRewardPercentage || '0'))
        .toFixed();

      setWithdrawLoading(true);
      await sendReceiveReward(i);
      setWithdrawAmount(shiftedAmount);
      setWithdrawLoading(false);
      setScreen(EvmDelegatorScreensEnum.WITHDRAW_SUCCESS);
    } catch (_error) {
      const error = _error as ProviderRpcError;

      if (error.code === 4001) {
        // do nothing if RPC error
        setWithdrawLoading(false);
        return;
      } else {
        setWithdrawLoading(false);
        setScreen(EvmDelegatorScreensEnum.ERROR);
      }
    }
  };

  const filteredRewardArray = rewardArray?.filter(({ amount, isWithdrowedByNominator }) => {
    if (amount === '0' || isWithdrowedByNominator) return false;
    else return true;
  });

  const renderBody = () => {
    if (invalidNetwork) {
      return (
        <ErrorAlert>
          <Text dark={true} style={{ margin: '0.5rem 0' }}>
            {' '}
            Please connect to the {getNetworkText(networkId)} network, to start claiming and
            withdrawing your rewards.
          </Text>
        </ErrorAlert>
      );
    }
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <Text dark={true}>
            <a href={delegationUrl} target={'_blank'} rel='noreferrer noopener'>
              Delegate now
            </a>{' '}
            to start earning rewards.
          </Text>
          <div style={{ marginTop: '-2rem' }}>
            <StyledDefWrapper>
              <StyledDl>
                <StyledDd>
                  <Text style={{ margin: '0.25rem 0' }} dark={true}>
                    Total delegated
                  </Text>
                </StyledDd>
                <StyledDt>
                  <Text style={{ margin: '0.1rem 0 0.75rem' }} dark={true}>
                    {totalDelegated} {chainSymbol}
                  </Text>
                </StyledDt>
                <StyledDd>
                  <Text style={{ margin: '0.25rem 0' }} dark={true}>
                    Next reward drop
                  </Text>
                </StyledDd>
                <StyledDt>
                  <Text style={{ margin: '0.1rem 0 0.75rem' }} color='lightgrey'>
                    {'PAUSED'}
                  </Text>
                </StyledDt>
                <StyledDd>
                  <Text style={{ margin: '0.25rem 0' }} dark={true}>
                    Available rewards
                  </Text>
                </StyledDd>
                <StyledDt>
                  <Text dark={true} style={{ margin: '0.1rem 0 0.75rem' }}>
                    {rewardBalance} OM
                  </Text>
                </StyledDt>
                {rewardBalance !== '0' && (
                  <ButtonContainer style={{ margin: '0.5rem 0 1.5rem' }}>
                    <Button
                      variant='secondary'
                      dark={true}
                      disabled={invalidNetwork}
                      onClick={() => {
                        setRewardType({ selectedRewardType: 'SHORT' });
                        setScreen(EvmDelegatorScreensEnum.CLAIM_SCREEN);
                      }}
                    >
                      Short claim
                    </Button>
                    <Button
                      variant='secondary'
                      dark={true}
                      disabled={invalidNetwork}
                      onClick={() => {
                        setRewardType({ selectedRewardType: 'LONG' });
                        setScreen(EvmDelegatorScreensEnum.CLAIM_SCREEN);
                      }}
                    >
                      {' '}
                      Long claim
                    </Button>
                  </ButtonContainer>
                )}
              </StyledDl>
            </StyledDefWrapper>
          </div>
          <Text dark={true}>Unclaimed rewards</Text>

          {filteredRewardArray && filteredRewardArray.length > 0 ? (
            <>
              {filteredRewardArray.map(
                ({ amount, timestamp, isFullWithdrawal, isWithdrowedByNominator, index }) => (
                  <Highlight darkAlt={true} key={index} style={{ marginTop: '1rem' }}>
                    <div
                      className='flex align-center justify-space-between'
                      style={{ padding: '0 1rem' }}
                    >
                      <div>
                        <Text color='lightgrey'>
                          {isFullWithdrawal ? 'Long claim' : 'Short Claim'}:{' '}
                          {isFullWithdrawal ? amount : parseFloat(amount) * 0.2} OM
                        </Text>
                        <Text color='lightgrey'>
                          {timeHelpers.checkWhenAvailable(
                            isFullWithdrawal ? longClaimPeriod || '0' : shortClaimPeriod || '0',
                            parseInt(timestamp),
                          )}
                        </Text>
                      </div>
                      <Button
                        dark={true}
                        variant='secondary'
                        onClick={() => handleWithdraw(index, isFullWithdrawal, amount)}
                        disabled={
                          !timeHelpers.isAvailable(
                            isFullWithdrawal ? longClaimPeriod || '0' : shortClaimPeriod || '0',
                            parseInt(timestamp),
                          ) || withdrawLoading
                        }
                        loading={withdrawLoading && withdrawIndex === index}
                      >
                        Withdraw
                      </Button>
                    </div>
                  </Highlight>
                ),
              )}
            </>
          ) : (
            <Text color='lightgrey'>**No unclaimed rewards**</Text>
          )}

          <Text dark={true}>How do delegator rewards work?</Text>
          <Text color='grey'>
            MANTRA DAO indexers gather information about your delegations on the {chainSymbol}{' '}
            network.
          </Text>
          <Text color='grey'>
            Once a month, a reward bucket will be able to be claimed by the linked and delegating
            participants of the month. You have two options with your rewards, 20% unlocked in 30
            days (sacrifice 80%), or a 100% full reward claim after 180 days of locking.
          </Text>
          <Text color='grey'>
            The current size of the monthly reward bucket is {totalMonthlyRewards.toLocaleString()}{' '}
            OM.
          </Text>
          <Text color='grey'>
            The reward pool is allocated based on how long you delegated to MANTRA DAO's{' '}
            {chainSymbol} node throughout the month.
          </Text>
        </>
      );
    }
  };

  return (
    <>
      <>{renderBody()}</>
    </>
  );
}
