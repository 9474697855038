import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 50 57'
      className={className}
      {...otherProps}
    >
      <path
        fill='#375BD2'
        d='M24.56 12l14.18 8.14V36.5L24.6 44.69l-14.17-8.12V20.22L24.56 12zm0-12l-5.2 3L5.2 11.22l-5.2 3v28.37l5.2 3 14.18 8.14 5.2 3 5.2-3 14.13-8.2 5.2-3V14.12l-5.2-3L29.74 3l-5.2-3h.02z'
      ></path>
    </svg>
  );
}
