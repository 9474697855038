import React from 'react';
// import BN from 'bignumber.js';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAsset, setActiveTab } from '../../../../actions/staking-actions';
import BalanceItem from './index';
import { DOT, TOKEN_OPTIONS } from '../../../../constants/blockchain-constants';
import * as modalActions from '../../../../actions/modal-actions';
import { formatDotToken } from '../../../../helpers/token-helper';
import { POLKADOT_ACCOUNT_MODAL } from '../../../../constants/modal-constants';
import { NOMINATE_TOKENS_PATH } from '../../../../constants/router-constants';

const Dot = () => {
  const dispatch = useDispatch();
  const { dotBalance /*dotData*/ } = useSelector((state) => state.token);
  try {
    const { bondedAmount } = useSelector((state) => state.delegator.polkadot);
    // console.log(bondedAmount); TODO rm console.log
    return (
      <BalanceItem
        token={TOKEN_OPTIONS[DOT].title}
        icon={TOKEN_OPTIONS[DOT].icon}
        balance={dotBalance}
        buttonText='Nominate'
        formattedBalance={formatDotToken(dotBalance)}
        formattedStakeBalance={formatDotToken(bondedAmount)}
        stakeBalance={bondedAmount}
        onClick={() => {
          dispatch(modalActions.hideModal(POLKADOT_ACCOUNT_MODAL));
          dispatch(setActiveAsset(DOT));
          dispatch(setActiveTab(NOMINATE_TOKENS_PATH));
        }}
      />
    );
  } catch (error) {
    throw error;
  }
};

export default Dot;
