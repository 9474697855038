import React from 'react';
import PropTypes from 'prop-types';
import OmIcon from '../../../../assets/images/tokens/token-type2/om.svg';
import EnableRow from './enable-row';
import RepayRow from './repay-row';

const Repay = ({
  isEnabled,
  disabled,
  onEnable,
  onRepay,
  tokenIcon,
  borrowAPY,
  distributionAPY,
  borrowBalanceUsd,
  borrowLimitPercent,
  newBorrowBalanceUsd,
  newBorrowLimitPercent,
  isPaused,
}) => {
  const tokenColumn = [
    {
      tokenImage: tokenIcon,
      apyName: 'Borrow APY',
      apyValue: borrowAPY,
    },
    {
      tokenImage: OmIcon,
      apyName: 'Distribution APY',
      apyValue: distributionAPY,
    },
  ];

  const borrowColumn = [
    {
      apyName: 'Borrow Balance',
      usdValue: borrowBalanceUsd,
      newUsdValue: newBorrowBalanceUsd,
    },
    {
      apyName: 'Borrow Limit Used',
      percentValue: borrowLimitPercent,
      newPercentValue: newBorrowLimitPercent,
    },
  ];

  return (
    <>
      {isEnabled ? (
        <RepayRow
          borrowColumn={borrowColumn}
          tokenColumn={tokenColumn}
          disabled={disabled}
          onClick={onRepay}
        />
      ) : (
        <EnableRow tokenColumn={tokenColumn} onClick={onEnable} />
      )}
    </>
  );
};

Repay.propTypes = {
  isEnabled: PropTypes.bool,
  onEnable: PropTypes.func,
  onRepay: PropTypes.func,
  disabled: PropTypes.bool,
  tokenIcon: PropTypes.string,
  borrowAPY: PropTypes.string,
  distributionAPY: PropTypes.string,
  borrowBalanceUsd: PropTypes.string,
  borrowLimitPercent: PropTypes.string,
  newBorrowBalanceUsd: PropTypes.string,
  newBorrowLimitPercent: PropTypes.string,
  isPaused: PropTypes.bool,
};

Repay.defaultProps = {
  isEnabled: false,
  disabled: false,
  onEnable: () => {},
  onRepay: () => {},
  tokenIcon: null,
  borrowAPY: '0',
  distributionAPY: '0',
  borrowBalanceUsd: '0',
  borrowLimitPercent: '0',
  newBorrowBalanceUsd: null,
  newBorrowLimitPercent: null,
  isPaused: false,
};

export default React.memo(Repay);
