import web3Service from '../../web3-service';
import MLT_ERC20_ABI from '../../../blockchain/abi/MLT_ERC20.json';
import config from '../../../config/env';

class MltTokenContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      MLT_ERC20_ABI,
      config.REACT_APP_MLT_ERC20_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  async allowance(address, contractAddress, options = {}, defaultBlock = 'latest') {
    this.contract.setProvider(window.ethereum);
    return this.contract.methods.allowance(address, contractAddress).call(options, defaultBlock);
  }

  async approve(address, contractAddress, amountApprove) {
    this.contract.setProvider(window.ethereum);
    const options = {
      from: address,
    };
    return this.contract.methods.approve(contractAddress, amountApprove).send(options);
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }
}

export default new MltTokenContractService();
