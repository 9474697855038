import web3Service from '../../../web3-service';
import CERC20_ABI from '../../../../blockchain/abi/lending/CERC20.json';

class ZenTokenService {
  constructor(address) {
    this._contract = new web3Service.eth.Contract(CERC20_ABI, address);
    this.address = address;
  }

  get contract() {
    return this._contract;
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }

  async getAccountSnapshot(account) {
    return this.contract.methods.getAccountSnapshot(account).call();
  }

  async exchangeRateStored() {
    return this.contract.methods.exchangeRateStored().call();
  }

  // method is used by eth.BatchRequest
  exchangeRateStoredRequest(cb) {
    return this._contract.methods.exchangeRateStored().call.request(cb);
  }

  async symbol() {
    return this.contract.methods.symbol().call();
  }

  async underlying() {
    return this.contract.methods.underlying().call();
  }

  async mint(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.mint(value).send(options);
  }

  async repay(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.repayBorrow(value).send(options);
  }

  async withdrawViaZenCall(address, val) {
    return this.contract.methods.redeem(val).call({ from: address });
  }

  async withdrawViaZen(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.redeem(value).send(options);
  }

  async withdraw(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.redeemUnderlying(value).send(options);
  }

  async borrow(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.borrow(value).send(options);
  }
}
export default ZenTokenService;
