import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { StakingPool } from './staking.types';

const MLT_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'MLT',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'MLT/BNB' as ContractType,
  stakingTokenAddress: '0x560b96F81a2190ff6AC84ebFd17788BAB3679CBC',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'MLT',
  rewardTokenAddress: '0x4518231a8fdf6ac553b9bbd51bbb86825b583263',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x398a5FEE22E0dEb67dA1bD15FA4841b6Aa64c471',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

const MLT_NAKED_POOL_CLOSED: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'MLT',
  stakingContractAddress: '0xF0185520Cc773502f0f208433ca178f2f57157A9',
  stakingTokenAddress: '0x4518231a8fdf6ac553b9bbd51bbb86825b583263',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const FACTOR_NAKED_POOL_CLOSED: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'FACTR',
  stakingContractAddress: '0x115a1FCc4AF9B0755c99526FD86a26f4a32f8cc6',
  stakingTokenAddress: '0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const ASVA_NAKED_POOL_CLOSED: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'ASVA',
  stakingContractAddress: '0x356dC904c2A60BBA56701935160a2a9729BF4e1e',
  stakingTokenAddress: '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const ROSN_NAKED_POOL_CLOSED: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'ROSN',
  stakingContractAddress: '0x7dd79e93dba1d677574d0b5e99721f2e4b45e297',
  stakingTokenAddress: '0x651Cd665bD558175A956fb3D72206eA08Eb3dF5b',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const BONDLY_NAKED_POOL_CLOSED: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'BONDLY',
  stakingContractAddress: '0x004c0908518e19aa8b27a55c171564097fa3c354',
  stakingTokenAddress: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const GAMER_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'GAMER',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'GAMER/BNB' as ContractType,
  stakingTokenAddress: '0x891322Bc97cC241F6C24FdCD777Eb7E6985eBeBC',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'GAMER',
  rewardTokenAddress: '0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xc85C6Fdf1c32Ec67D4B47Fb5B52A6aAFbA8eDD8b',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

const FACTR_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'WBNB',
  token1Symbol: 'FACTR',
  stakingTokenSymbol: 'FACTR/BNB' as ContractType,
  stakingTokenAddress: '0x790a02f9E8860968CA477b44EAF2f40BcbcacB8C',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'FACTR',
  rewardTokenAddress: '0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x8214A38c5938ECa5AAaB2EA46684f7C0E01ff16e',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const L3P_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'WBNB',
  token1Symbol: 'L3P',
  stakingTokenSymbol: 'L3P/BNB' as ContractType,
  stakingTokenAddress: '0xb62c57bda4c126e21a726e3d28734bfb1151231e',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'L3P',
  rewardTokenAddress: '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x3ba3E2f3cACcDbE3C56D3046FFe859cc9deE08a0',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const BNB_CBD_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'BNB_CBD',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'CBD/BNB' as ContractType,
  stakingTokenAddress: '0x0b49580278b403ca13055bf4d81b6b7aa85fd8b9',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'CBD',
  rewardTokenAddress: '0x0e2b41ea957624a314108cc4e33703e9d78f4b3c',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x92fCe8AfFB2A68d418BaDF8E360E0CDe06c39356',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

const ASVA_BUSD_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'BUSD',
  token1Symbol: 'ASVA',
  stakingTokenSymbol: 'ASVA/BUSD' as ContractType,
  stakingTokenAddress: '0x583C2a4226a3b9ABb87E658d1808926A73647768',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'ASVA',
  rewardTokenAddress: '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xb41f2d0a7aD958448EA8d6bC1D2b8b6f9801EAFD',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const FINE_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'BNB_FINE',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'FINE/BNB' as ContractType,
  stakingTokenAddress: '0xc309a6d2f1537922e06f15aa2eb21caa1b2eedb6',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'FINE',
  rewardTokenAddress: '0x4e6415a5727ea08aae4580057187923aec331227',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xF25897a7EDf1Dfa9C65f5DB7Ec4Bad868873805B',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  flipReserves: true,
  finished: true,
};
const BBANK_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'WBNB',
  token1Symbol: 'BNB_BBANK',
  stakingTokenSymbol: 'BBANK/BNB' as ContractType,
  stakingTokenAddress: '0x538e61bd3258304e9970f4f2db37a217f60436e1',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'FINE',
  rewardTokenAddress: '0xf4b5470523ccd314c6b9da041076e7d79e0df267',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x1E8BC897bf03ebac570Df7e5526561f8a42eCe05',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const BONDLY_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'BONDLY',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'BONDLY/BNB' as ContractType,
  stakingTokenAddress: '0xb8b4383B49d451BBeA63BC4421466E1086da6f18',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BONDLY',
  rewardTokenAddress: '0x96058f8c3e16576d9bd68766f3836d9a33158f89 ',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xD862866599CA681c492492E1B7B9aB80066f2FaC',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
  flipReserves: true,
};

const MIST_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'MIST',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'MIST/BNB' as ContractType,
  stakingTokenAddress: '0x5a26eB7C9c72140d01039Eb172Dcb8ec98D071bd',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'MIST',
  rewardTokenAddress: '0x68E374F856bF25468D365E539b700b648Bf94B67',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x4F905f75F5576228eD2D0EA508Fb0c32a0696090',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
  flipReserves: true,
};

const ROSN_BNB_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'ROSN',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'ROSN/BNB' as ContractType,
  stakingTokenAddress: '0x5548Bd47293171d3BC1621edccD953bcc9b814Cb',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'ROSN',
  rewardTokenAddress: '0x651cd665bd558175a956fb3d72206ea08eb3df5b',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x5B4463bBD7B2E870601e91161e0F1F7f84CDE214',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
  flipReserves: true,
};

export const BSC_CLOSED_POOLS = [
  MLT_NAKED_POOL_CLOSED,
  FACTOR_NAKED_POOL_CLOSED,
  ASVA_NAKED_POOL_CLOSED,
  ROSN_NAKED_POOL_CLOSED,
  BONDLY_NAKED_POOL_CLOSED,
  MLT_BNB_POOL_CLOSED,
  GAMER_BNB_POOL_CLOSED,
  FACTR_BNB_POOL_CLOSED,
  L3P_BNB_POOL_CLOSED,
  BNB_CBD_POOL_CLOSED,
  ASVA_BUSD_POOL_CLOSED,
  FINE_BNB_POOL_CLOSED,
  BBANK_BNB_POOL_CLOSED,
  BONDLY_BNB_POOL_CLOSED,
  MIST_BNB_POOL_CLOSED,
  ROSN_BNB_POOL_CLOSED,
];
