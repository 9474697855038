import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { CLOSED_POOLS_ETH } from './ethPoolsClosed';
import { StakingPool } from './staking.types';

const OM_POOL: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'OM',
  stakingContractAddress: '0xa01892d97e9c8290c2c225fb0b756bfe26bc9802',
  stakingTokenAddress: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
};

const OM_ETH_POOL: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'OM',
  token1Symbol: 'WETH',
  stakingTokenSymbol: 'OM/ETH' as ContractType,
  stakingTokenAddress: '0xe46935ae80e05cdebd4a4008b6ccaa36d2845370',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'OM',
  rewardTokenAddress: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d ',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x91FE14Df53Eae3A87E310ec6edcdD2D775E1A23f',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  flipReserves: true,
};

export const ETH_POOLS: StakingPool[] = [
  OM_POOL,
  OM_ETH_POOL,
  ...CLOSED_POOLS_ETH,
  // RFUEL_POOL_CLOSED,
];
