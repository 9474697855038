import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      fill='none'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        fill='#E6007A'
        fillRule='evenodd'
        d='M50 49.947H24.864l-12.37-12.414L25 25.002 50 49.947z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#E6007A'
        fillRule='evenodd'
        d='M49.948 0L25 25.003l-12.534-12.51L25 0h24.948z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#AB005B'
        fillRule='evenodd'
        d='M12.466 12.493l12.535 12.51-12.507 12.53L.056 25.055v-.189l12.41-12.373z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#E6007A'
        fillRule='evenodd'
        d='M12.494 37.533L.054 50V25.055l12.44 12.478zM12.466 12.493L.056 24.866v.19L0 25V.055l12.466 12.438z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
