import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      fill='none'
      viewBox='0 0 36 36'
      className={className}
      {...otherProps}
    >
      <path
        fill='url(#paint0_linear_cirus)'
        fillRule='evenodd'
        d='M.04 16.626c2.496 2.69 6.908 4.848 13.133 6.458l.571.145.581.142.59.138.259.06.508.12.62.153.605.154.473.124.462.125.452.127.441.127.431.129.421.129.207.065.405.13.297.1.292.099.473.166.092.034.454.168.437.17.17.067.334.137.162.07.318.137c1.77.786 3.039 1.61 3.801 2.458 1.114 1.24 1.09 2.558-.286 4.232a17.628 17.628 0 01-8.352 2.498c-.277-.672-.683-1.378-1.222-2.113a17.26 17.26 0 00-.944-1.168l-.179-.201-.236-.26-.247-.26-.256-.266c-.411-.418-.86-.848-1.344-1.29l-.245-.221-.322-.285-.331-.287-.342-.291-.351-.294-.18-.148-.366-.299-.377-.302-.485-.381-.5-.386-.412-.313-.421-.316-.432-.318-.554-.403-.57-.408-.466-.33-.477-.333-.487-.336-.75-.51-.512-.344-.522-.347-.532-.35-.68-.443-.696-.447-.855-.544-.583-.366-.893-.556-.5-.308a17.552 17.552 0 01-.1-2.926l.017-.312z'
        clipRule='evenodd'
      ></path>
      <path
        fill='url(#paint1_linear_cirus)'
        fillRule='evenodd'
        d='M1.018 23.412l.3.19.819.525.533.346.523.342.513.339.503.336.493.332.483.329.473.326.462.322.453.319.443.315.433.313.423.309.413.305.6.452.578.444.373.292.362.289.353.285.342.282.168.14.327.276.317.273.307.27.298.266.144.132.282.261.272.258.262.254c.114.113.226.224.334.334l.16.165.231.243c.491.525.911 1.025 1.26 1.5.128.177.246.347.353.512-6.842-.834-12.474-5.557-14.59-11.876z'
        clipRule='evenodd'
      ></path>
      <path
        fill='url(#paint2_linear_cirus)'
        fillRule='evenodd'
        d='M4.343 6.107c-.075 1.203.227 2.359.924 3.457 1.04 1.64 2.94 3.045 5.701 4.25l.209.089.364.152c.7.286 1.449.56 2.25.823l.346.112.435.136.22.067.45.133.228.066.463.13.235.064.238.063.482.126.244.063.496.122.506.121.633.148.549.132.537.134.527.136c.174.046.347.092.517.139l.506.14.495.142.485.144c6.877 2.083 10.528 4.8 11.208 8.187a17.657 17.657 0 01-3.138 4.476c-.098-1.062-.571-2.088-1.438-3.056-.754-.842-1.807-1.626-3.163-2.36l-.195-.104-.316-.162a22.36 22.36 0 00-.549-.268l-.285-.132-.351-.156-.18-.078-.182-.078-.371-.153-.19-.076-.387-.151a39.402 39.402 0 00-1.774-.63l-.315-.102-.448-.142-.459-.14-.469-.139-.722-.205-.495-.135-.633-.167-.648-.165-.265-.065-.535-.13-.924-.216-.5-.12-.492-.122-.483-.124-.238-.062-.47-.127-.461-.128-.452-.13a46.175 46.175 0 01-.223-.065l-.44-.132-.43-.134a44.82 44.82 0 01-.422-.136l-.413-.137-.404-.14a34.406 34.406 0 01-.2-.07l-.39-.142a36.394 36.394 0 01-.572-.215l-.37-.146a30.57 30.57 0 01-.714-.298l-.344-.151-.17-.076-.33-.154c-3.163-1.496-5.13-3.235-5.962-5.226a17.55 17.55 0 013.576-6.606l.088-.1z'
        clipRule='evenodd'
      ></path>
      <path
        fill='url(#paint3_linear_cirus)'
        fillRule='evenodd'
        d='M17.592 0c.166 0 .33.002.495.007-1.315 1.752-1.023 4.023 1.03 6.622.292.37.622.75.99 1.14l.186.195.247.251c.111.112.226.226.344.34l.18.171.276.26.288.262c.312.28.64.566.987.857l.263.218.338.276.349.279.358.28.369.285.378.287.39.29.602.439.414.296.64.45.44.303.224.153.455.308.232.154.47.312.48.315.492.317.5.32.512.323.522.326.532.329.542.331.552.335.562.337.573.34.583.343.593.346.308.177a17.383 17.383 0 01-.47 3.141c-1.67-2.467-4.788-4.538-9.285-6.202l-.44-.16a46.006 46.006 0 00-1.844-.614l-.482-.147-.491-.144-.5-.142a60.46 60.46 0 00-.253-.07l-.512-.138a64.533 64.533 0 00-.26-.068l-.524-.134a66.238 66.238 0 00-.803-.197l-1.037-.243-.48-.117-.471-.118-.46-.12-.451-.12-.221-.062-.435-.124-.214-.062-.42-.126-.206-.064-.405-.128-.198-.065-.39-.13-.19-.066-.375-.133-.183-.067-.36-.135-.175-.068-.173-.068-.339-.137-.165-.07-.324-.14a21.15 21.15 0 01-.69-.318l-.219-.108-.283-.145c-1.573-.824-2.657-1.709-3.25-2.638-.978-1.535-.614-3.401 1.325-5.722A17.759 17.759 0 0117.592 0z'
        clipRule='evenodd'
      ></path>
      <path
        fill='url(#paint4_linear_cirus)'
        fillRule='evenodd'
        d='M21.67.34c7.23 1.64 12.775 7.74 13.618 15.268l-.625-.375-.786-.478-.512-.315-.503-.313-.493-.31-.483-.306-.474-.304-.465-.3-.455-.299-.445-.295-.436-.293-.426-.29-.417-.286-.408-.284-.397-.281-.389-.278-.378-.276-.37-.272-.36-.27-.35-.266-.34-.264-.33-.26-.322-.258-.312-.255-.152-.127-.298-.25-.287-.248-.279-.245-.268-.241-.26-.24-.188-.176-.182-.176-.235-.231-.114-.115-.112-.114-.216-.225a16.96 16.96 0 01-.554-.616l-.126-.15-.173-.212-.169-.217c-1.127-1.48-1.392-2.54-1.026-3.27.214-.425.662-.835 1.353-1.211l.144-.077z'
        clipRule='evenodd'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_cirus'
          x1='0'
          x2='0'
          y1='16.626'
          y2='35.288'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF475A'></stop>
          <stop offset='1' stopColor='#FF9A89'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_cirus'
          x1='1.018'
          x2='1.018'
          y1='23.412'
          y2='35.288'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9A89'></stop>
          <stop offset='1' stopColor='#FF475A'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear_cirus'
          x1='0.679'
          x2='0.679'
          y1='6.107'
          y2='29.859'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9A89'></stop>
          <stop offset='1' stopColor='#FF475A'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear_cirus'
          x1='6.786'
          x2='6.786'
          y1='0'
          y2='21.715'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF475A'></stop>
          <stop offset='1' stopColor='#FF9A89'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear_cirus'
          x1='20.019'
          x2='20.019'
          y1='0.339'
          y2='15.608'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9A89'></stop>
          <stop offset='1' stopColor='#FF475A'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
