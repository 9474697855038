// Basic
export const DARK_MODE = 'DARK_MODE';
export const LIGHT_MODE = 'LIGHT_MODE';
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';
export const TOGGLE_MENU = 'TOGGLE_MENU';

// Wallets
export const CONNECT_METAMASK = 'CONNECT_METAMASK';

// Accounts
export const SET_AUTH = 'SET_AUTH';
export const SET_NETWORK_TYPE = 'SET_NETWORK_TYPE';
export const SET_NETWORK_ID = 'SET_NETWORK_ID';
export const LOGOUT = 'LOGOUT';

// Polkadot Accounts
export const POLKADOT_AUTH_MODE = 'POLKADOT_AUTH_MODE';
export const KUSAMA_AUTH_MODE = 'KUSAMA_AUTH_MODE';
export const SET_AUTH_POLKADOT = 'SET_AUTH_POLKADOT';
export const LOGOUT_POLKADOT = 'LOGOUT_POLKADOT';
export const SET_AUTH_KUSAMA = 'SET_AUTH_KUSAMA';
export const LOGOUT_KUSAMA = 'LOGOUT_KUSAMA';
export const GET_ALL_KUSAMA_ACCOUNTS = 'GET_ALL_KUSAMA_ACCOUNTS';

export const GET_ALL_POLKADOT_ACCOUNTS = 'GET_ALL_POLKADOT_ACCOUNTS';
export const POLKADOT_SET_STATUS = 'POLKADOT_SET_STATUS';
export const KUSAMA_SET_STATUS = 'KUSAMA_SET_STATUS';
export const SET_SUCCES_DATA = 'SET_SUCCES_DATA';
export const SET_REQUESTED_REWARDS = 'SET_REQUESTED_REWARDS';
export const SET_UNCLAIMED_AMOUNT = 'SET_UNCLAIMED_AMOUNT';

// Delegator rewards
export const CHECK_IS_NOMINATOR = 'CHECK_IS_NOMINATOR';
export const CHECK_IS_MAPPED = 'CHECK_IS_MAPPED';
export const CHECK_IS_WAIT_FOR_NOMINATE = 'CHECK_IS_WAIT_FOR_NOMINATE';
export const MAPPING_ETH_ADDRESS = 'MAPPING_ETH_ADDRESS';
export const SET_AMOUNT_BONDED = 'SET_AMOUNT_BONDED';

// Merkle tree airdrop

// MATIC

export const SET_AUTH_MATIC_MERKLE_AIRDROP = 'SET_AUTH_MATIC_MERKLE_AIRDROP';
export const LOGOUT_MATIC_MERKLE_AIRDROP = 'LOGOUT_MATIC_MERKLE_AIRDROP';
export const MATIC_MERKLE_AIRDROP_AUTH_MODE = 'MATIC_MERKLE_AIRDROP_AUTH_MODE';
export const GET_ALL_MATIC_MERKLE_AIRDROP_ACCOUNTS = 'GET_ALL_MATIC_MERKLE_AIRDROP_ACCOUNTS';
export const MATIC_MERKLE_AIRDROP_SET_STATUS = 'MATIC_MERKLE_AIRDROP_SET_STATUS';

// Staking display toggle
export const OM_STAKE_DISPLAY = 'OM_STAKE_DISPLAY';
export const RFUEL_STAKE_DISPLAY = 'RFUEL_STAKE_DISPLAY';
export const UNI_STAKE_DISPLAY = 'UNI_STAKE_DISPLAY';
export const DOT_NOMINATE_DISPLAY = 'DOT_NOMINATE_DISPLAY';
export const KSM_NOMINATE_DISPLAY = 'KSM_NOMINATE_DISPLAY';
export const SET_STAKE_TOTAL_STAKE_BALANCE_USD = 'SET_STAKE_TOTAL_STAKE_BALANCE_USD';

export const SET_STAKE_ACTIVE_ASSET = 'SET_STAKE_ACTIVE_ASSET';
export const SET_STAKE_ACTIVE_TAB = 'SET_STAKE_ACTIVE_TAB';

// OM STAKING
export const STAKE_SETTINGS = 'STAKE_SETTINGS';
export const STAKE_DETAILS = 'STAKE_DETAILS';
export const STAKE_SEND = 'STAKE_SEND';
export const STAKE_SUCCESS = 'STAKE_SUCCESS';
export const STAKE_ERROR = 'STAKE_ERROR';
export const RESET_STAKE = 'RESET_STAKE';
export const UNSTAKE_SETTINGS = 'UNSTAKE_SETTINGS';
export const UNSTAKE_INFORMATION = 'UNSTAKE_INFORMATION';
export const UNSTAKE_DETAILS = 'UNSTAKE_DETAILS';
export const UNSTAKE_SEND = 'UNSTAKE_SEND';
export const UNSTAKE_SUCCESS = 'UNSTAKE_SUCCESS';
export const STAKE_BALANCE = 'STAKE_BALANCE';
export const STAKE_TIME = 'STAKE_TIME';
export const UNSTAKED_BALANCE = 'UNSTAKED_BALANCE';
export const UNSTAKE_TIME = 'UNSTAKE_TIME';
export const REWARD_EARNED = 'REWARD_EARNED';
export const REWARD_BALANCE = 'REWARD_BALANCE';
export const GET_OM_BALANCE = 'GET_OM_BALANCE';
export const GET_OM_PRICE = 'GET_OM_PRICE';
export const WITHDRAW_DETAILS = 'WITHDRAW_DETAILS';
export const WITHDRAW_SEND = 'WITHDRAW_SEND';
export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS';
export const HISTORY_SHOW_OM_STAKING = 'HISTORY_SHOW_OM_STAKING';
export const MIGRATE_DETAILS = 'MIGRATE_DETAILS';
export const MIGRATE_SEND = 'MIGRATE_SEND';
export const MIGRATE_SUCCESS = 'MIGRATE_SUCCESS';
export const MIGRATE_TIMER = 'MIGRATE_TIMER';
export const MIGRATE_COMPLETED = 'MIGRATE_COMPLETED';
export const MIGRATE_CHANGE_STATUS = 'MIGRATE_CHANGE_STATUS';

// OM V2 STAKING
export const STAKE_SETTINGS_OM_V2 = 'STAKE_SETTINGS_OM_V2';
export const STAKE_DETAILS_OM_V2 = 'STAKE_DETAILS_OM_V2';
export const STAKE_SEND_OM_V2 = 'STAKE_SEND_OM_V2';
export const STAKE_SUCCESS_OM_V2 = 'STAKE_SUCCESS_OM_V2';
export const STAKE_ERROR_OM_V2 = 'STAKE_ERROR_OM_V2';
export const RESET_STAKE_OM_V2 = 'RESET_STAKE_OM_V2';
export const UNSTAKE_SETTINGS_OM_V2 = 'UNSTAKE_SETTINGS_OM_V2';
export const UNSTAKE_INFORMATION_OM_V2 = 'UNSTAKE_INFORMATION_OM_V2';
export const UNSTAKE_DETAILS_OM_V2 = 'UNSTAKE_DETAILS_OM_V2';
export const UNSTAKE_IMMEDIATELY_DETAILS_OM_V2 = 'UNSTAKE_IMMEDIATELY_DETAILS_OM_V2';
export const UNSTAKE_SEND_OM_V2 = 'UNSTAKE_SEND_OM_V2';
export const UNSTAKE_SUCCESS_OM_V2 = 'UNSTAKE_SUCCESS_OM_V2';
export const STAKE_BALANCE_OM_V2 = 'STAKE_BALANCE_OM_V2';
export const ESTIMATED_DAILY_REWARDS_OM_V2 = 'ESTIMATED_DAILY_REWARDS_OM_V2';
export const APR_OM_V2 = 'APR_OM_V2';
export const UNSTAKED_BALANCE_OM_V2 = 'UNSTAKED_BALANCE_OM_V2';
export const UNSTAKE_TIME_OM_V2 = 'UNSTAKE_TIME_OM_V2';
export const GET_OM_V2_BALANCE = 'GET_OM_V2_BALANCE';
export const GET_OM_V2_PRICE = 'GET_OM_V2_PRICE';
export const WITHDRAW_DETAILS_OM_V2 = 'WITHDRAW_DETAILS_OM_V2';
export const WITHDRAW_SEND_OM_V2 = 'WITHDRAW_SEND_OM_V2';
export const WITHDRAW_SUCCESS_OM_V2 = 'WITHDRAW_SUCCESS_OM_V2';
export const HISTORY_SHOW_OM_V2_STAKING = 'HISTORY_SHOW_OM_V2_STAKING';
export const EARNED_REWARDS_BALANCE_OM_V2 = 'REWARD_EARNED_BALANCE_OM_V2';
export const CLAIMING_FEE_PERCENT_OM_V2 = 'CLAIMING_FEE_PERCENT_OM_V2';
export const EARNED_REWARDS_STORED_DATA_OM_V2 = 'EARNED_REWARDS_STORED_DATA_OM_V2';

// OM V3 STAKING
export const STAKE_SETTINGS_OM_V3 = 'STAKE_SETTINGS_OM_V3';
export const STAKE_DETAILS_OM_V3 = 'STAKE_DETAILS_OM_V3';
export const STAKE_SEND_OM_V3 = 'STAKE_SEND_OM_V3';
export const STAKE_SUCCESS_OM_V3 = 'STAKE_SUCCESS_OM_V3';
export const STAKE_ERROR_OM_V3 = 'STAKE_ERROR_OM_V3';
export const RESET_STAKE_OM_V3 = 'RESET_STAKE_OM_V3';
export const UNSTAKE_SETTINGS_OM_V3 = 'UNSTAKE_SETTINGS_OM_V3';
export const UNSTAKE_INFORMATION_OM_V3 = 'UNSTAKE_INFORMATION_OM_V3';
export const UNSTAKE_DETAILS_OM_V3 = 'UNSTAKE_DETAILS_OM_V3';
export const UNSTAKE_IMMEDIATELY_DETAILS_OM_V3 = 'UNSTAKE_IMMEDIATELY_DETAILS_OM_V3';
export const UNSTAKE_SEND_OM_V3 = 'UNSTAKE_SEND_OM_V3';
export const UNSTAKE_SUCCESS_OM_V3 = 'UNSTAKE_SUCCESS_OM_V3';
export const STAKE_BALANCE_OM_V3 = 'STAKE_BALANCE_OM_V3';
export const ESTIMATED_DAILY_REWARDS_OM_V3 = 'ESTIMATED_DAILY_REWARDS_OM_V3';
export const APR_OM_V3 = 'APR_OM_V3';
export const UNSTAKED_BALANCE_OM_V3 = 'UNSTAKED_BALANCE_OM_V3';
export const UNSTAKE_TIME_OM_V3 = 'UNSTAKE_TIME_OM_V3';
export const GET_OM_V3_BALANCE = 'GET_OM_V3_BALANCE';
export const GET_OM_V3_PRICE = 'GET_OM_V3_PRICE';
export const WITHDRAW_DETAILS_OM_V3 = 'WITHDRAW_DETAILS_OM_V3';
export const WITHDRAW_SEND_OM_V3 = 'WITHDRAW_SEND_OM_V3';
export const WITHDRAW_SUCCESS_OM_V3 = 'WITHDRAW_SUCCESS_OM_V3';
export const HISTORY_SHOW_OM_V3_STAKING = 'HISTORY_SHOW_OM_V3_STAKING';
export const EARNED_REWARDS_BALANCE_OM_V3 = 'REWARD_EARNED_BALANCE_OM_V3';
export const CLAIMING_FEE_PERCENT_OM_V3 = 'CLAIMING_FEE_PERCENT_OM_V3';

// BNB FINE STAKING
export const STAKE_SETTINGS_BNB_FINE = 'STAKE_SETTINGS_BNB_FINE';
export const STAKE_DETAILS_BNB_FINE = 'STAKE_DETAILS_BNB_FINE';
export const STAKE_SEND_BNB_FINE = 'STAKE_SEND_BNB_FINE';
export const STAKE_SUCCESS_BNB_FINE = 'STAKE_SUCCESS_BNB_FINE';
export const STAKE_ERROR_BNB_FINE = 'STAKE_ERROR_BNB_FINE';
export const STAKE_BALANCE_BNB_FINE = 'STAKE_BALANCE_BNB_FINE';
export const RESET_STAKE_BNB_FINE = 'RESET_STAKE_BNB_FINE';
export const UNSTAKE_SETTINGS_BNB_FINE = 'UNSTAKE_SETTINGS_BNB_FINE';
export const UNSTAKE_INFORMATION_BNB_FINE = 'UNSTAKE_INFORMATION_BNB_FINE';
export const UNSTAKE_DETAILS_BNB_FINE = 'UNSTAKE_DETAILS_BNB_FINE';
export const UNSTAKE_IMMEDIATELY_DETAILS_BNB_FINE = 'UNSTAKE_IMMEDIATELY_DETAILS_BNB_FINE';
export const UNSTAKE_SEND_BNB_FINE = 'UNSTAKE_SEND_BNB_FINE';
export const UNSTAKE_SUCCESS_BNB_FINE = 'UNSTAKE_SUCCESS_BNB_FINE';
export const UNSTAKED_BALANCE_BNB_FINE = 'UNSTAKED_BALANCE_BNB_FINE';
export const UNSTAKE_TIME_BNB_FINE = 'UNSTAKE_TIME_BNB_FINE';
export const ESTIMATED_DAILY_REWARDS_BNB_FINE = 'ESTIMATED_DAILY_REWARDS_BNB_FINE';
export const APR_BNB_FINE = 'APR_BNB_FINE';
export const GET_BNB_FINE_BALANCE = 'GET_BNB_FINE_BALANCE';
export const GET_BNB_FINE_PRICE = 'GET_BNB_FINE_PRICE';
export const WITHDRAW_DETAILS_BNB_FINE = 'WITHDRAW_DETAILS_BNB_FINE';
export const WITHDRAW_SEND_BNB_FINE = 'WITHDRAW_SEND_BNB_FINE';
export const WITHDRAW_SUCCESS_BNB_FINE = 'WITHDRAW_SUCCESS_BNB_FINE';
export const HISTORY_SHOW_BNB_FINE_STAKING = 'HISTORY_SHOW_BNB_FINE_STAKING';
export const EARNED_REWARDS_BALANCE_BNB_FINE = 'REWARD_EARNED_BALANCE_BNB_FINE';
export const CLAIMING_FEE_PERCENT_BNB_FINE = 'CLAIMING_FEE_PERCENT_BNB_FINE';
export const CLAIM_REWARDS_DETAILS_BNB_FINE = 'CLAIM_REWARDS_DETAILS_BNB_FINE';
export const CLAIM_REWARDS_SEND_BNB_FINE = 'CLAIM_REWARDS_SEND_BNB_FINE';
export const CLAIM_REWARDS_SUCCESS_BNB_FINE = 'CLAIM_REWARDS_SUCCESS_BNB_FINE';
export const PERCENT_FROM_BNB_FINE_SWAP = 'PERCENT_FROM_BNB_FINE_SWAP';
export const PERCENT_IN_OM_BNB_FINE = 'PERCENT_IN_OM_BNB_FINE';
export const REWARD_BALANCE_BNB_FINE = 'REWARD_BALANCE_BNB_FINE';
export const REWARD_EARNED_BNB_FINE = 'REWARD_EARNED_BNB_FINE';
export const STAKE_INTERVALS_DATA_BNB_FINE = 'STAKE_INTERVALS_DATA_BNB_FINE';
export const STAKE_IS_AVAILABLE_BNB_FINE = 'STAKE_IS_AVAILABLE_BNB_FINE';

// BNB FINE STAKING
export const STAKE_SETTINGS_BNB_OM = 'STAKE_SETTINGS_BNB_OM';
export const STAKE_DETAILS_BNB_OM = 'STAKE_DETAILS_BNB_OM';
export const STAKE_SEND_BNB_OM = 'STAKE_SEND_BNB_OM';
export const STAKE_SUCCESS_BNB_OM = 'STAKE_SUCCESS_BNB_OM';
export const STAKE_ERROR_BNB_OM = 'STAKE_ERROR_BNB_OM';
export const STAKE_BALANCE_BNB_OM = 'STAKE_BALANCE_BNB_OM';
export const RESET_STAKE_BNB_OM = 'RESET_STAKE_BNB_OM';
export const UNSTAKE_SETTINGS_BNB_OM = 'UNSTAKE_SETTINGS_BNB_OM';
export const UNSTAKE_INFORMATION_BNB_OM = 'UNSTAKE_INFORMATION_BNB_OM';
export const UNSTAKE_DETAILS_BNB_OM = 'UNSTAKE_DETAILS_BNB_OM';
export const UNSTAKE_IMMEDIATELY_DETAILS_BNB_OM = 'UNSTAKE_IMMEDIATELY_DETAILS_BNB_OM';
export const UNSTAKE_SEND_BNB_OM = 'UNSTAKE_SEND_BNB_OM';
export const UNSTAKE_SUCCESS_BNB_OM = 'UNSTAKE_SUCCESS_BNB_OM';
export const UNSTAKED_BALANCE_BNB_OM = 'UNSTAKED_BALANCE_BNB_OM';
export const UNSTAKE_TIME_BNB_OM = 'UNSTAKE_TIME_BNB_OM';
export const ESTIMATED_DAILY_REWARDS_BNB_OM = 'ESTIMATED_DAILY_REWARDS_BNB_OM';
export const APR_BNB_OM = 'APR_BNB_OM';
export const GET_BNB_OM_BALANCE = 'GET_BNB_OM_BALANCE';
export const GET_BNB_OM_PRICE = 'GET_BNB_OM_PRICE';
export const WITHDRAW_DETAILS_BNB_OM = 'WITHDRAW_DETAILS_BNB_OM';
export const WITHDRAW_SEND_BNB_OM = 'WITHDRAW_SEND_BNB_OM';
export const WITHDRAW_SUCCESS_BNB_OM = 'WITHDRAW_SUCCESS_BNB_OM';
export const HISTORY_SHOW_BNB_OM_STAKING = 'HISTORY_SHOW_BNB_OM_STAKING';
export const EARNED_REWARDS_BALANCE_BNB_OM = 'REWARD_EARNED_BALANCE_BNB_OM';
export const CLAIMING_FEE_PERCENT_BNB_OM = 'CLAIMING_FEE_PERCENT_BNB_OM';
export const CLAIM_REWARDS_DETAILS_BNB_OM = 'CLAIM_REWARDS_DETAILS_BNB_OM';
export const CLAIM_REWARDS_SEND_BNB_OM = 'CLAIM_REWARDS_SEND_BNB_OM';
export const CLAIM_REWARDS_SUCCESS_BNB_OM = 'CLAIM_REWARDS_SUCCESS_BNB_OM';
export const PERCENT_FROM_BNB_OM_SWAP = 'PERCENT_FROM_BNB_OM_SWAP';
export const PERCENT_IN_OM_BNB_OM = 'PERCENT_IN_OM_BNB_OM';
export const REWARD_BALANCE_BNB_OM = 'REWARD_BALANCE_BNB_OM';
export const REWARD_EARNED_BNB_OM = 'REWARD_EARNED_BNB_OM';

// RAZE STAKING
export const STAKE_SETTINGS_RAZE = 'STAKE_SETTINGS_RAZE';
export const STAKE_DETAILS_RAZE = 'STAKE_DETAILS_RAZE';
export const STAKE_SEND_RAZE = 'STAKE_SEND_RAZE';
export const STAKE_SUCCESS_RAZE = 'STAKE_SUCCESS_RAZE';
export const STAKE_ERROR_RAZE = 'STAKE_ERROR_RAZE';
export const RESET_STAKE_RAZE = 'RESET_STAKE_RAZE';
export const UNSTAKE_SETTINGS_RAZE = 'UNSTAKE_SETTINGS_RAZE';
export const UNSTAKE_INFORMATION_RAZE = 'UNSTAKE_INFORMATION_RAZE';
export const UNSTAKE_DETAILS_RAZE = 'UNSTAKE_DETAILS_RAZE';
export const UNSTAKE_IMMEDIATELY_DETAILS_RAZE = 'UNSTAKE_IMMEDIATELY_DETAILS_RAZE';
export const UNSTAKE_SEND_RAZE = 'UNSTAKE_SEND_RAZE';
export const UNSTAKE_SUCCESS_RAZE = 'UNSTAKE_SUCCESS_RAZE';
export const STAKE_BALANCE_RAZE = 'STAKE_BALANCE_RAZE';
export const ESTIMATED_DAILY_REWARDS_RAZE = 'ESTIMATED_DAILY_REWARDS_RAZE';
export const APR_RAZE = 'APR_RAZE';
export const UNSTAKED_BALANCE_RAZE = 'UNSTAKED_BALANCE_RAZE';
export const UNSTAKE_TIME_RAZE = 'UNSTAKE_TIME_RAZE';
export const GET_RAZE_BALANCE = 'GET_RAZE_BALANCE';
export const GET_RAZE_PRICE = 'GET_RAZE_PRICE';
export const WITHDRAW_DETAILS_RAZE = 'WITHDRAW_DETAILS_RAZE';
export const WITHDRAW_SEND_RAZE = 'WITHDRAW_SEND_RAZE';
export const WITHDRAW_SUCCESS_RAZE = 'WITHDRAW_SUCCESS_RAZE';
export const HISTORY_SHOW_RAZE_STAKING = 'HISTORY_SHOW_RAZE_STAKING';
export const EARNED_REWARDS_BALANCE_RAZE = 'REWARD_EARNED_BALANCE_RAZE';
export const CLAIMING_FEE_PERCENT_RAZE = 'CLAIMING_FEE_PERCENT_RAZE';

// SRFUEL STAKING
export const STAKE_SETTINGS_SRFUEL = 'STAKE_SETTINGS_SRFUEL';
export const STAKE_DETAILS_SRFUEL = 'STAKE_DETAILS_SRFUEL';
export const STAKE_SEND_SRFUEL = 'STAKE_SEND_SRFUEL';
export const STAKE_SUCCESS_SRFUEL = 'STAKE_SUCCESS_SRFUEL';
export const STAKE_ERROR_SRFUEL = 'STAKE_ERROR_SRFUEL';
export const RESET_STAKE_SRFUEL = 'RESET_STAKE_SRFUEL';
export const UNSTAKE_SETTINGS_SRFUEL = 'UNSTAKE_SETTINGS_SRFUEL';
export const UNSTAKE_INFORMATION_SRFUEL = 'UNSTAKE_INFORMATION_SRFUEL';
export const UNSTAKE_DETAILS_SRFUEL = 'UNSTAKE_DETAILS_SRFUEL';
export const UNSTAKE_IMMEDIATELY_DETAILS_SRFUEL = 'UNSTAKE_IMMEDIATELY_DETAILS_SRFUEL';
export const UNSTAKE_SEND_SRFUEL = 'UNSTAKE_SEND_SRFUEL';
export const UNSTAKE_SUCCESS_SRFUEL = 'UNSTAKE_SUCCESS_SRFUEL';
export const STAKE_BALANCE_SRFUEL = 'STAKE_BALANCE_SRFUEL';
export const ESTIMATED_DAILY_REWARDS_SRFUEL = 'ESTIMATED_DAILY_REWARDS_SRFUEL';
export const APR_SRFUEL = 'APR_SRFUEL';
export const UNSTAKED_BALANCE_SRFUEL = 'UNSTAKED_BALANCE_SRFUEL';
export const UNSTAKE_TIME_SRFUEL = 'UNSTAKE_TIME_SRFUEL';
export const GET_SRFUEL_BALANCE = 'GET_SRFUEL_BALANCE';
export const GET_SRFUEL_PRICE = 'GET_SRFUEL_PRICE';
export const WITHDRAW_DETAILS_SRFUEL = 'WITHDRAW_DETAILS_SRFUEL';
export const WITHDRAW_SEND_SRFUEL = 'WITHDRAW_SEND_SRFUEL';
export const WITHDRAW_SUCCESS_SRFUEL = 'WITHDRAW_SUCCESS_SRFUEL';
export const HISTORY_SHOW_SRFUEL_STAKING = 'HISTORY_SHOW_SRFUEL_STAKING';
export const EARNED_REWARDS_BALANCE_SRFUEL = 'REWARD_EARNED_BALANCE_SRFUEL';
export const CLAIMING_FEE_PERCENT_SRFUEL = 'CLAIMING_FEE_PERCENT_SRFUEL';

// BITE staking
export const STAKE_SETTINGS_BITE = 'STAKE_SETTINGS_BITE';
export const STAKE_DETAILS_BITE = 'STAKE_DETAILS_BITE';
export const STAKE_SEND_BITE = 'STAKE_SEND_BITE';
export const STAKE_SUCCESS_BITE = 'STAKE_SUCCESS_BITE';
export const STAKE_ERROR_BITE = 'STAKE_ERROR_BITE';
export const RESET_STAKE_BITE = 'RESET_STAKE_BITE';
export const UNSTAKE_SETTINGS_BITE = 'UNSTAKE_SETTINGS_BITE';
export const UNSTAKE_INFORMATION_BITE = 'UNSTAKE_INFORMATION_BITE';
export const UNSTAKE_DETAILS_BITE = 'UNSTAKE_DETAILS_BITE';
export const UNSTAKE_IMMEDIATELY_DETAILS_BITE = 'UNSTAKE_IMMEDIATELY_DETAILS_BITE';
export const UNSTAKE_SEND_BITE = 'UNSTAKE_SEND_BITE';
export const UNSTAKE_SUCCESS_BITE = 'UNSTAKE_SUCCESS_BITE';
export const STAKE_BALANCE_BITE = 'STAKE_BALANCE_BITE';
export const ESTIMATED_DAILY_REWARDS_BITE = 'ESTIMATED_DAILY_REWARDS_BITE';
export const APR_BITE = 'APR_BITE';
export const UNSTAKED_BALANCE_BITE = 'UNSTAKED_BALANCE_BITE';
export const UNSTAKE_TIME_BITE = 'UNSTAKE_TIME_BITE';
export const GET_BITE_BALANCE = 'GET_BITE_BALANCE';
export const GET_BITE_PRICE = 'GET_BITE_PRICE';
export const WITHDRAW_DETAILS_BITE = 'WITHDRAW_DETAILS_BITE';
export const WITHDRAW_SEND_BITE = 'WITHDRAW_SEND_BITE';
export const WITHDRAW_SUCCESS_BITE = 'WITHDRAW_SUCCESS_BITE';
export const HISTORY_SHOW_BITE_STAKING = 'HISTORY_SHOW_BITE_STAKING';
export const EARNED_REWARDS_BALANCE_BITE = 'REWARD_EARNED_BALANCE_BITE';
export const CLAIMING_FEE_PERCENT_BITE = 'CLAIMING_FEE_PERCENT_BITE';

// CIRUS staking
export const STAKE_SETTINGS_CIRUS = 'STAKE_SETTINGS_CIRUS';
export const STAKE_DETAILS_CIRUS = 'STAKE_DETAILS_CIRUS';
export const STAKE_SEND_CIRUS = 'STAKE_SEND_CIRUS';
export const STAKE_SUCCESS_CIRUS = 'STAKE_SUCCESS_CIRUS';
export const STAKE_ERROR_CIRUS = 'STAKE_ERROR_CIRUS';
export const RESET_STAKE_CIRUS = 'RESET_STAKE_CIRUS';
export const UNSTAKE_SETTINGS_CIRUS = 'UNSTAKE_SETTINGS_CIRUS';
export const UNSTAKE_INFORMATION_CIRUS = 'UNSTAKE_INFORMATION_CIRUS';
export const UNSTAKE_DETAILS_CIRUS = 'UNSTAKE_DETAILS_CIRUS';
export const UNSTAKE_IMMEDIATELY_DETAILS_CIRUS = 'UNSTAKE_IMMEDIATELY_DETAILS_CIRUS';
export const UNSTAKE_SEND_CIRUS = 'UNSTAKE_SEND_CIRUS';
export const UNSTAKE_SUCCESS_CIRUS = 'UNSTAKE_SUCCESS_CIRUS';
export const STAKE_BALANCE_CIRUS = 'STAKE_BALANCE_CIRUS';
export const ESTIMATED_DAILY_REWARDS_CIRUS = 'ESTIMATED_DAILY_REWARDS_CIRUS';
export const APR_CIRUS = 'APR_CIRUS';
export const UNSTAKED_BALANCE_CIRUS = 'UNSTAKED_BALANCE_CIRUS';
export const UNSTAKE_TIME_CIRUS = 'UNSTAKE_TIME_CIRUS';
export const GET_CIRUS_BALANCE = 'GET_CIRUS_BALANCE';
export const GET_CIRUS_PRICE = 'GET_CIRUS_PRICE';
export const WITHDRAW_DETAILS_CIRUS = 'WITHDRAW_DETAILS_CIRUS';
export const WITHDRAW_SEND_CIRUS = 'WITHDRAW_SEND_CIRUS';
export const WITHDRAW_SUCCESS_CIRUS = 'WITHDRAW_SUCCESS_CIRUS';
export const HISTORY_SHOW_CIRUS_STAKING = 'HISTORY_SHOW_CIRUS_STAKING';
export const EARNED_REWARDS_BALANCE_CIRUS = 'REWARD_EARNED_BALANCE_CIRUS';
export const CLAIMING_FEE_PERCENT_CIRUS = 'CLAIMING_FEE_PERCENT_CIRUS';

// SKYRIM staking
export const STAKE_SETTINGS_SKYRIM = 'STAKE_SETTINGS_SKYRIM';
export const STAKE_DETAILS_SKYRIM = 'STAKE_DETAILS_SKYRIM';
export const STAKE_SEND_SKYRIM = 'STAKE_SEND_SKYRIM';
export const STAKE_SUCCESS_SKYRIM = 'STAKE_SUCCESS_SKYRIM';
export const STAKE_ERROR_SKYRIM = 'STAKE_ERROR_SKYRIM';
export const RESET_STAKE_SKYRIM = 'RESET_STAKE_SKYRIM';
export const UNSTAKE_SETTINGS_SKYRIM = 'UNSTAKE_SETTINGS_SKYRIM';
export const UNSTAKE_INFORMATION_SKYRIM = 'UNSTAKE_INFORMATION_SKYRIM';
export const UNSTAKE_DETAILS_SKYRIM = 'UNSTAKE_DETAILS_SKYRIM';
export const UNSTAKE_IMMEDIATELY_DETAILS_SKYRIM = 'UNSTAKE_IMMEDIATELY_DETAILS_SKYRIM';
export const UNSTAKE_SEND_SKYRIM = 'UNSTAKE_SEND_SKYRIM';
export const UNSTAKE_SUCCESS_SKYRIM = 'UNSTAKE_SUCCESS_SKYRIM';
export const STAKE_BALANCE_SKYRIM = 'STAKE_BALANCE_SKYRIM';
export const ESTIMATED_DAILY_REWARDS_SKYRIM = 'ESTIMATED_DAILY_REWARDS_SKYRIM';
export const APR_SKYRIM = 'APR_SKYRIM';
export const UNSTAKED_BALANCE_SKYRIM = 'UNSTAKED_BALANCE_SKYRIM';
export const UNSTAKE_TIME_SKYRIM = 'UNSTAKE_TIME_SKYRIM';
export const GET_SKYRIM_BALANCE = 'GET_SKYRIM_BALANCE';
export const GET_SKYRIM_PRICE = 'GET_SKYRIM_PRICE';
export const WITHDRAW_DETAILS_SKYRIM = 'WITHDRAW_DETAILS_SKYRIM';
export const WITHDRAW_SEND_SKYRIM = 'WITHDRAW_SEND_SKYRIM';
export const WITHDRAW_SUCCESS_SKYRIM = 'WITHDRAW_SUCCESS_SKYRIM';
export const HISTORY_SHOW_SKYRIM_STAKING = 'HISTORY_SHOW_SKYRIM_STAKING';
export const EARNED_REWARDS_BALANCE_SKYRIM = 'REWARD_EARNED_BALANCE_SKYRIM';
export const CLAIMING_FEE_PERCENT_SKYRIM = 'CLAIMING_FEE_PERCENT_SKYRIM';

// BCUBE staking
export const STAKE_SETTINGS_BCUBE = 'STAKE_SETTINGS_BCUBE';
export const STAKE_DETAILS_BCUBE = 'STAKE_DETAILS_BCUBE';
export const STAKE_SEND_BCUBE = 'STAKE_SEND_BCUBE';
export const STAKE_SUCCESS_BCUBE = 'STAKE_SUCCESS_BCUBE';
export const STAKE_ERROR_BCUBE = 'STAKE_ERROR_BCUBE';
export const RESET_STAKE_BCUBE = 'RESET_STAKE_BCUBE';
export const UNSTAKE_SETTINGS_BCUBE = 'UNSTAKE_SETTINGS_BCUBE';
export const UNSTAKE_INFORMATION_BCUBE = 'UNSTAKE_INFORMATION_BCUBE';
export const UNSTAKE_DETAILS_BCUBE = 'UNSTAKE_DETAILS_BCUBE';
export const UNSTAKE_IMMEDIATELY_DETAILS_BCUBE = 'UNSTAKE_IMMEDIATELY_DETAILS_BCUBE';
export const UNSTAKE_SEND_BCUBE = 'UNSTAKE_SEND_BCUBE';
export const UNSTAKE_SUCCESS_BCUBE = 'UNSTAKE_SUCCESS_BCUBE';
export const STAKE_BALANCE_BCUBE = 'STAKE_BALANCE_BCUBE';
export const ESTIMATED_DAILY_REWARDS_BCUBE = 'ESTIMATED_DAILY_REWARDS_BCUBE';
export const APR_BCUBE = 'APR_BCUBE';
export const UNSTAKED_BALANCE_BCUBE = 'UNSTAKED_BALANCE_BCUBE';
export const UNSTAKE_TIME_BCUBE = 'UNSTAKE_TIME_BCUBE';
export const GET_BCUBE_BALANCE = 'GET_BCUBE_BALANCE';
export const GET_BCUBE_PRICE = 'GET_BCUBE_PRICE';
export const WITHDRAW_DETAILS_BCUBE = 'WITHDRAW_DETAILS_BCUBE';
export const WITHDRAW_SEND_BCUBE = 'WITHDRAW_SEND_BCUBE';
export const WITHDRAW_SUCCESS_BCUBE = 'WITHDRAW_SUCCESS_BCUBE';
export const HISTORY_SHOW_BCUBE_STAKING = 'HISTORY_SHOW_BCUBE_STAKING';
export const EARNED_REWARDS_BALANCE_BCUBE = 'REWARD_EARNED_BALANCE_BCUBE';
export const CLAIMING_FEE_PERCENT_BCUBE = 'CLAIMING_FEE_PERCENT_BCUBE';

// SPWN staking
export const STAKE_SETTINGS_SPWN = 'STAKE_SETTINGS_SPWN';
export const STAKE_DETAILS_SPWN = 'STAKE_DETAILS_SPWN';
export const STAKE_SEND_SPWN = 'STAKE_SEND_SPWN';
export const STAKE_SUCCESS_SPWN = 'STAKE_SUCCESS_SPWN';
export const STAKE_ERROR_SPWN = 'STAKE_ERROR_SPWN';
export const RESET_STAKE_SPWN = 'RESET_STAKE_SPWN';
export const UNSTAKE_SETTINGS_SPWN = 'UNSTAKE_SETTINGS_SPWN';
export const UNSTAKE_INFORMATION_SPWN = 'UNSTAKE_INFORMATION_SPWN';
export const UNSTAKE_DETAILS_SPWN = 'UNSTAKE_DETAILS_SPWN';
export const UNSTAKE_IMMEDIATELY_DETAILS_SPWN = 'UNSTAKE_IMMEDIATELY_DETAILS_SPWN';
export const UNSTAKE_SEND_SPWN = 'UNSTAKE_SEND_SPWN';
export const UNSTAKE_SUCCESS_SPWN = 'UNSTAKE_SUCCESS_SPWN';
export const STAKE_BALANCE_SPWN = 'STAKE_BALANCE_SPWN';
export const ESTIMATED_DAILY_REWARDS_SPWN = 'ESTIMATED_DAILY_REWARDS_SPWN';
export const APR_SPWN = 'APR_SPWN';
export const UNSTAKED_BALANCE_SPWN = 'UNSTAKED_BALANCE_SPWN';
export const UNSTAKE_TIME_SPWN = 'UNSTAKE_TIME_SPWN';
export const GET_SPWN_BALANCE = 'GET_SPWN_BALANCE';
export const GET_SPWN_PRICE = 'GET_SPWN_PRICE';
export const WITHDRAW_DETAILS_SPWN = 'WITHDRAW_DETAILS_SPWN';
export const WITHDRAW_SEND_SPWN = 'WITHDRAW_SEND_SPWN';
export const WITHDRAW_SUCCESS_SPWN = 'WITHDRAW_SUCCESS_SPWN';
export const HISTORY_SHOW_SPWN_STAKING = 'HISTORY_SHOW_SPWN_STAKING';
export const EARNED_REWARDS_BALANCE_SPWN = 'REWARD_EARNED_BALANCE_SPWN';
export const CLAIMING_FEE_PERCENT_SPWN = 'CLAIMING_FEE_PERCENT_SPWN';

// LABS_GROUP staking
export const STAKE_SETTINGS_LABS_GROUP = 'STAKE_SETTINGS_LABS_GROUP';
export const STAKE_DETAILS_LABS_GROUP = 'STAKE_DETAILS_LABS_GROUP';
export const STAKE_SEND_LABS_GROUP = 'STAKE_SEND_LABS_GROUP';
export const STAKE_SUCCESS_LABS_GROUP = 'STAKE_SUCCESS_LABS_GROUP';
export const STAKE_ERROR_LABS_GROUP = 'STAKE_ERROR_LABS_GROUP';
export const RESET_STAKE_LABS_GROUP = 'RESET_STAKE_LABS_GROUP';
export const UNSTAKE_SETTINGS_LABS_GROUP = 'UNSTAKE_SETTINGS_LABS_GROUP';
export const UNSTAKE_INFORMATION_LABS_GROUP = 'UNSTAKE_INFORMATION_LABS_GROUP';
export const UNSTAKE_DETAILS_LABS_GROUP = 'UNSTAKE_DETAILS_LABS_GROUP';
export const UNSTAKE_IMMEDIATELY_DETAILS_LABS_GROUP = 'UNSTAKE_IMMEDIATELY_DETAILS_LABS_GROUP';
export const UNSTAKE_SEND_LABS_GROUP = 'UNSTAKE_SEND_LABS_GROUP';
export const UNSTAKE_SUCCESS_LABS_GROUP = 'UNSTAKE_SUCCESS_LABS_GROUP';
export const STAKE_BALANCE_LABS_GROUP = 'STAKE_BALANCE_LABS_GROUP';
export const ESTIMATED_DAILY_REWARDS_LABS_GROUP = 'ESTIMATED_DAILY_REWARDS_LABS_GROUP';
export const APR_LABS_GROUP = 'APR_LABS_GROUP';
export const UNSTAKED_BALANCE_LABS_GROUP = 'UNSTAKED_BALANCE_LABS_GROUP';
export const UNSTAKE_TIME_LABS_GROUP = 'UNSTAKE_TIME_LABS_GROUP';
export const GET_LABS_GROUP_BALANCE = 'GET_LABS_GROUP_BALANCE';
export const GET_LABS_GROUP_PRICE = 'GET_LABS_GROUP_PRICE';
export const WITHDRAW_DETAILS_LABS_GROUP = 'WITHDRAW_DETAILS_LABS_GROUP';
export const WITHDRAW_SEND_LABS_GROUP = 'WITHDRAW_SEND_LABS_GROUP';
export const WITHDRAW_SUCCESS_LABS_GROUP = 'WITHDRAW_SUCCESS_LABS_GROUP';
export const HISTORY_SHOW_LABS_GROUP_STAKING = 'HISTORY_SHOW_LABS_GROUP_STAKING';
export const EARNED_REWARDS_BALANCE_LABS_GROUP = 'REWARD_EARNED_BALANCE_LABS_GROUP';
export const CLAIMING_FEE_PERCENT_LABS_GROUP = 'CLAIMING_FEE_PERCENT_LABS_GROUP';

// ALPHA staking
export const STAKE_SETTINGS_ALPHA = 'STAKE_SETTINGS_ALPHA';
export const STAKE_DETAILS_ALPHA = 'STAKE_DETAILS_ALPHA';
export const STAKE_SEND_ALPHA = 'STAKE_SEND_ALPHA';
export const STAKE_SUCCESS_ALPHA = 'STAKE_SUCCESS_ALPHA';
export const STAKE_ERROR_ALPHA = 'STAKE_ERROR_ALPHA';
export const RESET_STAKE_ALPHA = 'RESET_STAKE_ALPHA';
export const UNSTAKE_SETTINGS_ALPHA = 'UNSTAKE_SETTINGS_ALPHA';
export const UNSTAKE_INFORMATION_ALPHA = 'UNSTAKE_INFORMATION_ALPHA';
export const UNSTAKE_DETAILS_ALPHA = 'UNSTAKE_DETAILS_ALPHA';
export const UNSTAKE_IMMEDIATELY_DETAILS_ALPHA = 'UNSTAKE_IMMEDIATELY_DETAILS_ALPHA';
export const UNSTAKE_SEND_ALPHA = 'UNSTAKE_SEND_ALPHA';
export const UNSTAKE_SUCCESS_ALPHA = 'UNSTAKE_SUCCESS_ALPHA';
export const STAKE_BALANCE_ALPHA = 'STAKE_BALANCE_ALPHA';
export const ESTIMATED_DAILY_REWARDS_ALPHA = 'ESTIMATED_DAILY_REWARDS_ALPHA';
export const APR_ALPHA = 'APR_ALPHA';
export const UNSTAKED_BALANCE_ALPHA = 'UNSTAKED_BALANCE_ALPHA';
export const UNSTAKE_TIME_ALPHA = 'UNSTAKE_TIME_ALPHA';
export const GET_ALPHA_BALANCE = 'GET_ALPHA_BALANCE';
export const GET_ALPHA_PRICE = 'GET_ALPHA_PRICE';
export const WITHDRAW_DETAILS_ALPHA = 'WITHDRAW_DETAILS_ALPHA';
export const WITHDRAW_SEND_ALPHA = 'WITHDRAW_SEND_ALPHA';
export const WITHDRAW_SUCCESS_ALPHA = 'WITHDRAW_SUCCESS_ALPHA';
export const HISTORY_SHOW_ALPHA_STAKING = 'HISTORY_SHOW_ALPHA_STAKING';
export const EARNED_REWARDS_BALANCE_ALPHA = 'REWARD_EARNED_BALANCE_ALPHA';
export const CLAIMING_FEE_PERCENT_ALPHA = 'CLAIMING_FEE_PERCENT_ALPHA';

// BONDLY ETH STAKING
export const STAKE_SETTINGS_BONDLY_ETH = 'STAKE_SETTINGS_BONDLY_ETH';
export const STAKE_DETAILS_BONDLY_ETH = 'STAKE_DETAILS_BONDLY_ETH';
export const STAKE_SEND_BONDLY_ETH = 'STAKE_SEND_BONDLY_ETH';
export const STAKE_SUCCESS_BONDLY_ETH = 'STAKE_SUCCESS_BONDLY_ETH';
export const STAKE_ERROR_BONDLY_ETH = 'STAKE_ERROR_BONDLY_ETH';
export const RESET_STAKE_BONDLY_ETH = 'RESET_STAKE_BONDLY_ETH';
export const UNSTAKE_SETTINGS_BONDLY_ETH = 'UNSTAKE_SETTINGS_BONDLY_ETH';
export const UNSTAKE_INFORMATION_BONDLY_ETH = 'UNSTAKE_INFORMATION_BONDLY_ETH';
export const UNSTAKE_DETAILS_BONDLY_ETH = 'UNSTAKE_DETAILS_BONDLY_ETH';
export const UNSTAKE_IMMEDIATELY_DETAILS_BONDLY_ETH = 'UNSTAKE_IMMEDIATELY_DETAILS_BONDLY_ETH';
export const UNSTAKE_SEND_BONDLY_ETH = 'UNSTAKE_SEND_BONDLY_ETH';
export const UNSTAKE_SUCCESS_BONDLY_ETH = 'UNSTAKE_SUCCESS_BONDLY_ETH';
export const STAKE_BALANCE_BONDLY_ETH = 'STAKE_BALANCE_BONDLY_ETH';
export const ESTIMATED_DAILY_REWARDS_BONDLY_ETH = 'ESTIMATED_DAILY_REWARDS_BONDLY_ETH';
export const APR_BONDLY_ETH = 'APR_BONDLY_ETH';
export const UNSTAKED_BALANCE_BONDLY_ETH = 'UNSTAKED_BALANCE_BONDLY_ETH';
export const UNSTAKE_TIME_BONDLY_ETH = 'UNSTAKE_TIME_BONDLY_ETH';
export const GET_BONDLY_ETH_BALANCE = 'GET_BONDLY_ETH_BALANCE';
export const GET_BONDLY_ETH_PRICE = 'GET_BONDLY_ETH_PRICE';
export const WITHDRAW_DETAILS_BONDLY_ETH = 'WITHDRAW_DETAILS_BONDLY_ETH';
export const WITHDRAW_SEND_BONDLY_ETH = 'WITHDRAW_SEND_BONDLY_ETH';
export const WITHDRAW_SUCCESS_BONDLY_ETH = 'WITHDRAW_SUCCESS_BONDLY_ETH';
export const HISTORY_SHOW_BONDLY_ETH_STAKING = 'HISTORY_SHOW_BONDLY_ETH_STAKING';
export const EARNED_REWARDS_BALANCE_BONDLY_ETH = 'REWARD_EARNED_BALANCE_BONDLY_ETH';
export const CLAIMING_FEE_PERCENT_BONDLY_ETH = 'CLAIMING_FEE_PERCENT_BONDLY_ETH';

// ROSN STAKING
export const STAKE_SETTINGS_ROSN = 'STAKE_SETTINGS_ROSN';
export const STAKE_DETAILS_ROSN = 'STAKE_DETAILS_ROSN';
export const STAKE_SEND_ROSN = 'STAKE_SEND_ROSN';
export const STAKE_SUCCESS_ROSN = 'STAKE_SUCCESS_ROSN';
export const STAKE_ERROR_ROSN = 'STAKE_ERROR_ROSN';
export const RESET_STAKE_ROSN = 'RESET_STAKE_ROSN';
export const UNSTAKE_SETTINGS_ROSN = 'UNSTAKE_SETTINGS_ROSN';
export const UNSTAKE_INFORMATION_ROSN = 'UNSTAKE_INFORMATION_ROSN';
export const UNSTAKE_DETAILS_ROSN = 'UNSTAKE_DETAILS_ROSN';
export const UNSTAKE_IMMEDIATELY_DETAILS_ROSN = 'UNSTAKE_IMMEDIATELY_DETAILS_ROSN';
export const UNSTAKE_SEND_ROSN = 'UNSTAKE_SEND_ROSN';
export const UNSTAKE_SUCCESS_ROSN = 'UNSTAKE_SUCCESS_ROSN';
export const STAKE_BALANCE_ROSN = 'STAKE_BALANCE_ROSN';
export const ESTIMATED_DAILY_REWARDS_ROSN = 'ESTIMATED_DAILY_REWARDS_ROSN';
export const APR_ROSN = 'APR_ROSN';
export const UNSTAKED_BALANCE_ROSN = 'UNSTAKED_BALANCE_ROSN';
export const UNSTAKE_TIME_ROSN = 'UNSTAKE_TIME_ROSN';
export const GET_ROSN_BALANCE = 'GET_ROSN_BALANCE';
export const GET_ROSN_PRICE = 'GET_ROSN_PRICE';
export const WITHDRAW_DETAILS_ROSN = 'WITHDRAW_DETAILS_ROSN';
export const WITHDRAW_SEND_ROSN = 'WITHDRAW_SEND_ROSN';
export const WITHDRAW_SUCCESS_ROSN = 'WITHDRAW_SUCCESS_ROSN';
export const HISTORY_SHOW_ROSN_STAKING = 'HISTORY_SHOW_ROSN_STAKING';
export const EARNED_REWARDS_BALANCE_ROSN = 'REWARD_EARNED_BALANCE_ROSN';
export const CLAIMING_FEE_PERCENT_ROSN = 'CLAIMING_FEE_PERCENT_ROSN';

// MLT STAKING
export const STAKE_SETTINGS_MLT = 'STAKE_SETTINGS_MLT';
export const STAKE_DETAILS_MLT = 'STAKE_DETAILS_MLT';
export const STAKE_SEND_MLT = 'STAKE_SEND_MLT';
export const STAKE_SUCCESS_MLT = 'STAKE_SUCCESS_MLT';
export const STAKE_ERROR_MLT = 'STAKE_ERROR_MLT';
export const RESET_STAKE_MLT = 'RESET_STAKE_MLT';
export const UNSTAKE_SETTINGS_MLT = 'UNSTAKE_SETTINGS_MLT';
export const UNSTAKE_INFORMATION_MLT = 'UNSTAKE_INFORMATION_MLT';
export const UNSTAKE_DETAILS_MLT = 'UNSTAKE_DETAILS_MLT';
export const UNSTAKE_IMMEDIATELY_DETAILS_MLT = 'UNSTAKE_IMMEDIATELY_DETAILS_MLT';
export const UNSTAKE_SEND_MLT = 'UNSTAKE_SEND_MLT';
export const UNSTAKE_SUCCESS_MLT = 'UNSTAKE_SUCCESS_MLT';
export const STAKE_BALANCE_MLT = 'STAKE_BALANCE_MLT';
export const ESTIMATED_DAILY_REWARDS_MLT = 'ESTIMATED_DAILY_REWARDS_MLT';
export const APR_MLT = 'APR_MLT';
export const UNSTAKED_BALANCE_MLT = 'UNSTAKED_BALANCE_MLT';
export const UNSTAKE_TIME_MLT = 'UNSTAKE_TIME_MLT';
export const GET_MLT_BALANCE = 'GET_MLT_BALANCE';
export const GET_MLT_PRICE = 'GET_MLT_PRICE';
export const WITHDRAW_DETAILS_MLT = 'WITHDRAW_DETAILS_MLT';
export const WITHDRAW_SEND_MLT = 'WITHDRAW_SEND_MLT';
export const WITHDRAW_SUCCESS_MLT = 'WITHDRAW_SUCCESS_MLT';
export const HISTORY_SHOW_MLT_STAKING = 'HISTORY_SHOW_MLT_STAKING';
export const EARNED_REWARDS_BALANCE_MLT = 'REWARD_EARNED_BALANCE_MLT';
export const CLAIMING_FEE_PERCENT_MLT = 'CLAIMING_FEE_PERCENT_MLT';

// OM_BSC STAKING
export const STAKE_SETTINGS_OM_BSC = 'STAKE_SETTINGS_OM_BSC';
export const STAKE_DETAILS_OM_BSC = 'STAKE_DETAILS_OM_BSC';
export const STAKE_SEND_OM_BSC = 'STAKE_SEND_OM_BSC';
export const STAKE_SUCCESS_OM_BSC = 'STAKE_SUCCESS_OM_BSC';
export const STAKE_ERROR_OM_BSC = 'STAKE_ERROR_OM_BSC';
export const RESET_STAKE_OM_BSC = 'RESET_STAKE_OM_BSC';
export const UNSTAKE_SETTINGS_OM_BSC = 'UNSTAKE_SETTINGS_OM_BSC';
export const UNSTAKE_INFORMATION_OM_BSC = 'UNSTAKE_INFORMATION_OM_BSC';
export const UNSTAKE_DETAILS_OM_BSC = 'UNSTAKE_DETAILS_OM_BSC';
export const UNSTAKE_IMMEDIATELY_DETAILS_OM_BSC = 'UNSTAKE_IMMEDIATELY_DETAILS_OM_BSC';
export const UNSTAKE_SEND_OM_BSC = 'UNSTAKE_SEND_OM_BSC';
export const UNSTAKE_SUCCESS_OM_BSC = 'UNSTAKE_SUCCESS_OM_BSC';
export const STAKE_BALANCE_OM_BSC = 'STAKE_BALANCE_OM_BSC';
export const ESTIMATED_DAILY_REWARDS_OM_BSC = 'ESTIMATED_DAILY_REWARDS_OM_BSC';
export const APR_OM_BSC = 'APR_OM_BSC';
export const UNSTAKED_BALANCE_OM_BSC = 'UNSTAKED_BALANCE_OM_BSC';
export const UNSTAKE_TIME_OM_BSC = 'UNSTAKE_TIME_OM_BSC';
export const GET_OM_BSC_BALANCE = 'GET_OM_BSC_BALANCE';
export const GET_OM_BSC_PRICE = 'GET_OM_BSC_PRICE';
export const WITHDRAW_DETAILS_OM_BSC = 'WITHDRAW_DETAILS_OM_BSC';
export const WITHDRAW_SEND_OM_BSC = 'WITHDRAW_SEND_OM_BSC';
export const WITHDRAW_SUCCESS_OM_BSC = 'WITHDRAW_SUCCESS_OM_BSC';
export const HISTORY_SHOW_OM_BSC_STAKING = 'HISTORY_SHOW_OM_BSC_STAKING';
export const EARNED_REWARDS_BALANCE_OM_BSC = 'REWARD_EARNED_BALANCE_OM_BSC';
export const CLAIMING_FEE_PERCENT_OM_BSC = 'CLAIMING_FEE_PERCENT_OM_BSC';

// OM_POLYGON STAKING
export const STAKE_SETTINGS_OM_POLYGON = 'STAKE_SETTINGS_OM_POLYGON';
export const STAKE_DETAILS_OM_POLYGON = 'STAKE_DETAILS_OM_POLYGON';
export const STAKE_SEND_OM_POLYGON = 'STAKE_SEND_OM_POLYGON';
export const STAKE_SUCCESS_OM_POLYGON = 'STAKE_SUCCESS_OM_POLYGON';
export const STAKE_ERROR_OM_POLYGON = 'STAKE_ERROR_OM_POLYGON';
export const RESET_STAKE_OM_POLYGON = 'RESET_STAKE_OM_POLYGON';
export const UNSTAKE_SETTINGS_OM_POLYGON = 'UNSTAKE_SETTINGS_OM_POLYGON';
export const UNSTAKE_INFORMATION_OM_POLYGON = 'UNSTAKE_INFORMATION_OM_POLYGON';
export const UNSTAKE_DETAILS_OM_POLYGON = 'UNSTAKE_DETAILS_OM_POLYGON';
export const UNSTAKE_IMMEDIATELY_DETAILS_OM_POLYGON = 'UNSTAKE_IMMEDIATELY_DETAILS_OM_POLYGON';
export const UNSTAKE_SEND_OM_POLYGON = 'UNSTAKE_SEND_OM_POLYGON';
export const UNSTAKE_SUCCESS_OM_POLYGON = 'UNSTAKE_SUCCESS_OM_POLYGON';
export const STAKE_BALANCE_OM_POLYGON = 'STAKE_BALANCE_OM_POLYGON';
export const ESTIMATED_DAILY_REWARDS_OM_POLYGON = 'ESTIMATED_DAILY_REWARDS_OM_POLYGON';
export const APR_OM_POLYGON = 'APR_OM_POLYGON';
export const UNSTAKED_BALANCE_OM_POLYGON = 'UNSTAKED_BALANCE_OM_POLYGON';
export const UNSTAKE_TIME_OM_POLYGON = 'UNSTAKE_TIME_OM_POLYGON';
export const GET_OM_POLYGON_BALANCE = 'GET_OM_POLYGON_BALANCE';
export const GET_OM_POLYGON_PRICE = 'GET_OM_POLYGON_PRICE';
export const WITHDRAW_DETAILS_OM_POLYGON = 'WITHDRAW_DETAILS_OM_POLYGON';
export const WITHDRAW_SEND_OM_POLYGON = 'WITHDRAW_SEND_OM_POLYGON';
export const WITHDRAW_SUCCESS_OM_POLYGON = 'WITHDRAW_SUCCESS_OM_POLYGON';
export const HISTORY_SHOW_OM_POLYGON_STAKING = 'HISTORY_SHOW_OM_POLYGON_STAKING';
export const EARNED_REWARDS_BALANCE_OM_POLYGON = 'REWARD_EARNED_BALANCE_OM_POLYGON';
export const CLAIMING_FEE_PERCENT_OM_POLYGON = 'CLAIMING_FEE_PERCENT_OM_POLYGON';

// GAMER WETH POLYGON STAKING
export const STAKE_SETTINGS_OM_ETH_POLYGON = 'STAKE_SETTINGS_OM_ETH_POLYGON';
export const STAKE_DETAILS_OM_ETH_POLYGON = 'STAKE_DETAILS_OM_ETH_POLYGON';
export const STAKE_SEND_OM_ETH_POLYGON = 'STAKE_SEND_OM_ETH_POLYGON';
export const STAKE_SUCCESS_OM_ETH_POLYGON = 'STAKE_SUCCESS_OM_ETH_POLYGON';
export const STAKE_ERROR_OM_ETH_POLYGON = 'STAKE_ERROR_OM_ETH_POLYGON';
export const RESET_STAKE_OM_ETH_POLYGON = 'RESET_STAKE_OM_ETH_POLYGON';
export const STAKE_BALANCE_OM_ETH_POLYGON = 'STAKE_BALANCE_OM_ETH_POLYGON';
export const UNSTAKED_BALANCE_OM_ETH_POLYGON = 'UNSTAKED_BALANCE_OM_ETH_POLYGON';
export const UNSTAKE_TIME_OM_ETH_POLYGON = 'UNSTAKE_TIME_OM_ETH_POLYGON';
export const REWARD_EARNED_OM_ETH_POLYGON = 'REWARD_EARNED_OM_ETH_POLYGON';
export const REWARD_BALANCE_OM_ETH_POLYGON = 'REWARD_BALANCE_OM_ETH_POLYGON';
export const PERCENT_IN_OM_OM_ETH_POLYGON = 'PERCENT_IN_OM_OM_ETH_POLYGON';
export const PERCENT_FROM_OM_ETH_POLYGON_SWAP_V2 = 'PERCENT_FROM_OM_ETH_POLYGON_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_OM_ETH_POLYGON = 'CLAIM_REWARDS_DETAILS_OM_ETH_POLYGON';
export const CLAIM_REWARDS_SEND_OM_ETH_POLYGON = 'CLAIM_REWARDS_SEND_OM_ETH_POLYGON';
export const CLAIM_REWARDS_SUCCESS_OM_ETH_POLYGON = 'CLAIM_REWARDS_SUCCESS_OM_ETH_POLYGON';
export const UNSTAKE_SETTINGS_OM_ETH_POLYGON = 'UNSTAKE_SETTINGS_OM_ETH_POLYGON';
export const UNSTAKE_DETAILS_OM_ETH_POLYGON = 'UNSTAKE_DETAILS_OM_ETH_POLYGON';
export const UNSTAKE_SEND_OM_ETH_POLYGON = 'UNSTAKE_SEND_OM_ETH_POLYGON';
export const UNSTAKE_SUCCESS_OM_ETH_POLYGON = 'UNSTAKE_SUCCESS_OM_ETH_POLYGON';
export const ESTIMATED_DAILY_REWARDS_OM_ETH_POLYGON = 'ESTIMATED_DAILY_REWARDS_OM_ETH_POLYGON';

export const GET_OM_ETH_POLYGON_BALANCE = 'GET_OM_ETH_POLYGON_BALANCE';
export const GET_OM_ETH_POLYGON_PRICE = 'GET_OM_ETH_POLYGON_PRICE';

export const WITHDRAW_DETAILS_OM_ETH_POLYGON = 'WITHDRAW_DETAILS_OM_ETH_POLYGON';
export const WITHDRAW_SEND_OM_ETH_POLYGON = 'WITHDRAW_SEND_OM_ETH_POLYGON';
export const WITHDRAW_SUCCESS_OM_ETH_POLYGON = 'WITHDRAW_SUCCESS_OM_ETH_POLYGON';

// GAMER_POLYGON STAKING
export const STAKE_SETTINGS_GAMER_POLYGON = 'STAKE_SETTINGS_GAMER_POLYGON';
export const STAKE_DETAILS_GAMER_POLYGON = 'STAKE_DETAILS_GAMER_POLYGON';
export const STAKE_SEND_GAMER_POLYGON = 'STAKE_SEND_GAMER_POLYGON';
export const STAKE_SUCCESS_GAMER_POLYGON = 'STAKE_SUCCESS_GAMER_POLYGON';
export const STAKE_ERROR_GAMER_POLYGON = 'STAKE_ERROR_GAMER_POLYGON';
export const RESET_STAKE_GAMER_POLYGON = 'RESET_STAKE_GAMER_POLYGON';
export const UNSTAKE_SETTINGS_GAMER_POLYGON = 'UNSTAKE_SETTINGS_GAMER_POLYGON';
export const UNSTAKE_INFORMATION_GAMER_POLYGON = 'UNSTAKE_INFORMATION_GAMER_POLYGON';
export const UNSTAKE_DETAILS_GAMER_POLYGON = 'UNSTAKE_DETAILS_GAMER_POLYGON';
export const UNSTAKE_IMMEDIATELY_DETAILS_GAMER_POLYGON =
  'UNSTAKE_IMMEDIATELY_DETAILS_GAMER_POLYGON';
export const UNSTAKE_SEND_GAMER_POLYGON = 'UNSTAKE_SEND_GAMER_POLYGON';
export const UNSTAKE_SUCCESS_GAMER_POLYGON = 'UNSTAKE_SUCCESS_GAMER_POLYGON';
export const STAKE_BALANCE_GAMER_POLYGON = 'STAKE_BALANCE_GAMER_POLYGON';
export const ESTIMATED_DAILY_REWARDS_GAMER_POLYGON = 'ESTIMATED_DAILY_REWARDS_GAMER_POLYGON';
export const APR_GAMER_POLYGON = 'APR_GAMER_POLYGON';
export const UNSTAKED_BALANCE_GAMER_POLYGON = 'UNSTAKED_BALANCE_GAMER_POLYGON';
export const UNSTAKE_TIME_GAMER_POLYGON = 'UNSTAKE_TIME_GAMER_POLYGON';
export const GET_GAMER_POLYGON_BALANCE = 'GET_GAMER_POLYGON_BALANCE';
export const GET_GAMER_POLYGON_PRICE = 'GET_GAMER_POLYGON_PRICE';
export const WITHDRAW_DETAILS_GAMER_POLYGON = 'WITHDRAW_DETAILS_GAMER_POLYGON';
export const WITHDRAW_SEND_GAMER_POLYGON = 'WITHDRAW_SEND_GAMER_POLYGON';
export const WITHDRAW_SUCCESS_GAMER_POLYGON = 'WITHDRAW_SUCCESS_GAMER_POLYGON';
export const HISTORY_SHOW_GAMER_POLYGON_STAKING = 'HISTORY_SHOW_GAMER_POLYGON_STAKING';
export const EARNED_REWARDS_BALANCE_GAMER_POLYGON = 'REWARD_EARNED_BALANCE_GAMER_POLYGON';
export const CLAIMING_FEE_PERCENT_GAMER_POLYGON = 'CLAIMING_FEE_PERCENT_GAMER_POLYGON';
export const STAKE_ATTENTION_GAMER_POLYGON = 'STAKE_ATTENTION_GAMER_POLYGON';

// OM ETH POLYGON STAKING
export const STAKE_SETTINGS_GAMER_WETH_POLYGON = 'STAKE_SETTINGS_GAMER_WETH_POLYGON';
export const STAKE_DETAILS_GAMER_WETH_POLYGON = 'STAKE_DETAILS_GAMER_WETH_POLYGON';
export const STAKE_SEND_GAMER_WETH_POLYGON = 'STAKE_SEND_GAMER_WETH_POLYGON';
export const STAKE_SUCCESS_GAMER_WETH_POLYGON = 'STAKE_SUCCESS_GAMER_WETH_POLYGON';
export const STAKE_ERROR_GAMER_WETH_POLYGON = 'STAKE_ERROR_GAMER_WETH_POLYGON';
export const RESET_STAKE_GAMER_WETH_POLYGON = 'RESET_STAKE_GAMER_WETH_POLYGON';
export const STAKE_BALANCE_GAMER_WETH_POLYGON = 'STAKE_BALANCE_GAMER_WETH_POLYGON';
export const UNSTAKED_BALANCE_GAMER_WETH_POLYGON = 'UNSTAKED_BALANCE_GAMER_WETH_POLYGON';
export const UNSTAKE_TIME_GAMER_WETH_POLYGON = 'UNSTAKE_TIME_GAMER_WETH_POLYGON';
export const REWARD_EARNED_GAMER_WETH_POLYGON = 'REWARD_EARNED_GAMER_WETH_POLYGON';
export const REWARD_BALANCE_GAMER_WETH_POLYGON = 'REWARD_BALANCE_GAMER_WETH_POLYGON';
export const PERCENT_IN_OM_GAMER_WETH_POLYGON = 'PERCENT_IN_OM_GAMER_WETH_POLYGON';
export const PERCENT_FROM_GAMER_WETH_POLYGON_SWAP_V2 = 'PERCENT_FROM_GAMER_WETH_POLYGON_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_GAMER_WETH_POLYGON = 'CLAIM_REWARDS_DETAILS_GAMER_WETH_POLYGON';
export const CLAIM_REWARDS_SEND_GAMER_WETH_POLYGON = 'CLAIM_REWARDS_SEND_GAMER_WETH_POLYGON';
export const CLAIM_REWARDS_SUCCESS_GAMER_WETH_POLYGON = 'CLAIM_REWARDS_SUCCESS_GAMER_WETH_POLYGON';
export const UNSTAKE_SETTINGS_GAMER_WETH_POLYGON = 'UNSTAKE_SETTINGS_GAMER_WETH_POLYGON';
export const UNSTAKE_DETAILS_GAMER_WETH_POLYGON = 'UNSTAKE_DETAILS_GAMER_WETH_POLYGON';
export const UNSTAKE_SEND_GAMER_WETH_POLYGON = 'UNSTAKE_SEND_GAMER_WETH_POLYGON';
export const UNSTAKE_SUCCESS_GAMER_WETH_POLYGON = 'UNSTAKE_SUCCESS_GAMER_WETH_POLYGON';
export const ESTIMATED_DAILY_REWARDS_GAMER_WETH_POLYGON =
  'ESTIMATED_DAILY_REWARDS_GAMER_WETH_POLYGON';

export const GET_GAMER_WETH_POLYGON_BALANCE = 'GET_GAMER_WETH_POLYGON_BALANCE';
export const GET_GAMER_WETH_POLYGON_PRICE = 'GET_GAMER_WETH_POLYGON_PRICE';

export const WITHDRAW_DETAILS_GAMER_WETH_POLYGON = 'WITHDRAW_DETAILS_GAMER_WETH_POLYGON';
export const WITHDRAW_SEND_GAMER_WETH_POLYGON = 'WITHDRAW_SEND_GAMER_WETH_POLYGON';
export const WITHDRAW_SUCCESS_GAMER_WETH_POLYGON = 'WITHDRAW_SUCCESS_GAMER_WETH_POLYGON';

// STFI STAKING
export const STAKE_SETTINGS_STFI = 'STAKE_SETTINGS_STFI';
export const STAKE_DETAILS_STFI = 'STAKE_DETAILS_STFI';
export const STAKE_SEND_STFI = 'STAKE_SEND_STFI';
export const STAKE_SUCCESS_STFI = 'STAKE_SUCCESS_STFI';
export const STAKE_ERROR_STFI = 'STAKE_ERROR_STFI';
export const RESET_STAKE_STFI = 'RESET_STAKE_STFI';
export const UNSTAKE_SETTINGS_STFI = 'UNSTAKE_SETTINGS_STFI';
export const UNSTAKE_INFORMATION_STFI = 'UNSTAKE_INFORMATION_STFI';
export const UNSTAKE_DETAILS_STFI = 'UNSTAKE_DETAILS_STFI';
export const UNSTAKE_IMMEDIATELY_DETAILS_STFI = 'UNSTAKE_IMMEDIATELY_DETAILS_STFI';
export const UNSTAKE_SEND_STFI = 'UNSTAKE_SEND_STFI';
export const UNSTAKE_SUCCESS_STFI = 'UNSTAKE_SUCCESS_STFI';
export const STAKE_BALANCE_STFI = 'STAKE_BALANCE_STFI';
export const ESTIMATED_DAILY_REWARDS_STFI = 'ESTIMATED_DAILY_REWARDS_STFI';
export const APR_STFI = 'APR_STFI';
export const UNSTAKED_BALANCE_STFI = 'UNSTAKED_BALANCE_STFI';
export const UNSTAKE_TIME_STFI = 'UNSTAKE_TIME_STFI';
export const GET_STFI_BALANCE = 'GET_STFI_BALANCE';
export const GET_STFI_PRICE = 'GET_STFI_PRICE';
export const WITHDRAW_DETAILS_STFI = 'WITHDRAW_DETAILS_STFI';
export const WITHDRAW_SEND_STFI = 'WITHDRAW_SEND_STFI';
export const WITHDRAW_SUCCESS_STFI = 'WITHDRAW_SUCCESS_STFI';
export const HISTORY_SHOW_STFI_STAKING = 'HISTORY_SHOW_STFI_STAKING';
export const EARNED_REWARDS_BALANCE_STFI = 'REWARD_EARNED_BALANCE_STFI';
export const CLAIMING_FEE_PERCENT_STFI = 'CLAIMING_FEE_PERCENT_STFI';

// FACTR STAKING
export const STAKE_SETTINGS_FACTR = 'STAKE_SETTINGS_FACTR';
export const STAKE_DETAILS_FACTR = 'STAKE_DETAILS_FACTR';
export const STAKE_SEND_FACTR = 'STAKE_SEND_FACTR';
export const STAKE_SUCCESS_FACTR = 'STAKE_SUCCESS_FACTR';
export const STAKE_ERROR_FACTR = 'STAKE_ERROR_FACTR';
export const RESET_STAKE_FACTR = 'RESET_STAKE_FACTR';
export const UNSTAKE_SETTINGS_FACTR = 'UNSTAKE_SETTINGS_FACTR';
export const UNSTAKE_INFORMATION_FACTR = 'UNSTAKE_INFORMATION_FACTR';
export const UNSTAKE_DETAILS_FACTR = 'UNSTAKE_DETAILS_FACTR';
export const UNSTAKE_IMMEDIATELY_DETAILS_FACTR = 'UNSTAKE_IMMEDIATELY_DETAILS_FACTR';
export const UNSTAKE_SEND_FACTR = 'UNSTAKE_SEND_FACTR';
export const UNSTAKE_SUCCESS_FACTR = 'UNSTAKE_SUCCESS_FACTR';
export const STAKE_BALANCE_FACTR = 'STAKE_BALANCE_FACTR';
export const ESTIMATED_DAILY_REWARDS_FACTR = 'ESTIMATED_DAILY_REWARDS_FACTR';
export const APR_FACTR = 'APR_FACTR';
export const UNSTAKED_BALANCE_FACTR = 'UNSTAKED_BALANCE_FACTR';
export const UNSTAKE_TIME_FACTR = 'UNSTAKE_TIME_FACTR';
export const GET_FACTR_BALANCE = 'GET_FACTR_BALANCE';
export const GET_FACTR_PRICE = 'GET_FACTR_PRICE';
export const WITHDRAW_DETAILS_FACTR = 'WITHDRAW_DETAILS_FACTR';
export const WITHDRAW_SEND_FACTR = 'WITHDRAW_SEND_FACTR';
export const WITHDRAW_SUCCESS_FACTR = 'WITHDRAW_SUCCESS_FACTR';
export const HISTORY_SHOW_FACTR_STAKING = 'HISTORY_SHOW_FACTR_STAKING';
export const EARNED_REWARDS_BALANCE_FACTR = 'REWARD_EARNED_BALANCE_FACTR';
export const CLAIMING_FEE_PERCENT_FACTR = 'CLAIMING_FEE_PERCENT_FACTR';

// BONDLY_BSC STAKING
export const STAKE_SETTINGS_BONDLY_BSC = 'STAKE_SETTINGS_BONDLY_BSC';
export const STAKE_DETAILS_BONDLY_BSC = 'STAKE_DETAILS_BONDLY_BSC';
export const STAKE_SEND_BONDLY_BSC = 'STAKE_SEND_BONDLY_BSC';
export const STAKE_SUCCESS_BONDLY_BSC = 'STAKE_SUCCESS_BONDLY_BSC';
export const STAKE_ERROR_BONDLY_BSC = 'STAKE_ERROR_BONDLY_BSC';
export const RESET_STAKE_BONDLY_BSC = 'RESET_STAKE_BONDLY_BSC';
export const UNSTAKE_SETTINGS_BONDLY_BSC = 'UNSTAKE_SETTINGS_BONDLY_BSC';
export const UNSTAKE_INFORMATION_BONDLY_BSC = 'UNSTAKE_INFORMATION_BONDLY_BSC';
export const UNSTAKE_DETAILS_BONDLY_BSC = 'UNSTAKE_DETAILS_BONDLY_BSC';
export const UNSTAKE_IMMEDIATELY_DETAILS_BONDLY_BSC = 'UNSTAKE_IMMEDIATELY_DETAILS_BONDLY_BSC';
export const UNSTAKE_SEND_BONDLY_BSC = 'UNSTAKE_SEND_BONDLY_BSC';
export const UNSTAKE_SUCCESS_BONDLY_BSC = 'UNSTAKE_SUCCESS_BONDLY_BSC';
export const STAKE_BALANCE_BONDLY_BSC = 'STAKE_BALANCE_BONDLY_BSC';
export const ESTIMATED_DAILY_REWARDS_BONDLY_BSC = 'ESTIMATED_DAILY_REWARDS_BONDLY_BSC';
export const APR_BONDLY_BSC = 'APR_BONDLY_BSC';
export const UNSTAKED_BALANCE_BONDLY_BSC = 'UNSTAKED_BALANCE_BONDLY_BSC';
export const UNSTAKE_TIME_BONDLY_BSC = 'UNSTAKE_TIME_BONDLY_BSC';
export const GET_BONDLY_BSC_BALANCE = 'GET_BONDLY_BSC_BALANCE';
export const GET_BONDLY_BSC_PRICE = 'GET_BONDLY_BSC_PRICE';
export const WITHDRAW_DETAILS_BONDLY_BSC = 'WITHDRAW_DETAILS_BONDLY_BSC';
export const WITHDRAW_SEND_BONDLY_BSC = 'WITHDRAW_SEND_BONDLY_BSC';
export const WITHDRAW_SUCCESS_BONDLY_BSC = 'WITHDRAW_SUCCESS_BONDLY_BSC';
export const HISTORY_SHOW_BONDLY_BSC_STAKING = 'HISTORY_SHOW_BONDLY_BSC_STAKING';
export const EARNED_REWARDS_BALANCE_BONDLY_BSC = 'REWARD_EARNED_BALANCE_BONDLY_BSC';
export const CLAIMING_FEE_PERCENT_BONDLY_BSC = 'CLAIMING_FEE_PERCENT_BONDLY_BSC';

// KYL STAKING
export const STAKE_SETTINGS_KYL = 'STAKE_SETTINGS_KYL';
export const STAKE_DETAILS_KYL = 'STAKE_DETAILS_KYL';
export const STAKE_SEND_KYL = 'STAKE_SEND_KYL';
export const STAKE_SUCCESS_KYL = 'STAKE_SUCCESS_KYL';
export const STAKE_ERROR_KYL = 'STAKE_ERROR_KYL';
export const RESET_STAKE_KYL = 'RESET_STAKE_KYL';
export const UNSTAKE_SETTINGS_KYL = 'UNSTAKE_SETTINGS_KYL';
export const UNSTAKE_INFORMATION_KYL = 'UNSTAKE_INFORMATION_KYL';
export const UNSTAKE_DETAILS_KYL = 'UNSTAKE_DETAILS_KYL';
export const UNSTAKE_IMMEDIATELY_DETAILS_KYL = 'UNSTAKE_IMMEDIATELY_DETAILS_KYL';
export const UNSTAKE_SEND_KYL = 'UNSTAKE_SEND_KYL';
export const UNSTAKE_SUCCESS_KYL = 'UNSTAKE_SUCCESS_KYL';
export const STAKE_BALANCE_KYL = 'STAKE_BALANCE_KYL';
export const ESTIMATED_DAILY_REWARDS_KYL = 'ESTIMATED_DAILY_REWARDS_KYL';
export const APR_KYL = 'APR_KYL';
export const UNSTAKED_BALANCE_KYL = 'UNSTAKED_BALANCE_KYL';
export const UNSTAKE_TIME_KYL = 'UNSTAKE_TIME_KYL';
export const GET_KYL_BALANCE = 'GET_KYL_BALANCE';
export const GET_KYL_PRICE = 'GET_KYL_PRICE';
export const WITHDRAW_DETAILS_KYL = 'WITHDRAW_DETAILS_KYL';
export const WITHDRAW_SEND_KYL = 'WITHDRAW_SEND_KYL';
export const WITHDRAW_SUCCESS_KYL = 'WITHDRAW_SUCCESS_KYL';
export const HISTORY_SHOW_KYL_STAKING = 'HISTORY_SHOW_KYL_STAKING';
export const EARNED_REWARDS_BALANCE_KYL = 'REWARD_EARNED_BALANCE_KYL';
export const CLAIMING_FEE_PERCENT_KYL = 'CLAIMING_FEE_PERCENT_KYL';

// PKF STAKING
export const STAKE_SETTINGS_PKF = 'STAKE_SETTINGS_PKF';
export const STAKE_DETAILS_PKF = 'STAKE_DETAILS_PKF';
export const STAKE_SEND_PKF = 'STAKE_SEND_PKF';
export const STAKE_SUCCESS_PKF = 'STAKE_SUCCESS_PKF';
export const STAKE_ERROR_PKF = 'STAKE_ERROR_PKF';
export const RESET_STAKE_PKF = 'RESET_STAKE_PKF';
export const UNSTAKE_SETTINGS_PKF = 'UNSTAKE_SETTINGS_PKF';
export const UNSTAKE_INFORMATION_PKF = 'UNSTAKE_INFORMATION_PKF';
export const UNSTAKE_DETAILS_PKF = 'UNSTAKE_DETAILS_PKF';
export const UNSTAKE_IMMEDIATELY_DETAILS_PKF = 'UNSTAKE_IMMEDIATELY_DETAILS_PKF';
export const UNSTAKE_SEND_PKF = 'UNSTAKE_SEND_PKF';
export const UNSTAKE_SUCCESS_PKF = 'UNSTAKE_SUCCESS_PKF';
export const STAKE_BALANCE_PKF = 'STAKE_BALANCE_PKF';
export const ESTIMATED_DAILY_REWARDS_PKF = 'ESTIMATED_DAILY_REWARDS_PKF';
export const APR_PKF = 'APR_PKF';
export const UNSTAKED_BALANCE_PKF = 'UNSTAKED_BALANCE_PKF';
export const UNSTAKE_TIME_PKF = 'UNSTAKE_TIME_PKF';
export const GET_PKF_BALANCE = 'GET_PKF_BALANCE';
export const GET_PKF_PRICE = 'GET_PKF_PRICE';
export const WITHDRAW_DETAILS_PKF = 'WITHDRAW_DETAILS_PKF';
export const WITHDRAW_SEND_PKF = 'WITHDRAW_SEND_PKF';
export const WITHDRAW_SUCCESS_PKF = 'WITHDRAW_SUCCESS_PKF';
export const HISTORY_SHOW_PKF_STAKING = 'HISTORY_SHOW_PKF_STAKING';
export const EARNED_REWARDS_BALANCE_PKF = 'REWARD_EARNED_BALANCE_PKF';
export const CLAIMING_FEE_PERCENT_PKF = 'CLAIMING_FEE_PERCENT_PKF';
export const ESTIMATED_DAILY_REWARDS_STORED_DATA_PKF = 'ESTIMATED_DAILY_REWARDS_STORED_DATA_PKF';

// LABS STAKING
export const STAKE_SETTINGS_LABS = 'STAKE_SETTINGS_LABS';
export const STAKE_DETAILS_LABS = 'STAKE_DETAILS_LABS';
export const STAKE_ERROR_LABS = 'STAKE_ERROR_LABS';
export const RESET_STAKE_LABS = 'RESET_STAKE_LABS';
export const UNSTAKE_SETTINGS_LABS = 'UNSTAKE_SETTINGS_LABS';
export const UNSTAKE_INFORMATION_LABS = 'UNSTAKE_INFORMATION_LABS';
export const UNSTAKE_DETAILS_LABS = 'UNSTAKE_DETAILS_LABS';
export const UNSTAKE_IMMEDIATELY_DETAILS_LABS = 'UNSTAKE_IMMEDIATELY_DETAILS_LABS';
export const UNSTAKE_SEND_LABS = 'UNSTAKE_SEND_LABS';
export const UNSTAKE_SUCCESS_LABS = 'UNSTAKE_SUCCESS_LABS';
export const STAKE_BALANCE_LABS = 'STAKE_BALANCE_LABS';
export const ESTIMATED_DAILY_REWARDS_LABS = 'ESTIMATED_DAILY_REWARDS_LABS';
export const APR_LABS = 'APR_LABS';
export const UNSTAKED_BALANCE_LABS = 'UNSTAKED_BALANCE_LABS';
export const UNSTAKE_TIME_LABS = 'UNSTAKE_TIME_LABS';
export const GET_LABS_BALANCE = 'GET_LABS_BALANCE';
export const GET_LABS_PRICE = 'GET_LABS_PRICE';
export const WITHDRAW_DETAILS_LABS = 'WITHDRAW_DETAILS_LABS';
export const WITHDRAW_SEND_LABS = 'WITHDRAW_SEND_LABS';
export const WITHDRAW_SUCCESS_LABS = 'WITHDRAW_SUCCESS_LABS';
export const HISTORY_SHOW_LABS_STAKING = 'HISTORY_SHOW_LABS_STAKING';
export const EARNED_REWARDS_BALANCE_LABS = 'REWARD_EARNED_BALANCE_LABS';
export const CLAIMING_FEE_PERCENT_LABS = 'CLAIMING_FEE_PERCENT_LABS';

// L3P STAKING
export const STAKE_SETTINGS_L3P = 'STAKE_SETTINGS_L3P';
export const STAKE_DETAILS_L3P = 'STAKE_DETAILS_L3P';
export const STAKE_SEND_L3P = 'STAKE_SEND_L3P';
export const STAKE_SUCCESS_L3P = 'STAKE_SUCCESS_L3P';
export const STAKE_ERROR_L3P = 'STAKE_ERROR_L3P';
export const RESET_STAKE_L3P = 'RESET_STAKE_L3P';
export const UNSTAKE_SETTINGS_L3P = 'UNSTAKE_SETTINGS_L3P';
export const UNSTAKE_INFORMATION_L3P = 'UNSTAKE_INFORMATION_L3P';
export const UNSTAKE_DETAILS_L3P = 'UNSTAKE_DETAILS_L3P';
export const UNSTAKE_IMMEDIATELY_DETAILS_L3P = 'UNSTAKE_IMMEDIATELY_DETAILS_L3P';
export const UNSTAKE_SEND_L3P = 'UNSTAKE_SEND_L3P';
export const UNSTAKE_SUCCESS_L3P = 'UNSTAKE_SUCCESS_L3P';
export const STAKE_BALANCE_L3P = 'STAKE_BALANCE_L3P';
export const ESTIMATED_DAILY_REWARDS_L3P = 'ESTIMATED_DAILY_REWARDS_L3P';
export const APR_L3P = 'APR_L3P';
export const UNSTAKED_BALANCE_L3P = 'UNSTAKED_BALANCE_L3P';
export const UNSTAKE_TIME_L3P = 'UNSTAKE_TIME_L3P';
export const GET_L3P_BALANCE = 'GET_L3P_BALANCE';
export const GET_L3P_PRICE = 'GET_L3P_PRICE';
export const WITHDRAW_DETAILS_L3P = 'WITHDRAW_DETAILS_L3P';
export const WITHDRAW_SEND_L3P = 'WITHDRAW_SEND_L3P';
export const WITHDRAW_SUCCESS_L3P = 'WITHDRAW_SUCCESS_L3P';
export const HISTORY_SHOW_L3P_STAKING = 'HISTORY_SHOW_L3P_STAKING';
export const EARNED_REWARDS_BALANCE_L3P = 'REWARD_EARNED_BALANCE_L3P';
export const CLAIMING_FEE_PERCENT_L3P = 'CLAIMING_FEE_PERCENT_L3P';

// RFUEL STAKING
export const STAKE_SETTINGS_RFUEL = 'STAKE_SETTINGS_RFUEL';
export const STAKE_DETAILS_RFUEL = 'STAKE_DETAILS_RFUEL';
export const STAKE_SEND_RFUEL = 'STAKE_SEND_RFUEL';
export const STAKE_SUCCESS_RFUEL = 'STAKE_SUCCESS_RFUEL';
export const STAKE_ERROR_RFUEL = 'STAKE_ERROR_RFUEL';
export const RESET_STAKE_RFUEL = 'RESET_STAKE_RFUEL';
export const STAKE_BALANCE_RFUEL = 'STAKE_BALANCE_RFUEL';
export const STAKE_TIME_RFUEL = 'STAKE_TIME_RFUEL';
export const UNSTAKED_BALANCE_RFUEL = 'UNSTAKED_BALANCE_RFUEL';
export const UNSTAKE_TIME_RFUEL = 'UNSTAKE_TIME_RFUEL';
export const REWARD_EARNED_RFUEL = 'REWARD_EARNED_RFUEL';
export const REWARD_BALANCE_RFUEL = 'REWARD_BALANCE_RFUEL';
export const UNSTAKE_SETTINGS_RFUEL = 'UNSTAKE_SETTINGS_RFUEL';
export const UNSTAKE_INFORMATION_RFUEL = 'UNSTAKE_INFORMATION_RFUEL';
export const UNSTAKE_DETAILS_RFUEL = 'UNSTAKE_DETAILS_RFUEL';
export const UNSTAKE_SEND_RFUEL = 'UNSTAKE_SEND_RFUEL';
export const UNSTAKE_SUCCESS_RFUEL = 'UNSTAKE_SUCCESS_RFUEL';
export const WITHDRAW_DETAILS_RFUEL = 'WITHDRAW_DETAILS_RFUEL';
export const WITHDRAW_SEND_RFUEL = 'WITHDRAW_SEND_RFUEL';
export const WITHDRAW_SUCCESS_RFUEL = 'WITHDRAW_SUCCESS_RFUEL';
export const HISTORY_SHOW_RFUEL_STAKING = 'HISTORY_SHOW_RFUEL_STAKING';

export const GET_RFUEL_BALANCE = 'GET_RFUEL_BALANCE';
export const GET_RFUEL_PRICE = 'GET_RFUEL_PRICE';

// UNI STAKING
export const STAKE_SETTINGS_UNI = 'STAKE_SETTINGS_UNI';
export const STAKE_DETAILS_UNI = 'STAKE_DETAILS_UNI';
export const STAKE_SEND_UNI = 'STAKE_SEND_UNI';
export const STAKE_SUCCESS_UNI = 'STAKE_SUCCESS_UNI';
export const STAKE_ERROR_UNI = 'STAKE_ERROR_UNI';
export const RESET_STAKE_UNI = 'RESET_STAKE_UNI';
export const STAKE_BALANCE_UNI = 'STAKE_BALANCE_UNI';
export const UNSTAKED_BALANCE_UNI = 'UNSTAKED_BALANCE_UNI';
export const UNSTAKE_TIME_UNI = 'UNSTAKE_TIME_UNI';
export const REWARD_EARNED_UNI = 'REWARD_EARNED_UNI';
export const REWARD_BALANCE_UNI = 'REWARD_BALANCE_UNI';
export const PERCENT_IN_OM_UNI = 'PERCENT_IN_OM_UNI';
export const PERCENT_FROM_UNI_SWAP = 'PERCENT_FROM_UNI_SWAP';
export const CLAIM_REWARDS_DETAILS_UNI = 'CLAIM_REWARDS_DETAILS_UNI';
export const CLAIM_REWARDS_SEND_UNI = 'CLAIM_REWARDS_SEND_UNI';
export const CLAIM_REWARDS_SUCCESS_UNI = 'CLAIM_REWARDS_SUCCESS_UNI';
export const UNSTAKE_SETTINGS_UNI = 'UNSTAKE_SETTINGS_UNI';
export const UNSTAKE_DETAILS_UNI = 'UNSTAKE_DETAILS_UNI';
export const UNSTAKE_SEND_UNI = 'UNSTAKE_SEND_UNI';
export const UNSTAKE_SUCCESS_UNI = 'UNSTAKE_SUCCESS_UNI';
export const ESTIMATED_DAILY_REWARDS_UNI = 'ESTIMATED_DAILY_REWARDS_UNI';

export const GET_UNI_BALANCE = 'GET_UNI_BALANCE';
export const GET_UNI_PRICE = 'GET_UNI_PRICE';

export const WITHDRAW_DETAILS_UNI = 'WITHDRAW_DETAILS_UNI';
export const WITHDRAW_SEND_UNI = 'WITHDRAW_SEND_UNI';
export const WITHDRAW_SUCCESS_UNI = 'WITHDRAW_SUCCESS_UNI';

// ROYA STAKING
export const STAKE_SETTINGS_ROYA = 'STAKE_SETTINGS_ROYA';
export const STAKE_DETAILS_ROYA = 'STAKE_DETAILS_ROYA';
export const STAKE_SEND_ROYA = 'STAKE_SEND_ROYA';
export const STAKE_SUCCESS_ROYA = 'STAKE_SUCCESS_ROYA';
export const STAKE_ERROR_ROYA = 'STAKE_ERROR_ROYA';
export const RESET_STAKE_ROYA = 'RESET_STAKE_ROYA';
export const UNSTAKE_SETTINGS_ROYA = 'UNSTAKE_SETTINGS_ROYA';
export const UNSTAKE_INFORMATION_ROYA = 'UNSTAKE_INFORMATION_ROYA';
export const UNSTAKE_DETAILS_ROYA = 'UNSTAKE_DETAILS_ROYA';
export const UNSTAKE_IMMEDIATELY_DETAILS_ROYA = 'UNSTAKE_IMMEDIATELY_DETAILS_ROYA';
export const UNSTAKE_SEND_ROYA = 'UNSTAKE_SEND_ROYA';
export const UNSTAKE_SUCCESS_ROYA = 'UNSTAKE_SUCCESS_ROYA';
export const STAKE_BALANCE_ROYA = 'STAKE_BALANCE_ROYA';
export const ESTIMATED_DAILY_REWARDS_ROYA = 'ESTIMATED_DAILY_REWARDS_ROYA';
export const APR_ROYA = 'APR_ROYA';
export const UNSTAKED_BALANCE_ROYA = 'UNSTAKED_BALANCE_ROYA';
export const UNSTAKE_TIME_ROYA = 'UNSTAKE_TIME_ROYA';
export const GET_ROYA_BALANCE = 'GET_ROYA_BALANCE';
export const GET_ROYA_PRICE = 'GET_ROYA_PRICE';
export const WITHDRAW_DETAILS_ROYA = 'WITHDRAW_DETAILS_ROYA';
export const WITHDRAW_SEND_ROYA = 'WITHDRAW_SEND_ROYA';
export const WITHDRAW_SUCCESS_ROYA = 'WITHDRAW_SUCCESS_ROYA';
export const HISTORY_SHOW_ROYA_STAKING = 'HISTORY_SHOW_ROYA_STAKING';
export const EARNED_REWARDS_BALANCE_ROYA = 'REWARD_EARNED_BALANCE_ROYA';
export const CLAIMING_FEE_PERCENT_ROYA = 'CLAIMING_FEE_PERCENT_ROYA';

// FXF STAKING
export const STAKE_SETTINGS_FXF = 'STAKE_SETTINGS_FXF';
export const STAKE_DETAILS_FXF = 'STAKE_DETAILS_FXF';
export const STAKE_SEND_FXF = 'STAKE_SEND_FXF';
export const STAKE_SUCCESS_FXF = 'STAKE_SUCCESS_FXF';
export const STAKE_ERROR_FXF = 'STAKE_ERROR_FXF';
export const RESET_STAKE_FXF = 'RESET_STAKE_FXF';
export const UNSTAKE_SETTINGS_FXF = 'UNSTAKE_SETTINGS_FXF';
export const UNSTAKE_INFORMATION_FXF = 'UNSTAKE_INFORMATION_FXF';
export const UNSTAKE_DETAILS_FXF = 'UNSTAKE_DETAILS_FXF';
export const UNSTAKE_IMMEDIATELY_DETAILS_FXF = 'UNSTAKE_IMMEDIATELY_DETAILS_FXF';
export const UNSTAKE_SEND_FXF = 'UNSTAKE_SEND_FXF';
export const UNSTAKE_SUCCESS_FXF = 'UNSTAKE_SUCCESS_FXF';
export const STAKE_BALANCE_FXF = 'STAKE_BALANCE_FXF';
export const ESTIMATED_DAILY_REWARDS_FXF = 'ESTIMATED_DAILY_REWARDS_FXF';
export const APR_FXF = 'APR_FXF';
export const UNSTAKED_BALANCE_FXF = 'UNSTAKED_BALANCE_FXF';
export const UNSTAKE_TIME_FXF = 'UNSTAKE_TIME_FXF';
export const GET_FXF_BALANCE = 'GET_FXF_BALANCE';
export const GET_FXF_PRICE = 'GET_FXF_PRICE';
export const WITHDRAW_DETAILS_FXF = 'WITHDRAW_DETAILS_FXF';
export const WITHDRAW_SEND_FXF = 'WITHDRAW_SEND_FXF';
export const WITHDRAW_SUCCESS_FXF = 'WITHDRAW_SUCCESS_FXF';
export const HISTORY_SHOW_FXF_STAKING = 'HISTORY_SHOW_FXF_STAKING';
export const EARNED_REWARDS_BALANCE_FXF = 'REWARD_EARNED_BALANCE_FXF';
export const ESTIMATED_DAILY_REWARDS_STORED_DATA_FXF = 'ESTIMATED_DAILY_REWARDS_STORED_DATA_FXF';

// Polkadot delegating

export const GO_BACK_DOT = 'GO_BACK_DOT';
export const CHANGE_CHAIN_MODE = 'CHANGE_CHAIN_MODE';

export const NOMINATE_BALANCE_DOT = 'NOMINATE_BALANCE_DOT';
export const GET_DOT_BALANCE = 'GET_DOT_BALANCE';
export const GET_DOT_PRICE = 'GET_DOT_PRICE';
export const GET_DOT_BALANCE_DATA = 'GET_DOT_BALANCE_DATA';

export const GET_KSM_BALANCE = 'GET_KSM_BALANCE';
export const GET_KSM_BALANCE_DATA = 'GET_KSM_BALANCE_DATA';
export const GET_KSM_PRICE = 'GET_KSM_PRICE';
export const NOMINATE_BALANCE_KSM = 'NOMINATE_BALANCE_KSM';

export const CLEAR_NOMINATORS = 'CLEAR_NOMINATORS';

// Total value staked/nominated

export const GET_TOTAL_STAKED_VALUE = 'GET_TOTAL_STAKED_VALUE';
export const GET_TOTAL_NOMINATED_VALUE = 'GET_TOTAL_NOMINATED_VALUE';

// Currently unused
// UNI V2 STAKING
export const STAKE_SETTINGS_UNI_V2 = 'STAKE_SETTINGS_UNI_V2';
export const STAKE_DETAILS_UNI_V2 = 'STAKE_DETAILS_UNI_V2';
export const STAKE_SEND_UNI_V2 = 'STAKE_SEND_UNI_V2';
export const STAKE_SUCCESS_UNI_V2 = 'STAKE_SUCCESS_UNI_V2';
export const STAKE_ERROR_UNI_V2 = 'STAKE_ERROR_UNI_V2';
export const RESET_STAKE_UNI_V2 = 'RESET_STAKE_UNI_V2';
export const STAKE_BALANCE_UNI_V2 = 'STAKE_BALANCE_UNI_V2';
export const UNSTAKED_BALANCE_UNI_V2 = 'UNSTAKED_BALANCE_UNI_V2';
export const UNSTAKE_TIME_UNI_V2 = 'UNSTAKE_TIME_UNI_V2';
export const REWARD_EARNED_UNI_V2 = 'REWARD_EARNED_UNI_V2';
export const REWARD_BALANCE_UNI_V2 = 'REWARD_BALANCE_UNI_V2';
export const PERCENT_IN_OM_UNI_V2 = 'PERCENT_IN_OM_UNI_V2';
export const PERCENT_FROM_UNI_SWAP_V2 = 'PERCENT_FROM_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_UNI_V2 = 'CLAIM_REWARDS_DETAILS_UNI_V2';
export const CLAIM_REWARDS_SEND_UNI_V2 = 'CLAIM_REWARDS_SEND_UNI_V2';
export const CLAIM_REWARDS_SUCCESS_UNI_V2 = 'CLAIM_REWARDS_SUCCESS_UNI_V2';
export const UNSTAKE_SETTINGS_UNI_V2 = 'UNSTAKE_SETTINGS_UNI_V2';
export const UNSTAKE_DETAILS_UNI_V2 = 'UNSTAKE_DETAILS_UNI_V2';
export const UNSTAKE_SEND_UNI_V2 = 'UNSTAKE_SEND_UNI_V2';
export const UNSTAKE_SUCCESS_UNI_V2 = 'UNSTAKE_SUCCESS_UNI_V2';
export const ESTIMATED_DAILY_REWARDS_UNI_V2 = 'ESTIMATED_DAILY_REWARDS_UNI_V2';

export const GET_UNI_V2_BALANCE = 'GET_UNI_V2_BALANCE';
export const GET_UNI_V2_PRICE = 'GET_UNI_V2_PRICE';

export const WITHDRAW_DETAILS_UNI_V2 = 'WITHDRAW_DETAILS_UNI_V2';
export const WITHDRAW_SEND_UNI_V2 = 'WITHDRAW_SEND_UNI_V2';
export const WITHDRAW_SUCCESS_UNI_V2 = 'WITHDRAW_SUCCESS_UNI_V2';

// ROYA UNI STAKING
export const STAKE_SETTINGS_ROYA_UNI = 'STAKE_SETTINGS_ROYA_UNI';
export const STAKE_DETAILS_ROYA_UNI = 'STAKE_DETAILS_ROYA_UNI';
export const STAKE_SEND_ROYA_UNI = 'STAKE_SEND_ROYA_UNI';
export const STAKE_SUCCESS_ROYA_UNI = 'STAKE_SUCCESS_ROYA_UNI';
export const STAKE_ERROR_ROYA_UNI = 'STAKE_ERROR_ROYA_UNI';
export const RESET_STAKE_ROYA_UNI = 'RESET_STAKE_ROYA_UNI';
export const STAKE_BALANCE_ROYA_UNI = 'STAKE_BALANCE_ROYA_UNI';
export const UNSTAKED_BALANCE_ROYA_UNI = 'UNSTAKED_BALANCE_ROYA_UNI';
export const UNSTAKE_TIME_ROYA_UNI = 'UNSTAKE_TIME_ROYA_UNI';
export const REWARD_EARNED_ROYA_UNI = 'REWARD_EARNED_ROYA_UNI';
export const REWARD_BALANCE_ROYA_UNI = 'REWARD_BALANCE_ROYA_UNI';
export const PERCENT_IN_OM_ROYA_UNI = 'PERCENT_IN_OM_ROYA_UNI';
export const PERCENT_FROM_ROYA_UNI_SWAP_V2 = 'PERCENT_FROM_ROYA_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ROYA_UNI = 'CLAIM_REWARDS_DETAILS_ROYA_UNI';
export const CLAIM_REWARDS_SEND_ROYA_UNI = 'CLAIM_REWARDS_SEND_ROYA_UNI';
export const CLAIM_REWARDS_SUCCESS_ROYA_UNI = 'CLAIM_REWARDS_SUCCESS_ROYA_UNI';
export const UNSTAKE_SETTINGS_ROYA_UNI = 'UNSTAKE_SETTINGS_ROYA_UNI';
export const UNSTAKE_DETAILS_ROYA_UNI = 'UNSTAKE_DETAILS_ROYA_UNI';
export const UNSTAKE_SEND_ROYA_UNI = 'UNSTAKE_SEND_ROYA_UNI';
export const UNSTAKE_SUCCESS_ROYA_UNI = 'UNSTAKE_SUCCESS_ROYA_UNI';
export const ESTIMATED_DAILY_REWARDS_ROYA_UNI = 'ESTIMATED_DAILY_REWARDS_ROYA_UNI';

export const GET_ROYA_UNI_BALANCE = 'GET_ROYA_UNI_BALANCE';
export const GET_ROYA_UNI_PRICE = 'GET_ROYA_UNI_PRICE';

export const WITHDRAW_DETAILS_ROYA_UNI = 'WITHDRAW_DETAILS_ROYA_UNI';
export const WITHDRAW_SEND_ROYA_UNI = 'WITHDRAW_SEND_ROYA_UNI';
export const WITHDRAW_SUCCESS_ROYA_UNI = 'WITHDRAW_SUCCESS_ROYA_UNI';

// RAZE ETH UNI STAKING
export const STAKE_SETTINGS_RAZE_ETH_UNI = 'STAKE_SETTINGS_RAZE_ETH_UNI';
export const STAKE_DETAILS_RAZE_ETH_UNI = 'STAKE_DETAILS_RAZE_ETH_UNI';
export const STAKE_SEND_RAZE_ETH_UNI = 'STAKE_SEND_RAZE_ETH_UNI';
export const STAKE_SUCCESS_RAZE_ETH_UNI = 'STAKE_SUCCESS_RAZE_ETH_UNI';
export const STAKE_ERROR_RAZE_ETH_UNI = 'STAKE_ERROR_RAZE_ETH_UNI';
export const RESET_STAKE_RAZE_ETH_UNI = 'RESET_STAKE_RAZE_ETH_UNI';
export const STAKE_BALANCE_RAZE_ETH_UNI = 'STAKE_BALANCE_RAZE_ETH_UNI';
export const UNSTAKED_BALANCE_RAZE_ETH_UNI = 'UNSTAKED_BALANCE_RAZE_ETH_UNI';
export const UNSTAKE_TIME_RAZE_ETH_UNI = 'UNSTAKE_TIME_RAZE_ETH_UNI';
export const REWARD_EARNED_RAZE_ETH_UNI = 'REWARD_EARNED_RAZE_ETH_UNI';
export const REWARD_BALANCE_RAZE_ETH_UNI = 'REWARD_BALANCE_RAZE_ETH_UNI';
export const PERCENT_IN_OM_RAZE_ETH_UNI = 'PERCENT_IN_OM_RAZE_ETH_UNI';
export const PERCENT_FROM_RAZE_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_RAZE_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_RAZE_ETH_UNI = 'CLAIM_REWARDS_DETAILS_RAZE_ETH_UNI';
export const CLAIM_REWARDS_SEND_RAZE_ETH_UNI = 'CLAIM_REWARDS_SEND_RAZE_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_RAZE_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_RAZE_ETH_UNI';
export const UNSTAKE_SETTINGS_RAZE_ETH_UNI = 'UNSTAKE_SETTINGS_RAZE_ETH_UNI';
export const UNSTAKE_DETAILS_RAZE_ETH_UNI = 'UNSTAKE_DETAILS_RAZE_ETH_UNI';
export const UNSTAKE_SEND_RAZE_ETH_UNI = 'UNSTAKE_SEND_RAZE_ETH_UNI';
export const UNSTAKE_SUCCESS_RAZE_ETH_UNI = 'UNSTAKE_SUCCESS_RAZE_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_RAZE_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_RAZE_ETH_UNI';

export const GET_RAZE_ETH_UNI_BALANCE = 'GET_RAZE_ETH_UNI_BALANCE';
export const GET_RAZE_ETH_UNI_PRICE = 'GET_RAZE_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_RAZE_ETH_UNI = 'WITHDRAW_DETAILS_RAZE_ETH_UNI';
export const WITHDRAW_SEND_RAZE_ETH_UNI = 'WITHDRAW_SEND_RAZE_ETH_UNI';
export const WITHDRAW_SUCCESS_RAZE_ETH_UNI = 'WITHDRAW_SUCCESS_RAZE_ETH_UNI';

// BBANK ETH UNI STAKING
export const STAKE_SETTINGS_BBANK_ETH_UNI = 'STAKE_SETTINGS_BBANK_ETH_UNI';
export const STAKE_DETAILS_BBANK_ETH_UNI = 'STAKE_DETAILS_BBANK_ETH_UNI';
export const STAKE_SEND_BBANK_ETH_UNI = 'STAKE_SEND_BBANK_ETH_UNI';
export const STAKE_SUCCESS_BBANK_ETH_UNI = 'STAKE_SUCCESS_BBANK_ETH_UNI';
export const STAKE_ERROR_BBANK_ETH_UNI = 'STAKE_ERROR_BBANK_ETH_UNI';
export const RESET_STAKE_BBANK_ETH_UNI = 'RESET_STAKE_BBANK_ETH_UNI';
export const STAKE_BALANCE_BBANK_ETH_UNI = 'STAKE_BALANCE_BBANK_ETH_UNI';
export const UNSTAKED_BALANCE_BBANK_ETH_UNI = 'UNSTAKED_BALANCE_BBANK_ETH_UNI';
export const UNSTAKE_TIME_BBANK_ETH_UNI = 'UNSTAKE_TIME_BBANK_ETH_UNI';
export const REWARD_EARNED_BBANK_ETH_UNI = 'REWARD_EARNED_BBANK_ETH_UNI';
export const REWARD_BALANCE_BBANK_ETH_UNI = 'REWARD_BALANCE_BBANK_ETH_UNI';
export const PERCENT_IN_OM_BBANK_ETH_UNI = 'PERCENT_IN_OM_BBANK_ETH_UNI';
export const PERCENT_FROM_BBANK_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_BBANK_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BBANK_ETH_UNI = 'CLAIM_REWARDS_DETAILS_BBANK_ETH_UNI';
export const CLAIM_REWARDS_SEND_BBANK_ETH_UNI = 'CLAIM_REWARDS_SEND_BBANK_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_BBANK_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_BBANK_ETH_UNI';
export const UNSTAKE_SETTINGS_BBANK_ETH_UNI = 'UNSTAKE_SETTINGS_BBANK_ETH_UNI';
export const UNSTAKE_DETAILS_BBANK_ETH_UNI = 'UNSTAKE_DETAILS_BBANK_ETH_UNI';
export const UNSTAKE_SEND_BBANK_ETH_UNI = 'UNSTAKE_SEND_BBANK_ETH_UNI';
export const UNSTAKE_SUCCESS_BBANK_ETH_UNI = 'UNSTAKE_SUCCESS_BBANK_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_BBANK_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_BBANK_ETH_UNI';

export const GET_BBANK_ETH_UNI_BALANCE = 'GET_BBANK_ETH_UNI_BALANCE';
export const GET_BBANK_ETH_UNI_PRICE = 'GET_BBANK_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_BBANK_ETH_UNI = 'WITHDRAW_DETAILS_BBANK_ETH_UNI';
export const WITHDRAW_SEND_BBANK_ETH_UNI = 'WITHDRAW_SEND_BBANK_ETH_UNI';
export const WITHDRAW_SUCCESS_BBANK_ETH_UNI = 'WITHDRAW_SUCCESS_BBANK_ETH_UNI';

// BONDLY ETH UNI STAKING
export const STAKE_SETTINGS_BONDLY_ETH_UNI = 'STAKE_SETTINGS_BONDLY_ETH_UNI';
export const STAKE_DETAILS_BONDLY_ETH_UNI = 'STAKE_DETAILS_BONDLY_ETH_UNI';
export const STAKE_SEND_BONDLY_ETH_UNI = 'STAKE_SEND_BONDLY_ETH_UNI';
export const STAKE_SUCCESS_BONDLY_ETH_UNI = 'STAKE_SUCCESS_BONDLY_ETH_UNI';
export const STAKE_ERROR_BONDLY_ETH_UNI = 'STAKE_ERROR_BONDLY_ETH_UNI';
export const RESET_STAKE_BONDLY_ETH_UNI = 'RESET_STAKE_BONDLY_ETH_UNI';
export const STAKE_BALANCE_BONDLY_ETH_UNI = 'STAKE_BALANCE_BONDLY_ETH_UNI';
export const UNSTAKED_BALANCE_BONDLY_ETH_UNI = 'UNSTAKED_BALANCE_BONDLY_ETH_UNI';
export const UNSTAKE_TIME_BONDLY_ETH_UNI = 'UNSTAKE_TIME_BONDLY_ETH_UNI';
export const REWARD_EARNED_BONDLY_ETH_UNI = 'REWARD_EARNED_BONDLY_ETH_UNI';
export const REWARD_BALANCE_BONDLY_ETH_UNI = 'REWARD_BALANCE_BONDLY_ETH_UNI';
export const PERCENT_IN_OM_BONDLY_ETH_UNI = 'PERCENT_IN_OM_BONDLY_ETH_UNI';
export const PERCENT_FROM_BONDLY_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_BONDLY_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BONDLY_ETH_UNI = 'CLAIM_REWARDS_DETAILS_BONDLY_ETH_UNI';
export const CLAIM_REWARDS_SEND_BONDLY_ETH_UNI = 'CLAIM_REWARDS_SEND_BONDLY_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_BONDLY_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_BONDLY_ETH_UNI';
export const UNSTAKE_SETTINGS_BONDLY_ETH_UNI = 'UNSTAKE_SETTINGS_BONDLY_ETH_UNI';
export const UNSTAKE_DETAILS_BONDLY_ETH_UNI = 'UNSTAKE_DETAILS_BONDLY_ETH_UNI';
export const UNSTAKE_SEND_BONDLY_ETH_UNI = 'UNSTAKE_SEND_BONDLY_ETH_UNI';
export const UNSTAKE_SUCCESS_BONDLY_ETH_UNI = 'UNSTAKE_SUCCESS_BONDLY_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_BONDLY_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_BONDLY_ETH_UNI';

export const GET_BONDLY_ETH_UNI_BALANCE = 'GET_BONDLY_ETH_UNI_BALANCE';
export const GET_BONDLY_ETH_UNI_PRICE = 'GET_BONDLY_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_BONDLY_ETH_UNI = 'WITHDRAW_DETAILS_BONDLY_ETH_UNI';
export const WITHDRAW_SEND_BONDLY_ETH_UNI = 'WITHDRAW_SEND_BONDLY_ETH_UNI';
export const WITHDRAW_SUCCESS_BONDLY_ETH_UNI = 'WITHDRAW_SUCCESS_BONDLY_ETH_UNI';

// BCUBE ETH UNI STAKING
export const STAKE_SETTINGS_BCUBE_ETH_UNI = 'STAKE_SETTINGS_BCUBE_ETH_UNI';
export const STAKE_DETAILS_BCUBE_ETH_UNI = 'STAKE_DETAILS_BCUBE_ETH_UNI';
export const STAKE_SEND_BCUBE_ETH_UNI = 'STAKE_SEND_BCUBE_ETH_UNI';
export const STAKE_SUCCESS_BCUBE_ETH_UNI = 'STAKE_SUCCESS_BCUBE_ETH_UNI';
export const STAKE_ERROR_BCUBE_ETH_UNI = 'STAKE_ERROR_BCUBE_ETH_UNI';
export const RESET_STAKE_BCUBE_ETH_UNI = 'RESET_STAKE_BCUBE_ETH_UNI';
export const STAKE_BALANCE_BCUBE_ETH_UNI = 'STAKE_BALANCE_BCUBE_ETH_UNI';
export const UNSTAKED_BALANCE_BCUBE_ETH_UNI = 'UNSTAKED_BALANCE_BCUBE_ETH_UNI';
export const UNSTAKE_TIME_BCUBE_ETH_UNI = 'UNSTAKE_TIME_BCUBE_ETH_UNI';
export const REWARD_EARNED_BCUBE_ETH_UNI = 'REWARD_EARNED_BCUBE_ETH_UNI';
export const REWARD_BALANCE_BCUBE_ETH_UNI = 'REWARD_BALANCE_BCUBE_ETH_UNI';
export const PERCENT_IN_OM_BCUBE_ETH_UNI = 'PERCENT_IN_OM_BCUBE_ETH_UNI';
export const PERCENT_FROM_BCUBE_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_BCUBE_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BCUBE_ETH_UNI = 'CLAIM_REWARDS_DETAILS_BCUBE_ETH_UNI';
export const CLAIM_REWARDS_SEND_BCUBE_ETH_UNI = 'CLAIM_REWARDS_SEND_BCUBE_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_BCUBE_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_BCUBE_ETH_UNI';
export const UNSTAKE_SETTINGS_BCUBE_ETH_UNI = 'UNSTAKE_SETTINGS_BCUBE_ETH_UNI';
export const UNSTAKE_DETAILS_BCUBE_ETH_UNI = 'UNSTAKE_DETAILS_BCUBE_ETH_UNI';
export const UNSTAKE_SEND_BCUBE_ETH_UNI = 'UNSTAKE_SEND_BCUBE_ETH_UNI';
export const UNSTAKE_SUCCESS_BCUBE_ETH_UNI = 'UNSTAKE_SUCCESS_BCUBE_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_BCUBE_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_BCUBE_ETH_UNI';

export const GET_BCUBE_ETH_UNI_BALANCE = 'GET_BCUBE_ETH_UNI_BALANCE';
export const GET_BCUBE_ETH_UNI_PRICE = 'GET_BCUBE_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_BCUBE_ETH_UNI = 'WITHDRAW_DETAILS_BCUBE_ETH_UNI';
export const WITHDRAW_SEND_BCUBE_ETH_UNI = 'WITHDRAW_SEND_BCUBE_ETH_UNI';
export const WITHDRAW_SUCCESS_BCUBE_ETH_UNI = 'WITHDRAW_SUCCESS_BCUBE_ETH_UNI';

// ALPHA ETH UNI STAKING
export const STAKE_SETTINGS_ALPHA_ETH_UNI = 'STAKE_SETTINGS_ALPHA_ETH_UNI';
export const STAKE_DETAILS_ALPHA_ETH_UNI = 'STAKE_DETAILS_ALPHA_ETH_UNI';
export const STAKE_SEND_ALPHA_ETH_UNI = 'STAKE_SEND_ALPHA_ETH_UNI';
export const STAKE_SUCCESS_ALPHA_ETH_UNI = 'STAKE_SUCCESS_ALPHA_ETH_UNI';
export const STAKE_ERROR_ALPHA_ETH_UNI = 'STAKE_ERROR_ALPHA_ETH_UNI';
export const RESET_STAKE_ALPHA_ETH_UNI = 'RESET_STAKE_ALPHA_ETH_UNI';
export const STAKE_BALANCE_ALPHA_ETH_UNI = 'STAKE_BALANCE_ALPHA_ETH_UNI';
export const UNSTAKED_BALANCE_ALPHA_ETH_UNI = 'UNSTAKED_BALANCE_ALPHA_ETH_UNI';
export const UNSTAKE_TIME_ALPHA_ETH_UNI = 'UNSTAKE_TIME_ALPHA_ETH_UNI';
export const REWARD_EARNED_ALPHA_ETH_UNI = 'REWARD_EARNED_ALPHA_ETH_UNI';
export const REWARD_BALANCE_ALPHA_ETH_UNI = 'REWARD_BALANCE_ALPHA_ETH_UNI';
export const PERCENT_IN_OM_ALPHA_ETH_UNI = 'PERCENT_IN_OM_ALPHA_ETH_UNI';
export const PERCENT_FROM_ALPHA_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_ALPHA_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ALPHA_ETH_UNI = 'CLAIM_REWARDS_DETAILS_ALPHA_ETH_UNI';
export const CLAIM_REWARDS_SEND_ALPHA_ETH_UNI = 'CLAIM_REWARDS_SEND_ALPHA_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_ALPHA_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_ALPHA_ETH_UNI';
export const UNSTAKE_SETTINGS_ALPHA_ETH_UNI = 'UNSTAKE_SETTINGS_ALPHA_ETH_UNI';
export const UNSTAKE_DETAILS_ALPHA_ETH_UNI = 'UNSTAKE_DETAILS_ALPHA_ETH_UNI';
export const UNSTAKE_SEND_ALPHA_ETH_UNI = 'UNSTAKE_SEND_ALPHA_ETH_UNI';
export const UNSTAKE_SUCCESS_ALPHA_ETH_UNI = 'UNSTAKE_SUCCESS_ALPHA_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_ALPHA_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_ALPHA_ETH_UNI';

export const GET_ALPHA_ETH_UNI_BALANCE = 'GET_ALPHA_ETH_UNI_BALANCE';
export const GET_ALPHA_ETH_UNI_PRICE = 'GET_ALPHA_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_ALPHA_ETH_UNI = 'WITHDRAW_DETAILS_ALPHA_ETH_UNI';
export const WITHDRAW_SEND_ALPHA_ETH_UNI = 'WITHDRAW_SEND_ALPHA_ETH_UNI';
export const WITHDRAW_SUCCESS_ALPHA_ETH_UNI = 'WITHDRAW_SUCCESS_ALPHA_ETH_UNI';

// BITE ETH UNI
export const STAKE_SETTINGS_BITE_ETH_UNI = 'STAKE_SETTINGS_BITE_ETH_UNI';
export const STAKE_DETAILS_BITE_ETH_UNI = 'STAKE_DETAILS_BITE_ETH_UNI';
export const STAKE_SEND_BITE_ETH_UNI = 'STAKE_SEND_BITE_ETH_UNI';
export const STAKE_SUCCESS_BITE_ETH_UNI = 'STAKE_SUCCESS_BITE_ETH_UNI';
export const STAKE_ERROR_BITE_ETH_UNI = 'STAKE_ERROR_BITE_ETH_UNI';
export const RESET_STAKE_BITE_ETH_UNI = 'RESET_STAKE_BITE_ETH_UNI';
export const STAKE_BALANCE_BITE_ETH_UNI = 'STAKE_BALANCE_BITE_ETH_UNI';
export const UNSTAKED_BALANCE_BITE_ETH_UNI = 'UNSTAKED_BALANCE_BITE_ETH_UNI';
export const UNSTAKE_TIME_BITE_ETH_UNI = 'UNSTAKE_TIME_BITE_ETH_UNI';
export const REWARD_EARNED_BITE_ETH_UNI = 'REWARD_EARNED_BITE_ETH_UNI';
export const REWARD_BALANCE_BITE_ETH_UNI = 'REWARD_BALANCE_BITE_ETH_UNI';
export const PERCENT_IN_OM_BITE_ETH_UNI = 'PERCENT_IN_OM_BITE_ETH_UNI';
export const PERCENT_FROM_BITE_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_BITE_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BITE_ETH_UNI = 'CLAIM_REWARDS_DETAILS_BITE_ETH_UNI';
export const CLAIM_REWARDS_SEND_BITE_ETH_UNI = 'CLAIM_REWARDS_SEND_BITE_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_BITE_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_BITE_ETH_UNI';
export const UNSTAKE_SETTINGS_BITE_ETH_UNI = 'UNSTAKE_SETTINGS_BITE_ETH_UNI';
export const UNSTAKE_DETAILS_BITE_ETH_UNI = 'UNSTAKE_DETAILS_BITE_ETH_UNI';
export const UNSTAKE_SEND_BITE_ETH_UNI = 'UNSTAKE_SEND_BITE_ETH_UNI';
export const UNSTAKE_SUCCESS_BITE_ETH_UNI = 'UNSTAKE_SUCCESS_BITE_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_BITE_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_BITE_ETH_UNI';

export const GET_BITE_ETH_UNI_BALANCE = 'GET_BITE_ETH_UNI_BALANCE';
export const GET_BITE_ETH_UNI_PRICE = 'GET_BITE_ETH_UNI_PRICE';

export const WITHDRAW_DETAILS_BITE_ETH_UNI = 'WITHDRAW_DETAILS_BITE_ETH_UNI';
export const WITHDRAW_SEND_BITE_ETH_UNI = 'WITHDRAW_SEND_BITE_ETH_UNI';
export const WITHDRAW_SUCCESS_BITE_ETH_UNI = 'WITHDRAW_SUCCESS_BITE_ETH_UNI';

// ZENUSDT OM ETH
export const STAKE_SETTINGS_ZENUSDT_OM_ETH = 'STAKE_SETTINGS_ZENUSDT_OM_ETH';
export const STAKE_DETAILS_ZENUSDT_OM_ETH = 'STAKE_DETAILS_ZENUSDT_OM_ETH';
export const STAKE_SEND_ZENUSDT_OM_ETH = 'STAKE_SEND_ZENUSDT_OM_ETH';
export const STAKE_SUCCESS_ZENUSDT_OM_ETH = 'STAKE_SUCCESS_ZENUSDT_OM_ETH';
export const STAKE_ERROR_ZENUSDT_OM_ETH = 'STAKE_ERROR_ZENUSDT_OM_ETH';
export const RESET_STAKE_ZENUSDT_OM_ETH = 'RESET_STAKE_ZENUSDT_OM_ETH';
export const STAKE_BALANCE_ZENUSDT_OM_ETH = 'STAKE_BALANCE_ZENUSDT_OM_ETH';
export const UNSTAKED_BALANCE_ZENUSDT_OM_ETH = 'UNSTAKED_BALANCE_ZENUSDT_OM_ETH';
export const UNSTAKE_TIME_ZENUSDT_OM_ETH = 'UNSTAKE_TIME_ZENUSDT_OM_ETH';
export const REWARD_EARNED_ZENUSDT_OM_ETH = 'REWARD_EARNED_ZENUSDT_OM_ETH';
export const REWARD_BALANCE_ZENUSDT_OM_ETH = 'REWARD_BALANCE_ZENUSDT_OM_ETH';
export const PERCENT_IN_OM_ZENUSDT_OM_ETH = 'PERCENT_IN_OM_ZENUSDT_OM_ETH';
export const PERCENT_FROM_ZENUSDT_OM_ETH_SWAP_V2 = 'PERCENT_FROM_ZENUSDT_OM_ETH_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZENUSDT_OM_ETH = 'CLAIM_REWARDS_DETAILS_ZENUSDT_OM_ETH';
export const CLAIM_REWARDS_SEND_ZENUSDT_OM_ETH = 'CLAIM_REWARDS_SEND_ZENUSDT_OM_ETH';
export const CLAIM_REWARDS_SUCCESS_ZENUSDT_OM_ETH = 'CLAIM_REWARDS_SUCCESS_ZENUSDT_OM_ETH';
export const UNSTAKE_SETTINGS_ZENUSDT_OM_ETH = 'UNSTAKE_SETTINGS_ZENUSDT_OM_ETH';
export const UNSTAKE_DETAILS_ZENUSDT_OM_ETH = 'UNSTAKE_DETAILS_ZENUSDT_OM_ETH';
export const UNSTAKE_SEND_ZENUSDT_OM_ETH = 'UNSTAKE_SEND_ZENUSDT_OM_ETH';
export const UNSTAKE_SUCCESS_ZENUSDT_OM_ETH = 'UNSTAKE_SUCCESS_ZENUSDT_OM_ETH';
export const ESTIMATED_DAILY_REWARDS_ZENUSDT_OM_ETH = 'ESTIMATED_DAILY_REWARDS_ZENUSDT_OM_ETH';

export const GET_ZENUSDT_OM_ETH_BALANCE = 'GET_ZENUSDT_OM_ETH_BALANCE';
export const GET_ZENUSDT_OM_ETH_PRICE = 'GET_ZENUSDT_OM_ETH_PRICE';

export const WITHDRAW_DETAILS_ZENUSDT_OM_ETH = 'WITHDRAW_DETAILS_ZENUSDT_OM_ETH';
export const WITHDRAW_SEND_ZENUSDT_OM_ETH = 'WITHDRAW_SEND_ZENUSDT_OM_ETH';
export const WITHDRAW_SUCCESS_ZENUSDT_OM_ETH = 'WITHDRAW_SUCCESS_ZENUSDT_OM_ETH';

// ZENUSDC UNI ETH
export const STAKE_SETTINGS_ZENUSDC_UNI_ETH = 'STAKE_SETTINGS_ZENUSDC_UNI_ETH';
export const STAKE_DETAILS_ZENUSDC_UNI_ETH = 'STAKE_DETAILS_ZENUSDC_UNI_ETH';
export const STAKE_SEND_ZENUSDC_UNI_ETH = 'STAKE_SEND_ZENUSDC_UNI_ETH';
export const STAKE_SUCCESS_ZENUSDC_UNI_ETH = 'STAKE_SUCCESS_ZENUSDC_UNI_ETH';
export const STAKE_ERROR_ZENUSDC_UNI_ETH = 'STAKE_ERROR_ZENUSDC_UNI_ETH';
export const RESET_STAKE_ZENUSDC_UNI_ETH = 'RESET_STAKE_ZENUSDC_UNI_ETH';
export const STAKE_BALANCE_ZENUSDC_UNI_ETH = 'STAKE_BALANCE_ZENUSDC_UNI_ETH';
export const UNSTAKED_BALANCE_ZENUSDC_UNI_ETH = 'UNSTAKED_BALANCE_ZENUSDC_UNI_ETH';
export const UNSTAKE_TIME_ZENUSDC_UNI_ETH = 'UNSTAKE_TIME_ZENUSDC_UNI_ETH';
export const REWARD_EARNED_ZENUSDC_UNI_ETH = 'REWARD_EARNED_ZENUSDC_UNI_ETH';
export const REWARD_BALANCE_ZENUSDC_UNI_ETH = 'REWARD_BALANCE_ZENUSDC_UNI_ETH';
export const PERCENT_IN_OM_ZENUSDC_UNI_ETH = 'PERCENT_IN_OM_ZENUSDC_UNI_ETH';
export const PERCENT_FROM_ZENUSDC_UNI_ETH_SWAP_V2 = 'PERCENT_FROM_ZENUSDC_UNI_ETH_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZENUSDC_UNI_ETH = 'CLAIM_REWARDS_DETAILS_ZENUSDC_UNI_ETH';
export const CLAIM_REWARDS_SEND_ZENUSDC_UNI_ETH = 'CLAIM_REWARDS_SEND_ZENUSDC_UNI_ETH';
export const CLAIM_REWARDS_SUCCESS_ZENUSDC_UNI_ETH = 'CLAIM_REWARDS_SUCCESS_ZENUSDC_UNI_ETH';
export const UNSTAKE_SETTINGS_ZENUSDC_UNI_ETH = 'UNSTAKE_SETTINGS_ZENUSDC_UNI_ETH';
export const UNSTAKE_DETAILS_ZENUSDC_UNI_ETH = 'UNSTAKE_DETAILS_ZENUSDC_UNI_ETH';
export const UNSTAKE_SEND_ZENUSDC_UNI_ETH = 'UNSTAKE_SEND_ZENUSDC_UNI_ETH';
export const UNSTAKE_SUCCESS_ZENUSDC_UNI_ETH = 'UNSTAKE_SUCCESS_ZENUSDC_UNI_ETH';
export const ESTIMATED_DAILY_REWARDS_ZENUSDC_UNI_ETH = 'ESTIMATED_DAILY_REWARDS_ZENUSDC_UNI_ETH';

export const GET_ZENUSDC_UNI_ETH_BALANCE = 'GET_ZENUSDC_UNI_ETH_BALANCE';
export const GET_ZENUSDC_UNI_ETH_PRICE = 'GET_ZENUSDC_UNI_ETH_PRICE';

export const WITHDRAW_DETAILS_ZENUSDC_UNI_ETH = 'WITHDRAW_DETAILS_ZENUSDC_UNI_ETH';
export const WITHDRAW_SEND_ZENUSDC_UNI_ETH = 'WITHDRAW_SEND_ZENUSDC_UNI_ETH';
export const WITHDRAW_SUCCESS_ZENUSDC_UNI_ETH = 'WITHDRAW_SUCCESS_ZENUSDC_UNI_ETH';

// ZEN DAI UNI
export const STAKE_SETTINGS_ZEN_DAI_UNI = 'STAKE_SETTINGS_ZEN_DAI_UNI';
export const STAKE_DETAILS_ZEN_DAI_UNI = 'STAKE_DETAILS_ZEN_DAI_UNI';
export const STAKE_SEND_ZEN_DAI_UNI = 'STAKE_SEND_ZEN_DAI_UNI';
export const STAKE_SUCCESS_ZEN_DAI_UNI = 'STAKE_SUCCESS_ZEN_DAI_UNI';
export const STAKE_ERROR_ZEN_DAI_UNI = 'STAKE_ERROR_ZEN_DAI_UNI';
export const RESET_STAKE_ZEN_DAI_UNI = 'RESET_STAKE_ZEN_DAI_UNI';
export const STAKE_BALANCE_ZEN_DAI_UNI = 'STAKE_BALANCE_ZEN_DAI_UNI';
export const UNSTAKED_BALANCE_ZEN_DAI_UNI = 'UNSTAKED_BALANCE_ZEN_DAI_UNI';
export const UNSTAKE_TIME_ZEN_DAI_UNI = 'UNSTAKE_TIME_ZEN_DAI_UNI';
export const REWARD_EARNED_ZEN_DAI_UNI = 'REWARD_EARNED_ZEN_DAI_UNI';
export const REWARD_BALANCE_ZEN_DAI_UNI = 'REWARD_BALANCE_ZEN_DAI_UNI';
export const PERCENT_IN_OM_ZEN_DAI_UNI = 'PERCENT_IN_OM_ZEN_DAI_UNI';
export const PERCENT_FROM_ZEN_DAI_UNI_SWAP_V2 = 'PERCENT_FROM_ZEN_DAI_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZEN_DAI_UNI = 'CLAIM_REWARDS_DETAILS_ZEN_DAI_UNI';
export const CLAIM_REWARDS_SEND_ZEN_DAI_UNI = 'CLAIM_REWARDS_SEND_ZEN_DAI_UNI';
export const CLAIM_REWARDS_SUCCESS_ZEN_DAI_UNI = 'CLAIM_REWARDS_SUCCESS_ZEN_DAI_UNI';
export const UNSTAKE_SETTINGS_ZEN_DAI_UNI = 'UNSTAKE_SETTINGS_ZEN_DAI_UNI';
export const UNSTAKE_DETAILS_ZEN_DAI_UNI = 'UNSTAKE_DETAILS_ZEN_DAI_UNI';
export const UNSTAKE_SEND_ZEN_DAI_UNI = 'UNSTAKE_SEND_ZEN_DAI_UNI';
export const UNSTAKE_SUCCESS_ZEN_DAI_UNI = 'UNSTAKE_SUCCESS_ZEN_DAI_UNI';
export const ESTIMATED_DAILY_REWARDS_ZEN_DAI_UNI = 'ESTIMATED_DAILY_REWARDS_ZEN_DAI_UNI';
export const GET_ZEN_DAI_UNI_BALANCE = 'GET_ZEN_DAI_UNI_BALANCE';
export const GET_ZEN_DAI_UNI_PRICE = 'GET_ZEN_DAI_UNI_PRICE';
export const WITHDRAW_DETAILS_ZEN_DAI_UNI = 'WITHDRAW_DETAILS_ZEN_DAI_UNI';
export const WITHDRAW_SEND_ZEN_DAI_UNI = 'WITHDRAW_SEND_ZEN_DAI_UNI';
export const WITHDRAW_SUCCESS_ZEN_DAI_UNI = 'WITHDRAW_SUCCESS_ZEN_DAI_UNI';

// ZEN UST UNI
export const STAKE_SETTINGS_ZEN_UST_UNI = 'STAKE_SETTINGS_ZEN_UST_UNI';
export const STAKE_DETAILS_ZEN_UST_UNI = 'STAKE_DETAILS_ZEN_UST_UNI';
export const STAKE_SEND_ZEN_UST_UNI = 'STAKE_SEND_ZEN_UST_UNI';
export const STAKE_SUCCESS_ZEN_UST_UNI = 'STAKE_SUCCESS_ZEN_UST_UNI';
export const STAKE_ERROR_ZEN_UST_UNI = 'STAKE_ERROR_ZEN_UST_UNI';
export const RESET_STAKE_ZEN_UST_UNI = 'RESET_STAKE_ZEN_UST_UNI';
export const STAKE_BALANCE_ZEN_UST_UNI = 'STAKE_BALANCE_ZEN_UST_UNI';
export const UNSTAKED_BALANCE_ZEN_UST_UNI = 'UNSTAKED_BALANCE_ZEN_UST_UNI';
export const UNSTAKE_TIME_ZEN_UST_UNI = 'UNSTAKE_TIME_ZEN_UST_UNI';
export const REWARD_EARNED_ZEN_UST_UNI = 'REWARD_EARNED_ZEN_UST_UNI';
export const REWARD_BALANCE_ZEN_UST_UNI = 'REWARD_BALANCE_ZEN_UST_UNI';
export const PERCENT_IN_OM_ZEN_UST_UNI = 'PERCENT_IN_OM_ZEN_UST_UNI';
export const PERCENT_FROM_ZEN_UST_UNI_SWAP_V2 = 'PERCENT_FROM_ZEN_UST_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZEN_UST_UNI = 'CLAIM_REWARDS_DETAILS_ZEN_UST_UNI';
export const CLAIM_REWARDS_SEND_ZEN_UST_UNI = 'CLAIM_REWARDS_SEND_ZEN_UST_UNI';
export const CLAIM_REWARDS_SUCCESS_ZEN_UST_UNI = 'CLAIM_REWARDS_SUCCESS_ZEN_UST_UNI';
export const UNSTAKE_SETTINGS_ZEN_UST_UNI = 'UNSTAKE_SETTINGS_ZEN_UST_UNI';
export const UNSTAKE_DETAILS_ZEN_UST_UNI = 'UNSTAKE_DETAILS_ZEN_UST_UNI';
export const UNSTAKE_SEND_ZEN_UST_UNI = 'UNSTAKE_SEND_ZEN_UST_UNI';
export const UNSTAKE_SUCCESS_ZEN_UST_UNI = 'UNSTAKE_SUCCESS_ZEN_UST_UNI';
export const ESTIMATED_DAILY_REWARDS_ZEN_UST_UNI = 'ESTIMATED_DAILY_REWARDS_ZEN_UST_UNI';
export const GET_ZEN_UST_UNI_BALANCE = 'GET_ZEN_UST_UNI_BALANCE';
export const GET_ZEN_UST_UNI_PRICE = 'GET_ZEN_UST_UNI_PRICE';
export const WITHDRAW_DETAILS_ZEN_UST_UNI = 'WITHDRAW_DETAILS_ZEN_UST_UNI';
export const WITHDRAW_SEND_ZEN_UST_UNI = 'WITHDRAW_SEND_ZEN_UST_UNI';
export const WITHDRAW_SUCCESS_ZEN_UST_UNI = 'WITHDRAW_SUCCESS_ZEN_UST_UNI';

// ZEN WBTC UNI
export const STAKE_SETTINGS_ZEN_WBTC_UNI = 'STAKE_SETTINGS_ZEN_WBTC_UNI';
export const STAKE_DETAILS_ZEN_WBTC_UNI = 'STAKE_DETAILS_ZEN_WBTC_UNI';
export const STAKE_SEND_ZEN_WBTC_UNI = 'STAKE_SEND_ZEN_WBTC_UNI';
export const STAKE_SUCCESS_ZEN_WBTC_UNI = 'STAKE_SUCCESS_ZEN_WBTC_UNI';
export const STAKE_ERROR_ZEN_WBTC_UNI = 'STAKE_ERROR_ZEN_WBTC_UNI';
export const RESET_STAKE_ZEN_WBTC_UNI = 'RESET_STAKE_ZEN_WBTC_UNI';
export const STAKE_BALANCE_ZEN_WBTC_UNI = 'STAKE_BALANCE_ZEN_WBTC_UNI';
export const UNSTAKED_BALANCE_ZEN_WBTC_UNI = 'UNSTAKED_BALANCE_ZEN_WBTC_UNI';
export const UNSTAKE_TIME_ZEN_WBTC_UNI = 'UNSTAKE_TIME_ZEN_WBTC_UNI';
export const REWARD_EARNED_ZEN_WBTC_UNI = 'REWARD_EARNED_ZEN_WBTC_UNI';
export const REWARD_BALANCE_ZEN_WBTC_UNI = 'REWARD_BALANCE_ZEN_WBTC_UNI';
export const PERCENT_IN_OM_ZEN_WBTC_UNI = 'PERCENT_IN_OM_ZEN_WBTC_UNI';
export const PERCENT_FROM_ZEN_WBTC_UNI_SWAP_V2 = 'PERCENT_FROM_ZEN_WBTC_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZEN_WBTC_UNI = 'CLAIM_REWARDS_DETAILS_ZEN_WBTC_UNI';
export const CLAIM_REWARDS_SEND_ZEN_WBTC_UNI = 'CLAIM_REWARDS_SEND_ZEN_WBTC_UNI';
export const CLAIM_REWARDS_SUCCESS_ZEN_WBTC_UNI = 'CLAIM_REWARDS_SUCCESS_ZEN_WBTC_UNI';
export const UNSTAKE_SETTINGS_ZEN_WBTC_UNI = 'UNSTAKE_SETTINGS_ZEN_WBTC_UNI';
export const UNSTAKE_DETAILS_ZEN_WBTC_UNI = 'UNSTAKE_DETAILS_ZEN_WBTC_UNI';
export const UNSTAKE_SEND_ZEN_WBTC_UNI = 'UNSTAKE_SEND_ZEN_WBTC_UNI';
export const UNSTAKE_SUCCESS_ZEN_WBTC_UNI = 'UNSTAKE_SUCCESS_ZEN_WBTC_UNI';
export const ESTIMATED_DAILY_REWARDS_ZEN_WBTC_UNI = 'ESTIMATED_DAILY_REWARDS_ZEN_WBTC_UNI';
export const GET_ZEN_WBTC_UNI_BALANCE = 'GET_ZEN_WBTC_UNI_BALANCE';
export const GET_ZEN_WBTC_UNI_PRICE = 'GET_ZEN_WBTC_UNI_PRICE';
export const WITHDRAW_DETAILS_ZEN_WBTC_UNI = 'WITHDRAW_DETAILS_ZEN_WBTC_UNI';
export const WITHDRAW_SEND_ZEN_WBTC_UNI = 'WITHDRAW_SEND_ZEN_WBTC_UNI';
export const WITHDRAW_SUCCESS_ZEN_WBTC_UNI = 'WITHDRAW_SUCCESS_ZEN_WBTC_UNI';

// ZEN ETH UNI
export const STAKE_SETTINGS_ZEN_ETH_UNI = 'STAKE_SETTINGS_ZEN_ETH_UNI';
export const STAKE_DETAILS_ZEN_ETH_UNI = 'STAKE_DETAILS_ZEN_ETH_UNI';
export const STAKE_SEND_ZEN_ETH_UNI = 'STAKE_SEND_ZEN_ETH_UNI';
export const STAKE_SUCCESS_ZEN_ETH_UNI = 'STAKE_SUCCESS_ZEN_ETH_UNI';
export const STAKE_ERROR_ZEN_ETH_UNI = 'STAKE_ERROR_ZEN_ETH_UNI';
export const RESET_STAKE_ZEN_ETH_UNI = 'RESET_STAKE_ZEN_ETH_UNI';
export const STAKE_BALANCE_ZEN_ETH_UNI = 'STAKE_BALANCE_ZEN_ETH_UNI';
export const UNSTAKED_BALANCE_ZEN_ETH_UNI = 'UNSTAKED_BALANCE_ZEN_ETH_UNI';
export const UNSTAKE_TIME_ZEN_ETH_UNI = 'UNSTAKE_TIME_ZEN_ETH_UNI';
export const REWARD_EARNED_ZEN_ETH_UNI = 'REWARD_EARNED_ZEN_ETH_UNI';
export const REWARD_BALANCE_ZEN_ETH_UNI = 'REWARD_BALANCE_ZEN_ETH_UNI';
export const PERCENT_IN_OM_ZEN_ETH_UNI = 'PERCENT_IN_OM_ZEN_ETH_UNI';
export const PERCENT_FROM_ZEN_ETH_UNI_SWAP_V2 = 'PERCENT_FROM_ZEN_ETH_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_ZEN_ETH_UNI = 'CLAIM_REWARDS_DETAILS_ZEN_ETH_UNI';
export const CLAIM_REWARDS_SEND_ZEN_ETH_UNI = 'CLAIM_REWARDS_SEND_ZEN_ETH_UNI';
export const CLAIM_REWARDS_SUCCESS_ZEN_ETH_UNI = 'CLAIM_REWARDS_SUCCESS_ZEN_ETH_UNI';
export const UNSTAKE_SETTINGS_ZEN_ETH_UNI = 'UNSTAKE_SETTINGS_ZEN_ETH_UNI';
export const UNSTAKE_DETAILS_ZEN_ETH_UNI = 'UNSTAKE_DETAILS_ZEN_ETH_UNI';
export const UNSTAKE_SEND_ZEN_ETH_UNI = 'UNSTAKE_SEND_ZEN_ETH_UNI';
export const UNSTAKE_SUCCESS_ZEN_ETH_UNI = 'UNSTAKE_SUCCESS_ZEN_ETH_UNI';
export const ESTIMATED_DAILY_REWARDS_ZEN_ETH_UNI = 'ESTIMATED_DAILY_REWARDS_ZEN_ETH_UNI';
export const GET_ZEN_ETH_UNI_BALANCE = 'GET_ZEN_ETH_UNI_BALANCE';
export const GET_ZEN_ETH_UNI_PRICE = 'GET_ZEN_ETH_UNI_PRICE';
export const WITHDRAW_DETAILS_ZEN_ETH_UNI = 'WITHDRAW_DETAILS_ZEN_ETH_UNI';
export const WITHDRAW_SEND_ZEN_ETH_UNI = 'WITHDRAW_SEND_ZEN_ETH_UNI';
export const WITHDRAW_SUCCESS_ZEN_ETH_UNI = 'WITHDRAW_SUCCESS_ZEN_ETH_UNI';

// STFI WBNB UNI
export const STAKE_SETTINGS_STFI_WBNB_UNI = 'STAKE_SETTINGS_STFI_WBNB_UNI';
export const STAKE_DETAILS_STFI_WBNB_UNI = 'STAKE_DETAILS_STFI_WBNB_UNI';
export const STAKE_SEND_STFI_WBNB_UNI = 'STAKE_SEND_STFI_WBNB_UNI';
export const STAKE_SUCCESS_STFI_WBNB_UNI = 'STAKE_SUCCESS_STFI_WBNB_UNI';
export const STAKE_ERROR_STFI_WBNB_UNI = 'STAKE_ERROR_STFI_WBNB_UNI';
export const RESET_STAKE_STFI_WBNB_UNI = 'RESET_STAKE_STFI_WBNB_UNI';
export const STAKE_BALANCE_STFI_WBNB_UNI = 'STAKE_BALANCE_STFI_WBNB_UNI';
export const UNSTAKED_BALANCE_STFI_WBNB_UNI = 'UNSTAKED_BALANCE_STFI_WBNB_UNI';
export const UNSTAKE_TIME_STFI_WBNB_UNI = 'UNSTAKE_TIME_STFI_WBNB_UNI';
export const REWARD_EARNED_STFI_WBNB_UNI = 'REWARD_EARNED_STFI_WBNB_UNI';
export const REWARD_BALANCE_STFI_WBNB_UNI = 'REWARD_BALANCE_STFI_WBNB_UNI';
export const PERCENT_IN_OM_STFI_WBNB_UNI = 'PERCENT_IN_OM_STFI_WBNB_UNI';
export const PERCENT_FROM_STFI_WBNB_UNI_SWAP_V2 = 'PERCENT_FROM_STFI_WBNB_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_STFI_WBNB_UNI = 'CLAIM_REWARDS_DETAILS_STFI_WBNB_UNI';
export const CLAIM_REWARDS_SEND_STFI_WBNB_UNI = 'CLAIM_REWARDS_SEND_STFI_WBNB_UNI';
export const CLAIM_REWARDS_SUCCESS_STFI_WBNB_UNI = 'CLAIM_REWARDS_SUCCESS_STFI_WBNB_UNI';
export const UNSTAKE_SETTINGS_STFI_WBNB_UNI = 'UNSTAKE_SETTINGS_STFI_WBNB_UNI';
export const UNSTAKE_DETAILS_STFI_WBNB_UNI = 'UNSTAKE_DETAILS_STFI_WBNB_UNI';
export const UNSTAKE_SEND_STFI_WBNB_UNI = 'UNSTAKE_SEND_STFI_WBNB_UNI';
export const UNSTAKE_SUCCESS_STFI_WBNB_UNI = 'UNSTAKE_SUCCESS_STFI_WBNB_UNI';
export const ESTIMATED_DAILY_REWARDS_STFI_WBNB_UNI = 'ESTIMATED_DAILY_REWARDS_STFI_WBNB_UNI';
export const GET_STFI_WBNB_UNI_BALANCE = 'GET_STFI_WBNB_UNI_BALANCE';
export const GET_STFI_WBNB_UNI_PRICE = 'GET_STFI_WBNB_UNI_PRICE';
export const WITHDRAW_DETAILS_STFI_WBNB_UNI = 'WITHDRAW_DETAILS_STFI_WBNB_UNI';
export const WITHDRAW_SEND_STFI_WBNB_UNI = 'WITHDRAW_SEND_STFI_WBNB_UNI';
export const WITHDRAW_SUCCESS_STFI_WBNB_UNI = 'WITHDRAW_SUCCESS_STFI_WBNB_UNI';

// L3P_WBNB_UNI
export const STAKE_SETTINGS_L3P_WBNB_UNI = 'STAKE_SETTINGS_L3P_WBNB_UNI';
export const STAKE_DETAILS_L3P_WBNB_UNI = 'STAKE_DETAILS_L3P_WBNB_UNI';
export const STAKE_SEND_L3P_WBNB_UNI = 'STAKE_SEND_L3P_WBNB_UNI';
export const STAKE_SUCCESS_L3P_WBNB_UNI = 'STAKE_SUCCESS_L3P_WBNB_UNI';
export const STAKE_ERROR_L3P_WBNB_UNI = 'STAKE_ERROR_L3P_WBNB_UNI';
export const RESET_STAKE_L3P_WBNB_UNI = 'RESET_STAKE_L3P_WBNB_UNI';
export const STAKE_BALANCE_L3P_WBNB_UNI = 'STAKE_BALANCE_L3P_WBNB_UNI';
export const UNSTAKED_BALANCE_L3P_WBNB_UNI = 'UNSTAKED_BALANCE_L3P_WBNB_UNI';
export const UNSTAKE_TIME_L3P_WBNB_UNI = 'UNSTAKE_TIME_L3P_WBNB_UNI';
export const REWARD_EARNED_L3P_WBNB_UNI = 'REWARD_EARNED_L3P_WBNB_UNI';
export const REWARD_BALANCE_L3P_WBNB_UNI = 'REWARD_BALANCE_L3P_WBNB_UNI';
export const PERCENT_IN_OM_L3P_WBNB_UNI = 'PERCENT_IN_OM_L3P_WBNB_UNI';
export const PERCENT_FROM_L3P_WBNB_UNI_SWAP_V2 = 'PERCENT_FROM_L3P_WBNB_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_L3P_WBNB_UNI = 'CLAIM_REWARDS_DETAILS_L3P_WBNB_UNI';
export const CLAIM_REWARDS_SEND_L3P_WBNB_UNI = 'CLAIM_REWARDS_SEND_L3P_WBNB_UNI';
export const CLAIM_REWARDS_SUCCESS_L3P_WBNB_UNI = 'CLAIM_REWARDS_SUCCESS_L3P_WBNB_UNI';
export const UNSTAKE_SETTINGS_L3P_WBNB_UNI = 'UNSTAKE_SETTINGS_L3P_WBNB_UNI';
export const UNSTAKE_DETAILS_L3P_WBNB_UNI = 'UNSTAKE_DETAILS_L3P_WBNB_UNI';
export const UNSTAKE_SEND_L3P_WBNB_UNI = 'UNSTAKE_SEND_L3P_WBNB_UNI';
export const UNSTAKE_SUCCESS_L3P_WBNB_UNI = 'UNSTAKE_SUCCESS_L3P_WBNB_UNI';
export const ESTIMATED_DAILY_REWARDS_L3P_WBNB_UNI = 'ESTIMATED_DAILY_REWARDS_L3P_WBNB_UNI';
export const GET_L3P_WBNB_UNI_BALANCE = 'GET_L3P_WBNB_UNI_BALANCE';
export const GET_L3P_WBNB_UNI_PRICE = 'GET_L3P_WBNB_UNI_PRICE';
export const WITHDRAW_DETAILS_L3P_WBNB_UNI = 'WITHDRAW_DETAILS_L3P_WBNB_UNI';
export const WITHDRAW_SEND_L3P_WBNB_UNI = 'WITHDRAW_SEND_L3P_WBNB_UNI';
export const WITHDRAW_SUCCESS_L3P_WBNB_UNI = 'WITHDRAW_SUCCESS_L3P_WBNB_UNI';

// GAMER_WBNB_UNI
export const STAKE_SETTINGS_GAMER_WBNB_UNI = 'STAKE_SETTINGS_GAMER_WBNB_UNI';
export const STAKE_DETAILS_GAMER_WBNB_UNI = 'STAKE_DETAILS_GAMER_WBNB_UNI';
export const STAKE_SEND_GAMER_WBNB_UNI = 'STAKE_SEND_GAMER_WBNB_UNI';
export const STAKE_SUCCESS_GAMER_WBNB_UNI = 'STAKE_SUCCESS_GAMER_WBNB_UNI';
export const STAKE_ERROR_GAMER_WBNB_UNI = 'STAKE_ERROR_GAMER_WBNB_UNI';
export const RESET_STAKE_GAMER_WBNB_UNI = 'RESET_STAKE_GAMER_WBNB_UNI';
export const STAKE_BALANCE_GAMER_WBNB_UNI = 'STAKE_BALANCE_GAMER_WBNB_UNI';
export const UNSTAKED_BALANCE_GAMER_WBNB_UNI = 'UNSTAKED_BALANCE_GAMER_WBNB_UNI';
export const UNSTAKE_TIME_GAMER_WBNB_UNI = 'UNSTAKE_TIME_GAMER_WBNB_UNI';
export const REWARD_EARNED_GAMER_WBNB_UNI = 'REWARD_EARNED_GAMER_WBNB_UNI';
export const REWARD_BALANCE_GAMER_WBNB_UNI = 'REWARD_BALANCE_GAMER_WBNB_UNI';
export const PERCENT_IN_OM_GAMER_WBNB_UNI = 'PERCENT_IN_OM_GAMER_WBNB_UNI';
export const PERCENT_FROM_GAMER_WBNB_UNI_SWAP_V2 = 'PERCENT_FROM_GAMER_WBNB_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_GAMER_WBNB_UNI = 'CLAIM_REWARDS_DETAILS_GAMER_WBNB_UNI';
export const CLAIM_REWARDS_SEND_GAMER_WBNB_UNI = 'CLAIM_REWARDS_SEND_GAMER_WBNB_UNI';
export const CLAIM_REWARDS_SUCCESS_GAMER_WBNB_UNI = 'CLAIM_REWARDS_SUCCESS_GAMER_WBNB_UNI';
export const UNSTAKE_SETTINGS_GAMER_WBNB_UNI = 'UNSTAKE_SETTINGS_GAMER_WBNB_UNI';
export const UNSTAKE_DETAILS_GAMER_WBNB_UNI = 'UNSTAKE_DETAILS_GAMER_WBNB_UNI';
export const UNSTAKE_SEND_GAMER_WBNB_UNI = 'UNSTAKE_SEND_GAMER_WBNB_UNI';
export const UNSTAKE_SUCCESS_GAMER_WBNB_UNI = 'UNSTAKE_SUCCESS_GAMER_WBNB_UNI';
export const ESTIMATED_DAILY_REWARDS_GAMER_WBNB_UNI = 'ESTIMATED_DAILY_REWARDS_GAMER_WBNB_UNI';
export const GET_GAMER_WBNB_UNI_BALANCE = 'GET_GAMER_WBNB_UNI_BALANCE';
export const GET_GAMER_WBNB_UNI_PRICE = 'GET_GAMER_WBNB_UNI_PRICE';
export const WITHDRAW_DETAILS_GAMER_WBNB_UNI = 'WITHDRAW_DETAILS_GAMER_WBNB_UNI';
export const WITHDRAW_SEND_GAMER_WBNB_UNI = 'WITHDRAW_SEND_GAMER_WBNB_UNI';
export const WITHDRAW_SUCCESS_GAMER_WBNB_UNI = 'WITHDRAW_SUCCESS_GAMER_WBNB_UNI';

// FACTR_WBNB_UNI
export const STAKE_SETTINGS_FACTR_WBNB_UNI = 'STAKE_SETTINGS_FACTR_WBNB_UNI';
export const STAKE_DETAILS_FACTR_WBNB_UNI = 'STAKE_DETAILS_FACTR_WBNB_UNI';
export const STAKE_SEND_FACTR_WBNB_UNI = 'STAKE_SEND_FACTR_WBNB_UNI';
export const STAKE_SUCCESS_FACTR_WBNB_UNI = 'STAKE_SUCCESS_FACTR_WBNB_UNI';
export const STAKE_ERROR_FACTR_WBNB_UNI = 'STAKE_ERROR_FACTR_WBNB_UNI';
export const RESET_STAKE_FACTR_WBNB_UNI = 'RESET_STAKE_FACTR_WBNB_UNI';
export const STAKE_BALANCE_FACTR_WBNB_UNI = 'STAKE_BALANCE_FACTR_WBNB_UNI';
export const UNSTAKED_BALANCE_FACTR_WBNB_UNI = 'UNSTAKED_BALANCE_FACTR_WBNB_UNI';
export const UNSTAKE_TIME_FACTR_WBNB_UNI = 'UNSTAKE_TIME_FACTR_WBNB_UNI';
export const REWARD_EARNED_FACTR_WBNB_UNI = 'REWARD_EARNED_FACTR_WBNB_UNI';
export const REWARD_BALANCE_FACTR_WBNB_UNI = 'REWARD_BALANCE_FACTR_WBNB_UNI';
export const PERCENT_IN_OM_FACTR_WBNB_UNI = 'PERCENT_IN_OM_FACTR_WBNB_UNI';
export const PERCENT_FROM_FACTR_WBNB_UNI_SWAP_V2 = 'PERCENT_FROM_FACTR_WBNB_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_FACTR_WBNB_UNI = 'CLAIM_REWARDS_DETAILS_FACTR_WBNB_UNI';
export const CLAIM_REWARDS_SEND_FACTR_WBNB_UNI = 'CLAIM_REWARDS_SEND_FACTR_WBNB_UNI';
export const CLAIM_REWARDS_SUCCESS_FACTR_WBNB_UNI = 'CLAIM_REWARDS_SUCCESS_FACTR_WBNB_UNI';
export const UNSTAKE_SETTINGS_FACTR_WBNB_UNI = 'UNSTAKE_SETTINGS_FACTR_WBNB_UNI';
export const UNSTAKE_DETAILS_FACTR_WBNB_UNI = 'UNSTAKE_DETAILS_FACTR_WBNB_UNI';
export const UNSTAKE_SEND_FACTR_WBNB_UNI = 'UNSTAKE_SEND_FACTR_WBNB_UNI';
export const UNSTAKE_SUCCESS_FACTR_WBNB_UNI = 'UNSTAKE_SUCCESS_FACTR_WBNB_UNI';
export const ESTIMATED_DAILY_REWARDS_FACTR_WBNB_UNI = 'ESTIMATED_DAILY_REWARDS_FACTR_WBNB_UNI';
export const GET_FACTR_WBNB_UNI_BALANCE = 'GET_FACTR_WBNB_UNI_BALANCE';
export const GET_FACTR_WBNB_UNI_PRICE = 'GET_FACTR_WBNB_UNI_PRICE';
export const WITHDRAW_DETAILS_FACTR_WBNB_UNI = 'WITHDRAW_DETAILS_FACTR_WBNB_UNI';
export const WITHDRAW_SEND_FACTR_WBNB_UNI = 'WITHDRAW_SEND_FACTR_WBNB_UNI';
export const WITHDRAW_SUCCESS_FACTR_WBNB_UNI = 'WITHDRAW_SUCCESS_FACTR_WBNB_UNI';
export const UNSTAKE_INFORMATION_FACTR_WBNB_UNI = 'UNSTAKE_INFORMATION_FACTR_WBNB_UNI';

// BITE ETH INTERVALS
export const STAKE_SETTINGS_BITE_ETH_INTERVALS = 'STAKE_SETTINGS_BITE_ETH_INTERVALS';
export const STAKE_DETAILS_BITE_ETH_INTERVALS = 'STAKE_DETAILS_BITE_ETH_INTERVALS';
export const STAKE_SEND_BITE_ETH_INTERVALS = 'STAKE_SEND_BITE_ETH_INTERVALS';
export const STAKE_SUCCESS_BITE_ETH_INTERVALS = 'STAKE_SUCCESS_BITE_ETH_INTERVALS';
export const STAKE_ERROR_BITE_ETH_INTERVALS = 'STAKE_ERROR_BITE_ETH_INTERVALS';
export const RESET_STAKE_BITE_ETH_INTERVALS = 'RESET_STAKE_BITE_ETH_INTERVALS';
export const STAKE_BALANCE_BITE_ETH_INTERVALS = 'STAKE_BALANCE_BITE_ETH_INTERVALS';
export const UNSTAKED_BALANCE_BITE_ETH_INTERVALS = 'UNSTAKED_BALANCE_BITE_ETH_INTERVALS';
export const UNSTAKE_TIME_BITE_ETH_INTERVALS = 'UNSTAKE_TIME_BITE_ETH_INTERVALS';
export const REWARD_EARNED_BITE_ETH_INTERVALS = 'REWARD_EARNED_BITE_ETH_INTERVALS';
export const REWARD_BALANCE_BITE_ETH_INTERVALS = 'REWARD_BALANCE_BITE_ETH_INTERVALS';
export const PERCENT_IN_OM_BITE_ETH_INTERVALS = 'PERCENT_IN_OM_BITE_ETH_INTERVALS';
export const PERCENT_FROM_BITE_ETH_INTERVALS_SWAP_V2 = 'PERCENT_FROM_BITE_ETH_INTERVALS_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BITE_ETH_INTERVALS = 'CLAIM_REWARDS_DETAILS_BITE_ETH_INTERVALS';
export const CLAIM_REWARDS_SEND_BITE_ETH_INTERVALS = 'CLAIM_REWARDS_SEND_BITE_ETH_INTERVALS';
export const CLAIM_REWARDS_SUCCESS_BITE_ETH_INTERVALS = 'CLAIM_REWARDS_SUCCESS_BITE_ETH_INTERVALS';
export const UNSTAKE_SETTINGS_BITE_ETH_INTERVALS = 'UNSTAKE_SETTINGS_BITE_ETH_INTERVALS';
export const UNSTAKE_INFORMATION_BITE_ETH_INTERVALS = 'UNSTAKE_INFORMATION_BITE_ETH_INTERVALS';
export const UNSTAKE_DETAILS_BITE_ETH_INTERVALS = 'UNSTAKE_DETAILS_BITE_ETH_INTERVALS';
export const UNSTAKE_SEND_BITE_ETH_INTERVALS = 'UNSTAKE_SEND_BITE_ETH_INTERVALS';
export const UNSTAKE_SUCCESS_BITE_ETH_INTERVALS = 'UNSTAKE_SUCCESS_BITE_ETH_INTERVALS';
export const ESTIMATED_DAILY_REWARDS_BITE_ETH_INTERVALS =
  'ESTIMATED_DAILY_REWARDS_BITE_ETH_INTERVALS';

export const GET_BITE_ETH_INTERVALS_BALANCE = 'GET_BITE_ETH_INTERVALS_BALANCE';
export const GET_BITE_ETH_INTERVALS_PRICE = 'GET_BITE_ETH_INTERVALS_PRICE';

export const WITHDRAW_DETAILS_BITE_ETH_INTERVALS = 'WITHDRAW_DETAILS_BITE_ETH_INTERVALS';
export const WITHDRAW_SEND_BITE_ETH_INTERVALS = 'WITHDRAW_SEND_BITE_ETH_INTERVALS';
export const WITHDRAW_SUCCESS_BITE_ETH_INTERVALS = 'WITHDRAW_SUCCESS_BITE_ETH_INTERVALS';

export const STAKE_IS_AVAILABLE_BITE_ETH_INTERVALS = 'STAKE_IS_AVAILABLE_BITE_ETH_INTERVALS';
export const STAKE_INTERVALS_DATA_BITE_ETH_INTERVALS = 'STAKE_INTERVALS_DATA_BITE_ETH_INTERVALS';

// BONDLY USDT UNI STAKING
export const STAKE_SETTINGS_BONDLY_USDT_UNI = 'STAKE_SETTINGS_BONDLY_USDT_UNI';
export const STAKE_DETAILS_BONDLY_USDT_UNI = 'STAKE_DETAILS_BONDLY_USDT_UNI';
export const STAKE_SEND_BONDLY_USDT_UNI = 'STAKE_SEND_BONDLY_USDT_UNI';
export const STAKE_SUCCESS_BONDLY_USDT_UNI = 'STAKE_SUCCESS_BONDLY_USDT_UNI';
export const STAKE_ERROR_BONDLY_USDT_UNI = 'STAKE_ERROR_BONDLY_USDT_UNI';
export const RESET_STAKE_BONDLY_USDT_UNI = 'RESET_STAKE_BONDLY_USDT_UNI';
export const STAKE_BALANCE_BONDLY_USDT_UNI = 'STAKE_BALANCE_BONDLY_USDT_UNI';
export const UNSTAKED_BALANCE_BONDLY_USDT_UNI = 'UNSTAKED_BALANCE_BONDLY_USDT_UNI';
export const UNSTAKE_TIME_BONDLY_USDT_UNI = 'UNSTAKE_TIME_BONDLY_USDT_UNI';
export const REWARD_EARNED_BONDLY_USDT_UNI = 'REWARD_EARNED_BONDLY_USDT_UNI';
export const REWARD_BALANCE_BONDLY_USDT_UNI = 'REWARD_BALANCE_BONDLY_USDT_UNI';
export const PERCENT_IN_OM_BONDLY_USDT_UNI = 'PERCENT_IN_OM_BONDLY_USDT_UNI';
export const PERCENT_FROM_BONDLY_USDT_UNI_SWAP_V2 = 'PERCENT_FROM_BONDLY_USDT_UNI_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BONDLY_USDT_UNI = 'CLAIM_REWARDS_DETAILS_BONDLY_USDT_UNI';
export const CLAIM_REWARDS_SEND_BONDLY_USDT_UNI = 'CLAIM_REWARDS_SEND_BONDLY_USDT_UNI';
export const CLAIM_REWARDS_SUCCESS_BONDLY_USDT_UNI = 'CLAIM_REWARDS_SUCCESS_BONDLY_USDT_UNI';
export const UNSTAKE_SETTINGS_BONDLY_USDT_UNI = 'UNSTAKE_SETTINGS_BONDLY_USDT_UNI';
export const UNSTAKE_DETAILS_BONDLY_USDT_UNI = 'UNSTAKE_DETAILS_BONDLY_USDT_UNI';
export const UNSTAKE_SEND_BONDLY_USDT_UNI = 'UNSTAKE_SEND_BONDLY_USDT_UNI';
export const UNSTAKE_SUCCESS_BONDLY_USDT_UNI = 'UNSTAKE_SUCCESS_BONDLY_USDT_UNI';
export const ESTIMATED_DAILY_REWARDS_BONDLY_USDT_UNI = 'ESTIMATED_DAILY_REWARDS_BONDLY_USDT_UNI';

export const GET_BONDLY_USDT_UNI_BALANCE = 'GET_BONDLY_USDT_UNI_BALANCE';
export const GET_BONDLY_USDT_UNI_PRICE = 'GET_BONDLY_USDT_UNI_PRICE';

export const WITHDRAW_DETAILS_BONDLY_USDT_UNI = 'WITHDRAW_DETAILS_BONDLY_USDT_UNI';
export const WITHDRAW_SEND_BONDLY_USDT_UNI = 'WITHDRAW_SEND_BONDLY_USDT_UNI';
export const WITHDRAW_SUCCESS_BONDLY_USDT_UNI = 'WITHDRAW_SUCCESS_BONDLY_USDT_UNI';

// BNB MIST STAKING
export const STAKE_SETTINGS_BNB_MIST = 'STAKE_SETTINGS_BNB_MIST';
export const STAKE_DETAILS_BNB_MIST = 'STAKE_DETAILS_BNB_MIST';
export const STAKE_SEND_BNB_MIST = 'STAKE_SEND_BNB_MIST';
export const STAKE_SUCCESS_BNB_MIST = 'STAKE_SUCCESS_BNB_MIST';
export const STAKE_ERROR_BNB_MIST = 'STAKE_ERROR_BNB_MIST';
export const RESET_STAKE_BNB_MIST = 'RESET_STAKE_BNB_MIST';
export const STAKE_BALANCE_BNB_MIST = 'STAKE_BALANCE_BNB_MIST';
export const UNSTAKED_BALANCE_BNB_MIST = 'UNSTAKED_BALANCE_BNB_MIST';
export const UNSTAKE_TIME_BNB_MIST = 'UNSTAKE_TIME_BNB_MIST';
export const REWARD_EARNED_BNB_MIST = 'REWARD_EARNED_BNB_MIST';
export const REWARD_BALANCE_BNB_MIST = 'REWARD_BALANCE_BNB_MIST';
export const PERCENT_IN_OM_BNB_MIST = 'PERCENT_IN_OM_BNB_MIST';
export const PERCENT_FROM_BNB_MIST_SWAP_V2 = 'PERCENT_FROM_BNB_MIST_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BNB_MIST = 'CLAIM_REWARDS_DETAILS_BNB_MIST';
export const CLAIM_REWARDS_SEND_BNB_MIST = 'CLAIM_REWARDS_SEND_BNB_MIST';
export const CLAIM_REWARDS_SUCCESS_BNB_MIST = 'CLAIM_REWARDS_SUCCESS_BNB_MIST';
export const UNSTAKE_SETTINGS_BNB_MIST = 'UNSTAKE_SETTINGS_BNB_MIST';
export const UNSTAKE_DETAILS_BNB_MIST = 'UNSTAKE_DETAILS_BNB_MIST';
export const UNSTAKE_SEND_BNB_MIST = 'UNSTAKE_SEND_BNB_MIST';
export const UNSTAKE_SUCCESS_BNB_MIST = 'UNSTAKE_SUCCESS_BNB_MIST';
export const ESTIMATED_DAILY_REWARDS_BNB_MIST = 'ESTIMATED_DAILY_REWARDS_BNB_MIST';

export const GET_BNB_MIST_BALANCE = 'GET_BNB_MIST_BALANCE';
export const GET_BNB_MIST_PRICE = 'GET_BNB_MIST_PRICE';

export const WITHDRAW_DETAILS_BNB_MIST = 'WITHDRAW_DETAILS_BNB_MIST';
export const WITHDRAW_SEND_BNB_MIST = 'WITHDRAW_SEND_BNB_MIST';
export const WITHDRAW_SUCCESS_BNB_MIST = 'WITHDRAW_SUCCESS_BNB_MIST';

// BNB CBD STAKING
export const STAKE_SETTINGS_BNB_CBD = 'STAKE_SETTINGS_BNB_CBD';
export const STAKE_DETAILS_BNB_CBD = 'STAKE_DETAILS_BNB_CBD';
export const STAKE_SEND_BNB_CBD = 'STAKE_SEND_BNB_CBD';
export const STAKE_SUCCESS_BNB_CBD = 'STAKE_SUCCESS_BNB_CBD';
export const STAKE_ERROR_BNB_CBD = 'STAKE_ERROR_BNB_CBD';
export const RESET_STAKE_BNB_CBD = 'RESET_STAKE_BNB_CBD';
export const STAKE_BALANCE_BNB_CBD = 'STAKE_BALANCE_BNB_CBD';
export const UNSTAKED_BALANCE_BNB_CBD = 'UNSTAKED_BALANCE_BNB_CBD';
export const UNSTAKE_TIME_BNB_CBD = 'UNSTAKE_TIME_BNB_CBD';
export const REWARD_EARNED_BNB_CBD = 'REWARD_EARNED_BNB_CBD';
export const REWARD_BALANCE_BNB_CBD = 'REWARD_BALANCE_BNB_CBD';
export const PERCENT_IN_OM_BNB_CBD = 'PERCENT_IN_OM_BNB_CBD';
export const PERCENT_FROM_BNB_CBD_SWAP_V2 = 'PERCENT_FROM_BNB_CBD_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BNB_CBD = 'CLAIM_REWARDS_DETAILS_BNB_CBD';
export const CLAIM_REWARDS_SEND_BNB_CBD = 'CLAIM_REWARDS_SEND_BNB_CBD';
export const CLAIM_REWARDS_SUCCESS_BNB_CBD = 'CLAIM_REWARDS_SUCCESS_BNB_CBD';
export const UNSTAKE_SETTINGS_BNB_CBD = 'UNSTAKE_SETTINGS_BNB_CBD';
export const UNSTAKE_DETAILS_BNB_CBD = 'UNSTAKE_DETAILS_BNB_CBD';
export const UNSTAKE_SEND_BNB_CBD = 'UNSTAKE_SEND_BNB_CBD';
export const UNSTAKE_SUCCESS_BNB_CBD = 'UNSTAKE_SUCCESS_BNB_CBD';
export const ESTIMATED_DAILY_REWARDS_BNB_CBD = 'ESTIMATED_DAILY_REWARDS_BNB_CBD';

export const GET_BNB_CBD_BALANCE = 'GET_BNB_CBD_BALANCE';
export const GET_BNB_CBD_PRICE = 'GET_BNB_CBD_PRICE';

export const WITHDRAW_DETAILS_BNB_CBD = 'WITHDRAW_DETAILS_BNB_CBD';
export const WITHDRAW_SEND_BNB_CBD = 'WITHDRAW_SEND_BNB_CBD';
export const WITHDRAW_SUCCESS_BNB_CBD = 'WITHDRAW_SUCCESS_BNB_CBD';

// BNB BBANK STAKING
export const STAKE_SETTINGS_BNB_BBANK = 'STAKE_SETTINGS_BNB_BBANK';
export const STAKE_DETAILS_BNB_BBANK = 'STAKE_DETAILS_BNB_BBANK';
export const STAKE_SEND_BNB_BBANK = 'STAKE_SEND_BNB_BBANK';
export const STAKE_SUCCESS_BNB_BBANK = 'STAKE_SUCCESS_BNB_BBANK';
export const STAKE_ERROR_BNB_BBANK = 'STAKE_ERROR_BNB_BBANK';
export const RESET_STAKE_BNB_BBANK = 'RESET_STAKE_BNB_BBANK';
export const STAKE_BALANCE_BNB_BBANK = 'STAKE_BALANCE_BNB_BBANK';
export const UNSTAKED_BALANCE_BNB_BBANK = 'UNSTAKED_BALANCE_BNB_BBANK';
export const UNSTAKE_TIME_BNB_BBANK = 'UNSTAKE_TIME_BNB_BBANK';
export const REWARD_EARNED_BNB_BBANK = 'REWARD_EARNED_BNB_BBANK';
export const REWARD_BALANCE_BNB_BBANK = 'REWARD_BALANCE_BNB_BBANK';
export const PERCENT_IN_OM_BNB_BBANK = 'PERCENT_IN_OM_BNB_BBANK';
export const PERCENT_FROM_BNB_BBANK_SWAP_V2 = 'PERCENT_FROM_BNB_BBANK_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BNB_BBANK = 'CLAIM_REWARDS_DETAILS_BNB_BBANK';
export const CLAIM_REWARDS_SEND_BNB_BBANK = 'CLAIM_REWARDS_SEND_BNB_BBANK';
export const CLAIM_REWARDS_SUCCESS_BNB_BBANK = 'CLAIM_REWARDS_SUCCESS_BNB_BBANK';
export const UNSTAKE_SETTINGS_BNB_BBANK = 'UNSTAKE_SETTINGS_BNB_BBANK';
export const UNSTAKE_DETAILS_BNB_BBANK = 'UNSTAKE_DETAILS_BNB_BBANK';
export const UNSTAKE_SEND_BNB_BBANK = 'UNSTAKE_SEND_BNB_BBANK';
export const UNSTAKE_SUCCESS_BNB_BBANK = 'UNSTAKE_SUCCESS_BNB_BBANK';
export const ESTIMATED_DAILY_REWARDS_BNB_BBANK = 'ESTIMATED_DAILY_REWARDS_BNB_BBANK';

export const GET_BNB_BBANK_BALANCE = 'GET_BNB_BBANK_BALANCE';
export const GET_BNB_BBANK_PRICE = 'GET_BNB_BBANK_PRICE';

export const WITHDRAW_DETAILS_BNB_BBANK = 'WITHDRAW_DETAILS_BNB_BBANK';
export const WITHDRAW_SEND_BNB_BBANK = 'WITHDRAW_SEND_BNB_BBANK';
export const WITHDRAW_SUCCESS_BNB_BBANK = 'WITHDRAW_SUCCESS_BNB_BBANK';

// BNB MLT STAKING
export const STAKE_SETTINGS_BNB_MLT = 'STAKE_SETTINGS_BNB_MLT';
export const STAKE_DETAILS_BNB_MLT = 'STAKE_DETAILS_BNB_MLT';
export const STAKE_SEND_BNB_MLT = 'STAKE_SEND_BNB_MLT';
export const STAKE_SUCCESS_BNB_MLT = 'STAKE_SUCCESS_BNB_MLT';
export const STAKE_ERROR_BNB_MLT = 'STAKE_ERROR_BNB_MLT';
export const RESET_STAKE_BNB_MLT = 'RESET_STAKE_BNB_MLT';
export const STAKE_BALANCE_BNB_MLT = 'STAKE_BALANCE_BNB_MLT';
export const UNSTAKED_BALANCE_BNB_MLT = 'UNSTAKED_BALANCE_BNB_MLT';
export const UNSTAKE_TIME_BNB_MLT = 'UNSTAKE_TIME_BNB_MLT';
export const REWARD_EARNED_BNB_MLT = 'REWARD_EARNED_BNB_MLT';
export const REWARD_BALANCE_BNB_MLT = 'REWARD_BALANCE_BNB_MLT';
export const PERCENT_IN_OM_BNB_MLT = 'PERCENT_IN_OM_BNB_MLT';
export const PERCENT_FROM_BNB_MLT_SWAP_V2 = 'PERCENT_FROM_BNB_MLT_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BNB_MLT = 'CLAIM_REWARDS_DETAILS_BNB_MLT';
export const CLAIM_REWARDS_SEND_BNB_MLT = 'CLAIM_REWARDS_SEND_BNB_MLT';
export const CLAIM_REWARDS_SUCCESS_BNB_MLT = 'CLAIM_REWARDS_SUCCESS_BNB_MLT';
export const UNSTAKE_SETTINGS_BNB_MLT = 'UNSTAKE_SETTINGS_BNB_MLT';
export const UNSTAKE_DETAILS_BNB_MLT = 'UNSTAKE_DETAILS_BNB_MLT';
export const UNSTAKE_SEND_BNB_MLT = 'UNSTAKE_SEND_BNB_MLT';
export const UNSTAKE_SUCCESS_BNB_MLT = 'UNSTAKE_SUCCESS_BNB_MLT';
export const ESTIMATED_DAILY_REWARDS_BNB_MLT = 'ESTIMATED_DAILY_REWARDS_BNB_MLT';

export const GET_BNB_MLT_BALANCE = 'GET_BNB_MLT_BALANCE';
export const GET_BNB_MLT_PRICE = 'GET_BNB_MLT_PRICE';

export const WITHDRAW_DETAILS_BNB_MLT = 'WITHDRAW_DETAILS_BNB_MLT';
export const WITHDRAW_SEND_BNB_MLT = 'WITHDRAW_SEND_BNB_MLT';
export const WITHDRAW_SUCCESS_BNB_MLT = 'WITHDRAW_SUCCESS_BNB_MLT';

// BNB ROSN STAKING
export const STAKE_SETTINGS_BNB_ROSN = 'STAKE_SETTINGS_BNB_ROSN';
export const STAKE_DETAILS_BNB_ROSN = 'STAKE_DETAILS_BNB_ROSN';
export const STAKE_SEND_BNB_ROSN = 'STAKE_SEND_BNB_ROSN';
export const STAKE_SUCCESS_BNB_ROSN = 'STAKE_SUCCESS_BNB_ROSN';
export const STAKE_ERROR_BNB_ROSN = 'STAKE_ERROR_BNB_ROSN';
export const RESET_STAKE_BNB_ROSN = 'RESET_STAKE_BNB_ROSN';
export const STAKE_BALANCE_BNB_ROSN = 'STAKE_BALANCE_BNB_ROSN';
export const UNSTAKED_BALANCE_BNB_ROSN = 'UNSTAKED_BALANCE_BNB_ROSN';
export const UNSTAKE_TIME_BNB_ROSN = 'UNSTAKE_TIME_BNB_ROSN';
export const REWARD_EARNED_BNB_ROSN = 'REWARD_EARNED_BNB_ROSN';
export const REWARD_BALANCE_BNB_ROSN = 'REWARD_BALANCE_BNB_ROSN';
export const PERCENT_IN_OM_BNB_ROSN = 'PERCENT_IN_OM_BNB_ROSN';
export const PERCENT_FROM_BNB_ROSN_SWAP_V2 = 'PERCENT_FROM_BNB_ROSN_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BNB_ROSN = 'CLAIM_REWARDS_DETAILS_BNB_ROSN';
export const CLAIM_REWARDS_SEND_BNB_ROSN = 'CLAIM_REWARDS_SEND_BNB_ROSN';
export const CLAIM_REWARDS_SUCCESS_BNB_ROSN = 'CLAIM_REWARDS_SUCCESS_BNB_ROSN';
export const UNSTAKE_SETTINGS_BNB_ROSN = 'UNSTAKE_SETTINGS_BNB_ROSN';
export const UNSTAKE_DETAILS_BNB_ROSN = 'UNSTAKE_DETAILS_BNB_ROSN';
export const UNSTAKE_SEND_BNB_ROSN = 'UNSTAKE_SEND_BNB_ROSN';
export const UNSTAKE_SUCCESS_BNB_ROSN = 'UNSTAKE_SUCCESS_BNB_ROSN';
export const ESTIMATED_DAILY_REWARDS_BNB_ROSN = 'ESTIMATED_DAILY_REWARDS_BNB_ROSN';

export const GET_BNB_ROSN_BALANCE = 'GET_BNB_ROSN_BALANCE';
export const GET_BNB_ROSN_PRICE = 'GET_BNB_ROSN_PRICE';

export const WITHDRAW_DETAILS_BNB_ROSN = 'WITHDRAW_DETAILS_BNB_ROSN';
export const WITHDRAW_SEND_BNB_ROSN = 'WITHDRAW_SEND_BNB_ROSN';
export const WITHDRAW_SUCCESS_BNB_ROSN = 'WITHDRAW_SUCCESS_BNB_ROSN';

// BONDLY WBNB STAKING
export const STAKE_SETTINGS_BONDLY_WBNB = 'STAKE_SETTINGS_BONDLY_WBNB';
export const STAKE_DETAILS_BONDLY_WBNB = 'STAKE_DETAILS_BONDLY_WBNB';
export const STAKE_SEND_BONDLY_WBNB = 'STAKE_SEND_BONDLY_WBNB';
export const STAKE_SUCCESS_BONDLY_WBNB = 'STAKE_SUCCESS_BONDLY_WBNB';
export const STAKE_ERROR_BONDLY_WBNB = 'STAKE_ERROR_BONDLY_WBNB';
export const RESET_STAKE_BONDLY_WBNB = 'RESET_STAKE_BONDLY_WBNB';
export const STAKE_BALANCE_BONDLY_WBNB = 'STAKE_BALANCE_BONDLY_WBNB';
export const UNSTAKED_BALANCE_BONDLY_WBNB = 'UNSTAKED_BALANCE_BONDLY_WBNB';
export const UNSTAKE_TIME_BONDLY_WBNB = 'UNSTAKE_TIME_BONDLY_WBNB';
export const REWARD_EARNED_BONDLY_WBNB = 'REWARD_EARNED_BONDLY_WBNB';
export const REWARD_BALANCE_BONDLY_WBNB = 'REWARD_BALANCE_BONDLY_WBNB';
export const PERCENT_IN_OM_BONDLY_WBNB = 'PERCENT_IN_OM_BONDLY_WBNB';
export const PERCENT_FROM_BONDLY_WBNB_SWAP_V2 = 'PERCENT_FROM_BONDLY_WBNB_SWAP_V2';
export const CLAIM_REWARDS_DETAILS_BONDLY_WBNB = 'CLAIM_REWARDS_DETAILS_BONDLY_WBNB';
export const CLAIM_REWARDS_SEND_BONDLY_WBNB = 'CLAIM_REWARDS_SEND_BONDLY_WBNB';
export const CLAIM_REWARDS_SUCCESS_BONDLY_WBNB = 'CLAIM_REWARDS_SUCCESS_BONDLY_WBNB';
export const UNSTAKE_SETTINGS_BONDLY_WBNB = 'UNSTAKE_SETTINGS_BONDLY_WBNB';
export const UNSTAKE_DETAILS_BONDLY_WBNB = 'UNSTAKE_DETAILS_BONDLY_WBNB';
export const UNSTAKE_SEND_BONDLY_WBNB = 'UNSTAKE_SEND_BONDLY_WBNB';
export const UNSTAKE_SUCCESS_BONDLY_WBNB = 'UNSTAKE_SUCCESS_BONDLY_WBNB';
export const ESTIMATED_DAILY_REWARDS_BONDLY_WBNB = 'ESTIMATED_DAILY_REWARDS_BONDLY_WBNB';

export const GET_BONDLY_WBNB_BALANCE = 'GET_BONDLY_WBNB_BALANCE';
export const GET_BONDLY_WBNB_PRICE = 'GET_BONDLY_WBNB_PRICE';

export const WITHDRAW_DETAILS_BONDLY_WBNB = 'WITHDRAW_DETAILS_BONDLY_WBNB';
export const WITHDRAW_SEND_BONDLY_WBNB = 'WITHDRAW_SEND_BONDLY_WBNB';
export const WITHDRAW_SUCCESS_BONDLY_WBNB = 'WITHDRAW_SUCCESS_BONDLY_WBNB';

// LABS UNI SIMPLE STAKING
export const STAKE_SETTINGS_LABS_UNI_SIMPLE = 'STAKE_SETTINGS_LABS_UNI_SIMPLE';
export const STAKE_DETAILS_LABS_UNI_SIMPLE = 'STAKE_DETAILS_LABS_UNI_SIMPLE';
export const STAKE_SEND_LABS_UNI_SIMPLE = 'STAKE_SEND_LABS_UNI_SIMPLE';
export const STAKE_SUCCESS_LABS_UNI_SIMPLE = 'STAKE_SUCCESS_LABS_UNI_SIMPLE';
export const STAKE_ERROR_LABS_UNI_SIMPLE = 'STAKE_ERROR_LABS_UNI_SIMPLE';
export const RESET_STAKE_LABS_UNI_SIMPLE = 'RESET_STAKE_LABS_UNI_SIMPLE';
export const STAKE_BALANCE_LABS_UNI_SIMPLE = 'STAKE_BALANCE_LABS_UNI_SIMPLE';
export const UNSTAKED_BALANCE_LABS_UNI_SIMPLE = 'UNSTAKED_BALANCE_LABS_UNI_SIMPLE';
export const UNSTAKE_TIME_LABS_UNI_SIMPLE = 'UNSTAKE_TIME_LABS_UNI_SIMPLE';
export const REWARD_EARNED_LABS_UNI_SIMPLE = 'REWARD_EARNED_LABS_UNI_SIMPLE';
export const REWARD_BALANCE_LABS_UNI_SIMPLE = 'REWARD_BALANCE_LABS_UNI_SIMPLE';
export const PERCENT_IN_OM_LABS_UNI_SIMPLE = 'PERCENT_IN_OM_LABS_UNI_SIMPLE';
export const PERCENT_FROM_LABS_UNI_SIMPLE_SWAP = 'PERCENT_FROM_LABS_UNI_SIMPLE_SWAP';
export const CLAIM_REWARDS_DETAILS_LABS_UNI_SIMPLE = 'CLAIM_REWARDS_DETAILS_LABS_UNI_SIMPLE';
export const CLAIM_REWARDS_SEND_LABS_UNI_SIMPLE = 'CLAIM_REWARDS_SEND_LABS_UNI_SIMPLE';
export const CLAIM_REWARDS_SUCCESS_LABS_UNI_SIMPLE = 'CLAIM_REWARDS_SUCCESS_LABS_UNI_SIMPLE';
export const UNSTAKE_SETTINGS_LABS_UNI_SIMPLE = 'UNSTAKE_SETTINGS_LABS_UNI_SIMPLE';
export const UNSTAKE_DETAILS_LABS_UNI_SIMPLE = 'UNSTAKE_DETAILS_LABS_UNI_SIMPLE';
export const UNSTAKE_SEND_LABS_UNI_SIMPLE = 'UNSTAKE_SEND_LABS_UNI_SIMPLE';
export const UNSTAKE_SUCCESS_LABS_UNI_SIMPLE = 'UNSTAKE_SUCCESS_LABS_UNI_SIMPLE';
export const ESTIMATED_DAILY_REWARDS_LABS_UNI_SIMPLE = 'ESTIMATED_DAILY_REWARDS_LABS_UNI_SIMPLE';

export const GET_LABS_UNI_SIMPLE_BALANCE = 'GET_LABS_UNI_SIMPLE_BALANCE';
export const GET_LABS_UNI_SIMPLE_PRICE = 'GET_LABS_UNI_SIMPLE_PRICE';

export const WITHDRAW_DETAILS_LABS_UNI_SIMPLE = 'WITHDRAW_DETAILS_LABS_UNI_SIMPLE';
export const WITHDRAW_SEND_LABS_UNI_SIMPLE = 'WITHDRAW_SEND_LABS_UNI_SIMPLE';
export const WITHDRAW_SUCCESS_LABS_UNI_SIMPLE = 'WITHDRAW_SUCCESS_LABS_UNI_SIMPLE';

// LABS UNI STAKING
export const STAKE_SETTINGS_LABS_UNI = 'STAKE_SETTINGS_LABS_UNI';
export const STAKE_DETAILS_LABS_UNI = 'STAKE_DETAILS_LABS_UNI';
export const STAKE_SEND_LABS_UNI = 'STAKE_SEND_LABS_UNI';
export const STAKE_SUCCESS_LABS_UNI = 'STAKE_SUCCESS_LABS_UNI';
export const STAKE_ERROR_LABS_UNI = 'STAKE_ERROR_LABS_UNI';
export const RESET_STAKE_LABS_UNI = 'RESET_STAKE_LABS_UNI';
export const STAKE_BALANCE_LABS_UNI = 'STAKE_BALANCE_LABS_UNI';
export const UNSTAKED_BALANCE_LABS_UNI = 'UNSTAKED_BALANCE_LABS_UNI';
export const UNSTAKE_TIME_LABS_UNI = 'UNSTAKE_TIME_LABS_UNI';
export const REWARD_EARNED_LABS_UNI = 'REWARD_EARNED_LABS_UNI';
export const REWARD_BALANCE_LABS_UNI = 'REWARD_BALANCE_LABS_UNI';
export const PERCENT_IN_OM_LABS_UNI = 'PERCENT_IN_OM_LABS_UNI';
export const PERCENT_FROM_LABS_UNI_SWAP = 'PERCENT_FROM_LABS_UNI_SWAP';
export const CLAIM_REWARDS_DETAILS_LABS_UNI = 'CLAIM_REWARDS_DETAILS_LABS_UNI';
export const CLAIM_REWARDS_SEND_LABS_UNI = 'CLAIM_REWARDS_SEND_LABS_UNI';
export const CLAIM_REWARDS_SUCCESS_LABS_UNI = 'CLAIM_REWARDS_SUCCESS_LABS_UNI';
export const UNSTAKE_SETTINGS_LABS_UNI = 'UNSTAKE_SETTINGS_LABS_UNI';
export const UNSTAKE_DETAILS_LABS_UNI = 'UNSTAKE_DETAILS_LABS_UNI';
export const UNSTAKE_SEND_LABS_UNI = 'UNSTAKE_SEND_LABS_UNI';
export const UNSTAKE_SUCCESS_LABS_UNI = 'UNSTAKE_SUCCESS_LABS_UNI';
export const ESTIMATED_DAILY_REWARDS_LABS_UNI = 'ESTIMATED_DAILY_REWARDS_LABS_UNI';

export const GET_LABS_UNI_BALANCE = 'GET_LABS_UNI_BALANCE';
export const GET_LABS_UNI_PRICE = 'GET_LABS_UNI_PRICE';

export const WITHDRAW_DETAILS_LABS_UNI = 'WITHDRAW_DETAILS_LABS_UNI';
export const WITHDRAW_SEND_LABS_UNI = 'WITHDRAW_SEND_LABS_UNI';
export const WITHDRAW_SUCCESS_LABS_UNI = 'WITHDRAW_SUCCESS_LABS_UNI';

export const STAKE_IS_AVAILABLE_LABS_UNI = 'STAKE_IS_AVAILABLE_LABS_UNI';
export const STAKE_INTERVALS_DATA_LABS_UNI = 'STAKE_INTERVALS_DATA_LABS_UNI';

// History
export const OM_POOL_STAKE_HISTORY = 'OM_POOL_STAKE_HISTORY';
export const OM_POOL_REWARD_HISTORY = 'OM_POOL_REWARD_HISTORY';
export const OM_POOL_UNSTAKE_HISTORY = 'OM_POOL_UNSTAKE_HISTORY';

// History
export const OM_V2_POOL_STAKE_HISTORY = 'OM_V2_POOL_STAKE_HISTORY';
export const OM_V2_POOL_UNSTAKE_HISTORY = 'OM_V2_POOL_UNSTAKE_HISTORY';

export const OM_V3_POOL_STAKE_HISTORY = 'OM_V3_POOL_STAKE_HISTORY';
export const OM_V3_POOL_UNSTAKE_HISTORY = 'OM_V3_POOL_UNSTAKE_HISTORY';

export const BNB_FINE_POOL_STAKE_HISTORY = 'BNB_FINE_POOL_STAKE_HISTORY';
export const BNB_FINE_POOL_UNSTAKE_HISTORY = 'BNB_FINE_POOL_UNSTAKE_HISTORY';

export const BNB_OM_POOL_STAKE_HISTORY = 'BNB_OM_POOL_STAKE_HISTORY';
export const BNB_OM_POOL_UNSTAKE_HISTORY = 'BNB_OM_POOL_UNSTAKE_HISTORY';

export const RAZE_POOL_STAKE_HISTORY = 'RAZE_POOL_STAKE_HISTORY';
export const RAZE_POOL_UNSTAKE_HISTORY = 'RAZE_POOL_UNSTAKE_HISTORY';

export const ROSN_POOL_STAKE_HISTORY = 'ROSN_POOL_STAKE_HISTORY';
export const ROSN_POOL_UNSTAKE_HISTORY = 'ROSN_POOL_UNSTAKE_HISTORY';

export const KYL_POOL_STAKE_HISTORY = 'KYL_POOL_STAKE_HISTORY';
export const KYL_POOL_UNSTAKE_HISTORY = 'KYL_POOL_UNSTAKE_HISTORY';

export const PKF_POOL_STAKE_HISTORY = 'PKF_POOL_STAKE_HISTORY';
export const PKF_POOL_UNSTAKE_HISTORY = 'PKF_POOL_UNSTAKE_HISTORY';

export const LABS_POOL_STAKE_HISTORY = 'LABS_POOL_STAKE_HISTORY';
export const LABS_POOL_UNSTAKE_HISTORY = 'LABS_POOL_UNSTAKE_HISTORY';

export const L3P_POOL_STAKE_HISTORY = 'L3P_POOL_STAKE_HISTORY';
export const L3P_POOL_UNSTAKE_HISTORY = 'L3P_POOL_UNSTAKE_HISTORY';

export const ROYA_POOL_STAKE_HISTORY = 'ROYA_POOL_STAKE_HISTORY';
export const ROYA_POOL_UNSTAKE_HISTORY = 'ROYA_POOL_UNSTAKE_HISTORY';

export const FXF_POOL_STAKE_HISTORY = 'FXF_POOL_STAKE_HISTORY';
export const FXF_POOL_UNSTAKE_HISTORY = 'FXF_POOL_UNSTAKE_HISTORY';

export const RFUEL_POOL_STAKE_HISTORY = 'RFUEL_POOL_STAKE_HISTORY';
export const RFUEL_POOL_REWARD_HISTORY = 'RFUEL_POOL_REWARD_HISTORY';
export const RFUEL_POOL_UNSTAKE_HISTORY = 'RFUEL_POOL_UNSTAKE_HISTORY';

// Mantra pool
export const MANTRA_POOL_UPDATE_ROUND = 'MANTRA_POOL_UPDATE_ROUND';
export const MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE =
  'MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE';
export const MANTRA_POOL_UPDATE_HISTORY_OFFSET = 'MANTRA_POOL_UPDATE_HISTORY_OFFSET';
export const MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE = 'MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE';
export const MANTRA_POOL_UPDATE_HISTORY_DATA = 'MANTRA_POOL_UPDATE_HISTORY_DATA';
export const MANTRA_POOL_CHANGE_HISTORY_LOADING = 'MANTRA_POOL_CHANGE_HISTORY_LOADING';
export const MANTRA_POOL_SET_TOTAL_ENTRIES = 'MANTRA_POOL_SET_TOTAL_ENTRIES';
export const MANTRA_POOL_SET_WITHDRAWAL_PERIOD = 'MANTRA_POOL_SET_WITHDRAWAL_PERIOD';
export const MANTRA_POOL_UPDATE_USER_PARTICIPANT = 'MANTRA_POOL_UPDATE_USER_PARTICIPANT';
export const MANTRA_POOL_SET_TOTAL_PARTICIPANTS = 'MANTRA_POOL_SET_TOTAL_PARTICIPANTS';

export const CLAIM_REWARDS_DETAILS_RFUEL = 'CLAIM_REWARDS_DETAILS_RFUEL';
export const CLAIM_REWARDS_SEND_RFUEL = 'CLAIM_REWARDS_SEND_RFUEL';
export const CLAIM_REWARDS_SUCCESS_RFUEL = 'CLAIM_REWARDS_SUCCESS_RFUEL';

// Lending
export const LENDING_NET_APY = 'LENDING_NET_APY';
export const LENDING_SUPPLY_BALANCE = 'LENDING_SUPPLY_BALANCE';
export const LENDING_WALLET_BALANCE = 'LENDING_WALLET_BALANCE';
export const LENDING_BORROW_BALANCE = 'LENDING_BORROW_BALANCE';
export const LENDING_TOKENS = 'LENDING_TOKENS';
export const LENDING_ACCOUNT_LIQUIDITY = 'LENDING_ACCOUNT_LIQUIDITY';
export const LENDING_BORROW_LIMIT = 'LENDING_BORROW_LIMIT';
export const LENDING_DISTRIBUTED_OM_REWARD = 'LENDING_DISTRIBUTED_OM_REWARD';

// Lending prices
export const LENDING_PRICES_UPDATE_LIST = 'LENDING_PRICES_UPDATE_ASSETS';
export const LENDING_PRICES_SET_FILTER = 'LENDING_PRICES_SET_FILTER';
export const LENDING_PRICES_SET_FILTERED_LIST = 'LENDING_PRICES_SET_FILTERED_LIST';
export const LENDING_PRICES_SET_ASSET_SELECTION = 'LENDING_PRICES_SET_ASSET_SELECTION';
export const LENDING_PRICES_SET_ASSETS = 'LENDING_PRICES_SET_ASSETS';

// Lending markets
export const LENDING_MARKETS_UPDATE_LIST = 'LENDING_MARKETS_UPDATE_ASSETS';
export const LENDING_MARKETS_SET_FILTER = 'LENDING_MARKETS_SET_FILTER';
export const LENDING_MARKETS_SET_FILTERED_LIST = 'LENDING_MARKETS_SET_FILTERED_LIST';
export const LENDING_MARKETS_SET_ASSETS = 'LENDING_MARKETS_SET_ASSETS';
export const LENDING_MARKETS_SET_SORT = 'LENDING_MARKETS_SET_SORT';
export const LENDING_MARKETS_SET_SORTED_LIST = 'LENDING_MARKETS_SET_SORTED_LIST';

// Lending liquidation
export const LENDING_LIQUIDATION_SET_ACCOUNT_LIST = 'LENDING_LIQUIDATION_SET_ACCOUNT_LIST';

//New staking

export const SELECTED_STAKING_POOL = 'SELECTED_STAKING_POOL';
