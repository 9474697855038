import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        d='M0 .001v29.69h21.7l-.06-4.015H4.017V4.015h25.676V0L0 .001z'
        fill='url(#prefix__eqx)'
      />
      <path
        d='M8.025 12.139h17.69v4.66h-17.7v4.111h17.7v8.782h3.975V8.027H8.025v4.112z'
        fill='url(#prefix__paint1_linear)'
      />
      <defs>
        <linearGradient
          id='prefix__eqx'
          x1={0}
          y1={0}
          x2={25.683}
          y2={25.684}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#12204E' />
          <stop offset={0.573} stopColor='#08515D' />
          <stop offset={1} stopColor='#42B294' />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear'
          x1={10.226}
          y1={5.839}
          x2={31.869}
          y2={27.514}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#12204E' />
          <stop offset={0.573} stopColor='#08515D' />
          <stop offset={1} stopColor='#42B294' />
        </linearGradient>
      </defs>
    </svg>
  );
}
