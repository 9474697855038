import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import Account from '../account';
import DotAccount from '../accountDot';
import KsmAccount from '../accountKsm';
import styles from './header.module.scss';
import config from '../../config/env';
import networkConstant from '../../constants/network.constant';
import BinanceLogo from '../../external/components/Icon/binance';
import PolygonLogo from '../../external/components/Icon/polygon';
import { BINANCE, POLYGON } from '../../constants/blockchain-constants';

const wrapStyles = {
  display: 'flex',

  width: '100%',
  paddingTop: '20px',
  paddingBottom: '20px',
};

const BscButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  color: #24191e;
  border-radius: 6px;
  height: 37px;
  padding: 13px;
  border: none;
  filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));
  margin-top: 0.5rem;

  @media screen and (min-width: 768px) {
    margin-left: 2rem;
    margin-top: 0;
  }

  @media screen and (min-width: 992px) {
  }

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    background-color: #919191;
    text-decoration: none;
    cursor: inherit;
  }
`;

const BscLogo = styled(BinanceLogo)`
  width: 24px;
  height: 24px;
  margin-left: 1rem;
`;

const PolyLogo = styled(PolygonLogo)`
  width: 24px;
  height: 24px;
  margin-left: 1rem;
`;

const getNetworkConnectParams = (isProd) => {
  return {
    [BINANCE]: [
      {
        chainId: isProd
          ? networkConstant.networkIdHex.bscMainnet
          : networkConstant.networkIdHex.bscTestnet,
        chainName: `Binance Smart Chain${isProd ? '' : ' (Testnet)'}`,
        nativeCurrency: {
          name: 'Binance Token',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        ...(isProd && { blockExplorerUrls: ['https://bscscan.com/'] }),
        ...(!isProd && { blockExplorerUrls: ['https://testnet.bscscan.com/'] }),
      },
    ],
    [POLYGON]: [
      {
        chainId: isProd
          ? networkConstant.networkIdHex.polyMainnet
          : networkConstant.networkIdHex.polyTestnet,
        chainName: `Polygon${isProd ? '' : ' (Testnet)'}`,
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com'],
        ...(isProd && { blockExplorerUrls: ['https://polygonscan.com/'] }),
        ...(!isProd && { blockExplorerUrls: ['https://mumbai.polygonscan.com/'] }),
      },
    ],
  };
};

const Header = ({ children }) => {
  const { networkId, networkType, auth } = useSelector((state) => state.account);

  const productionNetworks = [
    networkConstant.networkId.ethMainnet,
    networkConstant.networkId.bscMainnet,
    networkConstant.networkId.polyMainnet,
  ];

  const isProd = productionNetworks.includes(networkId);

  const isMetamaskInstalled = Boolean(window.ethereum && window.ethereum?.isMetaMask);

  async function handleNetworkChange(network) {
    if (auth === 'wallet_connect') {
      // update wallet connect to use bsc
    } else if (auth === 'metamask') {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: getNetworkConnectParams(isProd)[network][0].chainId }],
        });
      } catch (error) {
        if (error?.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: getNetworkConnectParams(isProd)[network],
            });
          } catch (error) {
            return;
          }
        }
      }
    }
  }

  return (
    <Grid container>
      <Grid className={styles.accountContainer} style={{ ...wrapStyles }} item>
        {children}
        <div className={styles.accountContainer}>
          <KsmAccount />
          <DotAccount />
          <Account />
          {isMetamaskInstalled && (
            <>
              <BscButton
                onClick={() => handleNetworkChange(BINANCE)}
                disabled={
                  networkType === networkConstant.networkType.binance ||
                  auth === 'wallet_connect_bsc'
                }
              >
                Change to BSC
                <BscLogo />
              </BscButton>
              <BscButton
                onClick={() => handleNetworkChange(POLYGON)}
                disabled={
                  networkType === networkConstant.networkType.polygon ||
                  auth === 'wallet_connect_polygon'
                }
              >
                Change to Polygon
                <PolyLogo />
              </BscButton>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
