import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Loader from '../../../../components/loader';
import config from '../../../../config/env';
import Icon from '../../../../external/components/Icon/Icon';
import { TextInput } from '../../../../external/components/Input/Input';
import { StatusLabel } from '../../../../external/components/StatusLabel/StatusLabel';
import Text from '../../../../external/components/Text/Text';
import { Store } from '../../../../store/store.types';
import cutCosmosAddress from '../../../../util/cutCosmosAddress';
import { useDelegatorPollCheckHook, useDelegatorSignCreateHook } from '../../hooks/delegator.hook';
import { StyledLabel } from '../Form/_FormComponents';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  otherWalletAddress: string;
  chainId: number;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function ApprovalScreen({ otherWalletAddress, setScreen, chainId }: Props) {
  enum VerificationStatus {
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
  }

  const [txVerificationStatus, setTxVerificationStatus] = useState<VerificationStatus>(
    VerificationStatus.PENDING,
  );

  const [pollStatus, delegatorLinks] = useDelegatorPollCheckHook(chainId);
  const [signCreateLink, loading, error, isSigned] = useDelegatorSignCreateHook(chainId);

  const [linkVerificationAmount, setLinkVerificationAmount] = useState(0);
  const [verified, setVerified] = useState(false);

  const { address: walletAddress } = useSelector(({ account }: Store) => account);

  useEffect(() => {
    const checkDelegatorStatus = async () => {
      await pollStatus(walletAddress, otherWalletAddress);
    };

    checkDelegatorStatus();

    let interval: NodeJS.Timeout;

    interval = setInterval(async () => {
      await checkDelegatorStatus();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (delegatorLinks.length > 0) {
      if (delegatorLinks[0]?.verification_amount)
        setLinkVerificationAmount(delegatorLinks[0].verification_amount);
      if (delegatorLinks[0]?.type === 'VERIFIED') {
        setVerified(true);
        setScreen(5);
      }
    }
  }, [delegatorLinks]);

  return (
    <StyledWrapper>
      {!delegatorLinks || !delegatorLinks.length || loading ? (
        <>
          <Loader />
          <br />
          <Text dark={true} as='span' size='md'>
            Processing request
          </Text>
        </>
      ) : (
        <>
          <Icon type='alert' width={200} />
          <Text dark={true}>
            Approval required to link address {cutCosmosAddress(otherWalletAddress)}
          </Text>
          <StyledLabel htmlFor='KSM'>
            <Text dark={true} as='span' size='lg'>
              Send exactly {linkVerificationAmount / 10 ** 6} ATOM to:
            </Text>
          </StyledLabel>
          <TextInput
            id='KSM'
            darkAlt={true}
            disabled={true}
            defaultValue={config.REACT_APP_COSMOS_RECIPIENT_ADDRESS}
            width='lg'
          ></TextInput>
          <br />
          <div>
            <QRCode size={120} value={config.REACT_APP_COSMOS_RECIPIENT_ADDRESS} />
          </div>
          <br />
          <StatusLabel loading={txVerificationStatus === VerificationStatus.PENDING}>
            {txVerificationStatus === VerificationStatus.PENDING
              ? 'Confirming verification transaction'
              : 'Awaiting verificaiton transaction'}
          </StatusLabel>
          <Text size={'xs'} dark={true}>
            Detection takes 1-2 minutes
          </Text>
        </>
      )}
    </StyledWrapper>
  );
}
