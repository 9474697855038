import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import Loader from '../../loader';
import Error from './error';
import Table from './table';

import { getCurrentAddressAndContract } from '../../../actions/mantra-pool-actions';
import * as modalActions from '../../../actions/modal-actions';

import closeIcon from '../../../assets/images/close-icon.svg';
import JoinPoolIcon from '../../../assets/mantra-pool/join-pool.svg';

import stylesModal from '../modal.module.scss';
import styles from './pool-round-modal.module.scss';

import { POOL_ROUND_MODAL } from '../../../constants/modal-constants';

export const SIZE_PER_PAGE = 8;

const PoolRoundModal = ({ show, roundIndex }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data, setData] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalEntries, setTotalEntries] = useState('0');
  const dark = useSelector((state) => state.basic.dark);

  const { currentContract } = dispatch(getCurrentAddressAndContract());

  const getData = async () => {
    setLoading(true);
    let items = [];
    const offset = (currentPage - 1) * SIZE_PER_PAGE;
    for (let i = offset; Math.min(participants.length, offset + SIZE_PER_PAGE) > i; i += 1) {
      items.push({
        number: i + 1,
        address: participants[i],
      });
    }

    items = await Promise.all(
      items.map(async (item) => {
        const userEntries = await currentContract
          .roundParticipant(roundIndex, item.address)
          .then((result) => result.entriesCount)
          .catch(() => '0');
        return { ...item, userEntries };
      }),
    );
    setLoading(false);
    return items;
  };

  useEffect(() => {
    (async () => {
      setData(await getData());
    })();
    // eslint-disable-next-line
  }, [participants, currentPage]);

  useEffect(() => {
    (async () => {
      try {
        const round = await currentContract.round(roundIndex);
        setTotalEntries(round.totalEntries);
        setParticipants(round.participants);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [roundIndex]);

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(stylesModal.dialog, stylesModal.poolResult, { [styles.dark]: dark })}
    >
      <div className={cn(stylesModal.container)}>
        <div className={stylesModal.header}>
          <div className={stylesModal.headerTitle}>
            <img className={stylesModal.headerIcon} src={JoinPoolIcon} alt='' />
            <span>POOL Round: {+roundIndex + 1}</span>
          </div>
          <button
            className={stylesModal.close}
            onClick={() => dispatch(modalActions.hideModal(POOL_ROUND_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={styles.body}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <Loader className={styles.loader} />
          ) : error ? (
            <Error text={error} />
          ) : (
            <Table
              currentPage={currentPage}
              sizePerPage={SIZE_PER_PAGE}
              totalDataSize={participants.length}
              totalEntries={totalEntries}
              onClick={(page) => setCurrentPage(page)}
              data={data}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

PoolRoundModal.propTypes = {
  show: PropTypes.bool,
  roundIndex: PropTypes.string,
};

PoolRoundModal.defaultProps = {
  show: false,
  roundIndex: '',
};

export default connectModal({ name: POOL_ROUND_MODAL })(PoolRoundModal);
