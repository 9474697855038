import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      id='prefix__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 1000 1000'
      xmlSpace='preserve'
      className={className}
      {...otherProps}
    >
      <style>{'.prefix__st0{fill:#f7cf0c}'}</style>
      <path
        className='prefix__st0'
        d='M448.5 89.4c-178.5 0-323.2 144.8-323.2 323.3 0 117.4 63.7 225.6 166.4 282.6L731.9 89.4H448.5zM545.3 912.9c178.5 0 323.2-144.8 323.2-323.3 0-117.4-63.7-225.6-166.4-282.6L261.7 912.9h283.6z'
      />
    </svg>
  );
}
