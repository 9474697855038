import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='9 8 29 30'
      className={className}
      {...otherProps}
    >
      <path
        fill='#F0B90B'
        stroke='#F0B90B'
        d='M23.5 10l3.345 3.425-8.423 8.423-3.344-3.345L23.5 10zM28.578 15.078l3.344 3.425-13.5 13.5-3.344-3.345 13.5-13.58zM13.345 20.155l3.344 3.426-3.344 3.344L10 23.581l3.345-3.426zM33.655 20.155L37 23.581l-13.5 13.5-3.345-3.345 13.5-13.58z'
      ></path>
    </svg>
  );
}
