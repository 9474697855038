import BN from 'bignumber.js';

import thegraphUniswapService from '../services/thegraph.uniswap.service';
import config from '../config/env';
import ZenWbtcUniStakingContractService from '../services/contracts/zen-wbtc-uni-staking-contract-services';
import { getZenDaiUniBalance } from './token-actions';
import { getTotalStakedBalance } from './staking-actions';
import { toBNScaled } from '../helpers/token-helper';
import { UINT_256_MAX_BN } from '../constants/blockchain-constants';
import Erc20TokenService from '../services/contracts/erc20-contract-service';
import web3Service from '../services/web3-service';

import { AMOUNT_BLOCKS_IN_DAY } from '../constants/blockchain-constants';
import {
  STAKE_DETAILS_ZEN_WBTC_UNI,
  STAKE_SEND_ZEN_WBTC_UNI,
  STAKE_SUCCESS_ZEN_WBTC_UNI,
  STAKE_ERROR_ZEN_WBTC_UNI,
  RESET_STAKE_ZEN_WBTC_UNI,
  STAKE_BALANCE_ZEN_WBTC_UNI,
  UNSTAKED_BALANCE_ZEN_WBTC_UNI,
  UNSTAKE_TIME_ZEN_WBTC_UNI,
  REWARD_EARNED_ZEN_WBTC_UNI,
  REWARD_BALANCE_ZEN_WBTC_UNI,
  CLAIM_REWARDS_DETAILS_ZEN_WBTC_UNI,
  CLAIM_REWARDS_SEND_ZEN_WBTC_UNI,
  CLAIM_REWARDS_SUCCESS_ZEN_WBTC_UNI,
  UNSTAKE_SETTINGS_ZEN_WBTC_UNI,
  UNSTAKE_DETAILS_ZEN_WBTC_UNI,
  UNSTAKE_SEND_ZEN_WBTC_UNI,
  UNSTAKE_SUCCESS_ZEN_WBTC_UNI,
  WITHDRAW_DETAILS_ZEN_WBTC_UNI,
  WITHDRAW_SEND_ZEN_WBTC_UNI,
  WITHDRAW_SUCCESS_ZEN_WBTC_UNI,
  PERCENT_IN_OM_ZEN_WBTC_UNI,
  PERCENT_FROM_ZEN_WBTC_UNI_SWAP_V2,
  ESTIMATED_DAILY_REWARDS_ZEN_WBTC_UNI,
  STAKE_SETTINGS_ZEN_WBTC_UNI,
  GET_ZEN_WBTC_UNI_PRICE,
} from './action-types';
import { getTokenByZenTokenSymbol } from '../helpers/function-helper';

export const updateBalances = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const [stakeBalance, rewardEarned, rewardBalance, unstakedTime] = await Promise.all([
    ZenWbtcUniStakingContractService.balanceOf(address),
    ZenWbtcUniStakingContractService.earnedOf(address),
    ZenWbtcUniStakingContractService.rewardOf(address),
    ZenWbtcUniStakingContractService.rewardUnlockingTime(address),
    dispatch(getZenDaiUniBalance()),
  ]);

  // TODO: make one dispatch
  dispatch({ type: STAKE_BALANCE_ZEN_WBTC_UNI, payload: { stakeBalance } });
  dispatch({ type: REWARD_EARNED_ZEN_WBTC_UNI, payload: { rewardEarned } });
  dispatch({ type: REWARD_BALANCE_ZEN_WBTC_UNI, payload: { rewardBalance } });
  dispatch({ type: UNSTAKE_TIME_ZEN_WBTC_UNI, payload: { unstakedTime } });
};

export const isCollateral = () => (dispatch, getState) => {
  return (
    getTokenByZenTokenSymbol(getState().lending.commonBorrowTokens, 'zenWBTC')?.collateral ??
    getTokenByZenTokenSymbol(getState().lending.borrowTokens, 'zenWBTC').collateral
  );
};

// STAKE

export const stakeSettings = () => async (dispatch) => {
  dispatch({ type: STAKE_SETTINGS_ZEN_WBTC_UNI });
};

export const stakeDetails = (formValues) => async (dispatch) => {
  dispatch({ type: STAKE_DETAILS_ZEN_WBTC_UNI, payload: formValues });
};

export const stakeSend = (formValues) => async (dispatch, getState) => {
  try {
    const { address } = getState().account;
    dispatch({ type: STAKE_SEND_ZEN_WBTC_UNI });

    const tokenService = new Erc20TokenService(config.REACT_APP_ZEN_WBTC_UNI_ERC20_ADDRESS);

    tokenService.contract.setProvider(window.ethereum);

    const allowance = await tokenService.allowance(
      address,
      config.REACT_APP_ZEN_WBTC_UNI_STAKING_ADDRESS,
    );

    const allowanceScaled = toBNScaled(allowance, 8);

    if (allowanceScaled < 1000000000000000000000) {
      await tokenService.approve(
        address,
        config.REACT_APP_ZEN_WBTC_UNI_STAKING_ADDRESS,
        UINT_256_MAX_BN,
      );
    }

    const { amount } = formValues;
    const transaction = await ZenWbtcUniStakingContractService.stake(address, amount);

    // COMPLETE
    dispatch({ type: STAKE_SUCCESS_ZEN_WBTC_UNI, payload: { id: transaction.transactionHash } });
    dispatch(updateBalances());
  } catch (e) {
    dispatch({ type: STAKE_ERROR_ZEN_WBTC_UNI, payload: { error: 'Stake failed' } });
  }
};

// CLAIM REWARDS

export const claimRewardsDetails = (formValues) => async (dispatch) => {
  dispatch({ type: CLAIM_REWARDS_DETAILS_ZEN_WBTC_UNI, payload: { type: formValues } });
};

export const claimRewardsSend = (amount) => async (dispatch, getState) => {
  const { address } = getState().account;

  dispatch({ type: CLAIM_REWARDS_SEND_ZEN_WBTC_UNI });

  try {
    const [rewardBalance, rewardEarned] = await Promise.all([
      ZenWbtcUniStakingContractService.rewardOf(address),
      ZenWbtcUniStakingContractService.earnedOf(address),
    ]);

    const transaction = await ZenWbtcUniStakingContractService.claim(
      address,
      new BN(rewardEarned).plus(rewardBalance).toString(10),
    );
    await dispatch(updateBalances());

    // COMPLETE
    dispatch({
      type: CLAIM_REWARDS_SUCCESS_ZEN_WBTC_UNI,
      payload: { id: transaction.transactionHash, amount: amount },
    });
  } catch (error) {
    dispatch({ type: STAKE_ERROR_ZEN_WBTC_UNI, payload: { error: 'Claim failed' } });
  }
};

// UNSTAKE

export const unstakeSettings = () => async (dispatch) => {
  dispatch({ type: UNSTAKE_SETTINGS_ZEN_WBTC_UNI });
};

export const unstakeDetails = (formValues) => async (dispatch) => {
  dispatch({ type: UNSTAKE_DETAILS_ZEN_WBTC_UNI, payload: formValues });
};

export const unstakeSend = (formValues) => async (dispatch, getState) => {
  const { address } = getState().account;

  dispatch({ type: UNSTAKE_SEND_ZEN_WBTC_UNI });

  const { amount } = formValues;

  try {
    const transaction = await ZenWbtcUniStakingContractService.unstake(address, amount);
    await dispatch(updateBalances());

    // COMPLETE
    dispatch({
      type: UNSTAKE_SUCCESS_ZEN_WBTC_UNI,
      payload: { id: transaction.transactionHash },
    });
  } catch (error) {
    dispatch({ type: STAKE_ERROR_ZEN_WBTC_UNI, payload: { error: 'Unstake failed' } });
  }
};

export const unstakeImmediatelySend = (formValues) => async (dispatch, getState) => {
  const { address } = getState().account;

  dispatch({ type: UNSTAKE_SEND_ZEN_WBTC_UNI });

  const { amount } = formValues;

  try {
    const transaction = await ZenWbtcUniStakingContractService.claim(address, amount);
    await dispatch(updateBalances());

    // COMPLETE
    dispatch({ type: UNSTAKE_SUCCESS_ZEN_WBTC_UNI, payload: { id: transaction.transactionHash } });
  } catch (error) {
    dispatch({ type: STAKE_ERROR_ZEN_WBTC_UNI, payload: { error: 'Unstake failed' } });
  }
};

// WITHDRAW UNSTAKED

export const withdrawUnstakedDetails = () => async (dispatch) => {
  dispatch({ type: WITHDRAW_DETAILS_ZEN_WBTC_UNI });
};

export const withdrawUnstakedSend = () => async (dispatch, getState) => {
  const { address } = getState().account;

  dispatch({ type: WITHDRAW_SEND_ZEN_WBTC_UNI });

  try {
    const unstakeBalance = await ZenWbtcUniStakingContractService.claimedOf(address);
    const transaction = await ZenWbtcUniStakingContractService.withdraw(address, unstakeBalance);
    await dispatch(updateBalances());

    // COMPLETE
    dispatch({
      type: WITHDRAW_SUCCESS_ZEN_WBTC_UNI,
      payload: { id: transaction.transactionHash },
    });
  } catch (error) {
    dispatch({ type: STAKE_ERROR_ZEN_WBTC_UNI, payload: { error: 'Unstake failed' } });
  }
};

export const stakeReset = () => async (dispatch) => {
  dispatch({ type: RESET_STAKE_ZEN_WBTC_UNI });
};

// VIEWS

export const getStakedBalance = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const stakeBalance = await ZenWbtcUniStakingContractService.balanceOf(address);
  dispatch({ type: STAKE_BALANCE_ZEN_WBTC_UNI, payload: { stakeBalance } });
  dispatch(getTotalStakedBalance());
};

export const getUnstakedBalance = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const unstakedBalance = await ZenWbtcUniStakingContractService.claimedOf(address);
  dispatch({ type: UNSTAKED_BALANCE_ZEN_WBTC_UNI, payload: { unstakedBalance } });
};

export const getUntakedTime = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const unstakedTime = await ZenWbtcUniStakingContractService.rewardUnlockingTime(address);
  dispatch({ type: UNSTAKE_TIME_ZEN_WBTC_UNI, payload: { unstakedTime } });
};

export const getRewardsEarned = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const rewardEarned = await ZenWbtcUniStakingContractService.earnedOf(address);
  dispatch({ type: REWARD_EARNED_ZEN_WBTC_UNI, payload: { rewardEarned } });
};

export const getRewardsBalance = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const rewardBalance = await ZenWbtcUniStakingContractService.rewardOf(address);
  dispatch({ type: REWARD_BALANCE_ZEN_WBTC_UNI, payload: { rewardBalance } });
};

export const getPercentInOM = () => async (dispatch, getState) => {
  try {
    const amountSecondsInYear = 31536000;

    const { OM: omUsdPrice, ZENWBTC: zenUsdPrice } = getState().basic.currency.USD;

    const secondReward = await ZenWbtcUniStakingContractService.perSecondReward();
    const uRoyaBalance = await ZenWbtcUniStakingContractService.totalSupply();

    if (+uRoyaBalance === 0) {
      return;
    }

    const yearPercent = new BN(secondReward)
      .times(omUsdPrice)
      .times(new BN(amountSecondsInYear))
      .times(100)
      .div(zenUsdPrice.times(uRoyaBalance).times(1e10));

    dispatch({
      type: PERCENT_IN_OM_ZEN_WBTC_UNI,
      payload: { percentInOM: yearPercent.toFixed(2, BN.ROUND_DOWN) },
    });
  } catch (e) {
    throw e;
  }
};

export const getPercentFromUniSwap = () => async (dispatch) => {
  const isProd = config.REACT_APP_NETWORK === 'mainnet';
  const contract = config.REACT_APP_ZEN_WBTC_UNI_ERC20_ADDRESS;
  const blockNumber = isProd ? await web3Service.web3.eth.getBlockNumber() : '12179425';
  const [uniswapV2CurrentBlock, uniswapV2LastDayBlock] = await Promise.all([
    thegraphUniswapService.getUniSwapV2(blockNumber, contract),
    thegraphUniswapService.getUniSwapV2(blockNumber - AMOUNT_BLOCKS_IN_DAY, contract),
  ]);

  if (!uniswapV2CurrentBlock || !uniswapV2LastDayBlock) {
    return;
  }

  const {
    reserveUSD: pollLiquidityUsd,
    volumeUSD: volumeUSDCurrent,
    totalSupply,
  } = uniswapV2CurrentBlock;
  const { volumeUSD: volumeUSDLastDay } = uniswapV2LastDayBlock;
  dispatch({ type: GET_ZEN_WBTC_UNI_PRICE, payload: pollLiquidityUsd / totalSupply });

  const tradeVolume24h = new BN(volumeUSDCurrent).minus(volumeUSDLastDay);
  const yearPercent = tradeVolume24h.times(0.003).times(365).div(pollLiquidityUsd).times(100);
  dispatch({
    type: PERCENT_FROM_ZEN_WBTC_UNI_SWAP_V2,
    payload: { percentFromUniSwap: yearPercent.toFixed(2, BN.ROUND_DOWN) },
  });
};

export const estimatedDailyReward = () => async (dispatch, getState) => {
  const { address } = getState().account;

  const balance = await ZenWbtcUniStakingContractService.balanceOf(address);
  const totalSupply = await ZenWbtcUniStakingContractService.totalSupply();
  const perSecondReward = await ZenWbtcUniStakingContractService.perSecondReward();
  const result = new BN(balance).div(totalSupply).times(perSecondReward).times(86400);

  dispatch({
    type: ESTIMATED_DAILY_REWARDS_ZEN_WBTC_UNI,
    payload: {
      estimatedDailyReward: result.toFixed(0, BN.ROUND_DOWN),
      perBlockReward: perSecondReward,
    },
  });
};

export const initData = () => async (dispatch) =>
  Promise.all([
    dispatch(estimatedDailyReward()),
    dispatch(getPercentInOM()),
    dispatch(getPercentFromUniSwap()),
    dispatch(getUntakedTime()),
    dispatch(getStakedBalance()),
    dispatch(getUnstakedBalance()),
    dispatch(getRewardsEarned()),
    dispatch(getRewardsBalance()),
    dispatch(getZenDaiUniBalance()),
  ]);
