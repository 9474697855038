import { web3Enable, web3FromSource, web3FromAddress } from '@polkadot/extension-dapp';
import BN from 'bignumber.js';
import axios from 'axios';

import config from '../config/env';
import { getMerkleProofFromJson } from '../helpers/merkle-tree-helper';
import { formatChainName } from '../util/formatter';
import {
  CHECK_IS_NOMINATOR,
  SET_SUCCES_DATA,
  SET_REQUESTED_REWARDS,
  SET_UNCLAIMED_AMOUNT,
  CHECK_IS_MAPPED,
  CHECK_IS_WAIT_FOR_NOMINATE,
  MAPPING_ETH_ADDRESS,
} from './action-types';
// import polkadotApiService from '../services/polkadot-api-service';
// import kusamaApiService from '../services/kusama-api-service';
import {
  CHAIN_NAMES,
  MERKLE_AIRDROP_NAMES,
  POLKADOT_UNAWAILABLE_CLAIM_PERIOD,
  KUSAMA_UNAWAILABLE_CLAIM_PERIOD,
} from '../constants/blockchain-constants';
// import { isJson } from './account-polkadot-actions';
import RewardDelegatingService from '../services/contracts/reward-delegating-contract';
import { base58Decode } from '@polkadot/util-crypto';
import LinkContract from '../services/contracts/reward-delegating-contract/link-contract';

import delegatorLinkApi from '../services/thegraph.delegator.service';

const contracts = {
  dotDelegatingContract: new RewardDelegatingService(
    config.REACT_APP_POLKADOT_REWARD_CONTRACT_ADDRESS,
    CHAIN_NAMES.POLKADOT.toLowerCase(),
  ),
  kusamaDelegatingContract: new RewardDelegatingService(
    config.REACT_APP_KUSAMA_REWARD_CONTRACT_ADDRESS,
    CHAIN_NAMES.KUSAMA.toLowerCase(),
  ),
  maticDelegatingContract: new RewardDelegatingService(
    config.REACT_APP_MATIC_MERKLE_AIRDROP_CONTRACT_ADDRESS,
    MERKLE_AIRDROP_NAMES.MATIC.toLowerCase(),
  ),
};

const linkContracts = {
  dotDelegatingContract: new LinkContract(config.REACT_APP_POLKADOT_LINK_CONTRACT_ADDRESS),
  kusamaDelegatingContract: new LinkContract(config.REACT_APP_KUSAMA_LINK_CONTRACT_ADDRESS),
};

export const getContractName = (chain) => {
  let contractName = '';
  switch (chain) {
    case CHAIN_NAMES.POLKADOT:
      contractName = 'dotDelegatingContract';
      break;
    case CHAIN_NAMES.KUSAMA:
      contractName = 'kusamaDelegatingContract';
      break;
    case MERKLE_AIRDROP_NAMES.MATIC:
      contractName = 'maticDelegatingContract';
      break;
    default:
      break;
  }
  return contractName;
};

export const storeEthSidechainPairLocally =
  (chain, sidechainAddress, ethAddress) => async (dispatch) => {
    localStorage.setItem(
      `sidechain-address-${sidechainAddress.toLowerCase()}`,
      JSON.stringify(ethAddress),
    );
    dispatch({ type: MAPPING_ETH_ADDRESS, payload: { sidechainAddress, ethAddress, chain } });
  };

const getSubstrateAddress = (chain) => (_dispatch, getState) => {
  if (chain === CHAIN_NAMES.POLKADOT) {
    return getState().polkadotAccount.substrateAddress;
  }
  if (chain === CHAIN_NAMES.KUSAMA) {
    return getState().kusamaAccount.substrateAddress;
  }
  return '';
};

const getEncodedAddress = (chain) => (_dispatch, getState) => {
  if (chain === CHAIN_NAMES.POLKADOT) {
    return getState().polkadotAccount.address;
  }
  if (chain === CHAIN_NAMES.KUSAMA) {
    return getState().kusamaAccount.address;
  }
  return '';
};

// const getStashAddress = (chain) => (_dispatch, getState) => {
//   if (chain === CHAIN_NAMES.POLKADOT) {
//     return getState().polkadotAccount.stash;
//   }
//   if (chain === CHAIN_NAMES.KUSAMA) {
//     return getState().kusamaAccount.stash;
//   }
//   return '';
// };

const getControllerAddress = (chain) => (_dispatch, getState) => {
  if (chain === CHAIN_NAMES.POLKADOT) {
    return getState().polkadotAccount.controller;
  }
  if (chain === CHAIN_NAMES.KUSAMA) {
    return getState().kusamaAccount.controller;
  }
  return '';
};

export const registerAddress = (chain) => async (dispatch, getState) => {
  const substrateAddress = dispatch(getSubstrateAddress(chain));
  const encodedAddress = dispatch(getEncodedAddress(chain));
  let isMappedFromState = false;
  let isMappedFromContract = false;
  if (chain === CHAIN_NAMES.POLKADOT) {
    isMappedFromState = getState().delegator.polkadot.isMapped;
  } else if (chain === CHAIN_NAMES.KUSAMA) {
    isMappedFromState = getState().delegator.kusama.isMapped;
  }
  if (isMappedFromState) {
    return;
  }
  const ethAddress = getState().account.address;
  const contractName = getContractName(chain);
  isMappedFromContract = await contracts[contractName].isAddressMapped(ethAddress, encodedAddress);
  if (isMappedFromContract.isMapped) {
    dispatch({ type: CHECK_IS_MAPPED, payload: { isMapped: true, chain } });
    return;
  }

  try {
    await web3Enable('MANTRA DAO');
    await web3FromAddress(substrateAddress);
  } catch (error) {
    throw error;
  }

  // to be able to retrieve the signer interface from this account
  // we can use web3FromSource which will return an InjectedExtension type
  const injector = await web3FromSource('polkadot-js');

  const signRaw = await injector?.signer?.signRaw;

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!signRaw) {
    // after making sure that signRaw is defined
    // we can use it to sign our message
    try {
      await signRaw({
        address: substrateAddress,
        data: ethAddress.toLowerCase(),
        type: 'bytes',
      }).then(async (sig) => {
        try {
          await linkContracts[contractName].mapAddress(ethAddress, encodedAddress, sig.signature);
          dispatch({ type: CHECK_IS_MAPPED, payload: { isMapped: true, chain } });
          dispatch(storeEthSidechainPairLocally(chain, encodedAddress, ethAddress));
        } catch (error) {
          throw error;
        }
      });
    } catch (error) {
      throw error;
    }
  }
};

export const checkAddressMapping = (chain) => async (dispatch, getState) => {
  const { address: ethAddress } = getState().account;
  const data = await delegatorLinkApi.getLinkedAddress(ethAddress, chain.toLowerCase());
  dispatch({
    type: CHECK_IS_MAPPED,
    payload: { isMapped: data ? true : false, info: data, chain },
  });
  return data;
};
// Address optional
export const checkIsNominator = (chain) => async (dispatch) => {
  try {
    let controller = dispatch(getControllerAddress(chain));
    let sidechainAddress = dispatch(getEncodedAddress(chain));

    let baseApi = '';
    let addresses = '';
    const nominatingData = [];

    switch (chain) {
      case CHAIN_NAMES.POLKADOT:
        baseApi = 'https://polkadot.api.subscan.io';
        addresses = `${config.REACT_APP_POLKADOT_VALIDATOR_ADDRESSES}`;
        break;
      case CHAIN_NAMES.KUSAMA:
        baseApi = 'https://kusama.api.subscan.io';
        addresses = `${config.REACT_APP_KUSAMA_VALIDATOR_ADDRESSES}`;
        break;
      default:
        baseApi = '';
        break;
    }

    const nodeAddresses = addresses.split(',').map((v) => {
      v = v.replace('[', '');
      v = v.replace(']', '');
      v = v.replace('"', '');
      v = v.replace('"', '');
      v = v.replace(' ', '');
      const publicKey = Buffer.from(base58Decode(v)).toString('hex');
      return publicKey; // .substring(2, publicKey.length - 4);
    });

    const accountLastExtrinsicRes = await fetch(`${baseApi}/api/scan/extrinsics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': '8f7a28f4096cdaebb56165a58b4dc669',
      },
      body: JSON.stringify({
        page: 0,
        row: 20,
        call: 'nominate',
        address: controller || sidechainAddress,
      }),
    });
    const accountLastExtrinsic = await accountLastExtrinsicRes.json();
    const accountLastExtrinsicParams =
      accountLastExtrinsic.data &&
      accountLastExtrinsic.data.extrinsics &&
      accountLastExtrinsic.data.extrinsics[0].params;
    if (!accountLastExtrinsicParams) {
      dispatch({
        type: CHECK_IS_NOMINATOR,
        payload: { isNominating: false, chain, nominatingData: [] },
      });
      return false;
    }
    const accountLastExtrinsicTargets = JSON.parse(accountLastExtrinsicParams).find(
      (el) => el.name === 'targets',
    );
    if (!accountLastExtrinsicTargets) {
      dispatch({
        type: CHECK_IS_NOMINATOR,
        payload: { isNominating: false, chain, nominatingData: [] },
      });
      return false;
    }

    for (let i = 0; i < nodeAddresses; i += 1) {
      const isNominateIn = accountLastExtrinsicTargets.value.find((el) => {
        const value = el.Id || el;
        return (
          value.toLowerCase() === nodeAddresses[i].toLowerCase() ||
          value.toLowerCase() === nodeAddresses[i].toLowerCase()
        );
      });
      if (isNominateIn) {
        nominatingData.push({
          isNominating: true,
          nodeAddress: nodeAddresses[i],
          chain,
        });
      }
    }

    dispatch({
      type: CHECK_IS_NOMINATOR,
      payload: { isNominating: !!nominatingData.length, chain, nominatingData },
    });
    return accountLastExtrinsic;
  } catch (error) {
    throw error;
  }
};

export const getUserReward = async (ethAddress, chainName) => {
  try {
    const file = await fetchRewardsFile(chainName);
    return file[ethAddress.toLowerCase()] ? file[ethAddress.toLowerCase()].amount : '0';
  } catch (e) {
    return '0';
  }
};

export const formatUserRequestAmount = (reqRewards) => {
  const array = new Array(...reqRewards);
  return array.map((reward) => ({
    amount: reward[0],
    timestamp: reward[1],
    isWithdrowedByNominator: reward[2],
    isFullWithdrawal: reward[3],
  }));
};

export const checkIsAwaitToClaim = (chain, extrinsics) => async (dispatch) => {
  let endDateToAdd;

  switch (chain) {
    case CHAIN_NAMES.POLKADOT:
      endDateToAdd = POLKADOT_UNAWAILABLE_CLAIM_PERIOD;
      break;
    case CHAIN_NAMES.KUSAMA:
      endDateToAdd = KUSAMA_UNAWAILABLE_CLAIM_PERIOD;
      break;
    default:
      endDateToAdd = POLKADOT_UNAWAILABLE_CLAIM_PERIOD;
  }

  const nominateOps = extrinsics;

  if (nominateOps.data && nominateOps.data.extrinsics && nominateOps.data.extrinsics.length === 1) {
    if (nominateOps.data.list) {
      const timestamp = nominateOps.data.list[0].block_timestamp * 1000;
      dispatch({
        type: CHECK_IS_WAIT_FOR_NOMINATE,
        payload: { chain, isReadyToClaim: false, endDate: timestamp + endDateToAdd },
      });
    } else {
      dispatch({
        type: CHECK_IS_WAIT_FOR_NOMINATE,
        payload: { chain, isReadyToClaim: true, endDate: 0 },
      });
    }
  } else {
    dispatch({
      type: CHECK_IS_WAIT_FOR_NOMINATE,
      payload: { chain, isReadyToClaim: true, endDate: 0 },
    });
  }
};

export const initData = (chain) => async (dispatch, getState) => {
  // Linked delegaotor rewards contract
  if (chain === CHAIN_NAMES.POLKADOT || chain === CHAIN_NAMES.KUSAMA) {
    const { address: ethAddress } = getState().account;
    const formattedChainName = formatChainName(chain);
    const contractName = getContractName(chain);
    await dispatch(checkAddressMapping(chain));
    const isNominator = await dispatch(checkIsNominator(chain));
    if (isNominator) {
      await dispatch(checkIsAwaitToClaim(chain, isNominator));
    }
    const requestedAmount = await contracts[contractName].getUserRequestedAmounts(ethAddress);

    const unclaimedAmount = await getUserReward(ethAddress, formattedChainName);
    const accountLastWithdrawTime = await contracts[contractName].getLastClaimTimestamp(ethAddress);
    const merleRootLastUpdateTime = await contracts[contractName].getLastMerkleUpdateTimestamp();

    dispatch({
      type: SET_REQUESTED_REWARDS,
      payload: { chain, requestedRewards: formatUserRequestAmount(requestedAmount) },
    });
    dispatch({
      type: SET_UNCLAIMED_AMOUNT,
      payload: {
        chain,
        unclaimedAmount: accountLastWithdrawTime > merleRootLastUpdateTime ? 0 : unclaimedAmount,
      },
    });
  } else if (chain === MERKLE_AIRDROP_NAMES.MATIC) {
    // Simple merkle airdrop rewards contract with linking functionality removed
    const contractName = getContractName(chain);
    const { address: ethAddress } = getState().account;
    const formattedChainName = formatChainName(chain);
    const requestedAmount = await contracts[contractName].getUserRequestedAmounts(ethAddress);

    const unclaimedAmount = await getUserReward(ethAddress, formattedChainName);
    const accountLastWithdrawTime = await contracts[contractName].getLastClaimTimestamp(ethAddress);
    const merleRootLastUpdateTime = await contracts[contractName].getLastMerkleUpdateTimestamp();

    dispatch({
      type: SET_REQUESTED_REWARDS,
      payload: { chain, requestedRewards: formatUserRequestAmount(requestedAmount) },
    });
    dispatch({
      type: SET_UNCLAIMED_AMOUNT,
      payload: {
        chain,
        unclaimedAmount: accountLastWithdrawTime > merleRootLastUpdateTime ? 0 : unclaimedAmount,
      },
    });
  }
};

const fetchRewardsFile = async (chainName) => {
  const { data: file } = await axios.get(
    `${config.REACT_APP_MANTRA_CDN_URL}/rewards-stats-${chainName}.json`,
  );

  return file;
};

export const sendRequestReward = (amount, isFullWithdraw, chain) => async (dispatch, getState) => {
  try {
    const { address: ethAddress } = getState().account;
    const formattedChainName = formatChainName(chain);
    const json = await fetchRewardsFile(formattedChainName);
    const proof = getMerkleProofFromJson(json, ethAddress);
    const contractName = getContractName(chain);
    const hash = await contracts[contractName].requestReward(
      proof,
      ethAddress,
      `0x${new BN(amount).toString(16).padStart(64, '0')}`,
      isFullWithdraw,
    );
    dispatch({ type: SET_SUCCES_DATA, payload: { hash, chain, successAmount: amount } });
  } catch (e) {
    throw e;
  }
};

export const sendReceiveReward = (index, chain) => async (dispatch, getState) => {
  const { address: ethAddress } = getState().account;
  const contractName = getContractName(chain);
  const hash = await contracts[contractName].receiveReward(index, ethAddress);
  dispatch({ type: SET_SUCCES_DATA, payload: { hash, chain } });
};
