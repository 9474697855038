import React from 'react';
import cn from 'classnames';
import Loader from '../../../loader';
import styles from '../join-pool-modal.module.scss';

const Confirm = () => (
  <div className={cn(styles.body, styles.sidePaddings)}>
    <Loader className={styles.loader} />
  </div>
);

export default Confirm;
