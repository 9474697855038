import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 139 79'
      className={className}
      {...otherProps}
    >
      <path
        fill='url(#paint0_linear_roya)'
        fillOpacity='0.4'
        fillRule='evenodd'
        d='M68.425 0l-9.174 12.684 9.174 12.684 9.015-12.684L68.425 0zM0 36.55l11.681 2.003 2.173 11.682-11.85-2.504L0 36.55zm19.03 28.538l-2.006-12.35 12.35 1.336 2.004 12.85-12.348-1.836zm56.575-24.533l-7.344-10.18-6.842 10.18 6.842 9.513 7.344-9.513zm-30.042 4.34l3.84-12.017 9.679 7.677-3.839 11.516-9.68-7.177zm-13.348-4.34l-1.168 12.517 11.179 5.174 1.001-12.517-11.012-5.174zm55.239-7.677l4.172 12.016-9.346 7.177-4.172-11.516 9.346-7.677zm18.692 20.194l-1.168-12.517-10.347 4.34v13.35l11.515-5.172zm20.027-16.189l11.85-1.335-1.836 11.85-11.182 1.001 1.168-11.516zm-6.675 16.19l-11.349 1.501-2.003 11.85 11.683-1.502 1.669-11.85zM101.473 70.93l-1.335 6.842-7.01 1.002 1.502-7.844h6.843zm-9.847-.835V62.92l-6.342 3.505.835 6.842 5.507-3.17zm-10.848-11.85l2.337 6.677-5.007 4.005-2.504-6.008 5.174-4.673zM72.6 62.92l-4.339-6.342-3.671 6.342 3.671 5.007 4.34-5.007zm-16.355-4.673l5.174 4.673-2.337 6.008-5.34-4.005 2.503-6.676zm-4.84 8.178l-6.676-3.505v7.176l6.676 3.171v-6.842zM36.05 70.93l6.175 1.168 1.001 6.676-7.176-1.002V70.93z'
        clipRule='evenodd'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_roya'
          x1='41.118'
          x2='76.102'
          y1='29.418'
          y2='75.567'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7B880'></stop>
          <stop offset='1' stopColor='#FFD099'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
