import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import cn from 'classnames';
import UserNav from '../userNav';
import * as modalActions from '../../actions/modal-actions';
import { ACCOUNT_MODAL, LOGIN_MODAL } from '../../constants/modal-constants';
import styles from './account.module.scss';
import { ButtonArrowIcon } from '../../constants/icon-constants';

const Account = () => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.account.address);
  const typeAuth = useSelector((state) => state.account.auth);

  const showAccoutModal = () => {
    dispatch(modalActions.showModal(ACCOUNT_MODAL));
  };

  const showLoginModal = () => {
    dispatch(modalActions.showModal(LOGIN_MODAL));
  };

  return address ? (
    <UserNav handleTrigger={() => showAccoutModal()} account={address} typeAuth={typeAuth} />
  ) : (
    <Button
      className={cn(styles.connectButton, styles.connectButtonHelpClass)}
      onClick={() => showLoginModal()}
    >
      <span className={styles.connectButtonBody}>
        Connect to a wallet
        {ButtonArrowIcon}
      </span>
    </Button>
  );
};

export default Account;
