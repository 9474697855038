import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChainType } from '../../../../external/components/Icon/Icon';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { DelegatorLink } from '../../../../services/delegator/delegator.types';
import { ErrorScreen } from './ErrorScreen';
import { WalletLinkScreen } from './Screen1';
import { CheckStatusScreen } from './Screen2';
import { WalletLinkBlockChain } from './Screen3';
import { ApprovalScreen } from './Screen4';
import { ApprovalScreen as EthApproval } from './Screen5';
import { LinkedScreen } from './Screen6';
import { SideBarHeader } from './_SidebarHeader';

interface SidebarBodyProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledSidebarWrapper = styled.div`
  padding: 1.5rem;
`;

export function NominateBody({ setShow }: SidebarBodyProps) {
  const [screen, setScreen] = useState(1);
  const [otherWalletAddress, setOtherWalletAddress] = useState('');
  const {
    walletAddress,
    delegatorData,
    selectedChainId: chainId,
  } = useSelector(({ account, delegators }: State) => ({
    walletAddress: account.address,
    delegatorData: (delegators as DelegatorDataType).delegatorData,
    selectedChainId: (delegators as DelegatorDataType).selectedChainId,
  }));

  if (!chainId) return <ErrorScreen setScreen={setScreen} setShow={setShow} />;

  const delegatorLinks = delegatorData.filter((del) => del.chainId === chainId)[0].delegatorLinks;

  const renderBody = () => {
    switch (screen) {
      case 1:
        return (
          <WalletLinkScreen
            setScreen={setScreen}
            delegatorLinks={delegatorLinks.filter((x) => x.address_eth === walletAddress)}
            setOtherWalletAddress={setOtherWalletAddress}
          />
        );
      case 2:
        return (
          <CheckStatusScreen
            setScreen={setScreen}
            delegatorLinks={delegatorLinks}
            walletAddress={walletAddress}
            otherWalletAddress={otherWalletAddress}
            chainId={chainId}
          />
        );
      case 3:
        return (
          <WalletLinkBlockChain
            setScreen={setScreen}
            otherWalletAddress={otherWalletAddress}
            chainId={chainId}
          />
        );
      case 4:
        return (
          <ApprovalScreen
            otherWalletAddress={otherWalletAddress}
            setScreen={setScreen}
            chainId={2}
          />
        );
      case 5:
        return (
          <EthApproval otherWalletAddress={otherWalletAddress} setScreen={setScreen} chainId={2} />
        );
      case 6:
        return (
          <LinkedScreen
            walletAddress={walletAddress}
            otherWalletAddress={otherWalletAddress}
            setShow={setShow}
          />
        );
      case 7:
        return <ErrorScreen setScreen={setScreen} setShow={setShow} />;
    }
  };

  return (
    <StyledSidebarWrapper>
      <SideBarHeader chain='ATOM' />

      {renderBody()}
    </StyledSidebarWrapper>
  );
}
