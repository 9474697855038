import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='70'
      height='70'
      viewBox='0 0 70 70'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M64.6324 59.4769C64.6324 59.4769 56.8631 46.1239 55.4695 43.729C57.0551 43.2453 58.7442 42.8181 59.446 41.6063C60.6156 39.5871 58.5336 36.2748 59.0803 34.1105C59.6435 31.8831 62.9679 29.9556 62.9679 27.6915C62.9679 25.4856 59.5309 22.9085 58.9674 20.6941C58.4169 18.5302 60.4927 15.2143 59.3192 13.1975C58.1458 11.1806 54.2374 11.3463 52.6285 9.79818C50.9731 8.205 50.9782 4.30699 48.9816 3.23933C46.9767 2.16768 43.7185 4.33348 41.4925 3.82045C39.2919 3.31329 37.3259 0 35.0273 0C32.6947 0 28.9845 3.7584 28.4026 3.89366C26.1778 4.41085 22.9154 2.25035 20.9127 3.32653C18.9179 4.39779 18.9302 8.29618 17.2778 9.89201C15.6716 11.443 11.763 11.2845 10.5932 13.3037C9.42353 15.3225 11.5055 18.6341 10.959 20.7995C10.411 22.9704 6.97077 25.17 6.97077 27.6807C6.97077 29.945 10.3035 31.8664 10.8701 34.0927C11.4208 36.2566 9.34503 39.5722 10.5184 41.5897C11.1573 42.6878 12.6067 43.1386 14.0542 43.572C14.2231 43.6225 14.5426 43.8115 14.3262 44.1268C13.3359 45.8361 5.36529 59.5923 5.36529 59.5923C4.7312 60.6865 5.24612 61.6244 6.5092 61.6775L12.6918 61.9354C13.9549 61.9885 15.5394 62.9064 16.2136 63.9757L19.5127 69.2108C20.1869 70.2802 21.2566 70.2601 21.8905 69.1661C21.8905 69.1661 31.1214 53.2294 31.1252 53.2249C31.3104 53.0085 31.4965 53.0529 31.5856 53.1286C32.5956 53.9886 34.0035 54.8464 35.1787 54.8464C36.3311 54.8464 37.3982 54.0389 38.4537 53.1382C38.5396 53.065 38.7487 52.915 38.9015 53.2264C38.904 53.2313 48.1246 69.0808 48.1246 69.0808C48.7602 70.1735 49.8302 70.192 50.5023 69.1213L53.7921 63.88C54.4644 62.8099 56.0472 61.8884 57.3103 61.8334L63.4923 61.564C64.7548 61.5086 65.268 60.5694 64.6324 59.4769ZM45.2449 45.1415C38.4091 49.1184 30.2039 48.6922 23.9367 44.7392C14.7535 38.857 11.732 26.6889 17.2525 17.1606C22.836 7.52248 35.1098 4.12161 44.8348 9.41814C44.8859 9.44595 44.9364 9.47471 44.9871 9.50308C45.0591 9.54299 45.131 9.58348 45.2027 9.62434C48.2029 11.3541 50.8046 13.8646 52.6675 17.0666C58.3611 26.8534 55.0315 39.4479 45.2449 45.1415Z' />
      <circle cx='35.5' cy='27.5' r='17.5' />
      <path d='M38.103 31.0343C38.518 31.2973 38.7255 31.4551 38.9627 31.5866C40.3857 32.4281 41.9273 32.507 42.9353 31.6655C44.0026 30.745 44.0915 29.588 43.5875 28.4046C43.0539 27.0634 41.8088 26.2482 40.2968 26.4585C39.6149 26.5637 38.8145 27.0108 38.4291 27.5104C37.4804 28.7201 37.1543 30.2717 35.583 31.0869C35.3755 31.1921 35.4348 31.7969 35.4348 32.1651C35.4645 34.7948 33.3003 36.8197 30.1874 37.0827C27.7268 37.2931 24.0506 35.5312 24.0803 33.0855C25.3847 33.6114 26.6298 34.3478 28.0232 34.6108C29.1794 34.8474 30.5728 34.7422 31.729 34.4004C33.241 33.9533 33.9525 32.6647 33.7746 31.5077C33.5967 30.5083 33.152 30.2454 30.8693 29.9035C30.721 29.325 30.5728 28.6938 30.4246 27.9838C30.6618 27.9575 30.8396 27.9049 31.0175 27.9049C32.0551 27.826 33.1817 27.7997 33.2706 26.5111C33.3595 25.4855 32.2626 24.2759 30.9879 24.381C29.7427 24.4862 28.5865 25.0648 27.1635 25.5118C26.8967 25.1437 26.452 24.5388 26.0369 23.934C27.8453 22.0406 30.81 21.6724 32.9741 23.0662C34.9605 24.3285 35.1383 26.3533 33.3892 28.7201C33.8932 29.1409 34.3972 29.5616 35.1087 30.1665C35.3459 29.5353 35.4645 29.0883 35.6423 28.6675C35.8795 28.0627 36.0574 27.4052 36.4428 26.853C37.3915 25.4066 38.6959 24.4073 40.7415 24.6177C42.7278 24.8281 43.7384 25.8738 44.5 27.5065C45.325 29.4206 44.9886 30.8611 44.5 31.954C44.0114 33.047 42.1942 34.1637 40.8897 33.9796C39.1702 33.743 38.1919 32.7699 38.103 31.0343Z' />
      <path d='M32.3789 20.9621C35.1064 22.6451 37.5077 22.4084 39.9387 20.9358C40.3834 21.5669 40.7688 22.0929 41.1542 22.6188C38.2785 24.6174 33.5944 23.5918 32.3789 20.9621Z' />
      <path d='M34.3438 19.5777C34.8334 18.9793 35.2142 18.5441 35.6767 18C36.2207 18.5712 36.5743 18.9521 37.0096 19.4417C36.6015 19.9042 36.2207 20.3122 35.7311 20.829C35.2686 20.421 34.8606 20.0402 34.3438 19.5777Z' />
    </svg>
  );
}
