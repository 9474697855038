import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import tick from '../../../../assets/images/tick.svg';
import styles from './third-step.module.scss';

const Success = ({ amount }) => {
  const { userEntries } = useSelector((state) => state.mantraPool);
  const dark = useSelector((state) => state.basic.dark);

  return (
    <div className={cn(styles.body, { [styles.dark]: dark })}>
      <div className={styles.icon}>
        <img src={tick} alt='' />
      </div>
      <div className={styles.title}>POOL Entries Added!</div>
      <div className={cn(styles.row, styles.accent)}>
        <div className={styles.rowLabel}>Added Entries</div>
        <div className={styles.rowValue}>{amount}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowLabel}>Total OM Tokens spent</div>
        <div className={styles.rowValue}>{amount}</div>
      </div>
      <div className={cn(styles.row, styles.accent)}>
        <div className={styles.rowLabel}>Your total POOL Entries</div>
        <div className={styles.rowValue}>{userEntries}</div>
      </div>
    </div>
  );
};

Success.propTypes = {
  amount: PropTypes.string.isRequired,
};

export default Success;
