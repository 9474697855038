import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 249.4 249.1'
      version='1.1'
      viewBox='0 0 249.4 249.1'
      xmlSpace='preserve'
      {...otherProps}
    >
      <path
        d='M1092.3 2493.4c-538-73-971-478-1070-1001-62-330 6-661 195-944 66-98 231-264 326-327 145-95 293-157 464-194 118-25 362-25 480 0 244 52 441 154 615 318 184 175 306 387 360 626 94 414-21 835-312 1144-171 180-419 317-665 364-103 20-300 27-393 14zm280-99c297-34 578-186 761-412 118-147 186-283 231-468 22-88 26-128 27-250 0-174-18-281-75-425-301-773-1296-982-1879-395-329 330-426 810-251 1243 89 223 275 438 481 555 225 129 459 179 705 152z'
        transform='matrix(.1 0 0 -.1 0 250)'
      ></path>
      <path
        d='M440.3 1548.4c-130-47-213-208-183-358 15-75 32-110 79-159 62-67 102-82 211-82 87 0 100 3 148 29 89 50 138 129 149 241 14 154-75 298-206 335-51 14-149 11-198-6zm219-125c59-43 89-142 68-229-40-168-261-201-337-50-46 90-22 231 48 280 58 42 161 41 221-1zM1793.3 1544.4c-188-75-241-352-99-520 77-92 259-109 340-31l22 21 3-28c3-25 6-27 53-27h50l3 158 3 157h-281v-100h85c92 0 95-2 69-56-18-37-82-74-130-74-115 0-184 81-184 212 1 145 94 228 221 198 49-12 99-55 99-86 0-10 15-14 60-14h59l-14 45c-31 105-108 158-235 162-56 2-87-2-124-17zM927.3 1254.4v-300h100l2 202 3 203 74-203 74-203 50 3 50 3 73 200 73 200 3-200 3-200 53-3 52-3-2 298-3 298h-139l-80-222c-44-123-83-220-86-215-4 4-40 99-80 212s-78 211-83 218c-6 7-34 12-73 12h-64v-300z'
        transform='matrix(.1 0 0 -.1 0 250)'
      ></path>
    </svg>
  );
}
