import React from 'react';
import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 125 183'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <ellipse rx={45.042} ry={5.424} transform='matrix(1 0 0 -1 45.042 176.197)' fill='#840032' />
      <path
        d='M123.489 79.875s3.041 3.042-1.331 6.527c-4.372 3.485-6.083 6.526-6.083 10.011 0 3.486 3.041 5.64 3.041 5.64s-6.526.444-6.97-8.681c-.443-9.125 4.372-15.208 3.485-21.735-.887-6.526 7.858 8.238 7.858 8.238z'
        fill='#FF84B3'
      />
      <path
        d='M86.102 80.763s1.71 2.598 6.97 1.33c5.196-1.33 8.681-5.195 14.764-6.082 6.083-.887 15.652 3.928 15.652 3.928l-6.527-38.273s0-4.372-9.568-6.083c-9.569-1.71-19.96 1.33-19.96 1.33l-1.331 43.85z'
        fill='#F22373'
      />
      <path
        d='M95.228 62.512s-.444 3.042-4.752 9.125c-4.373 6.083-4.373 9.125-4.373 9.125l-4.752-26.044c-.064-.063 10.835-3.929 13.877 7.794z'
        fill='#FF84B3'
      />
      <path
        d='M3.537 15.559s37.766-4.373 61.275-6.083c23.445-1.711 22.178 1.71 24.333 2.154 2.154.444 6.083 50.82 6.083 50.82s-16.095-4.753-30.86-3.042c-14.764 1.71-52.973 8.238-52.973 8.238s7.35-30.796-7.858-52.087z'
        fill='#FE3D88'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.48 27.34L56 30.583l3.567-3.243 7.146 6.44-3.578 3.244-3.567-3.15-3.569 3.15-3.52-3.195-3.473 3.196-3.673-3.244 7.147-6.441z'
        fill='#fff'
      />
      <path
        d='M52.48 23.514l-7.042 6.347 3.567 3.15 3.474-3.197L56 33.011l3.568-3.15 3.567 3.15 3.474-3.15-7.041-6.347L56 26.757l-3.52-3.243z'
        fill='#B4182C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.48 27.34L56 30.583l3.567-3.243 7.146 6.44-3.578 3.244-3.567-3.15-3.569 3.15-3.52-3.195-3.473 3.196-3.673-3.244 7.147-6.441zm-6.938 6.44l3.462 3.058 3.475-3.198L56 36.838l3.567-3.15 3.566 3.15 3.372-3.057-6.938-6.253L56 30.77l-3.521-3.243-6.937 6.252z'
        fill='#B4182C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56 16.464c-7.475 0-13.535 6.06-13.535 13.536 0 7.475 6.06 13.536 13.535 13.536 7.476 0 13.536-6.06 13.536-13.536S63.476 16.464 56 16.464zM41.64 30c0-7.932 6.43-14.362 14.361-14.362 7.932 0 14.362 6.43 14.362 14.362S63.932 44.362 56 44.362c-7.931 0-14.361-6.43-14.361-14.362z'
        fill='#B4182C'
      />
      <path
        d='M45.232 105.664l-1.014 8.301s1.457 1.267 2.281 2.915c.824 1.647 11.216 2.091 14.574.823 3.358-1.267 4.816-6.209 3.549-10.011-1.268-3.802-2.472-8.301-2.472-8.301s-13.18 6.716-16.918 6.273z'
        fill='#171717'
      />
      <path
        d='M23.621 165.736c-.444 0-.887-.38-.95-.823L2.837 10.87c-.063-.507.317-1.014.824-1.077.507-.064 1.014.316 1.077.823L24.572 164.66c.063.507-.317 1.014-.824 1.077h-.127z'
        fill='#637F9A'
      />
      <path d='M22.99 92.358l-3.612-.634v3.358l4.245 1.141-.633-3.865z' fill='#F9F9F9' />
      <path
        d='M20.028 96.797l-1.702.364c-1.578.195-2.45.367-2.973.054-.702-.422-.03-1.14-.03-1.14s-1.202-.131-1.25-.837c-.033-.481.483-.653.483-.653s-1.073-.163-1.36-.528c-.391-.499.237-.955.237-.955-1.53-.526-1.592-1.375-1.302-1.526.29-.151 1.45.094 1.45.094.94.053 3.525.247 2.329-.883-1.197-1.13-1.687-2.451-1.573-2.902.114-.451.783-.455.973.02.153.387.608.942.967 1.349.294.336.644.62 1.034.837.458.253.852.45 1.194.605l1.84 1.12-.317 4.98z'
        fill='#FFBE9D'
      />
      <path
        d='M40.037 72.59s-1.457 9.378-.824 11.85c.634 2.47 3.929 10.391 3.929 11.469 0 1.013 1.901 10.582 1.901 10.582s5.83 1.647 10.836.443c5.006-1.267 6.463-6.463 6.463-6.463s-3.739-23.065-4.816-26.614c-1.014-3.548-8.11-8.554-8.11-8.554l-9.379 7.287z'
        fill='#F9F9F9'
      />
      <path d='M48.336 63.462l-10.582 8.301.444 3.105 12.23-9.378-2.092-2.028z' fill='#F9F9F9' />
      <path
        d='M60.144 48.715c6.373-1.353 13.16-.896 19.265 1.31 2.735.983 5.452 2.415 6.99 4.787 1.823 2.802 1.657 6.325 1.584 9.619a108.217 108.217 0 001.427 20.037c.912 5.517 2.247 10.99 2.422 16.568.175 5.578-.93 11.402-4.448 15.865-5.415 6.878-15.425 9.074-24.394 8.213-4.724-.448-9.467-1.642-13.39-4.19-3.923-2.547-6.953-6.579-7.431-11.086'
        fill='#C40A51'
      />
      <path
        d='M71.63 56.853c-.064.053 1.133.975 2.23 3.03.552 1.02 1.067 2.293 1.565 3.734.515 1.44 1.022 3.066 1.841 4.673.82 1.608 1.934 3.014 3.27 3.945 1.316.948 2.854 1.317 4.097 1.247 1.253-.052 2.21-.465 2.763-.86.286-.194.47-.378.59-.51s.174-.202.156-.22c-.037-.026-.285.246-.847.571-.562.334-1.483.677-2.662.685a6.313 6.313 0 01-3.821-1.265c-1.243-.896-2.302-2.248-3.104-3.803-.792-1.564-1.317-3.171-1.85-4.612-.526-1.45-1.087-2.74-1.686-3.751-.598-1.02-1.225-1.766-1.713-2.231-.497-.448-.81-.65-.829-.633z'
        fill='#E0E0E0'
      />
      <path
        d='M76.526 124.264c.018 0 .046-.184.092-.544.046-.413.101-.931.166-1.582.138-1.37.303-3.364.488-5.824a531.8 531.8 0 001.095-19.247c.295-7.528.415-14.345.433-19.273.01-2.468 0-4.462-.027-5.841-.019-.65-.028-1.178-.037-1.59-.019-.36-.028-.554-.046-.554-.019 0-.037.193-.046.553-.01.413-.028.94-.056 1.59-.027 1.441-.073 3.418-.128 5.842-.102 4.928-.277 11.745-.571 19.265-.304 7.519-.673 14.318-.958 19.247-.138 2.415-.258 4.401-.34 5.832-.028.651-.056 1.178-.075 1.59-.009.343-.009.536.01.536z'
        fill='#E0E0E0'
      />
      <path
        d='M78.35 94.115c-.009.123 2.818.404 6.327.378 3.508-.018 6.335-.343 6.317-.466-.01-.132-2.836-.017-6.326.009-3.481.026-6.309-.053-6.318.079z'
        fill='#E0E0E0'
      />
      <path
        d='M80.645 94.307c-.056-.017-.304.44-.544 1.239-.12.395-.24.878-.331 1.423-.037.28-.074.597.046.94.12.351.442.659.884.72a.822.822 0 00.635-.193.883.883 0 00.277-.51c.064-.342.009-.65-.019-.913a7.405 7.405 0 00-.34-1.432c-.268-.79-.562-1.239-.618-1.212-.064.026.11.51.286 1.291.092.395.175.87.22 1.397.028.263.056.544 0 .773-.054.237-.202.369-.395.351-.497-.07-.626-.65-.543-1.15a23.94 23.94 0 01.22-1.406c.166-.808.286-1.3.222-1.318z'
        fill='#E0E0E0'
      />
      <path
        d='M65.82 114.254l-2.93 24.905 15.724 23.572-8.88 3.054-23.332-22.635 2.993-30.571 16.425 1.675z'
        fill='#000'
      />
      <path
        d='M28.529 164.448l1.864 8.871s-8.495 4.223-8.313 5.895l17.434-2.343-2.336-13.942-8.65 1.519z'
        fill='#ED1B6C'
      />
      <path
        opacity={0.6}
        d='M35.617 171.639c.35.034.65.368.628.724-.019.351-.36.658-.714.63-.353-.029-.719-.423-.663-.77.06-.349.48-.686.814-.575'
        fill='#fff'
      />
      <path
        d='M30.093 173.278c.016.082.447.049.91.252.466.186.744.517.817.473.077-.031-.138-.516-.702-.747-.565-.237-1.059-.049-1.025.022zM28.405 174.344c-.004.084.388.166.735.474.36.298.499.674.579.653.082-.001.055-.506-.386-.881-.436-.376-.938-.325-.928-.246zM27.753 176.699c.08.005.125-.436-.174-.862-.294-.428-.727-.544-.748-.465-.036.079.263.284.502.638.246.345.336.697.42.689zM29.894 171.209c.05.072.417-.11.903-.161.484-.061.885.034.916-.043.04-.069-.376-.319-.947-.256-.57.064-.922.4-.872.46z'
        fill='#263238'
      />
      <path
        opacity={0.3}
        d='M28.89 166.204l.332 1.582 8.506-1.55-.278-1.682-8.56 1.65z'
        fill='#000'
      />
      <path
        d='M29.552 169.413c.006.034.349.03.869-.156.258-.089.555-.233.86-.435.151-.101.303-.218.45-.348.072-.065.157-.134.227-.249.08-.103.095-.339-.04-.454a.47.47 0 00-.403-.131c-.128.017-.226.064-.317.099a3.466 3.466 0 00-.51.28 3.007 3.007 0 00-.707.663c-.338.444-.423.781-.388.795.043.022.192-.279.55-.658a3.53 3.53 0 01.699-.568 3.49 3.49 0 01.47-.241c.175-.078.33-.108.394-.037.025.028.03.042-.003.099a.849.849 0 01-.166.183 5.234 5.234 0 01-.413.341 4.949 4.949 0 01-.783.466c-.475.228-.798.308-.79.351z'
        fill='#263238'
      />
      <path
        d='M29.677 169.516c.034.009.145-.325.082-.88a2.88 2.88 0 00-.284-.93 2.779 2.779 0 00-.314-.488c-.132-.152-.293-.362-.63-.344-.172.026-.29.183-.322.306-.037.125-.032.231-.035.335a2.025 2.025 0 00.622 1.415c.417.393.789.431.79.4.02-.048-.304-.158-.637-.547a2.02 2.02 0 01-.416-.769 1.843 1.843 0 01-.073-.505c-.005-.189.04-.352.109-.344.086-.018.257.096.354.234.118.141.211.285.295.432.164.296.264.587.32.836.125.509.091.844.139.849z'
        fill='#263238'
      />
      <path
        d='M21.849 137.027a11.187 11.187 0 014.448-11.439l16.516-11.826 19.3-13.98c2.588 2.775 3.93 6.174 4.466 8.234 1.143 4.359-2.19 9.987-6.474 12.693l-24.571 15.52 3.598 28.087-10.91 2.634-6.373-29.923z'
        fill='#000'
      />
      <path
        d='M35.464 136.42c.003.01.116-.053.324-.172.228-.137.53-.313.91-.534.785-.473 1.912-1.176 3.293-2.059 2.763-1.762 6.513-4.294 10.785-6.894 4.255-2.641 7.92-5.32 10.465-7.391a77.508 77.508 0 002.959-2.515c.341-.307.6-.551.778-.716.177-.166.265-.257.258-.265-.008-.007-.106.071-.292.224-.202.169-.472.396-.805.68-.702.589-1.72 1.436-3.007 2.448-2.569 2.027-6.24 4.677-10.484 7.314-4.274 2.596-8.013 5.16-10.75 6.96-1.354.885-2.453 1.609-3.245 2.125-.369.239-.66.432-.885.578-.203.133-.307.208-.304.217zM52.973 66.364H75.41a7.431 7.431 0 007.435-7.435v-1.822a7.436 7.436 0 00-7.435-7.434H52.973a7.431 7.431 0 00-7.434 7.434v1.822a7.436 7.436 0 007.434 7.435z'
        fill='#455A64'
      />
      <path
        d='M76.386 50.007c.008-.008-.07-.062-.225-.132a2.726 2.726 0 00-.72-.194c-.636-.093-1.644-.007-2.66.59-.992.573-2.155 1.426-2.938 2.79-.799 1.334-1.21 3.032-1.28 4.784-.1 1.775.342 3.496 1.14 4.822.791 1.333 1.93 2.248 2.985 2.729 1.062.496 2.016.62 2.66.612.325 0 .573-.03.744-.062.17-.03.255-.054.248-.07-.008-.038-.357.016-.985-.03a6.498 6.498 0 01-2.543-.714c-1-.496-2.054-1.38-2.79-2.659-.745-1.271-1.156-2.9-1.055-4.613.07-1.713.45-3.341 1.194-4.62.713-1.295 1.814-2.14 2.752-2.73.946-.596 1.884-.72 2.504-.681.628.054.954.217.97.178z'
        fill='#263238'
      />
      <path
        d='M61.975 49.602l3.892.07c-1.054.163-2.008.79-2.69 1.612-.682.822-1.124 1.822-1.41 2.853-1.21 4.35.588 9.389 4.1 12.226l-3.147.054c0-.008-7.962-8.985-.745-16.815z'
        fill='#1B1B1B'
      />
      <path
        d='M56.955 82.14H79.39a7.431 7.431 0 007.434-7.435v-1.822a7.436 7.436 0 00-7.434-7.435H56.955a7.431 7.431 0 00-7.435 7.435v1.822a7.436 7.436 0 007.435 7.434z'
        fill='#455A64'
      />
      <path
        d='M80.37 65.782c.008-.007-.07-.062-.224-.131a2.724 2.724 0 00-.721-.194c-.636-.093-1.644-.008-2.66.589-.992.574-2.155 1.426-2.938 2.79-.798 1.334-1.209 3.032-1.279 4.784-.1 1.775.341 3.497 1.14 4.822.79 1.334 1.93 2.249 2.985 2.73 1.062.495 2.015.62 2.659.612.325 0 .573-.031.744-.062.17-.031.256-.055.248-.07-.008-.039-.357.015-.984-.031a6.498 6.498 0 01-2.543-.713c-1-.497-2.055-1.38-2.791-2.66-.745-1.271-1.155-2.899-1.055-4.612.07-1.714.45-3.342 1.194-4.62.714-1.296 1.814-2.14 2.752-2.73.946-.597 1.884-.72 2.504-.682.629.054.954.217.97.178z'
        fill='#263238'
      />
      <path
        d='M65.957 65.378l3.892.07c-1.055.163-2.008.79-2.69 1.612-.683.822-1.125 1.822-1.412 2.853-1.209 4.35.59 9.389 4.102 12.226l-3.148.054c0-.008-7.962-8.985-.744-16.815z'
        fill='#1B1B1B'
      />
      <path
        d='M74.894 104.538l.96 4.403s-1.168 1.788-1.429 2.72c-.26.933-.244 5.087-.244 5.087s.83.211 1.184-.572c.156-.35.283-2.379.283-2.379a1.023 1.023 0 011.669.489c.294 1.077.646 2.326.72 2.476.08.167-.378 1.608-.734 2.674-.215.638.566 1.219 1.065.766a.352.352 0 00.105-.157s.465 1.058 1.417-.544c0 0 1.397-.411 1.618-1.457 0 0 1.401-1.083 1.704-1.835.301-.748-1.472-5.939-1.472-5.939l-2.002-7.353-4.844 1.621z'
        fill='#FFBE9D'
      />
      <path
        d='M20.166 91.566l10.74-.542 4.965-14.21 5.28 9.16-4.248 9.27c-1.004 2.187-2.937 3.499-4.997 3.385l-12.518-1.74.778-5.323zM59.674 66.86l17.413 16.246a4.98 4.98 0 011.545 3.01l2.618 20.292-7.265 1.128-3.842-19.237-16.418-10.7 5.95-10.738z'
        fill='#ED1B6C'
      />
      <path
        d='M46.527 51.635l1.407 14.105c.094.78-.006.892-.437 1.55-.66 1.008-1.173 1.17-2.143 1.882l-2.946 4.68-2.2-1.836c-.741-.517-.974-3.188-1.119-4.082-.16-.989-.66-1.88-.66-1.88s-3.898.19-4.719-3.574c-.387-1.772-.887-5.44-1.305-8.822a7.462 7.462 0 015.905-8.226l.105-.02a7.134 7.134 0 018.112 6.223z'
        fill='#FFBE9D'
      />
      <path
        d='M38.573 72.896L38 72.101l1.044-3.027 2.843 4.304.76-2.35 5.706-6.795 2.48 1.814 9.365.875-.06.253 1.79 13.813 1.088 14.836 2.182 7.267.92.979c.556.592.775 1.432.586 2.222l-.065.258-22.973 7.456-1.779-2.779-6.046-34.335 2.73-3.996z'
        fill='#ED1B6C'
      />
      <path
        d='M56.813 65.878s-1.522-1.087-5.54.672c-4.234 1.857-5.9 6.367-2.922 17.9 2.457 9.509 6.497 10.286 6.497 10.286s-4.43-14.229-5.052-20.378c-.784-7.767 7.24-7.093 7.24-7.093 1.042-.534-.223-1.387-.223-1.387zM40.32 103.481c.402-.369 14.765-3.843 21.897-5.533l-4.092-1.296c-6.158 1.09-18.458 3.41-18.393 3.976.081.706.085 3.315.588 2.853z'
        fill='#1B1B1B'
      />
      <path
        d='M62.903 94.358s-.599 4.179-6.034-1.69c-5.432-5.87-4.345-3.03-4.345-3.03l-.727 1.213s6.558 10.073 11.687 7.504l-.58-3.997z'
        fill='#1B1B1B'
      />
      <path
        d='M38.348 65.9s2.736-.355 4.942-2.47c0 0-.648 3.011-4.487 3.448l-.455-.977z'
        fill='#EB996E'
      />
      <path
        d='M33.825 55.97c.047.287.331.481.631.437a.53.53 0 00.457-.602c-.046-.287-.331-.482-.63-.437a.529.529 0 00-.458.601zM33.56 55.074c.083.058.425-.329 1.006-.447.579-.127 1.066.084 1.112.002.024-.04-.07-.152-.283-.247a1.525 1.525 0 00-.91-.087c-.36.076-.638.27-.785.445-.144.17-.18.31-.14.334zM39.32 55.026c.047.287.331.482.631.438a.53.53 0 00.458-.602c-.047-.288-.332-.482-.632-.438a.53.53 0 00-.457.602zM39.29 54.013c.084.058.43-.33 1.007-.447.58-.127 1.067.084 1.113.002.023-.04-.07-.152-.283-.247a1.526 1.526 0 00-.91-.087c-.36.076-.638.274-.786.445-.148.17-.18.307-.14.334zM38.066 58.747c-.003-.031-.376-.022-.976.026-.151.014-.3.016-.344-.083-.056-.101-.029-.273.009-.459l.206-1.217c.276-1.733.447-3.143.377-3.155-.07-.011-.35 1.384-.63 3.117-.066.426-.129.83-.186 1.22-.023.179-.078.39.044.596.064.101.188.163.289.166.101.008.184-.006.26-.015.59-.092.955-.164.951-.196zM38.668 51.771c.09.146.653-.047 1.353-.107.698-.073 1.287.013 1.348-.147.027-.076-.101-.205-.355-.31a2.262 2.262 0 00-1.05-.143 2.232 2.232 0 00-1.003.334c-.231.153-.33.301-.293.373zM33.43 53.356c.13.109.501-.109.978-.215.476-.121.905-.093.97-.25.027-.075-.063-.193-.267-.28-.2-.089-.514-.131-.841-.051-.328.08-.592.256-.728.425-.14.166-.17.314-.112.371zM41.58 48.343c.008.003.017.01.025.013.697.35 1.236.955 1.498 1.694.012.027.018.05.025.073.368 1.048.57 2.148.949 3.191.38 1.042.43 1.868 1.537 1.979.695.072 1.227-.378 1.422-.791.078-.158.055-.344.033-.522-.336-2.642.075-3.732-.49-6.447a2.343 2.343 0 00-1.336-1.67c-.653-.29-1.434-.57-2.005-.143-.57.43-1.146 1.782-1.535 2.387'
        fill='#263238'
      />
      <path
        d='M45.604 53.604c.06-.04 2.437-1.303 2.884 1.297.447 2.597-2.25 2.514-2.267 2.44-.017-.075-.617-3.737-.617-3.737z'
        fill='#FFBE9D'
      />
      <path
        d='M46.844 56.217c.011-.01.051.022.134.044a.447.447 0 00.34-.053c.254-.148.414-.625.345-1.094a1.48 1.48 0 00-.24-.626c-.114-.175-.26-.286-.401-.289a.234.234 0 00-.245.17c-.022.08.004.129-.008.134-.007.009-.064-.032-.061-.145a.302.302 0 01.074-.182.347.347 0 01.24-.11c.202-.02.413.131.54.32.14.189.245.43.282.698.075.525-.109 1.061-.465 1.237a.506.506 0 01-.425.003c-.094-.045-.117-.098-.11-.107z'
        fill='#EB996E'
      />
      <path
        d='M37.468 60.285c-2.023 1.518-4.117-.704-4.787-1.86.494 4.077 1.2 9.016 7.52 7.788 4.354-.845 6.003-7.383 5.685-10.88-.213-1.177-.91-2.96-1.158-.807-.311 2.69-.85 3.498-2.564 4.235-1.714.738-2.26-.304-4.696 1.524z'
        fill='#263238'
      />
      <path
        d='M39.606 60.485c-.094-.016-.175.619-.778 1.005-.603.387-1.275.229-1.29.316-.011.038.138.143.422.186.28.047.704.006 1.071-.23.368-.237.556-.588.611-.85.062-.256.009-.426-.036-.427z'
        fill='#FFBE9D'
      />
      <path
        d='M47.582 49.81c.561 1.966-2.438 1.7-4.07 1.545-2.42-3.31-9.766.908-11.259 1.268-1.764.426-1.645-4.68-.553-5.66 2.02-1.813 3.33-6.655 6.9-6.346 3.568.31 4.595 3.79 6.325 4.745 1.73.957 1.956 1.989 2.657 4.447z'
        fill='#272828'
      />
      <path
        d='M56.322 77.16c.04-.03-.478-.541-.82-1.62a8.488 8.488 0 01-.378-1.934c-.06-.74-.045-1.565.144-2.395a5.647 5.647 0 011.039-2.137c.462-.577.984-1 1.466-1.288.97-.576 1.685-.696 1.665-.745-.005-.015-.179.005-.492.084-.313.08-.755.229-1.267.507-.507.273-1.069.701-1.561 1.297a5.689 5.689 0 00-1.108 2.227c-.2.87-.204 1.72-.13 2.475.075.76.239 1.432.443 1.974.203.541.437.954.64 1.202.21.254.349.363.359.353z'
        fill='#263238'
      />
      <path
        opacity={0.3}
        d='M61.92 80.992c-1.317-.343-4.03-1.814-4.03-1.814 1.635 4.3 3.39 9.439 4.93 13.773-.447-4.001-.452-7.952-.9-11.959z'
        fill='#000'
      />
      <path
        d='M77.053 83.645c-.035-.06-.8.353-1.54 1.119-.746.76-1.139 1.54-1.08 1.57.065.04.542-.656 1.263-1.391.716-.741 1.397-1.233 1.357-1.298zM75.428 80.951c-.05-.04-.78.984-1.113 2.48-.348 1.492-.13 2.734-.07 2.72.08-.006-.015-1.218.318-2.66.323-1.446.935-2.5.865-2.54zM65.373 92.808c0 .07.02.13.05.13.025 0 .05-.06.05-.13s-.02-.13-.05-.13c-.025 0-.05.06-.05.13z'
        fill='#263238'
      />
      <path
        d='M69.614 164.107l4.624 6.941s-5.504 7.032-4.774 8.398l14.109-7.947-7.088-11.648-6.871 4.256z'
        fill='#ED1B6C'
      />
      <path
        d='M30.56 95.39c.064 0 .034-1.02-.522-2.123-.547-1.108-1.347-1.745-1.382-1.69-.055.055.631.746 1.153 1.804.532 1.054.676 2.018.75 2.008z'
        fill='#263238'
      />
      <path
        d='M29.407 96.6c.06.02.363-1.153.145-2.61-.21-1.456-.835-2.495-.885-2.46-.075.035.432 1.089.631 2.495.209 1.407.03 2.56.11 2.575z'
        fill='#263238'
      />
      <path
        opacity={0.3}
        d='M37.83 92.49s-2.526 4.413-2.491 4.294c-.169-4.215-.154-8.23.815-12.336'
        fill='#000'
      />
      <path
        d='M42.618 71.227c-.016.025.257.334.701.898.447.553 1.043 1.391 1.835 2.254l.087.094.053-.134.873-2.124c.709-1.69 1.376-3.197 1.869-4.284.227-.508.42-.937.58-1.291.136-.304.205-.479.193-.489-.011-.01-.102.145-.258.434-.155.29-.371.713-.633 1.247a80.76 80.76 0 00-1.922 4.258c-.315.748-.603 1.471-.864 2.134l.14-.04c-.785-.832-1.403-1.645-1.873-2.174-.467-.533-.766-.803-.781-.783zM38.136 72.329c-.02.015.09.214.313.522.219.313.572.735.94 1.247l.139.194.07-.219c.143-.452.297-.93.456-1.436.105-.338.204-.661.294-.97l-.22.046c.98 1.197 1.72 2.037 1.765 2.003.045-.04-.616-.945-1.565-2.153l-.144-.189-.075.23c-.094.302-.199.625-.303.958-.15.502-.293.98-.433 1.432l.21-.025c-.398-.522-.766-.91-1.02-1.198-.263-.288-.407-.457-.427-.442z'
        fill='#263238'
      />
      <path
        d='M41.881 73.6c.065.03.348-.527.507-1.252.159-.721.318-1.288.254-1.318-.065-.03-.348.527-.508 1.253-.154.72-.318 1.287-.253 1.317zM41.246 74.263c-.01 0-.01.124-.005.353.01.258.02.597.035 1.014.034.88.109 2.152.223 3.723a156.42 156.42 0 003.773 24.31c.368 1.531.68 2.768.914 3.618.115.403.204.731.274.974.064.224.104.338.114.338.01 0-.015-.124-.06-.348-.06-.253-.134-.581-.228-.989-.204-.855-.492-2.097-.835-3.633a190.553 190.553 0 01-2.287-12.078 192.205 192.205 0 01-1.48-12.202 193.76 193.76 0 01-.304-3.718c-.035-.418-.065-.755-.085-1.009-.024-.229-.04-.353-.05-.353zM59.757 105.766c.02.065 1.347-.288 2.873-1.019 1.531-.726 2.64-1.536 2.605-1.591-.04-.064-1.203.642-2.714 1.357-1.506.721-2.789 1.178-2.764 1.253zM69.306 99.382c0 .07.025.13.055.13s.055-.06.055-.13-.025-.13-.055-.13-.055.06-.055.13z'
        fill='#263238'
      />
      <path
        d='M31.804 134.101c-.048.042.61.813 1.69 1.426 1.075.619 2.072.791 2.083.731.022-.074-.921-.351-1.96-.951-1.041-.59-1.759-1.259-1.813-1.206z'
        fill='#455A64'
      />
      <path
        d='M31.008 137.045c-.01.065 1.052.2 2.338-.03 1.29-.222 2.24-.708 2.212-.765-.03-.073-1.001.302-2.257.517-1.249.219-2.288.199-2.293.278z'
        fill='#455A64'
      />
      <path
        d='M73.853 170.183c.057.06.405-.197.904-.271.495-.09.906.042.944-.035.05-.067-.39-.362-.992-.259-.604.101-.923.522-.856.565zM73.368 171.994c.041.073.417-.066.874.01.464.062.781.306.838.246.069-.045-.222-.458-.795-.541-.57-.086-.968.224-.917.285zM74.07 174.335c.07-.037-.126-.435-.606-.637-.477-.206-.905-.073-.88.004.01.086.373.101.763.273.393.162.655.412.724.36zM72.822 168.681c.079.035.293-.315.678-.617.377-.309.767-.442.753-.523-.004-.08-.489-.07-.938.287-.449.357-.569.829-.493.853zM71.716 167.209c.024.026.312-.16.653-.594a4.146 4.146 0 00.693-1.361c.027-.092.062-.196.06-.331.013-.13-.1-.338-.276-.363a.47.47 0 00-.41.103c-.1.083-.158.174-.216.253a3.469 3.469 0 00-.283.508c-.149.339-.223.665-.246.938-.05.556.057.886.094.879.048-.004.014-.338.116-.85.053-.254.14-.55.29-.852.076-.152.165-.306.27-.455.106-.159.221-.266.314-.24.036.01.047.02.05.085a.864.864 0 01-.044.244c-.05.181-.107.349-.168.508-.127.32-.275.59-.415.811-.281.446-.512.685-.482.717z'
        fill='#263238'
      />
      <path
        d='M71.876 167.228c.034-.01-.05-.352-.398-.788a2.889 2.889 0 00-.735-.637 2.78 2.78 0 00-.526-.246c-.192-.059-.44-.15-.717.045-.132.113-.148.308-.11.43.036.125.096.213.15.301.113.177.251.324.394.443.29.24.613.37.885.425.562.111.897-.055.88-.081-.008-.052-.34.028-.83-.125a2.026 2.026 0 01-.76-.429 1.843 1.843 0 01-.33-.39c-.105-.157-.155-.318-.092-.349.064-.061.269-.055.424.01.175.057.331.129.48.209.297.164.536.356.716.538.377.364.526.666.569.644z'
        fill='#263238'
      />
    </svg>
  );
}
