import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { StakingPool } from './staking.types';

const GAMER_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.polyMainnet,
  stakingTokenSymbol: 'GAMER',
  stakingContractAddress: '0xd1ecDC553651daB068486d9c4d066eCDC614416e',
  stakingTokenAddress: '0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const GAMER_WETH_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.polyMainnet,
  token0Symbol: 'GAMER',
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'GAMER/WETH' as ContractType,
  stakingTokenAddress: '0x1df661fc4319415a2f990bd5f49d5ca70efdee1c',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'GAMER',
  rewardTokenAddress: '0x3f6b3595ecf70735d3f48d69b09c4e4506db3f47',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x11c70CAA910647d820bD014d676Dcd97EDD64A99',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

export const POLY_CLOSED_POOLS = [GAMER_POOL_CLOSED, GAMER_WETH_POOL_CLOSED];
