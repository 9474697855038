import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import BN from 'bignumber.js';
import BalanceItem from './index';
import { setActiveAsset, setActiveTab } from '../../../../actions/staking-actions';
import { KSM, TOKEN_OPTIONS } from '../../../../constants/blockchain-constants';
import * as modalActions from '../../../../actions/modal-actions';
import { formatKsmToken } from '../../../../helpers/token-helper';
import { KUSAMA_ACCOUNT_MODAL } from '../../../../constants/modal-constants';
import { NOMINATE_TOKENS_PATH } from '../../../../constants/router-constants';

const Ksm = () => {
  const dispatch = useDispatch();
  const { ksmBalance /*ksmData*/ } = useSelector((state) => state.token);
  // const data = Object.fromEntries(ksmData);
  const { bondedAmount } = useSelector((state) => state.delegator.kusama);
  // console.log(bondedAmount); // TODO rm console.log
  // const nominatedBalance = 'There is an issue displaying. try again later'; // new BN(data.miscFrozen).toString(10);

  return (
    <BalanceItem
      token={TOKEN_OPTIONS[KSM].title}
      icon={TOKEN_OPTIONS[KSM].icon}
      balance={ksmBalance}
      buttonText='Nominate'
      formattedBalance={formatKsmToken(ksmBalance)}
      formattedStakeBalance={formatKsmToken(bondedAmount)}
      stakeBalance={bondedAmount}
      onClick={() => {
        dispatch(modalActions.hideModal(KUSAMA_ACCOUNT_MODAL));
        dispatch(setActiveAsset(KSM));
        dispatch(setActiveTab(NOMINATE_TOKENS_PATH));
      }}
    />
  );
};

export default Ksm;
