import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_AUTH } from '../../../constants/account-contants';
import metamaskIcon from '../../../assets/images/wallets/Metamask.png';
import walletConnectIcon from '../../../assets/images/wallets/WalletConnect.svg';
import walletLinkIcon from '../../../assets/images/wallets/WalletLink.svg';
import * as accountActions from '../../../actions/account-actions';
import EthIllustration from '../../../assets/images/wallets/eth-illustration.svg';
import loadingIcon from '../../../assets/images/loading.png';
import styles from './login-modal.module.scss';
import Item from './item';

const EthWallets = ({ global }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const typeAuth = useSelector((state) => state.account.auth);

  const connect = async (type) => {
    try {
      setLoading(true);
      await dispatch(accountActions.connect(type));
      window.gtag('event', 'conversion', { send_to: 'AW-328866151/lYlNCLfE7PoCEOey6JwB' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <img width='100%' alt='' src={EthIllustration} />
      <h3
        className={styles.title}
        style={{ color: global.dark ? '#ffffff' : '#201f1e' }}
        id='alert-dialog-title'
      >
        Ethereum Wallets
      </h3>
      <p className={styles.description} style={{ color: global.dark ? '#ffffff' : '#606371' }}>
        Safely connect to your existing blockchain wallet and access your assets.
      </p>
      {loading ? (
        <div className={styles.loading}>
          <img src={loadingIcon} className='rotate' alt='' />
        </div>
      ) : (
        <ul className={styles.list}>
          <Item
            title='MetaMask'
            icon={metamaskIcon}
            onClick={() => connect(TYPE_AUTH.METAMASK)}
            active={typeAuth === TYPE_AUTH.METAMASK}
            supportedNetworks={{
              eth: true,
              bsc: true,
            }}
          />
          <Item
            title='WalletConnect'
            icon={walletConnectIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_CONNECT)}
            active={typeAuth === TYPE_AUTH.WALLET_CONNECT}
            supportedNetworks={{
              eth: true,
            }}
          />
          <Item
            title='WalletConnect'
            icon={walletConnectIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_CONNECT_BSC)}
            active={typeAuth === TYPE_AUTH.WALLET_CONNECT_BSC}
            supportedNetworks={{
              bsc: true,
            }}
          />
          <Item
            title='WalletConnect'
            icon={walletConnectIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_CONNECT_POLYGON)}
            active={typeAuth === TYPE_AUTH.WALLET_CONNECT_POLYGON}
            supportedNetworks={{
              poly: true,
            }}
          />
          <Item
            title='Coinbase Wallet'
            icon={walletLinkIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_LINK)}
            active={typeAuth === TYPE_AUTH.WALLET_LINK}
            supportedNetworks={{
              eth: true,
            }}
          />
        </ul>
      )}
    </>
  );
};

EthWallets.propTypes = {
  global: PropTypes.object.isRequired,
};

export default EthWallets;
