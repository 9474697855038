import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/loader';
import Button from '../../../../external/components/Button/Button';
import { ErrorAlert } from '../../../../external/components/ErrorAlert/ErrorAlert';
import Text from '../../../../external/components/Text/Text';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { ProviderRpcError } from '../../../../types/metamask.types';
import { getNetworkText } from '../../../staking/helpers/getNetworkText';
import { useDelegatorClaim } from '../../hooks/claim.hook';
import { useInterval } from '../../hooks/useInterval';
import { EvmDelegatorScreensEnum } from './Body';

interface Props {
  selectedChainId: DelegatorChainIds;
  setScreen: React.Dispatch<React.SetStateAction<EvmDelegatorScreensEnum>>;
}

export function LinkWallet({ selectedChainId, setScreen }: Props) {
  const [loading, setLoading] = useState(false);
  const [linking, setLinking] = useState(false);
  const [delay, setDelay] = useState<number | null>(null);
  const { walletNetworkId, walletAddress, delegatorData } = useSelector(
    ({ account, delegators }: State) => ({
      walletNetworkId: account.networkId,
      walletAddress: account.address,
      delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
        (del) => del.chainId === selectedChainId,
      )[0],
    }),
  );

  const { networkId, hasLinked } = delegatorData;

  const { getLink, link } = useDelegatorClaim({
    chainId: selectedChainId,
    walletNetworkId,
    networkId,
  });

  const invalidNetwork = networkId !== walletNetworkId;
  useInterval(() => getLink(), !hasLinked ? delay : null);

  const init = async () => {
    try {
      setLoading(true);
      await getLink();
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleLink = async () => {
    try {
      setLinking(true);
      await link(walletAddress);
      setLinking(false);
      setDelay(5000);
    } catch (_error) {
      setLinking(false);
      const error = _error as ProviderRpcError;
      if (error.code === 4001) {
        // do nothing if RPC error
        return;
      } else {
        setScreen(EvmDelegatorScreensEnum.ERROR);
      }
    }
  };

  useEffect(() => {
    if (!hasLinked) {
      init();
    } else {
      setScreen(EvmDelegatorScreensEnum.HOME);
    }
  }, [hasLinked]);

  if (invalidNetwork) {
    return (
      <ErrorAlert>
        <Text dark={true} style={{ margin: '0.5rem 0' }}>
          {' '}
          Please connect to the {getNetworkText(networkId)} network, to start claiming and
          withdrawing your rewards.
        </Text>
      </ErrorAlert>
    );
  }

  if (loading) {
    return (
      <div className='flex align-center direction-column'>
        <Loader />
        <Text dark={true} style={{ marginTop: '1rem' }}>
          Checking link
        </Text>
      </div>
    );
  }

  if (delay) {
    return (
      <div className='flex align-center direction-column'>
        <Loader />
        <Text dark={true} style={{ marginTop: '1rem' }}>
          Validating link, this may take a couple of minutes
        </Text>
      </div>
    );
  }

  const renderBody = () => {
    return (
      <>
        {!hasLinked && (
          <>
            <Text dark={true}> Please link to start earning rewards</Text>
            <Button onClick={handleLink} loading={linking} disabled={linking} dark={true}>
              {linking ? '...Linking' : 'Link'}
            </Button>
          </>
        )}
      </>
    );
  };

  return <>{renderBody()}</>;
}
