import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { StakingPool } from './staking.types';
import { POLY_CLOSED_POOLS } from './polyPoolsClosed';
export const POLY_POOLS: StakingPool[] = [
  {
    networkId: networkConstant.networkId.polyMainnet,
    stakingTokenSymbol: 'OM',
    stakingContractAddress: '0xCdD0f77A2A158B0C7cFe38d00443E9A4731d6ea6',
    stakingTokenAddress: '0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea',
    stakingTokenDecimals: 18,
    poolType: 'SINGLE',
    tokenBalance: undefined,
    stakedBalance: undefined,
    estimatedDailyReward: undefined,
    rewardsEarned: undefined,
    stakedBalanceUsd: '0',
    apr: undefined,
    fee: 0,
    unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
    strategyType: 'BLOCK',
  },
  {
    networkId: networkConstant.networkId.polyMainnet,
    token0Symbol: 'OM',
    token1Symbol: 'WETH' as ContractType,
    stakingTokenSymbol: 'OM/WETH' as ContractType,
    stakingTokenAddress: '0xFF2BBcB399aD50bbD06DebAdd47D290933ae1038',
    stakingTokenDecimals: 18,
    rewardTokenSymbol: 'OM',
    rewardTokenAddress: '0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea',
    rewardTokenDecimals: 18,
    stakingContractAddress: '0xCBf42Ace1dBD895FFDCaBC1b841488542626014d',
    poolType: 'LP',
    tokenBalance: undefined,
    stakedBalance: undefined,
    stakedBalanceUsd: '0',
    apr: undefined,
    unlockingTime: undefined,
    claimUnlockTime: undefined,
    unstakedBalance: undefined,
    lpTokenRate: 0,
    fee: 0,
    rewardBalance: undefined,
    estimatedDailyReward: undefined,
    rewardsEarned: undefined,
    strategyType: 'SECOND',
  },
  {
    networkId: networkConstant.networkId.polyMainnet,
    token0Symbol: 'WMATIC',
    token1Symbol: 'HELI[0x]' as ContractType,
    stakingTokenSymbol: 'WMATIC/HELI[0x]' as ContractType,
    stakingTokenAddress: '0xA6EC2a30067D72aD1E7b017642092fe89584D050',
    stakingTokenDecimals: 18,
    rewardTokenSymbol: 'HELI[0x]',
    rewardTokenAddress: '0xd30ED32f47c902636bc75D9804a6697B88FFeb31',
    rewardTokenDecimals: 8,
    stakingContractAddress: '0x8485791F389Af0CE535BFAeeb491068C73D5F57f',
    poolType: 'LP',
    tokenBalance: undefined,
    stakedBalance: undefined,
    stakedBalanceUsd: '0',
    apr: undefined,
    unlockingTime: undefined,
    claimUnlockTime: undefined,
    unstakedBalance: undefined,
    lpTokenRate: 0,
    fee: 0,
    rewardBalance: undefined,
    estimatedDailyReward: undefined,
    rewardsEarned: undefined,
    strategyType: 'SECOND',
  },
  ...POLY_CLOSED_POOLS,
];
