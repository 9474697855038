import WalletLink from 'walletlink';

import NetworkProviderService from '../network-provider-service';
import networkHelpers from '../../../helpers/network.helpers';
import networkConstant from '../../../constants/network.constant';
import config from '../../../config/env';

class WalletLinkService extends NetworkProviderService {
  constructor(networkId, isDark) {
    super();

    this.walletLink = new WalletLink({
      appName: 'MANTRA DAO',
      darkMode: isDark,
    });

    switch (networkHelpers.findNetworkType(networkId)) {
      case networkConstant.networkType.binance:
        this.provider = this.walletLink.makeWeb3Provider(config.REACT_APP_BSC_RPC_URL, networkId);
        break;
      case networkConstant.networkType.polygon:
        this.provider = this.walletLink.makeWeb3Provider(
          config.REACT_APP_POLYGON_RPC_URL,
          networkId,
        );
        break;
      default:
        this.provider = this.walletLink.makeWeb3Provider(
          config.REACT_APP_INFURA_RPC_URL,
          networkId,
        );
        break;
    }
  }

  get provider() {
    return this._provider;
  }

  set provider(instance) {
    this._provider = instance;
  }

  get walletLink() {
    return this._walletLink;
  }

  set walletLink(wallet) {
    this._walletLink = wallet;
  }

  async enable() {
    return this.provider.enable();
  }
}

export default WalletLinkService;
