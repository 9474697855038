import COMPOUND_LENS_ABI from '../../../../blockchain/abi/lending/COMPOUND_LENS.json';
import config from '../../../../config/env';
import { findContract } from '../../../../screens/staking/helpers/StakingContractService';

class LendingCompaundLensContractService {
  constructor() {
    this._contract = findContract({
      networkId: 1,
      abi: COMPOUND_LENS_ABI,
      contractAddress: config.REACT_APP_LENDING_COMPAUNDLENS_ADDRESS,
    });
  }

  get contract() {
    return this._contract;
  }

  /**
   * Get cTokens metadata
   * @param {string[]} cTokens
   * @returns {object[]}
   */
  async cTokenMetadataAll(cTokens) {
    return this.contract.methods.cTokenMetadataAll(cTokens).call();
  }

  /**
   * Get balances info by cTokens and Tokens
   * @param {string[]} cTokens
   * @param {string} account
   * @returns {object[]}
   */
  async cTokenBalancesAll(cTokens, account) {
    return this.contract.methods.cTokenBalancesAll(cTokens, account).call();
  }

  /**
   * Get cTokens prices
   * @param {string[]} cTokens
   * @returns {object[]}
   */
  async cTokenUnderlyingPriceAll(cTokens) {
    return this.contract.methods.cTokenUnderlyingPriceAll(cTokens).call();
  }

  /**
   * Get account limitation
   * @param {string} account
   * @returns {object}
   */
  async getAccountLimits(account) {
    return this.contract.methods
      .getAccountLimits(config.REACT_APP_LENDING_COMPTROLLER_ADDRESS, account)
      .call();
  }

  /**
   * Get account limitation
   * @param {string} account
   * @returns {object}
   */
  async getCompBalanceMetadataExt(account) {
    return this.contract.methods
      .getCompBalanceMetadataExt(
        config.REACT_APP_OM_V2_ERC20_ADDRESS,
        config.REACT_APP_LENDING_COMPTROLLER_ADDRESS,
        account,
      )
      .call();
  }
}

export default new LendingCompaundLensContractService();
