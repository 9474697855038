import { SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from '../../../../reducers';
import { DelegatorDataType } from '../../../../services/delegator/delegator.data';
import { SideBarHeader } from '../NominateSidebar/_SidebarHeader';
import { EvmClaim } from './Claim';
import { EvmClaimSuccess } from './ClaimSuccess';
import { EvmDelegationScreen } from './EvmDelegationScreen';
import { EvmError } from './EvmError';
import { LinkWallet } from './Link';
import { EvmWithdrawSuccess } from './WithdrawSuccess';

interface Props {
  setShow: React.Dispatch<SetStateAction<boolean>>;
}

const StyledSidebarWrapper = styled.div`
  padding: 1.5rem;
`;

export enum EvmDelegatorScreensEnum {
  HOME = 'HOME',
  CLAIM_SCREEN = 'CLAIM_SCREEN',
  ERROR = 'ERROR',
  CLAIM_SUCCESS = 'CLAIM_SUCCESS',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
  LINK_WALLET = 'LINK_WALLET',
}

export function EvmSidebarBody({ setShow }: Props) {
  const [screen, setScreen] = useState<EvmDelegatorScreensEnum>(
    EvmDelegatorScreensEnum.LINK_WALLET,
  );

  const { delegatorData, selectedChainId: chainId } = useSelector(({ delegators }: State) => ({
    delegatorData: (delegators as DelegatorDataType).delegatorData,
    selectedChainId: (delegators as DelegatorDataType).selectedChainId,
  }));

  const delegator = delegatorData.filter((del) => del.chainId === chainId)[0];
  const { chainSymbol } = delegator;
  const renderBody = () => {
    if (!chainId) return <>Error</>;

    switch (screen) {
      case EvmDelegatorScreensEnum.LINK_WALLET:
        return <LinkWallet selectedChainId={chainId} setScreen={setScreen} />;
      case EvmDelegatorScreensEnum.HOME:
        return <EvmDelegationScreen selectedChainId={chainId} setScreen={setScreen} />;
      case EvmDelegatorScreensEnum.CLAIM_SCREEN:
        return <EvmClaim selectedChainId={chainId} setScreen={setScreen} />;
      case EvmDelegatorScreensEnum.CLAIM_SUCCESS:
        return (
          <EvmClaimSuccess selectedChainId={chainId} setScreen={setScreen} setShow={setShow} />
        );
      case EvmDelegatorScreensEnum.WITHDRAW_SUCCESS:
        return (
          <EvmWithdrawSuccess selectedChainId={chainId} setScreen={setScreen} setShow={setShow} />
        );

      case EvmDelegatorScreensEnum.ERROR:
        return <EvmError setScreen={setScreen} setShow={setShow} />;
    }
  };

  return (
    <StyledSidebarWrapper>
      <SideBarHeader chain={'POLYGON'} />

      {renderBody()}
    </StyledSidebarWrapper>
  );
}
