import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      viewBox='0 0 49 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5032 0.027832C10.0891 0.027832 0.0254822 10.0935 0.0254822 22.5101C0.0254822 34.9265 10.0891 44.9924 22.5032 44.9924C26.9112 44.9924 31.0078 43.7334 34.4777 41.5406C35.5098 40.9424 36.7026 40.605 37.9811 40.605C40.8526 40.605 43.3208 42.3322 44.4042 44.8046C44.4537 44.9175 44.5642 44.9928 44.6876 44.9928C44.8549 44.9928 44.9905 44.8572 44.9905 44.6899L44.9797 22.7405C44.9803 22.6638 44.981 22.587 44.981 22.5101C44.981 10.0935 34.9172 0.027832 22.5032 0.027832ZM12.4323 22.51C12.4323 28.0731 16.9412 32.5829 22.5031 32.5829C28.0651 32.5829 32.574 28.0731 32.574 22.51C32.574 16.9469 28.0651 12.4372 22.5031 12.4372C16.9412 12.4372 12.4323 16.9469 12.4323 22.51Z'
        fill='url(#alium_paint0_linear)'
      />
      <path
        d='M22.5026 44.9914C26.9106 44.9914 31.0072 43.7324 34.4771 41.5396C35.5092 40.9414 36.702 40.604 37.9805 40.604C40.852 40.604 43.3202 42.3312 44.4036 44.8036C44.4531 44.9165 44.5636 44.9918 44.687 44.9918C44.8543 44.9918 44.9899 44.8562 44.9899 44.6892L44.9791 22.7396C44.9798 22.6629 44.9801 22.5861 44.9801 22.5092C44.9801 20.007 44.5716 17.6002 43.8173 15.3521L31.9293 18.9575C32.3456 20.062 32.5733 21.2591 32.5733 22.5093C32.5733 28.0724 28.0644 32.5822 22.5025 32.5822C16.9405 32.5822 12.4316 28.0724 12.4316 22.5093L1.81631 31.3192C5.24251 39.3572 13.2147 44.9914 22.5026 44.9914Z'
        fill='url(#alium_paint1_linear)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0254822 22.5101C0.0254822 10.0935 10.0891 0.027832 22.5032 0.027832C34.9172 0.027832 44.981 10.0935 44.981 22.5101C44.981 22.587 44.9803 22.6638 44.9797 22.7405L44.9905 44.6899C44.9905 44.8572 44.8549 44.9928 44.6876 44.9928C44.5642 44.9928 44.4537 44.9175 44.4042 44.8046C43.3208 42.3322 40.8526 40.605 37.9811 40.605C36.7026 40.605 35.5098 40.9424 34.4777 41.5406C31.0078 43.7334 26.9112 44.9924 22.5032 44.9924C10.0891 44.9924 0.0254822 34.9265 0.0254822 22.5101ZM24.197 34.5317C30.7087 34.5317 35.9877 29.2518 35.9877 22.7388C35.9877 22.1846 35.9493 21.6393 35.8754 21.1055L32.5068 21.3399C31.9271 16.3281 27.6694 12.4372 22.5031 12.4372C16.9412 12.4372 12.4323 16.9469 12.4323 22.51C12.4323 22.5858 12.4331 22.6614 12.4348 22.7368L12.4065 22.7388C12.4065 29.2518 17.6853 34.5317 24.197 34.5317Z'
        fill='url(#alium_paint2_linear)'
      />
      <path
        d='M44.9899 44.6887C44.9899 44.856 44.8543 44.9916 44.687 44.9916C44.5636 44.9916 44.4531 44.9163 44.4036 44.803C43.3203 42.331 40.852 40.6039 37.9806 40.6039C36.702 40.6039 35.5092 40.9413 34.4772 41.5394C31.0073 43.7323 26.9107 44.9913 22.5027 44.9913C15.85 44.9913 9.87225 42.1003 5.75668 37.5061L16.1368 31.3454C18.245 33.321 21.0794 34.5307 24.1963 34.5307C28.9146 34.5307 32.9856 31.7586 34.8703 27.7539H44.9815L44.9899 44.6887Z'
        fill='url(#alium_paint3_linear)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5032 0.027832C10.0891 0.027832 0.0254822 10.0935 0.0254822 22.5101C0.0254822 34.9265 10.0891 44.9924 22.5032 44.9924C24.895 44.9924 27.1951 44.6216 29.3533 43.9321C19.5287 43.8889 11.5778 35.9095 11.5778 26.0729C11.5778 23.3129 12.2037 20.6992 13.3213 18.3659H13.3214C14.9011 14.8699 18.4181 12.4372 22.5031 12.4372C26.5882 12.4372 30.1052 14.8699 31.6849 18.3659H37.6228L41.64 13.038C42.4717 13.8175 43.2288 14.6758 43.8994 15.6008C40.9847 6.5646 32.5072 0.027832 22.5032 0.027832Z'
        fill='#C4C4C4'
      />
      <path
        d='M22.5032 0.027832C10.0891 0.027832 0.0254822 10.0935 0.0254822 22.5101C0.0254822 34.9265 10.0891 44.9924 22.5032 44.9924C24.895 44.9924 27.1951 44.6216 29.3533 43.9321C19.5287 43.8889 11.5778 35.9095 11.5778 26.0729C11.5778 23.3129 12.2037 20.6992 13.3213 18.3659C14.9011 14.87 18.418 12.4373 22.503 12.4373C26.5881 12.4373 30.105 14.87 31.6848 18.3659H37.6228L41.64 13.038C42.4717 13.8175 43.2288 14.6758 43.8994 15.6008C40.9847 6.5646 32.5072 0.027832 22.5032 0.027832Z'
        fill='url(#alium_paint4_linear)'
      />
      <path
        d='M22.6718 39.4712C26.0031 39.4712 28.9722 37.9207 30.8975 35.502L26.5315 43.4738L22.6718 43.8485L14.7933 39.4712C13.2393 37.8217 10.1313 34.4644 10.1313 34.2313C10.1313 33.9981 11.8763 32.9321 12.7488 32.4282C14.1812 36.5293 18.0831 39.4712 22.6718 39.4712Z'
        fill='url(#alium_paint5_linear)'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M22.5032 0.027832C10.0891 0.027832 0.0254822 10.0935 0.0254822 22.5101C0.0254822 34.9265 10.0891 44.9924 22.5032 44.9924C24.895 44.9924 27.1951 44.6216 29.3533 43.9321C19.5287 43.8889 11.5778 35.9095 11.5778 26.0729C11.5778 23.3129 12.2037 20.6992 13.3213 18.3659C14.9011 14.87 18.418 12.4373 22.503 12.4373C26.5881 12.4373 30.105 14.87 31.6848 18.3659H37.6228L41.64 13.038C42.4717 13.8175 43.2288 14.6758 43.8994 15.6008C40.9847 6.5646 32.5072 0.027832 22.5032 0.027832Z'
          fill='url(#alium_paint6_linear)'
        />
        <path
          opacity='0.7'
          d='M22.6719 39.4712C26.0031 39.4712 28.9722 37.9207 30.8975 35.502L26.5315 43.4738L22.6719 43.8485L14.7933 39.4712C13.2393 37.8217 10.1313 34.4644 10.1313 34.2313C10.1313 33.9981 11.8763 32.9321 12.7488 32.4282C14.1812 36.5293 18.0831 39.4712 22.6719 39.4712Z'
          fill='url(#alium_paint7_linear)'
        />
      </g>
      <path
        opacity='0.4'
        d='M20.7242 44.9219C9.14146 44.015 0.0249329 34.3268 0.0249329 22.5089C0.0249329 15.8956 2.87979 9.94927 7.42411 5.83545L9.79499 7.61286C5.59072 11.2044 2.92528 16.5453 2.92528 22.5089C2.92528 30.4023 7.59487 37.205 14.3218 40.3038C16.4409 41.1923 21.6802 43.0135 25.6839 43.1899C29.6875 43.3666 25.2312 44.4643 22.5026 44.9913C21.9041 44.9913 21.3109 44.9676 20.7242 44.9219Z'
        fill='url(#alium_paint8_radial)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.473771 27.0019L0.558964 27.2762C0.320203 26.1695 0.194479 25.0207 0.194479 23.8426C0.194479 14.8945 7.44684 7.64073 16.3931 7.64073C22.1747 7.64073 27.2488 10.6703 30.1152 15.2289L26.2777 6.01432L16.3931 4.73828L5.90195 7.35145C2.25151 11.3487 0.0249329 16.669 0.0249329 22.5094C0.0249329 24.0479 0.179436 25.5503 0.473771 27.0019Z'
        fill='url(#alium_paint9_linear)'
      />
      <path
        d='M5.19336 8.16521C9.31638 3.19338 15.5398 0.0268555 22.5026 0.0268555C34.9166 0.0268555 44.9805 10.0925 44.9805 22.5091C44.9805 22.586 44.9798 22.6628 44.9791 22.7395L44.9899 44.6891C44.9899 44.8561 44.8543 44.9917 44.687 44.9917C44.5636 44.9917 44.4531 44.9164 44.4036 44.8034C43.3202 42.3311 40.852 40.6039 37.9806 40.6039C36.702 40.6039 35.5092 40.9413 34.4771 41.5395C31.0072 43.7323 26.9106 44.9913 22.5026 44.9913C20.9417 44.9913 19.4179 44.832 17.9465 44.5291C26.6237 42.5988 33.1112 34.8542 33.1112 25.5939C33.1112 14.8808 24.4283 6.19606 13.7173 6.19606C10.659 6.19606 7.76606 6.9041 5.19336 8.16521Z'
        fill='url(#alium_paint10_linear)'
      />
      <path
        d='M12.3116 42.5531C14.8716 43.8578 17.7147 44.6864 20.7243 44.922C21.3111 44.968 21.9041 44.9913 22.5026 44.9913C22.793 44.9352 23.1032 44.8728 23.4205 44.8056L22.5708 42.8525C21.1407 43.6114 19.5094 44.0412 17.7778 44.0412C15.7971 44.0412 13.9477 43.4789 12.3805 42.5054L12.3116 42.5531Z'
        fill='url(#alium_paint11_linear)'
      />
      <path
        opacity='0.4'
        d='M44.9794 22.7395C44.9801 22.6628 44.9808 22.586 44.9808 22.5091C44.9808 10.0925 34.917 0.0268555 22.503 0.0268555C19.7941 0.0268555 17.1971 0.506148 14.7922 1.38458C14.4296 1.51703 14.0713 1.65855 13.7177 1.80888C10.3886 3.22413 7.47084 5.41934 5.19376 8.16521C8.27645 6.09375 17.0122 2.66469 27.2937 5.52004C34.7102 7.608 40.1469 14.4235 40.1469 22.5091C40.1469 22.5697 40.1466 22.6304 40.1459 22.6909L40.1455 22.7164L40.1521 35.9676L40.1546 40.9476C40.6077 41.0954 41.0406 41.2878 41.4478 41.5203C42.7172 42.2443 43.7385 43.3534 44.3524 44.6891C44.3698 44.7268 44.3872 44.7651 44.404 44.8034C44.4535 44.9164 44.5639 44.9917 44.6873 44.9917C44.8547 44.9917 44.9902 44.8561 44.9902 44.6891L44.9794 22.7395Z'
        fill='url(#alium_paint12_radial)'
      />
      <path
        d='M22.5026 0.0268555C34.9166 0.0268555 44.9805 10.0925 44.9805 22.5091L44.9791 22.7395L44.9885 41.7835H43.0546L43.0456 22.7303L43.0466 22.5091C43.0466 11.1606 33.8488 1.96081 22.5026 1.96081C21.0707 1.96081 19.6731 2.10721 18.3239 2.38594C17.2053 2.64385 16.0497 2.99875 14.9003 3.41313C11.054 4.79976 7.27762 6.85241 5.19336 8.16521C6.18904 6.96457 7.30721 5.8692 8.52866 4.89827C12.3653 1.84867 17.2212 0.0268555 22.5026 0.0268555Z'
        fill='url(#alium_paint13_radial)'
      />
      <defs>
        <linearGradient
          id='alium_paint0_linear'
          x1='33.5173'
          y1='44.9928'
          x2='13.3872'
          y2='31.3285'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#069F5F' />
          <stop offset='1' stopColor='#007E49' />
        </linearGradient>
        <linearGradient
          id='alium_paint1_linear'
          x1='32.0907'
          y1='30.9271'
          x2='10.5195'
          y2='42.1015'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#005531' />
          <stop offset='1' stopColor='#005531' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='alium_paint2_linear'
          x1='33.5173'
          y1='44.9928'
          x2='13.3872'
          y2='31.3285'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0FA968' />
          <stop offset='1' stopColor='#088E56' />
        </linearGradient>
        <linearGradient
          id='alium_paint3_linear'
          x1='30.9141'
          y1='39.8973'
          x2='14.5939'
          y2='36.374'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#005531' />
          <stop offset='1' stopColor='#005531' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='alium_paint4_linear'
          x1='12.3125'
          y1='37.9835'
          x2='8.7267'
          y2='16.3894'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0FA968' />
          <stop offset='1' stopColor='#0D995E' />
        </linearGradient>
        <linearGradient
          id='alium_paint5_linear'
          x1='12.3119'
          y1='37.9828'
          x2='8.72613'
          y2='16.3887'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0FA968' />
          <stop offset='1' stopColor='#0D995E' />
        </linearGradient>
        <linearGradient
          id='alium_paint6_linear'
          x1='12.4125'
          y1='31.8317'
          x2='16.7499'
          y2='11.0316'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#008C51' stopOpacity='0' />
          <stop offset='1' stopColor='#00643A' />
        </linearGradient>
        <linearGradient
          id='alium_paint7_linear'
          x1='12.4119'
          y1='31.831'
          x2='16.7493'
          y2='11.0309'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#008C51' stopOpacity='0' />
          <stop offset='1' stopColor='#00643A' />
        </linearGradient>
        <radialGradient
          id='alium_paint8_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-6.49748 37.0638) rotate(-56.5654) scale(29.2511 20.3643)'
        >
          <stop stopColor='#8FFED0' />
          <stop offset='1' stopColor='#8FFED0' stopOpacity='0' />
        </radialGradient>
        <linearGradient
          id='alium_paint9_linear'
          x1='25.6647'
          y1='25.3649'
          x2='23.8701'
          y2='7.60671'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#13AC6C' />
          <stop offset='1' stopColor='#15BF78' />
        </linearGradient>
        <linearGradient
          id='alium_paint10_linear'
          x1='39.1036'
          y1='41.1787'
          x2='33.7714'
          y2='6.20066'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#24BA7B' />
          <stop offset='1' stopColor='#33D993' />
        </linearGradient>
        <linearGradient
          id='alium_paint11_linear'
          x1='39.1036'
          y1='41.1787'
          x2='33.7714'
          y2='6.20071'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#24BA7B' />
          <stop offset='1' stopColor='#33D993' />
        </linearGradient>
        <radialGradient
          id='alium_paint12_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(29.1812 10.0579) rotate(59.3695) scale(27.7189 24.5353)'
        >
          <stop stopColor='#91FFD1' />
          <stop offset='1' stopColor='#90FFD1' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='alium_paint13_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(32.1545 4.62004) rotate(67.4688) scale(29.0922 26.1746)'
        >
          <stop stopColor='#A6FFD9' />
          <stop offset='1' stopColor='#A6FFD9' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
}
