import * as React from 'react';

function SvgTomoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#242828' />
      <path d='M19.432 9.05v11.353l-5.682 5.682V14.733l5.682-5.682z' fill='#fff' />
      <path
        d='M36.466 31.766c0 6.266-5.085 11.352-11.352 11.352-6.278 0-11.364-5.086-11.364-11.352v-5.682h5.682v5.682a5.68 5.68 0 005.682 5.682 5.68 5.68 0 005.682-5.682h5.67z'
        fill='#fff'
      />
      <path d='M36.466 20.402l-5.682 5.682H13.75l5.682-5.682h17.034z' fill='#00E8B5' />
    </svg>
  );
}

export default SvgTomoIcon;
