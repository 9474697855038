import * as React from 'react';

function SvgKexIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#1E0644' />
      <path
        d='M29.018 19.989a1.62 1.62 0 00-.01-2.79l-7.983-4.68a2.836 2.836 0 00-2.816-.03l-6.497 3.625a1.557 1.557 0 00-.798 1.36v3.34a.81.81 0 001.62 0v-1.277c0-.89.97-1.441 1.735-.987l7.341 4.355c.775.46 1.738.459 2.512-.002l4.896-2.914z'
        fill='url(#KexIcon_svg__paint0_linear)'
      />
      <path
        d='M27.703 31.131a1.62 1.62 0 002.422 1.388l8.044-4.574a2.836 2.836 0 001.435-2.425l.108-7.439a1.557 1.557 0 00-.778-1.37l-2.893-1.67a.81.81 0 00-.81 1.403l1.105.639c.77.445.764 1.559-.012 1.995l-7.442 4.18a2.458 2.458 0 00-1.254 2.176l.075 5.697z'
        fill='url(#KexIcon_svg__paint1_linear)'
      />
      <path
        d='M18.665 24.686a1.62 1.62 0 00-2.412 1.404l-.061 9.254a2.836 2.836 0 001.383 2.454l6.388 3.813c.484.29 1.087.294 1.576.011l2.893-1.67a.81.81 0 10-.81-1.403l-1.106.638a1.148 1.148 0 01-1.722-1.008l.1-8.535c.011-.9-.471-1.735-1.257-2.175l-4.972-2.783z'
        fill='url(#KexIcon_svg__paint2_linear)'
      />
      <defs>
        <linearGradient
          id='KexIcon_svg__paint0_linear'
          x1={25.313}
          y1={12.129}
          x2={25.313}
          y2={41.831}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A13ED1' />
          <stop offset={1} stopColor='#7230D4' />
        </linearGradient>
        <linearGradient
          id='KexIcon_svg__paint1_linear'
          x1={25.313}
          y1={12.129}
          x2={25.313}
          y2={41.831}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A13ED1' />
          <stop offset={1} stopColor='#7230D4' />
        </linearGradient>
        <linearGradient
          id='KexIcon_svg__paint2_linear'
          x1={25.313}
          y1={12.129}
          x2={25.313}
          y2={41.831}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A13ED1' />
          <stop offset={1} stopColor='#7230D4' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgKexIcon;
