/* eslint-disable no-console */
import BN from 'bignumber.js';

import {
  GET_OM_BALANCE,
  GET_OM_V2_BALANCE,
  GET_OM_V3_BALANCE,
  GET_BNB_FINE_BALANCE,
  GET_BNB_OM_BALANCE,
  GET_KYL_BALANCE,
  GET_PKF_BALANCE,
  GET_ROYA_BALANCE,
  GET_RFUEL_BALANCE,
  GET_UNI_BALANCE,
  GET_UNI_V2_BALANCE,
  GET_ROYA_UNI_BALANCE,
  GET_FXF_BALANCE,
  GET_LABS_UNI_BALANCE,
  GET_L3P_BALANCE,
  GET_LABS_UNI_SIMPLE_BALANCE,
  GET_LABS_BALANCE,
  GET_RAZE_BALANCE,
  GET_RAZE_ETH_UNI_BALANCE,
  GET_BNB_MIST_BALANCE,
  GET_ROSN_BALANCE,
  GET_BNB_CBD_BALANCE,
  GET_BNB_BBANK_BALANCE,
  GET_BBANK_ETH_UNI_BALANCE,
  GET_BNB_ROSN_BALANCE,
  GET_BONDLY_ETH_UNI_BALANCE,
  GET_BONDLY_WBNB_BALANCE,
  GET_BONDLY_USDT_UNI_BALANCE,
  GET_BONDLY_ETH_BALANCE,
  GET_BONDLY_BSC_BALANCE,
  GET_BITE_BALANCE,
  GET_BITE_ETH_UNI_BALANCE,
  GET_BCUBE_BALANCE,
  GET_BITE_ETH_INTERVALS_BALANCE,
  GET_BCUBE_ETH_UNI_BALANCE,
  GET_OM_BSC_BALANCE,
  GET_ALPHA_BALANCE,
  GET_ALPHA_ETH_UNI_BALANCE,
  GET_LABS_GROUP_BALANCE,
  GET_SPWN_BALANCE,
  GET_MLT_BALANCE,
  GET_BNB_MLT_BALANCE,
  GET_SRFUEL_BALANCE,
  GET_ZEN_DAI_UNI_BALANCE,
  GET_ZENUSDT_OM_ETH_BALANCE,
  GET_ZENUSDC_UNI_ETH_BALANCE,
  GET_OM_POLYGON_BALANCE,
  GET_ZEN_UST_UNI_BALANCE,
  GET_ZEN_WBTC_UNI_BALANCE,
  GET_ZEN_ETH_UNI_BALANCE,
  GET_OM_ETH_POLYGON_BALANCE,
  GET_CIRUS_BALANCE,
  GET_SKYRIM_BALANCE,
  GET_GAMER_POLYGON_BALANCE,
  GET_STFI_WBNB_UNI_BALANCE,
  GET_L3P_WBNB_UNI_BALANCE,
  GET_STFI_BALANCE,
  GET_GAMER_WETH_POLYGON_BALANCE,
  GET_FACTR_WBNB_UNI_BALANCE,
  GET_FACTR_BALANCE,
  GET_GAMER_WBNB_UNI_BALANCE,
} from './action-types';
import {
  FXF,
  L3P,
  LABS_UNI,
  OM,
  OM_BSC,
  OM_V2,
  OM_V3,
  BNB_FINE,
  BNB_OM,
  RFUEL,
  ROYA,
  UNI,
  ROYA_UNI,
  UNI_V2,
  PKF,
  LABS_UNI_SIMPLE,
  LABS,
  KYL,
  RAZE,
  RAZE_ETH_UNI,
  BNB_MIST,
  ROSN,
  BNB_CBD,
  BNB_BBANK,
  BBANK_ETH_UNI,
  BNB_ROSN,
  BONDLY_ETH_UNI,
  BONDLY_WBNB,
  BONDLY_USDT_UNI,
  BONDLY_ETH,
  BONDLY_BSC,
  BITE,
  BITE_ETH_UNI,
  BCUBE,
  BITE_ETH_INTERVALS,
  BCUBE_ETH_UNI,
  ALPHA,
  ALPHA_ETH_UNI,
  LABS_GROUP,
  SPWN,
  MLT,
  BNB_MLT,
  SRFUEL,
  ZEN_DAI_UNI,
  ZENUSDT_OM_ETH,
  ZENUSDC_UNI_ETH,
  OM_POLYGON,
  ZEN_UST_UNI,
  ZEN_WBTC_UNI,
  ZEN_ETH_UNI,
  OM_ETH_POLYGON,
  SKYRIM,
  CIRUS,
  GAMER_POLYGON,
  STFI_WBNB_UNI,
  STFI,
  L3P_WBNB_UNI,
  FACTR_WBNB_UNI,
  GAMER_WETH_POLYGON,
  GAMER_WBNB_UNI,
  FACTR,
} from '../constants/blockchain-constants';
import omTokenContractService from '../services/contracts/om-token-contract-service';
import omTokenContractServiceBsc from '../services/contracts/om-token-contract-service-bsc';
import omTokenContractServicePolygon from '../services/contracts/om-token-contract-service-polygon';
import gamerTokenContractServicePolygon from '../services/contracts/gamer-token-contract-service-polygon';
import gamerWhethTokenContractServicePolygon from '../services/contracts/gamer-weth-token-contract-service-polygon';
import omEthTokenContractServicePolygon from '../services/contracts/om-eth-token-contract-service-polygon';
import omV2TokenContractService from '../services/contracts/om-v2-token-contract-service';
import omV3TokenContractService from '../services/contracts/om-v3-token-contract-service';
import bnbFineTokenContractService from '../services/contracts/bnb-fine-token-contract-service';
import bnbMistTokenContractService from '../services/contracts/bnb-mist-token-contract-services';
import bnbCbdTokenContractService from '../services/contracts/bnb-cbd-token-contract-services';
import bnbBbankTokenContractService from '../services/contracts/bnb-bbank-token-contract-services';
import bnbMltTokenContractService from '../services/contracts/bnb-mlt-token-contract-services';
import bnbRosnTokenContractService from '../services/contracts/bnb-rosn-token-contract-services';
import bondlyWbnbTokenContractService from '../services/contracts/bondly-wbnb-token-contract-services';
import bnbOmTokenContractService from '../services/contracts/bnb-om-token-contract-service';
import razeTokenContractService from '../services/contracts/raze-token-contract-service';
import sRfuelTokenContractService from '../services/contracts/srfuel-token-contract-service';
import biteTokenContractService from '../services/contracts/bite-token-contract-service';
import skyrimTokenContractService from '../services/contracts/skyrim-token-contract-service';
import cirusTokenContractService from '../services/contracts/cirus-token-contract-service';
import alphaTokenContractService from '../services/contracts/alpha-token-contract-service';
import bcubeTokenContractService from '../services/contracts/bcube-token-contract-service';
import spwnTokenContractService from '../services/contracts/spwn-token-contract-service';
import labsGroupTokenContractService from '../services/contracts/labs-group-token-contract-service';
import bondlyEthTokenContractService from '../services/contracts/bondly-eth-token-contract-service';
import rosnTokenContractService from '../services/contracts/rosn-token-contract-service';
import mltTokenContractService from '../services/contracts/mlt-token-contract-service';
import bondlyBscTokenContractService from '../services/contracts/bondly-bsc-token-contract-service';
import kylTokenContractService from '../services/contracts/kyl-token-contract-service';
import pkfTokenContractService from '../services/contracts/pkf-token-contract-service';
import labsTokenContractService from '../services/contracts/labs-token-contract-service';
import l3pTokenContractService from '../services/contracts/l3p-token-contract-service';
import royaTokenContractService from '../services/contracts/roya-token-contract-service';
import fxfTokenContractService from '../services/contracts/fxf-token-contract-service';
import rfuelTokenContractService from '../services/contracts/rfuel-token-contract-service';
import uniswapOmEthContractService from '../services/contracts/uniswap-om-eth-contract-services';
import uniswapV2OmEthContractService from '../services/contracts/uniswap-v2-om-eth-contract-services';
import labsUniSimpleEthContractService from '../services/contracts/labs-uni-simple-eth-contract-services';
import labsUniContractService from '../services/contracts/labs-uni-contract-services';
import royaUniContractService from '../services/contracts/roya-uni-contract-services';
import razeEthUniContractService from '../services/contracts/raze-eth-uni-contract-services';
import bbankEthUniContractService from '../services/contracts/bbank-eth-uni-contract-services';
import bondlyEthUniContractService from '../services/contracts/bondly-eth-uni-contract-services';
import bcubeEthUniContractService from '../services/contracts/bcube-eth-uni-contract-services';
import alphaEthUniContractService from '../services/contracts/alpha-eth-uni-contract-services';
import biteEthUniContractService from '../services/contracts/bite-eth-uni-contract-services';
import zenUsdtOmEthContractService from '../services/contracts/zenusdt-om-eth-contract-services';
import zenUsdcUniEthContractService from '../services/contracts/zenusdc-uni-eth-contract-services';
import biteEthIntervalsContractService from '../services/contracts/bite-eth-intervals-contract-services';
import bondlyUsdtUniContractService from '../services/contracts/bondly-usdt-uni-contract-services';
import zenDaiUniContractService from '../services/contracts/zen-dai-uni-contract-services';
import zenUstUniContractService from '../services/contracts/zen-ust-uni-contract-services';
import zenWbtcUniContractService from '../services/contracts/zen-wbtc-uni-contract-services';
import zenEthUniContractService from '../services/contracts/zen-eth-uni-contract-services';
import stfiWbnbUniContractService from '../services/contracts/stfi-wbnb-uni-contract-services';
import stfiContractService from '../services/contracts/stfi-token-contract-service';
import factrContractService from '../services/contracts/factr-token-contract-service';
import l3pWbnbUniContractService from '../services/contracts/l3p-wbnb-uni-contract-services';
import gamerWbnbUniContractService from '../services/contracts/gamer-wbnb-uni-contract-services';
import factrWbnbUniContractService from '../services/contracts/factr-wbnb-uni-contract-services';
import web3Service from '../services/web3-service';
import Erc20TokenService from '../services/contracts/erc20-contract-service';
import config from '../config/env';

export const getBalance = (contractAddress) => async (dispatch, getState) => {
  const account = getState().account.address;

  if (!account) {
    return '0';
  }

  try {
    const tokenService = new Erc20TokenService(contractAddress);
    const balance = await tokenService.balanceOf(account);
    return balance;
  } catch (e) {
    return '0';
  }
};

export const getOmBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_ERC20_ADDRESS));
  dispatch({ type: GET_OM_BALANCE, payload: balance });
};

export const getOmBcsBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_ERC20_ADDRESS_BSC));
  dispatch({ type: GET_OM_BSC_BALANCE, payload: balance });
};

export const getOmPolygonBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_ERC20_ADDRESS_POLYGON));
  dispatch({ type: GET_OM_POLYGON_BALANCE, payload: balance });
};

export const getGamerPolygonBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_GAMER_ERC20_ADDRESS_POLYGON));
  dispatch({ type: GET_GAMER_POLYGON_BALANCE, payload: balance });
};

export const getOmEthPolygonBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_ETH_ERC20_ADDRESS_POLYGON));
  dispatch({ type: GET_OM_ETH_POLYGON_BALANCE, payload: balance });
};

export const getGamerWethPolygonBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_GAMER_WETH_ERC20_ADDRESS_POLYGON));
  dispatch({ type: GET_GAMER_WETH_POLYGON_BALANCE, payload: balance });
};

export const getOmV2Balance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_V2_ERC20_ADDRESS));
  dispatch({ type: GET_OM_V2_BALANCE, payload: balance });
};

export const getOmV3Balance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_OM_V3_ERC20_ADDRESS));
  dispatch({ type: GET_OM_V3_BALANCE, payload: balance });
};

export const getBnbFineBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_FINE_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_FINE_BALANCE, payload: balance });
};

export const getBnbOmBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_OM_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_OM_BALANCE, payload: balance });
};

export const getRazeBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_RAZE_ERC20_ADDRESS));
  dispatch({ type: GET_RAZE_BALANCE, payload: balance });
};

export const getSRfuelBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_SRFUEL_ERC20_ADDRESS));
  dispatch({ type: GET_SRFUEL_BALANCE, payload: balance });
};

export const getBiteBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BITE_ERC20_ADDRESS));
  dispatch({ type: GET_BITE_BALANCE, payload: balance });
};

export const getCirusBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_CIRUS_ERC20_ADDRESS));
  dispatch({ type: GET_CIRUS_BALANCE, payload: balance });
};

export const getSkyrimBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_SKYRIM_ERC20_ADDRESS));
  dispatch({ type: GET_SKYRIM_BALANCE, payload: balance });
};

export const getBcubeBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BCUBE_ERC20_ADDRESS));
  dispatch({ type: GET_BCUBE_BALANCE, payload: balance });
};

export const getSpwnBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_SPWN_ERC20_ADDRESS));
  dispatch({ type: GET_SPWN_BALANCE, payload: balance });
};

export const getLabsGroupBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_LABS_GROUP_ERC20_ADDRESS));
  dispatch({ type: GET_LABS_GROUP_BALANCE, payload: balance });
};

export const getAlphaBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ALPHA_ERC20_ADDRESS));
  dispatch({ type: GET_ALPHA_BALANCE, payload: balance });
};

export const getBondlyEthBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BONDLY_ETH_ERC20_ADDRESS));
  dispatch({ type: GET_BONDLY_ETH_BALANCE, payload: balance });
};

export const getRosnBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ROSN_ERC20_ADDRESS));
  dispatch({ type: GET_ROSN_BALANCE, payload: balance });
};

export const getMltBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_MLT_ERC20_ADDRESS));
  dispatch({ type: GET_MLT_BALANCE, payload: balance });
};

export const getBondlyBscBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BONDLY_BSC_ERC20_ADDRESS));
  dispatch({ type: GET_BONDLY_BSC_BALANCE, payload: balance });
};

export const getKylBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_KYL_ERC20_ADDRESS));
  dispatch({ type: GET_KYL_BALANCE, payload: balance });
};

export const getPKFBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_PKF_ERC20_ADDRESS));
  dispatch({ type: GET_PKF_BALANCE, payload: balance });
};

export const getLabsBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_LABS_ERC20_ADDRESS));
  dispatch({ type: GET_LABS_BALANCE, payload: balance });
};

export const getL3PBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_L3P_ERC20_ADDRESS));
  dispatch({ type: GET_L3P_BALANCE, payload: balance });
};

export const getRoyaBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ROYA_ERC20_ADDRESS));
  dispatch({ type: GET_ROYA_BALANCE, payload: balance });
};

export const getFxfBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_FXF_ERC20_ADDRESS));
  dispatch({ type: GET_FXF_BALANCE, payload: balance });
};

export const getRfuelBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_RFUEL_ERC20_ADDRESS));
  dispatch({ type: GET_RFUEL_BALANCE, payload: balance });
};

export const getUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_UNI_BALANCE, payload: balance });
};

export const getUniV2Balance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_UNI_V2_ERC20_ADDRESS));
  dispatch({ type: GET_UNI_V2_BALANCE, payload: balance });
};

export const getLabsUniSimpleBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_LABS_UNI_SIMPLE_ERC20_ADDRESS));
  dispatch({ type: GET_LABS_UNI_SIMPLE_BALANCE, payload: balance });
};

export const getRoyaUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ROYA_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ROYA_UNI_BALANCE, payload: balance });
};

export const getRazeEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_RAZE_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_RAZE_ETH_UNI_BALANCE, payload: balance });
};

export const getBbankEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BBANK_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_BBANK_ETH_UNI_BALANCE, payload: balance });
};

export const getBondlyEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BONDLY_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_BONDLY_ETH_UNI_BALANCE, payload: balance });
};

export const getBcubeEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BCUBE_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_BCUBE_ETH_UNI_BALANCE, payload: balance });
};

export const getAlphaEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ALPHA_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ALPHA_ETH_UNI_BALANCE, payload: balance });
};

export const getBiteEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BITE_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_BITE_ETH_UNI_BALANCE, payload: balance });
};

export const getZenUsdtOmEthBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZENUSDT_OM_ETH_ERC20_ADDRESS));
  dispatch({ type: GET_ZENUSDT_OM_ETH_BALANCE, payload: balance });
};

export const getZenUsdcUniEthBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZENUSDC_UNI_ETH_ERC20_ADDRESS));
  dispatch({ type: GET_ZENUSDC_UNI_ETH_BALANCE, payload: balance });
};

export const getBiteEthIntervalsBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BITE_ETH_INTERVALS_ERC20_ADDRESS));
  dispatch({ type: GET_BITE_ETH_INTERVALS_BALANCE, payload: balance });
};

export const getBondlyUsdtUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BONDLY_USDT_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_BONDLY_USDT_UNI_BALANCE, payload: balance });
};

export const getBnbMistBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_MIST_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_MIST_BALANCE, payload: balance });
};

export const getBnbCbdBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_CBD_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_CBD_BALANCE, payload: balance });
};

export const getBnbBbankBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_BBANK_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_BBANK_BALANCE, payload: balance });
};

export const getBnbMltBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_MLT_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_MLT_BALANCE, payload: balance });
};

export const getBnbRosnBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BNB_ROSN_ERC20_ADDRESS));
  dispatch({ type: GET_BNB_ROSN_BALANCE, payload: balance });
};

export const getBondlyWbnbBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_BONDLY_WBNB_ERC20_ADDRESS));
  dispatch({ type: GET_BONDLY_WBNB_BALANCE, payload: balance });
};

export const getLabsUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_LABS_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_LABS_UNI_BALANCE, payload: balance });
};

export const getZenDaiUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZEN_DAI_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ZEN_DAI_UNI_BALANCE, payload: balance });
};

export const getZenUstUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZEN_UST_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ZEN_UST_UNI_BALANCE, payload: balance });
};

export const getZenWbtcUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZEN_WBTC_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ZEN_WBTC_UNI_BALANCE, payload: balance });
};

export const getZenEthUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_ZEN_ETH_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_ZEN_ETH_UNI_BALANCE, payload: balance });
};

export const getStfiWbnbUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_STFI_WBNB_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_STFI_WBNB_UNI_BALANCE, payload: balance });
};

export const getL3pWbnbUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_L3P_WBNB_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_L3P_WBNB_UNI_BALANCE, payload: balance });
};

export const getFactrWbnbUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_FACTR_WBNB_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_FACTR_WBNB_UNI_BALANCE, payload: balance });
};

export const getGamerWbnbUniBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_GAMER_WBNB_UNI_ERC20_ADDRESS));
  dispatch({ type: GET_GAMER_WBNB_UNI_BALANCE, payload: balance });
};

export const getStfiBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_STFI_ERC20_ADDRESS));
  dispatch({ type: GET_STFI_BALANCE, payload: balance });
};

export const getFactrBalance = () => async (dispatch) => {
  const balance = await dispatch(getBalance(config.REACT_APP_FACTR_ERC20_ADDRESS));
  dispatch({ type: GET_FACTR_BALANCE, payload: balance });
};

export const approve = async (
  token,
  address,
  contractAddress,
  amount = '100000000000000000000000000000',
) => {
  const service = {
    [OM]: omTokenContractService,
    [OM_V2]: omV2TokenContractService,
    [OM_V3]: omV3TokenContractService,
    [OM_BSC]: omTokenContractServiceBsc,
    [OM_POLYGON]: omTokenContractServicePolygon,
    [OM_ETH_POLYGON]: omEthTokenContractServicePolygon,
    [GAMER_POLYGON]: gamerTokenContractServicePolygon,
    [GAMER_WETH_POLYGON]: gamerWhethTokenContractServicePolygon,
    [BNB_FINE]: bnbFineTokenContractService,
    [BNB_MIST]: bnbMistTokenContractService,
    [BNB_CBD]: bnbCbdTokenContractService,
    [BNB_BBANK]: bnbBbankTokenContractService,
    [BNB_MLT]: bnbMltTokenContractService,
    [BNB_ROSN]: bnbRosnTokenContractService,
    [BNB_OM]: bnbOmTokenContractService,
    [RAZE]: razeTokenContractService,
    [SRFUEL]: sRfuelTokenContractService,
    [BITE]: biteTokenContractService,
    [SKYRIM]: skyrimTokenContractService,
    [CIRUS]: cirusTokenContractService,
    [BCUBE]: bcubeTokenContractService,
    [SPWN]: spwnTokenContractService,
    [ALPHA]: alphaTokenContractService,
    [ROSN]: rosnTokenContractService,
    [MLT]: mltTokenContractService,
    [KYL]: kylTokenContractService,
    [PKF]: pkfTokenContractService,
    [LABS]: labsTokenContractService,
    [L3P]: l3pTokenContractService,
    [ROYA]: royaTokenContractService,
    [FXF]: fxfTokenContractService,
    [RFUEL]: rfuelTokenContractService,
    [UNI]: uniswapOmEthContractService,
    [UNI_V2]: uniswapV2OmEthContractService,
    [LABS_UNI_SIMPLE]: labsUniSimpleEthContractService,
    [ROYA_UNI]: royaUniContractService,
    [LABS_UNI]: labsUniContractService,
    [RAZE_ETH_UNI]: razeEthUniContractService,
    [BBANK_ETH_UNI]: bbankEthUniContractService,
    [BONDLY_ETH_UNI]: bondlyEthUniContractService,
    [BCUBE_ETH_UNI]: bcubeEthUniContractService,
    [ALPHA_ETH_UNI]: alphaEthUniContractService,
    [BITE_ETH_UNI]: biteEthUniContractService,
    [ZENUSDT_OM_ETH]: zenUsdtOmEthContractService,
    [ZENUSDC_UNI_ETH]: zenUsdcUniEthContractService,
    [BITE_ETH_INTERVALS]: biteEthIntervalsContractService,
    [BONDLY_WBNB]: bondlyWbnbTokenContractService,
    [BONDLY_USDT_UNI]: bondlyUsdtUniContractService,
    [BONDLY_ETH]: bondlyEthTokenContractService,
    [BONDLY_BSC]: bondlyBscTokenContractService,
    [LABS_GROUP]: labsGroupTokenContractService,
    [ZEN_DAI_UNI]: zenDaiUniContractService,
    [ZEN_UST_UNI]: zenUstUniContractService,
    [ZEN_WBTC_UNI]: zenWbtcUniContractService,
    [ZEN_ETH_UNI]: zenEthUniContractService,
    [STFI_WBNB_UNI]: stfiWbnbUniContractService,
    [L3P_WBNB_UNI]: l3pWbnbUniContractService,
    [FACTR_WBNB_UNI]: factrWbnbUniContractService,
    [GAMER_WBNB_UNI]: gamerWbnbUniContractService,
    [STFI]: stfiContractService,
    [FACTR]: factrContractService,
  };

  if (!service[token]) {
    throw new Error('Token service not found');
  }

  const allowance = await service[token].allowance(address, contractAddress);

  if (new BN(allowance).isGreaterThanOrEqualTo('100000000000000000000')) {
    return;
  }

  await service[token].approve(address, contractAddress, amount);

  await new Promise((resolve, reject) => {
    const approvalInterval = setInterval(async () => {
      try {
        const blockNumber = await web3Service.eth.getBlockNumber();
        const newAllowance = await service[token].allowance(
          address,
          contractAddress,
          {},
          blockNumber - 2,
        );
        if (new BN(newAllowance).isGreaterThanOrEqualTo(amount)) {
          resolve();
          clearInterval(approvalInterval);
        }
      } catch (e) {
        reject(e);
        clearInterval(approvalInterval);
      }
    }, 2000);
  });
};
