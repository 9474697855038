import axios from 'axios';
import config from '../../config/env';

export default {
  getValidatorAccounting: async function (chainId: number) {
    //   Todo add chain id
    const { data: axiosResult } = await axios.get(`${config.REACT_APP_API_URL}/v1/validator/list`);
    const { data: validators } = axiosResult;
    return validators;
  },
};
