import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='-61.97 -239.65 1103.74 2137.9'
      className={className}
      {...otherProps}
    >
      <path fill='#8A92B2' d='M539.7 650.3V0L0 895.6z'></path>
      <path fill='#62688F' d='M539.7 1214.7V650.3L0 895.6zm0-564.4l539.8 245.3L539.7 0z'></path>
      <path fill='#454A75' d='M539.7 650.3v564.4l539.8-319.1z'></path>
      <path fill='#8A92B2' d='M539.7 1316.9L0 998l539.7 760.6z'></path>
      <path fill='#62688F' d='M1079.8 998l-540.1 318.9v441.7z'></path>
    </svg>
  );
}
