export const HOURS_IN_DAY = 24;

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
  NEUTRAL: 'NEUTRAL',
};

export const MANTRA_DAO_ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.mdao.mobile';

export const MANTRA_DAO_IOS_APP_LINK = 'https://apps.apple.com/by/app/mantra-wallet/id1546862610';
