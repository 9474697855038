import _ from 'lodash';
import compaundLensContractService from '../services/contracts/lending/compaundlens-contract-service';
import comptrollerContractService from '../services/contracts/lending/comproller-contract-service';
import omV2TokenContractService from '../services/contracts/om-v2-token-contract-service';
import ZenTokenService from '../services/contracts/lending/zentoken-contract-service';
import web3Service from '../services/web3-service-eth';
import Erc20TokenService from '../services/contracts/erc20-contract-service';
import { initData as initStakedZenUsdtData } from './zenusdt-om-eth-stake-actions';
import { initData as initStakedZenDaiData } from './zen-dai-uni-stake-actions';
import { initData as initStakedZenUsdcData } from './zenusdc-uni-eth-stake-actions';
import { initData as initStakedZenEthData } from './zen-eth-uni-stake-actions';
import { initData as initStakedZenUstData } from './zen-ust-uni-stake-actions';
import { initData as initStakedZenWbtcData } from './zen-wbtc-uni-stake-actions.js';
import { EXP_DECIMALS } from '../constants/blockchain-constants';
import {
  OPERATION_DECIMAL_PLACES,
  LENDING_ZEN_TOKENS,
  LENDING_TOKENS_CONSTANTS,
  ZEN_TOKEN_DECIMALS,
  ZEN_TOKEN_ADRESSES,
} from '../constants/lending-constants';
import { UINT_256_MAX_BN } from '../constants/blockchain-constants';
import { toBN, toBNScaled } from '../helpers/token-helper';
import {
  getZenTokenAPY,
  isTokenEnable,
  getZenDistributionAPY,
  getTotalDistributionAPY,
  getZenDistributionAPYWithoutPrice,
} from '../helpers/lending-helper';
import {
  LENDING_TOKENS,
  LENDING_NET_APY,
  LENDING_SUPPLY_BALANCE,
  LENDING_BORROW_BALANCE,
  LENDING_ACCOUNT_LIQUIDITY,
  LENDING_BORROW_LIMIT,
  LENDING_DISTRIBUTED_OM_REWARD,
  LENDING_WALLET_BALANCE,
} from './action-types';

export const findTokenByAddress = (zenToken) => (_, getState) => {
  const { lending } = getState();
  const { commonSupplyTokens, borrowTokens, supplyTokens } = lending;

  const token = [...borrowTokens, ...supplyTokens, ...commonSupplyTokens].find(
    ({ zenTokenAddress }) => zenTokenAddress === zenToken,
  );
  if (!token) {
    throw new Error('Unknown token');
  }

  return token;
};

export const initStakedZenTokens = () => async (dispatch, getState) => {
  dispatch(await initStakedZenUsdtData());
  dispatch(await initStakedZenDaiData());
  dispatch(await initStakedZenUsdcData());
  dispatch(await initStakedZenEthData());
  dispatch(await initStakedZenUstData());
  dispatch(await initStakedZenWbtcData());
};

const getExchangeRates = async () => {
  const web3Batch = new web3Service.eth.BatchRequest();

  const result = await new Promise((res) => {
    const batchResult = {};
    let requestsCount = ZEN_TOKEN_ADRESSES.length;
    let resultsCount = 0;
    ZEN_TOKEN_ADRESSES.forEach((zenTokenAddress, index) => {
      const zenTokenService = new ZenTokenService(zenTokenAddress);
      const { underlyingDecimals } = LENDING_ZEN_TOKENS[zenTokenAddress];

      web3Batch.add(
        zenTokenService.exchangeRateStoredRequest((error, exchangeRate) => {
          resultsCount += 1;
          if (error) {
            return;
          }

          batchResult[zenTokenAddress] = {
            exchangeRate: toBNScaled(
              exchangeRate,
              EXP_DECIMALS + underlyingDecimals - ZEN_TOKEN_DECIMALS,
            ),
          };

          if (resultsCount === requestsCount) {
            res(batchResult);
          }
        }),
      );

      requestsCount = index + 1;
    });
    web3Batch.execute();
  });

  return result;
};

const getCompSpeeds = async () => {
  const web3Batch = new web3Service.eth.BatchRequest();

  const result = await new Promise((res) => {
    const batchResult = {};
    let requestsCount = ZEN_TOKEN_ADRESSES.length;
    let resultsCount = 0;
    ZEN_TOKEN_ADRESSES.forEach((zenTokenAddress, index) => {
      web3Batch.add(
        comptrollerContractService.compSpeedsRequest(zenTokenAddress, (error, omSpeedsPerBlock) => {
          resultsCount += 1;
          if (error) {
            return;
          }

          batchResult[zenTokenAddress] = {
            omSpeedsPerBlock: toBNScaled(omSpeedsPerBlock, EXP_DECIMALS),
          };

          if (resultsCount === requestsCount) {
            res(batchResult);
          }
        }),
      );

      requestsCount = index + 1;
    });
    web3Batch.execute();
  });

  return result;
};

export const getZenTokensAll = () => async (dispatch, getState) => {
  const { account, basic } = getState();
  const { address: accountAddress } = account;
  const {
    currency: {
      USD: { ETH: ethUsdPrice, OM: omUsdPrice },
    },
  } = basic;

  const isWalletConnected = !!accountAddress;
  const aggregatedData = ZEN_TOKEN_ADRESSES.reduce((a, i) => ({ ...a, [i]: {} }), {});
  let normalizedAccountMarketZenTokens = [];

  const [
    exchangeRatesMap,
    compSpeedsMap,
    tokensMetadata,
    tokensUnderlyingPrices,
    accountLimits,
    tokensBalances,
  ] = await Promise.all([
    getExchangeRates(),
    getCompSpeeds(),
    compaundLensContractService.cTokenMetadataAll(ZEN_TOKEN_ADRESSES),
    compaundLensContractService.cTokenUnderlyingPriceAll(ZEN_TOKEN_ADRESSES),
    ...(isWalletConnected
      ? [
          compaundLensContractService.getAccountLimits(accountAddress),
          compaundLensContractService.cTokenBalancesAll(ZEN_TOKEN_ADRESSES, accountAddress),
        ]
      : []),
  ]);

  if (isWalletConnected) {
    const { markets, liquidity } = accountLimits;
    normalizedAccountMarketZenTokens = markets.map((address) => address.toLocaleLowerCase());

    dispatch({
      type: LENDING_ACCOUNT_LIQUIDITY,
      payload: toBNScaled(liquidity, EXP_DECIMALS),
    });
  }

  // set stored exchange rates for each zenTokens
  Object.keys(exchangeRatesMap).forEach((zenToken) => {
    const zenTokenAddress = zenToken.toLowerCase();
    aggregatedData[zenTokenAddress].exchangeRate = exchangeRatesMap[zenTokenAddress].exchangeRate;
  });

  // set stored exchange rates for each zenTokens
  Object.keys(compSpeedsMap).forEach((zenToken) => {
    const zenTokenAddress = zenToken.toLowerCase();
    aggregatedData[zenTokenAddress].omSpeedsPerBlock =
      compSpeedsMap[zenTokenAddress].omSpeedsPerBlock;
  });

  tokensUnderlyingPrices.forEach(([zenToken, underlyingPrice]) => {
    const zenTokenAddress = zenToken.toLowerCase();
    const zenTokenInfo = LENDING_ZEN_TOKENS[zenTokenAddress];

    aggregatedData[zenTokenAddress] = {
      ...aggregatedData[zenTokenAddress],
      ...zenTokenInfo,
      underlyingPrice: toBNScaled(underlyingPrice, 36 - zenTokenInfo.underlyingDecimals),
    };
  });

  tokensMetadata.forEach(
    ({
      cToken: zenToken,
      supplyRatePerBlock,
      borrowRatePerBlock,
      reserveFactorMantissa,
      totalBorrows: totalBorrowsResult,
      totalReserves,
      totalSupply: totalSupplyZenResult,
      isListed: isListedResult,
      collateralFactorMantissa,
      underlyingAssetAddress,
      underlyingDecimals,
      totalCash,
    }) => {
      const zenTokenAddress = zenToken.toLowerCase();
      if (!isListedResult) {
        aggregatedData[zenTokenAddress] = { broken: true };
        return;
      }

      const { underlyingPrice, omSpeedsPerBlock, exchangeRate } = aggregatedData[zenTokenAddress];
      const underlyingDecimalsNumber = Number(underlyingDecimals);
      const totalSupply =
        totalSupplyZenResult > 0
          ? toBNScaled(totalSupplyZenResult, ZEN_TOKEN_DECIMALS)
              .times(exchangeRate)
              .dp(underlyingDecimalsNumber)
          : toBN(0);
      const isZenTokenInMarket = normalizedAccountMarketZenTokens.includes(zenTokenAddress);
      const supplyAPY = getZenTokenAPY(toBNScaled(supplyRatePerBlock, EXP_DECIMALS));
      const borrowAPY = getZenTokenAPY(toBNScaled(borrowRatePerBlock, EXP_DECIMALS));
      const totalBorrows = toBNScaled(totalBorrowsResult, underlyingDecimals);

      const totalSupplyUsd = totalSupply.times(underlyingPrice).times(ethUsdPrice);
      const totalBorrowsUsd = totalBorrows.times(underlyingPrice).times(ethUsdPrice);

      const supplyDistributionAPY = getZenDistributionAPYWithoutPrice(
        omSpeedsPerBlock,
        totalSupply,
      );
      const borrowDistributionAPY = getZenDistributionAPY(
        omSpeedsPerBlock,
        omUsdPrice,
        totalBorrowsUsd,
      );

      aggregatedData[zenTokenAddress] = {
        ...aggregatedData[zenTokenAddress],
        zenTokenAddress,
        collateralFactor: toBNScaled(collateralFactorMantissa, EXP_DECIMALS),
        reserveFactor: toBNScaled(reserveFactorMantissa, EXP_DECIMALS),
        totalReserves: toBNScaled(totalReserves, underlyingDecimals),
        underlyingAssetAddress,
        underlyingDecimals: underlyingDecimalsNumber,
        collateral: isZenTokenInMarket,
        supplyAPY,
        borrowAPY,
        supplyDistributionAPY,
        borrowDistributionAPY,
        totalSupplyUsd,
        totalBorrowsUsd,
        marketLiquidity: toBNScaled(totalCash, underlyingDecimals),
        marketLiquidityUsd: toBNScaled(totalCash, underlyingDecimals)
          .times(underlyingPrice)
          .times(ethUsdPrice),
        isPositiveCollateralFactor: toBN(collateralFactorMantissa).isGreaterThan(0),
      };
    },
  );

  if (isWalletConnected && tokensBalances) {
    tokensBalances.forEach(
      ({
        cToken: zenToken,
        balanceOf: zenTokenWalletBalanceResult,
        borrowBalanceCurrent: underlyingBorrowBalanceResult,
        tokenBalance: tokenBalanceResult,
        tokenAllowance: tokenAllowanceResult,
      }) => {
        const zenTokenAddress = zenToken.toLowerCase();
        const { exchangeRate, underlyingPrice, underlyingDecimals, zenTokenSymbol } =
          aggregatedData[zenTokenAddress];

        const underlyingBorrowBalance = toBNScaled(
          underlyingBorrowBalanceResult,
          underlyingDecimals,
        );
        const underlyingSupplyBalance = toBNScaled(
          zenTokenWalletBalanceResult,
          ZEN_TOKEN_DECIMALS,
        ).times(exchangeRate);
        const tokenBalance = toBNScaled(tokenBalanceResult, underlyingDecimals);
        const tokenBalanceUsd = tokenBalance.times(underlyingPrice).times(ethUsdPrice);
        const underlyingBorrowBalanceUsd = underlyingBorrowBalance
          .times(underlyingPrice)
          .times(ethUsdPrice);
        const underlyingSupplyBalanceUsd = underlyingSupplyBalance
          .times(underlyingPrice)
          .times(ethUsdPrice);
        const isEnabled = isTokenEnable(tokenAllowanceResult, zenTokenSymbol, underlyingDecimals);

        aggregatedData[zenTokenAddress] = {
          ...aggregatedData[zenTokenAddress],
          zenTokenSymbol,
          tokenBalance,
          tokenBalanceUsd,
          zenTokenWalletBalanceResult: toBNScaled(zenTokenWalletBalanceResult, ZEN_TOKEN_DECIMALS),
          underlyingSupplyBalance,
          underlyingBorrowBalance,
          underlyingSupplyBalanceUsd,
          underlyingBorrowBalanceUsd,
          isEnabled,
        };
      },
    );
  }

  const filteredTokensWithMetadata = Object.values(aggregatedData).filter(({ broken }) => !broken);

  // TODO old implementation of sorting after task https://app.asana.com/0/1198299020457413/1199984850900340
  // filteredTokensWithMetadata.sort((a, b) => {
  //   if (a.isFixedInList || b.isFixedInList || !isWalletConnected) {
  //     return 0;
  //   }
  //   return b.tokenBalance.minus(a.tokenBalance).toNumber();
  // });

  filteredTokensWithMetadata.sort((a, b) => {
    if (!isWalletConnected) {
      return 0;
    }

    return b.marketLiquidityUsd.minus(a.marketLiquidityUsd).toNumber();
  });

  const supplyTokens = [];
  const borrowTokens = [];
  const commonSupplyTokens = [];
  const commonBorrowTokens = [];

  filteredTokensWithMetadata.forEach((token) => {
    const { zenTokenWalletBalanceResult, underlyingBorrowBalance } = token;

    if (zenTokenWalletBalanceResult && zenTokenWalletBalanceResult.isGreaterThan(0)) {
      supplyTokens.push(token);
    } else {
      commonSupplyTokens.push(token);
    }

    if (underlyingBorrowBalance && underlyingBorrowBalance.isGreaterThan(0)) {
      borrowTokens.push(token);
    } else {
      commonBorrowTokens.push(token);
    }
  });

  dispatch({
    type: LENDING_TOKENS,
    payload: {
      supplyTokens,
      borrowTokens,
      commonBorrowTokens,
      commonSupplyTokens,
    },
  });
};

export const getSupplyBalance = () => (dispatch, getState) => {
  const { lending, basic } = getState();
  const { supplyTokens } = lending;
  const { ETH: ethUsdPrice } = basic.currency.USD;

  const value = supplyTokens.reduce((a, i) => {
    const { underlyingSupplyBalance, underlyingPrice } = i;
    return a.plus(underlyingSupplyBalance.times(underlyingPrice));
  }, toBN(0));

  dispatch({
    type: LENDING_SUPPLY_BALANCE,
    payload: value.times(ethUsdPrice),
  });
};

export const getWalletBalance = () => (dispatch, getState) => {
  const { lending } = getState();
  const { commonSupplyTokens } = lending;

  const value = commonSupplyTokens.reduce((a, i) => {
    const { tokenBalanceUsd } = i;
    return a.plus(tokenBalanceUsd);
  }, toBN(0));

  dispatch({
    type: LENDING_WALLET_BALANCE,
    payload: value,
  });
};

export const getBorrowBalance = () => (dispatch, getState) => {
  const { lending, basic } = getState();
  const { borrowTokens } = lending;
  const { ETH: ethUsdPrice } = basic.currency.USD;

  const value = borrowTokens.reduce((a, i) => {
    const { underlyingBorrowBalance, underlyingPrice } = i;
    return a.plus(underlyingBorrowBalance.times(underlyingPrice));
  }, toBN(0));

  dispatch({
    type: LENDING_BORROW_BALANCE,
    payload: value.times(ethUsdPrice),
  });
};

export const getNetAPY = () => (dispatch, getState) => {
  const { basic, lending } = getState();
  const {
    currency: {
      USD: { OM: omUsdPrice },
    },
  } = basic;
  const { borrowTokens, supplyTokens, supplyBalanceUsd, borrowedBalanceUsd } = lending;

  let omSpeedPerBlock = 0;
  // https://gist.github.com/ajb413/a6f89486ec5485746cd5eac1e10e4fc2
  const accumulateAmount = _.uniqBy([...borrowTokens, ...supplyTokens], 'zenTokenSymbol').reduce(
    (a, token) => {
      const {
        underlyingSupplyBalanceUsd,
        underlyingBorrowBalanceUsd,
        supplyAPY,
        borrowAPY,
        omSpeedsPerBlock,
        totalSupplyUsd,
        totalBorrowsUsd,
      } = token;

      omSpeedPerBlock +=
        omSpeedsPerBlock *
        ((totalSupplyUsd > 0 ? underlyingSupplyBalanceUsd / totalSupplyUsd : 0) +
          (totalBorrowsUsd > 0 ? underlyingBorrowBalanceUsd / totalBorrowsUsd : 0));

      return a.plus(
        underlyingSupplyBalanceUsd
          .times(supplyAPY)
          .minus(underlyingBorrowBalanceUsd.times(borrowAPY)),
      );
    },
    toBN(0),
  );

  // TODO refactor
  const omPerBlockUsd = omSpeedPerBlock * omUsdPrice;
  const totalOmDistributionApy = getTotalDistributionAPY(
    omPerBlockUsd,
    supplyBalanceUsd.plus(borrowedBalanceUsd),
  );

  let totalNetAPY = totalOmDistributionApy;

  if (accumulateAmount.isGreaterThan(0)) {
    totalNetAPY = totalNetAPY.plus(accumulateAmount.div(supplyBalanceUsd));
  } else if (accumulateAmount.isLessThan(0)) {
    totalNetAPY = totalNetAPY.plus(accumulateAmount.div(borrowedBalanceUsd));
  }

  dispatch({
    type: LENDING_NET_APY,
    payload: totalNetAPY,
  });
};

export const getBorrowLimits = () => (dispatch, getState) => {
  const { lending, basic } = getState();
  const { supplyTokens, borrowedBalanceUsd } = lending;

  const borrowLimit = supplyTokens
    .filter(({ collateral }) => collateral)
    .reduce((acc, i) => {
      const { underlyingSupplyBalance, underlyingPrice, collateralFactor, underlyingDecimals } = i;
      return acc.plus(
        underlyingSupplyBalance
          .times(underlyingPrice)
          .times(collateralFactor)
          .dp(underlyingDecimals),
      );
    }, toBN(0));

  const { accountLiquidity } = lending;
  const { ETH: ethUsdPrice } = basic.currency.USD;

  const borrowLimitUsd = borrowLimit.times(ethUsdPrice);
  const borrowLimitPercent = accountLiquidity.isEqualTo(0)
    ? borrowLimit.isEqualTo(0)
      ? toBN(0)
      : toBN(100)
    : borrowedBalanceUsd.isEqualTo(0)
    ? toBN(0)
    : toBN(1).minus(accountLiquidity.div(borrowLimit)).times(100).dp(OPERATION_DECIMAL_PLACES);

  dispatch({
    type: LENDING_BORROW_LIMIT,
    payload: {
      valueEth: borrowLimit,
      valueUsd: borrowLimitUsd,
      percent: borrowLimitPercent,
    },
  });
};

export const getDistributedOmReward = () => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  if (!accountAddress) {
    return;
  }

  const { allocated } = await compaundLensContractService.getCompBalanceMetadataExt(accountAddress);

  dispatch({
    type: LENDING_DISTRIBUTED_OM_REWARD,
    payload: toBNScaled(allocated, EXP_DECIMALS),
  });
};

export const setLendingData = () => async (dispatch) => {
  await Promise.all([dispatch(getZenTokensAll()), dispatch(getDistributedOmReward())]);
  dispatch(getSupplyBalance());
  dispatch(getWalletBalance());
  dispatch(getBorrowBalance());
  dispatch(getNetAPY());
  dispatch(getBorrowLimits());
  // Init the staking data for zen token staking pools
  dispatch(initStakedZenTokens());
};

export const enterMarket = (zenTokenAddress) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  await comptrollerContractService.enterMarket(accountAddress, zenTokenAddress);
  await dispatch(setLendingData());
};

export const exitMarkets = (zenTokenAddress) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  await comptrollerContractService.exitMarket(accountAddress, zenTokenAddress);
  await dispatch(setLendingData());
};

export const enableToken = (zenToken) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  const token = dispatch(findTokenByAddress(zenToken));
  const { underlyingAssetAddress, zenTokenSymbol, underlyingDecimals } = token;

  if (zenTokenSymbol === LENDING_TOKENS_CONSTANTS.zenETH) {
    return true;
  }

  const tokenService = new Erc20TokenService(underlyingAssetAddress);

  const oldAllowance = await tokenService.allowance(accountAddress, zenToken);
  if (isTokenEnable(oldAllowance, zenTokenSymbol, underlyingDecimals)) {
    return true;
  }

  await tokenService.approve(accountAddress, zenToken, UINT_256_MAX_BN);

  const newAllowance = await tokenService.allowance(accountAddress, zenToken);
  return isTokenEnable(newAllowance, zenTokenSymbol, underlyingDecimals);
};

export const claimAllDistributedOm = () => async (dispatch, getState) => {
  const { account, lending } = getState();
  const { address: accountAddress } = account;
  const { borrowTokens, supplyTokens } = lending;
  const zenTokenAdresses = [
    ...new Set([...borrowTokens, ...supplyTokens].map(({ zenTokenAddress }) => zenTokenAddress)),
  ];

  const prevBalance = await omV2TokenContractService.balanceOf(accountAddress);
  const { transactionHash } = await comptrollerContractService.claimOm(
    accountAddress,
    zenTokenAdresses,
  );
  const newBalance = await omV2TokenContractService.balanceOf(accountAddress);

  const balanceDelta = toBN(newBalance)
    .minus(prevBalance)
    .div(10 ** EXP_DECIMALS);
  await dispatch(getDistributedOmReward());
  return { transactionHash, withdrawn: balanceDelta };
};
