import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface HighlightProps extends React.HtmlHTMLAttributes<HTMLElement> {
  className?: string;
  dark?: boolean;
  darkAlt?: boolean;
}

const StyledHighlight = styled.div<Pick<HighlightProps, 'dark' | 'darkAlt'>>`
  border-radius: 14px;
  padding: 1rem 0.75rem;
  background: #f1f3f9;
  position: relative;

  ${({ dark }) =>
    dark &&
    css`
      background: #302e2d;
    `}

  ${({ darkAlt }) =>
    darkAlt &&
    css`
      background: #201f1e;
    `}
`;

export function Highlight({
  dark,
  darkAlt,
  children,
  ...props
}: PropsWithChildren<HighlightProps>) {
  return (
    <StyledHighlight dark={dark} darkAlt={darkAlt} {...props}>
      {children}
    </StyledHighlight>
  );
}
