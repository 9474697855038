import styled from 'styled-components';
import Button from '../../../../external/components/Button/Button';
import Icon from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';
import { ViewDelegatorScreensEnum } from './Body';

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setScreen: React.Dispatch<React.SetStateAction<ViewDelegatorScreensEnum>>;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  button + button {
    margin-left: 1rem;
  }
`;
// for sidebar
export function ErrorScreen({ setScreen, setShow }: Props) {
  return (
    <>
      <StyledWrapper>
        <>
          <Text dark={true} size='lg'>
            An unexpected error has occured
          </Text>
          <Icon type='alert' width='100px'></Icon>
          <Text dark={true}>
            Contact us in{' '}
            <a href='https://t.me/MANTRADAO' rel='noopener noreferrer' target='_blank'>
              Telegram
            </a>{' '}
            or support@mantradao.com with the relevant info about the issue.
          </Text>
          <StyledButtonContainer>
            <Button
              dark={true}
              variant='secondary'
              onClick={() => setScreen(ViewDelegatorScreensEnum.HOME)}
            >
              View stake
            </Button>
            <Button dark={true} variant='secondary' onClick={() => setShow(false)}>
              Close
            </Button>
          </StyledButtonContainer>
        </>
      </StyledWrapper>
    </>
  );
}
