import BN from 'bignumber.js';
import { toBN } from './token-helper';
import { AMOUNT_BLOCKS_IN_DAY } from '../constants/blockchain-constants';

import {
  DAYS_PER_YEAR,
  TOKEN_ALLOWANCE_MIN_AMOUNT,
  TOKEN_ALLOWANCE_MIN_AMOUNT_UINT96,
  LENDING_TOKENS_CONSTANTS,
} from '../constants/lending-constants';

import config from '../config/env';

export const showBN = (value, toFixed = 2) => {
  if (!value) {
    return '0';
  }

  const val = value.decimalPlaces(Number(toFixed), BN.ROUND_DOWN).toFixed();

  if (val === '0' && value.isGreaterThan(0)) {
    return `<0.${''.padEnd(toFixed - 1, '0')}1`;
  }
  return val;
};

export const getZenTokenAPY = (ratePerBlock) =>
  toBN(((ratePerBlock * AMOUNT_BLOCKS_IN_DAY + 1) ** DAYS_PER_YEAR - 1) * 100);

export const getZenDistributionAPYWithoutPrice = (omSpeed, totalSupplyOM) => {
  const compPerYear = omSpeed * AMOUNT_BLOCKS_IN_DAY * DAYS_PER_YEAR;
  return toBN((compPerYear / totalSupplyOM) * 100);
};

export const getZenDistributionAPY = (omSpeed, omPriceUsd, tokenUsdTotal) => {
  if (tokenUsdTotal > 0) {
    const compPerYearUsd = omSpeed * AMOUNT_BLOCKS_IN_DAY * DAYS_PER_YEAR * omPriceUsd;
    return toBN((compPerYearUsd / tokenUsdTotal) * 100);
  }
  return toBN(0);
};

export const getTotalDistributionAPY = (omPerBlockUsd, totalUserBalances) => {
  if (totalUserBalances > 0) {
    const compBorrowApy = (100 * omPerBlockUsd) / totalUserBalances;
    return toBN(compBorrowApy);
  }
  return toBN(0);
};

export const isTokenEnable = (allowance, zenTokenSymbol, underlyingDecimals) => {
  if (zenTokenSymbol === LENDING_TOKENS_CONSTANTS.zenETH) {
    return true;
  }

  const minAllowanceValue = [
    LENDING_TOKENS_CONSTANTS.zenCOMP,
    LENDING_TOKENS_CONSTANTS.zenCREAM,
    LENDING_TOKENS_CONSTANTS.zenUNI,
  ].includes(zenTokenSymbol)
    ? TOKEN_ALLOWANCE_MIN_AMOUNT_UINT96
    : TOKEN_ALLOWANCE_MIN_AMOUNT;
  return toBN(allowance)
    .div(10 ** underlyingDecimals)
    .isGreaterThanOrEqualTo(minAllowanceValue);
};

export const isZenEth = (address) => address === config.REACT_APP_LENDING_ZEN_ETH_TOKEN_ADDRESS;

export const convertPriceFeedPriceToUsd = (tokenBN, tokenDecimals, ethUsdPrice) => {
  return toBN(tokenBN)
    .div(10 ** (36 - tokenDecimals))
    .times(ethUsdPrice);
};
