import BigNumber from 'bignumber.js';
import { sumBy } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../actions/delegator-actions';
import { DELEGATOR_CONTRACT_ABI } from '../../../blockchain/abi/typescript/DELEGATOR_CONTRACT_ABI';
import { MANTRA_DELEGATOR_ABI } from '../../../blockchain/abi/typescript/MANTRA_DELEGATION_ABI';
import networkConstant from '../../../constants/network.constant';
import { ChainType } from '../../../external/components/Icon/Icon';
import { getMerkleProofFromJson } from '../../../helpers/merkle-tree-helper';
import { State } from '../../../reducers';
import {
  DelegatorChainIds,
  DelegatorRewardRecord,
  DelegatorRewardRecordWithIndex,
} from '../../../services/delegator/delegator.data';
import delegatorService from '../../../services/delegator/delegator.service';
import metamaskService from '../../../services/metamask.service';
import web3Service from '../../../services/web3.service';
import { NetworkId } from '../../../types/network.types';
import { timeHelpers } from '../../staking/helpers/timeHelpers';

interface Props {
  chainId: DelegatorChainIds;
  walletNetworkId: NetworkId;
  networkId: NetworkId;
}

const BSC_DELEGATOR_ADDRESS_ATOM = '0x9f21939EFe79Bf081B27F7A6D9bAeAE04c1D67Ca';
const POLY_DELEGATOR_ADDRESS_ATOM = '0x181b3F69c7f2329da4Ee6aA361285dA7b78A3f5a';
const POLY_DELEGATOR_ADDRESS_MUMBAI = '0xC147038e9311960d081dc903c086E99cb0C55080';
const POLY_DELEGATOR_ADDRESS_MATIC = '0xA4dbd516f4a8505808FE036409fF27CB7e4BfDd1';
const MANTRA_POLY_DELEGATION_ADDRESS = '0xaAE5511b3AfbaE6C365E13a2D6D588AE84Ea1f2c';

const getContractAddress = (chainId: DelegatorChainIds, walletNetworkId: NetworkId) => {
  switch (chainId) {
    case DelegatorChainIds.ATOM:
      //@TODO CHANGE SO IT"S POLY
      if (walletNetworkId === networkConstant.networkId.polyMainnet) {
        return POLY_DELEGATOR_ADDRESS_ATOM;
      } else return BSC_DELEGATOR_ADDRESS_ATOM;
    case DelegatorChainIds.MUMBAI:
      return POLY_DELEGATOR_ADDRESS_MUMBAI;
    case DelegatorChainIds.MATIC:
      return POLY_DELEGATOR_ADDRESS_MATIC;
    default:
      return POLY_DELEGATOR_ADDRESS_ATOM;
  }
};

export function useDelegatorClaim({ chainId, networkId, walletNetworkId }: Props) {
  const [hasBscRewards, setHasBscRewards] = useState(false);
  const { walletAddress, currencies } = useSelector(({ account, basic }: State) => ({
    walletAddress: account.address,
    currencies: basic.currency.USD,
  }));

  const dispatch = useDispatch();
  const {
    setLinked,
    setUnclaimedRewards,
    setMetadata,
    setTransactionId,
    setRewardArray,
    setDelegatedAmount,
    setTotalDelegatedToNode,
    setApy,
  } = bindActionCreators(DelegatorActions, dispatch);

  const delegatorReadContract = new (web3Service.getInstanceByNetworkId(
    walletNetworkId,
  ).eth.Contract)(DELEGATOR_CONTRACT_ABI, getContractAddress(chainId, walletNetworkId));

  const metamaskInstance = metamaskService.getInstance();
  const delegatorContract = new metamaskInstance.eth.Contract(
    DELEGATOR_CONTRACT_ABI,
    getContractAddress(chainId, walletNetworkId),
  );

  const mantraDelegationContract = new (web3Service.getInstanceByNetworkId(
    networkConstant.networkId.ethMainnet,
  ).eth.Contract)(MANTRA_DELEGATOR_ABI, MANTRA_POLY_DELEGATION_ADDRESS);

  async function getTotalDelegatedToNode() {
    try {
      const amount = await mantraDelegationContract.methods.activeAmount().call();
      const shifted = new BigNumber(amount).shiftedBy(-18).toNumber();
      setTotalDelegatedToNode({ chainId, totalDelegatedToNode: shifted });
    } catch (error) {
      setTotalDelegatedToNode({ chainId, totalDelegatedToNode: 0 });
      throw error;
    }
  }

  async function getLink() {
    try {
      const res = await delegatorService.getLink(walletAddress, networkId);
      if (res.length > 0) setLinked({ chainId, hasLinked: true });
      else setLinked({ chainId, hasLinked: false });
    } catch (error) {
      setLinked({ chainId, hasLinked: false });
      throw error;
    }
  }

  async function getDelegationPoly() {
    try {
      const result = await mantraDelegationContract.methods.getTotalStake(walletAddress).call();
      const amount = new BigNumber(result[0] || 0).shiftedBy(-18).toFixed(2);

      setDelegatedAmount({ chainId, delegatedAmount: amount });
    } catch (error) {
      setDelegatedAmount({ chainId, delegatedAmount: '0' });
    }
  }

  async function getPolyApy(totalMonthlyRewards: number, chainSymbol: ChainType) {
    try {
      const addresses = await delegatorService.getLinks(networkId);
      const addressArray = addresses.map((add) => add.ethereumAddress);
      const totals = await delegatorService.getLinkedDelegatd(addressArray);
      const totalLinkedDelegated = sumBy(totals, (item) =>
        new BigNumber(item.amount).shiftedBy(-18).toNumber(),
      );

      const totalOmRewardsUsd = totalMonthlyRewards * currencies['OM'] * 12;
      const totalLinkedUsd = totalLinkedDelegated * currencies[chainSymbol];
      const rate = totalOmRewardsUsd / totalLinkedUsd;
      const adjusted = rate * 100;

      setApy({ chainId, apy: adjusted });
    } catch (error) {
      throw error;
    }
  }

  async function link(walletAddress: string) {
    try {
      // use null params
      const tx = await delegatorContract.methods
        .linkAddresses('', '0x', '')
        .send({ from: walletAddress });
      setTransactionId(tx.transactionHash);
    } catch (error) {
      throw error;
    }
  }

  async function fetchRewards() {
    try {
      const result = await delegatorService.fetchRewards(chainId);
      const addressExists = result[walletAddress];

      if (!addressExists) {
        setUnclaimedRewards({ chainId, unclaimedReward: '0' });
        return;
      }

      const { amount } = addressExists;

      const [lastRewardRequestTime, merkleRootLastUpdateTime] = await Promise.all([
        delegatorReadContract.methods.lastRewardRequestTime(walletAddress).call(),
        delegatorReadContract.methods.merkleRootLastUpdateTime().call(),
      ]);
      if (
        timeHelpers.isAfter(parseInt(lastRewardRequestTime), parseInt(merkleRootLastUpdateTime))
      ) {
        setUnclaimedRewards({ chainId, unclaimedReward: '0' });
        return;
      }
      if (!amount) {
        setUnclaimedRewards({ chainId, unclaimedReward: '0' });
      } else {
        const shifted = new BigNumber(amount).shiftedBy(-18).toFixed();
        setUnclaimedRewards({ chainId, unclaimedReward: shifted });
      }
    } catch (error) {
      setUnclaimedRewards({ chainId, unclaimedReward: '0' });
      throw error;
    }
  }

  async function getMetadata() {
    try {
      const [longClaimPeriod, shortClaimPeriod, shortRewardPercentage] = await Promise.all([
        delegatorReadContract.methods.fullRewardTimeoutPeriod().call(),
        delegatorReadContract.methods.shortRewardTimeoutPeriod().call(),
        delegatorReadContract.methods.shortRewardPercentage().call(),
      ]);

      const shiftedShortRewardPercentage = new BigNumber(shortRewardPercentage)
        .dividedBy(10)
        .toFixed();

      setMetadata({
        chainId,
        longClaimPeriod,
        shortClaimPeriod,
        shortRewardPercentage: shiftedShortRewardPercentage,
      });
    } catch (error) {
      throw error;
    }
  }

  async function requestReward(amount: string, isFullWithdraw: boolean) {
    try {
      const json = await delegatorService.fetchRewards(chainId);
      const proof = getMerkleProofFromJson(json, walletAddress);

      const shiftedAmount = new BigNumber(amount).shiftedBy(18).toFixed();

      const transaction = await delegatorContract.methods
        .requestTokensByMerkleProof(proof, shiftedAmount, isFullWithdraw)
        .send({ from: walletAddress });
      setTransactionId(transaction.transactionHash);
    } catch (e) {
      throw e;
    }
  }

  async function getRequestedRewardArray() {
    try {
      let data: DelegatorRewardRecord[] = await delegatorReadContract.methods
        .getRequestedRewardArray(walletAddress)
        .call();

      const shiftedArray: DelegatorRewardRecordWithIndex[] = data.map((rewardRecord, i) => ({
        ...rewardRecord,
        amount: new BigNumber(rewardRecord.amount).shiftedBy(-18).toFixed(),
        index: i.toString(),
      }));

      setRewardArray({ chainId, rewardArray: shiftedArray });
    } catch (error) {
      throw error;
    }
  }

  async function checkHasBscRewards() {
    try {
      const delegatorReadContract = new (web3Service.getInstanceByNetworkId(
        networkConstant.networkId.bscMainnet,
      ).eth.Contract)(DELEGATOR_CONTRACT_ABI, BSC_DELEGATOR_ADDRESS_ATOM);

      let data: DelegatorRewardRecord[] = await delegatorReadContract.methods
        .getRequestedRewardArray(walletAddress)
        .call();

      const shiftedArray: DelegatorRewardRecordWithIndex[] = data.map((rewardRecord, i) => ({
        ...rewardRecord,
        amount: new BigNumber(rewardRecord.amount).shiftedBy(-18).toFixed(),
        index: i.toString(),
      }));
      if (shiftedArray.length > 0) {
        setHasBscRewards(true);
        return;
      }
    } catch (error) {
      throw error;
    }
  }

  async function sendReceiveReward(index: string) {
    try {
      const transaction = await delegatorContract.methods
        .receiveReward(index)
        .send({ from: walletAddress });
      setTransactionId(transaction.transactionHash);
    } catch (error) {
      throw error;
    }
  }

  return {
    hasBscRewards,
    link,
    fetchRewards,
    getMetadata,
    requestReward,
    sendReceiveReward,
    getRequestedRewardArray,
    checkHasBscRewards,
    getDelegationPoly,
    getLink,
    getPolyApy,
    getTotalDelegatedToNode,
  };
}
