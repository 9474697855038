import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './balance-block.module.scss';

const BalanceBlock = ({ className, balanceTitle, wallet, tokenPrefix, walletUsd }) => (
  <div className={cn(styles.wallet, className)}>
    <div>
      <span>{balanceTitle}: </span>
      <span className={styles.balanceValue}>
        {wallet} {tokenPrefix}
      </span>
    </div>
    <p className={styles.balanceUcd}>${walletUsd}</p>
  </div>
);
BalanceBlock.propTypes = {
  className: PropTypes.string,
  balanceTitle: PropTypes.string,
  tokenPrefix: PropTypes.string,
  wallet: PropTypes.string,
  walletUsd: PropTypes.string,
};

BalanceBlock.defaultProps = {
  className: '',
  balanceTitle: '',
  tokenPrefix: '',
  wallet: null,
  walletUsd: null,
};

export default React.memo(BalanceBlock);
