import { Dispatch, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../actions/delegator-actions';
import Button from '../../../external/components/Button/Button';
import Icon from '../../../external/components/Icon/Icon';
import { Skeleton } from '../../../external/components/SkeletonLoading/Skeleton';
import Text from '../../../external/components/Text/Text';
import { State } from '../../../reducers';
import { DelegatorType } from '../../../services/delegator/delegator.data';
import {
  useDelegatorCheckHook,
  useDelegatorUserRateHook,
  useValidatorAccountingDataHook,
} from '../hooks/delegator.hook';
import { StyledWrapper } from './CardWrapper';
import { StyledLinkBadge } from './LinkedBadge';

interface TendermintCardProps {
  className?: string;
  delegator: DelegatorType;
  showNominateSidebar: boolean;
  setShowViewNominateSidebar: Dispatch<React.SetStateAction<boolean>>;
  setShowNominateSidebar: Dispatch<React.SetStateAction<boolean>>;
}

export function TendermintCard({
  className,
  children,
  delegator,
  showNominateSidebar,
  setShowNominateSidebar,
  setShowViewNominateSidebar,
  ...props
}: PropsWithChildren<TendermintCardProps>) {
  const { walletAddress, currencies } = useSelector(({ account, basic }: State) => ({
    walletAddress: account.address,
    currencies: basic.currency.USD,
  }));

  const { chainSymbol, name, chainId } = delegator;
  const dispatch = useDispatch();
  const { setSelectedChainId } = bindActionCreators(DelegatorActions, dispatch);

  const [linked, setLinked] = useState(false);

  const [checkAddress, delegatorLinks, loading, error] = useDelegatorCheckHook(chainId);

  const [getValidatorAccountingInfo, validators, validatorsLoading, validatorsError] =
    useValidatorAccountingDataHook(chainId);

  const { userRateParsed, checkDelegation } = useDelegatorUserRateHook(chainId);

  const totalYearlyOMReward = 12 * delegator.totalMonthlyRewards * currencies['OM']; // estimate 12 month reward pool
  const totalDelegated = userRateParsed.linkedStake / 10 ** 6; // get Atom in human readable
  const totalLinkedDelegated = totalDelegated * currencies[chainSymbol]; // get $ value
  const rate = totalYearlyOMReward / totalLinkedDelegated;
  const apyRaw = ((1 + rate / 1) ** 1 - 1) * 100;
  const APY = apyRaw.toLocaleString();

  useEffect(() => {
    checkAddress(walletAddress, null);
  }, [walletAddress]);

  useEffect(() => {
    // Pass no args just to get user totals
    checkDelegation('0x0000000000000000000000000000000000000000');
  }, []);

  useEffect(() => {
    if (!showNominateSidebar) {
      delegatorLinks.forEach((x) => {
        if (x.chain_id === chainId && x.type === 'LINKED') {
          setLinked(true);
        }
      });
    }
  }, [delegatorLinks, showNominateSidebar]);

  return (
    <StyledWrapper className={className} {...props}>
      {loading ? (
        <>
          <Skeleton variant='circle' height='50px' width='50px' dark={true} />
          <Skeleton height='18px' width='60px' dark={true} />
          <br />
          <Skeleton height='40px' width='90px' dark={true} />
          <Skeleton height='32px' width='80px' dark={true} />
        </>
      ) : (
        <>
          {linked && (
            <StyledLinkBadge>
              <span>LINKED</span>
            </StyledLinkBadge>
          )}
          <Icon.Chain type={chainSymbol} width='45' />
          <Text dark={true}>{name}</Text>
          <Text weight={500} color='grey' size='sm' style={{ marginTop: '-0.5rem' }} as='span'>
            Additional APR
          </Text>
          <Text color='pink' size='xl' weight={400}>
            {'PAUSED'}
          </Text>

          {linked ? (
            <Button
              onClick={() => {
                setSelectedChainId({ chainId });
                setShowViewNominateSidebar(true);
              }}
              variant='secondary'
              dark
            >
              View nomination
            </Button>
          ) : (
            <Button
              onClick={() => {
                setSelectedChainId({ chainId });
                setShowNominateSidebar(true);
              }}
              variant='secondary'
              dark
            >
              Start linking
            </Button>
          )}
        </>
      )}
    </StyledWrapper>
  );
}
