import web3Service from '../../web3-service';
import LINK_CONTRACT_ABI from '../../../blockchain/abi/LINK_CONTRACT_ABI.json';

class LinkContract {
  constructor(address) {
    this._contract = new web3Service.web3.eth.Contract(LINK_CONTRACT_ABI, address);
  }

  async mapAddress(ethAddress, sidechainAddress, sidechainSignature) {
    let isMapped;
    try {
      const options = { from: ethAddress };
      await this._contract.methods
        .linkAddresses(sidechainAddress, sidechainSignature, ethAddress.toLowerCase())
        .send(options);
      isMapped = true;
    } catch (e) {
      isMapped = false;
      throw Error('Unable to get map address', e);
    }
    return isMapped;
  }
}

export default LinkContract;
