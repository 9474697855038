import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 130 68'
      className={className}
      {...otherProps}
    >
      <path fill='#121212' d='M6.2 26.53H12.969999999999999V37.81H6.2z'></path>
      <path fill='#121212' d='M115.67 26.53H122.44V37.81H115.67z'></path>
      <path
        stroke='#000'
        strokeWidth='10.156'
        d='M45.71 5.08L64.33 31.6 45.71 61.5H5.09V5.08h40.62z'
      ></path>
      <path fill='#EF6337' d='M0 56.42h42.88l-6.77 10.16H0V56.42z'></path>
      <path
        stroke='#000'
        strokeWidth='10.156'
        d='M83.51 61.5L64.89 32.16 83.51 5.08h40.62V61.5H83.51z'
      ></path>
      <path fill='#EF6337' d='M129.21 0H92.98l-6.79 10.15h43.02V0z'></path>
    </svg>
  );
}
