import * as React from 'react';

function SvgBlzIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <circle cx={25.5} cy={25.5} r={23.5} fill='#0C071D' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.436 10.892a2.153 2.153 0 01-2.602 2.106l-.56 2.256 8.133 2.031a2.155 2.155 0 111.533 2.677l-2.01 8.041 2.264.561a2.154 2.154 0 11.184 1.828l-3.583 2.159-.964 3.857a2.154 2.154 0 01-.998 4.187 2.154 2.154 0 01-2.444-2.816l-3.02-.755-3.5 2.108A2.154 2.154 0 1122.384 38l.52-2.092-8.284-2.07a2.154 2.154 0 11-1.506-2.55l2.047-8.194-2.201-.545a2.155 2.155 0 11-.277-1.793l3.604-2.17.998-3.993a2.154 2.154 0 112.644-1.424l3.782.945 3.67-2.21a2.154 2.154 0 114.055-1.011zm-3.934 1.21c.264.389.652.686 1.107.836l-.561 2.26-4.018-1.004 3.472-2.092zm-3.754 2.261l4.244 1.06-1.546 6.222a3.914 3.914 0 00-2.816.407l-3.365-5.59 3.483-2.099zm-3.603 1.899l3.285-1.979-3.583-.894a2.162 2.162 0 01-.848.97l1.146 1.903zm-1.35-1.793l1.15 1.913-3.375 2.033.942-3.77a2.163 2.163 0 001.282-.176zm1.27 2.112l3.366 5.592a3.906 3.906 0 00-1.657 2.321l-6.33-1.57 1.046-4.189 3.576-2.154zm7.386 12.271a3.882 3.882 0 01-2.658.415l-1.608 6.472 4.146 1.035 3.587-2.16-3.467-5.762zm3.587 5.961l-3.388 2.041 2.828.706c.283-.599.834-1.046 1.499-1.187l-.939-1.56zm1.188 1.522l-.988-1.642 3.274-1.972-.908 3.636a2.168 2.168 0 00-.771.008 2.17 2.17 0 00-.607-.03zm-1.108-1.841l-3.467-5.76a3.91 3.91 0 001.633-2.139l6.364 1.578-1.056 4.228-3.474 2.093zm-8.787-6.86a3.906 3.906 0 002.235 1.58l-1.607 6.468-8.3-2.073a2.144 2.144 0 00-.222-1.232l7.894-4.744zm.278 10.425l.52-2.094 3.92.979-3.297 1.986a2.158 2.158 0 00-1.143-.871zm5.609-22.58l-1.546 6.225a3.91 3.91 0 012.22 1.715l7.651-4.598a2.145 2.145 0 01-.187-1.308l-8.138-2.033zm-15.206 6.843l2.205.547.99-3.964-3.405 2.052a2.144 2.144 0 01.21 1.365zm2.375.829l-2.052 8.213c.405.165.748.45.986.811l7.885-4.738a3.882 3.882 0 01-.481-2.716l-6.338-1.57zm20.318 4.796l-6.359-1.576a3.909 3.909 0 00-.339-2.75l7.65-4.596c.248.393.62.7 1.06.866l-2.012 8.056zm.17.282l-1 4.002 3.394-2.045a2.146 2.146 0 01-.14-1.398l-2.255-.559z'
        fill='url(#BlzIcon_svg__paint0_linear)'
      />
      <defs>
        <linearGradient
          id='BlzIcon_svg__paint0_linear'
          x1={25.558}
          y1={8.738}
          x2={25.558}
          y2={42.263}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F707FE' />
          <stop offset={1} stopColor='#27D7FE' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBlzIcon;
