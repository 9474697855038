import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connectModal } from 'redux-modal';
import BN from 'bignumber.js';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { SUPPLY_MODAL } from '../../../constants/modal-constants';
import Loader from '../../loader';
import * as lendingSupplyActions from '../../../actions/lending-supply-actions';
import s from './supply-modal.module.scss';
import { showBN } from '../../../helpers/lending-helper';
import Main from './main';
import Tabs from './tabs';

const SupplyModal = ({ show, token, borrowLimitUsd, borrowLimitPercent }) => {
  const { isEnabled, underlyingDecimals, zenTokenAddress } = token;

  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState();
  const [inputValue, setInputValue] = useState('');
  const [disabledSupply, setDisabledSupply] = useState(isEnabled);
  const [disabledWithdraw, setDisabledWithdraw] = useState(false);
  const [isTokenEnabled, setIsTokenEnabled] = useState(isEnabled);
  const [error, setError] = useState('');
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
  const [isMax, setIsMax] = useState(false);

  const [newBorrowLimit, setNewBorrowLimit] = useState({
    supply: { newBorrowLimitUsd: null, newBorrowLimitPercent: null },
    withdraw: { newBorrowLimitUsd: null, newBorrowLimitPercent: null },
  });

  useEffect(() => {
    if (zenTokenAddress) {
      setMaxWithdrawalAmount(
        showBN(
          dispatch(lendingSupplyActions.getMaxWithdrawalAmount(zenTokenAddress)),
          Number(underlyingDecimals),
        ),
      );
    }
  }, [dispatch, underlyingDecimals, zenTokenAddress]);

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={cn(s.container, { [s.dark]: dark })}>
        <Main
          setIsMax={setIsMax}
          token={token}
          isTokenEnabled={isTokenEnabled}
          inputValue={inputValue}
          setInputValue={setInputValue}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          error={error}
          setError={setError}
          maxWithdrawalAmount={maxWithdrawalAmount}
          setNewBorrowLimit={setNewBorrowLimit}
          newBorrowLimit={newBorrowLimit}
          setDisabledSupply={setDisabledSupply}
          setDisabledWithdraw={setDisabledWithdraw}
        />
        {loading && <Loader className={s.loading} />}
        <Tabs
          isMax={isMax}
          activeIndex={activeIndex}
          token={token}
          inputValue={inputValue}
          setIsTokenEnabled={setIsTokenEnabled}
          newBorrowLimit={newBorrowLimit}
          setError={setError}
          setLoading={setLoading}
          setDisabledSupply={setDisabledSupply}
          setDisabledWithdraw={setDisabledWithdraw}
          isTokenEnabled={isTokenEnabled}
          disabledSupply={disabledSupply}
          disabledWithdraw={disabledWithdraw}
          borrowLimitUsd={showBN(borrowLimitUsd, 2)}
          borrowLimitPercent={showBN(borrowLimitPercent, 2)}
          maxWithdrawalAmount={maxWithdrawalAmount}
        />
      </div>
    </Dialog>
  );
};

SupplyModal.propTypes = {
  show: PropTypes.bool,
  token: PropTypes.object,
  borrowLimitUsd: PropTypes.object,
  borrowLimitPercent: PropTypes.object,
};

SupplyModal.defaultProps = {
  show: false,
  token: {},
  borrowLimitUsd: new BN(0),
  borrowLimitPercent: new BN(0),
};

export default connectModal({ name: SUPPLY_MODAL })(SupplyModal);
