import React from 'react';
import PropTypes from 'prop-types';
import styles from './claim-rewards-modal.module.scss';

const Error = ({ text }) => (
  <div className={styles.body}>
    <div className={styles.errorTitle}>Error</div>
    <div className={styles.errorText}>{text}</div>
  </div>
);

Error.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Error;
