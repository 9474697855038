import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Loader from '../../loader';
import Error from './error';
import Details from './details';

import * as modalActions from '../../../actions/modal-actions';
import { getCurrentAddressAndContract } from '../../../actions/mantra-pool-actions';

import { POOL_RESULT_MODAL } from '../../../constants/modal-constants';

import closeIcon from '../../../assets/images/close-icon.svg';
import JoinPoolIcon from '../../../assets/mantra-pool/join-pool.svg';

import stylesModal from '../modal.module.scss';
import styles from './pool-result-modal.module.scss';

const PoolResultModal = ({ show, roundIndex }) => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.account.address);
  const dark = useSelector((state) => state.basic.dark);
  const { currentContract } = dispatch(getCurrentAddressAndContract());

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const [round, roundCalculation, roundParticipant] = await Promise.all([
        currentContract.round(roundIndex),
        currentContract.roundCalculation(roundIndex),
        currentContract.roundParticipant(roundIndex, address),
      ]);

      setData({
        closedAt: round.closedAt,
        endsAt: round.endsAt,
        index: round.index,
        countParticipants: round.participants.length,
        winners: round.winners,
        totalReward: round.totalReward,
        totalEntries: round.totalEntries,
        seed: roundCalculation.seed,
        rewardPaid: roundParticipant.rewardPaid,
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
    // eslint-disable-next-line
  }, [address, roundIndex]);

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(stylesModal.dialog, stylesModal.poolResult, { [styles.dark]: dark })}
    >
      <div className={stylesModal.container}>
        <div className={stylesModal.header}>
          <div className={stylesModal.headerTitle}>
            <img className={stylesModal.headerIcon} src={JoinPoolIcon} alt='' />
            <span>POOL Result</span>
          </div>
          <button
            className={stylesModal.close}
            onClick={() => dispatch(modalActions.hideModal(POOL_RESULT_MODAL))}
          >
            <img src={closeIcon} alt='close' />
          </button>
        </div>
        <div className={styles.body}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <Loader className={styles.loader} />
          ) : error ? (
            <Error text={error} />
          ) : (
            <Details data={data} updateData={() => getData()} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

PoolResultModal.propTypes = {
  show: PropTypes.bool,
  roundIndex: PropTypes.string,
};

PoolResultModal.defaultProps = {
  show: false,
  roundIndex: '',
};

export default connectModal({ name: POOL_RESULT_MODAL })(PoolResultModal);
