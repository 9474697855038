import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import * as modalActions from '../../../../actions/modal-actions';
import { SUPPLY_MODAL } from '../../../../constants/modal-constants';
import closeIcon from '../../../../assets/images/close-icon.svg';
import TokenLine from '../../../../screens/lending/blocks-for-lending-modals/token-column/token-line';
import BalanceBlock from '../../../../screens/lending/blocks-for-lending-modals/balance-block';
import InputWithButton from '../../../../screens/lending/blocks-for-lending-modals/input-with-button';
import { toBN } from '../../../../helpers/token-helper';
import * as lendingSupplyActions from '../../../../actions/lending-supply-actions';
import s from '../supply-modal.module.scss';
import { showBN } from '../../../../helpers/lending-helper';

const tabs = ['Supply', 'Withdraw'];

const Main = ({
  token,
  isTokenEnabled,
  inputValue,
  setInputValue,
  activeIndex,
  setError,
  setActiveIndex,
  error,
  maxWithdrawalAmount,
  setDisabledSupply,
  setDisabledWithdraw,
  setNewBorrowLimit,
  newBorrowLimit,
  setIsMax,
}) => {
  const dispatch = useDispatch();
  const defaultTitleForModal = isTokenEnabled ? 'Supply' : 'Enable';
  const [balanceTitle, setBalanceTitle] = useState('Wallet Balance');
  const [title, setTitle] = useState(defaultTitleForModal);

  const {
    underlyingIcon,
    underlyingSymbol,
    tokenBalance,
    tokenBalanceUsd,
    underlyingSupplyBalance,
    underlyingSupplyBalanceUsd,
    zenTokenAddress,
    underlyingDecimals,
  } = token;

  const handleClickTab = (index) => {
    setActiveIndex(index);
    setIsMax(false);
    if (index === 0) {
      setTitle(defaultTitleForModal);
      setBalanceTitle('Wallet Balance');
    } else if (index === 1) {
      setTitle('Withdraw');
      setBalanceTitle('Supply Balance');
    }
  };

  const onChangeInputHandler = (value) => {
    const availableBalance = activeIndex === 0 ? tokenBalance : maxWithdrawalAmount;
    const valueBN = toBN(value);
    if (
      (value && Number.isNaN(Number(value))) ||
      valueBN.isGreaterThan(availableBalance) ||
      valueBN.isNegative()
    ) {
      return;
    }
    setInputValue(value);
    setIsMax(false);
    try {
      setError('');
      if (value && !valueBN.isEqualTo(0)) {
        if (activeIndex === 0) {
          const borrowData = dispatch(
            lendingSupplyActions.getHypotheticalBorrowLimitsAfterSupply(zenTokenAddress, value),
          );
          setNewBorrowLimit({
            ...newBorrowLimit,
            supply: { ...borrowData },
          });
        } else {
          const borrowData = dispatch(
            lendingSupplyActions.getHypotheticalBorrowLimitsAfterWithdrawal(zenTokenAddress, value),
          );
          setNewBorrowLimit({
            ...newBorrowLimit,
            withdraw: { ...borrowData },
          });
        }
        setDisabledSupply(false);
        setDisabledWithdraw(false);
      } else {
        setDisabledSupply(true);
        setDisabledWithdraw(true);
        setNewBorrowLimit({
          ...newBorrowLimit,
          supply: { newBorrowLimitUsd: null, newBorrowLimitPercent: null },
        });
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const onInpupButtonClickHandler = () => {
    const availableBalance =
      activeIndex === 0
        ? tokenBalance
        : dispatch(lendingSupplyActions.getMaxWithdrawalAmount(zenTokenAddress));
    onChangeInputHandler(showBN(availableBalance, underlyingDecimals));
    if (availableBalance.isEqualTo(0)) {
      setDisabledSupply(true);
      setDisabledWithdraw(true);
    } else {
      setDisabledSupply(false);
      setDisabledWithdraw(false);
    }

    setIsMax(true);
  };

  return (
    <>
      <div className={s.header}>
        <div className={s.headerTitle}>{title}</div>
        <button className={s.close} onClick={() => dispatch(modalActions.hideModal(SUPPLY_MODAL))}>
          <img src={closeIcon} alt='CLOSE' />
        </button>
      </div>
      <div className={s.body}>
        <TokenLine className={s.token} tokenImage={underlyingIcon} tokenName={underlyingSymbol} />
        {isTokenEnabled && (
          <div className={s.inputWrapper}>
            <InputWithButton
              className={s.input}
              tokenName={underlyingSymbol}
              value={inputValue}
              onChange={(e) => onChangeInputHandler(e.target.value)}
              handleClickButton={onInpupButtonClickHandler}
              placeHolder='Please enter'
            />
          </div>
        )}
        <BalanceBlock
          className={s.balance}
          balanceTitle={balanceTitle}
          wallet={showBN(
            activeIndex === 0 ? tokenBalance : underlyingSupplyBalance,
            underlyingDecimals,
          )}
          tokenPrefix={underlyingSymbol}
          walletUsd={showBN(activeIndex === 0 ? tokenBalanceUsd : underlyingSupplyBalanceUsd, 2)}
        />
        {error && <div className={s.error}>{error}</div>}
        <div className={s.tabWrapper}>
          {tabs.map((item, index) => (
            <button
              key={item}
              className={cn(s.tab, { [s.tabActive]: index === activeIndex })}
              onClick={() => handleClickTab(index)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  token: PropTypes.object.isRequired,
  isTokenEnabled: PropTypes.bool.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInputValue: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setError: PropTypes.func.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  error: PropTypes.string,
  maxWithdrawalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setDisabledWithdraw: PropTypes.func.isRequired,
  setDisabledSupply: PropTypes.func.isRequired,
  setNewBorrowLimit: PropTypes.func.isRequired,
  setIsMax: PropTypes.func.isRequired,
  newBorrowLimit: PropTypes.object.isRequired,
};

Main.defaultProps = {
  inputValue: '',
  error: '',
  maxWithdrawalAmount: 0,
};

export default Main;
