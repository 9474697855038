import React from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import { POLKADOT_LOGIN_MODAL } from '../../../constants/modal-constants';
import WalletIllustration from '../../../assets/images/wallets/WalletIllustration.png';
import theme from '../../../theme';
import Main from './main';
import s from './login-modal.module.scss';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-white-icon.svg';

const LoginPolkadotModal = ({ show, global, dotChainMode }) => {
  const dispatch = useDispatch();
  const allDotAccounts = useSelector((state) => state.polkadotAccount.allAccounts);

  const colors = () => {
    if (global.dark) {
      return theme.palette.dark;
    }
    return theme.palette.light;
  };

  return (
    <Dialog open={show} className={s.modal}>
      <div className={s.container} style={{ backgroundColor: colors().background }}>
        <button
          className={s.close}
          onClick={() => dispatch(modalActions.hideModal(POLKADOT_LOGIN_MODAL))}
        >
          <img src={closeIcon} alt='close' />
        </button>
        <img width='100%' alt='' style={{ marginTop: '-6px' }} src={WalletIllustration} />
        <Main global={global} allDotAccounts={allDotAccounts} dotChainMode={dotChainMode} />
      </div>
    </Dialog>
  );
};

LoginPolkadotModal.propTypes = {
  show: PropTypes.bool,
  global: PropTypes.object.isRequired,
  dotChainMode: PropTypes.string.isRequired,
};

LoginPolkadotModal.defaultProps = {
  show: false,
};

export default connectModal({ name: POLKADOT_LOGIN_MODAL })(LoginPolkadotModal);
