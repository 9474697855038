import WalletConnectProvider from '@walletconnect/ethereum-provider';

import NetworkProviderService from '../network-provider-service';
import config from '../../../config/env';
import networkConstant from '../../../constants/network.constant';

class WalletConnectService extends NetworkProviderService {
  constructor() {
    super();

    this.provider = new WalletConnectProvider({
      chainId: 1,
      infuraId: config.REACT_APP_INFURA_PROJECT_ID,
      rpc: {
        [networkConstant.networkId.ethMainnet]: config.REACT_APP_INFURA_RPC_URL,
        [networkConstant.networkId.ethKovan]: config.REACT_APP_INFURA_RPC_URL,
        [networkConstant.networkId.bscMainnet]: config.REACT_APP_BSC_RPC_URL,
        [networkConstant.networkId.bscTestnet]: config.REACT_APP_BSC_RPC_URL,
        [networkConstant.networkId.polyMainnet]: config.REACT_APP_POLYGON_RPC_URL,
        [networkConstant.networkId.polyTestnet]: config.REACT_APP_POLYGON_RPC_URL,
      },
    });

    this.eventAccountsChanged = this.eventAccountsChanged.bind(this);
    this.eventChainChanged = this.eventChainChanged.bind(this);
    this.eventDisconnect = this.eventDisconnect.bind(this);

    this.initEvents();
    this.provider.enable();
  }

  get provider() {
    return this._provider;
  }

  set provider(instance) {
    this._provider = instance;
  }

  async enable() {
    return this.provider.enable();
  }

  async disconnect() {
    return this.provider.disconnect();
  }

  initEvents() {
    this.provider.on('accountsChanged', this.eventAccountsChanged);
    this.provider.on('chainChanged', this.eventChainChanged);
    this.provider.on('disconnect', this.eventDisconnect);
  }

  eventAccountsChanged(value) {
    this.emit('accountsChanged', value);
  }

  eventChainChanged(value) {
    this.emit('accountsChanged', value);
  }

  eventDisconnect() {
    this.emit('disconnect');
  }

  clearEvents() {
    super.clearEvents();
    if (this.provider.off) {
      this.provider.off('accountsChanged', this.eventAccountsChanged);
      this.provider.off('chainChanged', this.eventChainChanged);
      this.provider.off('disconnect', this.eventChainChanged);
    }
  }
}

export default WalletConnectService;
