import { chain } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from '../../../../reducers';
import { DelegatorDataType } from '../../../../services/delegator/delegator.data';

import { SideBarHeader } from '../NominateSidebar/_SidebarHeader';
import { DelegatorClaimScreen } from './Claim';
import { DelegatorClaimSuccess } from './ClaimSuccess';
import { ErrorScreen } from './ErrorScreen';
import { DelegatorRewardSummary } from './RewardSummary';
import { NominatedTimerScreen } from './Waiting';
import { DelegatorWithdrawSuccess } from './WithdrawSuccess';

interface SidebarBodyProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledSidebarWrapper = styled.div`
  padding: 1.5rem;
`;

export enum ViewDelegatorScreensEnum {
  HOME = 'HOME',
  CLAIM_SCREEN = 'CLAIM_SCREEN',
  ERROR = 'ERROR',
  CLAIM_SUCCESS = 'CLAIM_SUCCESS',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
  REWARD_SUMMARY = 'REWARD_SUMMARY',
}

export function ViewNominateBody({ setShow }: SidebarBodyProps) {
  const [screen, setScreen] = useState<ViewDelegatorScreensEnum>(
    ViewDelegatorScreensEnum.REWARD_SUMMARY,
  );

  const { delegatorData, selectedChainId: chainId } = useSelector(({ delegators }: State) => ({
    delegatorData: (delegators as DelegatorDataType).delegatorData,
    selectedChainId: (delegators as DelegatorDataType).selectedChainId,
  }));

  const renderBody = () => {
    if (!chainId) return <ErrorScreen setScreen={setScreen} setShow={setShow} />;

    const delegator = delegatorData.filter((del) => del.chainId === chainId)[0];
    const { chainSymbol } = delegator;

    switch (screen) {
      case ViewDelegatorScreensEnum.HOME:
        return <NominatedTimerScreen chainSymbol={chainSymbol} />;
      case ViewDelegatorScreensEnum.CLAIM_SCREEN:
        return <DelegatorClaimScreen setScreen={setScreen} />;
      case ViewDelegatorScreensEnum.ERROR:
        return <ErrorScreen setShow={setShow} setScreen={setScreen} />;
      case ViewDelegatorScreensEnum.CLAIM_SUCCESS:
        return <DelegatorClaimSuccess setShow={setShow} setScreen={setScreen} />;
      case ViewDelegatorScreensEnum.WITHDRAW_SUCCESS:
        return <DelegatorWithdrawSuccess setShow={setShow} setScreen={setScreen} />;
      case ViewDelegatorScreensEnum.REWARD_SUMMARY:
        return <DelegatorRewardSummary setScreen={setScreen} />;
    }
  };

  return (
    <StyledSidebarWrapper>
      <SideBarHeader chain='ATOM' />
      {renderBody()}
    </StyledSidebarWrapper>
  );
}
