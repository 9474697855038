import styled from 'styled-components';
import Button from '../../../../external/components/Button/Button';
import Icon from '../../../../external/components/Icon/Icon';

import Text from '../../../../external/components/Text/Text';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function ErrorScreen({ setScreen, setShow }: Props) {
  return (
    <StyledWrapper>
      <Icon type='alert' width={200} />
      <Text dark={true}>An unexpected error has occured, please try again later</Text>

      <br />
      <Button dark={true} onClick={() => setScreen(1)}>
        Try again
      </Button>
      <br />
      <Button dark={true} variant='secondary' onClick={() => setShow(false)}>
        Close
      </Button>
    </StyledWrapper>
  );
}
