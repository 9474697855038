import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconInfo from '../../../../../components/iconInfo';
import styles from './token-line.module.scss';

const TokenTitle = ({ className, text, info }) => {
  return (
    <div className={cn(className, styles.wrapper)}>
      <p className={styles.text}>{text}</p>
      {info && (
        <Tooltip placement='top-end' title={<div className={styles.tooltipText}>{info}</div>}>
          <div className={styles.tooltipIcon}>
            <IconInfo />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
TokenTitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  info: PropTypes.string,
};

TokenTitle.defaultProps = {
  className: '',
  info: '',
};

export default React.memo(TokenTitle);
