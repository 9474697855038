import React from 'react';
import Identicon from '@polkadot/react-identicon';

// eslint-disable-next-line import/no-anonymous-default-export
export default (address) => {
  // size (optional) is a number, indicating the size (in pixels, 64 as default)
  const size = 32;
  // theme (optional), depicts the type of icon, one of
  // 'polkadot', 'substrate' (default), 'beachball' or 'jdenticon'
  const theme = 'polkadot';

  const padding = '6px';

  // standard className & style props are also available
  return <Identicon value={address} size={size} theme={theme} style={{ padding }} />;
};
