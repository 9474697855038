import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../external/components/Icon/Icon';
import { Store } from '../../../../store/store.types';
import time from '../../helpers/delegator.helpers';
import { useDelegatorCheckHook, useDelegatorUserRateHook } from '../../hooks/delegator.hook';
import { StyledText } from '../Form/_FormComponents';

import Text from '../../../../external/components/Text/Text';

export function DelegationSummary() {
  const HARDCODED_CHAIN_ID = 2;
  const [checkAddress, delegatorLinks, loading, error] = useDelegatorCheckHook(HARDCODED_CHAIN_ID);
  const { address: walletAddress } = useSelector(({ account }: Store) => account);
  const { checkDelegation, userRate, REWARD_POOL_SIZE, userRateParsed } =
    useDelegatorUserRateHook(HARDCODED_CHAIN_ID);

  useEffect(() => {
    checkDelegation(delegatorLinks[0]?.address_other);
  }, [delegatorLinks[0]?.address_other]);

  useEffect(() => {
    checkAddress(walletAddress, null);
  }, []);

  return (
    <>
      <Text dark={true}>Delegated amount</Text>
      {/* If they have a rate, the address is already in the system */}
      {/* Re do this when we add re linking */}
      <StyledText color={userRateParsed.userStake ? 'green' : 'pink'} forwardedAs='span'>
        <Icon
          width={10}
          height={10}
          type={userRateParsed.userStake ? 'greenCircle' : 'pinkCircle'}
        />
        {userRateParsed.userStake / 10 ** 6 + ' ATOM'}
      </StyledText>

      {delegatorLinks[0] ? (
        <>
          <Text dark={true}>Link information</Text>
          <Text color='grey'>{`${delegatorLinks[0]?.address_eth}  ${delegatorLinks[0]?.address_other}`}</Text>

          {/* <DelegatorApy
            HARDCODED_CHAIN_ID={HARDCODED_CHAIN_ID}
            otherAddress={delegatorLinks[0]?.address_other}
          /> */}
        </>
      ) : null}

      <Text dark={true}>How do delegator rewards work?</Text>
      <Text color='grey'>
        MANTRA DAO indexers gather information about your delegations on the COSMOS network.
      </Text>
      <Text color='grey'>
        Once a month, a reward bucket will be able to be claimed by the linked and delegating
        participants of the month. You have two options with your rewards, 20% unlocked in 30 days
        (sacrifice 80%), or a 100% full reward claim after 180 days of locking.
      </Text>
      <Text color='grey'>The current size of the monthly reward bucket is 400,000 OM.</Text>
      <Text color='grey'>
        The reward pool is allocated based on how long you delegated to MANTRA DAO's COSMOS node
        throughout the month.
      </Text>

      {/* <Text dark={true}>Available OM for withdrawal</Text>

      <br />

      <Highlight darkAlt={true}>
        <Text color='grey'>Long Claim: 0 OM</Text>
        <Text color='grey'>Available in 0 Day</Text>
      </Highlight> */}
    </>
  );
}
