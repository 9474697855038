import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='77'
      height='59'
      viewBox='0 0 77 59'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M68.0754 37.8121H43.8152C42.7549 37.8121 41.8906 38.6764 41.8906 39.7364C41.8906 40.2764 42.1201 40.7628 42.4779 41.1137L59.5608 58.1966C59.9117 58.5747 60.4186 58.8113 60.9724 58.8113C61.6407 58.8113 62.2279 58.4736 62.5724 57.9604C66.7723 53.0852 69.4732 46.8935 69.9596 40.0806C69.9866 39.9659 70 39.8512 70 39.7362C70.0002 38.6764 69.1424 37.8121 68.0754 37.8121Z' />
      <path d='M36.4879 2.93701C35.4277 2.94349 34.5703 3.80129 34.5703 4.86133V31.8227C34.5703 32.8896 35.4277 33.7539 36.4879 33.7539H63.4562C64.5164 33.7539 65.3805 32.8896 65.3805 31.8227C64.4283 16.3065 52.011 3.88923 36.4879 2.93701Z' />
      <path d='M51.3098 56.6235L31.418 36.7318C31.0805 36.3874 30.878 35.9079 30.878 35.388L30.8709 7.2921C30.8709 6.23206 30.0135 5.37448 28.9533 5.37448C12.7954 6.36041 0 19.7835 0 36.1849C0 53.2341 13.822 67.0625 30.8709 67.0625C38.6494 67.0625 45.7527 64.1862 51.1814 59.4391C51.6002 59.0878 51.8636 58.5615 51.8636 57.974C51.8636 57.4539 51.654 56.9744 51.3098 56.6235Z' />
    </svg>
  );
}
