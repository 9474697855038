import BN from 'bignumber.js';

import ZenTokenService from '../services/contracts/lending/zentoken-contract-service';
import zenEthService from '../services/contracts/lending/zeneth-contract-service';
import { MAX_BORROW_PERCENT } from '../constants/lending-constants';
import { UINT_256_MAX_BN } from '../constants/blockchain-constants';
import { toBN } from '../helpers/token-helper';
import { isZenEth } from '../helpers/lending-helper';
import * as lendingActions from './lending-actions';

export const getHypotheticalBorrowLimitsAfterBorrow =
  (zenToken, underlyingTokenAmount) => (dispatch, getState) => {
    const { lending, basic } = getState();
    const { accountLiquidity, borrowLimitEth } = lending;
    const { ETH: ethUsdPrice } = basic.currency.USD;

    const token = dispatch(lendingActions.findTokenByAddress(zenToken));

    const { underlyingPrice, underlyingBorrowBalance } = token;
    const borowedAmount = toBN(underlyingTokenAmount).times(underlyingPrice);

    const newBorrowBalanceUsd = underlyingBorrowBalance
      .plus(underlyingTokenAmount)
      .times(underlyingPrice)
      .times(ethUsdPrice);
    const newAccountLiquidity = accountLiquidity.minus(borowedAmount);
    const newBorrowLimitPercent = toBN(1).minus(newAccountLiquidity.div(borrowLimitEth)).times(100);

    return {
      newBorrowBalanceUsd,
      newBorrowLimitPercent,
    };
  };

export const getMaxBorrowAmount = (zenToken) => (dispatch, getState) => {
  const { lending } = getState();
  const { accountLiquidity } = lending;
  const { underlyingPrice, marketLiquidity } = dispatch(
    lendingActions.findTokenByAddress(zenToken),
  );

  const maxBorrowAccounLiquidity = accountLiquidity.times(MAX_BORROW_PERCENT);
  const maxBorrowAccountTokenByLiquidity = maxBorrowAccounLiquidity.div(underlyingPrice);

  return BN.min(maxBorrowAccountTokenByLiquidity, marketLiquidity);
};

export const getHypotheticalBorrowLimitsAfterRepay =
  (zenToken, underlyingTokenAmount) => (dispatch, getState) => {
    const { lending, basic } = getState();
    const { accountLiquidity, borrowLimitEth } = lending;
    const { ETH: ethUsdPrice } = basic.currency.USD;

    const token = dispatch(lendingActions.findTokenByAddress(zenToken));

    const { underlyingPrice, underlyingBorrowBalance } = token;
    const repayedAmount = toBN(underlyingTokenAmount).times(underlyingPrice);
    const newBorrowBalanceUsd = underlyingBorrowBalance
      .minus(underlyingTokenAmount)
      .times(underlyingPrice)
      .times(ethUsdPrice);
    const newAccountLiquidity = accountLiquidity.plus(repayedAmount);

    const newBorrowLimitPercent = borrowLimitEth.isGreaterThan(newAccountLiquidity)
      ? toBN(1).minus(newAccountLiquidity.div(borrowLimitEth)).times(100)
      : null;

    return {
      newBorrowBalanceUsd,
      newBorrowLimitPercent,
    };
  };

export const borrow = (zenToken, amount) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  const token = dispatch(lendingActions.findTokenByAddress(zenToken));

  const { underlyingDecimals } = token;
  const underlyingDecimalsNumber = Number(underlyingDecimals);
  const tokenAmount = toBN(amount)
    .times(10 ** underlyingDecimalsNumber)
    .toFixed(0, BN.ROUND_DOWN);
  const zenTokenService = isZenEth(zenToken) ? zenEthService : new ZenTokenService(zenToken);

  await zenTokenService.borrow(accountAddress, tokenAmount);
  await dispatch(lendingActions.setLendingData());
};

export const repay = (zenToken, amount, isMaxRepay) => async (dispatch, getState) => {
  const { account } = getState();
  const { address: accountAddress } = account;

  await dispatch(lendingActions.enableToken(zenToken));
  const token = dispatch(lendingActions.findTokenByAddress(zenToken));

  const { underlyingDecimals, underlyingBorrowBalance, tokenBalance } = token;
  const underlyingDecimalsNumber = Number(underlyingDecimals);
  let tokenAmount = toBN(amount)
    .times(10 ** underlyingDecimalsNumber)
    .toFixed(0, BN.ROUND_DOWN);
  const zenTokenService = isZenEth(zenToken) ? zenEthService : new ZenTokenService(zenToken);
  if (isMaxRepay && !isZenEth(zenToken) && underlyingBorrowBalance.isLessThan(tokenBalance)) {
    // https://github.com/compound-finance/compound-protocol/blob/master/contracts/CToken.sol#L873
    tokenAmount = UINT_256_MAX_BN;
  }

  await zenTokenService.repay(accountAddress, tokenAmount);
  await dispatch(lendingActions.setLendingData());
};
