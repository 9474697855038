import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../external/components/Button/Button';
import { StyledDd, StyledDl, StyledDt } from '../../../../external/components/DefLists';
import Text from '../../../../external/components/Text/Text';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { ProviderRpcError } from '../../../../types/metamask.types';
import { BigNumberHelper } from '../../../staking/helpers/BigNumbeHelper';
import { timeHelpers } from '../../../staking/helpers/timeHelpers';
import { useDelegatorClaim } from '../../hooks/claim.hook';
import { ViewDelegatorScreensEnum } from './Body';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<ViewDelegatorScreensEnum>>;
}

export function DelegatorClaimScreen({ setScreen }: Props) {
  const [claimLoading, setClaimLoading] = useState(false);
  const { delegatorData, walletNetworkId, selectedRewardType } = useSelector(
    ({ delegators, account }: State) => ({
      walletNetworkId: account.networkId,
      delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
        ({ chainId }) => chainId === DelegatorChainIds.ATOM,
      )[0],
      selectedRewardType: (delegators as DelegatorDataType).selectedRewardType,
    }),
  );

  const { unclaimedRewards: rewardBalance, metadata, networkId } = delegatorData;
  const { shortClaimPeriod, longClaimPeriod, shortRewardPercentage } = metadata;

  const shortClaimHumanised = timeHelpers.humaniseTime(parseInt(shortClaimPeriod || '0'));
  const longClaimHumanised = timeHelpers.humaniseTime(parseInt(longClaimPeriod || '0'));

  const claimTime = selectedRewardType === 'LONG' ? longClaimHumanised : shortClaimHumanised;

  const { requestReward } = useDelegatorClaim({
    chainId: DelegatorChainIds.ATOM,
    walletNetworkId,
    networkId,
  });

  const handleClaim = async () => {
    try {
      setClaimLoading(true);
      if (rewardBalance) {
        await requestReward(rewardBalance, selectedRewardType === 'LONG' ? true : false);
        setClaimLoading(false);
        setScreen(ViewDelegatorScreensEnum.CLAIM_SUCCESS);
      } else {
        setClaimLoading(false);
        setScreen(ViewDelegatorScreensEnum.ERROR);
      }
    } catch (_error) {
      const error = _error as ProviderRpcError;

      if (error.code === 4001) {
        // do nothing if RPC error
        setClaimLoading(false);
        return;
      } else {
        setClaimLoading(false);
        setScreen(ViewDelegatorScreensEnum.ERROR);
      }
    }
  };

  return (
    <>
      <div className='flex direction-column align-center'>
        <Text color='lightgrey' size='sm' style={{ margin: 0 }}>
          You are claiming
        </Text>
        <Text dark={true} style={{ margin: '0.5rem 0' }} size='xl'>
          {' '}
          {selectedRewardType === 'LONG'
            ? rewardBalance
            : BigNumberHelper.multiply(rewardBalance || '0', shortRewardPercentage || '0')}{' '}
          OM
        </Text>
        {selectedRewardType === 'LONG' ? (
          <Text dark={true} size='sm' style={{ margin: '0.5rem 0 0', maxWidth: '25em' }}>
            You will be{' '}
            <Text as='span' color='lightpink'>
              receiving 100%
            </Text>{' '}
            of the displayed OM rewards.
          </Text>
        ) : (
          <Text dark={true} size='sm' style={{ margin: '0.5rem 0 0', maxWidth: '25em' }}>
            Short claimers are only able to claim 20% of their accrued OM nominator rewards. The
            remaining 80% are redistributed amongst longer term stakeholders in the MANTRA DAO
            ecosystem.
          </Text>
        )}
        <StyledDl>
          <StyledDd>
            <Text dark={true} style={{ marginBottom: '0.25rem' }}>
              Unclaimed rewards
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: 0 }} color='lightpink'>
              {rewardBalance} OM
            </Text>
          </StyledDt>
          <StyledDd>
            <Text dark={true} style={{ marginBottom: '0.25rem' }}>
              Forfeited rewards
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: 0 }} color='lightpink'>
              {selectedRewardType === 'LONG'
                ? 0
                : BigNumberHelper.multiply(
                    rewardBalance || '0',
                    new BigNumber(1).minus(shortRewardPercentage || '0').toFixed(),
                  )}{' '}
              OM
            </Text>
          </StyledDt>

          <StyledDd>
            <Text dark={true} style={{ marginBottom: '0.25rem' }}>
              Available rewards to claim
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: 0 }} color='lightpink'>
              {selectedRewardType === 'LONG'
                ? rewardBalance
                : BigNumberHelper.times(rewardBalance || '0', 0.2)}{' '}
              OM
            </Text>
          </StyledDt>

          <StyledDd>
            <Text dark={true} style={{ marginBottom: '0.25rem' }}>
              Claiming period
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: 0 }} color='lightpink'>
              {claimTime}
            </Text>
          </StyledDt>
        </StyledDl>

        <Text color='grey' size='sm'>
          There is a claiming period of {claimTime} before you are able to withdraw your unclaimed
          tokens. Following that claiming period of {claimTime}, users will be able to withdraw
          their rewards.
        </Text>
        <Button disabled={claimLoading} loading={claimLoading} dark={true} onClick={handleClaim}>
          Confirm claiming
        </Button>
      </div>
    </>
  );
}
