function numberWithCommas(x) {
  if (x) {
    const [integerPart, fractionalPart] = x.toString().split('.');
    return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
      fractionalPart ? `.${fractionalPart}` : ''
    }`;
  }
  return 0;
}

export const formatChainName = (chainName) => chainName.toLowerCase();
export default numberWithCommas;
