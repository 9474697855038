import _ from 'lodash';
import BN from 'bignumber.js';

import {
  LENDING_MARKETS_UPDATE_LIST,
  LENDING_MARKETS_SET_FILTER,
  LENDING_MARKETS_SET_FILTERED_LIST,
  LENDING_MARKETS_SET_SORT,
  LENDING_MARKETS_SET_SORTED_LIST,
} from '../actions/action-types';

const DEFAULT_FIELDS = {
  list: [],
  filteredList: [],
  sortedList: [],
  filter: '',
  sort: {
    columnName: null,
    direction: null,
  },
  totalMarketSize: new BN(0),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case LENDING_MARKETS_UPDATE_LIST:
      const { totalMarketSize, list } = action.payload;
      return { ...state, list, totalMarketSize };
    case LENDING_MARKETS_SET_FILTERED_LIST:
      return { ...state, filteredList: action.payload };
    case LENDING_MARKETS_SET_FILTER:
      return { ...state, filter: action.payload };
    case LENDING_MARKETS_SET_SORT:
      return { ...state, sort: action.payload };
    case LENDING_MARKETS_SET_SORTED_LIST:
      return { ...state, sortedList: action.payload };

    default:
      return state;
  }
};
