import styled from 'styled-components';

export const StyledLinkBadge = styled.div`
  border-radius: 24px;
  background: #23c581;
  color: #171616;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.75rem;
  letter-spacing: 1.4px;
  font-size: 0.75rem;
`;
