import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='249'
      height='130'
      viewBox='0 0 249 130'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      color={color}
      className={className}
    >
      <path
        d='M230.94 88.6881L151.844 43.0371L240.949 47.1872L246.483 52.5579L247.703 60.4512L246.808 65.8219L244.204 72.4132L241.519 77.5398L238.589 81.3644L230.94 88.6881Z'
        fill='#3F4C6B'
      />
      <path
        d='M164.458 1.37361C164.295 1.29224 164.213 1.21086 164.051 1.12949C160.877 -0.57937 156.564 -0.253873 151.844 2.51285L43.8608 64.8456C44.3491 65.171 44.756 65.5779 45.0815 66.0662L49.1502 63.7063L71.0398 111.229L82.3509 126.69L89.7559 129.294L97.161 126.365L133.047 105.94L204.25 65.2524L230.371 49.5472L237.206 47.3501L243.309 47.0246L164.458 1.37361Z'
        fill='#D4ECFF'
      />
      <path
        d='M79.0959 117.413C79.0959 106.265 86.9892 92.5942 96.6727 86.9794V88.9324C87.8843 93.9776 80.8048 106.346 80.8048 116.437C80.8048 126.527 87.9657 130.677 96.6727 125.632L230.94 48.0825C235.741 45.3158 240.136 44.9903 243.309 46.8619L164.458 1.29231C164.295 1.21094 164.213 1.12957 164.051 1.04819C160.877 -0.660669 156.564 -0.335171 151.844 2.43155L43.8608 64.8456C44.3491 65.1711 44.756 65.578 45.0815 66.0662L49.1502 63.7064L71.0398 111.229L80.0724 123.598C79.4214 121.808 79.0959 119.773 79.0959 117.413Z'
        fill='#2C3448'
      />
      <path
        d='M79.0962 116.356C79.0962 116.518 79.0962 116.762 79.0962 116.925C79.1776 111.31 81.2119 105.207 84.4669 99.7552C84.3041 99.6738 84.1414 99.5924 83.9786 99.4297C80.9678 104.882 79.0962 110.903 79.0962 116.356Z'
        fill='white'
      />
      <path
        d='M230.94 47.1061L96.6727 124.656C88.1284 129.62 81.1302 125.795 80.8047 116.193C80.8047 116.274 80.8047 116.437 80.8047 116.518C80.8047 126.609 87.9656 130.759 96.6727 125.714L230.94 48.164C235.741 45.3972 240.135 45.0717 243.309 46.9433L239.485 44.7462C236.962 44.5021 234.032 45.3159 230.94 47.1061Z'
        fill='#181C22'
      />
      <path
        d='M71.0398 110.09L49.1502 62.5671L45.0815 64.927C44.9187 64.7642 44.756 64.5201 44.5932 64.3574L43.8608 64.7642C44.3491 65.0897 44.756 65.4966 45.0815 65.9849L49.1502 63.625L71.0398 111.148L80.0724 123.516C79.9096 122.947 79.7469 122.377 79.5841 121.808L71.0398 110.09Z'
        fill='white'
      />
      <path
        d='M17.5768 41.3284C7.8933 46.9432 0 60.5327 0 71.7624C0 77.1331 1.87161 81.039 4.80108 82.9106H4.88246C5.04521 82.992 5.20795 83.1547 5.45207 83.2361L84.2224 128.724L79.4213 120.017L79.9909 111.31L81.537 106.428L83.8969 101.22C83.8969 101.22 85.8499 97.6394 85.9312 97.558C86.0126 97.4766 88.7793 93.9775 88.7793 93.9775L91.4647 91.2108L94.3128 89.3392L96.754 86.9793L17.5768 41.3284Z'
        fill='#4D5C80'
      />
      <path
        d='M230.94 88.6884V86.7354C239.729 81.6902 246.808 69.3213 246.808 59.2309C246.808 49.1405 239.647 44.9904 230.94 50.0356L96.6725 127.585C86.989 133.2 79.0957 128.643 79.0957 117.413C79.0957 106.184 86.989 92.5943 96.6725 86.9795V88.9325C87.8841 93.9777 80.8046 106.347 80.8046 116.437C80.8046 126.527 87.9655 130.678 96.6725 125.632L230.94 48.0826C240.624 42.4678 248.517 47.0247 248.517 58.2544C248.436 69.484 240.624 83.0735 230.94 88.6884Z'
        fill='#181C22'
      />
      <path
        d='M168.038 61.4277L130.443 40.189C128.978 39.3753 126.374 39.4566 124.665 40.5145L103.182 53.2089C101.474 54.1854 101.311 55.7315 102.776 56.5452L140.37 77.7839C141.835 78.5977 144.439 78.5163 146.148 77.4584L167.631 64.764C169.34 63.7875 169.502 62.2414 168.038 61.4277Z'
        fill='#5793CE'
        fillOpacity={0.11}
      />
      <path
        d='M139.394 73.2271L100.741 51.4188C100.009 51.0119 99.6019 50.4423 99.6019 49.7913L99.5205 45.4785C99.5205 45.4785 100.904 46.6177 101.718 47.2687L125.804 33.0282C127.757 31.889 130.768 31.7262 132.477 32.7027L169.421 53.5345L172.269 51.8257L172.35 56.1385C172.35 56.8709 171.781 57.6846 170.723 58.2542L146.148 72.8202C144.114 74.0408 141.184 74.1222 139.394 73.2271Z'
        fill='#B01350'
      />
      <path
        d='M171.048 50.0354L132.395 28.2271C130.686 27.2506 127.675 27.4134 125.722 28.5526L101.147 43.2C99.1944 44.3392 99.0317 46.0481 100.741 47.0246L139.393 68.8329C141.102 69.8094 144.113 69.6466 146.066 68.5074L170.641 53.9414C172.594 52.8021 172.757 51.0119 171.048 50.0354Z'
        fill='#ED1B6C'
      />
      <path
        d='M117.261 88.4441L85.7687 70.0535L83.7344 71.1927L115.226 89.5833L117.261 88.4441Z'
        fill='#D0E8FF'
      />
      <path
        d='M116.366 77.9467L94.3947 65.1709L92.3604 66.2288L114.413 79.0859L116.366 77.9467Z'
        fill='#D0E8FF'
      />
      <path
        d='M109.042 78.679L90.0817 67.6121L88.0474 68.7513L107.089 79.8182L109.042 78.679Z'
        fill='#D0E8FF'
      />
      <path
        d='M121.573 86.0028L112.053 80.3879L110.1 81.5272L119.539 87.142L121.573 86.0028Z'
        fill='#D0E8FF'
      />
      <path
        d='M190.741 45.1528L159.249 26.7622L157.296 27.9014L188.788 46.292L190.741 45.1528Z'
        fill='#D0E8FF'
      />
      <path
        d='M189.928 34.6556L167.875 21.8799L165.922 23.0191L187.893 35.7949L189.928 34.6556Z'
        fill='#D0E8FF'
      />
      <path
        d='M182.604 35.3879L163.562 24.321L161.609 25.4603L180.651 36.5272L182.604 35.3879Z'
        fill='#D0E8FF'
      />
      <path
        d='M195.135 42.7117L185.614 37.1782L183.58 38.3175L193.101 43.8509L195.135 42.7117Z'
        fill='#D0E8FF'
      />
      <path
        d='M154.327 49.2297L128.33 49.7828L128.883 42.0388'
        stroke='white'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
