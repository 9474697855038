const initialState = {
  transactionId: '',
  selectedPoolId: '',
};

type StakingReducerType = {
  transactionId: string;
  selectedPoolId: string;
};

export enum StakingActionEnum {
  SET_TRANSACTION_ID = 'SET_TRANSACTION_ID',

  SET_SELECTED_POOL_ID = 'SET_SELECTED_POOL_ID',
}

type SetStakingPoolId = {
  type: StakingActionEnum.SET_SELECTED_POOL_ID;
  payload: string;
};

type StakingTransactionIdType = {
  type: StakingActionEnum.SET_TRANSACTION_ID;
  payload: string;
};

export type StakingActionType = StakingTransactionIdType | SetStakingPoolId;

const StakingPoolReducer = (
  state: StakingReducerType = initialState,
  action: StakingActionType,
) => {
  switch (action.type) {
    case StakingActionEnum.SET_TRANSACTION_ID:
      return { ...state, transactionId: action.payload };

    case StakingActionEnum.SET_SELECTED_POOL_ID:
      return { ...state, selectedPoolId: action.payload };
    default:
      return { ...state };
  }
};
export default StakingPoolReducer;
