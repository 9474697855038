import { KUSAMA_SET_STATUS } from './action-types';
import { sendRequestReward } from './delegator-reward-actions';

export const setStatus = (status, withdrowedIndex) => async (dispatch) => {
  dispatch({ type: KUSAMA_SET_STATUS, payload: { status, withdrowedIndex } });
};

export const claimRewards = (unclaimedAmount, long, chain) => async (dispatch) => {
  try {
    dispatch(setStatus(long ? 12 : 11));
    await dispatch(sendRequestReward(unclaimedAmount, long, chain));
    dispatch(setStatus(8));
  } catch (error) {
    dispatch(setStatus(99));
  }
};
