import web3Service from '../../../web3-service';
import CETH_ABI from '../../../../blockchain/abi/lending/CETH.json';
import config from '../../../../config/env';

class ZenEthService {
  constructor() {
    this._contract = new web3Service.eth.Contract(
      CETH_ABI,
      config.REACT_APP_LENDING_ZEN_ETH_TOKEN_ADDRESS,
    );
  }

  get contract() {
    return this._contract;
  }

  async mint(address, valueWei) {
    const options = {
      from: address,
      value: valueWei,
    };
    return this.contract.methods.mint().send(options);
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }

  async exchangeRateStored() {
    return this.contract.methods.exchangeRateStored().call();
  }

  async repay(address, valueWei) {
    const options = {
      from: address,
      value: valueWei,
    };
    return this.contract.methods.repayBorrow().send(options);
  }

  async withdrawViaZen(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.redeem(value).send(options);
  }

  async withdrawViaZenCall(address, val) {
    return this.contract.methods.redeem(val).call({ from: address });
  }

  async withdraw(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.redeemUnderlying(value).send(options);
  }

  async borrow(address, value) {
    const options = {
      from: address,
    };
    return this.contract.methods.borrow(value).send(options);
  }
}
export default new ZenEthService();
