export enum LinkedStatusType {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  LINKED = 'LINKED',
}

export interface DelegatorLinks {
  delegators: DelegatorLink[];
}

export interface UserRate {
  userStake: number;
  userRatio: number;
  totalStake: number;
  totalLinked: number;
}

export interface DelegatorLink {
  id: string;
  address_eth: string;
  address_other: string;
  verification_amount: number | null;
  chain_id: number;
  type: LinkedStatusType;
  encoded_msg: string | null;
  signature: string | null;
}

export type amountType = {
  amount: string;
};

export interface RewardDataType {
  [key: string]: amountType;
}

export enum DelegatorChainEnum {
  COSMOS = 'cosmos',
  POLKADOT = 'polkadot',
  KUSAMA = 'kusama',
}
