import { ref } from 'joi';
import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  _size?: 'small' | 'large'; //Underscore because InputElement already has a size prop
  width?: 'sm' | 'md' | 'lg' | 'xl';
  dark?: boolean;
  darkAlt?: boolean;
  type?: 'number' | 'text' | 'email';
  className?: string;
  placeholder?: string;
}
const StyledInputWrapper = styled.div<Pick<SearchInputProps, 'width'>>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ width }) => {
    switch (width) {
      case 'sm':
        return css`
          width: 25%;
        `;
      case 'md':
        return css`
          width: 50%;
        `;
      case 'lg':
        return css`
          width: 75%;
        `;
      case 'xl':
        return css`
          width: 100%;
        `;
    }
  }}

  align-items: center;
  > svg {
    position: absolute;
    left: 0.75em;
  }
`;

const StyledInput = styled.input<Pick<SearchInputProps, 'dark' | 'darkAlt'>>`
  border-radius: 14px;
  padding: 0.75em 1em 0.75em;
  width: 100%;
  background: #fdfdfd;
  color: #282828;
  font-size: 1rem;
  border: solid #d2d6e8 1px;
  border-radius: 7px;

  &::placeholder {
    color: #9aa0ad;
  }

  &::focus-visible {
    outline: none;
  }

  ${({ dark }) =>
    dark &&
    css`
      background: #302e2d;
      color: #fff;
      border-color: #302e2d;
      &::placeholder {
        color: #9aa0ad;
      }
    `}

  ${({ darkAlt }) =>
    darkAlt &&
    css`
      background: #1c222f;
      color: #fff;
      border-color: #6d717a;
      border-width: 2px;
      &::placeholder {
        color: #6d717a;
      }
    `}
`;

export const TextInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ dark, type, placeholder, darkAlt, width, ...props }, ref) => {
    return (
      <StyledInputWrapper width={width}>
        <StyledInput
          placeholder={placeholder}
          dark={dark}
          darkAlt={darkAlt}
          type={type}
          ref={ref}
          {...props}
        />
      </StyledInputWrapper>
    );
  },
);
