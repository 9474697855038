import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color, ...props }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 115 115'
      {...props}
      className={className}
    >
      <circle cx='57.5' cy='57.5' r='57.5' fill='#ED1B6C' opacity='0.3'></circle>
      <circle cx='57.5' cy='57.5' r='46.5' fill='#ED1B6C' opacity='0.5'></circle>
      <circle cx='57.5' cy='57.5' r='36.5' fill='#ED1B6C'></circle>
      <path
        fill='#fff'
        d='M60.602 66.1h-6.26L53.168 41h8.553l-1.118 25.1zm.056 11.403c-.894.894-1.956 1.342-3.186 1.342s-2.292-.448-3.186-1.342C53.429 76.61 53 75.565 53 74.373c0-1.23.429-2.292 1.286-3.187.894-.894 1.956-1.341 3.186-1.341s2.292.447 3.186 1.341c.895.895 1.342 1.957 1.342 3.187 0 1.192-.447 2.236-1.342 3.13z'
      ></path>
    </svg>
  );
}
