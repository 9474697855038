import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 77 77'
      className={className}
      {...otherProps}
    >
      <path
        d='M69.38 64.97H18.46c-7.5 0-11.26-9.07-5.96-14.39l23.63-23.61'
        style={{
          stroke: '#bcb8b7',
          fill: 'none',
          strokeLinecap: 'square',
          strokeLinejoin: 'bevel',
          strokeWidth: '4.2px',
        }}
      />
      <path
        d='M7.65 12.27h50.89c7.5 0 11.26 9.07 5.96 14.39L40.89 50.27'
        style={{
          fill: 'none',
          strokeLinecap: 'square',
          strokeLinejoin: 'bevel',
          strokeWidth: '4.2px',
          stroke: '#ff97d6',
        }}
      />
    </svg>
  );
}
