import React from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { POLKADOT_ACCOUNT_MODAL } from '../../../constants/modal-constants';
import { TYPE_AUTH_TITLE } from '../../../constants/account-contants';
import * as accountActions from '../../../actions/account-polkadot-actions';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from '../account-modal/account-modal.module.scss';
import { CopyIcon, ViewIcon } from '../../../constants/icon-constants';
import cutWalletAddress from '../../../util/cutWalletAddress';
import Identicon from '../login-polkadot-modal/IdenticonDisplay';
import Dot from '../account-modal/balanceItem/dot';
import cn from 'classnames';

const AccountModal = ({ show }) => {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.basic.dark);
  const { address, substrateAddress, auth } = useSelector((state) => state.polkadotAccount);

  const logout = async () => {
    await dispatch(accountActions.logoutDot());
  };

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(s.dialog, { [s.dark]: dark })}
    >
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.headerTitle}>Polkadot.JS</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(POLKADOT_ACCOUNT_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.body}>
          <div className={s.block}>
            <div className={s.blockHeader}>
              <div>
                <div className={s.blockHeaderTitle}>Connected with {TYPE_AUTH_TITLE[auth]}</div>
                <div className={s.address}>
                  {Identicon(substrateAddress)}
                  <div className={s.addressText}>{cutWalletAddress(address, 5)}</div>
                </div>
              </div>
              <Button variant='outlined' className={s.disconnect} onClick={() => logout()}>
                Disconnect
              </Button>
            </div>
            <div className={s.controls}>
              <CopyToClipboard text={address}>
                <button className={s.control}>
                  {CopyIcon}
                  Copy Address
                </button>
              </CopyToClipboard>
              <a
                href={`https://polkadot.subscan.io/account/${address}`}
                target='_blank noreferrer'
                className={s.control}
              >
                {ViewIcon}
                View on Subscan
              </a>
            </div>
          </div>
          <div className={s.balanceTable}>
            <div className={s.balanceTableHeader}>
              <div>Asset Balance</div>
              <div>Current Nominated</div>
            </div>
            <Dot />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AccountModal.propTypes = {
  show: PropTypes.bool,
};

AccountModal.defaultProps = {
  show: false,
};

export default connectModal({ name: POLKADOT_ACCOUNT_MODAL })(AccountModal);
