import React from 'react';
import PropTypes from 'prop-types';
import BN from 'bignumber.js';
import { useDispatch } from 'react-redux';
import TokenColumn from '../../../../screens/lending/blocks-for-lending-modals/token-column';
import ModalButton from '../../../../screens/lending/blocks-for-lending-modals/modal-button';
import OmIcon from '../../../../assets/images/tokens/token-type2/om.svg';
import { BORROW_LIMIT_TOOLTIP_TEXT, SUPPLY_MODAL } from '../../../../constants/modal-constants';
import styles from './withdraw.module.scss';
import * as modalActions from '../../../../actions/modal-actions';
import * as lendingSupplyActions from '../../../../actions/lending-supply-actions';

const Withdraw = ({
  setLoading,
  setError,
  token,
  tokenIcon,
  disabled,
  supplyAPY,
  inputValue,
  maxWithdrawalAmount,
  borrowLimitUsd,
  newBorrowLimitUsd,
  borrowLimitPercent,
  newBorrowLimitPercent,
  isMax,
  isPaused,
}) => {
  const dispatch = useDispatch();
  const tokenColumn = [
    { tokenImage: tokenIcon, apyName: 'Supply APY', apyValue: isPaused ? '-' : supplyAPY },
    { tokenImage: OmIcon, apyName: 'Distribution APY', apyValue: isPaused ? '-' : 0.0 },
  ];

  const borrowColumn = [
    { apyName: 'Borrow Limit', usdValue: borrowLimitUsd, newUsdValue: newBorrowLimitUsd },
    {
      apyName: 'Borrow Limit Used',
      percentValue: borrowLimitPercent,
      newPercentValue: newBorrowLimitPercent,
    },
  ];

  const { zenTokenAddress, marketLiquidity } = token;

  const valueLessThanSupplyBalance =
    new BN(inputValue).isLessThanOrEqualTo(maxWithdrawalAmount) &&
    new BN(inputValue).isLessThanOrEqualTo(marketLiquidity);
  const isDisabled = !valueLessThanSupplyBalance || newBorrowLimitPercent >= 100 || disabled;

  const onWithdrawHandler = async () => {
    try {
      setLoading(true);
      setError('');
      await dispatch(lendingSupplyActions.withdraw(zenTokenAddress, inputValue, isMax));
      dispatch(modalActions.hideModal(SUPPLY_MODAL));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.row}>
        <TokenColumn title='Supply Rates' list={tokenColumn} />
        <TokenColumn
          title='Borrow Limit'
          list={borrowColumn}
          tooltipText={BORROW_LIMIT_TOOLTIP_TEXT}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <ModalButton
          styleType={isDisabled ? 'gray' : ''}
          disabled={isDisabled}
          onClick={onWithdrawHandler}
          text={!valueLessThanSupplyBalance ? 'Insufficient Collateral' : 'Withdraw'}
        />
      </div>
    </>
  );
};

Withdraw.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  inputValue: PropTypes.string,
  maxWithdrawalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borrowLimitUsd: PropTypes.string,
  tokenIcon: PropTypes.string,
  supplyAPY: PropTypes.string,
  isMax: PropTypes.bool,
  borrowLimitPercent: PropTypes.string,
  newBorrowLimitUsd: PropTypes.string,
  newBorrowLimitPercent: PropTypes.string,
  isPaused: PropTypes.bool,
};

Withdraw.defaultProps = {
  isMax: false,
  tokenIcon: null,
  disabled: false,
  supplyAPY: '0',
  inputValue: '0',
  maxWithdrawalAmount: 0,
  borrowLimitUsd: '0',
  borrowLimitPercent: '0',
  newBorrowLimitUsd: '0',
  newBorrowLimitPercent: '0',
  isPaused: false,
};

export default React.memo(Withdraw);
