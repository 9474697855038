// import React from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connectModal } from 'redux-modal';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from './distribution-om-rewards.module.scss';
import WithdrawalBg from '../../../assets/stake/withdrawal.svg';
import WithdrawalBgDark from '../../../assets/stake/withdrawal-dark.svg';
import { OM } from '../../../constants/blockchain-constants';
import { useGetTheme } from '../../../hooks/useGetTheme';
import { showBN } from '../../../helpers/lending-helper';
import {
  DISTRIBUTION_OM_REWARDS_MODAL,
  DISTRIBUTION_OM_SUCCESS_MODAL,
} from '../../../constants/modal-constants';
import { DistributionOmIcon } from '../../lending/distribution-om-icon';
import * as lendingActions from '../../../actions/lending-actions';
import Loader from '../../loader';

const descriptionText = `Enhanced OM Rewards can be withdrawn at any time, 
immediately, into your wallet. These OM rewards will also be automatically 
withdrawn into your wallet with any transaction on ZENTEREST (supply,
   borrow, repay, etc.).`;

const DistributionOmRewardsModal = ({ show }) => {
  const dispatch = useDispatch();
  const colors = useGetTheme();
  const dark = useSelector((state) => state.basic.dark);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { allocatedOmReward } = useSelector((state) => state.lending);

  const onClickHandler = async () => {
    try {
      setLoading(true);
      const { transactionHash, withdrawn } = await dispatch(lendingActions.claimAllDistributedOm());
      dispatch(modalActions.hideModal(DISTRIBUTION_OM_REWARDS_MODAL));
      dispatch(
        modalActions.showModal(DISTRIBUTION_OM_SUCCESS_MODAL, {
          transactionHash,
          withdrawn: showBN(withdrawn, 4),
        }),
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={cn(s.container, { [s.dark]: dark })}>
        <div className={s.header}>
          <div className={s.headerTitle}>Enhanced OM Rewards</div>
          <button
            className={s.close}
            onClick={() => dispatch(modalActions.hideModal(DISTRIBUTION_OM_REWARDS_MODAL))}
          >
            <img src={closeIcon} alt='CLOSE' />
          </button>
        </div>
        <div className={s.cardContent} style={{ color: colors.text }}>
          {loading && <Loader className={s.loading} />}
          <div className={s.title}>
            <div className={s.imageOmContainer}>
              <DistributionOmIcon />
            </div>
            <div>OM</div>
          </div>

          <div className={s.operationTitle}>You are Withdrawing</div>
          <div className={s.value}>
            {showBN(allocatedOmReward, 4)} {OM}
          </div>
          <img className={s.imageWithdraw} src={dark ? WithdrawalBgDark : WithdrawalBg} alt='' />
          <div className={s.footer}>
            <div>
              <div className={s.descriptionText}>
                <span>*</span> {descriptionText}
              </div>
            </div>
            {error && <div className={s.error}>{error}</div>}
            <div className={s.buttonContainer}>
              <Button
                onClick={onClickHandler}
                variant='contained'
                color='secondary'
                className={s.withdrawButton}
              >
                Withdraw
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

DistributionOmRewardsModal.propTypes = {
  show: PropTypes.bool,
};

DistributionOmRewardsModal.defaultProps = {
  show: false,
};

export default connectModal({ name: DISTRIBUTION_OM_REWARDS_MODAL })(DistributionOmRewardsModal);
