import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BN from 'bignumber.js';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import DashboardIcon from '../../assets/images/sidebar/home.svg';
import StakeIcon from '../../assets/images/sidebar/stake.svg';
// import BorrowIcon from '../../assets/images/sidebar/borrow.svg';
import LendIcon from '../../assets/images/sidebar/lend.svg';
import GovernanceIcon from '../../assets/images/sidebar/governance.svg';
import MantraPoolIcon from '../../assets/images/sidebar/pool.svg';

import HelpIcon from '../../assets/images/sidebar/help.svg';
import DelegatorIcon from '../../assets/images/sidebar/dele.svg';
import OmTokenMigrationIcon from '../../assets/images/sidebar/om-token-migration.svg';

import DelegatorIconActive from '../../assets/images/sidebar/dele_active.svg';
// import BorrowIconActive from '../../assets/images/sidebar/borrow-active.svg';
import LendIconActive from '../../assets/images/sidebar/lend-active.svg';
import GovernanceIconActive from '../../assets/images/sidebar/governance-active.svg';
import MantraPoolIconActive from '../../assets/images/sidebar/pool-active.svg';

import LaunchpadIconActive from '../../assets/images/sidebar/launchpad-active.svg';
import LaunchpadIcon from '../../assets/images/sidebar/launchpad.svg';
import HelpIconActive from '../../assets/images/sidebar/help-active.svg';

import OmTokenMigrationIconActive from '../../assets/images/sidebar/om-token-migration-active.svg';
import NavItem from './nav-item';

import styles from './navigation.module.scss';

import {
  STAKING_PATH,
  INDEX_PATH,
  GOVERNANCE_PATH,
  LENDING_PATH,
  MANTRA_POOL_PATH,
  SUPPORT_PATH,
  OM_TOKEN_SWAP_PATH,
  LAUNCHPAD_PATH,
  DELEGATOR_PATH,
} from '../../constants/router-constants';
import BurgerButton from '../burger-button';

const Navigation = ({ colors }) => {
  const [currentHover, setCurrentHover] = useState('');
  const isOpenMenu = useSelector((state) => state.basic.isOpenMenu);
  const omBalance = useSelector((state) => state.token.omBalance);

  return (
    <div className={cn(styles.wrapper, { [styles.active]: isOpenMenu })}>
      <BurgerButton className={styles.burgerButton} />
      <nav className={styles.container} style={{ backgroundColor: colors.banner }}>
        <NavItem
          text='Dashboard'
          routes={[INDEX_PATH]}
          colors={colors}
          icon={DashboardIcon}
          activeIcon={DashboardIcon}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
        {omBalance && new BN(omBalance).isGreaterThanOrEqualTo(10 ** 18) ? (
          <NavItem
            text='OM Token V2 Swap'
            routes={[OM_TOKEN_SWAP_PATH]}
            colors={colors}
            icon={OmTokenMigrationIcon}
            activeIcon={OmTokenMigrationIconActive}
            setCurrentHover={setCurrentHover}
            currentHover={currentHover}
          />
        ) : null}
        <NavItem
          text='Staking'
          routes={[STAKING_PATH]}
          colors={colors}
          icon={StakeIcon}
          activeIcon={StakeIcon}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
        <NavItem
          text='Delegator rewards'
          routes={[DELEGATOR_PATH]}
          colors={colors}
          icon={DelegatorIcon}
          activeIcon={DelegatorIcon}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
        <NavItem
          text='ZENTEREST'
          routes={[LENDING_PATH]}
          colors={colors}
          icon={LendIcon}
          activeIcon={LendIcon}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
        {/*<NavItem*/}
        {/*  text='MANTRA POOL'*/}
        {/*  routes={[MANTRA_POOL_PATH]}*/}
        {/*  colors={colors}*/}
        {/*  icon={MantraPoolIcon}*/}
        {/*  activeIcon={MantraPoolIcon}*/}
        {/*  setCurrentHover={setCurrentHover}*/}
        {/*  currentHover={currentHover}*/}
        {/*/>*/}
        <NavItem
          text='ZENDIT'
          routes={[LAUNCHPAD_PATH]}
          colors={colors}
          icon={LaunchpadIcon}
          activeIcon={LaunchpadIcon}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
        <NavItem
          text='Governance'
          routes={[GOVERNANCE_PATH]}
          colors={colors}
          icon={GovernanceIcon}
          activeIcon={GovernanceIconActive}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />

        <NavItem
          text='Support'
          routes={[SUPPORT_PATH]}
          colors={colors}
          icon={HelpIcon}
          activeIcon={HelpIconActive}
          setCurrentHover={setCurrentHover}
          currentHover={currentHover}
        />
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  colors: PropTypes.object.isRequired,
};

export default Navigation;
