import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export interface TextProps extends React.HtmlHTMLAttributes<HTMLElement> {
  dark?: boolean;
  as?: 'p' | 'span';
  color?: 'pink' | 'green' | 'grey' | 'navy' | 'lightpink' | 'lightgrey';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  weight?: 400 | 500 | 600;
  className?: string;
}

const StyledText = styled.p<Pick<TextProps, 'size' | 'dark' | 'color' | 'weight'>>`
  color: inherit;
  line-height: 1.25;
  font-size: 1.25rem;

  ${({ size }) => {
    if (size) {
      switch (size) {
        case 'xs':
          return css`
            font-size: 0.9rem;
          `;
        case 'sm':
          return css`
            font-size: 1rem;
          `;
        case 'md':
          return css`
            font-size: 1.25rem;
          `;
        case 'lg':
          return css`
            font-size: 1.5rem;
          `;
        case 'xl':
          return css`
            font-size: 1.75rem;
          `;
        case 'xxl':
          return css`
            font-size: 2rem;
          `;
      }
    }
  }}

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}

  ${({ color }) =>
    color === 'navy' &&
    css`
      color: #201f1e;
    `}

  ${({ color }) =>
    color === 'pink' &&
    css`
      color: #ae528b;
    `}

  ${({ color }) =>
    color === 'green' &&
    css`
      color: #23c581;
    `}

  ${({ color }) =>
    color === 'grey' &&
    css`
      color: #7f89a7;
    `}


  ${({ color }) =>
    color === 'lightpink' &&
    css`
      color: #ae528b;
    `}
    ${({ color }) =>
    color === 'lightgrey' &&
    css`
      color: #aeb6cd;
    `}



    ${({ dark }) =>
    dark &&
    css`
      color: white;
    `}


    
    a {
    color: #ae528b;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default function Text({
  dark,
  as,
  color,
  children,
  size,
  weight,
  className,
  ...props
}: PropsWithChildren<TextProps>) {
  return (
    <StyledText
      as={as}
      dark={dark}
      color={color}
      className={className}
      size={size}
      weight={weight}
      {...props}
    >
      {children}
    </StyledText>
  );
}
