import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='55'
      height='55'
      fill='none'
      viewBox='0 0 55 55'
      className={className}
      {...otherProps}
    >
      <path
        fill='#ED328D'
        d='M27.501.4C42.475.4 54.613 12.537 54.613 27.51c0 14.974-12.138 27.112-27.112 27.112C12.528 54.623.39 42.485.39 27.511.389 12.538 12.528.4 27.501.4zm0-.4a27.519 27.519 0 00-10.708 52.86A27.519 27.519 0 0038.21 2.162 27.335 27.335 0 0027.5 0z'
      ></path>
      <path
        fill='#ED338D'
        fillRule='evenodd'
        d='M27.5 5.491c12.163 0 22.021 9.859 22.021 22.02 0 12.162-9.858 22.021-22.02 22.021-12.162 0-22.02-9.858-22.02-22.02 0-12.162 9.858-22.02 22.02-22.02z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M18.927 36.604s7.342 1.372 12.814-4.213c6.2-6.331 3.14-14.174 3.14-14.174s2.68 1.732 4.483 4.903c.503.886.863 2.155 1.037 3.199.214 1.3.488 3.865-.287 6.34-.54 1.724-1.596 3.347-2.816 4.54-1.078 1.052-2.263 1.986-3.431 2.566-2.638 1.31-6.323 1.639-9.018.766-3.62-1.169-5.922-3.927-5.922-3.927z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
