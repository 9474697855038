import web3Service from '../../../services/web3.service';
import { NetworkId } from '../../../types/network.types';
import { AbiItem } from 'web3-utils';

interface Props {
  abi: AbiItem[];
  networkId: NetworkId;
  contractAddress: string;
}

export function findContract({ networkId, abi, contractAddress }: Props) {
  return new (web3Service.getInstanceByNetworkId(networkId).eth.Contract)(abi, contractAddress);
}
