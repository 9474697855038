import React from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import { INFO_MODAL } from '../../../constants/modal-constants';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/close-icon.svg';
import s from './info-modal.module.scss';

const InfoModal = ({ show, title, description, isClose }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={show} style={{ background: 'transparent' }} className={s.dialog}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.headerTitle}>{title}</div>
          {isClose ? (
            <button
              className={s.close}
              onClick={() => dispatch(modalActions.hideModal(INFO_MODAL))}
            >
              <img src={closeIcon} alt='CLOSE' />
            </button>
          ) : null}
        </div>
        <div className={s.body}>{description}</div>
      </div>
    </Dialog>
  );
};

InfoModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isClose: PropTypes.bool,
};

InfoModal.defaultProps = {
  show: false,
  isClose: true,
};

export default connectModal({ name: INFO_MODAL })(InfoModal);
