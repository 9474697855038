import web3Service from '../../web3-service';
import ERC20_ABI from '../../../blockchain/abi/ERC20.json';

class Erc20TokenService {
  constructor(address) {
    this._contract = new web3Service.eth.Contract(ERC20_ABI, address);
    this.address = address;
  }

  get contract() {
    return this._contract;
  }

  async approve(account, spenderAddress, amountApprove) {
    const options = {
      from: account,
    };
    return this.contract.methods.approve(spenderAddress, amountApprove).send(options);
  }

  async allowance(owner, spender) {
    return this.contract.methods.allowance(owner, spender).call();
  }

  async balanceOf(owner) {
    return this.contract.methods.balanceOf(owner).call();
  }
}
// export default Erc20TokenService;
// import web3Service from '../../web3-service';
// import ERC20_ABI from '../../../blockchain/abi/ERC20.json';
// import config from '../../../config/env';
// import Web3 from 'web3';

// class Erc20TokenService {
//   constructor(address, auth, networkId) {
//     // console.log(web3Service);
//     let web3 = {};
//     // if (networkId == 56 && auth == 'wallet_connect_bsc') {
//     // web3 = new Web3(new Web3.providers.HttpProvider(config.REACT_APP_BSC_RPC_URL));
//     // } else {
//     web3 = web3Service;
//     // }
//     // console.log(web3);
//     this._contract = new web3.eth.Contract(ERC20_ABI, address);
//     this._interactionContract = new web3Service.eth.Contract(ERC20_ABI, address);
//     // console.log(this._contract);
//     this.address = address;
//   }

//   get contract() {
//     return this._contract;
//   }

//   get interactionContract() {
//     return this._interactionContract;
//   }

//   async approve(account, spenderAddress, amountApprove) {
//     const options = {
//       from: account,
//     };
//     return this.interactionContract.methods.approve(spenderAddress, amountApprove).send(options);
//   }

//   async allowance(owner, spender) {
//     return this.contract.methods.allowance(owner, spender).call();
//   }

//   async balanceOf(owner) {
//     return this.contract.methods.balanceOf(owner).call();
//   }
// }
export default Erc20TokenService;
