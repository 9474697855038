import React from 'react';
import styled, { css } from 'styled-components';

export interface SkeletonProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  width?: string;
  height: string;
  margin?: string;
  variant?: 'circle' | 'square' | 'button';
  dark?: boolean;
  className?: string;
}

const SkeletonRoot = styled.span<SkeletonProps>`
  background: rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0.2rem;

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'circle':
        return css`
          border-radius: 50%;
        `;
      case 'button':
        return css`
          border-radius: 48px;
        `;
      default:
        return css`
          border-radius: 6px;
        `;
    }
  }}

  ${({ dark }) =>
    dark &&
    css`
      background: #586892;
    `}

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent 0%, #efeeee 50%, transparent 100%);
    transform: translateX(-100%);
    animation: loading 1s infinite;
    ${({ dark }) =>
      dark &&
      css`
        background: linear-gradient(90deg, transparent 0%, #7187c1 50%, transparent 100%);
      `}
    z-index:1;
  }

  &:empty:before {
    content: '\\00a0';
  }
`;

export function Skeleton({
  variant,
  width,
  height = '20px',
  dark,
  margin,
  className,
  ...props
}: SkeletonProps) {
  return (
    <SkeletonRoot
      width={width}
      height={height}
      variant={variant}
      style={{ width, height, margin }}
      dark={dark}
      className={className}
      {...props}
    />
  );
}
