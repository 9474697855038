import axios from 'axios';

import env from '../config/env';

export default {
  getPricesUSD: async function (tokenIds: string[]) {
    const { data } = await axios.get(`${env.REACT_APP_COINGECKO_API_URL}/simple/price`, {
      params: {
        vs_currencies: 'usd',
        ids: tokenIds.join(','),
      },
    });

    return data;
  },
};
