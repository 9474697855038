import {
  SET_STAKE_ACTIVE_ASSET,
  SET_STAKE_ACTIVE_TAB,
  SET_STAKE_TOTAL_STAKE_BALANCE_USD,
} from '../actions/action-types';

import { OM_V3 } from '../constants/blockchain-constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    activeAsset: OM_V3,
    activeTab: '',
    totalStakedBalanceUsd: 0,
  },
  action,
) => {
  switch (action.type) {
    case SET_STAKE_ACTIVE_ASSET:
      return {
        ...state,
        activeAsset: action.payload,
      };
    case SET_STAKE_TOTAL_STAKE_BALANCE_USD:
      return {
        ...state,
        totalStakedBalanceUsd: action.payload,
      };
    case SET_STAKE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return state;
  }
};
