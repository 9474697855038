import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      width='164.000000pt'
      height='141.000000pt'
      viewBox='0 0 164.000000 141.000000'
      preserveAspectRatio='xMidYMid meet'
      className={className}
      {...otherProps}
    >
      <g
        transform='translate(0.000000,141.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M510 1179 l-265 -160 5 -316 5 -316 276 -154 277 -155 271 162 271
162 0 101 c0 56 -3 199 -7 318 l-6 216 -131 73 c-72 41 -193 109 -269 152 -76
43 -144 78 -150 77 -7 0 -131 -73 -277 -160z m480 -112 c85 -49 168 -98 184
-110 l28 -20 -4 -225 -5 -226 -94 -54 c-52 -30 -140 -81 -197 -114 l-102 -58
-98 56 c-53 31 -143 84 -200 116 l-102 60 2 230 3 230 130 75 c72 41 159 92
195 113 52 30 69 35 85 27 11 -6 90 -50 175 -100z'
        />
        <path
          d='M702 902 c-45 -27 -79 -52 -75 -56 4 -4 0 -4 -9 -1 -22 8 -25 -7 -33
-141 l-7 -111 106 -61 c58 -34 108 -62 111 -62 4 0 53 26 111 58 l104 57 0
125 -1 125 -98 58 c-54 31 -105 57 -112 57 -8 0 -51 -22 -97 -48z m98 -12 c0
-5 -8 -14 -17 -20 -14 -8 -8 -10 27 -5 42 6 44 5 26 -10 -19 -14 -19 -15 -2
-28 16 -12 17 -15 3 -20 -14 -5 -117 41 -117 54 0 6 59 37 73 38 4 1 7 -3 7
-9z m-120 -182 l12 -42 18 23 c9 14 22 21 29 17 14 -9 15 -96 1 -96 -5 0 -10
15 -10 33 l0 32 -20 -25 -19 -25 -9 25 c-5 14 -12 32 -15 40 -4 10 -6 7 -6 -7
-2 -46 -21 -21 -21 27 0 61 21 60 40 -2z m280 42 c0 -5 -9 -14 -20 -20 -15 -8
-20 -23 -22 -65 -4 -68 -22 -77 -26 -13 -2 36 -6 46 -18 41 -31 -12 -11 17 29
41 46 28 57 31 57 16z'
        />
      </g>
    </svg>
  );
}
