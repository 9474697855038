import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='131'
      height='131'
      viewBox='0 0 131 131'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='65.5' cy='65.5' r='65.5' fill='#EBEEF5' />
      <circle cx='65.5' cy='65.5' r='53.5' fill='#E4E7EE' />
      <circle cx='65.6922' cy='63.6922' r='37.6922' fill='#43AF64' />
      <path
        d='M51.1924 68.1541L61.0503 76.369L80.7663 51.7241'
        stroke='white'
        strokeWidth='7.88637'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
