import networkConstant from '../../constants/network.constant';
import { ChainType } from '../../external/components/Icon/Icon';
import { NetworkId } from '../../types/network.types';
import { DelegatorLink } from './delegator.types';

export interface DelegatorRewardRecord {
  amount: string;
  isFullWithdrawal: boolean;
  isWithdrowedByNominator: boolean;
  timestamp: string;
}

export interface DelegatorRewardRecordWithIndex extends DelegatorRewardRecord {
  index: string;
}

export enum DelegatorChainIds {
  ATOM = 2,
  MATIC = 137,
  MUMBAI = 80001,
}

export type DelegatorChainType = 'TENDERMINT' | 'EVM' | 'SUBSTRATE';

export type DelegatorType = {
  name: string;
  type: DelegatorChainType;
  chainSymbol: ChainType;
  chainId: DelegatorChainIds;
  unclaimedRewards: string | undefined;
  networkId: NetworkId;
  totalMonthlyRewards: number;
  metadata: {
    shortClaimPeriod: string | undefined;
    longClaimPeriod: string | undefined;
    shortRewardPercentage: string | undefined;
  };
  rewardArray: DelegatorRewardRecordWithIndex[] | undefined;
  delegatorLinks: DelegatorLink[];
  hasLinked: boolean;
  totalDelegated: string;
  delegationUrl: string;
  totalDelegatedToNode: number;
  apy: number;
};

export type DelegatorDataType = {
  transactionId: string | undefined;
  selectedRewardType: 'LONG' | 'SHORT' | undefined;
  withdrawnAmount: undefined | string;
  selectedChainId: DelegatorChainIds | undefined;
  delegatorData: DelegatorType[];
};

export const initialDelegatorState = {
  transactionId: undefined,
  selectedRewardType: undefined,
  withdrawnAmount: undefined,
  selectedChainId: undefined,
  delegatorData: [
    {
      name: 'ATOM',
      type: 'TENDERMINT',
      chainSymbol: 'ATOM',
      chainId: DelegatorChainIds.ATOM,
      networkId: networkConstant.networkId.polyMainnet,
      unclaimedRewards: undefined,
      totalMonthlyRewards: 400000,
      metadata: {
        longClaimPeriod: undefined,
        shortClaimPeriod: undefined,
        shortRewardPercentage: undefined,
      },
      rewardArray: undefined,
      hasLinked: false,
      delegatorLinks: [],
      delegationUrl: '',
      totalDelegated: '0',
      totalDelegatedToNode: 0,
      apy: 0,
    },
    {
      name: 'POLYGON',
      type: 'EVM',
      chainSymbol: 'MATIC',
      chainId: DelegatorChainIds.MATIC,
      networkId: networkConstant.networkId.polyMainnet,
      unclaimedRewards: undefined,
      totalMonthlyRewards: 500000,
      metadata: {
        longClaimPeriod: undefined,
        shortClaimPeriod: undefined,
        shortRewardPercentage: undefined,
      },
      rewardArray: undefined,
      hasLinked: false,
      delegationUrl: 'https://wallet.polygon.technology/staking/validators/43',
      delegatorLinks: [],
      totalDelegated: '0',
      totalDelegatedToNode: 0,
      apy: 0,
    },
  ],
} as DelegatorDataType;
