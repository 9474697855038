import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import tick from '../../../assets/images/tick.svg';
import stylesModal from '../modal.module.scss';
import styles from './claim-rewards-modal.module.scss';
import { formatToken, formatUSDTToken } from '../../../helpers/token-helper';
import { useSelector } from 'react-redux';

const Success = ({ amountReward, onClick }) => {
  const networkId = useSelector((state) => state.account.networkId);

  let amount =
    networkId === 1 || networkId === 0 || networkId === 137
      ? formatUSDTToken(amountReward)
      : formatToken(amountReward, 18);

  let usdTokenSymbol = '';
  if (networkId === 1) {
    usdTokenSymbol = 'USDT';
  } else if (networkId === 56) {
    usdTokenSymbol = 'BUSD';
  } else if (networkId === 137) {
    usdTokenSymbol = 'USDT';
  }
  return (
    <div className={cn(styles.body, styles.success)}>
      <div className={styles.successIcon}>
        <img src={tick} alt='' />
      </div>
      <div className={styles.title}>You claimed</div>
      <div className={styles.value}>
        ${usdTokenSymbol} ${amount}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={cn(stylesModal.button, styles.button)}
          variant='contained'
          onClick={() => onClick()}
          color='secondary'
        >
          Done
        </Button>
      </div>
    </div>
  );
};

Success.propTypes = {
  amountReward: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Success;
