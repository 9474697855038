import _ from 'lodash';

import { SET_AUTH, SET_NETWORK_TYPE, SET_NETWORK_ID, LOGOUT } from '../actions/action-types';
import { TYPE_AUTH } from '../constants/account-contants';
import networkHelpers from '../helpers/network.helpers';

const DEFAULT_FIELDS = {
  auth: null,
  address: '',
  networkType: networkHelpers.findNetworkType(Number(window.ethereum?.networkVersion)),
  networkId: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case SET_AUTH:
      if (
        !action.payload.address ||
        !action.payload.type ||
        !Object.values(TYPE_AUTH).includes(action.payload.type)
      ) {
        return state;
      }
      return {
        ...state,
        auth: action.payload.type,
        address: action.payload.address,
        isWalletConnected: !!action.payload.address,
      };
    case SET_NETWORK_TYPE:
      return {
        ...state,
        networkType: action.payload,
      };
    case SET_NETWORK_ID:
      return {
        ...state,
        networkId: action.payload,
      };
    case LOGOUT:
      return _.cloneDeep(DEFAULT_FIELDS);
    default:
      return state;
  }
};
