import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Loader from '../../../../components/loader';
import networkConstant from '../../../../constants/network.constant';
import Button from '../../../../external/components/Button/Button';
import Icon from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';
import networkHelpers from '../../../../helpers/network.helpers';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { useDelegatorClaim } from '../../hooks/claim.hook';
import { EvmDelegatorScreensEnum } from './Body';

interface Props {
  selectedChainId: DelegatorChainIds;
  setScreen: React.Dispatch<React.SetStateAction<EvmDelegatorScreensEnum>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  button + button {
    margin-left: 1rem;
  }
`;

export function EvmClaimSuccess({ selectedChainId, setScreen, setShow }: Props) {
  const [loading, setLoading] = useState(false);
  const { transactionId, walletNetworkId, delegatorData } = useSelector(
    ({ delegators, account }: State) => ({
      transactionId: (delegators as DelegatorDataType).transactionId,
      walletNetworkId: account.networkId,
      delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
        (del) => del.chainId === selectedChainId,
      )[0],
    }),
  );

  const { networkId } = delegatorData;

  const { getRequestedRewardArray, fetchRewards } = useDelegatorClaim({
    chainId: selectedChainId,
    walletNetworkId,
    networkId,
  });

  const init = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchRewards(), getRequestedRewardArray()]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setScreen(EvmDelegatorScreensEnum.ERROR);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <StyledWrapper>
        {loading ? (
          <>
            <Loader />
            <Text dark={true} style={{ marginTop: '1rem' }}>
              Transaction confirmed, updating balances...
            </Text>{' '}
          </>
        ) : (
          <>
            <Text dark={true} size='lg'>
              Success
            </Text>
            <Icon type='doneDark' width={100}></Icon>

            <Text style={{ margin: '0.5rem 0' }} color='lightpink'>
              <a
                rel='noopener noreferrer'
                target='_blank'
                href={`${networkHelpers.findBlockExplorerUrl(
                  networkConstant.networkId.bscMainnet,
                )}/tx/${transactionId}`}
              >
                View transaction{' '}
                <Icon
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                  type='external'
                />
              </a>
            </Text>

            <StyledButtonContainer>
              <Button
                dark={true}
                variant='secondary'
                onClick={() => setScreen(EvmDelegatorScreensEnum.HOME)}
              >
                View summary
              </Button>
              <Button dark={true} variant='secondary' onClick={() => setShow(false)}>
                Close
              </Button>
            </StyledButtonContainer>
          </>
        )}
      </StyledWrapper>
    </>
  );
}
