import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './pool-round-modal.module.scss';
import Pagination from '../../pagination';
import { formatPrecision } from '../../../helpers/function-helper';

const Table = ({ data, totalEntries, currentPage, totalDataSize, sizePerPage, onClick }) => (
  <>
    <div className={styles.title}>Total Entries {formatPrecision(totalEntries, 0)}</div>
    <div className={styles.table}>
      <div className={cn(styles.tableRow, styles.tableHeading)}>
        <div className={styles.tableCell}>#</div>
        <div className={styles.tableCell}>User</div>
        <div className={styles.tableCell}>User Entries</div>
      </div>
      {data.map((item) => (
        <div key={item.number} className={styles.tableRow}>
          <div className={styles.tableCell}>{item.number}</div>
          <div className={styles.tableCell}>{item.address}</div>
          <div className={styles.tableCell}>{formatPrecision(item.userEntries, 0)}</div>
        </div>
      ))}
    </div>
    <Pagination
      currentPage={currentPage}
      totalDataSize={totalDataSize}
      sizePerPage={sizePerPage}
      onClick={(page) => onClick(page)}
      className={styles.pagination}
    />
  </>
);

Table.propTypes = {
  totalEntries: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalDataSize: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Table;
