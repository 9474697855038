import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.187 1.27441C4.70859 1.27441 0.28125 5.70175 0.28125 11.1802C0.28125 16.6586 4.70859 21.086 10.187 21.086C15.6655 21.086 20.0928 16.6586 20.0928 11.1802C20.0928 5.70175 15.6655 1.27441 10.187 1.27441V1.27441Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2276 0C17.4227 0 22.4552 5.01659 22.4552 11.2276C22.4552 17.4227 17.4386 22.4552 11.2276 22.4552C5.03252 22.4552 0 17.4386 0 11.2276C0 5.03252 5.01659 0 11.2276 0V0ZM10.0013 1.75183C15.3046 1.75183 19.5886 6.05176 19.5886 11.3391C19.5886 16.6423 15.2887 20.9263 10.0013 20.9263C4.69808 20.9263 0.414068 16.6264 0.414068 11.3391C0.414068 6.03583 4.714 1.75183 10.0013 1.75183V1.75183Z'
        fill='#53710C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8785 3.00995C20.4233 4.93696 21.347 7.37359 21.347 10.0332C21.347 16.2283 16.3304 21.2608 10.1194 21.2608C7.15723 21.2608 4.46579 20.1141 2.45916 18.2349C1.71065 17.3112 1.1214 16.2601 0.691406 15.1294C1.16918 15.9257 1.75843 16.6264 2.44323 17.2475C4.19506 19.493 6.93427 20.9263 9.992 20.9263C15.2953 20.9263 19.5793 16.6264 19.5793 11.3391C19.5793 8.53616 18.3689 6.00398 16.4419 4.25216C14.6901 2.00664 11.9509 0.573325 8.89313 0.573325C8.33573 0.573325 7.77833 0.621102 7.23686 0.716656C8.46314 0.254811 9.8009 0 11.2024 0C14.1645 0 16.856 1.14665 18.8626 3.02588L18.8785 3.00995Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.34834 8.77486C9.07761 10.67 8.87057 9.50744 9.88982 11.3707C10.495 12.4696 11.2913 12.1989 12.5494 12.1989C12.4539 12.9474 12.0239 13.2341 12.6609 13.7915C13.2183 14.2851 13.5368 14.1418 13.1387 15.2088C12.629 16.5784 11.0842 17.2155 11.2754 15.0177L11.992 14.9381C11.9124 14.4126 11.4346 14.094 10.8931 13.9029C10.8294 16.1325 11.3709 17.1995 11.3391 17.5499C10.6224 17.9481 10.3994 18.0118 9.52353 17.5977C9.5076 16.8014 9.85797 16.4192 9.74649 15.6548C9.66686 15.1292 9.3802 14.524 9.28464 13.9189C7.38949 14.5081 8.61576 14.2214 6.68875 14.3807C6.44987 14.8585 5.9721 15.1451 5.79692 15.814C5.54211 16.7855 6.17913 17.1358 5.95617 17.6773C5.43063 17.9799 4.65027 17.7251 4.28398 17.4384C4.14065 16.2122 4.74582 16.1644 4.84138 15.1133C4.90508 14.3966 4.76175 13.1385 4.7299 12.3422C4.47509 12.4855 4.04509 12.7404 3.63102 12.8837C3.15325 13.0429 2.61178 13.0748 2.48438 12.5492C3.24881 12.4218 3.15325 12.6767 3.42399 12.7085C3.69473 12.7404 3.64695 12.6926 3.80621 12.613C4.2999 12.3741 4.66619 12.2467 4.7936 11.5459C5.22359 9.173 6.5295 8.16969 9.34834 8.80671V8.77486Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6719 6.40245C15.3089 6.45022 16.4715 5.95653 17.3155 7.23058C18.3826 8.82315 16.8378 9.50795 16.5829 9.85832C16.567 9.9698 16.5192 10.0654 16.4555 10.1291C16.2007 10.1291 15.9618 10.0654 15.723 9.9698C15.5956 9.92202 15.3726 9.76276 15.3089 10.0494C15.293 10.145 15.3248 10.2087 15.3885 10.2724C15.3567 10.2565 15.3248 10.2405 15.293 10.2246C15.1178 10.145 14.7833 9.9061 14.6719 10.3202C14.6241 10.5113 14.7196 10.6387 14.9108 10.7342C13.9552 11.0846 13.6686 10.9253 12.8563 10.5272C13.1271 10.9413 13.0315 10.7183 13.4297 10.9413C12.7608 12.7249 10.579 12.2153 10.0534 11.4031C9.86231 11.1005 9.79861 10.9253 9.59158 10.6068C9.35269 10.2565 9.25714 9.9698 9.30491 9.46018C9.38454 8.52056 9.97379 8.2976 10.8497 8.52056C12.0441 7.53317 13.2067 7.27836 14.4171 8.21797C14.7993 8.42501 15.0222 8.80722 15.5 8.80722C16.3918 8.80722 16.9811 7.66057 16.2167 7.0554C15.5 6.498 13.8437 7.46947 13.9234 6.09986C14.0508 5.98838 13.9552 5.82912 14.4011 6.02023C14.8948 6.22726 14.3215 5.89282 14.5763 6.16356C14.5922 6.17949 14.5604 6.51393 14.6719 6.29097C14.6719 6.27504 14.6878 6.33874 14.7196 6.35467L14.6719 6.40245ZM13.5093 9.22129C13.3819 9.20537 13.2863 9.26907 13.2863 9.3487C13.2863 9.44425 13.3819 9.52388 13.5093 9.5398C13.6367 9.55573 13.7323 9.49203 13.7323 9.4124C13.7323 9.31685 13.6367 9.23722 13.5093 9.22129Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.31642 16.9132C7.71125 17.0406 7.13792 16.9769 6.86719 16.5469C6.89904 15.687 7.59977 16.0214 7.28125 14.4129C8.10939 14.4288 8.14124 14.2696 8.84197 14.1899C8.71457 14.8429 8.55531 14.9225 8.34828 15.3844C8.09346 15.9577 8.3642 16.2603 8.31642 16.9132Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2794 10.2893C15.9005 10.5919 16.649 10.6237 17.5249 10.353C16.7764 10.9582 14.5627 11.1015 14.7538 10.3689C14.8494 10.0026 15.136 10.2097 15.2953 10.2893H15.2794Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7201 9.98585C16.1819 10.1929 16.7234 10.2088 17.3445 9.98585C16.8189 10.4318 15.2104 10.5751 15.3219 10.0496C15.3856 9.77882 15.5927 9.92215 15.7201 9.96993V9.98585Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0728 5.75076C13.0409 5.60743 13.025 5.49595 12.8976 5.4641C12.7861 5.43225 12.7065 5.4004 12.6109 5.25707C12.4676 5.03411 12.4516 4.74744 12.3402 4.50856C12.7224 4.49263 13.009 4.87485 12.9931 5.28892C12.9931 5.33669 12.9772 5.41632 12.9772 5.44817C12.9772 5.49595 13.0728 5.73484 13.1046 5.76669C13.1524 5.83039 13.8372 6.10113 13.9009 6.13298C13.869 6.06928 13.8531 5.9578 13.8531 5.91002L13.9646 6.18076C14.0601 6.18076 14.2831 6.27631 14.4424 6.29224C14.522 6.29224 14.5538 6.29224 14.6016 6.35594C14.4264 6.43557 13.8053 6.18076 13.6142 6.10113C13.5027 6.05335 13.3116 5.98965 13.1842 6.0215C13.2002 6.00557 13.2798 5.98965 13.3116 5.98965C13.3276 5.97372 13.3276 5.98965 13.3276 5.97372C13.1683 5.87817 12.9613 5.78261 12.7861 5.70299C12.6746 5.63928 12.6109 5.60743 12.5154 5.60743C12.3879 5.62336 12.2924 5.57558 12.2128 5.68706C12.0694 5.86224 11.8465 6.0215 11.6076 6.0852C11.4006 6.13298 11.1457 6.11705 11.0183 5.9578C11.3687 5.79854 11.7509 5.48003 12.1331 5.57558C12.2765 5.60743 12.1809 5.62336 12.4676 5.59151C12.2446 5.4641 11.6235 5.30484 11.4165 5.35262C11.2891 5.38447 11.1617 5.60743 10.7954 5.65521C10.6202 5.68706 10.3972 5.62336 10.2539 5.5278C10.6043 5.22522 10.9546 5.11374 11.3209 5.30484C11.4643 5.38447 11.4165 5.30484 11.6554 5.33669C11.8146 5.35262 11.9739 5.38447 12.1013 5.41632C11.9102 5.22522 11.9739 5.25707 11.8465 5.22521C11.4483 5.16151 11.2732 4.66782 11.1298 4.36523C11.3209 4.2856 11.5439 4.39708 11.6713 4.55634C12.0535 4.98633 11.7668 5.30484 12.3242 5.49595C12.5154 5.55965 12.802 5.70299 13.009 5.79854L13.0728 5.75076Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2783 6.01945C13.1986 6.11501 13.1827 6.17871 13.0234 6.27426C12.896 6.35389 12.7846 6.36982 12.6572 6.38574C12.4501 6.40167 12.1953 6.38574 11.9883 6.38574C12.1953 6.09908 12.5935 5.8602 12.9597 5.90797C13.0234 5.9239 13.0712 5.9239 13.119 5.93982L13.2942 6.00353L13.2783 6.01945Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9075 5.93913L13.7801 5.9232C13.7482 5.90728 13.7004 5.89135 13.6686 5.8595C13.4615 5.68432 13.5571 5.28617 13.4297 4.99951C13.7482 4.99951 14.21 5.54098 13.9075 5.9232V5.93913Z'
        fill='#8AC014'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.65478 9.41324C5.41589 10.1299 5.33627 10.8625 5.39997 11.5951C5.36812 10.8625 5.5433 10.0025 5.81404 9.30176L5.65478 9.41324Z'
        fill='#8AC014'
      />
    </svg>
  );
}
