import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BN from 'bignumber.js';
import Button from '@material-ui/core/Button';
import { formatDollars } from '../../../../helpers/token-helper';
import styles from './balance-item.module.scss';
import { useSelector } from 'react-redux';

const BalanceItem = ({
  icon,
  iconLabel,
  subIcons,
  token,
  stakeBalance,
  formattedStakeBalance,
  formattedBalance,
  usdPrice,
  balance,
  onClick,
  withoutStaking,
  buttonText,
  stakeBalanceInUSD,
}) => {
  const dark = useSelector((state) => state.basic.dark);
  const getStakeBalanceInUSD = stakeBalanceInUSD
    ? stakeBalanceInUSD
    : new BN(stakeBalance).times(usdPrice).toFixed(0, BN.ROUND_DOWN);
  const balanceInUSD = new BN(balance).times(usdPrice).toFixed(0, BN.ROUND_DOWN);

  return (
    <div className={cn(styles.container, { [styles.dark]: dark })}>
      <div className={styles.column}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={icon} alt='' />
          {iconLabel && <div className={styles.logoLabel}>{iconLabel}</div>}
          {!!subIcons.length && (
            <div className={styles.subIcons}>
              {subIcons.map((src) => (
                <img className={styles.subIconsItem} key={src} src={src} alt='' />
              ))}
            </div>
          )}
        </div>
        <div>
          <div className={styles.value}>
            {token} {formattedBalance}
          </div>
          {usdPrice ? <div className={styles.usd}>${formatDollars(balanceInUSD)}</div> : null}
        </div>
      </div>
      <div className={styles.column}>
        <div>
          {withoutStaking ? (
            '—'
          ) : (
            <>
              <div className={styles.value}>{formattedStakeBalance}</div>
              {usdPrice ? (
                <div className={styles.usd}>${formatDollars(getStakeBalanceInUSD)}</div>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className={styles.column}>
        {onClick && (
          <Button variant='outlined' color='secondary' className={styles.button} onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

BalanceItem.propTypes = {
  token: PropTypes.string.isRequired,
  formattedStakeBalance: PropTypes.string,
  formattedBalance: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  withoutStaking: PropTypes.bool,
  stakeBalance: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  usdPrice: PropTypes.number,
  subIcons: PropTypes.array,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  stakeBalanceInUSD: PropTypes.string,
};

BalanceItem.defaultProps = {
  icon: '',
  iconLabel: '',
  stakeBalance: '0',
  buttonText: '',
  formattedStakeBalance: '0',
  withoutStaking: false,
  onClick: null,
  subIcons: [],
  usdPrice: 0,
};

export default BalanceItem;
