import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 864 504.979'
      fill={color}
      className={className}
      {...otherProps}
    >
      <defs>
        <linearGradient
          id='a'
          x1={0}
          y1={252.489}
          x2={864}
          y2={252.489}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.1} stopColor='#f8b994' />
          <stop offset={0.9} stopColor='#ff97d6' />
        </linearGradient>
      </defs>
      <path
        d='M528.167 96.166 432 0l-96.166 96.166L432 192.334l96.166-96.166Zm239.833-16L512 336.183c-44.125 44.094-115.875 44.094-160 0L96 80.166l-96 96 256 256.016c48.531 48.531 112.25 72.797 176 72.797s127.469-24.266 176-72.797l256-256.016-96-96Z'
        style={{
          fill: 'url(#a)',
        }}
      />
    </svg>
  );
}
