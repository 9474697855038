import axios from 'axios';

import web3Service from '../../web3-service';
import DELEGATE_CONTRACT_ABI from '../../../blockchain/abi/DELEGATE_CONTRACT_ABI.json';
import config from '../../../config/env';

class RewardDelegatingService {
  constructor(address, chainName) {
    this._contract = new web3Service.web3.eth.Contract(DELEGATE_CONTRACT_ABI, address);
    this._chainName = chainName;
  }

  get contract() {
    return this._contract;
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }
  async isAddressMapped(ethAddress, targetAddress) {
    const data = {};
    const graphUrl = `${config.REACT_APP_THEGRAPH_API_URL}/subgraphs/name/${config.REACT_APP_LINKING_SUBGRAPH_URL}`;

    try {
      const links = await axios.post(graphUrl, {
        variables: {},
        query: `{
            links(where: {ethereumAddress: "${ethAddress}", targetChain: "${this._chainName}", targetAddress: "${targetAddress}"}) {
                id
                ethereumAddress
                targetAddress
                signature
                targetChain
                timestamp
              }
            }
          `,
      });
      data.isMapped = links?.data?.data?.links[0] ? true : false;
    } catch (e) {
      throw Error('Unable to get mapped addresses from contract', e);
    }
    return data;
  }

  async getUserRequestedAmounts(ethAddress) {
    try {
      return this.contract.methods.getRequestedRewardArray(ethAddress).call();
    } catch (e) {
      throw Error('Unable to get requested rewards from contract', e);
    }
  }

  async requestReward(proof, address, amount, isFullWithdraw) {
    try {
      const hash = await this.contract.methods
        .requestTokensByMerkleProof(proof, amount, isFullWithdraw)
        .send({ from: address });
      return hash.transactionHash;
    } catch (e) {
      throw Error('Unable to send request trx', e);
    }
  }

  async receiveReward(index, address) {
    try {
      const hash = await this.contract.methods.receiveReward(index).send({ from: address });
      return hash.transactionHash;
    } catch (e) {
      throw Error('Unable to send receive trx', e);
    }
  }

  async getLastClaimTimestamp(ethAddress) {
    return this.contract.methods.lastRewardRequestTime(ethAddress).call();
  }

  async getLastMerkleUpdateTimestamp() {
    return this.contract.methods.merkleRootLastUpdateTime().call();
  }
}

export default RewardDelegatingService;
