import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import metamaskIcon from '../../assets/images/wallets/Metamask.png';
import walletConnectIcon from '../../assets/images/wallets/WalletConnect.png';
import walletLinkIcon from '../../assets/images/wallets/WalletLink.png';
import polkadotJsIcon from '../../assets/images/polkadotJs.svg';
import KsmIcon from '../../assets/images/tokens/ksm.svg';
import DotIcon from '../../assets/images/tokens/dot.svg';
import { TYPE_AUTH } from '../../constants/account-contants';
import cutWalletAddress from '../../util/cutWalletAddress';

import s from './userNav.module.scss';

const profileIcon = {
  [TYPE_AUTH.METAMASK]: metamaskIcon,
  [TYPE_AUTH.WALLET_CONNECT]: walletConnectIcon,
  [TYPE_AUTH.WALLET_CONNECT_BSC]: walletConnectIcon,
  [TYPE_AUTH.WALLET_LINK]: walletLinkIcon,
  [TYPE_AUTH.POLKADOT_JS]: polkadotJsIcon,
};

const UserNav = ({ handleTrigger, account, typeAuth }) => {
  const dark = useSelector((state) => state.basic.dark);

  if (typeAuth === 'polkadot_js') {
    if (account[0] === '1') {
      profileIcon[typeAuth] = DotIcon;
    } else {
      profileIcon[typeAuth] = KsmIcon;
    }
  }

  return (
    <div className={s.wrap}>
      <button className={cn(s.trigger, { [s.dark]: dark })} onClick={handleTrigger}>
        <div className={s.hint}>{cutWalletAddress(account)}</div>
        <img className={s.icon} src={profileIcon[typeAuth]} alt='' />
        <span className={s.dots} />
      </button>
    </div>
  );
};

UserNav.propTypes = {
  account: PropTypes.string.isRequired,
  typeAuth: PropTypes.string.isRequired,
  handleTrigger: PropTypes.func.isRequired,
};

export default UserNav;
