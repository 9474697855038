import React from 'react';

/**
 * @TODO Lazy load based on prop `type`
 */
import BarChart from './icons/BarChart';
import Padlock from './icons/Padlock';
import PieChart from './icons/PieChart';
import Database from './icons/Database';
import Ribbon from './icons/Ribbon';
import Ribbon2 from './icons/Ribbon2';
import RibbonOM from './icons/RibbonOM';
import RibbonApprove from './icons/RibbonApprove';
import RibbonApproveDark from './icons/RibbonApproveDark';
import Done from './icons/Done';
import DoneDark from './icons/DoneDark';
import Om from './icons/Om';
import OmDash from './icons/OmDash';
import LockedPool from './icons/LockedPool';
import DownArrow from './icons/DownChevron';
import UpArrow from './icons/UpChevron';
import Spinner from './icons/Spinner';
import LinkingIcon from './icons/LinkIcon';

import GreenCircle from './icons/GreenCircle';
import PinkCircle from './icons/PinkCircle';
import Alert from './icons/Alert';

/**
 * CHAIN ICONS
 */

import { AtomIcon } from './icons/chains';
import { BandIcon } from './icons/chains';
import { BlzIcon } from './icons/chains';
import { FuseIcon } from './icons/chains';
import { KavaIcon } from './icons/chains';
import { KexIcon } from './icons/chains';
import { KsmIcon } from './icons/chains';
import { LunaIcon } from './icons/chains';
import { NgmIcon } from './icons/chains';
import { PolkadotIcon } from './icons/chains';
import { TomoIcon } from './icons/chains';
import SpinnerWhite from './icons/SpinnerWhite';

/**
 * @TODO Lazy load based on prop `type`
 */
import BlackBankIcon from './icons/tokens/BlockBank';
import BCubeIcon from './icons/tokens/BCube';
import BitspawnIcon from './icons/tokens/Bitspawn';
import CryptoTycoonIcon from './icons/tokens/CryptoTycoon';
import DaiIcon from './icons/tokens/Dai';
import DnftIcon from './icons/tokens/Dnft';
import DpetIcon from './icons/tokens/MyDefiPet';
import EthIcon from './icons/tokens/Eth';
import EthLiteIcon from './icons/tokens/EthLite';
import EmiSwapIcon from './icons/tokens/EmiSwap';
import FinminityIcon from './icons/tokens/Finminity';
import GainsIcon from './icons/tokens/Gains';
import GamyfiIcon from './icons/tokens/Gamyfi';
import GreenheartIcon from './icons/tokens/Greenheart';
import KwikIcon from './icons/tokens/Kwik';
import LepriconIcon from './icons/tokens/Lepricon';
import LabsIcon from './icons/tokens/Labs';
import MelalieIcon from './icons/tokens/Melalie';
import MediaLicenseIcon from './icons/tokens/MediaLicense';
import PolkafoundryIcon from './icons/tokens/Polkafoundry';
import PolarsIcon from './icons/tokens/Polars';
import RazeIcon from './icons/tokens/Raze';
import TrodlIcon from './icons/tokens/Trodl';
import SingularityDaoIcon from './icons/tokens/SingularityDao';
import WasderIcon from './icons/tokens/Wasder';
import ExternalArrow from './icons/ExternalArrow';
import BiteIcon from './icons/tokens/DragonBite';
import EgrIcon from './icons/tokens/Egoras';
import Mixsome from './icons/tokens/Mixsome';
import UnifarmIcon from './icons/tokens/Unifarm';
import AliumIcon from './icons/tokens/AliumSwap';
import GrowIcon from './icons/tokens/Grow';
import CcaiIcon from './icons/tokens/Ccai';
import RetroDefiIcon from './icons/tokens/RetroDefi';
import TwoTwoIcon from './icons/tokens/TwoTwo';
import ZenditEQXIcon from './icons/tokens/ZenditEqx';
import CirusIcon from './icons/tokens/Cirus';
import RoyaIcon from './icons/tokens/Roya';
import FXFIcon from './icons/tokens/FXF';
import PriviIcon from './icons/tokens/Privi';
import WivaIcon from './icons/tokens/Wiva';
import SkyrimIcon from './icons/tokens/Skyrim';
import RuneIcon from './icons/tokens/Rune';
import MaticIcon from './icons/tokens/Matic';
import LinkIcon from './icons/tokens/Link';
import TetherIcon from './icons/tokens/Tether';
import UsdcIcon from './icons/tokens/Usdc';
import NetvrkIcon from './icons/tokens/Netvrk';
import TorumIcon from './icons/tokens/Torum';
import OctaneIcon from './icons/tokens/Octane';
import WeldIcon from './icons/tokens/Weld';
import BondlyIcon from './icons/tokens/Bondly';
import RioDefi from './icons/tokens/RioDefi';
import RosnIcon from './icons/tokens/Rosn';
import SwayIcon from './icons/tokens/Sway';
import WbtcToken from './icons/tokens/WrappedBtc';
import OMGtoken from './icons/tokens/Omg';
import ZenditPolkaPetsIcon from './icons/tokens/zenditPets';
import ClearpoolIcon from './icons/tokens/Clearpool';
import GamestationIcon from './icons/tokens/GameStation';
import ZenditLXF from './icons/tokens/zenditLXF';
import ZenditFACTR from './icons/tokens/zenditFACTR';
import ZenditUMAD from './icons/tokens/zenditUMAD';
import ZenditSDG from './icons/tokens/zenditSDG';
import ZenditCHICKS from './icons/tokens/zenditCHICKS';
import ZenditHINATA from './icons/tokens/zenditHINATA';
import ZenditMetaprintsNFT from './icons/tokens/zenditMetaprintsNFT';
import ImpactIcon from './icons/tokens/Impact';
import TerraUst from './icons/tokens/TerraUst';
import KYLIcon from './icons/tokens/KylIcon';
import FINEIcon from './icons/tokens/Fine';
import ZenUsdtIcon from './icons/tokens/ZenUsdt';
import ZenUsdcIcon from './icons/tokens/ZenUsdc';
import ZenEthIcon from './icons/tokens/ZenEth';
import ZenDaiIcon from './icons/tokens/ZenDai';
import ZenWbtcIcon from './icons/tokens/ZenWbtc';
import ZenUstIcon from './icons/tokens/zenUst';
import ZenReceipt from './icons/tokens/ZenReceipt';
import Asva from './icons/tokens/Asva';
import BUSD from './icons/tokens/BUSD';
import HeliIcon from './icons/tokens/Heli';

// Claim Icons
import BlackBankClaimIcon from './icons/claim/BlockBank';
import BCubeClaimIcon from './icons/claim/Bcube';
import CryptoTycoonClaimIcon from './icons/claim/CryptoTycoon';
import DnftClaimIcon from './icons/claim/Dnft';
import EmiSwapClaimIcon from './icons/claim/EmiSwap';
import FinminityClaimIcon from './icons/claim/Finminity';
import GainsClaimIcon from './icons/claim/Gains';
import GamyfiClaimIcon from './icons/claim/Gamyfi';
import KwikClaimIcon from './icons/claim/Kwik';
import LepriconClaimIcon from './icons/claim/Lepricon';
import LabsClaimIcon from './icons/claim/Labs';
import MelalieClaimIcon from './icons/claim/Melalie';
import MediaLicenseClaimIcon from './icons/claim/MediaLicense';
import PolkafoundryClaimIcon from './icons/claim/PolkaFoundry';
import PolarsClaimIcon from './icons/claim/Polars';
import RazeClaimIcon from './icons/claim/Raze';
import TrodlClaimIcon from './icons/claim/Trodl';
import SingularityDaoClaimIcon from './icons/claim/Sdao';
import WasderClaimIcon from './icons/claim/Wasder';
import BiteClaimIcon from './icons/claim/DragonBite';
import EgrClaimIcon from './icons/claim/Egoras';
import MixClaimIcon from './icons/claim/Mixsome';
import UnifarmClaimIcon from './icons/claim/Unifarm';
import AliumClaimIcon from './icons/claim/AliumSwap';
import GrowClaimIcon from './icons/claim/Grow';
import CcaiClaimIcon from './icons/claim/CCai';
import DefaultClaimIcon from './icons/claim/DefaultClaim';

// Network icons
import EtherscanIcon from './icons/network/Etherscan';
import BscIcon from './icons/network/Bsc';
import PolyIcon from './icons/network/Polygon';

import { NetworkId } from '../../../types/network.types';
import networkConstant from '../../../constants/network.constant';

export type IconType =
  | 'alert'
  | 'barchart'
  | 'external'
  | 'padlock'
  | 'piechart'
  | 'database'
  | 'locked_pool'
  | 'om'
  | 'omDash'
  | 'ribbon'
  | 'downArrow'
  | 'upArrow'
  | 'ribbon2'
  | 'ribbonOM'
  | 'spinner_white'
  | 'ribbon_approve'
  | 'ribbon_approve_dark'
  | 'done'
  | 'doneDark'
  | 'downArrow'
  | 'upArrow'
  | 'spinner'
  | 'spinner_white'
  | 'greenCircle'
  | 'pinkCircle'
  | 'linkIcon'
  | 'alert';

export type ContractType =
  | 'ALM'
  | 'ASVA'
  | 'BBANK'
  | 'BNB_BBANK'
  | 'BCUBE'
  | 'BITE'
  | 'BNB'
  | 'BNB_CBD'
  | 'BUSD'
  | 'CBD'
  | 'CCAI'
  | 'BONDLY'
  | 'CTT'
  | 'CPOOL'
  | 'DAI'
  | 'DNFT'
  | 'DPET'
  | 'EGR'
  | 'ESW'
  | 'ETH'
  | 'ETHLITE'
  | 'FXF'
  | 'FMT'
  | 'GAINS'
  | 'GROW'
  | 'GFX'
  | 'KWIK'
  | 'MIST'
  | 'WBNB'
  | 'FACTR'
  | 'KYL'
  | 'KWIK'
  | 'CIRUS'
  | 'LABS'
  | 'LINK'
  | 'L3P'
  | 'IMPACT'
  | 'zenditLXF'
  | 'MATIC'
  | 'WMATIC'
  | 'MEL'
  | 'MLT'
  | 'OM'
  | 'OMG'
  | 'FINE'
  | 'BNB_FINE'
  | 'PKF'
  | 'POL'
  | 'PIX'
  | 'RAZE'
  | 'RFUEL'
  | 'ROYA'
  | 'ROSN'
  | 'RUNE'
  | 'UFARM'
  | 'SDAO'
  | 'SOME'
  | 'SKYRIM'
  | 'SWAY'
  | 'SPWN'
  | 'TRO'
  | 'X22'
  | 'QBERT'
  | 'USDT'
  | 'USDC'
  | 'UMAD'
  | 'WAS'
  | 'TT'
  | 'EQX'
  | 'OM/V1'
  | 'WIVA'
  | 'NETVRK'
  | 'XTM'
  | 'WELD'
  | 'WBTC'
  | 'OCTANE'
  | 'zenditPETS'
  | 'GAMER'
  | 'zenditGAMER'
  | 'zenditFACTR'
  | 'zenditUMAD'
  | 'zenditSDG'
  | 'zenditCHICKS'
  | 'zenditHINATA'
  | 'zenditMetaprintsNFT'
  | 'zenUSDC'
  | 'zenUSDT'
  | 'zenWBTC'
  | 'zenDAI'
  | 'zenUST'
  | 'zenETH'
  | 'zenUST'
  | 'WETH'
  | 'sOM'
  | 'OM '
  | 'HELI[0x]';

export type ChainType =
  | 'ATOM'
  | 'BAND'
  | 'BLZ'
  | 'FUSE'
  | 'KAVA'
  | 'KEX'
  | 'COSMOS'
  | 'KSM'
  | 'LUNA'
  | 'MATIC'
  | 'POLYGON'
  | 'NGM'
  | 'POLKA'
  | 'TOMO';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  type: IconType;
  className?: string;
  color?: string;
  width?: number | string;
  height?: number;
  style?: any;
}

export interface TokenProps extends Omit<IconProps, 'type'> {
  type?: ContractType;
}

function TokenMap({ type, ...otherProps }: TokenProps) {
  switch (type) {
    case 'ALM':
      return <AliumIcon {...otherProps} />;
    case 'ASVA':
      return <Asva {...otherProps} />;
    case 'BBANK':
    case 'BNB_BBANK':
      return <BlackBankIcon {...otherProps} />;
    case 'BCUBE':
      return <BCubeIcon {...otherProps} />;
    case 'WBNB':
    case 'BNB':
      return <BscIcon {...otherProps} />;
    case 'BITE':
      return <BiteIcon {...otherProps} />;
    case 'BONDLY':
      return <BondlyIcon {...otherProps} />;
    case 'BUSD':
      return <BUSD {...otherProps} />;
    case 'BNB_CBD':
    case 'CBD':
      return <GreenheartIcon {...otherProps} />;
    case 'CCAI':
      return <CcaiIcon {...otherProps} />;
    case 'CPOOL':
      return <ClearpoolIcon {...otherProps} />;
    case 'CTT':
      return <CryptoTycoonIcon {...otherProps} />;
    case 'CIRUS':
      return <CirusIcon {...otherProps} />;
    case 'zenDAI':
      return <ZenDaiIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'DAI':
      return <DaiIcon {...otherProps} />;
    case 'DNFT':
      return <DnftIcon {...otherProps} />;
    case 'DPET':
      return <DpetIcon {...otherProps} />;
    case 'EGR':
      return <EgrIcon {...otherProps} />;
    case 'ESW':
      return <EmiSwapIcon {...otherProps} />;
    case 'FINE':
    case 'BNB_FINE':
      return <FINEIcon {...otherProps} />;
    case 'FMT':
      return <FinminityIcon {...otherProps} />;
    case 'FXF':
      return <FXFIcon {...otherProps} />;
    case 'KYL':
      return <KYLIcon {...otherProps} />;
    case 'GAINS':
      return <GainsIcon {...otherProps} />;
    case 'GFX':
      return <GamyfiIcon {...otherProps} />;
    case 'GROW':
      return <GrowIcon {...otherProps} />;
    case 'IMPACT':
      return <ImpactIcon {...otherProps} />;
    case 'KWIK':
      return <KwikIcon {...otherProps} />;
    case 'LABS':
      return <LabsIcon {...otherProps} />;
    case 'L3P':
      return <LepriconIcon {...otherProps} />;
    case 'LINK':
      return <LinkIcon {...otherProps} />;
    case 'zenditLXF':
      return <ZenditLXF {...otherProps} />;
    case 'WMATIC':
    case 'MATIC':
      return <MaticIcon {...otherProps} />;
    case 'MEL':
      return <MelalieIcon {...otherProps} />;
    case 'MLT':
      return <MediaLicenseIcon {...otherProps} />;
    case 'OM':
    case 'OM/V1':
      return <OmDash {...otherProps} />;
    case 'OMG':
      return <OMGtoken {...otherProps} />;
    case 'PIX':
      return <PriviIcon {...otherProps} />;
    case 'PKF':
      return <PolkafoundryIcon {...otherProps} />;
    case 'POL':
      return <PolarsIcon {...otherProps} />;
    case 'QBERT':
      return <RetroDefiIcon {...otherProps} />;
    case 'RAZE':
      return <RazeIcon {...otherProps} />;
    case 'RFUEL':
      return <RioDefi {...otherProps} />;
    case 'ROSN':
      return <RosnIcon {...otherProps} />;
    case 'ROYA':
      return <RoyaIcon {...otherProps} />;
    case 'RUNE':
      return <RuneIcon {...otherProps} />;
    case 'SDAO':
      return <SingularityDaoIcon {...otherProps} />;
    case 'SKYRIM':
      return <SkyrimIcon {...otherProps} />;
    case 'SOME':
      return <Mixsome {...otherProps} />;
    case 'SPWN':
      return <BitspawnIcon {...otherProps} />;
    case 'zenUSDT':
      return <ZenUsdtIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'USDT':
      return <TetherIcon {...otherProps} />;
    case 'SWAY':
      return <SwayIcon {...otherProps} />;
    case 'TRO':
      return <TrodlIcon {...otherProps} />;
    case 'X22':
      return <TwoTwoIcon {...otherProps} />;
    case 'EQX':
      return <ZenditEQXIcon {...otherProps} />;
    case 'UFARM':
      return <UnifarmIcon {...otherProps} />;
    case 'WAS':
      return <WasderIcon {...otherProps} />;
    case 'WELD':
      return <WeldIcon {...otherProps} />;
    case 'WIVA':
      return <WivaIcon {...otherProps} />;
    case 'NETVRK':
      return <NetvrkIcon {...otherProps} />;
    case 'zenWBTC':
      return <ZenWbtcIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'WBTC':
      return <WbtcToken {...otherProps} />;
    case 'XTM':
      return <TorumIcon {...otherProps} />;
    case 'zenditPETS':
      return <ZenditPolkaPetsIcon {...otherProps} />;
    case 'zenUSDC':
      return <ZenUsdcIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'USDC':
      return <UsdcIcon {...otherProps} />;
    case 'GAMER':
    case 'zenditGAMER':
      return <GamestationIcon {...otherProps} />;
    case 'FACTR':
    case 'zenditFACTR':
      return <ZenditFACTR {...otherProps} />;
    case 'UMAD':
    case 'zenditUMAD':
      return <ZenditUMAD {...otherProps} />;
    case 'zenditSDG':
      return <ZenditSDG {...otherProps} />;
    case 'zenditCHICKS':
      return <ZenditCHICKS {...otherProps} />;
    case 'zenditHINATA':
      return <ZenditHINATA {...otherProps} />;
    case 'zenditMetaprintsNFT':
      return <ZenditMetaprintsNFT {...otherProps} />;
    case 'zenUST':
      return <ZenUstIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'OCTANE':
      return <OctaneIcon {...otherProps} />;
    case 'zenETH':
      return <ZenEthIcon {...otherProps} width={(otherProps.width as number) * 1.15} />;
    case 'ETHLITE':
    case 'ETH':
    case 'WETH':
      return <EthLiteIcon {...otherProps} width={(otherProps.width as number) * 0.8} />;
    case 'sOM':
    case 'OM ':
      return <ZenReceipt {...otherProps} />;
    case 'HELI[0x]':
      return <HeliIcon {...otherProps} />;
    default:
      return <EthIcon {...otherProps} />;
  }
}

Icon.Contract = TokenMap;

function ClaimTokens({ type, ...otherProps }: TokenProps) {
  switch (type) {
    case 'ALM':
      return <AliumClaimIcon {...otherProps} />;
    case 'BBANK':
      return <BlackBankClaimIcon {...otherProps} />;
    case 'BCUBE':
      return <BCubeClaimIcon {...otherProps} />;
    case 'BITE':
      return <BiteClaimIcon {...otherProps} />;
    case 'CCAI':
      return <CcaiClaimIcon {...otherProps} />;
    case 'CTT':
      return <CryptoTycoonClaimIcon {...otherProps} />;
    case 'DNFT':
      return <DnftClaimIcon {...otherProps} />;
    case 'EGR':
      return <EgrClaimIcon {...otherProps} />;
    case 'ESW':
      return <EmiSwapClaimIcon {...otherProps} />;
    case 'FMT':
      return <FinminityClaimIcon {...otherProps} />;
    case 'GAINS':
      return <GainsClaimIcon {...otherProps} />;
    case 'GFX':
      return <GamyfiClaimIcon {...otherProps} />;
    case 'GROW':
      return <GrowClaimIcon {...otherProps} />;
    case 'KWIK':
      return <KwikClaimIcon {...otherProps} />;
    case 'L3P':
      return <LepriconClaimIcon {...otherProps} />;
    case 'LABS':
      return <LabsClaimIcon {...otherProps} />;
    case 'MEL':
      return <MelalieClaimIcon {...otherProps} />;
    case 'MLT':
      return <MediaLicenseClaimIcon {...otherProps} />;

    case 'PKF':
      return <PolkafoundryClaimIcon {...otherProps} />;
    case 'POL':
      return <PolarsClaimIcon {...otherProps} />;
    case 'RAZE':
      return <RazeClaimIcon {...otherProps} />;
    case 'SDAO':
      return <SingularityDaoClaimIcon {...otherProps} />;
    case 'SOME':
      return <MixClaimIcon {...otherProps} />;
    case 'TRO':
      return <TrodlClaimIcon {...otherProps} />;
    case 'UFARM':
      return <UnifarmClaimIcon {...otherProps} />;
    case 'WAS':
      return <WasderClaimIcon {...otherProps} />;
    default:
      return <DefaultClaimIcon {...otherProps} />;
  }
}

Icon.Claim = ClaimTokens;

export interface NetworkIconProps extends Omit<IconProps, 'type'> {
  networkId: NetworkId;
}

function NetworkTokens({ networkId, ...otherProps }: NetworkIconProps) {
  switch (networkId) {
    case networkConstant.networkId.ethMainnet:
      return <EtherscanIcon {...otherProps} />;
    case networkConstant.networkId.bscMainnet:
      return <BscIcon {...otherProps} />;
    case networkConstant.networkId.polyTestnet:
    case networkConstant.networkId.polyMainnet:
      return <PolyIcon {...otherProps} />;
    default:
      return <EthIcon {...otherProps} />;
  }
}

Icon.Network = NetworkTokens;

export interface ChainIconProps extends Omit<IconProps, 'type'> {
  type: ChainType;
}

function ChainIcons({ type, ...props }: ChainIconProps) {
  switch (type) {
    case 'ATOM':
    case 'COSMOS':
      return <AtomIcon {...props} />;
    case 'BAND':
      return <BandIcon {...props} />;
    case 'BLZ':
      return <BlzIcon {...props} />;
    case 'FUSE':
      return <FuseIcon {...props} />;
    case 'KAVA':
      return <KavaIcon {...props} />;
    case 'KEX':
      return <KexIcon {...props} />;
    case 'KSM':
      return <KsmIcon {...props} />;
    case 'LUNA':
      return <LunaIcon {...props} />;
    case 'MATIC':
    case 'POLYGON':
      return <MaticIcon {...props} />;
    case 'NGM':
      return <NgmIcon {...props} />;
    case 'POLKA':
      return <PolkadotIcon {...props} />;
    case 'TOMO':
      return <TomoIcon {...props} />;
    default:
      return <TomoIcon {...props} />;
  }
}

Icon.Chain = ChainIcons;

export default function Icon({ type, ...otherProps }: IconProps) {
  switch (type) {
    case 'alert':
      return <Alert {...otherProps} />;
    case 'barchart':
      return <BarChart {...otherProps} />;
    case 'external':
      return <ExternalArrow {...otherProps} />;
    case 'padlock':
      return <Padlock {...otherProps} />;
    case 'piechart':
      return <PieChart {...otherProps} />;
    case 'database':
      return <Database {...otherProps} />;
    case 'locked_pool':
      return <LockedPool {...otherProps} />;
    case 'om':
      return <Om {...otherProps} />;
    case 'omDash':
      return <OmDash {...otherProps} />;
    case 'ribbon':
      return <Ribbon {...otherProps} />;
    case 'ribbon2':
      return <Ribbon2 {...otherProps} />;
    case 'ribbonOM':
      return <RibbonOM {...otherProps} />;
    case 'ribbon_approve':
      return <RibbonApprove {...otherProps} />;
    case 'ribbon_approve_dark':
      return <RibbonApproveDark {...otherProps} />;
    case 'spinner_white':
      return <SpinnerWhite {...otherProps} />;
    case 'done':
      return <Done {...otherProps} />;
    case 'doneDark':
      return <DoneDark {...otherProps} />;
    case 'upArrow':
      return <UpArrow {...otherProps} />;
    case 'downArrow':
      return <DownArrow {...otherProps} />;
    case 'spinner':
      return <Spinner {...otherProps} />;
    case 'greenCircle':
      return <GreenCircle {...otherProps} />;
    case 'pinkCircle':
      return <PinkCircle {...otherProps} />;
    case 'linkIcon':
      return <LinkingIcon {...otherProps} />;

    default:
      return <svg></svg>;
  }
}
