import BigNumber from 'bignumber.js';

export const BigNumberHelper = {
  isGreaterThan: (number1: string | number, number2: string | number): boolean => {
    return new BigNumber(number1).isGreaterThan(number2);
  },
  roundDecimals: (number1: string, numberOfDecimals?: number) => {
    return new BigNumber(number1).toFixed(numberOfDecimals || 2);
  },
  multiply: (number1: string | number, number2: string | number) => {
    return new BigNumber(number1).times(number2).toNumber();
  },
  isLessThanOrEqualTo: (number1: string | number, number2: string | number) => {
    return new BigNumber(number1).isLessThanOrEqualTo(number2);
  },
  isLessThanOrEqualToZero: (number1: string | number) => {
    return new BigNumber(number1).isLessThanOrEqualTo(0);
  },
  formatBigNumb: (number1: string | number, maximumFractionDigits?: number) => {
    const numbStr = new BigNumber(number1).toFixed();
    let maxDigits = 4;
    if (numbStr.includes('.')) {
      maxDigits = numbStr.split('.')[1].length;
    }
    const numb = new BigNumber(number1).toNumber();
    return numb.toLocaleString('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: numb < 1 ? maxDigits : 4,
    });
  },
  divide: (numerator: string | number, denominator: string | number) => {
    return new BigNumber(numerator).div(denominator).toFixed();
  },
  times: (number1: string | number, number2: string | number) => {
    return new BigNumber(number1).times(number2).toFixed();
  },
};
