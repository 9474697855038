import axios from 'axios';

import config from '../config/env';

export default {
  async getUniSwapV2(blockNumber: number, contractAddress: string) {
    const { data: response } = await axios.post(
      `${config.REACT_APP_THEGRAPH_API_URL}/subgraphs/name/${config.REACT_APP_THEGRAPH_API_UNISWAP_V2_NAME}`,
      {
        operationName: 'pairs',
        query: `
      query pairs {
        pairs(first: 200, where: {id_in: ["${contractAddress}"]}, block:{number: ${blockNumber}}, orderBy: trackedReserveETH, orderDirection: desc) {
          id
          reserveUSD
          trackedReserveETH
          volumeUSD
          untrackedVolumeUSD
          totalSupply
          __typename
        }
      }
    `,
      },
    );

    return response.data?.pairs[0];
  },
};
