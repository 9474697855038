import envVarSchema from './env.schema';
import { Environment } from './env.types';

const { value: envValues, error } = envVarSchema.validate(process.env, {
  stripUnknown: true,
});

if (error) {
  throw Error(`Environment config error: ${error}`);
}

export default envValues as Environment;
