import _ from 'lodash';
import {
  CLEAR_NOMINATORS,
  SET_UNCLAIMED_AMOUNT,
  SET_REQUESTED_REWARDS,
  SET_SUCCES_DATA,
  CHECK_IS_NOMINATOR,
  CHECK_IS_MAPPED,
  CHECK_IS_WAIT_FOR_NOMINATE,
  MAPPING_ETH_ADDRESS,
  SET_AMOUNT_BONDED,
} from '../actions/action-types';
import { CHAIN_NAMES, MERKLE_AIRDROP_NAMES } from '../constants/blockchain-constants';

const DEFAULT_PROPETIES = {
  isNominating: false,
  nominatingData: [],
  isMapped: false,
  info: {},
  mappings: [],
  unclaimedAmount: '0',
  requestedRewards: [],
  hash: '',
  successAmount: '0',
  bondedAmount: '0',
};

const DEFAULT_FIELDS = {
  polkadot: _.cloneDeep(DEFAULT_PROPETIES),
  kusama: _.cloneDeep(DEFAULT_PROPETIES),
  matic: _.cloneDeep(DEFAULT_PROPETIES),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  let chainFieldName = 'polkadot';
  if (action.payload && action.payload.chain) {
    switch (action.payload.chain) {
      case CHAIN_NAMES.POLKADOT:
        chainFieldName = 'polkadot';
        break;
      case CHAIN_NAMES.KUSAMA:
        chainFieldName = 'kusama';
        break;
      case MERKLE_AIRDROP_NAMES.MATIC:
        chainFieldName = 'matic';
        break;
      default:
        chainFieldName = 'polkadot';
        break;
    }
  }

  switch (action.type) {
    case CHECK_IS_NOMINATOR:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          isNominating: action.payload.isNominating,
          nominatingData: action.payload.nominatingData,
        },
      };
    case SET_AMOUNT_BONDED:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          bondedAmount: action.payload.bondedAmount,
        },
      };
    case CHECK_IS_MAPPED:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          isMapped: action.payload.isMapped,
          info: action.payload.info,
        },
      };
    case MAPPING_ETH_ADDRESS:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          mappings: state[chainFieldName].mappings.concat([
            [action.payload.sidechainAddress, action.payload.ethAddress],
          ]),
        },
      };
    case CHECK_IS_WAIT_FOR_NOMINATE:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          isReadyToClaim: action.payload.isReadyToClaim,
          endDate: action.payload.endDate,
        },
      };
    case SET_UNCLAIMED_AMOUNT:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          unclaimedAmount: action.payload.unclaimedAmount,
        },
      };
    case SET_REQUESTED_REWARDS:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          requestedRewards: action.payload.requestedRewards,
        },
      };
    case CLEAR_NOMINATORS:
      return {
        ...state,
        [chainFieldName]: { ...state[chainFieldName], isNominating: false, nominatingData: [] },
      };
    case SET_SUCCES_DATA:
      return {
        ...state,
        [chainFieldName]: {
          ...state[chainFieldName],
          hash: action.payload.hash,
          successAmount: action.payload.successAmount,
        },
      };
    default:
      return state;
  }
};
