export default {
  formatUSD: function (dollarValue?: number) {
    if (dollarValue) {
      return dollarValue.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'USD',
      });
    }
    return '$0';
  },

  formatUSDLong: function (dollarValue?: number) {
    if (dollarValue) {
      return dollarValue.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
        style: 'currency',
        currency: 'USD',
      });
    }
    return '$0';
  },
};
