import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Input from '../../../../components/form/input';
import styles from './input-with-button.module.scss';

const InputWithButton = ({
  className,
  tokenName,
  value,
  onChangeInput,
  handleClickButton,
  buttonText,
  placeHolder,
  ...props
}) => {
  const dark = useSelector((state) => state.basic.dark);
  return (
    <div className={cn(styles.wrapper, className, { [styles.dark]: dark })}>
      <div className={styles.tokenName}>{tokenName}</div>
      <Input
        className={cn(styles.input)}
        value={value}
        onChange={onChangeInput}
        placeholder={placeHolder}
        {...props}
      />
      <Button
        variant='contained'
        color='secondary'
        className={styles.button}
        onClick={handleClickButton}
      >
        {buttonText}
      </Button>
    </div>
  );
};

InputWithButton.propTypes = {
  className: PropTypes.string,
  placeHolder: PropTypes.string,
  tokenName: PropTypes.string,
  value: PropTypes.string,
  buttonText: PropTypes.string,
  onChangeInput: PropTypes.func,
  handleClickButton: PropTypes.func,
};

InputWithButton.defaultProps = {
  className: '',
  placeHolder: '',
  tokenName: '',
  value: '',
  buttonText: 'Max',
  onChangeInput: () => {},
  handleClickButton: () => {},
};

export default InputWithButton;
