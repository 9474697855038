import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { zeroPad } from 'react-countdown';
import styles from './countdown.module.scss';

const Timer = ({ className, days, hours, minutes, seconds, withSeconds, dark }) => {
  return (
    <div className={cn(styles.countdownBody, className, { [styles.dark]: dark })}>
      <div className={styles.countdownItem}>
        <span>{zeroPad(days)}</span>
        Days
      </div>
      <div className={styles.countdownItem}>
        <span>{zeroPad(hours)}</span>
        Hours
      </div>
      <div className={styles.countdownItem}>
        <span>{zeroPad(minutes)}</span>
        Min
      </div>
      {withSeconds && (
        <div className={styles.countdownItem}>
          <span>{zeroPad(seconds)}</span>
          Sec
        </div>
      )}
    </div>
  );
};

Timer.propTypes = {
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  withSeconds: PropTypes.bool,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

Timer.defaultProps = {
  withSeconds: false,
  dark: false,
  className: '',
};

export default Timer;
