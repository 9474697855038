import * as React from 'react';

function SvgPolkadotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <path
        d='M23.1 29.314c1.381-.16 2.663-.21 3.907-.456 3.254-.64 5.628-3.756 5.34-6.86-.34-3.609-3.079-6.208-6.622-6.319-3.405-.098-6.458 2.365-6.96 5.617a6.996 6.996 0 00.276 3.215c.2.64-.013 1.256-.54 1.564-.516.296-1.056.296-1.52-.086a1.638 1.638 0 01-.503-.752c-1.08-3.62-.352-6.873 2.425-9.533 2.739-2.624 6.068-3.338 9.674-2.143 3.619 1.194 5.767 3.781 6.32 7.488.817 5.519-3.317 10.335-9.009 10.655-.666.037-1.344.123-1.96.345-1.03.357-1.658 1.145-1.934 2.18-.176.677-.302 1.355-.478 2.032-.176.727-.854 1.17-1.533 1.035-.741-.148-1.218-.813-1.055-1.565.98-4.545 1.972-9.077 2.965-13.622.176-.8.88-1.207 1.646-1.023.729.173 1.118.85.93 1.688l-1.282 5.875c-.038.197-.05.394-.088.665z'
        fill='#303030'
      />
      <circle cx={25.453} cy={35.799} r={1.52} fill='#FE0C7C' />
    </svg>
  );
}

export default SvgPolkadotIcon;
