export default {
  networkId: {
    ethMainnet: 1,
    ethRinkeby: 4,
    ethKovan: 42,
    bscMainnet: 56,
    bscTestnet: 97,
    polyMainnet: 137,
    polyTestnet: 80001,
  } as any,
  networkIdHex: {
    ethMainnet: '0x1',
    ethKovan: '0x2a',
    bscMainnet: '0x38',
    bscTestnet: '0x61',
    polyMainnet: '0x89',
    polyTestnet: '0x13881',
  },
  networkType: {
    binance: 'binance',
    polygon: 'polygon',
    ethereum: 'ethereum',
  },
};
