import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Routes from './route';
import { initApp } from './actions/basic-actions';
import GlobalLoader from './components/global-loader';
import store from './store/configure-store';

const isDarkTheme = window.localStorage.getItem('mantra-palette') === 'dark';

store.dispatch(initApp()).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Routes />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

// Initial loading modal overlay
ReactDOM.render(<GlobalLoader dark={isDarkTheme} />, document.getElementById('root'));
