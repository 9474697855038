import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import styled, { css } from 'styled-components';
import { CloseIcon } from './CloseeIcon';

export interface SideBarProps {
  size?: 'sm' | 'md' | 'lg';
  dark?: boolean;
  className?: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const Overlay = styled.div<Pick<SideBarProps, 'dark'>>`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  @keyframes fadeIn {
    from {
      visibility: hidden;
      opacity: 0;
    }
  }
  animation: fadeIn 0.2s ease-in-out;

  ${({ dark }) =>
    dark &&
    css`
      background: rgba(255, 255, 255, 0.8);
    `};

  @media screen and (min-width: 576px) {
    align-items: center;
    padding: 0;
  }
`;

const SidebarCloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const SideBarWrapper = styled.div<Pick<SideBarProps, 'size' | 'dark'>>`
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  position: relative;
  background: #fff;
  width: 100%;
  height: 100vh;
  margin-bottom: 1.5rem;
  border-left: 2px solid #525f7f;
  z-index: 6;

  overflow-y: scroll;

  @media screen and (min-width: 576px) {
    width: 80%;
    margin-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
  }

  @media screen and (min-width: 1200px) {
    width: 50%;
  }

  @media screen and (min-width: 1600px) {
    width: 40%;
  }

  > ${SidebarCloseButton} {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    > svg {
      pointer-events: none;
    }
  }

  @keyframes expand {
    from {
      width: 0;
    }
  }
  animation: expand 0.3s ease-in-out;

  ${({ dark }) =>
    dark &&
    css`
      background: #171616e0;
    `};
`;

const SidebarBody = styled.div`
  width: 100%;
`;

export function SideBar({
  className,
  show,
  dark,
  setShow,
  size = 'md',
  children,
  ...props
}: PropsWithChildren<SideBarProps>) {
  const modalRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const closeModal = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (modalRef.current === e.target || btnRef.current === e.target) {
      setShow(false);
    }
  };

  function escapeKeyPress({ key }: KeyboardEvent) {
    if (key === 'Escape') {
      setShow(false);
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', escapeKeyPress);

    if (show) {
      // locks scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    }

    return () => {
      document.removeEventListener('keyup', escapeKeyPress);
      document.body.style.overflow = '';
      document.body.style.height = '';
    };
  }, [show]);

  return ReactDom.createPortal(
    show ? (
      <Overlay ref={modalRef} onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => closeModal(e)}>
        <SideBarWrapper dark={dark} className={className} {...props}>
          <SidebarCloseButton
            ref={btnRef}
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => closeModal(e)}
          >
            <CloseIcon />
          </SidebarCloseButton>
          <SidebarBody>{children}</SidebarBody>
        </SideBarWrapper>
      </Overlay>
    ) : null,
    document.getElementById('portal') as HTMLElement,
  );
}
