import { useSelector } from 'react-redux';
import Text from '../../../src/external/components/Text/Text';
import { FC } from 'react';
const Disclaimer: FC<{ dark?: boolean }> = ({ dark }) => {
  return (
    <div className='container' style={{ marginTop: '60px', marginBottom: '20px' }}>
      <Text
        dark={true}
        as='span'
        style={{ color: dark ? '#A8A8A8' : 'black' }}
        size='sm'
        weight={400}
      >
        THE PRODUCTS AND SERVICES DISCUSSED ON THIS SITE ARE NOT AVAILABLE TO, OR ACCESSIBLE BY,
        PERSONS, ENTITIES OR ORGANIZATIONS IN HONG KONG, THE UNITED STATES, OR ANY OFAC SANCTIONED
        COUNTRIES.
      </Text>
    </div>
  );
};

export default Disclaimer;
