import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='53'
      height='53'
      fill='none'
      viewBox='0 0 53 53'
      className={className}
      {...otherProps}
    >
      <path
        fill='#2775CA'
        d='M26.5 53A26.436 26.436 0 0053 26.5 26.436 26.436 0 0026.5 0 26.436 26.436 0 000 26.5 26.436 26.436 0 0026.5 53z'
      ></path>
      <path
        fill='#fff'
        d='M33.788 30.696c0-3.865-2.32-5.19-6.957-5.742-3.312-.442-3.975-1.325-3.975-2.87 0-1.547 1.104-2.54 3.313-2.54 1.987 0 3.091.662 3.643 2.319a.83.83 0 00.773.552h1.767a.755.755 0 00.773-.773v-.11a5.516 5.516 0 00-4.969-4.528v-2.65c0-.441-.331-.773-.883-.883h-1.656c-.442 0-.773.331-.884.883v2.54c-3.312.442-5.41 2.65-5.41 5.41 0 3.644 2.208 5.08 6.846 5.632 3.091.552 4.085 1.214 4.085 2.98 0 1.768-1.546 2.982-3.644 2.982-2.87 0-3.864-1.215-4.195-2.871-.11-.442-.442-.663-.773-.663h-1.877a.755.755 0 00-.773.773v.111c.441 2.76 2.208 4.748 5.852 5.3v2.65c0 .441.331.773.883.883h1.656c.442 0 .773-.331.884-.883v-2.65c3.312-.552 5.52-2.871 5.52-5.852z'
      ></path>
      <path
        fill='#fff'
        d='M20.869 42.29c-8.613-3.092-13.03-12.698-9.827-21.2 1.656-4.638 5.3-8.171 9.827-9.827.442-.221.662-.552.662-1.104V8.613c0-.442-.22-.773-.662-.884-.11 0-.331 0-.442.11C9.937 11.153 4.196 22.306 7.508 32.795c1.988 6.183 6.736 10.931 12.919 12.919.442.22.884 0 .994-.442.11-.11.11-.22.11-.441v-1.547c0-.33-.331-.772-.662-.993zM32.573 7.84c-.442-.221-.884 0-.994.441-.11.111-.11.221-.11.442v1.546c0 .442.331.883.662 1.104 8.613 3.092 13.03 12.698 9.827 21.2-1.656 4.638-5.3 8.171-9.827 9.827-.442.221-.662.552-.662 1.105v1.545c0 .442.22.773.662.884.11 0 .331 0 .442-.11 10.49-3.313 16.231-14.465 12.919-24.955-1.988-6.294-6.846-11.042-12.919-13.03z'
      ></path>
    </svg>
  );
}
