import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAddresses, changeChainMode } from '../../../actions/account-polkadot-actions';
import { showModal } from '../../../actions/modal-actions';
import polkadotIcon from '../../../assets/images/wallets/polkadot-inner.svg';
import kusamaIcon from '../../../assets/images/wallets/kusama-inner.svg';
import SubstrateIllustration from '../../../assets/images/wallets/substrate-illustration.svg';
import styles from './login-modal.module.scss';
import Item from './item';
import { CHAIN_NAMES } from '../../../constants/blockchain-constants';
import { POLKADOT_LOGIN_MODAL } from '../../../constants/modal-constants';

const SubstrateWallets = ({ global }) => {
  const dispatch = useDispatch();
  const { address: polkadotAddress } = useSelector((state) => state.polkadotAccount);
  const { address: kusamaAddress } = useSelector((state) => state.kusamaAccount);

  return (
    <>
      <img width='100%' alt='' src={SubstrateIllustration} />
      <h3
        className={styles.title}
        style={{ color: global.dark ? '#ffffff' : '#201f1e' }}
        id='alert-dialog-title'
      >
        Substrate Wallets
      </h3>
      <p className={styles.description} style={{ color: global.dark ? '#ffffff' : '#606371' }}>
        Safely connect to your existing blockchain wallet and access your assets.
      </p>
      <ul className={styles.list}>
        <Item
          title='Polkadot.js'
          icon={polkadotIcon}
          active={!!polkadotAddress}
          onClick={async () => {
            await dispatch(changeChainMode('POLKADOT_AUTH_MODE'));
            await dispatch(getAllAddresses(CHAIN_NAMES.POLKADOT));
            dispatch(showModal(POLKADOT_LOGIN_MODAL));
          }}
        />
        <Item
          title='Kusama'
          icon={kusamaIcon}
          active={!!kusamaAddress}
          onClick={async () => {
            await dispatch(changeChainMode('KUSAMA_AUTH_MODE'));
            await dispatch(getAllAddresses(CHAIN_NAMES.KUSAMA));
            dispatch(showModal(POLKADOT_LOGIN_MODAL));
          }}
        />
      </ul>
    </>
  );
};

SubstrateWallets.propTypes = {
  global: PropTypes.object.isRequired,
};

export default SubstrateWallets;
