import { UnstakeData } from '../screens/staking/types/staking.types';
import { StakingData } from '../services/staking/staking.data';
import { StakingPool } from '../services/staking/staking.types';
import { NetworkId } from '../types/network.types';

const initialState = StakingData;

export enum StakingActionEnum {
  SELECTED_STAKING_POOL = 'SELECTED_STAKING_POOL',
  SET_UNSTAKE_DATA = 'SET_UNSTAKE_DATA',
  SET_TOKEN_BALANCE = 'SET_TOKEN_BALANCE',
  SET_STAKED_BALANCE = 'SET_STAKED_BALANCE',
  SET_STAKED_BALANCE_USD = 'SET_STAKED_BALANCE_USD',
  SET_APR = 'SET_APR',
  SET_FEE = 'SET_FEE',
  SET_UNLOCKING_TIME = 'SET_UNLOCKING_TIME',
  SET_REWARD_BALANCE = 'SET_REWARD_BALANCE',
  SET_UNSTAKED_BALANCE = 'SET_UNSTAKED_BALANCE',
  SET_CLAIM_UNLOCK_TIME = 'SET_CLAIM_UNLOCK_TIME',
  SET_ESTIMATED_DAILY_REWARD = 'SET_ESTIMATED_DAILY_REWARD',
  SET_REWARDS_EARNED = 'SET_REWARDS_EARNED',
  SET_LP_RATE = 'SET_LP_RATE',
}

type SetRewardsEarned = {
  type: StakingActionEnum.SET_REWARDS_EARNED;
  payload: { id: string; networkId: NetworkId; rewardsEarned: string };
};

type SetEstimatedDailyReward = {
  type: StakingActionEnum.SET_ESTIMATED_DAILY_REWARD;
  payload: { id: string; networkId: NetworkId; dailyReward: string };
};

type SetClaimUnlockTime = {
  type: StakingActionEnum.SET_CLAIM_UNLOCK_TIME;
  payload: { id: string; networkId: NetworkId; claimUnlockTime: string };
};

type SetUnstakedBalance = {
  type: StakingActionEnum.SET_UNSTAKED_BALANCE;
  payload: { id: string; networkId: NetworkId; unstakedBalance: string };
};

type SetRewardBalance = {
  type: StakingActionEnum.SET_REWARD_BALANCE;
  payload: { id: string; networkId: NetworkId; rewardBalance: string };
};

type SetUnlockingTime = {
  type: StakingActionEnum.SET_UNLOCKING_TIME;
  payload: { id: string; networkId: NetworkId; unlockingTime: number };
};

type SetFee = {
  type: StakingActionEnum.SET_FEE;
  payload: { id: string; networkId: NetworkId; fee: number };
};

type SetUnstakeData = {
  type: StakingActionEnum.SET_UNSTAKE_DATA;
  payload: { id: string; networkId: NetworkId; unstakeData: UnstakeData };
};

type SetTokenBalance = {
  type: StakingActionEnum.SET_TOKEN_BALANCE;
  payload: { id: string; networkId: NetworkId; balance: string };
};

type SetStakedBalance = {
  type: StakingActionEnum.SET_STAKED_BALANCE;
  payload: { id: string; networkId: NetworkId; balance: string };
};
type SetStakedBalanceUsd = {
  type: StakingActionEnum.SET_STAKED_BALANCE_USD;
  payload: { id: string; networkId: NetworkId; balance: string };
};
type SetApr = {
  type: StakingActionEnum.SET_APR;
  payload: { id: string; networkId: NetworkId; apr: string };
};

type SelectStakingPool = {
  type: StakingActionEnum.SELECTED_STAKING_POOL;
  payload: StakingPool;
};
type SetLpRate = {
  type: StakingActionEnum.SET_LP_RATE;
  payload: { id: string; networkId: NetworkId; rate: number };
};

export type StakingPoolsActionType =
  | SelectStakingPool
  | SetStakedBalance
  | SetApr
  | SetStakedBalanceUsd
  | SetTokenBalance
  | SetUnstakeData
  | SetUnlockingTime
  | SetRewardBalance
  | SetUnstakedBalance
  | SetFee
  | SetClaimUnlockTime
  | SetEstimatedDailyReward
  | SetLpRate
  | SetRewardsEarned;

const StakingPoolsReducer = (
  state: StakingPool[] = initialState,
  action: StakingPoolsActionType,
) => {
  switch (action.type) {
    case StakingActionEnum.SELECTED_STAKING_POOL:
      return state;
    case StakingActionEnum.SET_STAKED_BALANCE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, stakedBalance: action.payload.balance }
          : stakingPool,
      );
    case StakingActionEnum.SET_STAKED_BALANCE_USD:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, stakedBalanceUsd: action.payload.balance }
          : stakingPool,
      );
    case StakingActionEnum.SET_APR:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, apr: action.payload.apr }
          : stakingPool,
      );
    case StakingActionEnum.SET_TOKEN_BALANCE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, tokenBalance: action.payload.balance }
          : stakingPool,
      );
    case StakingActionEnum.SET_UNSTAKE_DATA:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, unstakeData: { ...action.payload.unstakeData } }
          : stakingPool,
      );
    case StakingActionEnum.SET_FEE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, fee: action.payload.fee }
          : stakingPool,
      );
    case StakingActionEnum.SET_UNLOCKING_TIME:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, unlockingTime: action.payload.unlockingTime }
          : stakingPool,
      );
    case StakingActionEnum.SET_REWARD_BALANCE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, rewardBalance: action.payload.rewardBalance }
          : stakingPool,
      );
    case StakingActionEnum.SET_UNSTAKED_BALANCE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, unstakedBalance: action.payload.unstakedBalance }
          : stakingPool,
      );
    case StakingActionEnum.SET_CLAIM_UNLOCK_TIME:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, claimUnlockTime: action.payload.claimUnlockTime }
          : stakingPool,
      );
    case StakingActionEnum.SET_ESTIMATED_DAILY_REWARD:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, estimatedDailyReward: action.payload.dailyReward }
          : stakingPool,
      );
    case StakingActionEnum.SET_REWARDS_EARNED:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, rewardsEarned: action.payload.rewardsEarned }
          : stakingPool,
      );
    case StakingActionEnum.SET_LP_RATE:
      return state.map((stakingPool) =>
        stakingPool.stakingContractAddress === action.payload.id &&
        stakingPool.networkId === action.payload.networkId
          ? { ...stakingPool, lpTokenRate: action.payload.rate }
          : stakingPool,
      );
    default:
      return state;
  }
};

export default StakingPoolsReducer;
