import { Dispatch } from 'redux';
import { DelegatorActionEnum, DelegatorActionType } from '../reducers/delegator-reducer';
import {
  DelegatorChainIds,
  DelegatorRewardRecordWithIndex,
} from '../services/delegator/delegator.data';
import { DelegatorLink } from '../services/delegator/delegator.types';

export const DelegatorActions = {
  setApy: ({ apy, chainId }: { chainId: DelegatorChainIds; apy: number }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_APY,
        payload: { chainId, apy },
      });
    };
  },
  setTotalDelegatedToNode: ({
    chainId,
    totalDelegatedToNode,
  }: {
    chainId: DelegatorChainIds;
    totalDelegatedToNode: number;
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_TOTAL_DELEGATED_TO_NODE,
        payload: { chainId, totalDelegatedToNode },
      });
    };
  },
  setLinked: ({ chainId, hasLinked }: { chainId: DelegatorChainIds; hasLinked: boolean }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_HAS_LINKED,
        payload: { chainId, hasLinked },
      });
    };
  },
  setDelegatedAmount: ({
    chainId,
    delegatedAmount,
  }: {
    chainId: DelegatorChainIds;
    delegatedAmount: string;
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_DELEGATED_AMOUNT,
        payload: { chainId, delegatedAmount },
      });
    };
  },
  setDelegatorLinks: ({
    chainId,
    delegatorLinks,
  }: {
    chainId: DelegatorChainIds;
    delegatorLinks: DelegatorLink[];
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_DELEGATOR_LINKS,
        payload: { chainId, delegatorLinks },
      });
    };
  },
  setSelectedChainId: ({ chainId }: { chainId: DelegatorChainIds }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_SELECTED_CHAIN,
        payload: { chainId },
      });
    };
  },
  setUnclaimedRewards: ({
    unclaimedReward,
    chainId,
  }: {
    unclaimedReward: string;
    chainId: DelegatorChainIds;
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_UNCLAIM_AMOUNT,
        payload: { unclaimedReward, chainId },
      });
    };
  },
  setTransactionId: (transactionId: string) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_TRANSACTION_ID,
        payload: transactionId,
      });
    };
  },
  setRewardType: ({ selectedRewardType }: { selectedRewardType: 'LONG' | 'SHORT' }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_REWARD_CLAIM_PERIOD,
        payload: { selectedRewardType },
      });
    };
  },

  setRewardArray: ({
    chainId,
    rewardArray,
  }: {
    chainId: DelegatorChainIds;
    rewardArray: DelegatorRewardRecordWithIndex[];
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_REWARDS_ARRAY,
        payload: { chainId, rewardArray },
      });
    };
  },

  setWithdrawAmount: (withdrawAmount: string) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_WITHDRAW_AMOUNT,
        payload: withdrawAmount,
      });
    };
  },
  setMetadata: ({
    chainId,
    longClaimPeriod,
    shortClaimPeriod,
    shortRewardPercentage,
  }: {
    chainId: DelegatorChainIds;
    longClaimPeriod: string;
    shortClaimPeriod: string;
    shortRewardPercentage: string;
  }) => {
    return async (dispatch: Dispatch<DelegatorActionType>) => {
      dispatch({
        type: DelegatorActionEnum.SET_META_DATA,
        payload: {
          chainId,
          metadata: {
            longClaimPeriod,
            shortClaimPeriod,
            shortRewardPercentage,
          },
        },
      });
    };
  },
};
