import React from 'react';
import { IconProps } from '../Icon';

function ErrorIcon({ className, color, ...props }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 480 480'
      {...props}
      className={className}
    >
      <path
        fill='#fff'
        d='M460.74 211.66c-6.76-38.25-22.94-71.98-48.34-101.36-30.22-34.96-67.73-58.01-112.66-68.72-9.53-2.27-19.38-3.2-29.08-4.81-.79-.13-1.5-.77-2.24-1.17h-36.85c-6.64 1.23-13.27 2.49-19.92 3.67-38.25 6.76-71.98 22.94-101.36 48.34-34.96 30.22-58.01 67.73-68.72 112.66-2.27 9.53-3.2 19.38-4.81 29.08-.13.79-.77 1.5-1.17 2.24v36.85c1.23 6.64 2.49 13.27 3.67 19.92 6.76 38.25 22.94 71.98 48.34 101.36 30.22 34.96 67.73 58.01 112.66 68.72 9.53 2.27 19.38 3.2 29.08 4.81.79.13 1.5.77 2.24 1.17h36.85c6.78-1.23 13.55-2.49 20.33-3.7 37.5-6.71 70.79-22.46 99.72-47.18 37.65-32.16 61.65-72.52 71.61-121.09 1.63-7.97 2.89-16.01 4.32-24.02v-36.85c-1.23-6.64-2.49-13.28-3.67-19.92zm-36.8 49.56c-4.78 82.84-77.44 168.5-185.36 162.63-82.69-4.49-168.46-77.33-162.4-185.48 4.73-84.36 77.65-163.44 173.93-162.12 92.51-2.27 179.96 78.77 173.83 184.97z'
      ></path>
      <path
        fill='#fff'
        d='M222.74 209.88c0-17.72.57-35.47-.14-53.16-.93-23.37 17.02-28.78 32.74-26.55 15.89 2.26 21.91 10.24 21.91 26.22 0 36.7.05 73.4-.03 110.1-.03 13.02-8.47 22.03-21.44 23.31-18.5 1.82-30.11-4.44-32.1-18.5-1.28-9.03-.81-18.33-.91-27.5-.12-11.31-.03-22.62-.03-33.92zm27.35 160.34c-15.14 0-26.52-11.46-26.51-26.71 0-14.9 11.44-26.13 26.6-26.13 15.25 0 26.25 11.24 26.24 26.82-.01 14.6-11.56 26.02-26.33 26.02z'
      ></path>
    </svg>
  );
}

export default ErrorIcon;
