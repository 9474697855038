import web3Service from '../../web3-service';
import ERC20_ABI from '../../../blockchain/abi/ERC20.json';
import config from '../../../config/env';

class OmV3TokenContractService {
  constructor() {
    this._contract = new web3Service.eth.Contract(ERC20_ABI, config.REACT_APP_OM_V3_ERC20_ADDRESS);
  }

  get contract() {
    return this._contract;
  }

  async allowance(address, contractAddress, options = {}, defaultBlock = 'latest') {
    return this.contract.methods.allowance(address, contractAddress).call(options, defaultBlock);
  }

  async approve(address, contractAddress, amountApprove) {
    const options = {
      from: address,
    };
    return this.contract.methods.approve(contractAddress, amountApprove).send(options);
  }

  async balanceOf(address) {
    return this.contract.methods.balanceOf(address).call();
  }
}

export default new OmV3TokenContractService();
