import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../external/components/Button/Button';
import Text from '../../../external/components/Text/Text';
import { DelegatorChainIds, DelegatorChainType } from '../../../services/delegator/delegator.data';
import { NetworkId } from '../../../types/network.types';

const SearchRow = styled.div`
  background: #171616;
  padding: 1.5rem 1rem;
  border-radius: 14px;
  margin-top: 1rem;
`;

const FilterRow = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

const StyledFilterButton = styled(Button)`
  margin-bottom: 0.75rem;

  @media screen and (min-width: 576px) {
    & + & {
      margin-left: 0.5rem;
    }
  }
`;

const StyledLabel = styled(Text)`
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
`;

const AdvancedOptions = styled.div``;

interface Props {
  chainType: DelegatorChainType | 'ALL';
  setChainType: React.Dispatch<React.SetStateAction<DelegatorChainType | 'ALL'>>;
}

export function DelegatorSearch({ chainType, setChainType }: Props) {
  return (
    <SearchRow>
      <AdvancedOptions>
        <div className='col-xs-12 col-lg-5'>
          <StyledLabel dark={true}> Chain Type</StyledLabel>
          <FilterRow>
            <StyledFilterButton
              variant={chainType === 'ALL' ? 'primary' : 'secondary'}
              dark={true}
              onClick={() => setChainType('ALL')}
            >
              All
            </StyledFilterButton>
            <StyledFilterButton
              variant={chainType === 'TENDERMINT' ? 'primary' : 'secondary'}
              dark={true}
              onClick={() => setChainType('TENDERMINT')}
            >
              Tendermint
            </StyledFilterButton>

            <StyledFilterButton
              dark={true}
              variant={chainType === 'EVM' ? 'primary' : 'secondary'}
              // variant={networkId === 56 ? 'primary' : 'secondary'}
              onClick={() => setChainType('EVM')}
            >
              EVM
            </StyledFilterButton>

            <StyledFilterButton
              dark={true}
              // variant={networkId === 56 ? 'primary' : 'secondary'}
              // onClick={() => setNetworkId(56)}
              disabled
            >
              Substrate
            </StyledFilterButton>
          </FilterRow>
        </div>
      </AdvancedOptions>
    </SearchRow>
  );
}
