import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../Icon/Icon';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Font size of the button. Will also dictate the padding
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Variant of the button. Secondary to be used for secondary page actions.
   */
  variant?: 'primary' | 'secondary';
  /**
   * The dark theme version
   */
  dark?: boolean;
  /**
   * Render button component as a link or html button
   */
  as?: 'a' | 'button';
  /**
   * Optional css class for additional styling
   */
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  href?: string;
  rel?: string;
  target?: string;
  type?: 'submit' | 'reset' | 'button';
}

const sizes = {
  small: '1rem',
  medium: '1.2rem',
  large: '1.4rem',
  extraLarge: '2rem',
};

const StyledButton = styled.button<ButtonProps>`
  font-weight: 525;
  border: 0;
  cursor: pointer;
  line-height: 1;
  color: white;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.3s;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding: 0.5em 1.25em;
  border-radius: 4px;

  ${({ as }) =>
    as === 'a' &&
    css`
      display: inline-block;
    `}

  ${({ size }) =>
    size &&
    css`
      font-size: ${sizes[size]};
    `}

  &:active {
    top: 1.5px;
  }

  border: 1px solid #dde1f2;

  ${({ dark }) =>
    dark &&
    css`
      background-color: #302e2d;
      color: #fff;
      border: none;
    `};

  :focus {
    outline: none;
  }
`;

const StyledPrimary = styled(StyledButton)`
  background-color: #ae528b;
  border: solid 2px #ae528b;

  &:hover {
    text-decoration: underline;
    background-color: #924474;
    border-color: #924474;
  }

  &:disabled {
    background-color: #f5b3cc;
    border-color: #f5b3cc;

    color: #ffffff;

    ${({ dark }) =>
      dark &&
      css`
        background: #3b4866;
        color: #5f729e;
        border-color: #3b4866;
      `}

    &:hover {
      text-decoration: none;
      cursor: not-allowed;
      background-color: #f5b3cc;

      ${({ dark }) =>
        dark &&
        css`
          background: #3b4866;
        `}
    }
  }
`;

const StyledSecondary = styled(StyledButton)<Pick<ButtonProps, 'dark'>>`
  color: #ae528b;
  background-color: transparent;
  border: solid 2px #ae528b;

  &:hover {
    text-decoration: underline;
    background-color: #ae528b;
    color: #fff;
  }

  &:disabled {
    color: #e3b7d2;
    border: solid 2px #e3b7d2;
    box-shadow: none;

    &:hover {
      text-decoration: none;
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  ${({ dark }) =>
    dark &&
    css`
      color: #ae528b;
      border: solid 2px #ae528b;
      box-shadow: none;
      &:disabled {
        color: #3b4866;
        border: solid 2px #3b4866;
        box-shadow: none;
      }
    `}
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-right: 0.25em;
  }
`;

/**
 * - Can be used as buttons on forms or as links
 * - Primary buttons should be used for primary actions on a page
 */
export default function Button({
  variant = 'primary',
  size = 'small',
  as = 'button',
  href,
  dark,
  disabled = false,
  children,
  className,
  loading,
  rel,
  target,
  type = 'button',
  ...props
}: PropsWithChildren<ButtonProps>) {
  return variant === 'primary' ? (
    <StyledPrimary
      as={disabled ? 'button' : as}
      dark={dark}
      size={size}
      disabled={disabled}
      className={className}
      href={href}
      rel={rel}
      target={target}
      type={type}
      {...props}
    >
      <StyledButtonWrapper>
        {loading && <Icon type='spinner_white' width={'1em'} />}
        {children}
      </StyledButtonWrapper>
    </StyledPrimary>
  ) : (
    <StyledSecondary
      as={disabled ? 'button' : as}
      dark={dark}
      size={size}
      disabled={disabled}
      className={className}
      href={href}
      rel={rel}
      target={target}
      type={type}
      {...props}
    >
      <StyledButtonWrapper>
        {loading && <Icon type='spinner_white' width={'1em'} />}
        {children}
      </StyledButtonWrapper>
    </StyledSecondary>
  );
}
