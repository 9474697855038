import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='55'
      height='40'
      viewBox='0 0 55 50'
      fill={color}
      fillOpacity='0.15'
      className={className}
    >
      <path d='M27.5003 0C15.2381 0 5.26168 9.94108 5.26168 22.1607V34.8173C5.26168 34.9611 5.08357 35.2034 4.94365 35.2498C3.74106 35.6494 2.76439 35.9957 1.87034 36.3392C0.804152 36.7488 0 37.9262 0 39.0781V68.1593C0 69.3033 0.798956 70.4817 1.85838 70.9001C10.0554 74.1369 18.6826 75.7778 27.5002 75.7778C36.3178 75.7778 44.945 74.1365 53.1424 70.8997C54.2014 70.4815 55 69.3033 55 68.1593V39.0781C55 37.9262 54.196 36.7488 53.1293 36.3388C52.2348 35.9953 51.2583 35.649 50.0566 35.2494C49.9171 35.2031 49.739 34.9606 49.739 34.8168V22.1603C49.7383 9.94108 39.7625 0 27.5003 0ZM21.4431 47.9352C21.4431 44.5993 24.155 41.8997 27.4999 41.8997C30.8446 41.8997 33.5565 44.599 33.5565 47.9352C33.5565 50.1457 32.3056 51.9964 30.5282 53.0455V63.0241C30.5282 64.6923 29.1737 66.0419 27.4998 66.0419C25.8258 66.0419 24.4714 64.6923 24.4714 63.0241V53.0455C22.6941 51.9964 21.4431 50.1457 21.4431 47.9352ZM42.0921 22.1603V33.0806C37.307 32.0546 32.3977 31.5349 27.5023 31.5345C22.609 31.5345 17.7008 32.0543 12.9083 33.0795V22.1603C12.9083 14.1425 19.454 7.6198 27.4999 7.6198C35.5457 7.61967 42.0921 14.1425 42.0921 22.1603Z' />
    </svg>
  );
}
