import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 2000 2000'
      className={className}
      {...otherProps}
    >
      <defs>
        {/* <style>
        .cls-1{fill:url(#Nueva_muestra_de_degradado_2);}.cls-2{fill:url(#Nueva_muestra_de_degradado_2-2);}.cls-3{fill:#fff;}
      </style> */}
        <linearGradient
          id='Nueva_muestra_de_degradado_2'
          x1='540.04'
          y1='876.18'
          x2='1368.76'
          y2='876.18'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#c683e4' />
          <stop offset='1' stopColor='#00fca9' />
        </linearGradient>
        <linearGradient
          id='Nueva_muestra_de_degradado_2-2'
          x1='749.44'
          y1='1160.96'
          x2='1459.96'
          y2='1160.96'
          xlinkHref='#Nueva_muestra_de_degradado_2'
        />
      </defs>
      <path
        className='cls-1'
        d='M593.66,1168.29c.58-12.12,1.25-24.61,2.05-37.37A423.43,423.43,0,0,1,575.08,1000c0-234.3,190.62-424.92,424.92-424.92,134.58,0,254.74,62.9,332.65,160.83L1368.76,725A462.77,462.77,0,0,0,1179,576.19,460.21,460.21,0,0,0,576.19,1179q7.2,17,15.67,33.29C592.58,1193.31,593.21,1177.68,593.66,1168.29Z'
      />
      <path
        className='cls-2'
        d='M1438.89,862c-.52,1.46-1,2.92-1.59,4.37a219.23,219.23,0,0,1-22.11,43,425.12,425.12,0,0,1,9.73,90.64c0,234.3-190.62,424.92-424.92,424.92a422.24,422.24,0,0,1-206.46-53.62l-44.1,14.53A460.23,460.23,0,0,0,1438.89,862Z'
      />
      <path
        className='cls-3'
        d='M1409.09,759.16s-306.26,92.74-307.25,92.74c-.48,0-46.9-27.39-96.71-41.64C953.28,795.43,894,793.13,894,793.13,840.3,765.6,787.43,774,734.84,794.75l25.33,23.4C638.25,879.22,644,1117.27,634.43,1223.43c0,2.15,40.74-130.91,40.74-130.91l122.05-33.77s-9.34,50.09-21.81,56.19c-13.45,6.57-35.22,11.84-51.67,15.77l-78.51,243.72,27.14-9.29L743.67,1151s36.7-10.13,49.14-17.35c8.34-4.84,14.54-21.92,16.4-28.38,5.59-19.32,16.88-54.54,17.18-80.88L675,1066.52C683.47,959,725.31,857.21,784.47,836.8a660.84,660.84,0,0,1,137.4,148.27l-27.55,15.1c-9.46,5.18-16.78,11.47-19.78,26.26l-3,18.93c-1.55,12.34,3.91,25.33,10,36.19l40.07,67.64-10.94,60.25-164.57,58.48c-14.24,6-19.42,7.6-26.62,27.72l-18.7,59.8,24.65-8.43s9.76-35,12.82-43.91,15.16-13.89,25.82-17.62c68.2-23.89,85.14-29.86,85.14-29.86s-3.28,39.22-26,57.92c35.33-12.09,44.51-30.37,55.78-67.69l55.86-22.78,15.6-73.48L1056,1086a56.63,56.63,0,0,0,26-35.68l42.31-181.48,259.41-74.35s-2.56,24.65-13.7,48.56c-5.21,11.19-10.65,23.73-23.07,39-12.09,14.81-29.32,33.85-40.44,38.3l-161.16,55.14-5.46,27.25,166-56.81c.41,24.25-19.54,61.76-40.38,84.24-22.77,24.58-77.69,45.58-116.15,59-22.72,7.92-40.4,14.23-40.4,14.23l-8.57,27.17,76.24-24.74c-.15,7-6.5,23.51-9.39,30.16-10.76,24.74-23.13,40-56.13,53.08s-209.35,74.38-209.35,74.38l-10.21,28.89s159.12-52.35,233.33-79.83c53.26-19.73,73.75-62.61,81.9-117.48,87.42-45.84,121.64-82.22,127.11-163.68C1396.54,884.15,1411.28,820.37,1409.09,759.16ZM773.82,799.92c74.44-22.12,138.61,15,225.64,147.66l-53.87,25.87A788.27,788.27,0,0,0,773.82,799.92Zm258.23,270.63-92.37,54.23-37.81-60.63a26.63,26.63,0,0,1-3.61-17.81l1.64-11.19c.5-3.4,1.06-7.1,3.36-9.65,7.1-7.87,23.55-15.91,23.55-15.91l84.06-41.7,35.7,53.4a35.7,35.7,0,0,1,6.1,21,11.69,11.69,0,0,1-.25,2.47h0C1049.27,1055.65,1042.7,1063.65,1032.05,1070.55Zm32.62-69.84c-33.42-48.07-56.27-88.46-85-123.74-17-20.91-37.88-43.57-65.64-60.25,56.33,2.95,126,23.4,179,60.72Z'
      />
    </svg>
  );
}
