import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@material-ui/core';
import stylesModal from '../modal.module.scss';
import styles from './claim-rewards-modal.module.scss';
import { formatUSDTToken, formatToken } from '../../../helpers/token-helper';
import { useSelector } from 'react-redux';

const Details = ({ amountReward, onClick }) => {
  const networkId = useSelector((state) => state.account.networkId);

  let amount =
    networkId === 1 || networkId === 0 || networkId === 137
      ? formatUSDTToken(amountReward)
      : formatToken(amountReward, 18);

  let usdTokenSymbol = '';
  if (networkId === 1) {
    usdTokenSymbol = 'USDT';
  } else if (networkId === 56) {
    usdTokenSymbol = 'BUSD';
  } else if (networkId === 137) {
    usdTokenSymbol = 'USDT';
  }
  return (
    <div className={styles.body}>
      <div className={styles.title}>You are claiming</div>
      <div className={styles.value}>
        ${usdTokenSymbol} ${amount}
      </div>
      <div className={styles.description}>
        Congratulations on winning in the MANTRA POOL!
        <br />
        We hope to see you again in the next rounds..
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={cn(stylesModal.button, styles.button)}
          variant='contained'
          onClick={() => onClick()}
          color='secondary'
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

Details.propTypes = {
  amountReward: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Details;
