import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connectModal } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';

import networkConstant from '../../../constants/network.constant';

import Dialog from '@material-ui/core/Dialog';

import Form from './form';
import Details from './details';
import Success from './success';
import Error from './error';
import Confirm from './confirm';

import { getCurrentAddressAndContract, initData } from '../../../actions/mantra-pool-actions';
import * as modalActions from '../../../actions/modal-actions';
import * as tokenActions from '../../../actions/token-actions';

import closeIcon from '../../../assets/images/close-icon.svg';
import JoinPoolIcon from '../../../assets/mantra-pool/join-pool.svg';

import { BackArrow } from '../../../constants/icon-constants';
import { JOIN_POOL_MODAL } from '../../../constants/modal-constants';
import { OM_V2, OM_BSC, OM_POLYGON } from '../../../constants/blockchain-constants';

import stylesModal from '../modal.module.scss';
import styles from './join-pool-modal.module.scss';

const JoinPoolModal = ({ show }) => {
  const dispatch = useDispatch();
  const { address, networkId } = useSelector((state) => state.account);
  const { currentRound } = useSelector((state) => state.mantraPool);
  const dark = useSelector((state) => state.basic.dark);

  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState('1');
  const [error, setError] = useState(null);

  const { currentAddress, currentContract } = dispatch(getCurrentAddressAndContract());

  const sendTransaction = async () => {
    try {
      setStep(3);

      let currentToken = null;
      if (networkId === networkConstant.networkId.ethMainnet) {
        currentToken = OM_V2;
      } else if (networkId === networkConstant.networkId.bscMainnet) {
        currentToken = OM_BSC;
      } else if (networkId === networkConstant.networkId.polyMainnet) {
        currentToken = OM_POLYGON;
      }

      await tokenActions.approve(currentToken, address, currentAddress);
      await currentContract.buyEntries(address, currentRound, amount);
      await dispatch(initData());

      setStep(5);
    } catch (e) {
      setError(e.message);
      setStep(4);
    }
  };

  return (
    <Dialog
      open={show}
      style={{ background: 'transparent' }}
      className={cn(stylesModal.dialog, { [styles.dark]: dark })}
    >
      <div className={stylesModal.container}>
        <div className={stylesModal.header}>
          {step === 2 && (
            <button className={stylesModal.headerBack} onClick={() => setStep(1)}>
              {BackArrow}
            </button>
          )}
          <div className={stylesModal.headerTitle}>
            <img className={stylesModal.headerIcon} src={JoinPoolIcon} alt='' />
            <span>Join POOL</span>
          </div>
          {[1, 4, 5].includes(step) && (
            <button
              className={stylesModal.close}
              onClick={() => dispatch(modalActions.hideModal(JOIN_POOL_MODAL))}
            >
              <img src={closeIcon} alt='close' />
            </button>
          )}
        </div>
        {step === 1 && (
          <Form
            amount={amount}
            onSubmit={(value) => {
              setAmount(value);
              setStep(2);
            }}
          />
        )}
        {step === 2 && <Details amount={amount} onSubmit={() => sendTransaction()} />}
        {step === 3 && <Confirm />}
        {step === 4 && <Error text={error} />}
        {step === 5 && <Success amount={amount} />}
      </div>
    </Dialog>
  );
};

JoinPoolModal.propTypes = {
  show: PropTypes.bool,
};

JoinPoolModal.defaultProps = {
  show: false,
};

export default connectModal({ name: JOIN_POOL_MODAL })(JoinPoolModal);
