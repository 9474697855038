import _ from 'lodash';

import {
  MANTRA_POOL_UPDATE_ROUND,
  MANTRA_POOL_SET_TOTAL_ENTRIES,
  MANTRA_POOL_UPDATE_USER_PARTICIPANT,
  MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE,
  MANTRA_POOL_UPDATE_HISTORY_OFFSET,
  MANTRA_POOL_UPDATE_HISTORY_DATA,
  MANTRA_POOL_CHANGE_HISTORY_LOADING,
  MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE,
  MANTRA_POOL_SET_WITHDRAWAL_PERIOD,
  MANTRA_POOL_SET_TOTAL_PARTICIPANTS,
} from '../actions/action-types';

const DEFAULT_FIELDS = {
  currentRound: null,
  userEntries: '0',
  withdrawalPeriod: '0',
  round: {
    endsAt: null,
    totalEntries: '0',
    totalReward: '0',
    winners: [],
    participants: [],
    entriesCount: '100',
  },
  history: {
    loading: false,
    totalDataSize: 0,
    sizePerPage: 2,
    currentPage: 1,
    offset: 0,
    data: [],
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case MANTRA_POOL_UPDATE_ROUND:
      return {
        ...state,
        currentRound: action.payload.currentRound,
        round: {
          ...state.round,
          endsAt: action.payload.endsAt,
        },
      };

    case MANTRA_POOL_SET_TOTAL_ENTRIES:
      return {
        ...state,
        round: {
          ...state.round,
          totalEntries: action.payload.totalEntries,
        },
      };
    case MANTRA_POOL_SET_TOTAL_PARTICIPANTS:
      return {
        ...state,
        round: {
          ...state.round,
          participants: action.payload.participants,
        },
      };

    case MANTRA_POOL_UPDATE_USER_PARTICIPANT:
      return {
        ...state,
        userEntries: action.payload.userEntries,
      };

    case MANTRA_POOL_SET_WITHDRAWAL_PERIOD:
      return {
        ...state,
        withdrawalPeriod: action.payload.withdrawalPeriod,
      };

    case MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE:
      return {
        ...state,
        history: {
          ...state.history,
          totalDataSize: action.payload.totalDataSize,
        },
      };

    case MANTRA_POOL_UPDATE_HISTORY_OFFSET:
      return {
        ...state,
        history: {
          ...state.history,
          offset: action.payload.offset,
        },
      };

    case MANTRA_POOL_UPDATE_HISTORY_DATA:
      return {
        ...state,
        history: {
          ...state.history,
          data: action.payload.data,
        },
      };

    case MANTRA_POOL_CHANGE_HISTORY_LOADING:
      return {
        ...state,
        history: {
          ...state.history,
          loading: !!action.payload.loading,
        },
      };

    case MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE:
      return {
        ...state,
        history: {
          ...state.history,
          currentPage: action.payload.currentPage,
        },
      };

    default:
      return state;
  }
};
