import styled from 'styled-components';

import Text from '../../../external/components/Text/Text';

interface StatCalloutProps {
  label: string;
  value: string;
  dark?: boolean;
}

const StyledStatRow = styled.div`
  display: inline-flex;
  align-items: flex-end;
`;

const StyledHeadingWrapper = styled.div`
  margin-bottom: 0.1rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function StatCallout({ label, value, dark, ...props }: StatCalloutProps) {
  return (
    <StyledWrapper {...props}>
      <StyledHeadingWrapper>
        <Text as='span' dark={dark} size='xs'>
          {label}
        </Text>
      </StyledHeadingWrapper>
      <StyledStatRow>
        <Text color='pink' size='md' as='span'>
          {value}
        </Text>
        <Text color='grey' size='xs' as='span'>
          USD
        </Text>
      </StyledStatRow>
    </StyledWrapper>
  );
}
