import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, color }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='249'
      height='130'
      fill='none'
      viewBox='0 0 249 130'
      className={className}
      color={color}
    >
      <path
        fill='#DFEBFF'
        d='M230.94 88.688l-79.096-45.65 89.105 4.15 5.534 5.37 1.22 7.893-.895 5.37-2.604 6.592-2.685 5.127-2.93 3.824-7.649 7.324z'
      ></path>
      <path
        fill='#D4ECFF'
        d='M164.458 1.373c-.163-.081-.245-.162-.407-.244-3.174-1.709-7.487-1.383-12.207 1.384L43.861 64.845c.488.326.895.733 1.22 1.22l4.07-2.359L71.04 111.23l11.31 15.461 7.406 2.604 7.405-2.93 35.886-20.425 71.203-40.687 26.121-15.705 6.835-2.197 6.103-.326-78.851-45.65z'
      ></path>
      <path
        fill='#ABCCE6'
        d='M79.096 117.413c0-11.148 7.893-24.819 17.577-30.434v1.953c-8.789 5.046-15.868 17.414-15.868 27.505 0 10.09 7.16 14.24 15.868 9.195l134.267-77.55c4.801-2.766 9.196-3.092 12.369-1.22l-78.851-45.57c-.163-.081-.245-.162-.407-.244-3.174-1.709-7.487-1.383-12.207 1.384L43.861 64.846c.488.325.895.732 1.22 1.22l4.07-2.36L71.04 111.23l9.032 12.369c-.65-1.79-.976-3.825-.976-6.185z'
      ></path>
      <path
        fill='#fff'
        d='M79.096 116.356v.569c.082-5.615 2.116-11.718 5.37-17.17a1.704 1.704 0 01-.487-.325c-3.011 5.452-4.883 11.473-4.883 16.926zm151.844-69.25l-134.267 77.55c-8.545 4.963-15.543 1.139-15.868-8.463v.325c0 10.091 7.16 14.241 15.868 9.195l134.267-77.55c4.801-2.766 9.195-3.091 12.369-1.22l-3.824-2.197c-2.523-.244-5.453.57-8.545 2.36zm-159.9 62.983L49.15 62.567l-4.069 2.36c-.162-.163-.325-.407-.488-.57l-.732.407a4.36 4.36 0 011.22 1.22l4.07-2.36 21.889 47.523 9.032 12.369-.488-1.709-8.544-11.718z'
      ></path>
      <path
        fill='#CEE3F8'
        d='M17.577 41.328C7.893 46.943 0 60.532 0 71.762c0 5.37 1.872 9.277 4.801 11.148h.081c.163.082.326.245.57.326l78.77 45.488-4.8-8.707.569-8.707 1.546-4.882 2.36-5.208s1.953-3.58 2.034-3.662c.082-.082 2.848-3.58 2.848-3.58l2.686-2.767 2.848-1.872 2.441-2.36-79.177-45.65z'
      ></path>
      <path
        fill='#DFEBFF'
        d='M230.94 88.688v-1.953c8.789-5.045 15.868-17.414 15.868-27.504s-7.161-14.24-15.868-9.195L96.673 127.585c-9.684 5.615-17.577 1.058-17.577-10.172 0-11.229 7.893-24.819 17.576-30.433v1.953c-8.788 5.045-15.867 17.414-15.867 27.504s7.16 14.241 15.867 9.195l134.268-77.55c9.684-5.614 17.577-1.057 17.577 10.172-.081 11.23-7.893 24.82-17.577 30.434z'
      ></path>
      <path
        fill='#5793CE'
        d='M168.038 61.428l-37.595-21.239c-1.465-.814-4.069-.732-5.778.325L103.182 53.21c-1.708.976-1.871 2.522-.406 3.336l37.594 21.239c1.465.814 4.069.732 5.778-.326l21.483-12.694c1.709-.976 1.871-2.523.407-3.336z'
      ></path>
      <path
        fill='#B01350'
        d='M139.394 73.227L100.741 51.42c-.732-.407-1.14-.977-1.14-1.628l-.08-4.313s1.383 1.14 2.197 1.79l24.086-14.24c1.953-1.139 4.964-1.302 6.673-.325l36.944 20.831 2.848-1.708.081 4.313c0 .732-.569 1.546-1.627 2.115L146.148 72.82c-2.034 1.22-4.964 1.302-6.754.407z'
      ></path>
      <path
        fill='#ED1B6C'
        d='M171.048 50.035l-38.653-21.808c-1.709-.976-4.72-.814-6.673.326L101.147 43.2c-1.953 1.14-2.115 2.848-.406 3.825l38.652 21.808c1.709.976 4.72.814 6.673-.326l24.575-14.566c1.953-1.139 2.116-2.93.407-3.906z'
      ></path>
      <path
        fill='#D0E8FF'
        d='M117.261 88.444l-31.492-18.39-2.035 1.138 31.492 18.391 2.035-1.14zm-.895-10.497L94.395 65.17l-2.035 1.058 22.053 12.857 1.953-1.14zm-7.324.733l-18.96-11.068-2.035 1.14 19.042 11.066 1.953-1.139zm12.531 7.322l-9.52-5.614-1.953 1.139 9.439 5.615 2.034-1.14zm69.168-40.849l-31.492-18.39-1.953 1.138 31.492 18.391 1.953-1.14zm-.813-10.497L167.875 21.88l-1.953 1.14 21.971 12.775 2.035-1.14zm-7.324.732l-19.042-11.067-1.953 1.14 19.042 11.066 1.953-1.139zm12.531 7.324l-9.521-5.534-2.034 1.14 9.521 5.533 2.034-1.14z'
      ></path>
      <path stroke='#fff' d='M154.327 49.23l-25.997.553.553-7.744'></path>
    </svg>
  );
}
