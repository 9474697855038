import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import OmIcon from '../../../../assets/images/tokens/token-type2/om.svg';
import EnableRow from './enable-row';
import SupplyRow from './supply-row';
import * as lendingActions from '../../../../actions/lending-actions';
import * as lendingSupplyActions from '../../../../actions/lending-supply-actions';
import * as modalActions from '../../../../actions/modal-actions';
import { SUPPLY_MODAL } from '../../../../constants/modal-constants';

const Supply = ({
  setLoading,
  setError,
  setDisabledSupply,
  token,
  inputValue,
  setIsTokenEnabled,
  isEnabled,
  disabled,
  tokenIcon,
  supplyAPY,
  distributionAPY,
  borrowLimitUsd,
  borrowLimitPercent,
  newBorrowLimitUsd,
  newBorrowLimitPercent,
  isPaused,
}) => {
  const dispatch = useDispatch();
  const { zenTokenAddress } = token;
  const tokenColumn = [
    { tokenImage: tokenIcon, apyName: 'Supply APY', apyValue: isPaused ? '-' : supplyAPY },
    { tokenImage: OmIcon, apyName: 'Distribution APY', apyValue: isPaused ? '-' : distributionAPY },
  ];

  const borrowColumn = [
    { apyName: 'Borrow Limit', usdValue: borrowLimitUsd, newUsdValue: newBorrowLimitUsd },
    {
      apyName: 'Borrow Limit Used',
      percentValue: borrowLimitPercent,
      newPercentValue: newBorrowLimitPercent,
    },
  ];

  const onSupplyHandler = async () => {
    try {
      setLoading(true);
      setError('');
      setDisabledSupply(true);
      await dispatch(lendingSupplyActions.supply(zenTokenAddress, inputValue));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setDisabledSupply(false);
      dispatch(modalActions.hideModal(SUPPLY_MODAL));
    }
  };

  const onEnableHandler = async () => {
    try {
      setLoading(true);
      setError('');
      const success = await dispatch(lendingActions.enableToken(zenTokenAddress));
      if (success) {
        setIsTokenEnabled(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setDisabledSupply(true);
      setLoading(false);
    }
  };

  return (
    <>
      {isEnabled ? (
        <SupplyRow
          borrowColumn={borrowColumn}
          tokenColumn={tokenColumn}
          disabled={disabled}
          onClick={onSupplyHandler}
        />
      ) : (
        <EnableRow tokenColumn={tokenColumn} disabled={disabled} onClick={onEnableHandler} />
      )}
    </>
  );
};

Supply.propTypes = {
  isEnabled: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setDisabledSupply: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  setIsTokenEnabled: PropTypes.func.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  tokenIcon: PropTypes.string,
  supplyAPY: PropTypes.string,
  distributionAPY: PropTypes.string,
  borrowLimitUsd: PropTypes.string,
  borrowLimitPercent: PropTypes.string,
  newBorrowLimitUsd: PropTypes.string,
  newBorrowLimitPercent: PropTypes.string,
  isPaused: PropTypes.bool,
};

Supply.defaultProps = {
  isEnabled: false,
  disabled: false,
  tokenIcon: null,
  inputValue: '',
  supplyAPY: '0',
  distributionAPY: '0',
  borrowLimitUsd: '0',
  borrowLimitPercent: '0',
  newBorrowLimitUsd: '0',
  newBorrowLimitPercent: '0',
  isPaused: false,
};

export default React.memo(Supply);
