import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31 35'
      className={className}
      {...otherProps}
    >
      <path
        fill='#F1EEEE'
        d='M30.142 17.051V9.387c0-.015-.005-.029-.006-.042a1.613 1.613 0 00-.05-.381l-.005-.01a1.66 1.66 0 00-.141-.341c-.017-.032-.037-.06-.056-.091a1.675 1.675 0 00-.175-.228c-.024-.026-.047-.053-.073-.077a1.555 1.555 0 00-.31-.239L15.886.218a1.627 1.627 0 00-1.63 0L.816 7.976A1.631 1.631 0 000 9.387v19.305c0 .581.311 1.12.815 1.41l6.638 3.832c.02.01.041.016.06.025.086.045.177.083.273.112.034.01.068.024.103.032.117.027.237.045.361.047l.015.001h.003c.13 0 .257-.02.378-.048.035-.008.067-.021.1-.03a1.717 1.717 0 00.63-.362c.013-.01.027-.018.037-.03.093-.091.17-.196.238-.308.008-.013.019-.022.026-.034.015-.027.025-.056.038-.083.026-.053.052-.105.073-.161a1.905 1.905 0 00.082-.304c.01-.06.015-.117.018-.176.002-.03.01-.06.01-.091V14.159l11.811-6.82 3.546 2.047-10.999 6.35a1.63 1.63 0 00-.815 1.411v7.666c0 .015.005.029.006.042a1.609 1.609 0 00.055.392c.033.118.082.23.141.34.017.032.036.06.056.091.052.08.11.156.175.228.024.026.045.053.073.077.092.09.192.172.31.239l13.44 7.76a1.626 1.626 0 001.631 0 1.63 1.63 0 00.815-1.411v-7.502c0-.68-.366-1.314-.956-1.656l-4.218-2.435 4.359-2.518c.503-.288.814-.827.814-1.409zM6.638 29.703l-3.38-1.951v-9.76l3.38-1.95v13.661zm-3.38-15.474v-3.902l11.812-6.82 3.38 1.951L3.258 14.23zm23.624 11.62v3.901l-8.554-4.939 3.38-1.95 5.174 2.987zm0-9.737L16.7 21.99v-3.902l10.183-5.878v3.902z'
      ></path>
    </svg>
  );
}
