import styled from 'styled-components';

import Text from '../../../../external/components/Text/Text';
import Icon, { ChainType } from '../../../../external/components/Icon/Icon';

interface Props {
  chain: ChainType;
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 90%;
    border: solid 1px #4f5460;
  }
`;

export function SideBarHeader({ chain }: Props) {
  return (
    <HeaderWrapper>
      <Icon.Chain type={chain} width={50} />
      <Text dark={true}>{chain} - Delegation</Text>
    </HeaderWrapper>
  );
}
