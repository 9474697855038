import BN from 'bignumber.js';

import {
  SET_STAKE_ACTIVE_ASSET,
  SET_STAKE_ACTIVE_TAB,
  SET_STAKE_TOTAL_STAKE_BALANCE_USD,
} from './action-types';
export const setActiveAsset = (asset) => async (dispatch) => {
  dispatch({ type: SET_STAKE_ACTIVE_ASSET, payload: asset });
};

export const setActiveTab = (tab) => async (dispatch) => {
  dispatch({ type: SET_STAKE_ACTIVE_TAB, payload: tab });
};

export const getTotalStakedBalance = () => (dispatch, getStore) => {
  const {
    ZENUSDTOMETHstake: {
      stake: { stakeBalance: zenUsdtOmEthStakeBalance },
    },
    ZENUSDCUNIETHstake: {
      stake: { stakeBalance: zenUsdcUniEthStakeBalance },
    },
    ZENDAIUNIstake: {
      stake: { stakeBalance: uniZenDaiStakeBalance },
    },
    ZENUSTUNIstake: {
      stake: { stakeBalance: uniZenUstStakeBalance },
    },
    basic: {
      currency: {},
    },
    token: { zenUsdtOmEthBalance, zenUsdcUniEthBalance, zenDaiUniPrice, zenUstUniPrice },
  } = getStore();

  let totalUsdValue = 0;

  totalUsdValue += uniZenDaiStakeBalance * zenDaiUniPrice;

  totalUsdValue += uniZenUstStakeBalance * zenUstUniPrice;

  totalUsdValue += zenUsdtOmEthStakeBalance * zenUsdtOmEthBalance;

  totalUsdValue += zenUsdcUniEthStakeBalance * zenUsdcUniEthBalance;

  const totalStakedBalance = new BN(totalUsdValue).toFixed(0, BN.ROUND_DOWN);

  dispatch({ type: SET_STAKE_TOTAL_STAKE_BALANCE_USD, payload: totalStakedBalance });

  return totalStakedBalance;
};
