import * as React from 'react';

function SvgBandIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.5} cy={25.5} r={24.25} fill='#fff' stroke='#ED1B6C' strokeWidth={1.5} />
      <path
        d='M25.5 49C38.479 49 49 38.479 49 25.5S38.479 2 25.5 2 2 12.521 2 25.5 12.521 49 25.5 49z'
        fill='#516AFF'
      />
      <path
        d='M29.613 19.038l4.113 2.35V9.638L25.5 5.525l-12.778 7.197v25.556L25.5 45.623l12.631-7.49V25.5l-12.19-7.343-4.113 2.056 12.19 7.197.147 8.665-8.665 4.847-8.813-4.993v-21.15l8.813-4.847 4.112 2.203v6.903z'
        fill='#fff'
      />
      <path d='M25.06 26.087l2.79-1.468 3.084 1.91-9.106 5.14V21.094l3.231 1.91' fill='#fff' />
    </svg>
  );
}

export default SvgBandIcon;
