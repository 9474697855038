import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import EN from '../../assets/images/EN.png';
import googlePlayBadgeIcon from '../../assets/images/google-play-badge.png';
import appStoreBadgeIcon from '../../assets/images/app-store-badge.svg';
import appStoreBadgeBlackIcon from '../../assets/images/app-store-badge-black.svg';
import theme from '../../theme';
import styles from './footer.module.scss';
import {
  MANTRA_DAO_ANDROID_APP_LINK,
  MANTRA_DAO_IOS_APP_LINK,
} from '../../constants/common-constants';
import { DELEGATOR_PATH, INDEX_PATH, STAKING_PATH } from '../../constants/router-constants';

const Footer = ({ colors, isDark, handleDarkLightChange }) => {
  const pagePath = window.location.pathname;

  const langBtnStyles = {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    border: 0,
    display: 'flex',
    alignItems: 'center',
  };

  const langBgStyles = {
    width: '30px',
    height: '30px',
    borderRadius: '8px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mantra.pink,
    alignItems: 'center',
  };

  const langHintStyles = {
    fontSize: 14,
    marginLeft: '10px',
    color: 'white',
  };

  const switchWrap = {
    display: 'flex',
    alignItem: 'center',
    marginLeft: '60px',
    alignItems: 'center',
  };

  const switchHint = {
    fontSize: 14,
    color: 'white',
    whiteSpace: 'nowrap',
  };

  const StyledSwitch = withStyles({
    switchBase: {
      'color': '#fafafa',
      '&$checked': {
        color: '#ae528b',
      },
      '&$checked + $track': {
        backgroundColor: '#ae528b',
      },
    },
    checked: {},
    track: {
      backgroundColor: '#7F89A7',
      opacity: 1,
    },
  })(Switch);
  const pathIsHome = pagePath === INDEX_PATH;
  const pathIsStaking = pagePath === STAKING_PATH;
  const pagePathDelegator = pagePath === DELEGATOR_PATH;

  return (
    <div className={styles.wrapper} style={{ backgroundColor: colors.banner }}>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <button style={langBtnStyles}>
            <div style={langBgStyles}>
              <img height='11px' src={EN} alt='' />
            </div>
            <span style={langHintStyles}>English</span>
          </button>

          <div
            style={switchWrap}
            className={pathIsHome || pathIsStaking || pagePathDelegator ? styles.tooltip : ''}
          >
            {pathIsHome || pathIsStaking || pagePathDelegator ? (
              <span className={styles.tooltiptext}>Light mode not available on this page</span>
            ) : null}

            <StyledSwitch
              checked={pathIsHome || pathIsStaking || pagePathDelegator ? true : isDark}
              onChange={
                pathIsHome || pathIsStaking || pagePathDelegator ? () => {} : handleDarkLightChange
              }
            />
            <div style={switchHint}>Dark Mode</div>
          </div>
        </div>
        <div className={styles.downloadLinks}>
          <a
            className={styles.downloadButton}
            href={MANTRA_DAO_IOS_APP_LINK}
            target='_blank'
            rel='noreferrer'
            color='secondary'
            variant='contained'
          >
            <img src={isDark ? appStoreBadgeBlackIcon : appStoreBadgeIcon} alt='Download IOS app' />
          </a>
          <a
            className={styles.downloadButton}
            href={MANTRA_DAO_ANDROID_APP_LINK}
            target='_blank'
            rel='noreferrer'
            color='secondary'
            variant='contained'
          >
            <img src={googlePlayBadgeIcon} alt='Download ANDROID app' />
          </a>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  colors: PropTypes.object.isRequired,
  isDark: PropTypes.bool.isRequired,
  handleDarkLightChange: PropTypes.func.isRequired,
};

Footer.defaultProps = {};

export default React.memo(Footer);
