import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 50 50' {...otherProps}>
      <path
        fill='#5A5564'
        d='M40.77 10.493l-1.373 1.373a19.435 19.435 0 010 26.231l1.372 1.373a21.399 21.399 0 000-29.009v.032zM11.898 10.612a19.435 19.435 0 0126.231 0l1.373-1.373a21.398 21.398 0 00-29.009 0l1.405 1.373zM10.613 38.11a19.435 19.435 0 010-26.216L9.24 10.52a21.398 21.398 0 000 29.008l1.373-1.418zM38.11 39.379a19.435 19.435 0 01-26.23 0l-1.373 1.372a21.398 21.398 0 0029.009 0L38.11 39.38z'
      ></path>
      <path
        fill='#F09242'
        d='M33.667 20.42c-.274-2.866-2.745-3.827-5.871-4.12v-3.944H25.38v3.871h-1.931v-3.871H21.05v3.972h-4.902v2.586s1.785-.032 1.758 0a1.25 1.25 0 011.373 1.061v10.873a.847.847 0 01-.293.59.838.838 0 01-.623.211c.032.028-1.757 0-1.757 0l-.458 2.888h4.856v4.036h2.416v-3.977h1.931v3.959h2.421v-3.99c4.082-.248 6.929-1.255 7.285-5.076.289-3.075-1.157-4.448-3.468-5.002 1.405-.69 2.274-1.972 2.077-4.068zm-3.386 8.593c0 3.002-5.144 2.66-6.782 2.66v-5.328c1.638.005 6.782-.466 6.782 2.668zm-1.121-7.505c0 2.746-4.293 2.412-5.657 2.412v-4.842c1.364 0 5.657-.43 5.657 2.43z'
      ></path>
      <path
        fill='#282138'
        d='M24.995 50a25 25 0 11.01-50 25 25 0 01-.01 50zm0-48.05a23.037 23.037 0 10.037 46.073 23.037 23.037 0 00-.037-46.074z'
      ></path>
    </svg>
  );
}
