import BN from 'bignumber.js';
import keccak256 from 'keccak256';

const hashValue = (value) => {
  console.log(value);
  const encodedValueBuffer = Buffer.from(value, 'hex');
  return `0x${keccak256(encodedValueBuffer).toString('hex')}`;
};

const createLeaf = (hash1, hash2) => {
  const bigIntH1 = new BN(hash1.startsWith('0x') ? hash1 : `0x${hash1}`);
  const bigIntH2 = new BN(hash2.startsWith('0x') ? hash2 : `0x${hash2}`);
  const encodedValueBuffer = bigIntH1.lte(bigIntH2)
    ? Buffer.from(`${hash1.replace('0x', '')}${hash2.replace('0x', '')}`, 'hex')
    : Buffer.from(`${hash2.replace('0x', '')}${hash1.replace('0x', '')}`, 'hex');
  return keccak256(encodedValueBuffer, 'hex').toString('hex');
};

const createMerkleTreeRootHash = (hashes, proof, startPosition) => {
  if (hashes.length > 1) {
    const newHashes = [];
    const relatedHashPosition = startPosition % 2 === 0 ? startPosition + 1 : startPosition - 1;
    // This check needs for case when our relatedHashPosition is odd and last in hashes array(3 pos in array of 3 hashes, for example).
    // So, we cant push smth in this case. When we construct a tree, we just "stretch" the value to the next level in such cases
    // But now we need to push hash of hashes[0] + hasehes[1] (if using the example above), it will be created and pushed on the next iteration anyway
    if (relatedHashPosition <= hashes.length - 1) {
      proof.push(hashes[relatedHashPosition]);
    }
    for (let i = 0; i < hashes.length; i += 2) {
      let hash;
      if (hashes[i + 1]) {
        hash = createLeaf(hashes[i], hashes[i + 1]);
      } else {
        hash = hashes[i];
      }
      newHashes.push(hash);
    }
    return createMerkleTreeRootHash(newHashes, proof, Math.floor(startPosition / 2));
  }
  return proof.map((el) => (el.startsWith('0x') ? el : `0x${el}`));
};

export const getMerkleProofFromJson = (json, ethAddress) => {
  let startPos;
  const normalizedStats = Object.keys(json).map((address, i) => {
    if (address.toLowerCase() === ethAddress.toLowerCase()) {
      startPos = i;
    }
    const addressData = json[address];
    return (
      `${address.toLowerCase().replace('0x', '')}` +
      `${new BN(addressData.amount).toString(16).padStart(64, '0')}`
    );
  });
  const firstLevelHashes = normalizedStats.map(hashValue);
  const merkleProof = createMerkleTreeRootHash(firstLevelHashes, [], startPos);
  return merkleProof;
};
