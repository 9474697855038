import React from 'react';
import infoIcon from '../../assets/images/info.svg';

const iconInfo = () => (
  <span
    style={{
      cursor: 'pointer',
      marginLeft: '8px',
      borderRadius: '8px',
      height: '16px',
      width: '16px',
      display: 'inline-flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      justifyContent: 'center',
      // backgroundColor: bg,
    }}
  >
    <img src={infoIcon} alt='' />
  </span>
);

export default iconInfo;
