import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DelegatorActions } from '../../../../actions/delegator-actions';
import { Highlight } from '../../../../external/components/Highlight/Highlight';
import { State } from '../../../../reducers';
import {
  DelegatorChainIds,
  DelegatorDataType,
} from '../../../../services/delegator/delegator.data';
import { timeHelpers } from '../../../staking/helpers/timeHelpers';
import { useDelegatorClaim } from '../../hooks/claim.hook';
import { ViewDelegatorScreensEnum } from './Body';
import Text from '../../../../external/components/Text/Text';
import Button from '../../../../external/components/Button/Button';
import { ButtonContainer } from '../../../staking/components/ButtonContainer';
import { ProviderRpcError } from '../../../../types/metamask.types';
import time from '../../helpers/delegator.helpers';
import { ErrorAlert } from '../../../../external/components/ErrorAlert/ErrorAlert';
import { getNetworkText } from '../../../staking/helpers/getNetworkText';
import {
  StyledDd,
  StyledDefWrapper,
  StyledDl,
  StyledDt,
} from '../../../../external/components/DefLists';
import { HorizontalRule } from '../../../staking/components/HorizontalRule';
import BigNumber from 'bignumber.js';
import { DelegationSummary } from './Summary';
import networkConstant from '../../../../constants/network.constant';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<ViewDelegatorScreensEnum>>;
}
export function DelegatorRewardSummary({ setScreen }: Props) {
  const dispatch = useDispatch();

  const { delegatorData, walletNetworkId } = useSelector(({ delegators, account }: State) => ({
    delegatorData: (delegators as DelegatorDataType).delegatorData.filter(
      ({ chainId }) => chainId === DelegatorChainIds.ATOM,
    )[0],
    walletNetworkId: account.networkId,
  }));

  const { unclaimedRewards: rewardBalance, rewardArray, metadata, networkId } = delegatorData;

  const { setRewardType, setWithdrawAmount } = bindActionCreators(DelegatorActions, dispatch);

  const [rewardsLoading, setRewardsLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawIndex, setWithdrawIndex] = useState('');
  const {
    hasBscRewards,
    fetchRewards,
    getMetadata,
    getRequestedRewardArray,
    sendReceiveReward,
    checkHasBscRewards,
  } = useDelegatorClaim({
    chainId: DelegatorChainIds.ATOM,
    walletNetworkId,
    networkId,
  });

  const { longClaimPeriod, shortClaimPeriod, shortRewardPercentage } = metadata;

  const invalidNetwork = networkId !== walletNetworkId;

  const filteredRewardArray = rewardArray?.filter(({ amount, isWithdrowedByNominator }) => {
    if (amount === '0' || isWithdrowedByNominator) return false;
    else return true;
  });

  const init = async () => {
    try {
      setRewardsLoading(true);
      if (
        delegatorData.chainId === DelegatorChainIds.ATOM &&
        walletNetworkId !== networkConstant.networkId.bscMainnet
      ) {
        await checkHasBscRewards();
      }
      if (!rewardBalance) {
        await Promise.all([fetchRewards(), getMetadata(), getRequestedRewardArray()]);
      }
      setRewardsLoading(false);
    } catch (error) {
      setRewardsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleWithdraw = async (i: string, isFullWithdrawal: boolean, amount: string) => {
    setWithdrawIndex(i);
    try {
      const shiftedAmount = new BigNumber(amount)
        .multipliedBy(isFullWithdrawal ? 1 : parseFloat(shortRewardPercentage || '0'))
        .toFixed();

      setWithdrawLoading(true);
      await sendReceiveReward(i);
      setWithdrawAmount(shiftedAmount);
      setWithdrawLoading(false);
      setScreen(ViewDelegatorScreensEnum.WITHDRAW_SUCCESS);
    } catch (_error) {
      const error = _error as ProviderRpcError;

      if (error.code === 4001) {
        // do nothing if RPC error
        setWithdrawLoading(false);
        return;
      } else {
        setWithdrawLoading(false);
        setScreen(ViewDelegatorScreensEnum.ERROR);
      }
    }
  };

  return (
    <>
      {invalidNetwork && (
        <ErrorAlert>
          <Text dark={true} style={{ margin: '0.5rem 0' }}>
            {' '}
            Please connect to the {getNetworkText(networkId)} network, to start claiming and
            withdrawing
          </Text>
        </ErrorAlert>
      )}
      {hasBscRewards && walletNetworkId !== networkConstant.networkId.bscMainnet && (
        <Text dark={true} style={{ margin: '0.75rem 0 -0.5rem 0' }}>
          <strong>IMPORTANT: </strong>Please change to BSC network to claim your previous Cosmos
          rewards
        </Text>
      )}
      <StyledDefWrapper>
        <StyledDl>
          <StyledDd>
            <Text style={{ margin: '0.25rem 0' }} dark={true}>
              Unclaimed OM rewards
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: '0.1rem 0 0.75rem' }} color='lightgrey'>
              {rewardsLoading ? 'loading...' : `${rewardBalance} OM`}
            </Text>
          </StyledDt>
          {!rewardsLoading && rewardBalance !== '0' && (
            <ButtonContainer style={{ margin: '0.5rem 0 1.5rem' }}>
              <Button
                variant='secondary'
                dark={true}
                disabled={withdrawLoading || invalidNetwork}
                onClick={() => {
                  setRewardType({ selectedRewardType: 'SHORT' });
                  setScreen(ViewDelegatorScreensEnum.CLAIM_SCREEN);
                }}
              >
                Short claim
              </Button>
              <Button
                variant='secondary'
                dark={true}
                disabled={withdrawLoading || invalidNetwork}
                onClick={() => {
                  setRewardType({ selectedRewardType: 'LONG' });
                  setScreen(ViewDelegatorScreensEnum.CLAIM_SCREEN);
                }}
              >
                {' '}
                Long claim
              </Button>
            </ButtonContainer>
          )}
          <HorizontalRule />
          <StyledDd>
            <Text style={{ margin: '0.25rem 0' }} dark={true}>
              Next reward drop
            </Text>
          </StyledDd>
          <StyledDt>
            <Text style={{ margin: '0.1rem 0 0.75rem' }} color='lightgrey'>
              {'PAUSED'}
            </Text>
          </StyledDt>
        </StyledDl>
      </StyledDefWrapper>

      {!rewardsLoading ? (
        <>
          {filteredRewardArray && filteredRewardArray.length > 0 ? (
            <>
              {filteredRewardArray.map(
                ({ amount, timestamp, isFullWithdrawal, isWithdrowedByNominator, index }) => (
                  <Highlight darkAlt={true} key={index} style={{ marginTop: '1rem' }}>
                    <div
                      className='flex align-center justify-space-between'
                      style={{ padding: '0 1rem' }}
                    >
                      <div>
                        <Text color='lightgrey'>
                          {isFullWithdrawal ? 'Long claim' : 'ShortClaim'}:{' '}
                          {isFullWithdrawal ? amount : parseFloat(amount) * 0.2} OM
                        </Text>
                        <Text color='lightgrey'>
                          {timeHelpers.checkWhenAvailable(
                            isFullWithdrawal ? longClaimPeriod || '0' : shortClaimPeriod || '0',
                            parseInt(timestamp),
                          )}
                        </Text>
                      </div>
                      <Button
                        dark={true}
                        variant='secondary'
                        onClick={() => handleWithdraw(index, isFullWithdrawal, amount)}
                        disabled={
                          !timeHelpers.isAvailable(
                            isFullWithdrawal ? longClaimPeriod || '0' : shortClaimPeriod || '0',
                            parseInt(timestamp),
                          ) || withdrawLoading
                        }
                        loading={withdrawLoading && withdrawIndex === index}
                      >
                        Withdraw
                      </Button>
                    </div>
                  </Highlight>
                ),
              )}
            </>
          ) : (
            <Text color='lightgrey'>**No unclaimed rewards**</Text>
          )}
        </>
      ) : null}
      <DelegationSummary />
    </>
  );
}
