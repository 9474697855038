import React from 'react';

import { IconProps } from '../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 250 226' {...otherProps}>
      <g clipPath='url(#clip0_locked)'>
        <path
          fill='#E0E0E0'
          d='M249.339 168.957c0 .055-53.754.166-120.074.166S9.19 169.067 9.19 168.957c0-.056 53.754-.166 120.075-.166 66.32 0 120.074.11 120.074.166z'
        ></path>
        <path
          fill='#EBEBEB'
          d='M211.861 126.939c.166-.166 1.439.719 3.045 2.546.83.886 1.716 2.048 2.546 3.432.886 1.384 1.716 2.935 2.491 4.651a34.902 34.902 0 011.661 4.982 27.9 27.9 0 01.72 4.207c.221 2.436 0 3.931-.222 3.931-.608.055-1.107-5.813-3.985-12.345-2.768-6.588-6.699-11.017-6.256-11.404zM224.04 155.504c-.277.056-.941-3.155-1.606-8.47-.664-5.259-1.218-12.566-1.384-20.649-.221-8.082-.11-15.39.056-20.704.166-5.315.387-8.58.664-8.58s.498 3.265.72 8.58c.166 5.259.332 12.566.553 20.649.222 8.027.554 15.334.831 20.594.221 5.314.442 8.58.166 8.58z'
        ></path>
        <path
          fill='#EBEBEB'
          d='M235.831 109.612c.276.166-.775 2.38-2.104 6.144-1.384 3.765-3.045 8.969-4.706 14.837-1.605 5.868-2.878 11.238-3.653 15.113-.775 3.875-1.052 6.366-1.384 6.366-.222 0-.443-2.546 0-6.532.443-4.042 1.494-9.522 3.155-15.446 1.661-5.923 3.543-11.182 5.259-14.836 1.717-3.709 3.211-5.813 3.433-5.646z'
        ></path>
        <path
          fill='#E0E0E0'
          d='M208.871 148.805v6.422c0 7.584 6.145 13.729 13.729 13.729s13.729-6.145 13.729-13.729v-6.422h-27.458z'
        ></path>
        <path
          fill='#FAFAFA'
          d='M236.551 154.231c0 .111-6.201.222-13.84.222-7.64 0-13.84-.111-13.84-.222s6.2-.221 13.84-.221c7.639.055 13.84.11 13.84.221zM236.551 156.002c0 .111-6.201.222-13.84.222-7.64 0-13.84-.111-13.84-.222s6.2-.221 13.84-.221c7.639.055 13.84.11 13.84.221z'
        ></path>
        <path fill='#E0E0E0' d='M235.057 0h-52.481v54.75h52.481V0z'></path>
        <path fill='#EBEBEB' d='M231.514 0h-52.481v54.75h52.481V0z'></path>
        <path fill='#FAFAFA' d='M224.427 7.363h-38.364v40.025h38.364V7.363z'></path>
        <path
          fill='#E0E0E0'
          d='M186.063 47.664c0 .056-.332.443-.941 1.108-.609.664-1.495 1.494-2.436 2.49-.941.942-1.827 1.828-2.491 2.381-.664.61-1.052.941-1.107.941-.056-.055.332-.443.941-1.107.609-.664 1.495-1.495 2.436-2.491.941-.941 1.882-1.827 2.491-2.436.664-.609 1.107-.941 1.107-.886zM224.483 47.554c.055 0 .442.332 1.107.94.664.61 1.494 1.495 2.491 2.437.941.94 1.827 1.827 2.38 2.49.609.665.941 1.053.941 1.108-.055.055-.442-.332-1.107-.941-.664-.61-1.494-1.495-2.491-2.436-.941-.941-1.827-1.882-2.436-2.491-.553-.664-.941-1.107-.885-1.107zM224.372 7.474c0-.056.332-.443.941-1.107.609-.665 1.495-1.495 2.436-2.492.941-.94 1.827-1.826 2.491-2.38.665-.609 1.052-.941 1.107-.941.056.055-.332.443-.941 1.107-.609.664-1.494 1.495-2.435 2.491-.942.941-1.883 1.827-2.492 2.436-.664.554-1.107.886-1.107.886zM186.008 7.584c-.056 0-.443-.332-1.108-.94-.664-.61-1.494-1.495-2.491-2.437-.941-.94-1.827-1.826-2.38-2.49-.609-.665-.941-1.053-.941-1.108.055-.055.443.332 1.107.941.664.61 1.495 1.495 2.491 2.436.941.941 1.827 1.882 2.436 2.491.553.664.886 1.052.886 1.107z'
        ></path>
        <path
          fill='#E0E0E0'
          d='M224.372 47.387v-.166-.553-2.16c0-1.882 0-4.65-.056-8.137 0-7.03-.055-16.995-.055-28.953l.111.11h-38.254l.166-.165v40.024l-.11-.11c11.459 0 21.036.055 27.735.055 3.321 0 5.978 0 7.805.055h2.492-2.547c-1.772 0-4.429 0-7.75.056-6.699 0-16.276.055-27.791.055h-.11v-.11V7.196H224.538v.11c0 12.013-.056 22.033-.056 29.009 0 3.487 0 6.255-.055 8.137v2.713c-.055.166-.055.221-.055.221z'
        ></path>
        <path
          fill='#EBEBEB'
          d='M196.193 24.081s-.83 9.91 9.909 9.91c0-5.315-4.318-9.467-9.909-9.91z'
        ></path>
        <path
          fill='#EBEBEB'
          d='M215.846 24.081s-9.91-.83-9.91 9.91c5.315 0 9.467-4.374 9.91-9.91z'
        ></path>
        <path
          fill='#E0E0E0'
          d='M205.66 18.656s-8.083 7.252.443 15.335c3.986-4.207 3.598-10.795-.443-15.335zM74.182 17.991h-44.51v46.392h44.51V17.992z'
        ></path>
        <path fill='#FAFAFA' d='M71.193 17.991H26.684v46.392h44.509V17.992z'></path>
        <path
          fill='#E0E0E0'
          d='M71.192 64.383H26.628V17.992h44.564v46.391zm-44.509 0h44.509V17.992H26.683v46.391z'
        ></path>
        <path fill='#FAFAFA' d='M65.213 24.247h-32.55v33.935h32.55V24.247z'></path>
        <path
          fill='#E0E0E0'
          d='M32.662 58.404c0 .055-.277.387-.83.94-.498.554-1.273 1.274-2.049 2.105-.83.83-1.55 1.55-2.103 2.048-.554.498-.886.83-.941.775-.056-.055.277-.388.775-.941.498-.554 1.273-1.273 2.048-2.104.83-.83 1.55-1.55 2.104-2.048.609-.554.94-.83.996-.775zM65.214 58.294c.055 0 .387.276.94.83.554.498 1.274 1.273 2.105 2.048.83.83 1.55 1.55 2.048 2.104.498.553.83.886.775.94-.055.056-.388-.276-.941-.774-.554-.498-1.273-1.273-2.104-2.048-.83-.83-1.55-1.55-2.048-2.104-.554-.609-.83-.996-.775-.996zM65.103 24.303c0-.056.276-.388.83-.942.498-.553 1.273-1.273 2.048-2.103.83-.83 1.55-1.55 2.104-2.048.553-.499.886-.83.941-.775.055.055-.277.387-.775.94-.498.554-1.273 1.274-2.048 2.104-.83.83-1.55 1.55-2.104 2.049-.609.498-.996.83-.996.775zM32.551 24.414c-.055 0-.387-.277-.94-.83-.555-.499-1.274-1.274-2.105-2.05-.83-.83-1.55-1.55-2.048-2.103-.498-.553-.83-.886-.775-.94.055-.056.388.276.941.774.554.498 1.274 1.273 2.104 2.049.83.83 1.55 1.55 2.048 2.103.498.61.83.941.775.997z'
        ></path>
        <path
          fill='#E0E0E0'
          d='M65.102 58.127v-.166-.498-1.827c0-1.606 0-3.93-.055-6.92 0-5.924-.056-14.394-.056-24.524l.111.11h-32.44l.166-.166v33.936l-.111-.111c9.743 0 17.825.055 23.528.055 2.823 0 5.093 0 6.587.056h2.215c0 .055.055.055.055.055h-2.325c-1.55 0-3.764 0-6.588.055-5.702 0-13.84.056-23.583.056h-.11v-.111-34.101h32.716v.11c0 10.186-.055 18.656-.055 24.635 0 2.934 0 5.26-.055 6.865v2.491z'
        ></path>
        <path fill='#EBEBEB' d='M54.363 35.762h-10.85v10.85h10.85v-10.85z'></path>
        <path
          fill='#E22D73'
          d='M164.971 82.209c-.055-1.384-.83-2.713-2.048-3.267-1.052-.498-2.38-.332-3.432.277-1.052.61-1.772 1.55-2.381 2.602-1.494 2.713-1.66 5.924-1.992 9.024-.333 3.1-1.052 5.702-2.713 8.746l11.349-13.507c.775-1.163 1.328-2.547 1.217-3.876z'
        ></path>
        <path
          fill='#000'
          d='M164.971 82.209c-.055-1.384-.83-2.713-2.048-3.267-1.052-.498-2.38-.332-3.432.277-1.052.61-1.772 1.55-2.381 2.602-1.494 2.713-1.66 5.924-1.992 9.024-.333 3.1-1.052 5.702-2.713 8.746l11.349-13.507c.775-1.163 1.328-2.547 1.217-3.876z'
          opacity='0.2'
        ></path>
        <path
          fill='#F5F5F5'
          d='M121.79 225.7c64.726 0 117.196-9.616 117.196-21.479s-52.47-21.48-117.196-21.48c-64.725 0-117.195 9.617-117.195 21.48S57.065 225.7 121.79 225.7z'
        ></path>
        <path
          fill='#FAFAFA'
          d='M166.521 204.22s0-.166-.222-.387c-.221-.221-.609-.443-1.162-.664-1.107-.443-2.879-.831-5.149-1.218-4.539-.72-11.182-1.329-19.375-1.716-8.193-.388-17.992-.499-28.898-.277a315.92 315.92 0 00-17.05.83c-2.935.222-5.924.498-8.969.941-1.494.222-3.044.443-4.54.775-.774.166-1.494.332-2.214.609-.387.111-.72.277-1.051.443-.333.166-.665.443-.665.72 0 .277.388.553.665.719.332.167.72.333 1.051.443.72.222 1.495.443 2.215.609 1.495.332 3.045.554 4.54.775 3.044.388 6.033.72 8.968.941 5.923.443 11.625.665 17.05.831 10.851.277 20.649.111 28.898-.277 8.193-.387 14.836-.941 19.375-1.716 2.27-.388 4.042-.775 5.149-1.218.553-.221.996-.443 1.162-.664.277-.332.222-.499.222-.499s.055.167-.166.443c-.222.222-.609.499-1.163.72-1.107.443-2.878.886-5.148 1.273-4.54.775-11.183 1.384-19.376 1.772-8.193.387-17.992.553-28.897.332-5.426-.111-11.128-.332-17.051-.775-2.934-.222-5.979-.498-8.968-.941-1.495-.222-3.045-.443-4.54-.775-.775-.166-1.495-.332-2.27-.609-.387-.111-.72-.277-1.107-.443l-.498-.332c-.166-.166-.332-.332-.332-.609 0-.277.166-.443.332-.609.166-.166.332-.277.498-.332.332-.166.72-.332 1.107-.443.776-.277 1.495-.443 2.27-.609 1.495-.332 3.045-.554 4.54-.775 3.044-.388 6.034-.72 8.968-.941 5.923-.443 11.625-.665 17.051-.775 10.85-.222 20.704-.056 28.897.332 8.193.387 14.836 1.052 19.376 1.771 2.27.388 4.041.775 5.148 1.274.554.221.997.442 1.163.719.221.166.166.332.166.332z'
        ></path>
        <path
          fill='#FAFAFA'
          d='M193.204 204.221s.056-.277-.221-.72c-.277-.443-.83-.941-1.716-1.384-1.716-.886-4.484-1.772-8.083-2.491-7.252-1.495-17.881-2.768-31.112-3.543-13.175-.775-28.953-1.107-46.446-.609-8.747.277-17.881.719-27.348 1.661a192.9 192.9 0 00-14.393 1.882c-2.436.443-4.816.941-7.252 1.605-1.218.332-2.38.72-3.488 1.218-.553.277-1.107.554-1.605.886-.498.332-.941.83-.941 1.384 0 .553.443 1.052.94 1.384.5.387 1.053.664 1.606.886 1.107.498 2.325.885 3.488 1.217 2.38.665 4.816 1.163 7.252 1.606 4.816.83 9.633 1.439 14.393 1.882 9.467.941 18.656 1.384 27.403 1.661 17.494.498 33.216.166 46.447-.609 13.175-.775 23.86-1.993 31.112-3.543 3.598-.775 6.366-1.606 8.082-2.491.886-.443 1.44-.941 1.716-1.384.166-.222.111-.498.166-.498v.166c0 .11-.055.332-.221.553-.277.443-.83.941-1.716 1.384-1.716.941-4.484 1.772-8.083 2.547-7.252 1.55-17.881 2.823-31.112 3.598-13.23.775-28.953 1.163-46.446.664-8.747-.276-17.937-.719-27.403-1.66a186.11 186.11 0 01-14.393-1.883c-2.436-.442-4.872-.941-7.252-1.605-1.218-.332-2.38-.72-3.544-1.273-.553-.277-1.162-.554-1.66-.941-.277-.166-.498-.443-.72-.665a1.503 1.503 0 01-.332-.941c0-.332.166-.719.387-.941.222-.277.443-.498.72-.664.498-.388 1.107-.665 1.66-.941 1.163-.499 2.381-.886 3.544-1.274 2.38-.664 4.816-1.162 7.252-1.605 4.872-.83 9.688-1.439 14.393-1.882 9.467-.941 18.656-1.384 27.403-1.661 17.494-.498 33.271-.166 46.447.664 13.175.775 23.86 2.049 31.112 3.599 3.598.775 6.366 1.66 8.082 2.546.886.443 1.44.941 1.716 1.384.111.222.166.388.222.554-.056.221-.056.277-.056.277z'
        ></path>
        <path
          fill='#FAFAFA'
          d='M226.254 204.221s.055-.388-.277-1.052c-.277-.665-1.052-1.495-2.214-2.325-2.381-1.606-6.422-3.101-11.681-4.429-10.574-2.657-26.24-4.872-45.671-6.2-19.432-1.329-42.682-1.938-68.424-1.052-12.9.443-26.407 1.273-40.302 2.878-6.975.831-14.006 1.827-21.092 3.322-3.543.775-7.086 1.605-10.574 2.823-1.716.609-3.432 1.274-5.037 2.159-.775.443-1.55.941-2.215 1.606-.664.609-1.162 1.439-1.218 2.325 0 .886.554 1.661 1.163 2.325.664.609 1.44 1.163 2.214 1.605 1.606.886 3.322 1.55 5.038 2.159 3.488 1.163 7.03 2.049 10.574 2.824 7.086 1.494 14.172 2.491 21.092 3.321 13.95 1.606 27.458 2.436 40.301 2.879 25.742.886 48.993.277 68.424-1.052 19.431-1.384 35.098-3.543 45.672-6.2 5.259-1.329 9.3-2.823 11.681-4.429 1.217-.775 1.937-1.605 2.214-2.325.387-.775.277-1.162.332-1.162v.276c0 .111 0 .222-.055.332 0 .111-.111.277-.166.443-.277.665-1.052 1.55-2.215 2.325-2.38 1.606-6.421 3.156-11.681 4.485-10.573 2.712-26.24 4.927-45.671 6.311-19.431 1.384-42.682 1.992-68.48 1.107-12.898-.443-26.406-1.274-40.357-2.879-6.975-.83-14.06-1.827-21.147-3.322-3.543-.775-7.086-1.605-10.573-2.823-1.716-.609-3.488-1.273-5.093-2.214a11.177 11.177 0 01-2.27-1.606c-.665-.664-1.273-1.494-1.273-2.546 0-.997.608-1.882 1.273-2.491.664-.665 1.495-1.163 2.27-1.606 1.605-.886 3.377-1.605 5.093-2.214 3.487-1.218 7.03-2.048 10.573-2.823 7.086-1.495 14.172-2.492 21.148-3.322 13.95-1.605 27.458-2.436 40.357-2.879 25.742-.885 48.993-.277 68.479 1.107 19.431 1.384 35.153 3.599 45.727 6.311 5.259 1.384 9.3 2.879 11.681 4.485 1.218.775 1.937 1.66 2.214 2.325.055.166.111.332.166.443.055.11.055.221.055.332-.055.332-.055.443-.055.443z'
        ></path>
        <path
          fill='#E0E0E0'
          d='M126.608 191.045c-9.079-.609-18.214-1.162-27.293-.83-9.078.387-18.268 1.771-26.295 4.982-3.156 1.273-6.422 3.045-6.976 5.702-.608 2.879 2.27 5.536 5.702 6.92 7.031 2.768 15.723 1.329 23.03-.996s14.393-5.536 22.31-6.201c6.588-.553 13.175.775 19.376 2.492 6.2 1.716 12.234 3.819 18.656 4.982 6.421 1.162 13.452 1.329 19.431-.775 3.211-1.107 6.2-3.211 6.366-5.868.111-1.882-1.218-3.654-3.1-4.927-1.882-1.218-4.263-1.993-6.588-2.602-15.168-4.041-31.721-3.543-47.775-2.99M13.84 203.28c-1.273.941-2.546 1.882-3.82 2.768-.387.276-.83.664-.72 1.107.056.609.83.775 1.44.83 4.096.222 6.92-.221 10.518-2.104.332-.166 1.384-.719 1.827-1.107.277-.221 1.495-1.107 1.273-1.384-.221-.276-.664-.332-1.052-.276-3.266.055-6.2.11-9.466.166z'
        ></path>
        <path
          fill='#FFBE9D'
          d='M152.017 74.846c.665.554 22.753 25.908 22.753 25.908l-11.958 9.467-15.168-18.933 4.373-16.442zM86.195 83.371c-.609.665-18.435 24.359-18.435 24.359l11.183 12.123 13.729-20.704-6.477-15.778z'
        ></path>
        <path
          fill='#E22D73'
          d='M86.416 99.093c1.329.61 2.049 1.993 2.547 3.322.498 1.328.83 2.768 1.771 3.875.941 1.107 2.657 1.716 3.82.886 1.107-.775 1.163-2.492.664-3.709-.609-1.551-1.827-2.768-3.266-3.599-1.384-.83-2.99-1.328-4.595-1.716'
        ></path>
        <path
          fill='#000'
          d='M86.416 99.093c1.329.61 2.049 1.993 2.547 3.322.498 1.328.83 2.768 1.771 3.875.941 1.107 2.657 1.716 3.82.886 1.107-.775 1.163-2.492.664-3.709-.609-1.551-1.827-2.768-3.266-3.599-1.384-.83-2.99-1.328-4.595-1.716'
          opacity='0.2'
        ></path>
        <path
          fill='#455A64'
          d='M190.215 147.034c-.222-.055-.665-.166-1.218-.221l-44.509-18.656H100.2l-43.623 17.217c-4.428 1.494-10.74 10.13-7.141 20.427l.332.83c2.879 7.197 10.795 10.906 18.269 8.913 6.034-1.605 14.67-3.986 23.583-6.421h.996l27.735-7.861c4.374 1.218 13.508 3.709 23.639 6.532 12.345 3.377 26.185 7.197 34.71 9.466 7.473 1.993 15.445-1.716 18.268-8.912l.333-.831c3.653-10.352-2.658-18.988-7.086-20.483z'
        ></path>
        <path
          fill='#263238'
          d='M120.074 161.372s.055 0 .166-.055c.111-.056.277-.056.443-.166.443-.111.996-.277 1.771-.498 1.55-.443 3.82-1.108 6.643-1.883l-.11.111c-1.163-4.207-2.658-9.799-4.429-16.276l.166.111h-4.539c-1.55 0-3.156.166-4.817.222-3.266.166-6.698.387-10.241.553-12.677.72-24.081 1.606-32.385 2.27-4.097.332-7.474.609-9.799.83-1.162.111-2.048.166-2.657.222-.277 0-.498.055-.72.055h-.221s.055 0 .221-.055c.166 0 .388-.056.664-.056.61-.055 1.495-.166 2.658-.276 2.325-.222 5.646-.554 9.798-.942 8.249-.719 19.708-1.605 32.385-2.38 3.543-.166 6.976-.388 10.242-.554 1.661-.055 3.266-.166 4.816-.221H124.835l.055.111c1.716 6.477 3.211 12.068 4.318 16.275v.111h-.111c-2.823.775-5.093 1.384-6.698 1.772-.775.221-1.329.332-1.772.442-.166.056-.332.056-.442.111-.056.166-.111.166-.111.166z'
        ></path>
        <path
          fill='#263238'
          d='M105.626 143.159c-.055.056-1.273-.609-2.602-1.716-1.328-1.107-2.27-2.214-2.159-2.27.055-.055 1.107.886 2.381 1.993 1.328 1.052 2.435 1.882 2.38 1.993zM184.955 148.64h-.609c-.443 0-.996-.056-1.772-.111-1.55-.111-3.709-.277-6.421-.443-5.425-.387-12.899-.886-21.147-1.439-8.249-.498-15.722-.941-21.148-1.273-2.657-.166-4.871-.277-6.421-.388-.72-.055-1.329-.111-1.717-.111-.387-.055-.608-.055-.608-.055h.608c.443 0 .997.055 1.772.055 1.55.056 3.709.166 6.422.277 5.425.277 12.898.664 21.147 1.163 8.248.553 15.722 1.107 21.147 1.55 2.657.221 4.872.443 6.422.553.72.056 1.328.111 1.716.166.388 0 .609.056.609.056zM120.794 147.81c-.055-.056.664-.72 1.827-1.052 1.162-.277 2.104-.056 2.104 0 0 .11-.942.055-1.993.332-1.108.277-1.883.775-1.938.72zM122.012 150.578c0-.111.775-.277 1.827-.388.996-.111 1.826-.166 1.826-.055 0 .11-.775.277-1.826.387-.997.166-1.827.166-1.827.056zM122.511 153.844c0-.111.996-.111 2.159-.499 1.162-.332 1.993-.83 2.048-.719.055.055-.72.719-1.937 1.107-1.218.387-2.27.221-2.27.111zM123.617 157.387c0-.111.941-.166 2.048-.609 1.108-.388 1.827-.941 1.938-.886.055.056-.664.775-1.771 1.218-1.218.443-2.215.388-2.215.277z'
        ></path>
        <path
          fill='#263238'
          d='M143.88 141.443c.055.055-.665.941-1.495 2.048-.83 1.107-1.439 2.104-1.55 2.048-.111-.055.387-1.107 1.273-2.269.831-1.107 1.716-1.883 1.772-1.827z'
        ></path>
        <path
          fill='#FFBE9D'
          d='M124.834 142.55l-10.629-1.605s-.941-2.491-2.602-2.602c-1.661-.111-3.155 1.661-4.318 1.55-1.218-.111-15.445-4.872-16.22 1.55l5.923 2.436M159.656 148.363l-31.555 6.754-2.824-10.85 11.681-.554s1.384-2.27 3.045-2.104c1.605.167 2.768 2.215 3.986 2.326 1.162.11 15.999-2.049 15.667 4.428zM120.02 65.766c5.481.388 10.076-4.152 9.743-9.632-.11-2.27-.221-4.041-.221-4.041s7.197-1.108 7.307-8.36c.111-7.196-1.162-23.804-1.162-23.804a25.056 25.056 0 00-25.189 3.155l-1.051.83 2.214 33.327c.221 4.54 3.82 8.193 8.359 8.525z'
        ></path>
        <path
          fill='#EB996E'
          d='M129.543 51.981s-4.54.388-9.411-2.657c0 0 2.214 5.204 9.411 4.54V51.98z'
        ></path>
        <path
          fill='#FFBE9D'
          d='M110.278 31.388c-.111-.055-4.983-1.494-4.817 3.488.166 4.927 5.093 3.764 5.093 3.598 0-.11-.276-7.086-.276-7.086z'
        ></path>
        <path
          fill='#EB996E'
          d='M109.004 36.703l-.221.11c-.166.056-.388.111-.665.056-.553-.166-.996-.997-1.051-1.882 0-.443.055-.886.221-1.218.166-.388.387-.61.609-.665.277-.055.443.056.498.222.056.11.056.221.056.221s.11-.11.055-.276c0-.111-.111-.222-.222-.277-.11-.111-.276-.111-.498-.111-.387.055-.719.387-.886.775a3.628 3.628 0 00-.276 1.384c.055.996.609 1.882 1.328 2.104.332.055.609-.056.775-.166.222-.166.277-.277.277-.277z'
        ></path>
        <path
          fill='#263238'
          d='M119.412 30.669c.11-.111.885.442 1.993.442 1.107 0 1.937-.553 2.048-.442.055.055-.055.276-.443.553-.332.277-.941.498-1.661.498-.664 0-1.273-.276-1.605-.498-.277-.277-.388-.498-.332-.553zM130.318 30.448c.11-.111.885.442 1.992.442 1.108 0 1.938-.553 2.049-.442.055.055-.056.276-.443.553-.332.277-.941.498-1.661.498-.664 0-1.273-.276-1.605-.498-.277-.277-.388-.498-.332-.553zM127.439 38.53c0-.056.664-.166 1.826-.332.277-.056.554-.111.609-.277.056-.222-.055-.498-.166-.83a45.895 45.895 0 01-.83-2.16c-1.163-3.044-1.993-5.59-1.882-5.646.11-.056 1.162 2.38 2.325 5.48.277.775.553 1.495.775 2.16.111.332.277.664.166 1.107-.055.221-.277.387-.443.387-.166.055-.332.055-.498.055-1.218.111-1.882.111-1.882.056zM124.174 39.304c.166 0 .166 1.218 1.218 2.048 1.052.83 2.325.72 2.325.886 0 .055-.277.221-.831.221-.553 0-1.328-.166-1.937-.664a2.694 2.694 0 01-.941-1.716c-.056-.498.055-.775.166-.775zM123.675 27.07c-.111.277-1.218.166-2.491.332-1.329.111-2.381.499-2.547.222-.055-.111.111-.388.554-.72a4.975 4.975 0 011.882-.664c.775-.11 1.495.055 1.993.221.388.222.664.498.609.61zM133.861 28.122c-.222.277-.942 0-1.883 0-.941-.056-1.716.166-1.882-.11-.055-.112.055-.388.388-.61a2.468 2.468 0 011.55-.387c.609 0 1.162.221 1.494.498.333.277.443.498.333.609z'
        ></path>
        <path
          fill='#263238'
          d='M139.175 15.39c.055-.886-.277-1.883-.997-2.38-.719-.499-1.882-.499-2.436.165.333-1.218-.664-2.602-1.882-2.657-1.162-.055-2.159.83-3.321 1.107-1.107.332-2.325.056-3.377-.387-1.052-.443-2.048-.997-3.156-1.44-2.269-.83-4.816-.72-7.03.277-2.159.997-3.931 2.879-4.817 5.149-1.605-.498-3.376.332-4.373 1.66-.996 1.33-1.218 3.1-1.052 4.761.111 1.108.443 2.16.831 3.267v.276c.055 3.986.498 8.636 2.214 8.636 2.934.056 2.27-5.535 2.27-5.535 3.598-1.385 2.269-6.81 3.155-7.64.886-.83 2.824-2.713 10.408 1.162 2.934 1.495 6.864 1.44 9.023.997.056 0 .111 0 .166-.055h.111a6.622 6.622 0 001.938-.83c.664-.444 1.328-.997 1.605-1.772.277-.775.166-1.716-.387-2.27.609-.775 1.051-1.605 1.107-2.491z'
        ></path>
        <path
          fill='#455A64'
          d='M131.978 5.923s.056.056.111.222c.111.11.166.332.277.609.221.553.332 1.44.166 2.491-.222 1.052-.775 2.27-1.993 3.1-1.218.83-2.879.941-4.429.72-1.605-.222-3.045-.775-4.373-1.052-1.329-.277-2.602-.332-3.599-.11a4.902 4.902 0 00-2.159 1.106c-.442.388-.553.665-.609.665 0 0 .056-.056.111-.222.056-.11.222-.332.443-.553.388-.443 1.163-.941 2.159-1.218 1.052-.277 2.325-.277 3.709 0s2.823.775 4.374.996c1.55.222 3.1.111 4.262-.664 1.107-.775 1.716-1.938 1.938-2.879.221-.996.111-1.882-.056-2.436-.166-.498-.387-.72-.332-.775z'
        ></path>
        <path
          fill='#263238'
          d='M130.096 7.307s.388.333.443 1.108c.055.387 0 .83-.221 1.328a2.785 2.785 0 01-1.108 1.163c-.498.276-1.051.443-1.55.443-.498 0-.941-.056-1.328-.167-.72-.22-1.108-.498-1.052-.553 0-.055.443.166 1.107.332.664.166 1.716.221 2.657-.332.443-.277.831-.664 1.052-1.052.222-.387.277-.83.277-1.162-.055-.72-.332-1.108-.277-1.108z'
        ></path>
        <path
          fill='#E22D73'
          d='M155.395 126.163s.442 3.433-2.99 5.315l-7.916-3.322-9.743.665 3.819-10.408s10.906 1.329 12.456 2.602c1.606 1.163 4.484 3.488 4.374 5.148z'
        ></path>
        <path
          fill='#000'
          d='M155.395 126.163s.442 3.433-2.99 5.315l-7.916-3.322-9.743.665 3.819-10.408s10.906 1.329 12.456 2.602c1.606 1.163 4.484 3.488 4.374 5.148z'
          opacity='0.4'
        ></path>
        <path
          fill='#E22D73'
          d='M155.395 126.163s.442 3.433-2.99 5.315l-7.916-3.322-9.743.665 3.819-10.408s10.906 1.329 12.456 2.602c1.606 1.163 4.484 3.488 4.374 5.148z'
        ></path>
        <path
          fill='#000'
          d='M155.395 126.163s.442 3.433-2.99 5.315l-7.916-3.322-9.743.665 3.819-10.408s10.906 1.329 12.456 2.602c1.606 1.163 4.484 3.488 4.374 5.148z'
          opacity='0.4'
        ></path>
        <path
          fill='#E22D73'
          d='M163.587 79.33l-.775-.665L146.093 65.6c-5.148-5.48-8.913-7.14-16.165-7.971-1.162-.166.665.11 0 0-5.536 1.716-13.009 1.882-18.545.166-2.602.11-4.706.886-6.92 1.328-6.92 1.33-10.573 6.2-13.286 11.017L77.835 92.56l.554.886c1.882 2.879 4.65 5.038 7.916 6.2l.886.443c3.1 1.55 5.923 3.765 7.861 6.588l5.868-10.297-1.66 16.497-1.163 16.165c8.304 2.27 15.224 3.211 24.136 3.1 11.626.056 27.846-14.116 33.05-5.923.055-4.207-5.204-9.965-6.699-12.733-4.539-8.636-3.709-12.123-4.041-18.656l7.806 4.761 1.384-2.712c1.162-2.326 3.432-7.474 4.263-11.46.166-.11 1.107-7.584 5.591-6.09z'
        ></path>
        <path
          fill='#000'
          d='M125.629 111.708c.532.337.798.539 1.102.708 1.824 1.078 3.8 1.179 5.092.101 1.368-1.18 1.482-2.663.836-4.18-.684-1.718-2.28-2.763-4.218-2.494-.874.135-1.9.708-2.394 1.349-1.216 1.55-1.634 3.539-3.647 4.583-.266.135-.19.91-.19 1.382.038 3.371-2.736 5.966-6.726 6.303-3.154.27-7.865-1.989-7.827-5.123 1.672.674 3.268 1.618 5.053 1.955 1.482.303 3.268.168 4.75-.27 1.938-.573 2.85-2.224 2.622-3.707-.228-1.281-.798-1.618-3.724-2.056-.19-.742-.38-1.551-.57-2.461.304-.034.532-.101.76-.101 1.33-.101 2.774-.135 2.888-1.786.114-1.315-1.292-2.865-2.926-2.73-1.596.134-3.078.876-4.902 1.449-.342-.472-.911-1.247-1.443-2.022 2.317-2.427 6.117-2.899 8.891-1.113 2.546 1.618 2.774 4.214.532 7.247.646.539 1.292 1.078 2.204 1.854.304-.809.456-1.382.684-1.921.304-.776.532-1.618 1.025-2.326 1.216-1.854 2.888-3.135 5.51-2.865 2.546.27 3.841 1.61 4.817 3.702 1.058 2.454.626 4.3 0 5.701-.626 1.401-2.955 2.832-4.627 2.596-2.204-.303-3.458-1.551-3.572-3.775z'
        ></path>
        <path
          fill='#000'
          d='M118.295 98.797c3.496 2.158 6.574 1.854 9.689-.033.57.809 1.064 1.483 1.558 2.157-3.685 2.561-9.689 1.247-11.247-2.124z'
        ></path>
        <path
          fill='#fff'
          d='M120.811 97.022c.627-.767 1.116-1.325 1.708-2.022.697.732 1.151 1.22 1.709 1.848a66.6 66.6 0 01-1.639 1.778c-.593-.523-1.116-1.011-1.778-1.604z'
        ></path>
        <path
          fill='#263238'
          d='M101.032 79.717s.055.222.055.665c0 .443.056 1.051.056 1.827 0 1.55.055 3.709 0 6.09 0 2.38-.056 4.539-.056 6.089 0 .775-.055 1.384-.055 1.826 0 .443-.055.665-.055.665s-.056-.222-.056-.665c0-.442-.055-1.051-.055-1.826 0-1.55-.055-3.71 0-6.09 0-2.38.055-4.54.055-6.09 0-.775.056-1.383.056-1.826 0-.443 0-.665.055-.665zM145.651 105.847s-.055-.388-.11-1.052c-.056-.72-.166-1.716-.277-2.879-.222-2.436-.443-5.757-.72-9.466-.277-3.71-.498-7.03-.609-9.467-.055-1.162-.11-2.159-.166-2.878-.055-.665-.055-1.052 0-1.052 0 0 .056.387.111 1.052.055.72.166 1.716.277 2.878.221 2.436.443 5.758.719 9.467.277 3.709.499 7.03.609 9.466.056 1.163.111 2.159.166 2.879v1.052z'
        ></path>
        <path
          fill='#000'
          d='M109.28 58.348a43.071 43.071 0 0013.452 12.235c1.938 1.107 4.041 2.103 6.256 2.27 3.819.332 7.473-1.772 10.462-4.208 1.163-.94 2.326-2.048 2.824-3.487.498-1.44.11-3.322-1.218-3.986-.941-.499-2.104-.332-3.045.11-.941.443-1.771 1.108-2.602 1.717-3.82 2.712-8.802 3.764-13.397 2.934-4.595-.886-8.857-3.654-11.404-7.585M114.595 123.672c8.138 1.55 16.885-1.937 21.812-8.525.775-1.052 1.55-2.38 1.218-3.654-.332-1.328-1.827-2.214-3.211-2.159-1.384.056-2.657.775-3.709 1.716-2.547 2.215-3.986 5.481-6.532 7.695-2.27 1.993-5.315 3.045-8.304 2.879-.443-.055-.941 0-1.384.055-.609.111-1.052.665-1.052 1.108-.055.553.387.775 1.162.885zM143.989 84.7l.665 10.075 7.861 4.761 1.605-3.045L143.989 84.7z'
          opacity='0.2'
        ></path>
        <path
          fill='#263238'
          d='M163.699 79.496c0 .055-.332-.166-.941-.166-.609-.056-1.55.166-2.38.886-.83.664-1.439 1.882-1.827 3.321-.387 1.384-.72 2.99-1.273 4.65-.997 3.266-2.159 6.145-3.1 8.138-.443.996-.886 1.827-1.163 2.38-.277.554-.498.83-.498.83s.111-.331.387-.885c.277-.553.665-1.384 1.108-2.38.885-2.049 2.048-4.872 3.044-8.138.499-1.606.886-3.211 1.274-4.595.387-1.44 1.051-2.657 1.993-3.377.885-.72 1.882-.886 2.491-.83.332 0 .553.11.719.166.111-.056.166 0 .166 0zM95.108 106.622s-.221-.221-.553-.664c-.388-.443-.886-1.107-1.606-1.771-.72-.72-1.66-1.495-2.768-2.215-1.162-.72-2.49-1.384-3.875-2.103-1.384-.72-2.712-1.44-3.82-2.215a20.993 20.993 0 01-2.767-2.325c-.72-.775-1.163-1.44-1.44-1.993-.166-.221-.221-.443-.277-.609-.055-.166-.11-.221-.055-.221.055-.056.554 1.162 1.938 2.657.72.72 1.66 1.495 2.767 2.27 1.108.775 2.436 1.439 3.82 2.159 1.384.719 2.713 1.384 3.876 2.103 1.162.72 2.103 1.55 2.823 2.27.72.72 1.218 1.384 1.55 1.882.166.222.277.443.387.554a.266.266 0 000 .221zM139.34 109.944s0 .11-.111.332c-.055.221-.166.498-.332.886-.332.775-.83 1.826-1.661 3.1-1.605 2.491-4.428 5.646-8.525 7.75-4.041 2.104-8.304 2.602-11.238 2.436-1.495-.056-2.657-.277-3.488-.443-.387-.055-.719-.166-.941-.222-.221-.055-.332-.11-.332-.11s.111 0 .332.055c.222.055.554.111.941.166.831.166 1.993.332 3.488.332 2.934.111 7.086-.443 11.127-2.491 3.986-2.048 6.865-5.148 8.47-7.584.831-1.218 1.384-2.27 1.716-3.045.166-.387.277-.664.388-.886.111-.166.166-.276.166-.276z'
        ></path>
        <path
          fill='#000'
          d='M95.606 105.46c.61-8.027 2.491-15.999 5.425-23.528V96.27l-5.425 9.19z'
          opacity='0.2'
        ></path>
        <path
          fill='#263238'
          d='M109.003 58.016c2.436 5.204 8.857 8.138 14.449 9.245 5.646 1.108 11.736-.664 15.943-4.594 3.543-3.322 5.647-7.917 8.913-11.515 5.647-6.09 14.836-8.58 22.753-6.2l.443-7.861c-2.713-1.717-9.633-2.38-12.788-1.883-6.256.942-12.069 4.374-15.944 9.356-3.488 4.484-5.536 10.242-10.131 13.508-3.321 2.38-7.473 2.38-11.514 1.716-4.042-.664-6.754-4.373-9.854-7.03l-2.27 5.258z'
        ></path>
        <path
          fill='#263238'
          d='M111.218 52.757c2.103 2.657 5.314 4.429 8.636 4.872 3.377.387 6.864-.61 9.577-2.713.719-.553 1.661-1.273 2.436-.83.609.332.775 1.162.885 1.827.222 1.605.333 3.432-.609 4.76-.941 1.274-2.601 1.717-4.152 1.993-3.653.61-7.584.554-10.961-.94-3.377-1.495-5.48-5.26-5.812-8.969z'
        ></path>
        <path
          fill='#455A64'
          d='M134.246 56.743s-.055.055-.166.222l-.553.553a10.86 10.86 0 01-2.381 1.606 13.783 13.783 0 01-4.041 1.384 12.809 12.809 0 01-5.204-.166c-1.827-.443-3.377-1.274-4.761-2.104-.664-.388-1.328-.775-1.937-1.218-.609-.388-1.107-.83-1.55-1.218-.886-.775-1.55-1.55-1.882-2.159-.167-.277-.333-.498-.388-.664-.055-.166-.111-.277-.111-.277s.166.332.554.886c.387.553 1.052 1.273 1.937 2.048.443.387.997.775 1.551 1.162.608.388 1.273.776 1.937 1.163 1.384.775 2.934 1.605 4.706 2.048 1.771.443 3.543.443 5.093.166 1.55-.221 2.878-.775 3.986-1.328 1.051-.554 1.882-1.107 2.38-1.55.554-.332.83-.61.83-.554zM143.768 56.798c-.055.056-1.495-.72-3.211-1.66-1.771-.941-3.211-1.716-3.155-1.772.055-.055 1.55.61 3.321 1.55 1.716.886 3.1 1.772 3.045 1.883zM147.645 51.76c-.056.056-1.606-.72-3.543-1.771-1.938-1.052-3.433-1.993-3.433-2.048.056-.056 1.606.72 3.543 1.771 1.938 1.052 3.433 1.938 3.433 2.048zM151.352 47.609c-.055.055-.387-.277-.941-.775-.609-.61-1.328-1.274-2.104-1.993-.775-.72-1.494-1.384-2.159-1.993-.553-.498-.885-.83-.83-.886 0-.055.388.221.997.72.553.498 1.384 1.162 2.214 1.937.83.775 1.55 1.55 2.048 2.104.498.498.831.83.775.886zM151.906 37.257c.055 0 .332.443.775 1.162.498.83 1.163 1.772 1.827 2.824.664 1.051 1.329 1.992 1.827 2.767.498.72.775 1.163.72 1.163-.056 0-.388-.388-.886-1.052a52.129 52.129 0 01-1.938-2.768 61.816 61.816 0 01-1.771-2.878c-.332-.776-.554-1.218-.554-1.218zM160.598 35.596c.056 0 .166.442.332 1.218.222.885.443 1.882.665 2.989.277 1.107.498 2.104.719 2.99.167.774.277 1.217.277 1.273-.055 0-.221-.443-.443-1.218-.221-.775-.553-1.827-.83-2.99-.277-1.162-.498-2.27-.609-3.044-.111-.72-.111-1.218-.111-1.218zM167.407 35.485c.111 0 .055 1.882.166 4.152.111 2.27.332 4.152.221 4.152-.055 0-.166-.443-.276-1.218a31.134 31.134 0 01-.277-2.934c-.056-1.163-.056-2.215 0-2.934.055-.72.166-1.218.166-1.218z'
        ></path>
        <path
          fill='#455A64'
          d='M138.842 63.054c-.055.055-1.328-1.108-2.879-2.602-1.55-1.495-2.768-2.768-2.712-2.823.055-.056 1.328 1.107 2.879 2.601 1.55 1.55 2.768 2.768 2.712 2.824zM130.982 67.095c-.056 0-.609-1.55-1.218-3.487-.609-1.938-.997-3.543-.941-3.599.055 0 .609 1.55 1.218 3.488.608 1.993 1.051 3.598.941 3.598zM122.289 66.984c-.055 0 .166-1.494.554-3.266.387-1.771.719-3.21.83-3.21.055 0-.166 1.494-.554 3.266-.387 1.826-.719 3.21-.83 3.21zM115.757 64.493c-.055 0 .332-1.328 1.052-2.878.719-1.55 1.55-2.657 1.605-2.602.056.055-.609 1.218-1.328 2.713-.775 1.494-1.274 2.767-1.329 2.767zM113.93 56.134c.055.056-.443 1.163-1.273 2.491-.831 1.33-1.606 2.27-1.661 2.27-.056-.055.609-1.107 1.439-2.38.775-1.329 1.439-2.436 1.495-2.38zM121.237 57.795c.056 0 .056.442 0 .996-.055.554-.111.997-.166.997-.055 0-.166-.443-.111-.997.056-.609.222-1.052.277-.996zM126.662 60.341c-.055 0-.276-.775-.387-1.771-.111-.997-.055-1.827 0-1.827s.166.775.277 1.771c.11 1.052.166 1.827.11 1.827zM130.538 54.529c.056 0 .167.996.388 2.214s.443 2.215.332 2.215c-.055 0-.443-.941-.609-2.16-.221-1.273-.166-2.27-.111-2.27z'
        ></path>
        <path
          fill='#FFBE9D'
          d='M80.272 119.078l34.765-20.15a9.072 9.072 0 004.484-7.363l.941-21.148c0-.553-.387-1.052-.996-1.107-.498-.055-.997.277-1.163.775l-4.428 15.888-41.354 17.494c-4.65 1.993-6.643 7.473-4.373 11.957 2.214 4.54 7.806 6.201 12.124 3.654z'
        ></path>
        <path
          fill='#EB996E'
          d='M113.874 85.973s-.111.055-.387.221c-.277.111-.665.332-1.163.554a319.796 319.796 0 01-4.318 1.993 588.815 588.815 0 01-14.338 6.255c-5.647 2.38-10.74 4.43-14.504 5.869-1.882.719-3.377 1.328-4.429 1.716-.498.166-.886.332-1.218.443-.277.11-.443.166-.443.11 0 0 .111-.055.388-.166.277-.111.72-.277 1.218-.498a212.448 212.448 0 014.373-1.772c3.71-1.494 8.802-3.598 14.449-5.978s10.684-4.595 14.338-6.2c1.827-.776 3.266-1.44 4.373-1.883a36.41 36.41 0 011.163-.498l.498-.166z'
        ></path>
        <path
          fill='#FFBE9D'
          d='M164.196 113.155l-35.43-15.833a8.676 8.676 0 01-5.038-6.643l-2.989-20.206c-.056-.554.276-1.052.83-1.163a.991.991 0 011.162.664l5.869 14.781 41.464 12.622c5.204 1.661 7.196 6.533 5.425 11.072-1.716 4.54-6.865 6.699-11.293 4.706z'
        ></path>
        <path
          fill='#EB996E'
          d='M128.6 84.756s.166.055.443.11c.277.111.72.222 1.218.388 1.052.332 2.547.775 4.374 1.384 3.709 1.162 8.746 2.768 14.393 4.484a842.396 842.396 0 0014.449 4.263c1.827.553 3.321.94 4.373 1.273.498.166.886.277 1.218.332.277.11.388.11.388.166 0 0-.166 0-.443-.11-.277-.056-.72-.167-1.218-.277-1.052-.277-2.547-.665-4.429-1.163-3.709-.996-8.857-2.491-14.504-4.207-5.647-1.716-10.74-3.322-14.393-4.54-1.827-.609-3.267-1.107-4.319-1.44-.498-.165-.885-.331-1.162-.387-.277-.221-.388-.276-.388-.276z'
        ></path>
        <path
          fill='#263238'
          d='M16.83 188.886l.11-.221c.056-.167.167-.388.333-.72.277-.609.664-1.55.941-2.823.664-2.492.886-6.311-.055-10.796-.443-2.269-1.163-4.705-1.993-7.252-.83-2.601-1.827-5.314-2.658-8.248-.775-2.934-1.439-6.145-1.328-9.522.11-3.377 1.107-6.698 2.214-10.075 1.107-3.322 2.215-6.643 2.547-9.91.387-3.266-.332-6.532-1.827-9.134-1.44-2.657-3.266-4.982-4.65-7.307-1.384-2.381-2.27-4.761-2.768-7.031-1.052-4.539-.665-8.36-.166-10.906.553-2.546 1.162-3.875 1.162-3.875s0 .11-.055.277c-.056.166-.11.443-.222.72-.221.664-.442 1.605-.72 2.878-.442 2.547-.774 6.311.277 10.851.554 2.214 1.44 4.65 2.824 6.975 1.384 2.325 3.21 4.595 4.65 7.307 1.55 2.658 2.27 5.979 1.882 9.356-.332 3.322-1.494 6.643-2.546 9.965-1.107 3.321-2.104 6.643-2.215 9.964-.11 3.322.499 6.477 1.274 9.412.775 2.934 1.771 5.702 2.601 8.248.83 2.602 1.55 5.038 1.993 7.307.942 4.54.665 8.415-.055 10.906a13.995 13.995 0 01-1.052 2.824c-.166.276-.277.498-.387.664-.056.055-.111.166-.111.166z'
        ></path>
        <path
          fill='#E22D73'
          d='M12.4 115.147s-2.767-8.857 2.215-14.061c.166-.166.498-.111.554.166.221 1.439.553 5.923-2.768 13.895z'
        ></path>
        <path
          fill='#263238'
          d='M14.061 104.02c.056 0-.055.609-.166 1.661-.166 1.107-.387 2.435-.609 3.93-.221 1.44-.442 2.768-.609 3.931-.166.996-.221 1.605-.276 1.605-.056 0-.056-.609.055-1.661.11-.996.277-2.38.498-3.93.277-1.55.498-2.934.72-3.875.166-1.052.332-1.661.387-1.661z'
        ></path>
        <path
          fill='#E22D73'
          d='M15.613 136.018s1.716-9.134 8.525-11.404c.221-.055.498.166.387.388-.442 1.439-2.214 5.535-8.912 11.016z'
        ></path>
        <path
          fill='#263238'
          d='M22.311 126.994c.056.056-.332.554-.94 1.384-.665.886-1.495 1.993-2.381 3.156a493.32 493.32 0 00-2.38 3.155c-.61.831-.997 1.329-.997 1.273-.056 0 .277-.553.83-1.439a60.086 60.086 0 012.325-3.211 84.602 84.602 0 012.491-3.1c.554-.775.997-1.218 1.052-1.218z'
        ></path>
        <path
          fill='#E22D73'
          d='M13.785 158.937s-1.052-9.245 4.817-13.397c.221-.166.498 0 .498.277 0 1.494-.498 5.923-5.315 13.12z'
        ></path>
        <path
          fill='#263238'
          d='M17.55 148.363c.055 0-.167.609-.499 1.55-.387 1.052-.83 2.325-1.384 3.709-.498 1.384-.94 2.658-1.328 3.709-.333.942-.554 1.551-.61 1.551-.055 0 .056-.609.388-1.606.277-.996.72-2.325 1.273-3.764.554-1.44 1.052-2.768 1.44-3.709.387-.886.664-1.44.72-1.44z'
        ></path>
        <path
          fill='#E22D73'
          d='M18.935 181.966s-.72-9.245 5.314-13.176c.222-.111.498 0 .498.277-.11 1.495-.775 5.923-5.812 12.899z'
        ></path>
        <path
          fill='#263238'
          d='M23.03 171.558c.055 0-.166.609-.553 1.55-.443 1.052-.942 2.27-1.495 3.654a223.475 223.475 0 01-1.495 3.654c-.387.941-.609 1.55-.664 1.494-.056 0 .11-.609.443-1.605.332-.941.83-2.27 1.384-3.709a109.206 109.206 0 011.605-3.654c.443-.886.775-1.439.775-1.384z'
        ></path>
        <path
          fill='#E22D73'
          d='M8.416 107.341S.167 103.023 0 95.882c0-.277.277-.387.498-.277 1.218.83 4.595 3.765 7.917 11.736z'
        ></path>
        <path
          fill='#263238'
          d='M1.717 98.318c.055 0 .443.443 1.052 1.273.664.941 1.44 1.993 2.325 3.211.886 1.218 1.66 2.27 2.325 3.211.61.83.997 1.328.941 1.328-.055.056-.498-.442-1.107-1.217-.664-.776-1.495-1.883-2.436-3.156-.886-1.273-1.716-2.436-2.214-3.266-.61-.775-.941-1.329-.886-1.384z'
        ></path>
        <path
          fill='#E22D73'
          d='M17.218 126.385s-8.47-3.764-9.08-10.961c0-.277.222-.443.444-.277 1.328.775 4.871 3.432 8.636 11.238z'
        ></path>
        <path
          fill='#263238'
          d='M10.021 117.804c.055-.055.443.443 1.107 1.218.72.886 1.55 1.938 2.491 3.045a104.079 104.079 0 002.547 3.045c.664.775 1.052 1.273.996 1.273-.055.056-.498-.387-1.218-1.107s-1.605-1.772-2.601-2.989a74.494 74.494 0 01-2.436-3.156c-.61-.775-.941-1.329-.886-1.329z'
        ></path>
        <path
          fill='#E22D73'
          d='M12.347 147.588s-5.813-7.252-3.1-13.896c.11-.221.387-.276.553-.055.775 1.329 2.713 5.315 2.547 13.951z'
        ></path>
        <path
          fill='#263238'
          d='M9.855 136.682c.055 0 .221.609.443 1.606.221 1.107.553 2.435.83 3.875.332 1.439.61 2.768.83 3.875.222.996.388 1.605.333 1.605-.056 0-.277-.553-.554-1.55a68.104 68.104 0 01-.94-3.875c-.333-1.495-.61-2.879-.72-3.875-.222-1.052-.277-1.661-.222-1.661z'
        ></path>
        <path
          fill='#E22D73'
          d='M18.104 172.61s-8.415-3.93-8.913-11.127c0-.277.277-.443.443-.277 1.328.775 4.816 3.543 8.47 11.404z'
        ></path>
        <path
          fill='#263238'
          d='M11.071 163.864c.056-.056.443.443 1.107 1.218.72.885 1.55 1.937 2.436 3.1.941 1.162 1.772 2.214 2.491 3.1.61.775 1.052 1.273.997 1.329-.055.055-.498-.388-1.163-1.163-.664-.775-1.605-1.827-2.546-3.045a164.229 164.229 0 01-2.38-3.155c-.665-.775-.997-1.329-.942-1.384zM34.877 136.073v.831c0 .553-.055 1.328-.11 2.269-.111 1.993-.277 4.761-.443 8.027-.61 3.377-2.325 7.086-4.595 10.685-1.66 2.657-3.432 5.093-4.982 7.363-1.55 2.269-2.824 4.318-3.71 6.145a30.597 30.597 0 00-1.77 4.539c-.167.554-.278.996-.333 1.273-.055.277-.11.443-.11.443s0-.166.055-.443.11-.719.276-1.328a25.228 25.228 0 011.661-4.595c.886-1.882 2.16-3.986 3.71-6.256 1.494-2.27 3.265-4.705 4.926-7.363 2.27-3.598 3.986-7.252 4.595-10.573.222-3.322.443-6.034.554-8.027.055-.941.11-1.716.166-2.27 0-.222.055-.443.055-.609.055 0 .055-.111.055-.111z'
        ></path>
        <path
          fill='#E22D73'
          d='M29.894 157.221s3.543-8.581-.94-14.172c-.167-.221-.5-.166-.555.111-.387 1.384-1.107 5.812 1.495 14.061z'
        ></path>
        <path
          fill='#263238'
          d='M29.229 145.983c.055 0 .166.609.276 1.606.111.996.277 2.38.388 3.93.11 1.55.166 2.934.166 3.986 0 .997-.056 1.661-.11 1.661-.056 0-.112-.609-.167-1.661-.055-1.162-.166-2.491-.277-3.93-.11-1.495-.221-2.824-.276-3.931-.056-.996-.056-1.661 0-1.661z'
        ></path>
        <path
          fill='#000'
          d='M29.894 157.221s3.543-8.581-.94-14.172c-.167-.221-.5-.166-.555.111-.387 1.384-1.107 5.812 1.495 14.061z'
          opacity='0.3'
        ></path>
        <path
          fill='#E22D73'
          d='M18.823 177.87s2.879-8.858-2.048-14.062c-.166-.166-.498-.11-.554.166-.276 1.44-.608 5.868 2.602 13.896z'
        ></path>
        <path
          fill='#263238'
          d='M17.273 166.742c.056 0 .222.609.388 1.605.221.997.443 2.381.72 3.931.221 1.55.387 2.934.498 3.93.055.997.055 1.661.055 1.661-.055 0-.11-.609-.277-1.605-.166-1.107-.387-2.436-.609-3.931-.221-1.439-.442-2.768-.609-3.93-.11-.997-.221-1.606-.166-1.661z'
        ></path>
        <path
          fill='#000'
          d='M18.823 177.87s2.879-8.858-2.048-14.062c-.166-.166-.498-.11-.554.166-.276 1.44-.608 5.868 2.602 13.896z'
          opacity='0.3'
        ></path>
        <path
          fill='#E22D73'
          d='M34.434 146.702s7.585-5.314 6.865-12.456c0-.276-.332-.387-.498-.221-1.163 1.052-4.152 4.373-6.367 12.677z'
        ></path>
        <path
          fill='#000'
          d='M34.434 146.702s7.585-5.314 6.865-12.456c0-.276-.332-.387-.498-.221-1.163 1.052-4.152 4.373-6.367 12.677z'
          opacity='0.3'
        ></path>
        <path
          fill='#263238'
          d='M39.859 136.904c.055 0-.222.609-.61 1.495a56.886 56.886 0 01-1.826 3.543c-.72 1.384-1.44 2.602-1.993 3.432-.553.83-.941 1.384-.941 1.329-.055-.056.277-.554.775-1.44.554-.996 1.163-2.159 1.882-3.487.72-1.329 1.329-2.492 1.883-3.488.498-.83.774-1.384.83-1.384z'
        ></path>
        <path
          fill='#E22D73'
          d='M21.923 169.123s9.19-1.44 11.625-8.193c.111-.222-.11-.499-.387-.388-1.44.388-5.591 2.048-11.238 8.581z'
        ></path>
        <path
          fill='#000'
          d='M21.923 169.123s9.19-1.44 11.625-8.193c.111-.222-.11-.499-.387-.388-1.44.388-5.591 2.048-11.238 8.581z'
          opacity='0.3'
        ></path>
        <path
          fill='#263238'
          d='M31.113 162.701c.055.055-.443.443-1.218 1.107-.775.609-1.938 1.495-3.156 2.381a87.093 87.093 0 01-3.321 2.214c-.886.554-1.44.83-1.44.775 0-.055.498-.387 1.329-.996.94-.665 2.048-1.44 3.21-2.27 1.219-.83 2.326-1.661 3.212-2.27a20.03 20.03 0 011.384-.941z'
        ></path>
        <path
          fill='#455A64'
          d='M11.018 186.007l2.657 17.272 10.518-.055 3.543-17.217H11.018z'
        ></path>
        <path
          fill='#263238'
          d='M26.186 192.651l-.11.055a1.212 1.212 0 01-.277.166c-.277.111-.665.277-1.163.332-.553.056-1.218.056-1.937-.166-.72-.221-1.495-.72-2.104-1.495-.277-.387-.443-.885-.498-1.439-.056-.554.166-1.162.72-1.495.553-.332 1.217-.221 1.77.166.555.333.831.942.997 1.606.166.609.166 1.273.111 1.937-.055.665-.166 1.329-.443 1.993-.277.665-.664 1.274-1.218 1.717-.553.498-1.273.83-2.048.83-.775.055-1.495-.222-2.104-.609-.609-.388-1.107-.886-1.55-1.439-.443-.554-.775-1.218-.775-1.883-.055-.664.222-1.384.72-1.826.554-.443 1.329-.388 1.882-.111.554.277.997.83 1.052 1.384.221 1.162-.498 2.27-1.329 2.712-.885.499-1.771.499-2.546.388-.775-.166-1.329-.443-1.827-.72a15.358 15.358 0 01-.997-.664c-.22-.166-.332-.277-.332-.277s.111.056.332.222c.222.166.554.387 1.052.609.443.221 1.052.498 1.772.664.72.111 1.605.111 2.38-.388.775-.443 1.384-1.439 1.218-2.491-.11-.498-.443-.996-.94-1.218-.5-.277-1.108-.277-1.606.111-.443.388-.665.996-.61 1.605.056.609.333 1.218.72 1.717.388.498.941.996 1.495 1.328.554.332 1.273.609 1.938.554a3.196 3.196 0 001.826-.775 4.786 4.786 0 001.163-1.606c.221-.609.388-1.273.388-1.882.055-.664 0-1.273-.111-1.882a2.302 2.302 0 00-.886-1.439c-.443-.333-1.052-.388-1.495-.167a1.321 1.321 0 00-.608 1.274c0 .498.166.941.442 1.328.499.775 1.274 1.218 1.938 1.495.72.221 1.329.277 1.882.221 1.218-.11 1.716-.498 1.716-.442z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_locked'>
          <path fill='#fff' d='M0 0H249.338V225.7H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}
