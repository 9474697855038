import _ from 'lodash';

import { LENDING_LIQUIDATION_SET_ACCOUNT_LIST } from '../actions/action-types';

const DEFAULT_FIELDS = {
  accounts: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = _.cloneDeep(DEFAULT_FIELDS), action) => {
  switch (action.type) {
    case LENDING_LIQUIDATION_SET_ACCOUNT_LIST:
      return { ...state, accounts: action.payload };
    default:
      return state;
  }
};
