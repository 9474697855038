import axios from 'axios';

import config from '../config/env';

export default {
  async getLinkedAddress(ethereumAddress: string, chain: string) {
    const { data: response } = await axios.post(
      `${config.REACT_APP_THEGRAPH_API_URL}/subgraphs/name/${config.REACT_APP_THEGRAPH_API_REWARD_DELEGATOR_NAME}`,
      {
        query: `
        {
          links(
            where: {
              ethereumAddress: "${ethereumAddress}",
              targetChain: "${chain}"
            }, 
            orderBy: timestamp) 
          {
            id
            ethereumAddress
            targetAddress
            signature
            targetChain
          }
        }
    `,
      },
    );

    return response.data?.links[0];
  },
};
