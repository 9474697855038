import React from 'react';

import { IconProps } from '../../Icon';

export default function Icon({ className, ...otherProps }: Omit<IconProps, 'type'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      width='1458.000000pt'
      height='681.000000pt'
      viewBox='0 0 1458.000000 681.000000'
      preserveAspectRatio='xMidYMid meet'
      className={className}
      {...otherProps}
    >
      <g
        transform='translate(0.000000,681.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M7470 6610 c-91 -16 -300 -49 -465 -75 -1102 -170 -2503 -408 -2520
-427 -2 -2 -288 -778 -355 -962 -5 -14 -62 76 -235 366 -169 286 -232 384
-244 382 -9 -2 -245 -47 -526 -99 -669 -125 -661 -123 -710 -146 -70 -33 -122
-84 -157 -156 -51 -103 -50 -190 1 -468 52 -281 139 -803 185 -1105 20 -129
39 -252 42 -272 l6 -37 -44 -5 c-24 -3 -405 -8 -848 -11 -443 -2 -857 -9 -920
-14 -280 -24 -422 -140 -450 -367 -5 -43 -14 -347 -20 -674 -6 -327 -15 -872
-21 -1210 -13 -737 -12 -818 20 -883 29 -59 98 -121 165 -145 49 -18 108 -20
1336 -25 707 -4 2266 -16 3465 -27 2684 -24 4104 -37 5615 -50 641 -5 1638
-15 2216 -20 811 -9 1063 -8 1100 1 132 32 253 128 273 215 28 120 35 663 23
1659 -14 1081 -11 1034 -81 1104 -20 20 -59 48 -86 61 l-50 25 -1545 3 -1545
2 0 393 c-1 517 -19 641 -106 724 -183 172 -634 263 -1306 263 -165 0 -263 4
-263 10 0 5 -30 60 -67 122 -112 186 -257 316 -448 403 -177 81 -411 132 -652
142 l-141 6 -6 31 c-3 17 -37 265 -77 551 -43 313 -79 540 -90 570 -25 66 -69
118 -123 145 -69 33 -156 33 -346 0z m83 -642 c5 -20 30 -187 82 -545 8 -56
15 -108 15 -116 0 -10 -23 -16 -82 -23 l-82 -9 -170 -454 -169 -453 13 -97 c7
-53 14 -99 16 -102 2 -3 34 0 71 7 37 7 75 13 84 14 12 0 21 -42 48 -227 18
-126 40 -280 50 -344 l17 -115 -488 -73 c-269 -41 -491 -71 -495 -68 -10 11
-114 667 -107 678 2 4 40 10 84 13 92 8 87 -1 66 127 l-14 86 -294 379 -293
379 -40 -1 c-22 0 -62 -3 -90 -5 l-50 -4 -17 110 c-9 61 -32 213 -52 340 -19
126 -33 231 -31 234 8 7 639 101 664 98 18 -1 32 -17 59 -67 31 -57 113 -190
286 -459 26 -40 49 -72 51 -70 2 2 44 157 95 344 51 187 93 341 94 343 4 3
625 100 652 101 15 1 24 -6 27 -21z m-2139 -468 c14 -91 37 -246 51 -344 27
-198 28 -195 -35 -196 -19 0 -52 -3 -73 -6 -36 -6 -37 -8 -31 -38 3 -18 38
-249 78 -514 39 -265 75 -484 78 -487 3 -4 41 0 85 8 43 8 79 14 80 13 7 -6
102 -679 97 -685 -4 -4 -768 -120 -771 -117 -1 1 -7 35 -13 76 -6 41 -17 104
-25 140 -7 36 -41 252 -75 480 -34 228 -64 422 -66 430 -3 8 -99 -206 -214
-474 -115 -269 -211 -491 -213 -493 -4 -3 -54 55 -285 333 -102 123 -322 384
-390 463 l-22 26 5 -25 c6 -34 130 -871 147 -995 l13 -100 188 -3 187 -2 0
-305 0 -305 -259 0 -258 0 -7 46 c-3 26 -6 66 -6 90 l0 44 -165 0 -165 0 0
-195 0 -195 235 0 235 0 0 -185 0 -185 -235 0 -235 0 0 -215 0 -215 170 0 170
0 0 80 0 80 270 0 270 0 0 -295 0 -295 -825 0 -825 0 0 221 0 221 78 -3 77 -4
3 475 c2 261 0 526 -3 588 l-7 112 -74 0 -74 0 0 220 0 220 226 0 227 0 -7 38
c-32 190 -76 495 -72 499 2 3 40 9 82 14 43 6 79 11 81 12 4 5 -154 1027 -160
1033 -6 6 -122 -11 -150 -22 -9 -3 -17 24 -29 103 -9 60 -33 215 -53 346 -20
131 -34 241 -32 243 4 4 685 109 754 117 18 2 73 -60 295 -331 150 -183 295
-360 321 -395 26 -34 65 -81 85 -104 l37 -43 215 521 c118 286 218 523 223
526 8 5 759 121 764 119 1 -1 13 -76 27 -166z m3141 -526 c193 -40 376 -135
529 -275 60 -55 165 -187 196 -248 l19 -37 108 14 c138 18 534 20 668 3 235
-29 434 -80 574 -148 90 -43 185 -131 198 -183 4 -19 6 -151 3 -292 l-5 -256
-30 -43 c-89 -127 -374 -234 -731 -275 -155 -18 -566 -15 -704 6 -88 13 -283
51 -289 56 -2 2 19 29 47 60 28 31 65 84 83 118 l32 61 -64 -64 c-165 -164
-343 -244 -575 -258 -218 -13 -451 52 -644 179 -89 58 -228 194 -288 281 -158
229 -211 485 -152 732 18 73 82 200 139 276 124 163 341 281 570 309 81 10
223 3 316 -16z m-6535 -2011 c132 -45 206 -116 251 -242 l24 -66 0 -710 0
-710 -27 -57 c-35 -76 -104 -148 -173 -182 -111 -54 -133 -56 -852 -56 l-663
0 0 221 0 221 80 -7 80 -7 0 596 0 595 -80 -6 -80 -6 0 222 0 222 688 -3 c679
-3 688 -3 752 -25z m4100 -303 l0 -330 -269 0 -269 0 -7 115 -7 115 -164 0
-164 0 0 -195 0 -195 235 0 235 0 0 -185 0 -185 -235 0 -235 0 0 -216 0 -216
75 7 75 7 0 -221 0 -221 -460 0 -460 0 0 220 0 221 78 -3 77 -3 3 475 c2 261
0 526 -3 588 l-7 112 -74 0 -74 0 0 220 0 220 825 0 825 0 0 -330z m1130 110
l0 -220 -70 0 -70 0 0 -591 0 -590 68 3 67 3 3 -217 2 -218 -455 0 -455 0 0
221 0 221 80 -7 80 -7 0 591 0 591 -80 0 -80 0 0 220 0 220 455 0 455 0 0
-220z m2221 136 c157 -34 241 -100 279 -222 35 -113 53 -488 30 -646 -21 -147
-82 -243 -185 -293 -89 -44 -181 -54 -489 -55 l-278 0 6 -31 c3 -17 6 -101 6
-185 l0 -154 63 0 64 0 6 -98 c4 -53 7 -152 7 -219 l0 -123 -455 0 -455 0 0
221 0 222 54 -6 c30 -3 64 -3 75 0 21 5 21 8 21 584 l0 579 -75 0 -75 0 0 220
0 220 668 0 c535 0 680 -3 733 -14z m2149 -281 l0 -295 -260 0 -260 0 0 80 0
80 -171 0 -172 0 7 -37 c3 -21 6 -107 6 -190 l0 -153 205 0 205 0 0 -185 0
-185 -211 0 -212 0 7 -37 c3 -21 6 -118 6 -215 l0 -178 165 0 165 0 0 85 0 85
270 0 270 0 0 -300 0 -300 -820 0 -820 0 0 220 0 220 69 -2 c39 -1 72 0 75 3
3 3 6 266 6 584 l1 580 -75 -3 -76 -3 0 220 0 221 810 0 810 0 0 -295z m2180
-80 l0 -375 -220 0 -220 0 0 160 0 160 -110 0 -110 0 0 -585 0 -585 73 0 74 0
6 -97 c4 -54 7 -153 7 -220 l0 -123 -465 0 -465 0 2 218 3 217 78 3 77 3 0
584 0 585 -115 0 -115 0 0 -160 0 -160 -220 0 -220 0 0 278 c0 152 3 321 7
375 l6 97 964 0 963 0 0 -375z'
        />
        <path
          d='M8162 4479 c-76 -43 -148 -118 -179 -186 -21 -45 -28 -78 -31 -145
-6 -113 6 -157 78 -303 65 -131 103 -171 202 -215 102 -45 327 -51 439 -12
262 92 368 392 225 637 -15 25 -28 45 -30 45 -2 0 3 -21 11 -47 23 -76 28
-255 9 -337 -41 -171 -131 -251 -316 -278 -170 -25 -341 21 -425 114 -62 69
-80 129 -79 258 2 143 31 262 98 395 29 58 53 105 52 105 -1 0 -25 -14 -54
-31z'
        />
        <path
          d='M10169 4043 c-1 -16 -1 -43 0 -61 7 -95 -98 -139 -350 -149 -144 -6
-301 8 -371 33 -54 19 -58 18 -58 -10 0 -27 4 -29 160 -47 131 -15 401 -7 530
16 143 26 247 61 253 86 13 50 -37 108 -126 147 -37 16 -37 16 -38 -15z'
        />
        <path d='M1350 1965 l0 -595 170 0 170 0 0 595 0 595 -170 0 -170 0 0 -595z' />
        <path d='M8840 2270 l0 -220 170 0 170 0 0 220 0 220 -170 0 -170 0 0 -220z' />
      </g>
    </svg>
  );
}
