import styled from 'styled-components';

export const StyledWrapper = styled.div`
  border-radius: 14px;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  background: #171616;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 250px;
`;
