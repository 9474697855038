import { useState, useEffect } from 'react';
import { ButtonContainer, StyledText } from '../Form/_FormComponents';
import Icon from '../../../../external/components/Icon/Icon';
import Text from '../../../../external/components/Text/Text';
import { TextInput } from '../../../../external/components/Input/Input';
import Button from '../../../../external/components/Button/Button';
import { DelegatorLink, LinkedStatusType } from '../../../../services/delegator/delegator.types';
import { useDelegatorUserRateHook } from '../../hooks/delegator.hook';

interface Props {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  delegatorLinks: DelegatorLink[];
  walletAddress: string;
  otherWalletAddress: string;
  chainId: number;
}

export function CheckStatusScreen({
  setScreen,
  delegatorLinks,
  walletAddress,
  otherWalletAddress,
  chainId,
}: Props) {
  const [linked, setLinked] = useState(false);
  const [filteredDelegatorLinks, setFilteredDelegatorLinks] = useState<DelegatorLink[]>([]);

  const handleStartLinkClick = async () => {
    setScreen(3);
  };

  const { checkDelegation, userRate, REWARD_POOL_SIZE, userRateParsed, loading, error } =
    useDelegatorUserRateHook(chainId);

  useEffect(() => {
    checkDelegation(otherWalletAddress);
  }, []);

  useEffect(() => {
    if (delegatorLinks.length) {
      setFilteredDelegatorLinks(
        delegatorLinks.filter((x) => x.address_other === otherWalletAddress),
      );
    }
  }, [delegatorLinks]);

  useEffect(() => {
    if (filteredDelegatorLinks.length) {
      if (filteredDelegatorLinks[0].type === LinkedStatusType.LINKED) setLinked(true);
    }
  }, [filteredDelegatorLinks]);

  return (
    <>
      {/* {filteredDelegatorLinks.length ? ( */}
      <>
        <TextInput
          id='walletAddress'
          placeholder={otherWalletAddress}
          darkAlt={true}
          defaultValue={otherWalletAddress}
          autoComplete='off'
          disabled={true}
        ></TextInput>
        <StyledText dark={true} size='sm'>
          <Icon width={10} height={10} type='greenCircle' />
          Wallet is recognized
        </StyledText>
      </>
      {/* ) : null} */}
      <Text dark={true}>Address is currently:</Text>
      {/* If they have a rate, the address is already in the system */}
      {/* Re do this when we add re linking */}
      {/* <StyledText color={userRateParsed.userStake ? 'green' : 'pink'} forwardedAs='span'>
        <Icon
          width={10}
          height={10}
          type={userRateParsed.userStake ? 'greenCircle' : 'pinkCircle'}
        />
        {userRateParsed.userStake ? 'Already linked' : 'Not Linked'}
      </StyledText>
      <br /> */}

      <>
        {/** @TODO fix up all this brs */}
        <StyledText forwardedAs='span' color={userRateParsed.userStake > 0 ? 'green' : 'pink'}>
          <Icon
            width={10}
            height={10}
            type={userRateParsed.userStake > 0 ? 'greenCircle' : 'pinkCircle'}
          />
          {userRateParsed.userStake > 0 ? 'Delegated' : 'Not Delegated'}
        </StyledText>
        {/* <Text dark={true}>Estimated daily rewards at the current rate:</Text>
        <Text color='pink' as='span'>
          {userRateParsed.userRatio > 0
            ? `${(
                ((REWARD_POOL_SIZE / 30) * (userRateParsed.userStake / 10 ** 6)) /
                userRateParsed.linkedStake
              ).toLocaleString()} OM`
            : `0 OM`}
        </Text> */}
        <br />
        <br />
        <br />
        <ButtonContainer>
          <Button
            onClick={() => handleStartLinkClick()}
            dark={true}
            // disabled={!!userRateParsed.userStake}
          >
            Link Address
          </Button>
        </ButtonContainer>
      </>
    </>
  );
}
